import React, { useMemo } from 'react';
import { useEffect, useState, useRef } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from 'react-select';
import "../../assets/css/master.css"
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../services/ApiCallingUserServices';
import Switch from 'react-switch';
import { isValidImage, NumericWithDecimal, onlyNumeric } from '../commonClass/CommonValidation';
import { useLocation, useNavigate } from 'react-router-dom';
import { ImageDelete, ImageUpload } from '../../ImageUpload/ImageCommon';
import { Rating } from 'react-simple-star-rating';
import { ClearLocalStorage, GetLocalStorage } from '../commonClass/Session';
import { useDispatch } from "react-redux";
import { loaderActions } from "../../_actions/loader.actions";
import RichTextBox from '../../customControl/RichTextBox';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import CustomTimePicker from '../../customControl/CustomTimePicker'
import { htmlToText } from 'html-to-text';
import { ConfirmationModal } from '../../customControl/ConfirmationModal';
import CustomSelection from '../../customControl/CustomSelection';

const Packagemaster = () => {
    const dispatch = useDispatch();

    //#region  analysis state
    const [atFirstDisable, setAtFirstDisable] = useState(true)
    const [flightopen, setFlightOpen] = useState();
    const [hotelopen, setHotelOpen] = useState();
    const [sightseeingopen, steSightseeingOpen] = useState();
    const [disabled, setDisabled] = useState(false);
    const [basicdraftdisabled, setBasicDraftDisabled] = useState(true);
    const [backdisable, setBackDisable] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [activeId, setActiveId] = useState(false);

    const [formerror, setFormError] = useState('');
    const [isRetrive, setIsRetrive] = useState(false);
    const [stateenable, setStateDisable] = useState(false);

    const [isSubmit, setIsSubmit] = useState(false);
    const [cleardisabled, setClearDisable] = useState(false);
    const [packageValidateStart, setPackageValidateStart] = useState(null);
    const [packageValidateEnd, setPackageValidateEnd] = useState(null);
    const packageD = useLocation();
    const navigate = useNavigate();

    const [bestpackage, setBestPackage] = useState(false);

    const [hotdeals, setHotDeals] = useState(false);
    // const [hotofferpercentage, setHotOfferPercentage] = useState(0);
    const [isinternationaldestination, setIsInternationalDestination] = useState(false);
    const [isbestdestinatio, setIsBestDestination] = useState(false);
    const [istrendingholiday, setIsTrendingHoliday] = useState(false);


    const [isbasicShown, setIsBasicShown] = useState(true);
    const [isAddShown, setIsAddShown] = useState(false);
    const [isAddDraftShown, setIsAddDraftShown] = useState(false);
    const [isDetailblockshow, SetIsDetailBlockShow] = useState(false);

    const [flightvisible, setFlightVisibled] = useState(true);

    const [pkgfinalprice, setPkgFinalPrice] = useState(0);
    // const [POverView, setPOverView] = useState(true);
    // const [PItinerary, setPItinerary] = useState(false);
    // const [PInclusion, setPInclusion] = useState(true);
    // const [PExclusion, setPExclusion] = useState(true);
    // const [PPolicies, setPPolicies] = useState(false);
    // const [PSummary, setPSummary] = useState(false);
    // const [PAboutPlace, setPAboutPlace] = useState(false);
    // const [PVisibility, setPVisibility] = useState(true);
    const [PkgSummary, setPkgSummary] = useState([]);
    const [hotelbuttontext, setHotelbuttontext] = useState('');
    const [sightseeingbuttontext, setSightseeingbuttontext] = useState('');
    const [flightbuttontext, setFlightbuttontext] = useState('');
    const [richboxheight, setRichBoxHeight] = useState(false)
    const [cityData, setCityData] = useState([]);

    const [retrivecomplete, setRetriveComplete] = useState(0);
    const [inventoryvalidation, setInventoryValidation] = useState({
        InventoryCount: 0,
        MinSoldoutDate: null,
        MaxSoldOutDate: null,
    })
    const [formvalue, setFormValue] = useState({
        PackageId: 0,
        PackageName: "",
        PackageCode: "",
        PackageCategoryId: 0,
        // StateId: 2,
        StateId: [],
        LocationId: [],
        PackageTypeId: 0,
        PackageLevelId: 0,
        IsBestPackage: false,
        ArrivalSlotId: 0,
        DepartureSlotId: 0,
        PackageValidateStart: null,
        PackageValidateEnd: null,

        PackageValidateStartD: '',
        PackageValidateEndD: '',

        PackageInventoryCountD: '',
        PackageCutOffCountD: '',
        PackageInventoryCount: 0,
        PackageCutOffCount: 0,
        PackageHighlights: "",
        IsActive: false,
        IsDraft: false,

        PackageBasicPrice: 0,
        PackageMarkup: "FIX",
        PackageMarkupValue: "0",
        GST: 0,
        PackageFinalValue: 0,

        ChildrenPrice: null,

        PackageBanner: [],
        PackageGallery: [],

        PackageMenu: [],
        //PackageInclusion : [],
        InclusionD: '',
        datainclusiondetails: [],
        ExclusionD: '',
        dataexclusiondetails: [],
        PolicyD: '',
        datapolicydetails: [],
        CityD: '',
        CityName: '',
        CityDetailsD: '',
        datacitydetails: [],
        PackageInclusion: [],
        PackageExclusion: [],
        PackagePolicy: [],
        PackageOverview: [],
        PackagePlace: [],
        PackageItinerary: [],
        PackageThemeId: [],

        IsHotDeals: false,
        HotOfferPercentage: 0,
        IsInternationalDestination: false,
        IsBestDestination: false,
        IsTrendingHoliday: false,

        Day: '',
        DaySchedule: '',
        SightseeingId: 0,
        RoomPriceD: 0,
        PickUpPoint: '',
        DropPoint: '',
        Hours: '',
        ItineraryDetails: '',
        VehicalPrice: 0,
        AirrivalTime: null,
        DepartureTime: null,
        Duration: '',
        HotelPrice: '',

        FlightPrice: '',

        ArrivalTimeD: '',
        DepartureTimeD: '',

        PackageFinalPrice: 0,
        PackageFixPrice: 0


    })
    function formatTime(date) {

        var d = new Date(date),
            // month = '' + (d.getMonth() + 1),
            // day = '' + d.getDate(),
            // year = d.getFullYear();
            hour = d.getHours(),
            minu = d.getMinutes();


        if (minu.toString().length < 2)
            minu = '0' + minu;
        if (hour.toString().length < 2)
            hour = '0' + hour;

        if (date === null) {
            minu = '';
            hour = '';
        }
        // if (month.length < 2) 
        // month = '0' + month;
        // if (day.length < 2) 
        // day = '0' + day;

        return [hour, minu].join(':');
    }

    const handleFlight = () => {
        setFlightOpen(!flightopen)
        setFlightbuttontext(!flightbuttontext)
    }
    const handleHotel = () => {
        setHotelOpen(!hotelopen)
        setHotelbuttontext(!hotelbuttontext)

    }
    const handleSightseeing = () => {
        steSightseeingOpen(!sightseeingopen)
        setSightseeingbuttontext(!sightseeingbuttontext)
    }

    //#endregion

    //#region handle Dropdown and radio loading part 
    const [statelistdate, setStateListData] = useState([]);
    const [slotlist, setSlotList] = useState([]);
    const [bannerlist, setBannerList] = useState([]);
    const [gallerylist, setGalleryList] = useState([]);
    const [packagetypelist, setPackageTypeList] = useState([]);
    const [packagelevellist, setPackageLevelList] = useState([]);
    const [packagecategorylist, setPackageCategoryList] = useState([]);
    const [inclusionlist, setInclusionList] = useState([]);
    const [exclusionlist, setExclusionList] = useState([]);
    const [policylist, setPolicyList] = useState([]);
    const [menulist, setMenuList] = useState([]);
    const [durationlist, setDurationList] = useState([]);
    const [visitfromduration, setVisitFromDurationList] = useState([]);
    const [visittoduration, setVisitToDurationList] = useState([]);
    const [packagepoint, setPackageoint] = useState([]);
    const [endpackagepoint, setEndPackageoint] = useState([]);
    const [packagetheme, setPackageTheme] = useState([]);
    const [aboutplacecitylist, setAboutPlaceCityList] = useState([]);
    const [hotelnamelist, setHotelNameList] = useState([]);
    const [roomtypelist, setRoomTypeList] = useState([]);
    const [roompricelist, setRoomPriceList] = useState([]);
    const [sightseeinglist, setSightSeeingList] = useState([]);
    const [vehicaltypelist, setVehicalTypeList] = useState([]);

    const [defcheckedvalueofpackagetype, setDefCheckedValueOfPackageType] = useState(0);
    const [defcheckedvalueofpackagelevel, setDefCheckedValueOfPackageLevel] = useState(0);
    const [checkedvalueofpackagetypevalue, setCheckedValueOfPackageTypeValue] = useState(0);
    const [checkedvalueofpackagelevelvalue, setCheckedValueOfPackageLevelValue] = useState(0);
    const [hotelname, setHotelName] = useState('');
    const [sightseeingname, setSightseeingName] = useState('');
    const [hotelrating, setHotelRating] = useState();
    const [editdisabled, setEditDisabled] = useState(false);
    const [allbanner, setAllBanner] = useState([]);
    const [bannerdisabled, setBannerDisabled] = useState(false);
    const [allstartpoint, setAllStartpoint] = useState([]);
    const [startpointdisabled, setStartPointDisabled] = useState(false);
    const [allendpoint, setAllEndpoint] = useState([]);
    const [endpointdisabled, setEndPointDisabled] = useState(false);
    const [fromflight, setFlightfrom] = useState([]);
    const [fromflightdisable, setFromFlightDisable] = useState(false)
    const [allflightfrom, setAllFilghtFrom] = useState([]);
    const [toflight, setFlightTo] = useState([]);
    const [toflightdisable, setToFlightDisable] = useState(false)
    const [allflightto, setAllFilghtTo] = useState([]);
    const [hoteldisable, setHotelDisable] = useState(false)
    const [allhotel, setAllHotel] = useState([]);
    const [sightseeingdisable, setSightseeingDisable] = useState(false)
    const [allsightseeing, setAllSightseeing] = useState([]);
    const [aboutcitydisable, setAboutCityDisable] = useState(false)
    const [allaboutcity, setAllAboutCity] = useState([]);

    const [nightlist, setNightList] = useState([]);
    const [nightid, setNightId] = useState(0);
    const [overviewCityList, setOverviewCityList] = useState([])
    const [overviewCityid, setOverviewCityId] = useState(0);

    const [overviewCityDetails, setOverviewCityDetails] = useState([]);
    const [overviewCity, setOverviewCity] = useState([])
    const [overviewNight, setOverviewNight] = useState([])
    const [overviewbtntext, setOverviewBtnText] = useState('Add');
    const [overviewCityIdEdit, setOverviewCityIdEdit] = useState(0)

    const [overviewCityNightError, setOverviewCityNightError] = useState('')
    // const [overviewCityError, setOverviewCityError] = useState('')
    // const [overviewNightError, setOverviewNightError] = useState('')
    const [overviewCityLengthError, setOverviewCityLengthError] = useState('')
    const [overviewId, setOverviewId] = useState(0);
    const [nightallcity, setNightAllCity] = useState([]);
    const [citydisable, setCityDisable] = useState(false);

    const getStateData = async () => {
        const statedatalist = await getAllData('/master/v1/getpackagestate');
        setStateListData(statedatalist.data);
    }

    const [allCity, setallCity] = useState([]);
    const getalllocationData = async () => {
        await getAllData('/master/vendor/v1/vengetpackagelocationcity').then((response) => {
            if (packageD.state.PackageId <= 0 || packageD.state.PackageId == null) {
                setallCity(response.data?.filter(IsAct => IsAct.IsActive === true));
            }
            else {
                setallCity(response.data);
                setCityData(response.data?.map((value) => ({
                    label: value.City,
                    value: value.LocationId
                })));
                setNightAllCity(response.data)
            }
            // load city overview in live
            const overviewCity = response.data?.filter((value) => formvalue.LocationId?.includes(value.LocationId))?.map((ele) => ({
                label: ele.City,
                value: ele.LocationId
            }))
            setOverviewCityList(overviewCity)
        });

    }

    const statelist = statelistdate?.map((value) => ({
        label: value.StateName,
        value: value.StateId
    }));

    const getslot = async () => {
        const arrivallistslot = await getAllData('/master/v1/getpackageslot');
        setSlotList(arrivallistslot.data);
    }

    const arrivalslotlist = slotlist?.map((value) => ({
        label: value.SlotName,
        value: value.SlotId
    }));

    const departureslotlist = slotlist?.map((value) => ({
        label: value.SlotName,
        value: value.SlotId
    }));

    const getbannerlist = async () => {
        const PackageId = packageD.state.PackageId;
        const bannerlistD = await getAllData('/master/v1/getpackagebanner?PackageId=' + PackageId);
        setBannerList(bannerlistD.data?.filter(IsAct => IsAct.IsActive === true));
        setAllBanner(bannerlistD.data);
    }

    const bannerdatalist = bannerlist?.map((value) => ({
        label: value?.BannerName,
        value: value?.BannerId
    }));

    const getgallerylist = async () => {
        const PackageId = packageD.state.PackageId;
        const gallerylistdata = await getAllData('/master/v1/getpackagegallery?PackageId=' + PackageId);
        setGalleryList(gallerylistdata.data);
    }

    const gallerydatalist = gallerylist?.map((value) => ({
        label: value.ImageDisplayName,
        value: value.GalleryId
    }));


    const getpackagetype = async () => {

        const packagetypelistdata = await getAllData('/master/v1/getpackagetype');
        setPackageTypeList(packagetypelistdata.data);
        packagetypelistdata.data?.map((value) => {
            if (value.IsDefault === true) {
                // setCheckedValueOfPackageType(true);
                // setDefCheckedValueOfPackageType(true);
                setDefCheckedValueOfPackageType(value.PackageTypeId);
                setCheckedValueOfPackageTypeValue(value.PackageTypeId);
                formvalue.PackageTypeId = Number(value.PackageTypeId)
            }
        });
    }


    const getpackagelevel = async () => {
        ///PackageLevelList
        const packagelevellistdata = await getAllData('/master/v1/getpackagelevel');
        setPackageLevelList(packagelevellistdata.data);
        packagelevellistdata.data?.map((value) => {
            if (value.IsDefault === true) {
                // setCheckedValueOfPackageLevel(true);
                // setDefCheckedValueOfPackageLevel(true);
                setDefCheckedValueOfPackageLevel(value.PackageLevelId);
                setCheckedValueOfPackageLevelValue(value.PackageLevelId);
                formvalue.PackageLevelId = Number(value.PackageLevelId)
            }
        });
    }

    const getpackagecategory = async () => {
        const packagecategorylist = await getAllData('/master/v1/getpackagecategory');
        setPackageCategoryList(packagecategorylist.data);
    }
    const packagecategorydatalist = packagecategorylist?.map((value) => ({
        label: value.PackageCategoryName,
        value: value.PackageCategoryId
    }));

    const getInclusionList = async () => {
        const listofinclusiondata = await getAllData('/master/v1/getpackageinclusion');
        setInclusionList(listofinclusiondata.data);
    }
    const inclusionlistdata = inclusionlist?.map((value) => ({
        label: value.InclusionName,
        value: value.InclusionId
    }));
    const getExclusionList = async () => {
        const listofexclusiondata = await getAllData('/master/v1/getpackageexclusion');
        setExclusionList(listofexclusiondata.data);
    }
    const exclusionistdata = exclusionlist?.map((value) => ({
        label: value.ExclusionName,
        value: value.ExclusionId
    })) || [];

    const getPolisylist = async () => {
        const listofpolicydata = await getAllData('/master/v1/getpackagepolicy');
        setPolicyList(listofpolicydata.data);
    }
    const policylistdata = policylist?.map((value) => ({
        label: value.PolicyName,
        value: value.PolicyId
    }));

    const getMenulist = async () => {
        const listofmenudata = await getAllData('/master/v1/getpackagemenu');
        setMenuList(listofmenudata.data);
    }

    const getDurationdatallist = async () => {
        const durationlistD = await getAllData('/master/v1/getpackageduration');
        setDurationList(durationlistD.data);
    }

    const durationlistofoverview = durationlist?.map((value) => ({
        label: value.Duration,
        value: value.DurationId
    }));

    const getdurationtovisit = async () => {
        const visitduration = await getAllData('/master/v1/getpackagemonth');
        setVisitFromDurationList(visitduration.data);
        setVisitToDurationList(visitduration.data);
    }
    const visitfromdurationlist = visitfromduration?.map((value) => ({
        label: value.MonthName,
        value: value.MonthId
    }));
    const visittodurationlist = visittoduration?.map((value) => ({
        label: value.MonthName,
        value: value.MonthId
    }));
    const getpackagepoint = async () => {
        await getAllData('/master/v1/getpackagelocationcity').then((packagepointlist) => {
            setPackageoint(packagepointlist.data?.filter(IsAct => IsAct.IsActive === true));
            setEndPackageoint(packagepointlist.data?.filter(IsAct => IsAct.IsActive === true));
            setFlightfrom(packagepointlist.data?.filter(IsAct => IsAct.IsActive === true));
            setFlightTo(packagepointlist.data?.filter(IsAct => IsAct.IsActive === true));
            setAllFilghtFrom(packagepointlist.data);
            setAllFilghtTo(packagepointlist.data);
            setAllStartpoint(packagepointlist.data);
            setAllEndpoint(packagepointlist.data);
            handleRetrive();
            dispatch(loaderActions.end());
        });
    }
    const packagepointfromlist = packagepoint?.map((value) => ({
        label: value.City,
        value: value.LocationId
    }));

    const packagepointtolist = endpackagepoint?.map((value) => ({
        label: value.City,
        value: value.LocationId
    }));
    const getpackagethemelist = async () => {
        const packagethemelistD = await getAllData('/master/v1/getpackagetheme');
        setPackageTheme(packagethemelistD.data);
    }

    const packagethemelist = packagetheme?.map((value) => ({
        label: value.ThemeName,
        value: value.ThemeId
    }));

    const getaboutplacecity = async () => {
        const aboutplacecity = await getAllData('/master/v1/getpackagelocationcity');
        setAboutPlaceCityList(aboutplacecity.data?.filter(IsAct => IsAct.IsActive === true));
        setAllAboutCity(aboutplacecity.data)
    }
    const aboutplacecitydatalist = aboutplacecitylist?.map((value) => ({
        label: value.City,
        value: value.LocationId
    }));
    const flightfrom = fromflight?.map((value) => ({
        label: value.City,
        value: value.LocationId
    }));
    const flightto = toflight?.map((value) => ({
        label: value.City,
        value: value.LocationId
    }));
    const gethotelnamelist = async () => {
        const roomtype = await getAllData('/master/v1/getpackagehotel');
        setAllHotel(roomtype.data);
        setHotelNameList(roomtype.data?.filter(IsAct => IsAct.IsActive === true));
    }
    const hotelnamelistdata = hotelnamelist?.map((value) => ({
        label: value.Name,
        value: value.HotelId
    }));
    const getsightseeinglistdata = async () => {
        const sightseeingD = await getAllData('/master/v1/getpackagesightseeing');
        setSightSeeingList(sightseeingD.data?.filter(IsAct => IsAct.IsActive === true));
        setAllSightseeing(sightseeingD.data)
    }
    const sightseeinglistdata = sightseeinglist?.map((value) => ({
        label: value.SightseeingName,
        value: value.SightseeingId
    }));

    useEffect(() => {
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            //if(Window.$name &&  Window.$userToken)        {
            if (packageD.state != null) {
                getalllocationData()
                getStateData();
                getslot();
                getbannerlist();
                getgallerylist();
                getpackagetype();
                getpackagelevel();
                getpackagecategory();
                getInclusionList();
                getExclusionList();
                getPolisylist();
                getMenulist();
                getDurationdatallist();
                getdurationtovisit();
                getpackagepoint();
                getpackagethemelist();
                getaboutplacecity();
                //getpackageairline();
                //getroomtype();
                //getvehichaltypelist();
                gethotelnamelist();
                getsightseeinglistdata();
                setEditDisabled(packageD.state.editdisabled);
                if (packageD.state.PackageId > 0) {
                    if (packageD.state.viewtype === 'View') {
                        setDisabled(packageD.state.editdisabled);

                    }
                    setAtFirstDisable(packageD.state.atfirstdisable)

                    setClearDisable(true);
                    // setTimeout(handleRetrive, 3000);
                    setIsBasicShown(false);
                    setIsAddShown(true);
                    setIsAddDraftShown(true);
                    SetIsDetailBlockShow(true);
                    setIsRetrive(true);
                    setStateDisable(true);

                }
            }
            else {
                navigate('/Packagelist')
            }

        }
        else {
            ClearLocalStorage();
            // alert('Your Session Expired. Please Login Again!');
            // navigate('/');
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
    }, []);
    //#endregion

    //#region handle dropdown change
    const [banneridlist, setBannerIdList] = useState({
        BannerId: 0
    });
    const [galleridlist, setGalleryIdList] = useState([{
        GalleryId: 0,
        Id: 0
    }]);
    const [themeidlist, setThemeIdList] = useState([{
        ThemeId: 0,
        Id: 0
    }]);
    const [bannerimgpath, setBannerImgPath] = useState('');
    const galleryimgpathlist = useState([{
        GalleryId: 0,
        GalleryPath: '',
        Id: 0
    }]);
    const themeimgpathlist = useState([{
        ThemeId: 0,
        ThemePath: '',
        Id: 0
    }]);
    const [themeimgpath, setThemeImgPath] = useState(themeimgpathlist);
    const [galleryimgpath, setGalleryImgPath] = useState(galleryimgpathlist);
    const [stateid, setStateId] = useState([]);
    const [arrivalslotid, setArrivalSlotId] = useState(0);
    const [departureslotid, setDepartureSlotId] = useState(0);
    const [bannerId, setBannerId] = useState(0);
    const [galleryid, setGalleryId] = useState(0);
    const [gallerycount, setGalleryCount] = useState(0);
    const [packagecategoryid, setPackageCategoryId] = useState(0);
    const [galleryimgerror, setGalleryError] = useState('');
    const [durationid, setDurationId] = useState(0);
    const [visitfromdurationid, setVisitFromDurationId] = useState(0);
    const [visittodurationid, setVisitToDurationId] = useState(0);
    const [visitpointfromid, setVisitFromId] = useState(0);
    const [visitpointtoid, setVisitToId] = useState(0);

    const [themebyid, setThemeById] = useState(0);
    const [themebycounter, setThemeCounter] = useState(0);
    const [hotelnameid, setHotelNameId] = useState(0);
    const [hotelroomtypeid, setHotelRoomTypeId] = useState(0);
    const [hotelroomprice, setHotelRoomPrice] = useState(0);
    const [noofpaxid, setNoOfPaxId] = useState(0);

    // const handleStateChange = (e) => {
    //     formvalue.StateId = Number(e.value)
    //     setStateId(e.value);
    //     setBasicDraftDisabled(false);
    //     reseterrormessage();
    // }

    const [selectedValue, setSelectedValue] = useState([]);
    const [selectedCityValue, setSelectedCityValue] = useState([]);

    const handleStateChange = (e) => {
        const states = e?.map(obj => obj.value)
        formvalue.StateId = states
        setStateId(states);
        if (states?.length === 0) {
            setSelectedCityValue([])
        }
        setBasicDraftDisabled(false);
        reseterrormessage();
        // setSelectedValue(Array.isArray(e) ? e?.map(x => x.value) : []);
        setSelectedValue(e);

        const cityAgain = allCity?.filter((obj) => states?.includes(obj.StateId))?.map((ele) => ({
            label: ele.City,
            value: ele.LocationId
        }))

        const cityAgainValue = cityAgain.map(object => object.value)
        const cityCheck = selectedCityValue?.filter((obj) => cityAgainValue?.includes(obj))

        setSelectedCityValue(selectedCityValue?.filter((obj) => cityAgainValue?.includes(obj)).map((ele) => {
            // setLocationId(ele)
            return ele
        }))


        if (cityCheck?.length === 0) {
            // setLocationId([])
            setSelectedCityValue([])
        }

        setCityData(allCity?.filter((obj) => states?.includes(obj.StateId))?.map((ele) => ({
            label: ele.City,
            value: ele.LocationId
        })))
    }
    useEffect(() => {
        const CityIds = selectedCityValue.map(obj => {
            return obj.value
        })
        formvalue.LocationId = CityIds
        // formvalue.LocationId = selectedCityValue
    }, [selectedCityValue])
    const handleCityChange = (e) => {
        // const cities = e?.map(obj => obj.value)

        // setLocationId(cities);
        setBasicDraftDisabled(false);
        reseterrormessage();
        // setSelectedCityValue(Array.isArray(e) ? e?.map(x => x.value) : []);
        setSelectedCityValue(e);
    }

    const handleArrivalSlotChange = (e) => {
        formvalue.ArrivalSlotId = Number(e.value)
        setArrivalSlotId(e.value);
        reseterrormessage();
        setEditDisabled(false);
    }
    const handleDepatureSlotChange = (e) => {
        formvalue.DepartureSlotId = Number(e.value)
        setDepartureSlotId(e.value);
        reseterrormessage();
        setEditDisabled(false);
    }
    const handleBannerChange = (e) => {
        // {formvalue.Banne = Number(e.value)}
        setBannerId(e.value);
        banneridlist.BannerId = e.value
        formvalue.PackageBanner = [banneridlist]
        bannerlist?.filter((obj) => obj.BannerId === e.value).map((value) => {
            setBannerImgPath('https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + value.BannerPath);
        });
        reseterrormessage();
        setEditDisabled(false);
    }
    const removeBannerImage = () => {
        setBannerId(0);
        banneridlist.BannerId = 0
        formvalue.PackageBanner = [banneridlist]
        setBannerImgPath('');
        reseterrormessage();
        setEditDisabled(false);
    }
    // const handleisActive = (e) => {
    //     { formvalue.IsActive = e }
    //     setActiveId(!activeId);
    //     reseterrormessage();
    //     setEditDisabled(false);
    //     // handleresetallfield();

    // }
    const handleisActive = (e) => {
        if (!isAddShown) {
            formvalue.IsActive = e
            setActiveId(!activeId);
            reseterrormessage();
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (e) => {
        //setYesNoConfirm(e)
        if (e === true) {
            formvalue.IsActive = e
            setActiveId(!activeId);
            reseterrormessage();
            setEditDisabled(false);
        }
        setModalShow(false);
    }
    const handleGalleryChange = (e) => {
        setGalleryId(e.value);
        reseterrormessage();
        setGalleryCount(gallerycount + 1);
        setEditDisabled(false);
    }
    const GalleryImage = () => {

        const galleryimgrepeat = galleryimgpath?.filter((obj) => obj.GalleryId === galleryid);
        if (galleryid > 0) {
            if (galleryimgpath.length < 20) {
                if (galleryimgrepeat.length === 0) {
                    gallerylist?.filter((obj) => obj.GalleryId === galleryid).map((value) => {
                        if (gallerycount === 1) {
                            setGalleryIdList([{ GalleryId: galleryid, Id: gallerycount }]);
                            setGalleryImgPath(current => [{ GalleryId: value.GalleryId, GalleryPath: 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + value.ImagePath, Id: gallerycount }]);
                        }
                        else {
                            setGalleryIdList(current => [...current, { GalleryId: galleryid, Id: gallerycount }]);
                            setGalleryImgPath(current => [...current, { GalleryId: value.GalleryId, GalleryPath: 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + value.ImagePath, Id: gallerycount }]);
                        }

                    });
                }
                else {
                    setGalleryError('You can not enter duplicate gallery');
                }
            }
            else {
                setGalleryError('You can not enter more than 20 image');
                document.getElementById("GalleryCount").focus();
            }

        }

    }

    useEffect(() => { }, [galleryimgpath]);

    const removeGalleryImage = (id, e) => {

        setGalleryImgPath((oldState) => oldState?.filter((item) => item.Id !== id));
        setGalleryIdList((oldState) => oldState?.filter((item) => item.Id !== id));
        setGalleryId(0);
        reseterrormessage();
        setEditDisabled(false);
    }

    const handlepackagecategory = (e) => {
        formvalue.PackageCategoryId = e.value
        setPackageCategoryId(e.value);
        reseterrormessage();
        setEditDisabled(false);
    }

    const [totalNights, setTotalNights] = useState(0)
    const handledurationchange = (e) => {
        setDurationId(e.value);
        reseterrormessage();
        setEditDisabled(false);

        const nightid = durationlist?.map(id => {
            if (id.DurationId === e.value) {
                return id.NightId
            }
        }).find(x => x != undefined ? x : '')
        setTotalNights(nightid)
        const nightlistdata = durationlist?.filter(obj => obj.NightId <= nightid)?.map((value) => ({
            label: value.Night,
            value: value.NightId
        }));
        setNightList(nightlistdata)

    }
    const handleNightChange = (e) => {
        const night = [
            { label: e.label, value: e.value }
        ]
        setNightId(e.value)
        reseterrormessage();
        setEditDisabled(false);
        setOverviewNight(night)
    }

    const handlecityDatachange = (e) => {
        const city = [
            { label: e.label, value: e.value }
        ]
        setOverviewCityId(e.value)
        reseterrormessage();
        setEditDisabled(false);
        setOverviewCity(city)
    }

    const [curTotalNight, setCurTotalNight] = useState(0)
    const handleOverviewAdd = (e) => {
        reseterrormessage()
        if (overviewbtntext === 'Add') {
            let totalnight = 0
            overviewCityDetails?.map(value => {
                let temp = parseInt(value.night)
                totalnight = temp + totalnight
            })
            let temp2 = parseInt(overviewNight[0]?.label != undefined ? overviewNight[0]?.label : 0)
            // let temp2 = parseInt(overviewNight[0]?.label)
            totalnight = temp2 + totalnight
            setCurTotalNight(totalnight)
            if (totalnight > totalNights) {
                setOverviewCityLengthError('Only ' + totalNights + ' nights are allowed')
            }
            else {
                if (overviewCityid === 0 || nightid === 0) {
                    setOverviewCityNightError('Kindly select city with no. of nights')
                }
                // if (overviewCityid === 0) {
                //     setOverviewCityError('Kindly select city with no. of nights')
                // }
                // if (nightid === 0) {
                //     setOverviewNightError('Kindly select city with no. of nights')
                // }

                setOverviewId(overviewId + 1)
                setOverviewCityDetails([...overviewCityDetails, {
                    packageOverviewCityId: overviewId + 1,
                    city: overviewCity[0].label,
                    cityId: overviewCity[0].value,
                    night: overviewNight[0].label,
                    nightId: overviewNight[0].value
                }
                ]);
                setOverviewCityId(0)
                setNightId(0)
                setOverviewCity([])
                setOverviewNight([])
            }
        }
        else {
            if (overviewCityIdEdit > 0) {
                handleeditofoverview(overviewCityIdEdit);
            }
        }
    }
    const handleEditOverviewCity = (id, e) => {
        setOverviewBtnText('Update');
        setOverviewCityIdEdit(id)
        overviewCityDetails?.filter(obj => obj.packageOverviewCityId === id)?.map(value => {
            setOverviewCityId(value.cityId)
            setNightId(value.nightId)
            const city = [
                { label: value.city, value: value.cityId }
            ]
            const night = [
                { label: value.night, value: value.nightId }
            ]
            setOverviewCity(city)
            setOverviewNight(night)
            let allcitylist = nightallcity?.filter(IsAct => IsAct.IsActive === true);
            setOverviewCityList(allcitylist?.filter((val) => selectedCityValue?.map(objVal => objVal.value)?.includes(val.LocationId))?.map((ele) => ({
                label: ele.City,
                value: ele.LocationId
            })));
            setCityDisable(false);
            if (!allcitylist?.find(IsCity => IsCity.LocationId === value.cityId)) {
                setCityDisable(true)
                setOverviewCityList((prvCityData) => prvCityData?.length > 0 ? [...prvCityData, nightallcity?.find(isCity => isCity.LocationId === value.cityId)]?.map((el) => ({
                    label: el.City,
                    value: el.LocationId
                })) : [])
            }
        })

        reseterrormessage();
        setEditDisabled(false);
    }

    const handleeditofoverview = (id) => {
        let totalnight = 0
        let tid = 0
        overviewCityDetails?.map(value => {
            if (value.packageOverviewCityId === id) {
                tid = parseInt(value.night)
            }
            let temp = parseInt(value.night)
            totalnight = temp + totalnight
        })
        let temp2 = parseInt(overviewNight[0]?.label != undefined ? overviewNight[0]?.label : 0)
        // let temp2 = parseInt(overviewNight[0]?.label)
        totalnight = temp2 + totalnight - tid
        setCurTotalNight(totalnight)
        if (totalnight > totalNights) {
            setOverviewCityLengthError('Only ' + totalNights + ' nights are allowed')
        }
        else {
            const overviewData = overviewCityDetails?.map(value => {
                if (value.packageOverviewCityId === id) {
                    value.city = overviewCity[0].label
                    value.night = overviewNight[0].label
                    value.cityId = overviewCity[0].value
                    value.nightId = overviewNight[0].value
                }
                return value
            })
            setOverviewCityDetails(overviewData)
            setOverviewBtnText('Add');
            setOverviewCityId(0)
            setNightId(0)
            reseterrormessage();
            setEditDisabled(false);
            setOverviewCity([])
            setOverviewNight([])
        }
    }

    const removeverviewCity = (id, e) => {
        setOverviewCityDetails((oldState) => oldState?.filter((item) => item.packageOverviewCityId !== id))
        setOverviewBtnText('Add');
        setOverviewCityId(0)
        setNightId(0)
        reseterrormessage();
        setEditDisabled(false);
    }

    useEffect(() => {
        let totalnight = 0
        overviewCityDetails?.map(value => {
            let temp = parseInt(value.night)
            totalnight = temp + totalnight
        })
        // let temp2 = parseInt(overviewNight[0]?.label != undefined ? overviewNight[0]?.label : 0)
        // let temp2 = parseInt(overviewNight[0]?.label)
        // totalnight = temp2 + totalnight
        setCurTotalNight(totalnight)
    }, [overviewCityDetails, overviewNight, curTotalNight])

    const handledurationfromchange = (e) => {
        setVisitFromDurationId(e.value);
        reseterrormessage();
        setEditDisabled(false);
    }
    const handledurationtochange = (e) => {
        setVisitToDurationId(e.value);
        reseterrormessage();
        setEditDisabled(false);
    }
    const handlevisitfrompoint = (e) => {
        setVisitFromId(e.value);
        reseterrormessage();
        setEditDisabled(false);
    }
    const handlevisittopoint = (e) => {
        setVisitToId(e.value);
        reseterrormessage();
        setEditDisabled(false);
    }
    const themelist = useState([{
        ThemeId: 0,
        ThemeName: '',
        SeqNo: 0
    }]);
    //const [themelistData , setThemeListData] = useState(themelist);
    // const [themelistData , setThemeListData] = useState(themelist);

    const handlethemebychange = (e) => {
        setThemeById(e.value);
        reseterrormessage();
        setThemeCounter(themebycounter + 1);
        setEditDisabled(false);
    }
    const GetThemeImage = () => {
        const themeimagerepeat = themeimgpath?.filter((obj) => obj.ThemeId === themebyid);


        if (themeimagerepeat.length === 0) {
            packagetheme?.filter((obj) => obj.ThemeId === themebyid).map((value) => {

                if (themebycounter === 1) {
                    setThemeIdList([{ ThemeId: themebyid, Id: themebycounter }]);
                    setThemeImgPath(current => [{ ThemeId: value.ThemeId, ThemePath: 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + value.ImagePath, Id: themebycounter }]);
                }
                else {
                    setThemeIdList(current => [...current, { ThemeId: themebyid, Id: themebycounter }]);
                    setThemeImgPath(current => [...current, { ThemeId: value.ThemeId, ThemePath: 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + value.ImagePath, Id: themebycounter }]);
                }

            });


        }
        else {
            //setGalleryError('You can not enter more than 8 image');
        }

    }
    const removeThmeImage = (id) => {

        setThemeImgPath((oldState) => oldState?.filter((item) => item.Id !== id));
        setThemeIdList((oldState) => oldState?.filter((item) => item.Id !== id));
        setThemeById(0);
        reseterrormessage();
        setEditDisabled(false);
    }

    // const handlehotelchange = () => {

    // }


    const handlehotelchange = async (id) => {
        const HotelId = id
        const hoteldetailbyid = await postUsersData('/master/v1/getpackagehoteldetail', { HotelId });
        const roomdetail = hoteldetailbyid.data.roomTypeDetail?.map((value) => ({
            label: value.RoomTypeName,
            value: value.RoomTypeId
        }));
        setRoomTypeList(roomdetail);

    }
    const handlehotelnamechange = (e) => {
        hotelnamelist?.filter(obj => obj.HotelId === e.value).map(value => {
            setHotelName(value.Name);
            setHotelRating(value.Rating);
        });
        setHotelNameId(e.value);
        handlehotelchange(e.value);

        formvalue.HotelPrice = ''
        setNoOfPaxId(0);
        setHotelRoomPrice('');
        setRoomPriceList([]);
        setHotelRoomTypeId(0);
    }

    const handleRoomTypeChange = async (e) => {
        const HotelId = hotelnameid;
        const hoteldetailbyid = await postUsersData('/master/v1/getpackagehoteldetail', { HotelId });
        const roompricedetail = hoteldetailbyid.data.priceDetail?.filter(obj => obj.RoomTypeId === e.value).map((value) => ({
            label: value.NoOfPax,
            value: value.NoOfPax
        })) || [];

        if (hotelroomtypeid !== e.value) {
            setNoOfPaxId(0);
            formvalue.HotelPrice = ''
        }
        setHotelRoomPrice('');
        setRoomPriceList(roompricedetail);
        setHotelRoomTypeId(e.value);
    }
    const handleNoofpaxchange = async (e) => {
        const HotelId = hotelnameid
        const hoteldetailbyid = await postUsersData('/master/v1/getpackagehoteldetail', { HotelId });
        const roompricedetail = hoteldetailbyid.data.priceDetail?.filter(obj => obj.RoomTypeId === hotelroomtypeid && obj.NoOfPax === e.value).map((value) => {
            return value.Price
        });
        formvalue.HotelPrice = roompricedetail[0]
        setNoOfPaxId(e.value);
        setHotelRoomPrice(roompricedetail[0]);
    }
    const [vehicalistdata, setVehicalListData] = useState([]);
    const [vehicalprice, setVehicalPrice] = useState('');
    const [vehicaltypeid, setVehicalTypeId] = useState(0);
    const [sightseeingid, setSightSeeingId] = useState(0);

    const handlesightseeingchange = async (e) => {
        sightseeinglist?.filter(obj => obj.SightseeingId === e.value).map((value) => {
            formvalue.SightseeingId = e.value
            formvalue.PickUpPoint = value.PickUpPoint
            formvalue.DropPoint = value.DropPoint
            formvalue.Hours = value.Hours
            formvalue.ItineraryDetails = value.ItineraryDetails
            setHoursTime(formatTime(value.Hours))
            setSightseeingName(value.SightseeingName);
        })
        const SightseeingId = formvalue.SightseeingId;
        const vehicallist = await postUsersData('/master/v1/getpackagesightseeingdetail', { SightseeingId });
        const vehichallistD = vehicallist.data?.map((value) => ({
            label: value.VehicleType,
            value: value.VehicleId
        })) || [];
        setVehicalTypeId(0);
        formvalue.VehicalPrice = ''
        setVehicalPrice('');
        setVehicalListData(vehichallistD);
        setVehicalTypeList(vehicallist.data);
        setSightSeeingId(e.value);
    }

    const handlevehicaltype = (e) => {
        const pricedata = vehicaltypelist?.filter((obj) => obj.VehicleId === e.value).map((value) => {
            return value.Price
        });
        setVehicalTypeId(e.value);
        formvalue.VehicalPrice = pricedata[0]
        setVehicalPrice(pricedata[0]);
    }



    // const vehicllistdata = vehicaltypelist?.map((value) => ({
    //     label : value.VehicleType,
    //     value : value.VehicleId
    // }));
    useEffect(() => {

    }, [themeimgpath]);
    useEffect(() => {

        if (themebycounter > 0)
            GetThemeImage();
    }, [themebycounter])
    useEffect(() => {
        if (gallerycount > 0)
            GalleryImage();
    }, [gallerycount]);

    useEffect(() => {

    }, [galleridlist, galleryimgpathlist]);
    //#endregion

    //#region  clear section 
    const handleclear = () => {
        clear();
    }

    function clear() {
        reseterrormessage();
        resetDisableStateofcontrol();
        setFormValue({
            ...formvalue, PackageName: "", PackageCode: "", StateId: [], LocationId: [], ChildrenPrice: null,
            PackageTypeId: defcheckedvalueofpackagetype, PackageLevelId: defcheckedvalueofpackagelevel, IsBestPackage: false,
            ArrivalSlotId: 0, DepartureSlotId: 0, PackageValidateStart: null, PackageValidateEnd: null,
            PackageValidateStartD: "", PackageValidateEndD: "",
            PackageInventoryCountD: '', PackageInventoryCount: 0, PackageCutOffCountD: '', PackageCutOffCount: 0,
            PackageHighlights: "", PackageOverview: [],
            IsActive: false, IsDraft: false, PackageBanner: [], PackageGallery: [],
            Day: '', DaySchedule: '', AirrivalTime: null, DepartureTime: null,
            ArrivalTimeD: '', DepartureTimeD: '', HotelPrice: '', SightseeingId: 0,
            Duration: '', PickUpPoint: '', DropPoint: '', ItineraryDetails: '',
            Hours: '', FlightPrice: '', InclusionD: '', datainclusiondetails: [],
            ExclusionD: '', dataexclusiondetails: [], PolicyD: '', datapolicydetails: [],
            CityD: '', CityName: '', CityDetailsD: '', datacitydetails: [], PackageInclusion: [],
            PackageExclusion: [], PackagePlace: [], PackageItinerary: [], PackageThemeId: [], IsHotDeals: false,
            HotOfferPercentage: 0, IsInternationalDestination: false, IsBestDestination: false, IsTrendingHoliday: false
        });

        // {formvalue.PackageValidateStartD = ''}
        resetcontrolvalues();
    }
    const resetcontrolvalues = () => {
        setBannerImgPath('');
        setBannerIdList({ BannerId: 0 });
        setGalleryImgPath([]);
        setGalleryIdList([]);
        // setStateId(0);
        setStateId([]);
        setSelectedValue([])
        setSelectedCityValue([])
        setCityData([])
        setArrivalSlotId(0);
        setDepartureSlotId(0);
        setBannerId(0);
        setGalleryId(0);
        setGalleryCount(0);
        setPackageCategoryId(0);
        setBestPackage(false);
        setActiveId(false);
        setDurationId(0);
        setVisitFromDurationId(0);
        setVisitToDurationId(0);
        setVisitFromId(0);
        setVisitToId(0);
        setCheckedValueOfPackageTypeValue(defcheckedvalueofpackagetype);
        setCheckedValueOfPackageLevelValue(defcheckedvalueofpackagelevel);
        setInclusionDetails([]);
        setExclusionDetails([]);
        setPolicyDetails([]);
        setFlightFromId(0);
        setFlightToId(0);
        setClassId(0);
        setSupplierId(0);
        setVehicalTypeId(0);
        setVehicalPrice(0);
        setFlightNo('');
        setAirlineId(0);
        setHotelNameId(0);
        setHotelRating(0);
        setHotelName('Hotel Name');
        setSightseeingName('Sightseeing Name');
        setHotelRoomTypeId(0);
        setSightSeeingId(0);
        setNoOfPaxId(0);
        setHotelRoomPrice(0);
        setItineraryBtnText('Add');
        setDepartureTime("--:--");
        setArrivalTime("--:--");
        setHoursTime("--:--");
        setPackageValidateStart(null);
        setPackageValidateEnd(null);

        //document.getElementById("txtcityimages").value = "";
    }
    const reseterrormessage = () => {
        setGalleryError('');
        setFormError('');
        setPolicyonDuplicateError('');
        setInclusionDuplicateError('');
        setExclusionDuplicateError('');
        // setOverviewNightError('')
        // setOverviewCityError('')
        setOverviewCityNightError('')
        setOverviewCityLengthError('')

    }
    const resetDisableStateofcontrol = () => {
        setIsSubmit(false);
        if (packageD.state.PackgeId === 0) {
            setStateDisable(false);
        }
        setDisabled(false);
        setBasicDraftDisabled(true);
        setClearDisable(false);
        setBannerDisabled(false);
        setStartPointDisabled(false);
        setEndPointDisabled(false);
        setFromFlightDisable(false);
        setToFlightDisable(false);
        setHotelDisable(false);
        setSightseeingDisable(false);
        setAboutCityDisable(false);
    }
    //#endregion

    //#region Back button 
    const handleBackButton = () => {
        reseterrormessage();
        resetDisableStateofcontrol();
        resetcontrolvalues();
        navigate('/Packagelist');
    }
    //#endregion

    //#region inclusion start
    const [inclusionduplicateerror, setInclusionDuplicateError] = useState('');
    const [inclusionvalue, setInclusion] = useState([]);
    const InclusionDetails = useState([{
        InclsuionId: 0,
        Inclusion: '',
        SeqNo: 0
    }]);
    const [datainclusiondetails, setInclusionDetails] = useState(InclusionDetails);
    const [datainclusioncounter, setInclusionCounter] = useState(0);
    const InclusionClick = () => {
        if (formvalue.InclusionD !== '')
            setInclusionCounter(datainclusioncounter + 1);
    };
    function AddDataRowForInclusionDetails() {
        if (packageD.state.PackageId === 0) {
            let duplicate = datainclusiondetails?.filter(obj => obj.InclsuionId === inclusionvalue[0]?.value)
            if (duplicate.length === 0) {
                if (datainclusioncounter === 1) {
                    setInclusionDetails(current => [{ SeqNo: datainclusioncounter, Inclusion: formvalue.InclusionD, InclsuionId: inclusionvalue[0]?.value }]);
                }
                else {
                    setInclusionDetails(current => [...current, { SeqNo: datainclusioncounter, Inclusion: formvalue.InclusionD, InclsuionId: inclusionvalue[0].value }]);
                }
            }
            else {
                setInclusionDuplicateError('You can not add duplicate value');
            }

        }
        else {
            let dinc = datainclusiondetails?.filter(obj => obj.SeqNo > 0);

            let len = dinc.length;
            //let len = datainclusiondetails?.length || 0;
            let duplicate = datainclusiondetails?.filter(obj => obj.InclsuionId === inclusionvalue[0]?.value)

            if (inclusionvalue[0]?.value > 0) {
                if (duplicate.length === 0) {
                    if (len === 0) {
                        setInclusionDetails(current => [{ SeqNo: datainclusioncounter, Inclusion: formvalue.InclusionD, InclsuionId: inclusionvalue[0]?.value }]);
                    }
                    else {
                        setInclusionDetails(current => [...current, { SeqNo: datainclusioncounter, Inclusion: formvalue.InclusionD, InclsuionId: inclusionvalue[0].value }]);
                    }
                }
                else {
                    setInclusionDuplicateError('You can not add duplicate value');
                }

            }
        }

    }
    useEffect(() => {
        if (datainclusioncounter > 0)
            AddDataRowForInclusionDetails();
    }, [datainclusioncounter]);

    const inclusionremovev = (id, e) => {
        //setCounter(counter + -1);
        setInclusionDetails((oldState) => oldState?.filter((item) => item.SeqNo !== id));
        reseterrormessage();
        setEditDisabled(false);
    }
    // const options = [
    //     { value: 1, label: 'Chocolate' },
    //     { value: 2, label: 'Strawberry' },
    //     { value: 3, label: 'Vanilla' }
    // ]
    const handleinclusionchange = (e) => {
        formvalue.InclusionD = e.label
        const Inclusiontype = [
            { value: e.value, label: e.label }
        ]
        setInclusion(Inclusiontype);
        reseterrormessage();
        setEditDisabled(false);
        //setInclusion(current => [...current , {vehichaltype}]);
        //vehicaltypevalue
    }
    useEffect(() => {

    }, [datainclusiondetails]);
    //#endregion 

    //#region Exclusion start
    const [exclusionduplicateerror, setExclusionDuplicateError] = useState('');
    const [exclusionvalue, setExclusion] = useState([]);
    const ExclusionDetails = useState([{
        ExclusionId: 0,
        Exclusion: '',
        SeqNo: 0
    }]);
    const [dataexclusiondetails, setExclusionDetails] = useState(ExclusionDetails);
    const [dataexclusioncounter, setExclusionCounter] = useState(0);
    const ExclusionClick = () => {
        if (formvalue.ExclusionD !== '')
            setExclusionCounter(dataexclusioncounter + 1);
    };
    function AddDataRowForExclusionDetails() {
        // if (dataexclusioncounter === 1) {
        //     setExclusionDetails(current => [{ SeqNo: dataexclusioncounter, Exclusion: formvalue.ExclusionD , ExclusionId : exclusionvalue[0].value }]);
        // }
        // else {
        //     setExclusionDetails(current => [...current, { SeqNo: dataexclusioncounter, Exclusion: formvalue.ExclusionD , ExclusionId : exclusionvalue[0].value }]);
        // }

        if (packageD.state.PackageId === 0) {
            let duplicate = dataexclusiondetails?.filter(obj => obj.ExclusionId === exclusionvalue[0]?.value)
            if (duplicate.length === 0) {
                if (datainclusioncounter === 1) {
                    setExclusionDetails(current => [{ SeqNo: dataexclusioncounter, Exclusion: formvalue.ExclusionD, ExclusionId: exclusionvalue[0].value }]);
                }
                else {
                    setExclusionDetails(current => [...current, { SeqNo: dataexclusioncounter, Exclusion: formvalue.ExclusionD, ExclusionId: exclusionvalue[0].value }]);
                }
            }
            else {
                setExclusionDuplicateError('You can not add duplicate value');
            }

        }
        else {

            let dexc = dataexclusiondetails?.filter(obj => obj.SeqNo > 0);

            let len = dexc.length;

            let duplicate = dataexclusiondetails?.filter(obj => obj.ExclusionId === exclusionvalue[0]?.value)

            if (exclusionvalue[0]?.value > 0) {
                if (duplicate.length === 0) {
                    if (len === 0) {
                        setExclusionDetails(current => [{ SeqNo: dataexclusioncounter, Exclusion: formvalue.ExclusionD, ExclusionId: exclusionvalue[0].value }]);
                    }
                    else {
                        setExclusionDetails(current => [...current, { SeqNo: dataexclusioncounter, Exclusion: formvalue.ExclusionD, ExclusionId: exclusionvalue[0].value }]);
                    }
                }
                else {
                    setExclusionDuplicateError('You can not add duplicate value');
                }

            }
        }
    }
    useEffect(() => {
        if (dataexclusioncounter > 0)
            AddDataRowForExclusionDetails();
    }, [dataexclusioncounter]);
    const exclusionremovev = (id, e) => {
        //setCounter(counter + -1);
        setExclusionDetails((oldState) => oldState?.filter((item) => item.SeqNo !== id));
        reseterrormessage();
        setEditDisabled(false);
    }
    const handleexclusionchange = (e) => {
        formvalue.ExclusionD = e.label
        const Exclusiontype = [
            { value: e.value, label: e.label }
        ]
        setExclusion(Exclusiontype);
        reseterrormessage();
        setEditDisabled(false);
        //setInclusion(current => [...current , {vehichaltype}]);
        //vehicaltypevalue
    }
    //#endregion

    //#region policy start
    const [policyduplicateerror, setPolicyonDuplicateError] = useState('');
    const [policyvalue, setPolicy] = useState([]);
    const PolicyDetails = useState([{
        PolicyId: 0,
        Policy: '',
        SeqNo: 0
    }]);
    const [datapolicydetails, setPolicyDetails] = useState(PolicyDetails);
    const [datapolicycounter, setPolicyCounter] = useState(0);
    const PolicyClick = () => {
        if (formvalue.PolicyD !== '')
            setPolicyCounter(datapolicycounter + 1);
    };
    function AddDataRowForPolicyDetails() {
        // if (datapolicycounter === 1) {
        //     setPolicyDetails(current => [{ SeqNo: datapolicycounter, Policy: formvalue.PolicyD , PolicyId :policyvalue[0].value}]);
        // }
        // else {
        //     setPolicyDetails(current => [...current, { SeqNo: datapolicycounter, Policy: formvalue.PolicyD ,PolicyId :policyvalue[0].value}]);
        // }
        if (packageD.state.PackageId === 0) {
            let duplicate = datapolicydetails?.filter(obj => obj.PolicyId === policyvalue[0]?.value)
            if (duplicate.length === 0) {
                if (datainclusioncounter === 1) {
                    setPolicyDetails(current => [{ SeqNo: datapolicycounter, Policy: formvalue.PolicyD, PolicyId: policyvalue[0].value }]);
                }
                else {
                    setPolicyDetails(current => [...current, { SeqNo: datapolicycounter, Policy: formvalue.PolicyD, PolicyId: policyvalue[0].value }]);
                }
            }
            else {
                setExclusionDuplicateError('You can not add duplicate value');
            }

        }
        else {

            let dexc = datapolicydetails?.filter(obj => obj.SeqNo > 0);

            let len = dexc.length;

            let duplicate = datapolicydetails?.filter(obj => obj.PolicyId === policyvalue[0]?.value)

            if (policyvalue[0]?.value > 0) {
                if (duplicate.length === 0) {
                    if (len === 0) {
                        setPolicyDetails(current => [{ SeqNo: datapolicycounter, Policy: formvalue.PolicyD, PolicyId: policyvalue[0].value }]);
                    }
                    else {
                        setPolicyDetails(current => [...current, { SeqNo: datapolicycounter, Policy: formvalue.PolicyD, PolicyId: policyvalue[0].value }]);
                    }

                }
                else {
                    setPolicyonDuplicateError('You can not add duplicate value');
                }

            }
        }
    }
    useEffect(() => {
        if (datapolicycounter > 0)
            AddDataRowForPolicyDetails();
    }, [datapolicycounter]);
    const policyremovev = (id, e) => {
        //setCounter(counter + -1);
        setPolicyDetails((oldState) => oldState?.filter((item) => item.SeqNo !== id));
        reseterrormessage();
        setEditDisabled(false);
    }
    const handlepolicyonchange = (e) => {
        formvalue.PolicyD = e.label
        const Policytype = [
            { value: e.value, label: e.label }
        ]
        setPolicy(Policytype);
        reseterrormessage();
        setEditDisabled(false);
        //setInclusion(current => [...current , {vehichaltype}]);
        //vehicaltypevalue
    }
    //#endregion

    //#region image upload

    // const inputRef = useRef();
    const [fileselected, setSelectedFile] = useState();
    const [placeimagename, setImageName] = useState('');
    const [placeimagepath, setImagePath] = useState('');
    // var elPreview = document.getElementById("preview");
    // var useBlob = false && window.URL;
    //window.URL = window.URL || window.webkitURL;
    // var elBrowse = document.getElementById("txtcityimages"),
    //     elPreview = document.getElementById("preview"),
    //     useBlob = false && window.URL;
    function getDateCombination() {
        var today = new Date();
        let date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
        let time = today.getHours().toString() + (today.getMinutes() + 1).toString() + today.getSeconds().toString();
        return date + '_' + time;
    }
    const [imageError, setImageError] = useState('');
    // let heightimg = 0;
    // let widthimg = 0;
    const handleUploadImageLoad = (e) => {
        reseterrormessage();
        //resetDisableStateofcontrol();
        setEditDisabled(false);
        const files = e.target.files;
        if (isValidImage(e.target.files[0].name)) {
            const files = e.target.files;
            const filecom = getDateCombination();
            const filename = filecom + '_' + '1' + '_' + (files[0].name.replace(/ /g, "_")).replace(/-/g, '');
            setImageName(filename);
            setImagePath('CMS/Package/Place/' + filename);
            setSelectedFile(e.target.files[0]);
            setImageError('');
            // { formvalue.BannerFileName = filename }
            // { formvalue.BannerPath = 'CMS/Banner/' + filename }
            //setEditDisabled(true);
            // setBannerPath('');
            // setBannerFileName('');
            // setBannerDuplicateErrorMessage('');
        }
        else {
            const input = document.getElementById('txtcityimages');
            if (input) {
                input.value = "";
            }
            setImageError("Please select Only Image");
            setIsSubmit(false);
            //setFormValue({ ...formValue, ['BannerFileName']: '', ['BannerPath']: '' });
            // setBannerPath('');
            // setFormErrors('');
            // setBannerFileName('');
            // setBannerDuplicateErrorMessage('');
            return;
        }
        // const filecom  = getDateCombination();
        // const filename = filecom  + '_' + '1' + '_' + (files[0].name.replace(/ /g,"_")).replace(/-/g, '');
        // { formvalue.HotelBannerFileName = filename }
        // { formvalue.HotelBannerPath = 'CMS/Banner/' + filename }

        // var elPreview = document.getElementById("preview");
        // var useBlob = false && window.URL;
        //var files  = this.files;
        // var errors = "";

        // if (!files) {
        //     errors += "File upload not supported by your browser.";
        // }

        // if (files && files[0]) {

        //     for (var i = 0; i < files.length; i++) {

        //         var file = files[i];

        //         if (!(/\.(png|jpeg|jpg|gif)$/i).test(file.name)) {
        //             errors += file.name + " Unsupported Image extension\n";
        //             // readImage(file);
        //         }
        //     }
        // }

    }

    // function readImage(file) {

    //     var reader = new FileReader();

    //     // Once a file is successfully readed:
    //     reader.addEventListener("load", function () {

    //         var image = new Image();
    //         image.addEventListener("load", function () {

    //             var imageInfo = file.name + ' ' + // get the value of `name` from the `file` Obj
    //                 image.width + '×' + // But get the width from our `image`
    //                 image.height + ' ' +
    //                 file.type + ' ' +
    //                 Math.round(file.size / 1024) + 'KB';


    //             elPreview.appendChild(this);
    //             elPreview.insertAdjacentHTML("beforeend", imageInfo + '<br>');

    //             heightimg = image.height;
    //             widthimg = image.width;
    //             alert ('hight' ,heightimg )
    //             // if (useBlob) {
    //             //   window.URL.revokeObjectURL(image.src);
    //             // }
    //         });

    //         image.src = useBlob ? window.URL.createObjectURL(file) : reader.result;

    //     });

    //     reader.readAsDataURL(file);
    // }

    useEffect(() => {
        //setSelectedFile(fileselected);
    }, [fileselected]);
    const removeImage = () => {


        //setUploadImage((oldState) => oldState.filter((item) => item.id !== id));
        setImageName('');
        setImagePath('');
        const input = document.getElementById('txtcityimages');
        if (input) {
            input.value = "";
        }

        setSelectedFile();
        // setHotelBannerFileName('');
        // { formvalue.HotelBannerPath = '' }
        // { formvalue.HotelBannerFileName = '' }
        // setHotelBannerPath('');
    };

    //#endregion 

    //#region About start
    const [aboutplaceid, setAboutPlaceId] = useState(0);
    const [cityvalue, setCityValue] = useState(0);
    const [deletefilelist, setDeleteFileList] = useState([]);
    const [aboutplacebtntext, setAboutPlaceBtnText] = useState('Add');
    // const CityDetails = useState([{
    //     City: '',
    //     CityName: '',
    //     CityDetails: '',
    //     PlaceImageName: '',
    //     PlaceImagePath: '',
    //     PlaceImage: '',
    //     SeqNo: 0
    // }]);
    const CityDetails = useState([{
        City: '',
        CityName: '',
        CityDetails: '',
        PlaceImageName: '',
        PlaceImagePath: '',
        // PlaceImage: '',
        SeqNo: 0
    }]);
    const [datacitydetails, setCityDetails] = useState(CityDetails);
    const [datacitycounter, setCityCounter] = useState(0);
    const [checkduplicatecity, setCheckDuplicateCity] = useState(0)
    const [aboutCityDetail, setAboutCityDetail] = useState('')
    const CityClick = () => {
        const error = {}
        const abc = datacitydetails?.map(value => {
            return value.City;
        })
        const duplicatecity = abc.includes(cityvalue)
        if (aboutplacebtntext === 'Add') {
            if (cityvalue === 0) {
                error.AboutPlaceError = 'Please enter all required field';
                setFormError(error);
                return;
            }
            else if (formvalue.CityDetailsD === null) {
                error.AboutPlaceError = 'Kindly add city details in location master';
                setFormError(error);
                return;
            }
            // else//(formvalue.CityDetailsD !== '' )
            // {
            //     setCityCounter(datacitycounter + 1);
            // }
            else {
                if (duplicatecity) {
                    error.AboutPlaceError = 'City already exist';
                    setFormError(error);
                    return;
                }
                setCityCounter(datacitycounter + 1);
            }
        }
        if (aboutplacebtntext !== 'Add') {
            if (cityvalue === 0) {
                error.AboutPlaceError = 'Please enter all required field';
                setFormError(error);
                return;
            }
            else if (formvalue.CityDetailsD === null) {
                error.AboutPlaceError = 'Kindly add city details in location master';
                setFormError(error);
                return;
            }
            else {
                if (checkduplicatecity !== cityvalue) {
                    if (duplicatecity) {
                        error.AboutPlaceError = 'City already exist';
                        setFormError(error);
                        return;
                    }
                }
                AddDataRowForCityDetails();
            }
            // else//(formvalue.CityDetailsD !== '' )
            // {
            //     setCityCounter(datacitycounter + 1);
            // }
        }
        // if (formvalue.CityDetailsD.trim() !== '') {
        //     setCityCounter(datacitycounter + 1);
        // }

    };
    function AddDataRowForCityDetails() {
        if (packageD.state.PackageId === 0) {
            if (aboutplacebtntext === 'Add') {
                // if (datacitycounter === 1) {
                //     setCityDetails(current => [{ SeqNo: datacitycounter, City: formvalue.CityD, CityName: formvalue.CityName, CityDetails: formvalue.CityDetailsD, PlaceImageName: placeimagename, PlaceImagePath: placeimagepath, PlaceImage: fileselected }]);
                // }
                // else {
                //     setCityDetails(current => [...current, { SeqNo: datacitycounter, City: formvalue.CityD, CityName: formvalue.CityName, CityDetails: formvalue.CityDetailsD, PlaceImageName: placeimagename, PlaceImagePath: placeimagepath, PlaceImage: fileselected }]);
                // }
                if (datacitycounter === 1) {
                    setCityDetails(current => [{ SeqNo: datacitycounter, City: formvalue.CityD, CityName: formvalue.CityName, CityDetails: formvalue.CityDetailsD, PlaceImageName: placeimagename, PlaceImagePath: placeimagepath }]);
                    setFormValue({ ...formvalue, CityD: '', CityName: '', CityDetailsD: '' })
                    setImageName('')
                    setImagePath('')
                    setCityValue(0)
                    setFormError('')
                    setAboutCityDetail('')

                }
                else {
                    setCityDetails(current => [...current, { SeqNo: datacitycounter, City: formvalue.CityD, CityName: formvalue.CityName, CityDetails: formvalue.CityDetailsD, PlaceImageName: placeimagename, PlaceImagePath: placeimagepath }]);
                    setFormValue({ ...formvalue, CityD: '', CityName: '', CityDetailsD: '' })
                    setImageName('')
                    setImagePath('')
                    setCityValue(0)
                    setFormError('')
                    setAboutCityDetail('')
                }
            }
            else {
                if (aboutplaceid > 0) {
                    handleeditaboutplacedetail(aboutplaceid);
                }

            }
        }
        else {

            let dinc = datacitydetails?.filter(obj => obj.SeqNo > 0);

            let len = dinc.length;
            //let len = datainclusiondetails?.length || 0;

            if (aboutplaceid === 0) {

                if (len === 0) {
                    setCityDetails(current => [{ SeqNo: datacitycounter, City: formvalue.CityD, CityName: formvalue.CityName, CityDetails: formvalue.CityDetailsD, PlaceImageName: placeimagename, PlaceImagePath: placeimagepath }]);
                    setFormValue({ ...formvalue, CityD: '', CityName: '', CityDetailsD: '' })
                    setImageName('')
                    setImagePath('')
                    setCityValue(0)
                    setFormError('')
                    setAboutCityDetail('')
                }
                else {
                    setCityDetails(current => [...current, { SeqNo: datacitycounter, City: formvalue.CityD, CityName: formvalue.CityName, CityDetails: formvalue.CityDetailsD, PlaceImageName: placeimagename, PlaceImagePath: placeimagepath }]);
                    setFormValue({ ...formvalue, CityD: '', CityName: '', CityDetailsD: '' })
                    setImageName('')
                    setImagePath('')
                    setCityValue(0)
                    setFormError('')
                    setAboutCityDetail('')
                }

            }
            else if (aboutplaceid > 0) {

                handleeditaboutplacedetail(aboutplaceid);
            }
        }

    }
    const handleeditaboutplacedetail = (id) => {
        const newList = datacitydetails?.map((item) => {

            if (item.SeqNo === id) {
                const updatedItem = {
                    ...item,
                    City: formvalue.CityD,
                    CityName: formvalue.CityName,
                    CityDetails: formvalue.CityDetailsD,
                    PlaceImageName: placeimagename,
                    PlaceImagePath: placeimagepath,
                    // PlaceImage: fileselected
                    //IsChecked: !item.IsChecked,
                };
                return updatedItem;
            }

            return item;
        });
        setCityDetails(newList);
        setFormValue({ ...formvalue, CityD: '', CityName: '', CityDetailsD: '' })
        setImageName('')
        setImagePath('')
        setCityValue(0)
        setFormError('')
        setAboutCityDetail('')
        setAboutPlaceBtnText('Add');
        setAboutPlaceId(0)
        setEditDisabled(false);
    }
    // useEffect(() => {
    //     //&& packageD.state.PackageId === 0
    //     if (datacitycounter > 0) {
    //         setCityValue(0);
    //         formvalue.CityDetailsD = ''
    //         setSelectedFile('');
    //         setImageName('');
    //         setImagePath('');
    //         setAboutPlaceId(0);

    //         setAboutPlaceBtnText('Add');
    //         const input = document.getElementById('txtcityimages');
    //         if (input) {
    //             input.value = "";
    //         }
    //     }
    // }, [datacitydetails]);

    useEffect(() => {
        if (datacitycounter > 0) {
            if (cityvalue !== 0) {
                AddDataRowForCityDetails();
            }
        }

    }, [datacitycounter]);
    const cityremovev = (id, e) => {
        // if (aboutplacebtntext !== 'Add') {
        //     datacitydetails?.map((obj) => {

        //         if (obj.SeqNo === id) {
        //             setDeleteFileList([...deletefilelist, { ImagePath: obj.PlaceImagePath }])
        //         }
        //     });
        // }
        setCityDetails((oldState) => oldState?.filter((item) => item.SeqNo !== id));
        reseterrormessage();
        setEditDisabled(false);
        setFormValue({ ...formvalue, CityD: '', CityName: '', CityDetailsD: '' })
        setImageName('')
        setImagePath('')
        setAboutCityDetail('')
        setCityValue(0)
        setFormError('')
        setAboutPlaceBtnText('Add');
    }
    const cityeditdata = (id, e) => {
        setAboutPlaceBtnText('Update');
        datacitydetails?.filter(obj => obj.SeqNo === id).map((ele, index) => {
            // if (ele.PlaceImage !== '') {
            //     setCityValue(ele.City);
            //     formvalue.CityDetailsD = ele.CityDetails
            //     setRetriveComplete(htmlToText(ele.CityDetails).length);
            //     setSelectedFile(ele.PlaceImage);
            //     setImageName(ele.PlaceImageName);
            //     setImagePath(ele.PlaceImagePath);
            //     setAboutPlaceId(id);
            // }
            // else {
            setCityValue(ele.City);
            setCheckDuplicateCity(ele.City)
            formvalue.CityD = ele.City
            formvalue.CityName = ele.CityName
            formvalue.CityDetailsD = ele.CityDetails
            setAboutCityDetail(htmlToText(ele.CityDetails))
            // setRetriveComplete(htmlToText(ele.CityDetails).length);
            //setSelectedFile(ele.PlaceImage);
            setImageName(ele.PlaceImageName);
            setImagePath(ele.PlaceImagePath);
            // setImagePath('https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + ele.PlaceImagePath);
            setAboutPlaceId(id);
            let allaboutcitylist = allaboutcity?.filter(IsAct => IsAct.IsActive === true);
            setAboutPlaceCityList(allaboutcity?.filter(IsAct => IsAct.IsActive === true));
            setAboutCityDisable(false);
            if (!allaboutcitylist?.find(IsAboutCity => IsAboutCity.LocationId === ele.City)) {
                setAboutCityDisable(true)
                setAboutPlaceCityList((prvAboutdata) => prvAboutdata?.length > 0 ? [...prvAboutdata, allaboutcity?.find(isBanner => isBanner.LocationId === ele.City)] : [])

            }
            setFormError('')
            // }
        })

    }
    const handlecityonchange = async (e) => {

        formvalue.CityName = e.label
        formvalue.CityD = e.value
        // const Citytype = [
        //     { value: e.value, label: e.label }
        // ]
        setCityValue(e.value);
        setEditDisabled(false);
        setFormError('');
        getaboutplacedetailsfromlocmaster(e.value)
    }
    const getaboutplacedetailsfromlocmaster = async (id) => {

        await getAllData('/master/v1/getaboutplacedetailsfromlocmaster?CityId=' + id).then(res => {
            res.data?.map(obj => {
                formvalue.CityD = obj.LocationId
                formvalue.CityDetailsD = obj.CityDetail
                setAboutCityDetail(htmlToText(obj.CityDetail))
                setImagePath('https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + obj.PlaceImagePath)
                setImageName(obj.PlaceImageName)
            })
        })
    }


    //#endregion

    //#region 

    //const [activeId, setActiveId] = useState(0);

    // const handleisChecked = (e) => {
    //     { formvalue.InActive = e.target.checked }
    //     setActiveId(e.target.checked)
    //     setChecked(!checked);
    // }
    // const  menunamelist = menulist.map((obj) => {
    //     obj.PackageMenuName = obj.IsDefault
    // })
    const [Shown, setShown] = useState({
        Overview: true,
        Itinerary: false,
        Inclusion: true,
        Exclusion: true,
        Policies: false,
        Summary: false,
        About: false,
        Visibility: true,
        Price: true,
    });


    // const showDivHandler = (val) => {

    //     const newState = Object.fromEntries(
    //         Object.entries(Shown)?.map(([key, value]) => [key, value])
    //     );

    //     setShown({
    //         ...newState,
    //         [val]: !Shown[val]
    //     });

    //     // setMenuIdList({
    //     //    ...menuIdlist,
    //     // });
    // };

    // const showDivHandlerD = (id) => {
    //     const newList = menulist.map((item) => {

    //         if (item.PackageMenuId === id) {
    //             // if(item.PackageMenuName ==='About Place')
    //             // {
    //             //     setShown({
    //             //         ...Shown,
    //             //         ['About']: !item.IsDefault
    //             //     });
    //             // }
    //             // else{
    //             //     setShown({
    //             //         ...Shown,
    //             //         [item.PackageMenuName]: !item.IsDefault
    //             //     });
    //             // }
    //             setShown({
    //                 ...Shown,
    //                 [item.PackageMenuName]: !item.IsDefault
    //             });

    //             const updatedItem = {
    //               ...item,
    //               IsDefault: !item.IsDefault,
    //             };
    //             return updatedItem;
    //           }

    //     return item;
    //   });
    //   //setMenuIdList([...menuidlist,{PackageMenuId : id}])
    //   setMenuList(newList);
    //   reseterrormessage();
    //   if(packageD.state.viewtype !== 'View')
    //   {
    //     setEditDisabled(false);  
    //   }

    // }
    const showDivHandlerD = (id) => {
        const newList = menulist?.map((item) => {
            if (item.PackageMenuId === id) {
                // if (item.PackageMenuName === 'About Place') {
                //     setShown({
                //         ...Shown,
                //         ['About']: !item.IsDefault
                //     });
                //     setPAboutPlace(!item.IsDefault);
                // }
                // else {
                //     setShown({
                //         ...Shown,
                //         [item.PackageMenuName]: !item.IsDefault
                //     });
                //     // Code by Imran by Temp
                //     if (item.PackageMenuName === "Overview") {
                //         setPOverView(!item.IsDefault);
                //     }
                //     if (item.PackageMenuName === "Itinerary") {
                //         setPItinerary(!item.IsDefault);
                //     }
                //     if (item.PackageMenuName === "Inclusion") {
                //         setPInclusion(!item.IsDefault);
                //     }
                //     if (item.PackageMenuName === "Exclusion") {
                //         setPExclusion(!item.IsDefault);
                //     }
                //     if (item.PackageMenuName === "Policies") {
                //         setPPolicies(!item.IsDefault);
                //     }
                //     if (item.PackageMenuName === "Summary") {
                //         setPSummary(!item.IsDefault);
                //     }
                //     if (item.PackageMenuName === "About") {
                //         setPAboutPlace(!item.IsDefault);
                //     }
                //     if (item.PackageMenuName === "Visibility") {
                //         setPVisibility(!item.IsDefault);
                //     }
                //     // Code by Imran by Temp
                // }
                setShown({
                    ...Shown,
                    [item.PackageMenuName]: !item.IsDefault
                });
                const updatedItem = {
                    ...item,
                    IsDefault: !item.IsDefault,
                };
                return updatedItem;
            }
            return item;
        });
        setMenuList(newList);
        setMenuList(newList);
        reseterrormessage();
        setEditDisabled(false);

        if ((formvalue.PackageTypeId) === 1) {
            setFlightVisibled(false);
            clearFlightDetail();
        } else { setFlightVisibled(true) }

        // Number(formvalue.PackageTypeId) === 1 ? setFlightVisibled(false) : setFlightVisibled(true);
    }

    useEffect(() => {

    }, [Shown])

    //#endregion

    //#region submit
    // const [buttonisdrafttype , setButtonIsDraftType] =useState(false);

    const onDraftSubmit = (e) => {
        e.preventDefault();
        handledataforsubmit();
        setFormError(validateDraftData(formvalue));
    }
    const onSubmitData = (e) => {
        e.preventDefault();
        handledataforsubmit();
        setFormError(validateData(formvalue));
    }
    const handledataforsubmit = () => {

        // const galleryimgrepeat = galleryimgpath?.filter((obj) => obj.GalleryId > 0);

        // if(galleryimgrepeat.length > 0)
        // {
        // }
        formvalue.IsActive = activeId;

        formvalue.PackageGallery = galleryimgpath?.filter((obj) => obj.GalleryId > 0).map(obj => ({
            GalleryId: obj.GalleryId
        }))



        formvalue.PackageInclusion = datainclusiondetails?.filter(obj => obj.SeqNo > 0).map(obj => ({
            InclusionId: obj.InclsuionId
        }))


        formvalue.PackageExclusion = dataexclusiondetails?.filter(obj => obj.SeqNo > 0).map(obj => ({
            ExclusionId: obj.ExclusionId
        }))

        formvalue.PackagePolicy = datapolicydetails?.filter(obj => obj.SeqNo > 0).map(obj => ({
            PolicyId: obj.PolicyId
        }))


        const menuidlistd = menulist?.filter(obj => obj.IsDefault === true).map((obj) => ({
            PackageMenuId: obj.PackageMenuId
        }));


        formvalue.PackageMenu = menuidlistd

        const themeidlistD = themeidlist?.filter(obj => obj.ThemeId > 0).map(obj => ({
            ThemeId: obj.ThemeId
        }));

        formvalue.PackageOverview = [{
            DurationId: durationid,
            BestTimeVisitFromId: visitfromdurationid,
            BestTimeVisitToId: visittodurationid,
            PackageStartPointLocationId: visitpointfromid,
            PackageEndPointLocationId: visitpointtoid,
            PackageOverviewTheme: themeidlistD,
            PackageOverviewCities: overviewCityDetails
        }]
        const aboutplacedetail = datacitydetails?.map(obj => ({
            // LocationId: obj.City,
            LocationId: obj.City,
            CityDetail: obj.CityDetails,
            PlaceImageName: obj.PlaceImageName,
            PlaceImagePath: obj.PlaceImagePath
        }))
        formvalue.PackagePlace = aboutplacedetail

        formvalue.PackageItinerary = dataitinerary?.filter(obj => obj.SeqNo > 0)
        if (menuidlistd?.filter(obj => obj.PackageMenuId === 2).length === 0) {
            formvalue.PackageMarkup = 'FIX'
            formvalue.PackageMarkupValue = '0'
            formvalue.PackageFinalValue = 0
            formvalue.PackageFixPrice = 0
            formvalue.PackageMarkupValue = '0'
            formvalue.PackageBasicPrice = 0
        }
        else {

            formvalue.PackageMarkup = gsttype

            //{formvalue.PackageFinalValue= Number(formvalue.PackageFinalPrice)}
            formvalue.PackageFinalValue = Number(pkgfinalprice)
        }
    }

    const onBasicDraftSubmit = (e) => {
        e.preventDefault();
        // const galleryimgrepeat = galleryimgpath?.filter((obj) => obj.GalleryId > 0);
        // if(galleryimgrepeat.length > 0)
        formvalue.PackageGallery = galleryimgpath?.filter((obj) => obj.GalleryId > 0).map(obj => ({
            GalleryId: obj.GalleryId
        }))
        setFormError(validateDraftData(formvalue));

    }

    const onSubmitBasicDraftForm = async () => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            let status = false;
            let data = false;
            const packagebasic = await postUsersData('/master/v1/draftpackage', formvalue);
            status = packagebasic.status;
            data = packagebasic.data;
            if ((!status && data === null) || (!status && !data)) {
                dispatch({ type: 'ALERTINGMESSAGE', message: packagebasic.message })
                // dispatch({ type: 'ALERTING', message: 'Try again later!' })
                // alert('Try again later!');
                resetDisableStateofcontrol();
                return;
            }
            else {
                if (data > 0) {
                    dispatch({ type: 'ALERTINGMESSAGE', message: packagebasic.message })
                    // dispatch({ type: 'ALERTING', message: 'Record saved successfully' })
                    // alert('Record saved successfully');
                    clear();
                    // navigate('/Packagelist');
                }
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
    }
    const onSubmitForm = async () => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            let status1 = false;
            let data1 = false;
            const packagebasic = await patchUsersData('/master/v1/updatepackage', formvalue);
            status1 = packagebasic.status;
            data1 = packagebasic.data;
            if ((!status1 && data1 === null) || (!status1 && !data1)) {
                dispatch({ type: 'ALERTINGMESSAGE', message: packagebasic.message })
                // dispatch({ type: 'ALERTING', message: 'Try again later!' })
                // alert('Try again later!');
                resetDisableStateofcontrol();
                return;
            }
            else {
                if (status1 && data1 > 0) {
                    // try {
                    //     const deletefilelistlen = deletefilelist?.length || 0;
                    //     for (let i = 0; i < deletefilelistlen; i++) {
                    //         try {
                    //             // const path = 'CMS/Hotel/';

                    //             await handleimageDelete(deletefilelist[i].ImagePath);
                    //         }
                    //         catch (err) {
                    //             dispatch({ type: 'ALERTINGMESSAGE', message: err })
                    //             // dispatch({ type: 'ALERTING', message: err })
                    //             // alert(err)
                    //             resetDisableStateofcontrol();
                    //             return;
                    //         }

                    //     }

                    //     const uplimglist = datacitydetails?.filter((obj) => obj.PlaceImage !== '');
                    //     for (let i = 0; i < uplimglist.length; i++) {
                    //         try {
                    //             const path = 'CMS/Package/Place/';
                    //             await handleimageupload(path, uplimglist[i].PlaceImageName, uplimglist[i].PlaceImage);
                    //         }
                    //         catch (err) {
                    //             dispatch({ type: 'ALERTINGMESSAGE', message: err })
                    //             // dispatch({ type: 'ALERTING', message: err })
                    //             // alert(err)
                    //             resetDisableStateofcontrol();
                    //             return;
                    //         }

                    //     }

                    // }
                    // catch (err) {
                    //     dispatch({ type: 'ALERTINGMESSAGE', message: err })
                    //     // dispatch({ type: 'ALERTING', message: err })
                    //     // alert(err)
                    //     resetDisableStateofcontrol();
                    //     return;
                    // }
                    dispatch({ type: 'ALERTINGMESSAGE', message: packagebasic.message })
                    // dispatch({ type: 'ALERTING', message: 'Record updated successfully' })
                    // alert('Record updated successfully');
                    clear();
                    // navigate('/Packagelist');
                }
                else {
                    dispatch({ type: 'ALERTINGMESSAGE', message: packagebasic.message })
                    // dispatch({ type: 'ALERTING', message: 'Try again later!' })
                    // alert('Try again later!');
                    resetDisableStateofcontrol();
                    return;
                }
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
    }
    const DepartureSlotid = useRef(null)
    const BannerImgPath = useRef(null)
    const GalleryCount = useRef(null)
    const ArrivalSlotId = useRef(null)
    const StateId = useRef(null)
    const Locationid = useRef(null)
    const PackageCategory = useRef(null)

    useEffect(() => {
        if (Object.keys(formerror).length === 0 && isSubmit && !isRetrive) {
            setBasicDraftDisabled(true);
            setDisabled(true);
            setStateDisable(true);
            setBackDisable(true);
            setClearDisable(true);
            onSubmitBasicDraftForm();
        }
        if (Object.keys(formerror).length === 0 && isSubmit && isRetrive) {
            setBasicDraftDisabled(true);
            setDisabled(true);
            setStateDisable(true);
            setBackDisable(true);
            setClearDisable(true);
            onSubmitForm();
        }
        if (Object.keys(formerror).length > 0) {

            let id = Object.keys(formerror)[0];
            let inc = Object.keys(formerror).find(obj => obj === "InclusionError");
            let exc = Object.keys(formerror).find(obj => obj === "ExclusionError");

            let pkgdur = Object.keys(formerror).find(obj => obj === "PackageDuration");
            let btvf = Object.keys(formerror).find(obj => obj === "BestTimeVisitFrom");
            let bttv = Object.keys(formerror).find(obj => obj === "BestTimeToVisit");
            let pkgstrpoint = Object.keys(formerror).find(obj => obj === "PackageStartPoint");
            let pkgendpoint = Object.keys(formerror).find(obj => obj === "PackageEndPoint");
            let themelist = Object.keys(formerror).find(obj => obj === "ThemeList");
            let overviewcitydetail = Object.keys(formerror).find(obj => obj === "overviewCityDetail");
            let totalnightspending = Object.keys(formerror).find(obj => obj === "totalNightsPending");

            let pcy = Object.keys(formerror).find(obj => obj === "PolicyError");

            let abtplc = Object.keys(formerror).find(obj => obj === "AboutPlaceError")

            let itnery = Object.keys(formerror).find(obj => obj === "ItineraryError")

            let childprice = Object.keys(formerror).find(obj => obj === "ChildrenPrice")

            if (inc?.length > 0 || inclusionduplicateerror?.length > 0) {
                let incllopased = document.getElementById("InclusionError")
                if (incllopased.ariaExpanded === "false")
                    document.querySelector("#InclusionError").click();
            }
            if (exc?.length > 0 || exclusionduplicateerror?.length > 0) {
                let exccllopased = document.getElementById("ExclusionError")
                if (exccllopased.ariaExpanded === "false")
                    document.querySelector("#ExclusionError").click();
            }
            if (pkgdur?.length > 0 || btvf?.length > 0 || bttv?.length > 0 || pkgstrpoint?.length > 0 || pkgendpoint?.length > 0 || themelist?.length > 0 || overviewcitydetail?.length > 0 || totalnightspending?.length > 0) {
                let overviewcllopased = document.getElementById("OverviewError")
                if (overviewcllopased.ariaExpanded === "false")
                    document.querySelector("#OverviewError").click();
            }
            if (pcy?.length > 0 || policyduplicateerror?.length > 0) {
                let policycllopased = document.getElementById("PolicyError")
                if (policycllopased.ariaExpanded === "false")
                    document.querySelector("#PolicyError").click();
            }
            if (abtplc?.length > 0) {
                let aboutcllopased = document.getElementById("AboutPlaceError")
                if (aboutcllopased.ariaExpanded === "false")
                    document.querySelector("#AboutPlaceError").click();
            }
            if (itnery?.length > 0) {
                let itenrycllopased = document.getElementById("ItineraryError")
                if (itenrycllopased.ariaExpanded === "false")
                    document.querySelector("#ItineraryError").click();
            }
            if (childprice?.length > 0) {
                let childpricecllopased = document.getElementById("PricingError")
                if (childpricecllopased.ariaExpanded === "false")
                    document.querySelector("#PricingError").click();
            }

            if (id === "DepartureSlotid" || id === "BannerImgPath" || id === "GalleryCount" || id === "ArrivalSlotId" || id === "StateId" || id === "Locationid" || id === "PackageCategory") {
                eval(id).current.focus();
            }
            else if (id === "PackageValidateStart") {
                document.getElementById("startdate").focus();
            }
            else if (id === "PackageDateValidation" || id === "PackageValidateEnd") {
                document.getElementById("enddate").focus();
            }
            else {
                let doc = document.getElementById(id);
                if (doc !== null)
                    doc.focus();
            }
        }

    }, [formerror]);

    useEffect(() => {

    }, [isSubmit]);

    //#endregion

    //#region validate section

    const validateDraftData = (values) => {
        const error = {};
        formvalue.IsDraft = true
        setIsSubmit(true);

        if (stateid.length === 0) {
            error.StateId = 'Please select State';
            setIsSubmit(false);
        }
        if (stateid.length > 15) {
            error.StateId = 'Maximum 15 states can be selected';
            setIsSubmit(false);
        }
        if (selectedCityValue.length === 0) {
            error.Locationid = 'Please select City';
            setIsSubmit(false);
        }
        if (selectedCityValue.length > 15) {
            error.Locationid = 'Maximum 15 cities can be selected';
            setIsSubmit(false);
        }
        if (values.PackageName.trim().length === 0) {
            error.PackageName = 'Please enter package name';
            setIsSubmit(false);
        }
        if (values.PackageName.trim().length > 50) {
            error.PackageName = 'Maximum length of the package name is 50';
            setIsSubmit(false);
        }
        return error;
    }
    const validateData = (values) => {
        const error = {};
        formvalue.IsDraft = false

        setIsSubmit(true);

        if (stateid.length === 0) {
            error.StateId = 'Please select State';
            setIsSubmit(false);
        }
        if (stateid.length > 15) {
            error.StateId = 'Maximum 15 states can be selected';
            setIsSubmit(false);
        }
        if (selectedCityValue.length === 0) {
            error.Locationid = 'Please select City';
            setIsSubmit(false);
        }
        if (selectedCityValue.length > 15) {
            error.Locationid = 'Maximum 15 cities can be selected';
            setIsSubmit(false);
        }
        if (values.PackageName.trim().length === 0) {
            error.PackageName = 'Please enter package name';
            setIsSubmit(false);
        }
        if (values.PackageName.trim().length > 50) {
            error.PackageName = 'Maximum length of the package name is 50';
            setIsSubmit(false);
        }
        if (packagecategoryid === 0) {
            error.PackageCategory = "Please select Package Category";
            setIsSubmit(false);
        }
        if (arrivalslotid === 0) {
            error.ArrivalSlotId = 'Please select Arrival Slot';
            setIsSubmit(false);
        }
        if (departureslotid === 0) {
            error.DepartureSlotid = 'Please select Departure Slot';
            setIsSubmit(false);
        }
        if (!values.PackageValidateStartD || values.PackageValidateStartD.trim().length === 0) {
            error.PackageValidateStart = 'Please select package start date';
            setIsSubmit(false);
        }
        if (!values.PackageValidateEndD || values.PackageValidateEndD.trim().length === 0) {
            error.PackageValidateEnd = 'Please select package end date';
            setIsSubmit(false);
        }
        if (values.PackageValidateStartD > values.PackageValidateEndD && values.PackageValidateStartD !== null && values.PackageValidateEndD !== null && values.PackageValidateStartD !== "" && values.PackageValidateEndD !== "") {
            error.PackageDateValidation = "Please enter valid end Date";
            setIsSubmit(false);
        }
        if (inventoryvalidation.MinSoldoutDate != null) {
            if (formatDate(inventoryvalidation.MinSoldoutDate) < formatDate(values.PackageValidateStartD)) {
                error.PackageValidateStart = 'You are not allowed to change the date as inventory is already in use';
                setIsSubmit(false);
            }
        }
        if (inventoryvalidation.MaxSoldOutDate != null) {
            if (formatDate(inventoryvalidation.MaxSoldOutDate) > formatDate(values.PackageValidateEndD)) {
                error.PackageValidateEnd = 'You are not allowed to change the date as inventory is already in use';
                setIsSubmit(false);
            }
        }
        if (inventoryvalidation.MaxSoldOutDate != null && inventoryvalidation.MaxSoldOutDate != null) {
            if (values.PackageCutOffCountD != null && values.PackageCutOffCountD != 0) {
                var d = new Date(values.PackageValidateEndD);
                d.setDate(d.getDate() - values.PackageCutOffCountD);
                if (d <= new Date(inventoryvalidation.MaxSoldOutDate)) {
                    error.PackageCutOffCount = "You are not allowed to change the date as inventory is already in use";
                    setIsSubmit(false);
                }
            }
        }
        if (values.PackageInventoryCountD.toString() == '0' || values.PackageInventoryCountD.toString().trim().length === 0) {
            error.PackageInventoryCount = "Please enter Inventory Count";
            setIsSubmit(false);
        }
        if (inventoryvalidation.InventoryCount > 0) {
            if (values.PackageInventoryCountD < inventoryvalidation.InventoryCount) {
                error.PackageInventoryCount = "Inventory can not be decreased lesser than max inventory sold";
                setIsSubmit(false);
            }
        }
        if (values.PackageCutOffCountD === null) {
            error.PackageCutOffCount = "Please enter CutOff Count";
            setIsSubmit(false);
        }
        if (values.PackageCutOffCountD !== null) {
            if (values.PackageCutOffCountD > 100 || values.PackageCutOffCountD < 0) {
                error.PackageCutOffCount = "Package Cut off days should be between 0 to 100";
                setIsSubmit(false);
            }
        }
        if (values.PackageHighlights.trim().length === 0) {
            error.PackageHighlights = "Please enter Package Highlights";
            setIsSubmit(false);
        }
        // package highlights field has max length i.e., infinite length //
        // if (values.PackageHighlights.trim().length > 250) {
        //     error.PackageHighlights = "Maximum length is 250 character.";
        //     setIsSubmit(false);
        // }
        //const gallerycount = galleridlist?.
        //const eqSet = (xs, ys) => [...galleryimgpath].every((x) => ys.includes(x));
        // const bannercount = bannerimgpath.filter(obj => obj.GalleryId !== 0)
        if (bannerimgpath === '') {
            error.BannerImgPath = "Please select Banner";
            setIsSubmit(false);
        }
        const galleridcount = galleridlist?.filter(obj => obj.Id);
        // const galleridcount = galleridlist?.filter(obj => obj.Id === 0);
        if (galleridcount.length === 0) {
            // if (galleridcount.length > 0) {
            error.GalleryCount = "Please select at least one Gallery";
            setIsSubmit(false);
        }
        const inclusioncount = datainclusiondetails?.filter((obj) => obj.SeqNo > 0);
        if (inclusioncount.length === 0) {
            error.InclusionError = 'Please select at least one Inclusion';
            setIsSubmit(false);
        }

        const exclsuioncount = dataexclusiondetails?.filter((obj) => obj.SeqNo > 0);
        if (exclsuioncount.length === 0) {
            error.ExclusionError = 'Please select at least one Exclusion';
            setIsSubmit(false);
        }

        const pricecount = menulist?.filter((obj) => obj.PackageMenuId === 9 && obj.IsDefault === true);
        if (pricecount.length === 1) {
            if (values.ChildrenPrice === null) {
                error.ChildrenPrice = 'Kindly add pricing for children';
                setIsSubmit(false);
            }
        }

        const policymenuselection = menulist?.filter((obj) => obj.PackageMenuId === 5 && obj.IsDefault === true)
        const policycount = datapolicydetails?.filter((obj) => obj.SeqNo > 0);

        if (policymenuselection.length === 1 && policycount.length === 0) {
            error.PolicyError = 'Please enter at least one policy';
            setIsSubmit(false);
        }

        const overviewcount = menulist?.filter((obj) => obj.PackageMenuId === 1 && obj.IsDefault === true);
        if (overviewcount.length === 1) {
            if (durationid === 0) {
                error.PackageDuration = "Please select package duration.";
                setIsSubmit(false);
            }
            if (visitfromdurationid === 0) {
                error.BestTimeVisitFrom = "Please select Best time visit from.";
                setIsSubmit(false);
            }
            if (visittodurationid === 0) {
                error.BestTimeToVisit = "Please select Best time visit to.";
                setIsSubmit(false);
            }
            if (visitpointfromid === 0) {
                error.PackageStartPoint = "Please select Package start point.";
                setIsSubmit(false);
            }
            if (visitpointtoid === 0) {
                error.PackageEndPoint = "Please select Package end point.";
                setIsSubmit(false);
            }
            const themeidcount = themeidlist?.filter(obj => obj.Id > 0);
            if (themeidcount.length === 0) {
                error.ThemeList = 'Please select package theme';
                setIsSubmit(false);
            }
            if (overviewCityDetails?.length === 0) {
                error.overviewCityDetail = 'Please select city with required no. of nights';
                setIsSubmit(false);
            }
            if (overviewCityDetails?.length > 0 && curTotalNight !== totalNights) {
                if (curTotalNight < totalNights) {
                    if ((totalNights - curTotalNight) > 1) {
                        error.totalNightsPending = 'Kindly add ' + JSON.stringify(totalNights - curTotalNight) + ' nights more';
                    }
                    else {
                        error.totalNightsPending = 'Kindly add ' + JSON.stringify(totalNights - curTotalNight) + ' night more';
                    }
                }
                else {
                    if (totalNights > 1) {
                        error.totalNightsPending = 'Only ' + JSON.stringify(totalNights) + ' nights are allowed';
                    }
                    else {
                        error.totalNightsPending = 'Only ' + JSON.stringify(totalNights) + ' night are allowed';
                    }
                }
                setIsSubmit(false);
            }
        }
        //obj.SeqNo
        const aboutmenucount = menulist?.filter((obj) => obj.PackageMenuId === 7 && obj.IsDefault === true)
        const aboutmenudatacount = datacitydetails?.filter((obj) => obj.SeqNo > 0);

        if (aboutmenucount.length === 1 && aboutmenudatacount.length === 0) {
            error.AboutPlaceError = 'Please enter about place detail';
            setIsSubmit(false);
        }

        const itinerarymenucount = menulist?.filter((obj) => obj.PackageMenuId === 2 && obj.IsDefault === true)
        const itinerarydatacount = dataitinerary?.filter((obj) => obj.SeqNo > 0)

        if (itinerarymenucount.length === 1 && itinerarydatacount.length === 0) {
            error.ItineraryError = 'Please enter itinerary detail';
            setIsSubmit(false);
        }
        if (gsttype === 'GST' && (values.PackageFixPrice < 1 || values.PackageFixPrice > 99.99)) {
            error.MarkupvalueError = 'Entered percentage should be 1 < value < 100';
            setIsSubmit(false);
        }
        // if(itinerarymenucount.length===1 && itinerarydatacount.length > 0 && (Number(formvalue.PackageMarkupValue) === 0 || formvalue.PackageMarkupValue === ''))
        // {
        //     error.MarkupvalueError = 'Please enter MarkupValue';
        //     setIsSubmit(false);
        // }
        return error;
    }
    //#endregion

    //#region  handle image upload to server and delete
    const handleimageupload = async (pathdirectory, filename, file) => {
        await ImageUpload(pathdirectory, filename, file);
    }
    const handleimageDelete = async (filename) => {
        await ImageDelete(filename);
    }
    //#endregion

    //#region retrive data


    const [retrivedata, setRetriveData] = useState([]);

    const handleRetrive = async () => {
        //setButtonAddEditText("Update");

        const PackageId = packageD.state.PackageId;
        const PackageById = await postUsersData('/master/v1/getpackagebyid', { PackageId });
        setRetriveData(PackageById.data);

    }


    useEffect(() => {
        if (retrivedata?.length === 1 && menulist?.length > 0) {
            RetriveData();
            // dispatch(loaderActions.end());
        }
    }, [retrivedata]);


    const RetriveData = () => {
        //document.getElementById("txtcityimages").value = "";
        retrivedata?.map(obj => {

            formvalue.PackageId = obj.packageId
            formvalue.PackageName = obj.packageName
            formvalue.PackageCode = obj.packageCode
            formvalue.PackageCategoryId = obj.packageCategoryId
            formvalue.StateId = obj.stateId
            formvalue.LocationId = obj.locationId
            formvalue.PackageTypeId = Number(obj.packageTypeId)
            formvalue.PackageLevelId = Number(obj.packageLevelId)
            formvalue.IsBestPackage = obj.isBestPackage
            formvalue.ArrivalSlotId = obj.arrivalSlotId
            formvalue.DepartureSlotId = obj.departureSlotId
            formvalue.PackageValidateStart = obj.packageValidateStart
            formvalue.PackageValidateEnd = obj.packageValidateEnd
            let stateOption = []
            let cityOption = []
            obj.stateId?.map(val => {
                statelist?.filter(id => {
                    if (val === id.value) {
                        stateOption.push(id)
                    }
                }
                )
            })
            obj.locationId?.map(val => {
                cityData?.filter(id => {
                    if (val === id.value) {
                        cityOption.push(id)
                    }
                }
                )
            })
            setSelectedValue(stateOption)
            setSelectedCityValue(cityOption)
            // setSelectedValue(obj.stateId)
            // setSelectedCityValue(obj.locationId)
            const overviewCity = nightallcity?.filter((value) => obj.locationId?.includes(value.LocationId))?.map((ele) => ({
                label: ele.City,
                value: ele.LocationId
            }))
            setOverviewCityList(overviewCity)

            const nightid = durationlist?.map(id => {
                if (id.DurationId === obj.packageOverview[0]?.durationId) {
                    return id.NightId
                }
            }).find(x => x != undefined ? x : '')
            setTotalNights(nightid)

            const nightlistdata = durationlist?.filter(obj => obj.NightId <= nightid)?.map((value) => ({
                label: value.Night,
                value: value.NightId
            }));
            setNightList(nightlistdata)

            if (obj.packageValidateStart !== null) {
                formvalue.PackageValidateStartD = formatDate(obj.packageValidateStart)
                setPackageValidateStart(new Date(formatDate(obj.packageValidateStart)))
            }
            if (obj.packageValidateEnd !== null) {
                formvalue.PackageValidateEndD = formatDate(obj.packageValidateEnd)
                setPackageValidateEnd(new Date(formatDate(obj.packageValidateEnd)))
            }
            formvalue.PackageInventoryCount = obj.packageInventoryCount
            formvalue.PackageCutOffCount = obj.packageCutOffCount
            if (obj.packageInventoryCount === 0) {
                formvalue.PackageInventoryCountD = ''
            }
            else {
                formvalue.PackageInventoryCountD = obj.packageInventoryCount
            }
            if (obj.packageCutOffCount === null) {
                formvalue.PackageCutOffCountD = ''
            }
            else {
                formvalue.PackageCutOffCountD = obj.packageCutOffCount
            }


            formvalue.ChildrenPrice = obj.childrenPrice
            setChildPrice(obj.childrenPrice)
            formvalue.PackageHighlights = obj.packageHighlights
            // {formvalue.packageBasicPrice = obj.packageBasicPrice}
            formvalue.IsActive = obj.isActive
            formvalue.IsDraft = obj.isDraft
            formvalue.PackageBanner = obj.packageBanner
            formvalue.PackageGallery = obj.packageGallery

            formvalue.PackageMenu = obj.packageMenu
            formvalue.PackageInclusion = obj.packageInclusion
            formvalue.PackageExclusion = obj.packageExclusion
            formvalue.PackagePolicy = obj.packagePolicy
            formvalue.PackageOverview = obj.packageOverview
            formvalue.PackagePlace = obj.packagePlace
            formvalue.PackageSummary = obj.packageSummary

            formvalue.IsBestPackage = obj.isBestPackage
            formvalue.IsHotDeals = obj.isHotDeals
            formvalue.HotOfferPercentage = obj.hotOfferPercentage
            formvalue.IsInternationalDestination = obj.isInternationalDestination
            formvalue.IsBestDestination = obj.isBestDestination
            formvalue.IsTrendingHoliday = obj.isTrendingHoliday
            formvalue.PackageItinerary = obj.packageItinerary

            formvalue.PackageBasicPrice = obj.packageBasicPrice
            formvalue.PackageMarkup = obj.packageMarkup
            formvalue.PackageMarkupValue = (obj.packageMarkupValue === null ? "0" : obj.packageMarkupValue.toString())
            formvalue.PackageFixPrice = obj.packageMarkupValue
            formvalue.PackageFinalValue = obj.packageFinalValue
            formvalue.PackageFinalPrice = obj.packageFinalValue
            formvalue.GST = 1
            formvalue.PackageSummary = obj.packageSummary
            setInventoryValidation({ ...inventoryvalidation, InventoryCount: obj.inventoryCount, MinSoldoutDate: obj.minSoldoutDate, MaxSoldOutDate: obj.maxSoldOutDate })
            //setGstType(obj.packageMarkup);


            if (formvalue.PackageOverview?.length > 0) {
                if (formvalue.PackageOverview[0].packageStartPointLocationId > 0) {
                    // Start to End point Dropdown
                    let allpointlist = allstartpoint?.filter(IsAct => IsAct.IsActive === true);
                    setPackageoint(allstartpoint?.filter(IsAct => IsAct.IsActive === true));
                    setStartPointDisabled(false);
                    if (!allpointlist?.find(IsStartPoint => IsStartPoint.LocationId === formvalue.PackageOverview[0].packageStartPointLocationId)) {
                        setStartPointDisabled(true)
                        setPackageoint((prvStartdata) => prvStartdata?.length > 0 ? [...prvStartdata, allstartpoint?.find(isStartPoint => isStartPoint.LocationId === formvalue.PackageOverview[0].packageStartPointLocationId)] : [])

                    }
                }
                if (formvalue.PackageOverview[0].packageEndPointLocationId > 0) {
                    let allEndpointlist = allendpoint?.filter(IsAct => IsAct.IsActive === true);
                    setEndPackageoint(allendpoint?.filter(IsAct => IsAct.IsActive === true));
                    setEndPointDisabled(false);
                    if (!allEndpointlist?.find(IsEndPoint => IsEndPoint.LocationId === formvalue.PackageOverview[0].packageEndPointLocationId)) {
                        setEndPointDisabled(true)
                        setEndPackageoint((prvEnddata) => prvEnddata?.length > 0 ? [...prvEnddata, allendpoint?.find(isEndPoint => isEndPoint.LocationId === formvalue.PackageOverview[0].packageEndPointLocationId)] : [])

                    }
                }


            }

        });
        setPkgFinalPrice(formvalue.PackageFinalPrice);
        setGstId(1);
        setGst(5)
        if (formvalue.PackageMarkup && formvalue.PackageMarkup !== null) {
            setGstType(formvalue.PackageMarkup);
        }
        else {
            setGstType('FIX');
        }
        if ((formvalue.PackageTypeId) === 1) {
            setFlightVisibled(false);
            clearFlightDetail();
        } else { setFlightVisibled(true) }


        setBestPackage(formvalue.IsBestPackage);
        setHotDeals(formvalue.IsHotDeals)
        //setHotOfferPercentage(formvalue.HotOfferPercentage);
        setIsInternationalDestination(formvalue.IsInternationalDestination);
        setIsBestDestination(formvalue.IsBestDestination);
        setIsTrendingHoliday(formvalue.IsTrendingHoliday);
        setStateId(formvalue.StateId);
        setActiveId(formvalue.IsActive);
        setArrivalSlotId(formvalue.ArrivalSlotId);
        setDepartureSlotId(formvalue.DepartureSlotId);
        setPackageCategoryId(formvalue.PackageCategoryId);
        const bannerid = formvalue.PackageBanner?.map((obj) => {
            return obj.bannerId;
        }) || [];
        setBannerId(bannerid[0]);
        banneridlist.BannerId = bannerid[0]

        if (formvalue.PackageBanner?.length > 0) {
            let allbannerlist = allbanner?.filter(IsAct => IsAct.IsActive === true);
            setBannerList(allbanner?.filter(IsAct => IsAct.IsActive === true));
            setBannerDisabled(false);
            if (!allbannerlist?.find(IsBannerName => IsBannerName.BannerId === formvalue.PackageBanner[0].bannerId)) {
                setBannerDisabled(true)
                setBannerList((prvBannerdata) => prvBannerdata?.length > 0 ? [...prvBannerdata, allbanner?.find(isBanner => isBanner.BannerId === formvalue.PackageBanner[0].bannerId)] : [])

            }
            allbanner?.filter(isBanner => isBanner.BannerId === bannerid[0]).map((value) => {
                setBannerImgPath('https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + value.BannerPath);
            })
        }
        // bannerlist?.filter((obj) => obj.BannerId === bannerid[0]).map((value) => {
        //     setBannerImgPath('https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + value.BannerPath);
        // });

        if (formvalue.PackageGallery?.length > 0) {
            formvalue.PackageGallery?.map((val, index) => {
                const d = gallerylist?.filter((obj) => obj.GalleryId === val.galleryId).map((value) => { return value.ImagePath });
                setGalleryCount(index + 1);
                if (index === 0) {
                    let cnti = index + 1
                    setGalleryIdList([{ GalleryId: val.galleryId, Id: cnti }]);
                    // setGalleryImgPath(current => [{ GalleryId: val.galleryId, GalleryPath: 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + d[0], Id: cnti }]);
                    setGalleryImgPath(current => [{ GalleryId: val.galleryId, GalleryPath: 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + (d !== undefined ? d[0] : ''), Id: cnti }]);
                }
                else {
                    let cntz = index + 1
                    setGalleryIdList(current => [...current, { GalleryId: val.galleryId, Id: cntz }]);
                    // setGalleryImgPath(current => [...current, { GalleryId: val.galleryId, GalleryPath: 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + d[0], Id: cntz }]);
                    setGalleryImgPath(current => [...current, { GalleryId: val.galleryId, GalleryPath: 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + (d !== undefined ? d[0] : ''), Id: cntz }]);
                }
            });
        }
        formvalue.PackageMenu?.map((val, index) => {
            const newList = menulist;
            newList?.filter(e => e.PackageMenuId === val.packageMenuId).map((item) => {
                item.IsDefault = true;
                Shown[item.PackageMenuName] = true;
            });

            setMenuList(newList);
        });


        formvalue.PackageInclusion?.filter((obj) => obj.inclusionId > 0).map((val, index) => {
            const d = inclusionlistdata?.filter((obj) => obj.value === val.inclusionId).map((obj) => { return obj.label })
            // setInclusionCounter(index + 1,()=>{

            // });
            if (index === 0) {
                // alert(cntinclusion)
                let cnti = index + 1
                setInclusionDetails(current => [{ InclsuionId: val.inclusionId, Inclusion: d[0], SeqNo: cnti }]);
                setInclusionCounter(index + 1);
            }
            else {
                let cntz = index + 1
                //alert(cntinclusion)
                setInclusionDetails(current => [...current, { InclsuionId: val.inclusionId, Inclusion: d[0], SeqNo: cntz }]);
                setInclusionCounter(index + 1);
            }
        });

        formvalue.PackageExclusion?.filter((obj) => obj.exclusionId > 0).map((val, index) => {
            const d = exclusionistdata?.filter((obj) => obj.value === val.exclusionId).map((obj) => { return obj.label })

            if (index === 0) {
                //alert('1')
                let cnti = index + 1
                setExclusionDetails(current => [{ SeqNo: cnti, Exclusion: d[0], ExclusionId: val.exclusionId }]);
                setExclusionCounter(index + 1);
            }
            else {
                //alert('2')
                let cntz = index + 1
                setExclusionDetails(current => [...current, { SeqNo: cntz, Exclusion: d[0], ExclusionId: val.exclusionId }]);
                setExclusionCounter(index + 1);
            }

        });

        formvalue.PackagePolicy?.filter((obj) => obj.policyId > 0).map((val, index) => {
            const d = policylistdata?.filter((obj) => obj.value === val.policyId).map((obj) => { return obj.label });

            if (index === 0) {
                //alert('1')
                let cnti = index + 1
                setPolicyDetails(current => [{ SeqNo: cnti, Policy: d[0], PolicyId: val.policyId }]);
                setPolicyCounter(index + 1);
            }
            else {
                //alert('2')
                let cntz = index + 1
                setPolicyDetails(current => [...current, { SeqNo: cntz, Policy: d[0], PolicyId: val.policyId }]);
                setPolicyCounter(index + 1);
            }

        });


        setCheckedValueOfPackageTypeValue(formvalue.PackageTypeId);
        setCheckedValueOfPackageLevelValue(formvalue.PackageLevelId);

        if (formvalue.PackageOverview?.length > 0) {
            // let overerviewtheme = [];
            setDurationId(formvalue.PackageOverview[0].durationId);
            setVisitFromDurationId(formvalue.PackageOverview[0].bestTimeVisitFromId);
            setVisitToDurationId(formvalue.PackageOverview[0].bestTimeVisitToId);
            setVisitFromId(formvalue.PackageOverview[0].packageStartPointLocationId);
            setVisitToId(formvalue.PackageOverview[0].packageEndPointLocationId);
            setOverviewCityDetails(formvalue.PackageOverview[0].packageOverviewCities)

            if (formvalue.PackageOverview[0].packageOverviewTheme?.length > 0) {
                formvalue.PackageOverview[0].packageOverviewTheme?.map((val, index) => {

                    const d = packagetheme?.filter((obj) => obj.ThemeId === val.themeId).map((ele) => {
                        return ele.ImagePath;
                    });
                    if (index === 0) {
                        let cnti = index + 1
                        setThemeIdList([{ ThemeId: val.themeId, Id: cnti }]);
                        setThemeImgPath(current => [{ ThemeId: val.themeId, ThemePath: 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + d[0], Id: cnti }]);
                        setThemeCounter(index + 1);
                    }
                    else {
                        let cntz = index + 1
                        setThemeIdList(current => [...current, { ThemeId: val.themeId, Id: cntz }]);
                        setThemeImgPath(current => [...current, { ThemeId: val.themeId, ThemePath: 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + d[0], Id: cntz }]);
                        setThemeCounter(index + 1);
                    }
                });
            }

        }
        if (formvalue.PackagePlace?.length > 0) {
            formvalue.PackagePlace?.map((val, index) => {
                if (index === 0) {
                    let cnti = index + 1
                    setCityDetails(current => [{
                        SeqNo: cnti, City: val.locationId, CityName: val.city,
                        CityDetails: val.cityDetail, PlaceImageName: val.placeImageName,
                        // PlaceImagePath: 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + val.placeImagePath
                        PlaceImagePath: val.placeImagePath
                    }]);
                    setCityCounter(index + 1);
                }
                else {
                    let cntz = index + 1
                    setCityDetails(current => [...current, {
                        SeqNo: cntz, City: val.locationId, CityName: val.city,
                        CityDetails: val.cityDetail, PlaceImageName: val.placeImageName,
                        // PlaceImagePath: 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + val.placeImagePath
                        PlaceImagePath: val.placeImagePath
                    }]);
                    setCityCounter(index + 1);
                }
            });

        }

        // let lenofitinerary = formvalue.PackageItinerary?.length;
        // let itincnt = 0;
        if (formvalue.PackageItinerary?.length > 0) {
            formvalue.PackageItinerary?.map((val, index) => {

                if (index === 0) {
                    let intiti = index + 1;
                    setDataItinerary(current =>
                        [{
                            SeqNo: intiti,
                            Day: val.day,
                            DaySchedule: val.daySchedule,
                            FlightFromLocationId: val.flightFromLocationId,
                            FlightToLocationId: val.flightToLocationId,
                            FlightNo: val.flightNo,
                            ArrivalTime: (val.arrivalTime) !== '1900-01-01T00:00:00' ? val.arrivalTime : null,
                            DepartureTime: (val.departureTime) !== '1900-01-01T00:00:00' ? val.departureTime : null,
                            ArrivalTimeD: (val.arrivalTime) !== '1900-01-01T00:00:00' ? formatTime(val.arrivalTime) : '',
                            DepartureTimeD: (val.departureTime) !== '1900-01-01T00:00:00' ? formatTime(val.departureTime) : '',
                            ClassId: val.classId,
                            SupplierId: val.supplierId,
                            FlightPrice: Number(val.flightPrice),
                            HotelId: val.hotelId,
                            RoomTypeId: val.roomTypeId,
                            NoOfPax: val.noOfPax,

                            HotelPrice: val.hotelPrice,
                            SightseeingId: val.sightseeingId,
                            VehicleId: val.vehicleId,
                            SightseeingPrice: val.sightseeingPrice,
                            AirlineId: val.airlineId
                        }]);
                    setDataItineraryCounter(index + 1);
                }
                else {
                    let intitiz = index + 1;
                    setDataItinerary(current =>
                        [...current, {
                            SeqNo: intitiz,
                            Day: val.day,
                            DaySchedule: val.daySchedule,
                            FlightFromLocationId: val.flightFromLocationId,
                            FlightToLocationId: val.flightToLocationId,
                            FlightNo: val.flightNo,
                            ArrivalTime: (val.arrivalTime) !== '1900-01-01T00:00:00' ? val.arrivalTime : null,
                            DepartureTime: (val.departureTime) !== '1900-01-01T00:00:00' ? val.departureTime : null,
                            ArrivalTimeD: (val.arrivalTime) !== '1900-01-01T00:00:00' ? formatTime(val.arrivalTime) : '',
                            DepartureTimeD: (val.departureTime) !== '1900-01-01T00:00:00' ? formatTime(val.departureTime) : '',
                            ClassId: val.classId,
                            SupplierId: val.supplierId,
                            FlightPrice: Number(val.flightPrice),
                            HotelId: val.hotelId,
                            RoomTypeId: val.roomTypeId,
                            NoOfPax: val.noOfPax,

                            HotelPrice: val.hotelPrice,
                            SightseeingId: val.sightseeingId,
                            VehicleId: val.vehicleId,
                            SightseeingPrice: val.sightseeingPrice,
                            AirlineId: val.airlineId
                        }]);
                    setDataItineraryCounter(index + 1);
                }
            })


        }

        if (formvalue.PackageSummary?.length > 0) {
            setPkgSummary(formvalue.PackageSummary)
        }

    }

    useEffect(() => {

    }, [checkedvalueofpackagetypevalue, checkedvalueofpackagelevelvalue, defcheckedvalueofpackagelevel, defcheckedvalueofpackagetype]);

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return date === null ? null : [year, month, day].join('-');
    }
    //#endregion

    //#region  Package type and level change handle
    const handlePackageLevelChange = (id, e) => {

        formvalue.PackageLevelId = Number(e.target.value)
        //setCheckedValueOfPackageLevel(e.target.value);
        setEditDisabled(false);



        const newList = packagelevellist?.map((item) => {


            if (item.PackageLevelId === id) {
                const updatedItem = {
                    ...item,
                    IsDefualt: !item.IsDefualt,
                };
                //const list = handleotherradio(id,updatedItem);
                return updatedItem;
            }

            return item;
        });
        setPackageLevelList(newList);
        setCheckedValueOfPackageLevelValue(id);
        setPackageLevelCounter(counterpackgelevel + 1);

    }
    const handlePackageTypeChange = (id, e) => {
        formvalue.PackageTypeId = Number(e.target.value)
        //setCheckedValueOfPackageType(e.target.value);
        setEditDisabled(false);

        if ((e.target.value) === 1) {
            setFlightVisibled(false);
            clearFlightDetail();
        } else { setFlightVisibled(true) }

        const newList = packagetypelist?.map((item) => {


            if (item.PackageTypeId === id) {
                const updatedItem = {
                    ...item,
                    IsDefualt: !item.IsDefualt,
                };
                //const list = handleotherradio(id,updatedItem);
                return updatedItem;
            }

            return item;
        });
        setPackageTypeList(newList);
        setCheckedValueOfPackageTypeValue(id);
        setpackagetypecounter(counterpackagetype + 1);
    }


    const [counterpackgelevel, setPackageLevelCounter] = useState(0);
    const [counterpackagetype, setpackagetypecounter] = useState(0);


    const handlepackagetyperadio = (id) => {
        const newList = packagetypelist?.map((item) => {


            if (item.PackageTypeId !== id) {
                const updatedItem = {
                    ...item,
                    IsDefualt: false,
                };
                //const list = handleotherradio(id,updatedItem);
                return updatedItem;
            }

            return item;
        });
        //alert(JSON.stringify(newList));
        setPackageTypeList(newList);
    }
    const handlepackagelevelradio = (id) => {
        const newList = packagelevellist?.map((item) => {


            if (item.PackageLevelId !== id) {
                const updatedItem = {
                    ...item,
                    IsDefualt: false,
                };
                //const list = handleotherradio(id,updatedItem);
                return updatedItem;
            }

            return item;
        });
        //alert(JSON.stringify(newList));
        setPackageLevelList(newList);
    }
    useEffect(() => {
        if (counterpackagetype > 0)
            handlepackagetyperadio(checkedvalueofpackagetypevalue);
    }, [counterpackagetype]);

    useEffect(() => {
        if (counterpackgelevel > 0)
            handlepackagelevelradio(checkedvalueofpackagelevelvalue);
    }, [counterpackgelevel]);
    //#endregion

    //#region iteninory section 
    const [flightfromid, setFlightFromId] = useState(0);
    const [flighttoid, setFlightToId] = useState(0);
    const [airlinelist, setAirlineList] = useState([]);
    const [airlineid, setAirlineId] = useState(0);
    const [flightnolist, setFlightNoList] = useState([]);
    const [flightno, setFlightNo] = useState('');
    const [supplierlist, setSupplierList] = useState([]);
    const [airlineclasslist, setAirlineClassList] = useState([]);
    const [flightdata, setFlightData] = useState([]);
    const [classid, setClassId] = useState(0);
    const [supplierid, setSupplierId] = useState(0);
    const [itineraryid, setItineraryId] = useState(0);
    //const [fixprice , setFixPrice] = useState(0);

    const Itinerary = useState([{
        SeqNo: 0,
        Day: '',
        DaySchedule: '',
        FlightFromLocationId: 0,
        FlightToLocationId: 0,
        FlightNo: '',
        ArrivalTime: null,
        DepartureTime: null,
        ArrivalTimeD: "",
        DepartureTimeD: "",
        ClassId: 0,
        SupplierId: 0,
        FlightPrice: 0,
        HotelId: 0,
        RoomTypeId: 0,
        NoOfPax: 0,
        HotelPrice: 0,
        SightseeingId: 0,
        VehicleId: 0,
        SightseeingPrice: 0,
        AirlineId: 0
    }])
    const [dataitinerary, setDataItinerary] = useState(Itinerary);
    const [dataitinerarycounter, setDataItineraryCounter] = useState(0);
    const [itinerarybtntext, setItineraryBtnText] = useState('Add');
    //const [flightdeparturetime ,setDepartureTime] = useState()

    const handleFlightFromchange = (e) => {
        setFlightFromId(e.value);
        if (flighttoid > 0) {
            resetflightselection();
            //clearFlightDetail();
            getAirlineData(e.value, flighttoid);
        }
    }
    const handleFlightToChange = (e) => {
        setFlightToId(e.value);
        if (flightfromid > 0) {
            resetflightselection();
            //clearFlightDetail();
            getAirlineData(flightfromid, e.value);
        }
    }
    const getAirlineData = async (fromid, toid) => {
        const airlinelistD = await getAllData('/master/v1/getpackageairlinebydstarv?dstid=' + fromid + '&arvid=' + toid);
        const airlinedatalist = airlinelistD.data?.map((value) => ({
            value: value.AirlineId,
            label: value.AirlineName
        })) || [];
        //resetflightselection();
        setAirlineList(airlinedatalist);
    }
    const resetflightselection = () => {

        setAirlineId(0);
        setFlightData([]);
        setFlightNoList([]);
        setFlightNo('');
        formvalue.DepartureTime = null
        formvalue.AirrivalTime = null
        formvalue.DepartureTimeD = ''
        formvalue.ArrivalTimeD = ''

        setArrivalTime("--:--")
        setDepartureTime("--:--")
        setHoursTime("--:--");
        formvalue.Duration = ''
        formvalue.FlightPrice = ''
        setSupplierList([]);
        setAirlineClassList([]);
        setAirlineList([]);

    }
    const resetflightairlineselection = () => {

        // setAirlineId(0);
        // setFlightData([]);
        // setFlightNoList([]);
        setFlightNo('');
        formvalue.DepartureTime = null
        formvalue.AirrivalTime = null
        formvalue.DepartureTimeD = ''
        formvalue.ArrivalTimeD = ''

        setArrivalTime("--:--")
        setDepartureTime("--:--")
        setHoursTime("--:--");
        // {formvalue.Duration = ''}
        formvalue.FlightPrice = ''
        setSupplierList([]);
        setAirlineClassList([]);
        // setAirlineList([]);

    }
    const handleAirlineChange = (e) => {
        if (airlineid !== e.value) {
            resetflightairlineselection();
        }
        setAirlineId(e.value);
        getflightnodata(e.value);
    }


    const getflightnodata = async (id) => {
        const flightnolistD = await getAllData('/master/v1/getpackageairlinedetail?airlineId=' + id + '&dstid=' + flightfromid + '&arvid=' + flighttoid);
        const flightlistdata = flightnolistD.data?.map((value) => ({
            value: value.FlightNo,
            label: value.FlightNo
        })) || []
        setFlightData(flightnolistD.data);
        setFlightNoList(flightlistdata);
    }

    const handleFlightNoChange = (e) => {
        setFlightNo(e.value);
        flightdata?.filter(obj => obj.FlightNo === e.value).map((value) => {
            formvalue.DepartureTime = formatTime(value.DepartureTime)
            formvalue.AirrivalTime = formatTime(value.AirrivalTime)
            formvalue.DepartureTimeD = formatTime(value.DepartureTime)
            formvalue.ArrivalTimeD = formatTime(value.AirrivalTime)
            setArrivalTime(formatTime(value.AirrivalTime))
            setDepartureTime(formatTime(value.DepartureTime))
            formvalue.Duration = value.Duration

            const supplierlist = flightdata?.filter(obj => obj.FlightNo === e.value).map((value) => ({
                value: value.SupplierId,
                label: value.SupplierName
            })) || [];

            const classlist = flightdata?.filter(obj => obj.FlightNo === e.value).map((value) => ({
                value: value.AirlineClassId,
                label: value.AirlineClassName
            })) || [];

            setSupplierList(supplierlist);
            setAirlineClassList(classlist);
        })
    }
    const handleclasschange = (e) => {
        setClassId(e.value)
    }
    const handlesupplierchange = (e) => {
        setSupplierId(e.value);
    }

    const handleitinerary = (e) => {

        const error = {}
        if (formvalue.Day.trim() !== '' && formvalue.DaySchedule.trim() !== '') {

            // if (formvalue.Day.trim().length > 500 || formvalue.DaySchedule.trim().length > 5000) {
            if (formvalue.Day.trim().length > 500) {
                if (formvalue.Day.length > 500) {
                    error.ItineraryDayError = 'Maximum character limit is 500 characters.';
                    setFormError(error);
                }
                // alert(formvalue.DaySchedule.length)
                // if (formvalue.DaySchedule.trim().length > 5000) {
                //     error.ItineraryDayScheduleError = 'Maximum character limit is 5000 characters.';
                //     setFormError(error);
                // }

                return;
                // setIsSubmit(false);
            }
            else {
                setDataItineraryCounter(dataitinerarycounter + 1);
            }

        }
        // if (formvalue.Day !== '' && formvalue.DaySchedule !== '')
        // {

        // }
        else {
            error.ItineraryError = 'Please enter all mandatory fields';
            setFormError(error);
            return;
        }
    }

    const AddItineraryData = () => {
        if (packageD.state.PackageId === 0) {

            if (itinerarybtntext === 'Add') {
                let Day = formvalue.Day
                let DayScheduleD = formvalue.DaySchedule
                let AirrivalTime = formvalue.ArrivalTimeD
                let DepartureTime = formvalue.DepartureTimeD
                let HotelPrice = formvalue.HotelPrice
                let SightseeingId = formvalue.SightseeingId
                let FlightPrice = formvalue.FlightPrice
                let fromid = flightfromid;
                let toid = flighttoid;
                let supid = supplierid;
                let clsid = classid;
                let vehitypeid = vehicaltypeid;
                let price = vehicalprice;

                if (dataitinerarycounter === 1) {
                    setDataItinerary(current =>
                        [{
                            SeqNo: dataitinerarycounter, Day: Day,
                            DaySchedule: DayScheduleD,
                            FlightFromLocationId: fromid,
                            FlightToLocationId: toid,
                            FlightNo: flightno ? flightno : "",
                            ArrivalTime: (AirrivalTime !== null && AirrivalTime !== '' && AirrivalTime !== '--:--') ? "2022-11-10T" + AirrivalTime + ":00" : null,
                            ArrivalTimeD: (AirrivalTime !== null && AirrivalTime !== '' && AirrivalTime !== '--:--') ? "2022-11-10T" + AirrivalTime + ":00" : null,
                            DepartureTime: (DepartureTime !== null && DepartureTime !== '' && DepartureTime !== '--:--') ? "2022-11-10T" + DepartureTime + ":00" : null,
                            DepartureTimeD: (DepartureTime !== null && DepartureTime !== '' && DepartureTime !== '--:--') ? "2022-11-10T" + DepartureTime + ":00" : null,
                            ClassId: clsid,
                            SupplierId: supid,
                            FlightPrice: Number(FlightPrice),
                            HotelId: hotelnameid,
                            RoomTypeId: hotelroomtypeid,
                            NoOfPax: noofpaxid,

                            HotelPrice: HotelPrice ? HotelPrice : 0,
                            SightseeingId: SightseeingId,
                            VehicleId: vehitypeid,
                            SightseeingPrice: price === "" ? 0 : price,
                            AirlineId: Number(airlineid)
                        }]);
                }

                else {
                    setDataItinerary(current => [...current, {
                        SeqNo: dataitinerarycounter, Day: Day,
                        DaySchedule: DayScheduleD,
                        FlightFromLocationId: fromid,
                        FlightToLocationId: toid,
                        FlightNo: flightno ? flightno : "",
                        ArrivalTime: (AirrivalTime !== null && AirrivalTime !== '' && AirrivalTime !== '--:--') ? "2022-11-10T" + AirrivalTime + ":00" : null,
                        ArrivalTimeD: (AirrivalTime !== null && AirrivalTime !== '' && AirrivalTime !== '--:--') ? "2022-11-10T" + AirrivalTime + ":00" : null,
                        DepartureTime: (DepartureTime !== null && DepartureTime !== '' && DepartureTime !== '--:--') ? "2022-11-10T" + DepartureTime + ":00" : null,
                        DepartureTimeD: (DepartureTime !== null && DepartureTime !== '' && DepartureTime !== '--:--') ? "2022-11-10T" + DepartureTime + ":00" : null,
                        ClassId: clsid,
                        SupplierId: supid,
                        FlightPrice: Number(FlightPrice),
                        HotelId: hotelnameid,
                        RoomTypeId: hotelroomtypeid,
                        NoOfPax: noofpaxid,

                        HotelPrice: HotelPrice ? HotelPrice : 0,
                        SightseeingId: SightseeingId,
                        VehicleId: vehitypeid,
                        SightseeingPrice: price === "" ? 0 : price,
                        AirlineId: Number(airlineid)
                    }]);
                    // setDataItinerary(current => [...current , {SeqNo:dataitinerarycounter,Day:formvalue.Day , DaySchedule: formvalue.DaySchedule , 
                    //     FlightToLocationId:flightfromid, FlightToLocationId : flighttoid,
                    //     ArrivalTime : formvalue.AirrivalTime , DepartureTime : formvalue.DepartureTime, ClassId : classid,
                    //     SupplierId : supplierid , FlightPrice : formvalue.HotelPrice , SightseeingId : formvalue.SightseeingId,
                    //     VehicleId : vehicaltypeid , SightseeingPrice : vehicalprice}]);
                }


            }
            else {
                if (itineraryid > 0) {
                    handleeditofitinerary(itineraryid);
                }

            }
        }
        else {
            let dinc = dataitinerary?.filter(obj => obj.SeqNo > 0);

            let len = dinc.length;
            if (itineraryid === 0 && formvalue.Day.trim() !== '' && formvalue.DaySchedule.trim() !== '') {
                if (len === 0) {
                    setDataItinerary(current => [{
                        SeqNo: dataitinerarycounter,
                        Day: formvalue.Day,
                        DaySchedule: formvalue.DaySchedule,
                        FlightFromLocationId: flightfromid,
                        FlightToLocationId: flighttoid,
                        FlightNo: flightno ? flightno : "",
                        ArrivalTime: (formvalue.ArrivalTimeD !== null && formvalue.ArrivalTimeD !== '' && formvalue.ArrivalTimeD !== '--:--') ? "2022-11-10T" + formvalue.ArrivalTimeD + ":00" : null,
                        DepartureTime: (formvalue.DepartureTimeD !== null && formvalue.DepartureTimeD !== '' && formvalue.DepartureTimeD !== '--:--') ? "2022-11-10T" + formvalue.DepartureTimeD + ":00" : null,
                        ArrivalTimeD: (formvalue.ArrivalTimeD !== null && formvalue.ArrivalTimeD !== '' && formvalue.ArrivalTimeD !== '--:--') ? "2022-11-10T" + formvalue.ArrivalTimeD + ":00" : null,
                        DepartureTimeD: (formvalue.DepartureTimeD !== null && formvalue.DepartureTimeD !== '' && formvalue.DepartureTimeD !== '--:--') ? "2022-11-10T" + formvalue.DepartureTimeD + ":00" : null,
                        ClassId: classid,
                        SupplierId: supplierid,
                        FlightPrice: Number(formvalue.FlightPrice),
                        HotelId: hotelnameid,
                        RoomTypeId: hotelroomtypeid,
                        NoOfPax: noofpaxid,

                        HotelPrice: formvalue.HotelPrice ? formvalue.HotelPrice : 0,
                        SightseeingId: formvalue.SightseeingId,
                        VehicleId: vehicaltypeid,
                        SightseeingPrice: vehicalprice === "" ? 0 : vehicalprice,
                        AirlineId: Number(airlineid)
                    }])
                    //setCityDetails(current => [{ SeqNo: datacitycounter, City: formvalue.CityD,CityName : formvalue.CityName, CityDetails: formvalue.CityDetailsD , PlaceImageName: placeimagename ,PlaceImagePath : placeimagepath , PlaceImage: fileselected }]);
                }
                else {
                    setDataItinerary(current => [...current, {
                        SeqNo: dataitinerarycounter,
                        Day: formvalue.Day,
                        DaySchedule: formvalue.DaySchedule,
                        FlightFromLocationId: flightfromid,
                        FlightToLocationId: flighttoid,
                        FlightNo: flightno ? flightno : "",
                        ArrivalTime: (formvalue.ArrivalTimeD !== null && formvalue.ArrivalTimeD !== '' && formvalue.ArrivalTimeD !== '--:--') ? "2022-11-10T" + formvalue.ArrivalTimeD + ":00" : null,
                        DepartureTime: (formvalue.DepartureTimeD !== null && formvalue.DepartureTimeD !== '' && formvalue.DepartureTimeD !== '--:--') ? "2022-11-10T" + formvalue.DepartureTimeD + ":00" : null,
                        ArrivalTimeD: (formvalue.ArrivalTimeD !== null && formvalue.ArrivalTimeD !== '' && formvalue.ArrivalTimeD !== '--:--') ? "2022-11-10T" + formvalue.ArrivalTimeD + ":00" : null,
                        DepartureTimeD: (formvalue.DepartureTimeD !== null && formvalue.DepartureTimeD !== '' && formvalue.DepartureTimeD !== '--:--') ? "2022-11-10T" + formvalue.DepartureTimeD + ":00" : null,
                        ClassId: classid,
                        SupplierId: supplierid,
                        FlightPrice: Number(formvalue.FlightPrice),
                        HotelId: hotelnameid,
                        RoomTypeId: hotelroomtypeid,
                        NoOfPax: noofpaxid,

                        HotelPrice: formvalue.HotelPrice ? formvalue.HotelPrice : 0,
                        SightseeingId: formvalue.SightseeingId,
                        VehicleId: vehicaltypeid,
                        SightseeingPrice: vehicalprice === "" ? 0 : vehicalprice,
                        AirlineId: Number(airlineid)
                    }])
                }


            }
            else if (itineraryid > 0 && formvalue.Day.trim() !== '' && formvalue.DaySchedule.trim() !== '') {

                handleeditofitinerary(itineraryid);
                setEditDisabled(false);
                reseterrormessage();
            }
        }

    }
    const handleeditofitinerary = (id) => {

        const newList = dataitinerary?.map((item) => {

            if (item.SeqNo === id) {
                const updatedItem = {
                    ...item,
                    Day: formvalue.Day,
                    DaySchedule: formvalue.DaySchedule,
                    FlightFromLocationId: flightfromid,
                    FlightToLocationId: flighttoid,
                    FlightNo: flightno ? flightno : "",
                    ArrivalTime: (formvalue.ArrivalTimeD !== null && formvalue.ArrivalTimeD !== '' && formvalue.ArrivalTimeD !== '--:--') ? "2022-11-10T" + formvalue.ArrivalTimeD + ":00" : null,
                    DepartureTime: (formvalue.DepartureTimeD !== null && formvalue.DepartureTimeD !== '' && formvalue.DepartureTimeD !== '--:--') ? "2022-11-10T" + formvalue.DepartureTimeD + ":00" : null,
                    ArrivalTimeD: (formvalue.ArrivalTimeD !== null && formvalue.ArrivalTimeD !== '' && formvalue.ArrivalTimeD !== '--:--') ? "2022-11-10T" + formvalue.ArrivalTimeD + ":00" : "",
                    DepartureTimeD: (formvalue.DepartureTimeD !== null && formvalue.DepartureTimeD !== '' && formvalue.DepartureTimeD !== '--:--') ? "2022-11-10T" + formvalue.DepartureTimeD + ":00" : "",
                    ClassId: classid,
                    SupplierId: supplierid,
                    FlightPrice: Number(formvalue.FlightPrice),
                    HotelId: hotelnameid,
                    RoomTypeId: hotelroomtypeid,
                    NoOfPax: noofpaxid,

                    HotelPrice: Number(formvalue.HotelPrice),
                    SightseeingId: formvalue.SightseeingId,
                    VehicleId: vehicaltypeid,
                    SightseeingPrice: vehicalprice === "" ? 0 : vehicalprice,
                    AirlineId: Number(airlineid)
                    //IsChecked: !item.IsChecked,
                };

                return updatedItem;
            }

            return item;
        });
        setDataItinerary(newList);

    }

    useEffect(() => {

        handlegstcalculation();
        ClearItineraryDetail();

    }, [dataitinerary]);
    const removeitinerary = (id, e) => {

        // const olddata = dataitinerary?.filter((result) => result.SeqNo !== id);
        // setDataItinerary(olddata);
        setDataItinerary((oldState) => oldState?.filter((item) => item.SeqNo !== id));
        reseterrormessage();
        setEditDisabled(false);
        //handlegstcalculation();
    }

    useEffect(() => {

        if (dataitinerarycounter > 0) {
            AddItineraryData();
        }
    }, [dataitinerarycounter]);

    const handleclearItinerary = () => {
        ClearItineraryDetail();

        // document.querySelector('.dayschedule .jodit-wysiwyg').innerText = "";
        // const richcontent = document.querySelector('.dayschedule .jodit-wysiwyg').innerText;
    }

    const ClearItineraryDetail = () => {
        setItineraryBtnText('Add');
        setItineraryId(0);
        setHotelName('Hotel Name');
        setSightseeingName('Sightseeing Name');
        setHotelRating(0);

        formvalue.Rating = 0
        formvalue.Day = ''
        formvalue.DaySchedule = ''
        formvalue.AirrivalTime = null
        formvalue.DepartureTime = null
        formvalue.ArrivalTimeD = ''
        formvalue.DepartureTimeD = ''
        formvalue.HotelPrice = ''
        formvalue.SightseeingId = 0
        formvalue.FlightPrice = ''
        setArrivalTime("--:--");
        setDepartureTime("--:--");
        setHoursTime("--:--");
        setAirlineList([]);
        setFlightNoList([]);
        setAirlineClassList([]);
        setSupplierList([]);
        setRoomTypeList([]);
        setRoomPriceList([]);
        setVehicalListData([]);

        setFlightFromId(0);
        setFlightToId(0);
        setClassId(0);
        setSupplierId(0);
        setVehicalTypeId(0);
        setVehicalPrice(0);


        formvalue.Duration = ''
        formvalue.PickUpPoint = ''
        formvalue.DropPoint = ''
        formvalue.ItineraryDetails = ''
        formvalue.HotelPrice = ''
        formvalue.Hours = ''
        setFlightNo('');
        setAirlineId(0);
        setHotelNameId(0);
        setHotelRoomTypeId(0);
        setSightSeeingId(0);
        setNoOfPaxId(0);
        setHotelRoomPrice(0);
        setFormError('');
        setFromFlightDisable(false)
        setToFlightDisable(false)
        setHotelDisable(false)
        setSightseeingDisable(false)
        const richtext = document.querySelector('.dayschedule .jodit-wysiwyg');
        if (richtext !== null) {
            document.querySelector('.dayschedule .jodit-wysiwyg').innerHTML = null;
        }
    }

    const clearFlightDetail = () => {
        formvalue.AirrivalTime = null
        formvalue.DepartureTime = null
        formvalue.ArrivalTimeD = ''
        formvalue.DepartureTimeD = ''
        formvalue.FlightPrice = ''
        formvalue.Duration = ''
        setArrivalTime("--:--");
        setDepartureTime("--:--");
        setHoursTime("--:--");
        setAirlineList([]);
        setFlightNoList([]);
        setAirlineClassList([]);
        setAirlineClassList([]);
        setSupplierList([]);

        setFlightNo('');
        setAirlineId(0);

        setFlightFromId(0);
        setFlightToId(0);
        setClassId(0);
        setSupplierId(0);

    }
    const handleSightseenhandlechangeretrive = async (id, fromid, toid) => {
        allsightseeing?.filter(obj => obj.SightseeingId === id).map((value) => {

            formvalue.SightseeingId = id
            formvalue.PickUpPoint = value.PickUpPoint
            formvalue.DropPoint = value.DropPoint
            formvalue.Hours = value.Hours
            formvalue.ItineraryDetails = value.ItineraryDetails
            setHoursTime(formatTime(value.Hours))
            // if (ele.Hours === null) {
            //     setHoursTime("--:--");
            // }
            // else {
            //     { formvalue.Hours = formatTime(ele.Hours) }
            //     setArrivalTime(formatTime(ele.ArrivalTime))
            // }
        })
        const SightseeingId = formvalue.SightseeingId;
        const vehicallist = await postUsersData('/master/v1/getpackagesightseeingdetail', { SightseeingId });
        const vehichallistD = vehicallist.data?.map((value) => ({
            label: value.VehicleType,
            value: value.VehicleId
        }));
        setVehicalListData(vehichallistD);
        setVehicalTypeList(vehicallist.data);
        setSightSeeingId(id);
    }
    const getflightnodataforedit = async (id, fromid, toid, flightno) => {
        const flightnolistD = await getAllData('/master/v1/getpackageairlinedetail?airlineId=' + id + '&dstid=' + fromid + '&arvid=' + toid);
        const flightlistdata = flightnolistD.data?.map((value) => ({
            value: value.FlightNo,
            label: value.FlightNo
        })) || []
        setFlightNoList(flightlistdata);
        setFlightData(flightnolistD.data)

        flightnolistD.data?.filter(obj => obj.FlightNo === flightno).map((value) => {
            formvalue.DepartureTime = formatTime(value.DepartureTime)
            formvalue.AirrivalTime = formatTime(value.AirrivalTime)
            formvalue.Duration = value.Duration

        });

        const supplierlist = flightnolistD.data?.filter(obj => obj.FlightNo === flightno).map((value) => ({
            value: value.SupplierId,
            label: value.SupplierName
        })) || [];

        const classlist = flightnolistD.data?.filter(obj => obj.FlightNo === flightno).map((value) => ({
            value: value.AirlineClassId,
            label: value.AirlineClassName
        })) || [];

        setSupplierList(supplierlist);
        setAirlineClassList(classlist);
    }
    // useEffect(() => {

    // },[]);
    const handlehotelchangeedit = async (id, roomid) => {
        const HotelId = id
        const hoteldetailbyid = await postUsersData('/master/v1/getpackagehoteldetail', { HotelId });
        const roomdetail = hoteldetailbyid.data.roomTypeDetail?.map((value) => ({
            label: value.RoomTypeName,
            value: value.RoomTypeId
        }));
        setRoomTypeList(roomdetail);


        const roompricedetail = hoteldetailbyid.data.priceDetail?.filter(obj => obj.RoomTypeId === roomid).map((value) => ({
            label: value.NoOfPax,
            value: value.NoOfPax
        }));
        setRoomPriceList(roompricedetail);
        setHotelRoomTypeId(roomid);
    }
    const handleEditItinerary = (id, e) => {
        ClearItineraryDetail();
        setItineraryId(id);
        setItineraryBtnText('Update');

        dataitinerary?.filter(obj => obj.SeqNo === id).map((ele, index) => {
            formvalue.Day = ele.Day
            formvalue.DaySchedule = ele.DaySchedule
            setFlightFromId(ele.FlightFromLocationId);
            setFlightToId(ele.FlightToLocationId);
            if (ele.FlightFromLocationId !== 0 && ele.FlightToLocationId !== 0) {
                getAirlineData(ele.FlightFromLocationId, ele.FlightToLocationId);
                setAirlineId(ele.AirlineId);
                getflightnodataforedit(ele.AirlineId, ele.FlightFromLocationId, ele.FlightToLocationId, ele.FlightNo)
                setFlightNo(ele.FlightNo);
                setAirlineId(ele.AirlineId);

                setClassId(ele.ClassId);
                setSupplierId(ele.SupplierId);
                formvalue.FlightPrice = ele.FlightPrice
                let allfromflightlist = allflightfrom?.filter(IsAct => IsAct.IsActive === true);
                setFlightfrom(allflightfrom?.filter(IsAct => IsAct.IsActive === true));
                setFromFlightDisable(false);
                if (!allfromflightlist?.find(Isfromlist => Isfromlist.LocationId === ele.FlightFromLocationId)) {
                    setFromFlightDisable(true)
                    setFlightfrom((prvfromData) => prvfromData?.length > 0 ? [...prvfromData, allflightfrom?.find(isFrom => isFrom.LocationId === ele.FlightFromLocationId)] : [])

                }
                let allytoflightlist = allflightto?.filter(IsAct => IsAct.IsActive === true);
                setFlightTo(allflightto?.filter(IsAct => IsAct.IsActive === true));
                setToFlightDisable(false);
                if (!allytoflightlist?.find(Istolist => Istolist.LocationId === ele.FlightToLocationId)) {
                    setToFlightDisable(true)
                    setFlightTo((prvtoData) => prvtoData?.length > 0 ? [...prvtoData, allflightto?.find(isTo => isTo.LocationId === ele.FlightToLocationId)] : [])

                }
            }
            if (ele.HotelId > 0) {
                allhotel?.filter(obj => obj.HotelId === ele.HotelId).map(value => {
                    setHotelName(value.Name);
                    setHotelRating(value.Rating);
                });
                setHotelNameId(ele.HotelId);

                let allhotellist = allhotel?.filter(IsAct => IsAct.IsActive === true);
                setHotelNameList(allhotel?.filter(IsAct => IsAct.IsActive === true));
                setHotelDisable(false)
                if (!allhotellist?.find(Ishotellist => Ishotellist.HotelId === ele.HotelId)) {
                    setHotelDisable(true)
                    setHotelNameList((prvhotelData) => prvhotelData?.length > 0 ? [...prvhotelData, allhotel?.find(isHotel => isHotel.HotelId === ele.HotelId)] : [])
                }

                handlehotelchangeedit(ele.HotelId, ele.RoomTypeId);
                //handleroomtypechangeretrive(ele.HotelId,ele.RoomTypeId);
                formvalue.HotelPrice = ele.HotelPrice



                setNoOfPaxId(ele.NoOfPax);

                //setHotelNameId(ele.HotelId);   
            }
            if (ele.HotelId === 0) {
                setHotelDisable(false);
            }
            formvalue.AirrivalTime = ele.ArrivalTime
            formvalue.DepartureTime = ele.DepartureTime


            if (ele.ArrivalTime === null || ele.DepartureTime === null) {
                setDepartureTime("--:--");
                setArrivalTime("--:--");
            }
            else {
                formvalue.ArrivalTimeD = formatTime(ele.ArrivalTime)
                formvalue.DepartureTimeD = formatTime(ele.DepartureTime)
                setDepartureTime(formatTime(ele.DepartureTime))
                setArrivalTime(formatTime(ele.ArrivalTime))
            }

            formvalue.HotelPrice = ele.HotelPrice
            formvalue.SightseeingId = ele.SightseeingId
            if (ele.SightseeingId > 0) {
                //setVehicalTypeId(ele.SightseeingId);
                handleSightseenhandlechangeretrive(ele.SightseeingId);
                allsightseeing?.filter(obj => obj.SightseeingId === ele.SightseeingId).map(value => {
                    setSightseeingName(value.SightseeingName);
                });

                setVehicalTypeId(ele.VehicleId);

                formvalue.VehicalPrice = ele.SightseeingPrice

                let allsightseeinglist = allsightseeing?.filter(IsAct => IsAct.IsActive === true);
                setSightSeeingList(allsightseeing?.filter(IsAct => IsAct.IsActive === true));
                setSightseeingDisable(false)
                if (!allsightseeinglist?.find(Issightlist => Issightlist.SightseeingId === ele.SightseeingId)) {
                    setSightseeingDisable(true)
                    setSightSeeingList((prvsightData) => prvsightData?.length > 0 ? [...prvsightData, allsightseeing?.find(isSight => isSight.SightseeingId === ele.SightseeingId)] : [])
                }
                setVehicalPrice(ele.SightseeingPrice);
            }
        })
    }
    //#endregion

    const handlerichtextbox = (e) => {
        // const str = e.replace('<p><br></p>', '')

        // formvalue.CityDetailsD = str

        // const html = htmlToText(str);
        // if (html.length !== (retrivecomplete)) {
        //     setEditDisabled(false);
        //     //setFormError('');
        // }

        // setEditDisabled(false);
        // setFormError('');
    }
    //#region  gst count

    const [gstid, setGstId] = useState(1);
    const [gst, setGst] = useState(5);
    const [gsttype, setGstType] = useState('FIX');

    const gstlist = [
        { value: 1, label: '5' },
        { value: 2, label: '10' },
        { value: 3, label: '18' },
        { value: 4, label: '28' }
    ]
    const [gstamout, setGSTAmount] = useState(0);
    //const [netprice , setNetPrice] = useState(0);

    const handlegstonchange = (e) => {
        reseterrormessage();
        setEditDisabled(false);
        formvalue.GST = e.value
        //setGstId(e.value);
        setGst(e.label);

        handlegstcalculation();

    }
    //  useEffect(()=> {handlegstcalculation();},[gst]);
    const handlegsttypechange = (e) => {
        formvalue.GST = gstid
        formvalue.PackageFixPrice = 1
        formvalue.PackageMarkupValue = '1'
        setGstType('GST');
    }
    useEffect(() => {
        handlegstcalculation();
    }, [gsttype]);

    const handlefixtypetax = () => {
        //{formvalue.PackageFinalPrice = 0}
        // {formvalue.GST = 0}
        //setGstId(0);
        formvalue.GST = gstid
        formvalue.PackageFixPrice = 0
        formvalue.PackageMarkupValue = '0'
        setGstType('FIX');
        handlegstcalculation();
        setFormError('');
    }
    //#endregion

    const onChange = (e) => {
        var result;

        if ([e.target.name][0] === 'IsBestPackage') {
            setBestPackage(e.target.checked);
            result = e.target.checked
        }
        else if ([e.target.name][0] === 'IsHotDeals') {
            setHotDeals(e.target.checked);
            result = e.target.checked
        }
        else if ([e.target.name][0] === 'HotOfferPercentage') {
            result = isNaN(e.target.value) ? 0 : Number(onlyNumeric(e.target.value))
            formvalue.HotOfferPercentage = result
        }
        else if ([e.target.name][0] === 'IsInternationalDestination') {
            setIsInternationalDestination(e.target.checked);
            result = e.target.checked
        }
        else if ([e.target.name][0] === 'IsBestDestination') {
            setIsBestDestination(e.target.checked);
            result = e.target.checked
        }
        else if ([e.target.name][0] === 'IsTrendingHoliday') {
            setIsTrendingHoliday(e.target.checked);
            result = e.target.checked
        }
        else if ([e.target.name][0] === 'PackageInventoryCountD') {
            result = isNaN(e.target.value) ? 0 : Number(onlyNumeric(e.target.value));

            formvalue.PackageInventoryCount = result

        }
        else if ([e.target.name][0] === 'PackageCutOffCountD') {
            result = e.target.value === "" ? null : Number(onlyNumeric(e.target.value));
            if (result === null) {
                formvalue.PackageCutOffCount = null
            }
            else {
                formvalue.PackageCutOffCount = result
            }

        }
        else if ([e.target.name][0] === 'PackageValidateStartD') {

            formvalue.PackageValidateStart = e.target.value
            //{ formvalue.PackageValidateStartD = e.target.value }
            result = e.target.value;
        }
        else if ([e.target.name][0] === 'PackageValidateEndD') {
            formvalue.PackageValidateEnd = e.target.value
            //{ formvalue.PackageValidateEndD = e.target.value }
            result = e.target.value;
        }
        else if ([e.target.name][0] === 'PackageFixPrice') {
            formvalue.PackageFixPrice = ((e.target.value !== '' && !isNaN(e.target.value)) ? (NumericWithDecimal(e.target.value)) : '0')
            formvalue.PackageMarkupValue = ((e.target.value !== '' && !isNaN(e.target.value)) ? (NumericWithDecimal(e.target.value)) : '0')
            // setFixPrice(Number(e.target.value));
            result = ((e.target.value !== '' && !isNaN(e.target.value)) ? (NumericWithDecimal(e.target.value)) : '0');
            handlegstcalculation();

            // {formvalue.PackageFixPrice = Number(e.target.value)}
            // {formvalue.PackageMarkupValue = e.target.value}
            // {formvalue.PackageFinalPrice = Number(formvalue.PackageBasicPrice)+  Number(e.target.value)}
        }

        else if ([e.target.name][0] === 'FlightPrice') {
            result = isNaN(e.target.value) ? 0 : Number(onlyNumeric(e.target.value))
        }
        else {
            result = e.target.value;
        }

        setFormValue({ ...formvalue, [e.target.name]: result });
        if ([e.target.name][0] === 'PackageName') {
            setBasicDraftDisabled(false);
        }
        reseterrormessage();
        setEditDisabled(false);
    }

    const handleDayScheduleChange = (e) => {
        const str = e.replace('<p><br></p>', '')
        formvalue.DaySchedule = str

    }
    //#region textbox

    const config = useMemo(() => ({
        readonly: true, // all options from https://xdsoft.net/jodit/doc/,
        height: '100%',
        width: '100%',
        statusbar: false,
        removeButtons: ["link", "about"],
        toolbarAdaptive: false,
        height: 'auto',
        maxHeight: richboxheight === true ? 200 : 500,
        disablePlugins: ["source"],
        disabled: disabled ? true : false
    }), [disabled]);
    useEffect(() => {
        const richtexteboxheight = document.getElementById("layoutSidenav_content").clientWidth
        if (richtexteboxheight > 912) {
            setRichBoxHeight(true)
        }
        else {
            setRichBoxHeight(false)
        }
    }, []);

    const dayscheduleconfig = useMemo(() => ({
        readonly: false, // all options from https://xdsoft.net/jodit/doc/,
        height: '100%',
        width: '100%',
        statusbar: false,
        disablePlugins: ["source"],
        removeButtons: ["about", "image", "link", "select", "video"],
        height: 'auto',
        maxHeight: richboxheight === true ? 200 : 500,
        toolbarAdaptive: false,
        //autofocus: true,
        defaultMode: "1",
        disabled: disabled ? true : false
    }), [disabled]);
    //#endregion textbox

    const handlegstcalculation = () => {
        let basicpricecalculation = 0;
        setGst(5);

        const result = dataitinerary.reduce((total, currentValue) => total = total + (Number(currentValue.FlightPrice) + Number(currentValue.HotelPrice) + Number(currentValue.SightseeingPrice)), 0);
        basicpricecalculation = result;
        //fixprice
        if (result > 0) {
            formvalue.PackageBasicPrice = result
            //alert('fixprice',formvalue.PackageFixPrice);
            if (gsttype === 'GST') {
                let markupvalue = (Number(basicpricecalculation) * Number(formvalue.PackageFixPrice)) / 100;
                const GSTAmount = (((Number(basicpricecalculation) + Number(markupvalue)) * Number(gst)) / 100);
                setGSTAmount(GSTAmount);
                //const NetPrice = Number(basicpricecalculation) + GSTAmount
                formvalue.PackageFinalPrice = (Number(basicpricecalculation) + Number(markupvalue) + Number(GSTAmount)).toFixed(2)
                setPkgFinalPrice((Number(basicpricecalculation) + Number(markupvalue) + Number(GSTAmount)).toFixed(2));
            }
            else {
                let gstfixvalue = (((Number(basicpricecalculation) + Number(formvalue.PackageFixPrice)) * Number(gst)) / 100);
                ///formvalue.PackageFinalPrice = (Number(basicpricecalculation) + Number(formvalue.PackageFixPrice)+ Number(gstfixvalue)).toFixed(2)
                formvalue.PackageFinalPrice = (Number(basicpricecalculation) + Number(formvalue.PackageFixPrice) + Number(gstfixvalue)).toFixed(2)
                setPkgFinalPrice((Number(basicpricecalculation) + Number(formvalue.PackageFixPrice) + Number(gstfixvalue)).toFixed(2))
            }
        }
        else {
            formvalue.PackageBasicPrice = 0
            if (gsttype === 'GST') {
                setGSTAmount(0)
                formvalue.PackageFinalPrice = 0
                setPkgFinalPrice(0)

            }
            else {
                formvalue.PackageFinalPrice = 0
                setPkgFinalPrice(0)
            }

        }
    }

    // const disablePastDate = () => {
    //     const today = new Date();
    //     const dd = String(today.getDate()).padStart(2, "0");
    //     // const dd = String(today.getDate() + 1).padStart(2, "0");
    //     const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    //     const yyyy = today.getFullYear();
    //     return yyyy + "-" + mm + "-" + dd;
    // };


    //#region start input date type box

    const handleDateChange = (fieldname, e) => {

        if (fieldname === 'PackageValidateStartD') {
            // { formvalue.AirrivalTime = formatDate(e) + "T" + formvalue.AirrTime }
            formvalue.PackageValidateStartD = formatDate(e)
            formvalue.PackageValidateStart = formatDate(e)
            setPackageValidateStart(e);
        }
        else if (fieldname === 'PackageValidateEndD') {
            // { formvalue.DepartureTime = formatDate(e) + "T" + formvalue.DepTime }
            formvalue.PackageValidateEndD = formatDate(e)
            formvalue.PackageValidateEnd = formatDate(e)
            setPackageValidateEnd(e)
        }
        setEditDisabled(false);
        reseterrormessage();

    }
    const openDatePicker = (e) => {
        //open date picker on click date icon 
        document.querySelector("#" + e).click();
    };


    //#endregion end input date type box

    //#region start input time type box
    const [departureTime, setDepartureTime] = useState("--:--");
    const [arrivalTime, setArrivalTime] = useState("--:--");
    const [hoursTime, setHoursTime] = useState("--:--");

    const handleonchangeofTime = (fieldname, times) => {
        if (fieldname === 'ArrivalTimeD') {
            // { formvalue.ArrivalTime = "1900-01-01T" + times + ":00"}
            formvalue.ArrivalTimeD = times
            setArrivalTime(times)
            reseterrormessage();
            // if ('INPUT' === document.activeElement.tagName) {
            //     setEditDisabled(true);
            // }
            // else if (document.querySelector(".inputWrapper input").tagName){
            //     setEditDisabled(true);
            // }
        }
        else if (fieldname === 'DepartureTimeD') {
            // { formvalue.AirrivalTime = formvalue.ArrivalDate + "T" + times + ":00" }
            // { formvalue.AirrTime = times }
            // { formvalue.DepartureTime = "1900-01-01T" + times + ":00"}
            formvalue.DepartureTimeD = times
            setDepartureTime(times)
            reseterrormessage();
            // if ('INPUT' === document.activeElement.tagName) {
            //     setEditDisabled(true);
            // }
        }
        else if (fieldname === 'Hours') {
            // { formvalue.AirrivalTime = formvalue.ArrivalDate + "T" + times + ":00" }
            // { formvalue.AirrTime = times }
            formvalue.Hours = times
            setHoursTime(times)
            reseterrormessage();
            // if ('INPUT' === document.activeElement.tagName) {
            //     setEditDisabled(true);
            // }
        }



        //setEditDisabled(true);
    }
    //#endregion end input time type box
    const [childprice, setChildPrice] = useState(null)
    const handleChildPrice = (e) => {
        let result = NumericWithDecimal(e.target.value) === '' ? '' : NumericWithDecimal(e.target.value)
        setChildPrice(result)
        formvalue.ChildrenPrice = Number(result) !== 0 ? Number(result) : null

        reseterrormessage();
        setEditDisabled(false);
    }

    return (
        <>
            <div class="container mt-4 heading_color">
                <h3>ETM Package</h3>
            </div>
            <div class="container form_border py-5 my-3">
                <form action="" >
                    <div class="mb-3 row mx-1">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="row">
                                {/* {flightduplicateerrorMessage && <p className='text-danger text-start'>{flightduplicateerrorMessage}</p>} */}

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label class="col-form-label required">Select State</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            <Select
                                                ref={StateId}
                                                id="StateId"
                                                className="dropdown select_dropdown"
                                                options={statelist}
                                                // value={statelist?.filter(obj => selectedValue?.includes(obj.value))}
                                                value={selectedValue}
                                                onChange={handleStateChange} // assign onChange function
                                                isDisabled={stateenable ? true : false}
                                                isSearchable
                                                isMulti
                                            />
                                            {formerror.StateId && <p className='text-danger text-start'>{formerror.StateId}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label className="col-form-label required">Select City</label>
                                        </div>
                                        <div className="col-lg-8 mb-1 col-md-12">
                                            <Select
                                                ref={Locationid}
                                                id="Locationid"
                                                className="dropdown select_dropdown"
                                                options={cityData}
                                                // value={cityData?.filter(obj => selectedCityValue?.includes(obj?.value))}
                                                value={selectedCityValue}
                                                onChange={handleCityChange} // assign onChange function
                                                isDisabled={stateenable ? true : false}
                                                isSearchable
                                                isMulti
                                            />
                                            {formerror.Locationid && <p className='text-danger text-start'>{formerror.Locationid}</p>}
                                        </div>
                                    </div>
                                </div>


                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label class="col-form-label required">Package Code</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            <input type="text" id="inputPassword6" class="form-control"
                                                name="PackageCode"
                                                value={formvalue.PackageCode}
                                                onChange={onChange}
                                                disabled={true}
                                                placeholder="Package Code"
                                            />
                                            {/* {formerror.PackageCode && <p className='text-danger text-start'>{formerror.PackageCode}</p>} */}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label class="col-form-label required">Package Name</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            <input type="text" id="PackageName" class="form-control"
                                                name="PackageName"
                                                value={formvalue.PackageName}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                                placeholder="Package Name"
                                            />
                                            {formerror.PackageName && <p className='text-danger text-start'>{formerror.PackageName}</p>}
                                        </div>
                                    </div>
                                </div>


                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label class="col-form-label required">Package Type</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            {/* //checkedvalueofpackagetypevalue */}
                                            {packagetypelist && packagetypelist?.map((ele, index) => {
                                                return (<div class="form-check-inline">
                                                    <input class="form-check-input" type="radio" name="flexRadioType" id="flexRadioLevel4" value={ele.PackageTypeId} key={ele.PackageTypeId} onChange={(e) => handlePackageTypeChange(ele.PackageTypeId, e)}
                                                        checked={checkedvalueofpackagetypevalue === ele.PackageTypeId ? true : false} disabled={stateenable ? true : false} />
                                                    <label class="form-check-label ms-1">{ele.PackageTypeName}</label>
                                                    {/* <label class="form-check-label ms-1" for="flexRadioLevel4">{checkedvalueofpackagetypevalue}</label> */}
                                                </div>
                                                );

                                            })}
                                            {/* {formErrors.Classid && <p className='text-danger text-start'>{formErrors.Classid}</p>} */}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label class="col-form-label required">Select Category</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            <Select
                                                ref={PackageCategory}
                                                id="PackageCategory"
                                                className="dropdown select_dropdown"
                                                options={packagecategorydatalist}
                                                value={packagecategorydatalist?.filter(function (packagecategorydatalist) {
                                                    return packagecategorydatalist.value === packagecategoryid;
                                                })}// set list of the data
                                                onChange={handlepackagecategory} // assign onChange function
                                                isDisabled={disabled ? true : false}
                                                isSearchable
                                            />
                                            {formerror.PackageCategory && <p className='text-danger text-start'>{formerror.PackageCategory}</p>}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                      
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" name ="IsBestPackage" value={formvalue.IsBestPackage} onChange={onChange} 
                                                            id="flexCheckChecked" disabled={disabled ? true :false}/>
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    Mark As Best Package
                                                </label>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div> */}


                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label class="col-form-label required">Arrival Slot</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            <Select
                                                id="ArrivalSlotId"
                                                ref={ArrivalSlotId}
                                                className="dropdown select_dropdown"
                                                options={arrivalslotlist}
                                                value={arrivalslotlist?.filter(function (arrivalslotlist) {
                                                    return arrivalslotlist.value === arrivalslotid;
                                                })}// set list of the data
                                                onChange={handleArrivalSlotChange} // assign onChange function
                                                isDisabled={disabled ? true : false}
                                                isSearchable
                                            />
                                            {formerror.ArrivalSlotId && <p className='text-danger text-start'>{formerror.ArrivalSlotId}</p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label class="col-form-label required">Package Level</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            {/* <div class="form-check-inline">
                                                <input class="form-check-input" type="radio" name="flexRadioLevel" id="flexRadioLevel3" />
                                                <label class="form-check-label ms-1" for="flexRadioLevel3">
                                                    Domestic
                                                </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <input class="form-check-input" type="radio" name="flexRadioLevel" id="flexRadioLevel4" defaultChecked />
                                                <label class="form-check-label ms-1" for="flexRadioLevel4">
                                                    International
                                                </label>
                                            </div> */}
                                            {packagelevellist && packagelevellist?.map((ele, index) => {

                                                return (
                                                    <div class="form-check-inline">
                                                        <input class="form-check-input" type="radio" name="flexRadioLevel" id="flexRadioLevel4" value={ele.PackageLevelId} key={ele.PackageLevelId} onChange={(e) => handlePackageLevelChange(ele.PackageLevelId, e)}
                                                            checked={checkedvalueofpackagelevelvalue === ele.PackageLevelId ? true : false} disabled={disabled ? true : false} />
                                                        <label class="form-check-label ms-1">{ele.PackageLevelName}</label>
                                                    </div>
                                                );

                                            })}



                                            {/* {formErrors.Classid && <p className='text-danger text-start'>{formErrors.Classid}</p>} */}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>

                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label class="col-form-label required">Package Validity Start</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            {/* <input type="date" id="inputPassword6" class="form-control"
                                                min={disablePastDate()}
                                                name="PackageValidateStartD"
                                                value={formvalue.PackageValidateStartD}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                            /> */}
                                            <div class="text-container">
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    className='form-control'
                                                    id='startdate'
                                                    selected={packageValidateStart}
                                                    onChange={(e) => handleDateChange('PackageValidateStartD', e)}
                                                    disabled={disabled ? true : false}
                                                    placeholderText="dd/mm/yyyy"
                                                    // onInputClick={true}
                                                    minDate={new Date()}
                                                    maxDate={new Date(new Date().getFullYear() + 20, 11, 30)}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode='select'
                                                    autoComplete='off'
                                                />
                                                <span className="example-custom-input calendarBtn" onClick={(e) => openDatePicker("startdate")} id="calendarBtn1" Disabled={disabled ? true : false} ><i class="fa fa-calendar"></i></span>
                                            </div>

                                            {formerror.PackageValidateStart && <p className='text-danger text-start'>{formerror.PackageValidateStart}</p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label class="col-form-label required">Departure Slot</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            <Select
                                                id="DepartureSlotid"
                                                ref={DepartureSlotid}
                                                className="dropdown select_dropdown"
                                                options={departureslotlist}
                                                value={departureslotlist?.filter(function (departureslotlist) {
                                                    return departureslotlist.value === departureslotid;
                                                })}// set list of the data
                                                onChange={handleDepatureSlotChange} // assign onChange function
                                                isDisabled={disabled ? true : false}
                                                isSearchable
                                            />
                                            {formerror.DepartureSlotid && <p className='text-danger text-start'>{formerror.DepartureSlotid}</p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label class="col-form-label required">Package Inventory Count</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            <input type="text" id="PackageInventoryCount" class="form-control"
                                                name="PackageInventoryCountD"
                                                value={formvalue.PackageInventoryCountD}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                                placeholder="Package Inventory Count"
                                            />
                                            {formerror.PackageInventoryCount && <p className='text-danger text-start'>{formerror.PackageInventoryCount}</p>}
                                        </div>
                                    </div>
                                </div>


                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label class="col-form-label required">Package Validity End</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            {/* <input type="date" id="inputPassword6" class="form-control"
                                                min={disablePastDate()}
                                                name="PackageValidateEndD"
                                                value={formvalue.PackageValidateEndD}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                            /> */}
                                            <div class="text-container">
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    className='form-control'
                                                    id='enddate'
                                                    selected={packageValidateEnd}
                                                    onChange={(e) => handleDateChange('PackageValidateEndD', e)}
                                                    disabled={disabled ? true : false}
                                                    placeholderText="dd/mm/yyyy"
                                                    // onInputClick={true}
                                                    minDate={new Date()}
                                                    maxDate={new Date(new Date().getFullYear() + 20, 11, 30)}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode='select'
                                                    autoComplete='off'
                                                />
                                                <span className="example-custom-input calendarBtn" onClick={(e) => openDatePicker("enddate")} id="calendarBtn1" Disabled={disabled ? true : false} ><i class="fa fa-calendar"></i></span>
                                            </div>
                                            {formerror.PackageDateValidation && <p className='text-danger text-start'>{formerror.PackageDateValidation}</p>}
                                            {formerror.PackageValidateEnd && <p className='text-danger text-start'>{formerror.PackageValidateEnd}</p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 d-none'>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">

                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" name="IsBestPackage" value={formvalue.IsBestPackage} onChange={onChange}
                                                    id="flexCheckChecked" disabled={disabled ? true : false} checked={bestpackage ? true : false} />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    Mark As Best Package
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label class="col-form-label required">Package cut off Days</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            <div className='prefix_div'>
                                                <span className='prefix text-muted'>D-</span>
                                                <input type="text" id="PackageCutOffCount" class="form-control cutoffinput"
                                                    name="PackageCutOffCountD"
                                                    value={formvalue.PackageCutOffCountD}
                                                    onChange={onChange}
                                                    disabled={disabled ? true : false}
                                                    placeholder="Package cut off Days"
                                                />
                                            </div>
                                            {formerror.PackageCutOffCount && <p className='text-danger text-start'>{formerror.PackageCutOffCount}</p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-12 col-sm-12 '>
                                    <div className='row'>
                                        <div class="col-lg-2 col-md-12 mb-1">
                                            <label class="col-form-label required">Package Highlights</label>
                                        </div>
                                        <div class="col-lg-10 mb-1 col-md-12">
                                            <textarea type='text' id="PackageHighlights" class="form-control"
                                                name="PackageHighlights"
                                                value={formvalue.PackageHighlights}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                            ></textarea>
                                            {formerror.PackageHighlights && <p className='text-danger text-start'>{formerror.PackageHighlights}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label class="col-form-label required">Package Banner</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            {/* <Select
                                                className="dropdown select_dropdown"
                                                options={bannerdatalist}
                                                value={bannerdatalist?.filter(function (bannerdatalist) {
                                                    return bannerdatalist.value === bannerId;
                                                })}// set list of the data
                                                onChange={handleBannerChange} // assign onChange function
                                                isDisabled={disabled ? true : false}
                                                isSearchable
                                            /> */}
                                            <CustomSelection
                                                selId="BannerImgPath"
                                                selRef={BannerImgPath}
                                                // bannerId="BannerImgPath"
                                                datalist={bannerdatalist}
                                                fieldid={bannerId}
                                                SelectionChange={handleBannerChange}
                                                isdisable={disabled || atFirstDisable}
                                                isactivedisable={bannerdisabled}
                                                issearchable={true}
                                            />
                                            {formerror.BannerImgPath && <p className='text-danger text-start'>{formerror.BannerImgPath}</p>}
                                        </div>
                                        {/* <div class="col-lg-12 mb-1 col-md-12">
                                            <label for="inputPassword6" class="col-form-label">Banner Display</label>
                                        </div> */}
                                        <div class="col-lg-8 mb-1 col-md-12 mx-auto">
                                            {bannerimgpath &&
                                                (
                                                    <div className="col-lg-4 col-md-6 col-6 mx-auto"  >
                                                        {/* <img src={galleryimg.GalleryPath} key = {index} className="img-fluid" style={{width:"80%" ,height:"80%"}}/> */}
                                                        <img src={bannerimgpath} className="img_thumb w-100" alt='' />
                                                        <button className='closebtnimg' onClick={() => removeBannerImage()} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }} disabled={disabled ? true : false} >&times;</button>

                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label class="col-form-label required">Package Gallery</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            <Select
                                                ref={GalleryCount}
                                                id="GalleryCount"
                                                className="dropdown select_dropdown"
                                                options={gallerydatalist}
                                                value={gallerydatalist?.filter(function (gallerydatalist) {
                                                    return gallerydatalist.value === galleryid;
                                                })}// set list of the data
                                                onChange={handleGalleryChange} // assign onChange function
                                                isDisabled={disabled || atFirstDisable ? true : false}
                                                isSearchable
                                            />
                                            {galleryimgerror && <p className='text-danger text-start'>{galleryimgerror}</p>}
                                            {formerror.GalleryCount && <p className='text-danger text-start'>{formerror.GalleryCount}</p>}
                                        </div>
                                        <div class="col-lg-12 mb-1 col-md-12">
                                            <div class="row">


                                                {/* this label for only Gallery image  galleryimgpath */}
                                                {/* <label for="inputPassword6" class="col-form-label">Gallery Display</label> */}
                                                {galleryimgpath && galleryimgpath?.map((galleryimg, index) => {
                                                    if (gallerycount > 0) {
                                                        return (
                                                            <div className="col-lg-3 col-md-6 col-6 mx-auto py-2" key={index} >
                                                                <img src={galleryimg.GalleryPath} key={index} className="img_thumb w-100" alt='' />
                                                                <button className='closebtnimg' onClick={(e) => removeGalleryImage(galleryimg.Id, e)} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }} disabled={disabled ? true : false} >&times;</button>

                                                            </div>

                                                        );
                                                    }
                                                })}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>

                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label class="col-form-label">Active</label>
                                        </div>
                                        <div class="col-auto col-lg-8" disabled={false}>
                                            {/* <input class="form-check-input mt-2" type="checkbox" id="checkboxNoLabel" checked={activeId}
                                        aria-label="..." onChange={handleisActive} disabled={disabled} /> */}
                                            <Switch
                                                onChange={handleisActive}
                                                checked={activeId}
                                                className="react-switch"
                                                disabled={disabled ? true : false}
                                            />
                                        </div>
                                        {/* <div class=" col-auto col-lg-4"> */}
                                        <ConfirmationModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            noconfirm={() => handleConfirmation(false)}
                                            yesconfirm={() => handleConfirmation(true)}
                                        />

                                        {/* </div> */}
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* {tagduplicateerrorMessage && <div className="text-danger"> {tagduplicateerrorMessage} </div>} */}
                    </div>
                    <div class="mb-3 mt-5 row mx-1" style={{ display: isDetailblockshow ? 'block' : 'none' }}>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="row">
                                <div className='col-lg-6 col-sm-6 ' >
                                    <div className='row'>
                                        <div class="col-lg-12 col-md-12 mb-1">
                                            <label class="col-form-label required">Package Menu Master</label>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-lg-12 col-12 col-md-12 '>
                                    <div className='row'>
                                        {menulist && menulist?.map((ele, index) => {
                                            return (
                                                <>
                                                    <div class="col-lg-2 col-md-3 col-6 mb-1">
                                                        <label class="col-form-label">{ele.PackageMenuUseName}</label>
                                                    </div>
                                                    <div class="col-lg-2 col-md-3 col-6 mb-1">
                                                        <input className="form-check-input mt-2 me-2" name='menutype' type="checkbox" id="checkboxNoLabelm" checked={ele.IsDefault ? true : false}
                                                            //aria-label="..." value={ele.PackageMenuId} key={ele.PackageMenuId} onChange={() => showDivHandlerD(ele.PackageMenuId)} disabled={ele.PackageMenuId === 3 ? true : false}/>
                                                            aria-label="..." value={ele.PackageMenuId} key={ele.PackageMenuId} onChange={() => showDivHandlerD(ele.PackageMenuId)} disabled={packageD?.state?.viewtype !== 'View' ? (ele.IsDisabled ? true : false) : true} />
                                                    </div>
                                                </>

                                                //              <input type='checkbox' name="menutype" value={ele.MenuTypeId} key={ele.MenuTypeId} checked={ele.IsChecked ? true :false} onChange ={() => handlecheckboxchange(ele.MenuTypeId)}/>
                                                // <label>{ele.MenuType}</label>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="accordion mb-3" id="accordionPanelsStayOpenExample">
                            {(Shown.Overview === true) && <div class="accordion-item mb-3">
                                <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                                    <button class="accordion-button collapsed border_set" id="OverviewError" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                                        Overview
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                                    <div class="accordion-body">
                                        <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                                            <div class="row">
                                                {/* <label for="inputPassword6" class="col-form-label">Overview</label> */}
                                                <div class="row">
                                                    <div className='col-lg-6 col-sm-6 '>
                                                        <div className='row'>
                                                            <div class="col-lg-4 col-md-12 mb-1">
                                                                <label class="col-form-label required">Package Duration</label>
                                                            </div>
                                                            <div class="col-lg-8 mb-1 col-md-12">
                                                                <Select
                                                                    className="dropdown select_dropdown"
                                                                    options={durationlistofoverview}
                                                                    value={durationlistofoverview?.filter(function (durationlistofoverview) {
                                                                        return durationlistofoverview.value === durationid;
                                                                    })}// set list of the data
                                                                    onChange={handledurationchange} // assign onChange function
                                                                    isDisabled={disabled ? true : false}
                                                                    isSearchable
                                                                />
                                                                {formerror.PackageDuration && <p className='text-danger text-start'>{formerror.PackageDuration}</p>}
                                                                {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-sm-6 '>
                                                        <div className='row'>
                                                            <div class="col-lg-2 col-md-12 mb-1">
                                                                <label class="col-form-label required">Best Time To Visit</label>
                                                            </div>
                                                            <div class="col-lg-1 col-md-4 mb-1">
                                                                <label class="col-form-label required">From</label>
                                                            </div>
                                                            <div class="col-lg-3 mb-1 col-md-8">
                                                                <Select
                                                                    className="dropdown select_dropdown"
                                                                    options={visitfromdurationlist}
                                                                    value={visitfromdurationlist?.filter(function (visitfromdurationlist) {
                                                                        return visitfromdurationlist.value === visitfromdurationid;
                                                                    })}// set list of the data
                                                                    onChange={handledurationfromchange} // assign onChange function
                                                                    isDisabled={disabled ? true : false}
                                                                    isSearchable
                                                                />
                                                                {formerror.BestTimeVisitFrom && <p className='text-danger text-start'>{formerror.BestTimeVisitFrom}</p>}
                                                                {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                            </div>
                                                            <div class="col-lg-2 col-md-4 mb-1">
                                                                <label class="col-form-label required">To</label>
                                                            </div>
                                                            <div class="col-lg-3 mb-1 col-md-8">
                                                                <Select
                                                                    className="dropdown select_dropdown"
                                                                    options={visittodurationlist}
                                                                    value={visittodurationlist?.filter(function (visittodurationlist) {
                                                                        return visittodurationlist.value === visittodurationid;
                                                                    })}// set list of the data
                                                                    onChange={handledurationtochange} // assign onChange function
                                                                    isDisabled={disabled ? true : false}
                                                                    isSearchable
                                                                />
                                                                {formerror.BestTimeToVisit && <p className='text-danger text-start'>{formerror.BestTimeToVisit}</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-sm-6 '>
                                                        <div className='row'>
                                                            <div class="col-lg-4 col-md-12 mb-1">
                                                                <label class="col-form-label required">Package Start Point</label>
                                                            </div>
                                                            <div class="col-lg-8 mb-1 col-md-12">
                                                                {/* <Select
                                                                    className="dropdown select_dropdown"
                                                                    options={packagepointfromlist}
                                                                    value={packagepointfromlist?.filter(function (packagepointfromlist) {
                                                                        return packagepointfromlist.value === visitpointfromid;
                                                                    })}// set list of the data
                                                                    onChange={handlevisitfrompoint} // assign onChange function
                                                                    isDisabled={disabled ? true : false}
                                                                    isSearchable
                                                                /> */}
                                                                <CustomSelection
                                                                    datalist={packagepointfromlist}
                                                                    fieldid={visitpointfromid}
                                                                    SelectionChange={handlevisitfrompoint}
                                                                    isdisable={disabled}
                                                                    isactivedisable={startpointdisabled}
                                                                    issearchable={true}
                                                                />
                                                                {formerror.PackageStartPoint && <p className='text-danger text-start'>{formerror.PackageStartPoint}</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-sm-6 '>
                                                        <div className='row'>
                                                            <div class="col-lg-4 col-md-12 mb-1">
                                                                <label class="col-form-label required">Package End Point</label>
                                                            </div>
                                                            <div class="col-lg-8 mb-1 col-md-12">
                                                                {/* <Select
                                                                    className="dropdown select_dropdown"
                                                                    options={packagepointtolist}
                                                                    value={packagepointtolist?.filter(function (packagepointtolist) {
                                                                        return packagepointtolist.value === visitpointtoid;
                                                                    })}// set list of the data
                                                                    onChange={handlevisittopoint} // assign onChange function
                                                                    isDisabled={disabled ? true : false}
                                                                    isSearchable
                                                                /> */}
                                                                <CustomSelection
                                                                    datalist={packagepointtolist}
                                                                    fieldid={visitpointtoid}
                                                                    SelectionChange={handlevisittopoint}
                                                                    isdisable={disabled}
                                                                    isactivedisable={endpointdisabled}
                                                                    issearchable={true}
                                                                />
                                                                {formerror.PackageEndPoint && <p className='text-danger text-start'>{formerror.PackageEndPoint}</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-sm-6 '>
                                                        <div className='row'>
                                                            <div class="col-lg-4 col-md-12 mb-1">
                                                                <label class="col-form-label required">Package Theme</label>
                                                            </div>
                                                            <div class="col-lg-8 mb-1 col-md-12">

                                                                <Select
                                                                    className="dropdown select_dropdown"
                                                                    options={packagethemelist}
                                                                    value={packagethemelist?.filter(function (packagethemelist) {
                                                                        return packagethemelist.value === themebyid;
                                                                    })}// set list of the data
                                                                    // value={packagethemelist.filter(obj => themebyid.includes(obj.value))}
                                                                    onChange={handlethemebychange} // assign onChange function
                                                                    isDisabled={disabled ? true : false}
                                                                    isSearchable
                                                                />

                                                                {formerror.ThemeList && <p className='text-danger text-start'>{formerror.ThemeList}</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 mb-1 col-md-12">
                                                        <div class="row">
                                                            {/* this label for only Gallery image  galleryimgpath */}
                                                            {/* <label for="inputPassword6" class="col-form-label">Gallery Display</label> */}
                                                            {themeimgpath && themeimgpath?.map((themeimg, index) => {
                                                                if (themebycounter > 0) {
                                                                    return (
                                                                        <div className="col-lg-2 col-md-3 col-6 mx-auto py-2" key={index} >
                                                                            <img src={themeimg.ThemePath} key={index} className="img_thumb w-100" alt='' />
                                                                            <button className='closebtnimg' onClick={(e) => removeThmeImage(themeimg.Id, e)} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }} disabled={disabled ? true : false} >&times;</button>

                                                                        </div>

                                                                    );
                                                                }
                                                            })}

                                                            {/* </div> */}
                                                        </div>
                                                    </div>

                                                    <div className="form_border mt-4 mx-2">
                                                        {formerror.overviewCityDetail && <p className='mt-2 text-danger text-center'>{formerror.overviewCityDetail}</p>}
                                                        {overviewCityLengthError && <p className='mt-2 text-danger text-center'>{overviewCityLengthError}</p>}
                                                        {formerror.totalNightsPending && <p className="mt-2 text-center text-danger"> {formerror.totalNightsPending} </p>}
                                                        {overviewCityNightError && <p className="mt-2 text-center text-danger"> {overviewCityNightError} </p>}
                                                        <div className='col-lg-12 col-sm-12 mt-3'>
                                                            <div className='row'>
                                                                {/* <div className='col-lg-5 col-sm-5'> */}
                                                                <div className='col-lg-6 col-sm-6'>
                                                                    <div className='row'>
                                                                        <div class="col-lg-4 col-md-12 mb-1">
                                                                            <label class="col-form-label required">City</label>
                                                                        </div>
                                                                        <div class="col-lg-8 mb-1 col-md-12">
                                                                            {/* <Select
                                                                                className="dropdown select_dropdown"
                                                                                options={overviewCityList}
                                                                                value={overviewCityList?.filter(function (overviewCity) {
                                                                                    return overviewCity.value === overviewCityid;
                                                                                })}
                                                                                onChange={handlecityDatachange}
                                                                                isDisabled={disabled}
                                                                                isSearchable
                                                                            /> */}
                                                                            <CustomSelection
                                                                                datalist={overviewCityList}
                                                                                fieldid={overviewCityid}
                                                                                SelectionChange={handlecityDatachange}
                                                                                isdisable={disabled}
                                                                                isactivedisable={citydisable}
                                                                                issearchable={true}
                                                                            />
                                                                            {/* {overviewCityError && <p className='text-danger text-start'>{overviewCityError}</p>} */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className='col-lg-5 col-sm-5'> */}
                                                                <div className='col-lg-6 col-sm-6'>
                                                                    <div className='row'>
                                                                        <div class="col-lg-4 col-md-12 mb-1">
                                                                            <label class="col-form-label required">Night</label>
                                                                        </div>
                                                                        <div class="col-lg-8 mb-1 col-md-12">
                                                                            <Select
                                                                                className="dropdown select_dropdown"
                                                                                options={nightlist}
                                                                                value={nightlist?.filter(function (night) {
                                                                                    return night.value === nightid;
                                                                                })}
                                                                                onChange={handleNightChange}
                                                                                isDisabled={disabled}
                                                                                isSearchable
                                                                            />
                                                                            {/* {overviewNightError && <p className='text-danger text-start'>{overviewNightError}</p>} */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className='col-lg-2 col-sm-2'>
                                                                    <div className='row'>
                                                                        <div class="col-lg-12 mb-1 col-md-12">
                                                                            <button type="button" class="btn btn-primary mx-1" id="btnAdd"> Add </button>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                                <div class="d-flex justify-content-center mt-3">
                                                                    <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={handleOverviewAdd} disabled={disabled}> {overviewbtntext} </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="mb-3 row py-3">
                                                                    <div class="col-lg-12 mb-1">
                                                                        <div class="row">
                                                                            <div class="col-lg-12 mb-1 table-scrollable">
                                                                                <table id="data" className="table table-hover" >
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>City
                                                                                            </th>
                                                                                            <th className='text-center'>Night
                                                                                            </th>
                                                                                            <th className='text-end pe-4'>Edit
                                                                                            </th>
                                                                                            <th className='text-end pe-4'>Remove
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody id="location-data">
                                                                                        {overviewCityDetails?.map((element, index) => {

                                                                                            return (
                                                                                                <tr class="ReactTable" key={index}>
                                                                                                    <td>{element.city}</td>
                                                                                                    <td className='text-center'>{element.night}</td>
                                                                                                    <td className='text-end'><button type="button" onClick={(e) => { handleEditOverviewCity(element.packageOverviewCityId, e) }} class="btn btn-primary mx-1" id="btnEdit" disabled={disabled}>Edit</button></td>
                                                                                                    <td className='text-end'><button type="button" onClick={(e) => { removeverviewCity(element.packageOverviewCityId, e) }} class="btn btn-primary mx-1" id="btnAdd" disabled={disabled}>Remove</button></td>
                                                                                                </tr>
                                                                                            );

                                                                                        })}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {(Shown.Itinerary === true) && <div class="accordion-item mb-3">
                                <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                                    <button class="accordion-button collapsed" type="button" id="ItineraryError" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                        Itinerary
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                                    <div class="accordion-body">
                                        <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                                            <div class="row">
                                                {/* <label for="inputPassword6" class="col-form-label">Itinerary</label> */}
                                                <div class="row">
                                                    {formerror.ItineraryError && <p className='text-danger text-center'>{formerror.ItineraryError}</p>}
                                                    <div className='col-lg-12 col-sm-12 '>

                                                        <div className='row'>

                                                            <div class="col-lg-2 col-md-12 mb-1">
                                                                <label class="col-form-label required">Enter Day</label>
                                                            </div>
                                                            <div class="col-lg-10 mb-1 col-md-12">
                                                                <textarea type="text"
                                                                    id="inputPassword6"
                                                                    class="form-control"
                                                                    name="Day"
                                                                    value={formvalue.Day}
                                                                    onChange={onChange}
                                                                    disabled={disabled ? true : false}
                                                                    placeholder="Enter Day"
                                                                />
                                                                {formerror.ItineraryDayError && <p className='text-danger text-start'>{formerror.ItineraryDayError}</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-sm-12 '>
                                                        <div className='row'>
                                                            <div class="col-lg-2 col-md-3 mb-1">
                                                                <label class="col-form-label required">Day Schedule</label>
                                                            </div>
                                                            <div class="col-lg-10 mb-1 col-md-9 dayschedule">
                                                                {/* <input type="text" id="inputPassword6" class="form-control"
                                                                    name="DaySchedule"
                                                                    value={formvalue.DaySchedule}
                                                                    onChange={onChange}
                                                                    disabled={disabled ? true : false}
                                                                    placeholder="Day Schedule"
                                                                /> */}
                                                                <RichTextBox field="DaySchedule" fieldvalue={formvalue.DaySchedule} tabIndex={1} OnChangeofRichBox={handleDayScheduleChange} textconfig={dayscheduleconfig} />
                                                                {formerror.ItineraryDayScheduleError && <p className='text-danger text-start'>{formerror.ItineraryDayScheduleError}</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-sm-12 '>
                                                        <div className={`${flightvisible ? '' : 'd-none'} row`} >
                                                            <div class="col-lg-2 col-md-12 mb-1">
                                                                <label class="col-form-label">Add Flight</label>
                                                            </div>
                                                            <div class="col-lg-1 col-md-2 mb-1">
                                                                <label class="col-form-label">From</label>
                                                            </div>
                                                            <div class="col-lg-3 mb-1 col-md-4">
                                                                {/* <Select
                                                                    className="dropdown select_dropdown"
                                                                    options={flightfrom}
                                                                    value={flightfrom?.filter(function (flightfrom) {
                                                                        return flightfrom.value === flightfromid;
                                                                    })}// set list of the data
                                                                    onChange={handleFlightFromchange} // assign onChange function
                                                                    isDisabled={disabled ? true : false}
                                                                    //isDisabled={flightdisable ? true : false}
                                                                    isSearchable
                                                                /> */}
                                                                <CustomSelection
                                                                    datalist={flightfrom}
                                                                    fieldid={flightfromid}
                                                                    SelectionChange={handleFlightFromchange}
                                                                    isdisable={disabled}
                                                                    isactivedisable={fromflightdisable}
                                                                    issearchable={true}
                                                                />
                                                                {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                            </div>
                                                            <div class="col-lg-1 col-md-2 mb-1">
                                                                <label class="col-form-label">To</label>
                                                            </div>
                                                            <div class="col-lg-3 mb-1 col-md-4">
                                                                {/* <Select
                                                                    className="dropdown select_dropdown"
                                                                    options={flightto}
                                                                    value={flightto?.filter(function (flightto) {
                                                                        return flightto.value === flighttoid;
                                                                    })}// set list of the data
                                                                    onChange={handleFlightToChange} // assign onChange function
                                                                    isDisabled={disabled ? true : false}
                                                                    //isDisabled={flightdisable ? true : false}
                                                                    isSearchable
                                                                /> */}
                                                                <CustomSelection
                                                                    datalist={flightto}
                                                                    fieldid={flighttoid}
                                                                    SelectionChange={handleFlightToChange}
                                                                    isdisable={disabled}
                                                                    isactivedisable={toflightdisable}
                                                                    issearchable={true}
                                                                />
                                                                {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                            </div>
                                                            <div class="col-lg-2 mb-1 col-md-12">
                                                                <button type="button" class="btn btn-primary mx-1" onClick={handleFlight} id="btnAdd"><i class={`fa fa-plus ${flightbuttontext ? "fa-minus" : "fa-plus"}`} aria-hidden="true"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`col-lg-12 col-sm-12 mt-3 mb-2 py-2 form_border ms-2 ${flightopen ? 'd-block' : 'd-none'} ${flightvisible ? '' : 'd-none'}`}>
                                                        <div className='row'>
                                                            <div class="col-lg-12 col-md-12 mb-1">
                                                                <label class="col-form-label ">Flight Details</label>
                                                            </div>
                                                            <div className='col-lg-12 col-sm-12 '>
                                                                <div className='row'>
                                                                    <div class="col-lg-1 col-md-2 mb-1">
                                                                        <label class="col-form-label ">From</label>
                                                                    </div>
                                                                    <div class="col-lg-3 mb-1 col-md-3">
                                                                        <Select
                                                                            className="dropdown select_dropdown"
                                                                            options={flightfrom}
                                                                            value={flightfrom?.filter(function (flightfrom) {
                                                                                return flightfrom.value === flightfromid;
                                                                            })}
                                                                            // onChange={handlesupplierchange} // assign onChange function
                                                                            isDisabled
                                                                            isSearchable
                                                                        />
                                                                        {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                                    </div>
                                                                    <div class="col-lg-1 col-md-2 mb-1">
                                                                        <label class="col-form-label ">To</label>
                                                                    </div>
                                                                    <div class="col-lg-3 mb-1 col-md-3">
                                                                        <Select
                                                                            className="dropdown select_dropdown"
                                                                            options={flightto}
                                                                            value={flightto?.filter(function (flightto) {
                                                                                return flightto.value === flighttoid;
                                                                            })}// set list of the data
                                                                            // onChange={handlesupplierchange} // assign onChange function
                                                                            isDisabled
                                                                            isSearchable
                                                                        />
                                                                        {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                                    </div>
                                                                    <div class="col-lg-1 col-md-3 mb-1">
                                                                        <label class="col-form-label ">Duration</label>
                                                                    </div>
                                                                    <div class="col-lg-3 mb-1 col-md-6">
                                                                        <input type="text" id="inputPassword6" class="form-control"
                                                                            name="Duration"
                                                                            value={formvalue.Duration}
                                                                            onChange={onChange}
                                                                            disabled
                                                                            placeholder="Duration"
                                                                        />
                                                                        {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-6 col-sm-6 '>
                                                                <div className='row'>
                                                                    <div class="col-lg-4 col-md-12 mb-1">
                                                                        <label class="col-form-label "> Select Airline Name</label>
                                                                    </div>
                                                                    <div class="col-lg-8 mb-1 col-md-12">
                                                                        <Select
                                                                            className="dropdown select_dropdown"
                                                                            options={airlinelist}
                                                                            value={airlinelist?.filter(function (airlinelist) {
                                                                                return airlinelist.value === airlineid;
                                                                            })}// set list of the data
                                                                            onChange={handleAirlineChange} // assign onChange function
                                                                            isDisabled={disabled ? true : false}
                                                                            isSearchable
                                                                        />
                                                                        {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-6 col-sm-6 '>
                                                                <div className='row'>
                                                                    <div class="col-lg-4 col-md-12 mb-1">
                                                                        <label class="col-form-label ">Flight No</label>
                                                                    </div>
                                                                    <div class="col-lg-8 mb-1 col-md-12">
                                                                        <Select
                                                                            className="dropdown select_dropdown"
                                                                            options={flightnolist}
                                                                            value={flightnolist?.filter(function (flightnolist) {
                                                                                return flightnolist.value === flightno;
                                                                            })}// set list of the data
                                                                            onChange={handleFlightNoChange} // assign onChange function
                                                                            isDisabled={disabled ? true : false}
                                                                            isSearchable
                                                                        />
                                                                        {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-6 col-sm-6 '>
                                                                <div className='row'>
                                                                    <div class="col-lg-4 col-md-12 mb-1">
                                                                        <label class="col-form-label ">Arrival Time</label>
                                                                    </div>
                                                                    <div class="col-lg-8 mb-1 col-md-12">
                                                                        {/* <input type="time" id="inputPassword6" class="form-control"
                                                                            name="ArrivalTimeD"
                                                                            value={formvalue.ArrivalTimeD}
                                                                            onChange={onChange}
                                                                            disabled
                                                                        /> */}
                                                                        <CustomTimePicker classid="ArrivalTimeD" filedvalue={arrivalTime} isDisable={true} handleOnChange={(e) => handleonchangeofTime("ArrivalTimeD", e)} />
                                                                        {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-6 col-sm-6 '>
                                                                <div className='row'>
                                                                    <div class="col-lg-4 col-md-12 mb-1">
                                                                        <label class="col-form-label ">Departure Time</label>
                                                                    </div>
                                                                    <div class="col-lg-8 mb-1 col-md-12">
                                                                        {/* <input type="time" id="inputPassword6" class="form-control"
                                                                            name="DepartureTimeD"
                                                                            value={formvalue.DepartureTimeD}
                                                                            onChange={onChange}
                                                                            disabled
                                                                        /> */}
                                                                        <CustomTimePicker classid="DepartureTimeD" filedvalue={departureTime} isDisable={true} handleOnChange={(e) => handleonchangeofTime("DepartureTimeD", e)} />
                                                                        {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-6 col-sm-6 '>
                                                                <div className='row'>
                                                                    <div class="col-lg-4 col-md-12 mb-1">
                                                                        <label class="col-form-label ">Select Class</label>
                                                                    </div>
                                                                    <div class="col-lg-8 mb-1 col-md-12">
                                                                        <Select
                                                                            className="dropdown select_dropdown"
                                                                            options={airlineclasslist}
                                                                            value={airlineclasslist?.filter(function (airlineclasslist) {
                                                                                return airlineclasslist.value === classid;
                                                                            })}// set list of the data
                                                                            onChange={handleclasschange} // assign onChange function
                                                                            isDisabled={disabled ? true : false}
                                                                            isSearchable
                                                                        />
                                                                        {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-6 col-sm-6 '>
                                                                <div className='row'>
                                                                    <div class="col-lg-4 col-md-12 mb-1">
                                                                        <label class="col-form-label ">Select Supplier</label>
                                                                    </div>
                                                                    <div class="col-lg-8 mb-1 col-md-12">
                                                                        <Select
                                                                            className="dropdown select_dropdown"
                                                                            options={supplierlist}
                                                                            value={supplierlist?.filter(function (supplierlist) {
                                                                                return supplierlist.value === supplierid;
                                                                            })}// set list of the data
                                                                            onChange={handlesupplierchange} // assign onChange function
                                                                            isDisabled={disabled ? true : false}
                                                                            isSearchable
                                                                        />
                                                                        {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-6 col-sm-6 '>
                                                                <div className='row'>
                                                                    <div class="col-lg-4 col-md-12 mb-1">
                                                                        <label class="col-form-label ">Price</label>
                                                                    </div>
                                                                    <div class="col-lg-8 mb-1 col-md-12">
                                                                        <input type="text" id="inputPassword6" class="form-control"
                                                                            name="FlightPrice"
                                                                            //disabled
                                                                            value={formvalue.FlightPrice}
                                                                            onChange={onChange}
                                                                            disabled={disabled ? true : false}
                                                                        />
                                                                        {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-sm-6 '>
                                                        <div className='row'>
                                                            <div class="col-lg-2 col-md-12 mb-1">
                                                                <label class="col-form-label">Add Hotels<br /> Or Similar Hotels</label>
                                                            </div>
                                                            <div class="col-lg-4 mb-1 col-md-12">
                                                                {/* <Select
                                                                    className="dropdown select_dropdown"
                                                                    options={hotelnamelistdata}
                                                                    value={hotelnamelistdata?.filter(function (hotelnamelistdata) {
                                                                        return hotelnamelistdata.value === hotelnameid;
                                                                    })}// set list of the data
                                                                    onChange={handlehotelnamechange} // assign onChange function
                                                                    isDisabled={disabled ? true : false}
                                                                    isSearchable
                                                                /> */}
                                                                <CustomSelection
                                                                    datalist={hotelnamelistdata}
                                                                    fieldid={hotelnameid}
                                                                    SelectionChange={handlehotelnamechange}
                                                                    isdisable={disabled}
                                                                    isactivedisable={hoteldisable}
                                                                    issearchable={true}
                                                                />
                                                                {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                            </div>
                                                            <div class="col-lg-2 mb-1 col-md-12">
                                                                <button type="button" class="btn btn-primary mx-1" onClick={() => handleHotel()} id="btnAdd"><i class={`fa fa-plus ${hotelbuttontext ? "fa-minus" : "fa-plus"}`} aria-hidden="true"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`col-lg-12 col-sm-12 mt-3 mb-2 py-2 form_border ms-2 ${hotelopen ? 'd-block' : 'd-none'}`}>
                                                        <div className='row'>
                                                            <div class="col-lg-12 col-md-12 mb-1">
                                                                <label class="col-form-label ">{hotelname}
                                                                    <Rating
                                                                        className='ms-2'
                                                                        size={20}
                                                                        allowFraction
                                                                        readonly
                                                                        initialValue={hotelrating}
                                                                    />
                                                                </label>
                                                            </div>
                                                            <div className='col-lg-6 col-sm-6 '>
                                                                <div className='row'>
                                                                    <div class="col-lg-4 col-md-12 mb-1">
                                                                        <label class="col-form-label ">Select Room Type</label>
                                                                    </div>
                                                                    <div class="col-lg-8 mb-1 col-md-12">
                                                                        <Select
                                                                            className="dropdown select_dropdown"
                                                                            options={roomtypelist}
                                                                            value={roomtypelist?.filter(function (roomtypelist) {
                                                                                return roomtypelist.value === hotelroomtypeid;
                                                                            })}// set list of the data
                                                                            onChange={handleRoomTypeChange} // assign onChange function
                                                                            isDisabled={disabled ? true : false}
                                                                            isSearchable
                                                                        />
                                                                        {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-6 col-sm-6 '>
                                                                <div className='row'>
                                                                    <div class="col-lg-4 col-md-12 mb-1">
                                                                        <label class="col-form-label ">No Of Pax</label>
                                                                    </div>
                                                                    <div class="col-lg-8 mb-1 col-md-12">
                                                                        <Select
                                                                            className="dropdown select_dropdown"
                                                                            options={roompricelist}
                                                                            value={roompricelist?.filter(function (roompricelist) {
                                                                                return roompricelist.value === noofpaxid;
                                                                            })}// set list of the data
                                                                            onChange={handleNoofpaxchange} // assign onChange function
                                                                            isDisabled={disabled ? true : false}
                                                                            isSearchable
                                                                        />
                                                                        {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-6 col-sm-6 '>
                                                                <div className='row'>
                                                                    <div class="col-lg-4 col-md-12 mb-1">
                                                                        <label class="col-form-label ">Price</label>
                                                                    </div>
                                                                    <div class="col-lg-8 mb-1 col-md-12">
                                                                        <input type="text" id="inputPassword6" class="form-control"
                                                                            name="RoomPriceD"
                                                                            disabled
                                                                            value={formvalue.HotelPrice}
                                                                            onChange={onChange}
                                                                        />
                                                                        {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-sm-6 '>
                                                        <div className='row'>
                                                            <div class="col-lg-2 col-md-12 mb-1">
                                                                <label class="col-form-label">Add Sightseeing</label>
                                                            </div>
                                                            <div class="col-lg-4 mb-1 col-md-12">
                                                                {/* <Select
                                                                    className="dropdown select_dropdown"
                                                                    options={sightseeinglistdata}
                                                                    value={sightseeinglistdata?.filter(function (sightseeinglistdata) {
                                                                        return sightseeinglistdata.value === sightseeingid;
                                                                    })}// set list of the data
                                                                    onChange={handlesightseeingchange} // assign onChange function
                                                                    isDisabled={disabled ? true : false}
                                                                    isSearchable
                                                                /> */}
                                                                <CustomSelection
                                                                    datalist={sightseeinglistdata}
                                                                    fieldid={sightseeingid}
                                                                    SelectionChange={handlesightseeingchange}
                                                                    isdisable={disabled}
                                                                    isactivedisable={sightseeingdisable}
                                                                    issearchable={true}
                                                                />
                                                                {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                            </div>
                                                            <div class="col-lg-2 mb-1 col-md-12">
                                                                <button type="button" class="btn btn-primary mx-1" onClick={handleSightseeing} id="btnAdd"><i class={`fa fa-plus ${sightseeingbuttontext ? "fa-minus" : "fa-plus"}`} aria-hidden="true"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`col-lg-12 col-sm-12 mt-3 mb-2 py-2 form_border ms-2 ${sightseeingopen ? 'd-block' : 'd-none'}`}>
                                                        <div className='row'>
                                                            <div class="col-lg-12 col-md-12 mb-1">
                                                                <label class="col-form-label ">{sightseeingname}</label>
                                                            </div>
                                                            <div className='col-lg-12 col-sm-12 '>
                                                                <div className='row'>
                                                                    <div class="col-lg-1 col-md-2 mb-1">
                                                                        <label class="col-form-label ">Pick Up Point</label>
                                                                    </div>
                                                                    <div class="col-lg-3 mb-1 col-md-3">
                                                                        <input type="text" id="inputPassword6" class="form-control"
                                                                            name="PickupPoint"
                                                                            disabled
                                                                            value={formvalue.PickUpPoint}
                                                                            placeholder='PickUpPoint'
                                                                        />
                                                                        {/* <Select
                                                                            className="dropdown select_dropdown"
                                                                            //options={dightseeinglistdata}
                                                                            // value={suppliernamelist.filter(function (suppliernamelist) {
                                                                            // return suppliernamelist.value === supplierid;
                                                                            // })}// set list of the data
                                                                            // onChange={handlesupplierchange} // assign onChange function
                                                                            isDisabled={true}
                                                                            isSearchable={false}
                                                                        /> */}
                                                                        {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                                    </div>
                                                                    <div class="col-lg-1 col-md-2 mb-1">
                                                                        <label class="col-form-label ">Drop Point</label>
                                                                    </div>
                                                                    <div class="col-lg-3 mb-1 col-md-3">
                                                                        <input type="text" id="inputPassword6" class="form-control"
                                                                            name="DropPoint"
                                                                            disabled
                                                                            value={formvalue.DropPoint}
                                                                            placeholder="DropPoint"
                                                                        />
                                                                        {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                                    </div>
                                                                    <div class="col-lg-1 col-md-3 mb-1">
                                                                        <label class="col-form-label ">Duration</label>
                                                                    </div>
                                                                    <div class="col-lg-3 mb-1 col-md-6">

                                                                        {/* <input type="text" id="inputPassword6" class="form-control"
                                                                            name="Hours"
                                                                            disabled
                                                                            value={formvalue.Hours}
                                                                            placeholder="Hours"
                                                                        /> */}
                                                                        <CustomTimePicker classid="Hours" filedvalue={hoursTime} isDisable={true} handleOnChange={(e) => handleonchangeofTime("Hours", e)} />
                                                                        {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-12 col-sm-12 '>
                                                                <div className='row'>
                                                                    <div class="col-lg-2 col-md-12 mb-1">
                                                                        <label class="col-form-label">Itinerary Details</label>
                                                                    </div>
                                                                    <div class="col-lg-10 mb-1 col-md-12">
                                                                        <textarea type='text' id="inputPassword6" class="form-control"
                                                                            name="ItineraryDetails"
                                                                            value={formvalue.ItineraryDetails}
                                                                            disabled
                                                                        ></textarea>
                                                                        {/* {formErrors.DepartureTime && <p className='text-danger text-start'>{formErrors.DepartureTime}</p>} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-12 col-sm-6 '>
                                                                <div className='row'>
                                                                    <div class="col-lg-2 col-md-12 mb-1">
                                                                        <label class="col-form-label "> Select Vehicle</label>
                                                                    </div>
                                                                    <div class="col-lg-4 mb-1 col-md-12">
                                                                        <Select
                                                                            className="dropdown select_dropdown"
                                                                            options={vehicalistdata}
                                                                            value={vehicalistdata?.filter(function (vehicalistdata) {
                                                                                return vehicalistdata.value === vehicaltypeid;
                                                                            })}// set list of the data
                                                                            onChange={handlevehicaltype} // assign onChange function
                                                                            isDisabled={disabled ? true : false}
                                                                            isSearchable

                                                                        />
                                                                        {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-6 col-sm-6 '>
                                                                <div className='row'>
                                                                    <div class="col-lg-4 col-md-12 mb-1">
                                                                        <label for="inputPassword7" class="col-form-label ">Price</label>
                                                                    </div>
                                                                    <div class="col-lg-8 mb-1 col-md-12">
                                                                        <input type="text" id="inputPassword7" class="form-control"
                                                                            name="VehicalPrice"
                                                                            disabled
                                                                            //value={formvalue.VehicalPrice}
                                                                            value={vehicalprice}
                                                                            placeholder="Vehicle Price"
                                                                        />
                                                                        {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex justify-content-center mt-3">
                                                        <button type="button" class="btn btn-primary mx-1" id="btnAdd" disabled={disabled ? true : false}
                                                            onClick={handleitinerary}
                                                        // onClick={onSubmitForm} disabled={editdisabled ? false : true}
                                                        >
                                                            {itinerarybtntext}
                                                        </button>
                                                        <button type="button" class="btn btn-primary mx-1" id="btnClear" disabled={disabled ? true : false}
                                                            onClick={handleclearItinerary}
                                                        >Clear</button>
                                                    </div>
                                                    <div class="my-3 row mx-1">
                                                        <div class="col-lg-12">
                                                            <div class="mb-3 row mx-1 form_border py-3">
                                                                <div class="col-lg-12 mb-1">
                                                                    <div class="row">
                                                                        <div class="col-lg-12 mb-1 table-scrollable">
                                                                            <table id="data" className="table table-hover" >
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Day
                                                                                        </th>
                                                                                        {/* <th>Day Schedule
                                                                                        </th> */}
                                                                                        <th className='text-end pe-4'>Edit
                                                                                        </th>
                                                                                        <th className='text-end pe-4'>Remove
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody id="location-data">
                                                                                    {dataitinerary?.map((element, index) => {
                                                                                        if (dataitinerarycounter > 0) {
                                                                                            return (
                                                                                                <tr class="ReactTable" key={index}>
                                                                                                    <td>{element.Day}</td>
                                                                                                    {/* <td>{element.DaySchedule}</td> */}
                                                                                                    <td className='text-end'><button type="button" onClick={(e) => { handleEditItinerary(element.SeqNo, e) }} class="btn btn-primary mx-1" id="btnEdit" disabled={disabled ? true : false}>Edit</button></td>
                                                                                                    <td className='text-end'><button type="button" onClick={(e) => { removeitinerary(element.SeqNo, e) }} class="btn btn-primary mx-1" id="btnAdd" disabled={disabled ? true : false}>Remove</button></td>
                                                                                                </tr>
                                                                                            );
                                                                                        }
                                                                                    })}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {(Shown.Inclusion === true) && <div class="accordion-item mb-3">
                                <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                                    <button class="accordion-button collapsed" type="button" id="InclusionError" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                        Inclusion
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                                    <div class="accordion-body">
                                        <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                                            <div class="row">
                                                {/* <label for="inputPassword6" class="col-form-label">Inclusion</label> */}
                                                <div class="row">
                                                    <div className='col-lg-12 col-sm-12 '>
                                                        <div className='row'>
                                                            {inclusionduplicateerror && <p className='text-center text-danger'>{inclusionduplicateerror}</p>}
                                                            {formerror.InclusionError && <p className='text-center text-danger'>{formerror.InclusionError}</p>}
                                                            <div class="col-lg-2 col-md-3 mb-1">
                                                                <label class="col-form-label required">Add Inclusion</label>
                                                            </div>
                                                            <div class="col-lg-4 mb-1 col-md-4">
                                                                <Select
                                                                    className="dropdown select_dropdown"
                                                                    // options={suppliernamelist}
                                                                    // value={suppliernamelist.filter(function (suppliernamelist) {
                                                                    // return suppliernamelist.value === supplierid;
                                                                    // })}// set list of the data
                                                                    // onChange={handlesupplierchange} // assign onChange function
                                                                    isDisabled={disabled ? true : false}
                                                                    name='InclusionD'
                                                                    value={inclusionlistdata?.find(({ value }) => value === inclusionvalue)}
                                                                    options={inclusionlistdata}
                                                                    onChange={handleinclusionchange}
                                                                    isSearchable
                                                                />
                                                                {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                            </div>
                                                            <div class="col-lg-4 mb-1 col-md-4">
                                                                <button type="button" onClick={InclusionClick} class="btn btn-primary mx-1" disabled={disabled ? true : false} id="btnAdd">Add</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='mb-1 col-lg-12 col-md-12 mt-3'>
                                                        <table id="data" className="table table-hover" >
                                                            <thead>
                                                                <tr>
                                                                    <th>Inclusion
                                                                    </th>
                                                                    {/* <th className='d-none'>Inclusion Type Id
                                                                    </th> */}
                                                                    <th className='text-end pe-4'>Remove
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="location-data">
                                                                {datainclusiondetails && datainclusiondetails?.map((element, index) => {
                                                                    if (datainclusioncounter > 0) {
                                                                        return (
                                                                            <tr class="ReactTable" key={index}>
                                                                                <td >{element.Inclusion}</td>
                                                                                {/* <td className='d-none'>{element.InclusionTypeId}</td> */}
                                                                                <td className='text-end'><button type="button" onClick={(e) => { inclusionremovev(element.SeqNo, e) }} class="btn btn-primary mx-1" id="btnAdd" disabled={disabled ? true : false} >Remove</button></td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {(Shown.Exclusion === true) && <div class="accordion-item mb-3">
                                <h2 class="accordion-header" id="panelsStayOpen-headingFour">
                                    <button class="accordion-button collapsed" id="ExclusionError" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                                        Exclusion
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                                    <div class="accordion-body">
                                        <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                                            <div class="row">
                                                {/* <label for="inputPassword6" class="col-form-label">Exclusion</label> */}
                                                <div class="row">
                                                    <div className='col-lg-12 col-sm-12 '>
                                                        <div className='row'>
                                                            {formerror.ExclusionError && <p className='text-center text-danger'>{formerror.ExclusionError}</p>}
                                                            {exclusionduplicateerror && <p className='text-center text-danger'>{exclusionduplicateerror}</p>}
                                                            <div class="col-lg-2 col-md-3 mb-1">
                                                                <label class="col-form-label required">Add Exclusion</label>
                                                            </div>
                                                            <div class="col-lg-4 mb-1 col-md-4">
                                                                <Select
                                                                    className="dropdown select_dropdown"
                                                                    // options={suppliernamelist}
                                                                    // value={suppliernamelist.filter(function (suppliernamelist) {
                                                                    // return suppliernamelist.value === supplierid;
                                                                    // })}// set list of the data
                                                                    // onChange={handlesupplierchange} // assign onChange function
                                                                    isDisabled={disabled ? true : false}
                                                                    name='ExclusionD'
                                                                    value={exclusionistdata.find(({ value }) => value === exclusionvalue)}
                                                                    options={exclusionistdata}
                                                                    onChange={handleexclusionchange}
                                                                    isSearchable
                                                                />
                                                                {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                            </div>
                                                            <div class="col-lg-4 mb-1 col-md-4">
                                                                <button type="button" onClick={ExclusionClick} disabled={disabled ? true : false} class="btn btn-primary mx-1" id="btnAdd">Add</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='mb-1 col-lg-12 col-md-12 mt-3'>
                                                        <table id="data" className="table table-hover" >
                                                            <thead>
                                                                <tr>
                                                                    <th>Exclusion
                                                                    </th>
                                                                    {/* <th className='d-none'>Inclusion Type Id
                                                                    </th> */}
                                                                    <th className='text-end pe-4'>Remove
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="location-data">
                                                                {dataexclusiondetails?.map((element, index) => {
                                                                    if (dataexclusioncounter > 0) {
                                                                        return (
                                                                            <tr class="ReactTable" key={index}>
                                                                                <td >{element.Exclusion}</td>
                                                                                {/* <td className='d-none'>{element.InclusionTypeId}</td> */}
                                                                                <td className='text-end'><button type="button" onClick={(e) => { exclusionremovev(element.SeqNo, e) }} class="btn btn-primary mx-1" id="btnAdd" disabled={disabled ? true : false} >Remove</button></td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {(Shown.Policies === true) && <div class="accordion-item mb-3">
                                <h2 class="accordion-header" id="panelsStayOpen-headingFive">
                                    <button class="accordion-button collapsed" id="PolicyError" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                                        Policy
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
                                    <div class="accordion-body">
                                        <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                                            <div class="row">
                                                {/* <label for="inputPassword6" class="col-form-label">Policy</label> */}
                                                <div class="row">
                                                    {formerror.PolicyError && <p className='text-center text-danger'>{formerror.PolicyError}</p>}
                                                    {policyduplicateerror && <p className='text-center text-danger'>{policyduplicateerror}</p>}
                                                    <div className='col-lg-12 col-sm-12 '>
                                                        <div className='row'>
                                                            <div class="col-lg-2 col-md-3 mb-1">
                                                                <label class="col-form-label required">Add Policy</label>
                                                            </div>
                                                            <div class="col-lg-4 mb-1 col-md-4">
                                                                <Select
                                                                    className="dropdown select_dropdown"
                                                                    // options={suppliernamelist}
                                                                    // value={suppliernamelist.filter(function (suppliernamelist) {
                                                                    // return suppliernamelist.value === supplierid;
                                                                    // })}// set list of the data
                                                                    // onChange={handlesupplierchange} // assign onChange function
                                                                    isDisabled={disabled ? true : false}
                                                                    name='PolicyD'
                                                                    value={policylistdata?.find(({ value }) => value === policyvalue)}
                                                                    options={policylistdata}
                                                                    onChange={handlepolicyonchange}
                                                                    isSearchable
                                                                />
                                                                {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                            </div>
                                                            <div class="col-lg-4 mb-1 col-md-4">
                                                                <button type="button" onClick={PolicyClick} class="btn btn-primary mx-1" disabled={disabled ? true : false} id="btnAdd">Add</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='mb-1 col-lg-12 col-md-12 mt-3'>
                                                        <table id="data" className="table table-hover" >
                                                            <thead>
                                                                <tr>
                                                                    <th>Policy
                                                                    </th>
                                                                    {/* <th className='d-none'>Inclusion Type Id
                                                                    </th> */}
                                                                    <th className='text-end pe-4'>Remove
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="location-data">
                                                                {datapolicydetails?.map((element, index) => {
                                                                    if (datapolicycounter > 0) {
                                                                        return (
                                                                            <tr class="ReactTable" key={index}>
                                                                                <td >{element.Policy}</td>
                                                                                {/* <td className='d-none'>{element.InclusionTypeId}</td> */}
                                                                                <td className='text-end'><button type="button" onClick={(e) => { policyremovev(element.SeqNo, e) }} disabled={disabled ? true : false} class="btn btn-primary mx-1" id="btnAdd" >Remove</button></td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            {(Shown.Summary === true) && <div class="accordion-item mb-3">
                                <h2 class="accordion-header" id="panelsStayOpen-headingSix">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false" aria-controls="panelsStayOpen-collapseSix">
                                        Summary
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseSix" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSix">

                                    <div id="Summary" class="tabcontentpkg">
                                        <div class="summary-inner">
                                            {/* <p class="fs-5 text-dark">Summary</p> */}
                                            <div class="summaer-box position-relative">
                                                {PkgSummary?.map((curElem) => {
                                                    return (
                                                        <>
                                                            <div class="accordion-body">
                                                                <div class="summary-title setclr">
                                                                    <ul class="p-0 d-flex setclr">
                                                                        <li><p class="text-dark m-0 fw-normal">{curElem.day}</p></li>
                                                                    </ul>
                                                                </div>

                                                                {(curElem.flightNo != null && curElem.flightNo !== "") && (
                                                                    <div class="day1-box day2box setclr">
                                                                        <ul class="row">
                                                                            <li class="col-lg-2 col-md-2 col-2 text-center" style={{ width: "100px" }}><img src="assets/img/flight.png" alt='' /> <p class="text-muted">Flight</p></li>
                                                                            <li class="col-lg-2 col-md-2 col-12 text-center text-lg-start ps-4">
                                                                                <div><p class="m-0 ">{curElem.airlineName}</p><p class="m-0">{curElem.flightNo}</p></div>
                                                                            </li>
                                                                            <li class="col-lg-2 col-md-2 col-12 text-center text-lg-start ps-4"><p class="fs-5 m-0 text-dark">{formatTime(curElem.departureTime)}</p><p class="fs-6">{curElem.flightFrom}</p></li>
                                                                            <li class='col-lg-2 col-md-2 col-12 text-center text-lg-start ps-4'><span class='d-flex align-items-center justify-content-center justify-content-lg-start'>{curElem.duration}</span><div class="d-flex align-items-center justify-content-center justify-content-lg-start"><span class="border_top"></span><img src="assets/img/hand.png" /></div></li>
                                                                            <li class="col-lg-2 col-md-2 col-12 text-center text-lg-start ps-4"><p class="fs-5 m-0 text-dark">{formatTime(curElem.arrivalTime)}</p><p class="fs-6">{curElem.flightTo}</p></li>
                                                                        </ul>
                                                                    </div>)}
                                                                {curElem.hotelName != null && (
                                                                    <div class="day1-box">
                                                                        <ul class="row">
                                                                            <li class="col-lg-2 col-md-2 col-2 text-center" style={{ width: "100px" }}><img src="assets/img/hotel.png" class="img-fluid" alt='' /> <p class="text-muted">Hotel</p></li>
                                                                            <li class="col-lg-10 col-md-10 col-10 ps-4">
                                                                                <div className='row'>
                                                                                    <div className='col-lg-4 col-md-6 col-12'>
                                                                                        <img src={'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + curElem.documentPath} class="me-2 img-fluid" alt='' /></div>
                                                                                    <div className='col-lg-8 col-md-6 col-12'>
                                                                                        <p class="m-0 text-dark">{curElem.hotelName}</p>
                                                                                        <div class="star mb-2">
                                                                                            <ul class="d-flex p-0">
                                                                                                <Rating size={16} allowFraction readonly initialValue={curElem.hotelRating} />
                                                                                            </ul>
                                                                                        </div>
                                                                                        <p class="m-0">{curElem.roomType}<br />
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>)}

                                                                < div class="day1-box">
                                                                    <ul class="row">
                                                                        <li class="col-lg-2 col-md-2 col-2 text-center" style={{ width: "100px" }}><img src="assets/img/explore.png" alt='' /> <p class="text-muted">Explore</p></li>
                                                                        <li className='col-lg-10 col-md-10 col-10 ps-4'>
                                                                            <p class="text-dark m-0">{curElem.sightSeeingCity}</p>
                                                                            <ul class="d-flex flex-column p-0">
                                                                                <li class="p-0">
                                                                                    <p class="text-muted mb-0 defualt-unstyled wraptextcont" dangerouslySetInnerHTML={{ __html: curElem.daySchedule }}></p>
                                                                                </li>
                                                                            </ul>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>}
                            {(Shown.About === true) && <div class="accordion-item mb-3">
                                <h2 class="accordion-header" id="panelsStayOpen-headingSeven">
                                    <button class="accordion-button collapsed" type="button" id="AboutPlaceError" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded="false" aria-controls="panelsStayOpen-collapseSeven">
                                        About Place
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseSeven" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSeven">
                                    <div class="accordion-body">
                                        <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                                            <div class="row">
                                                {/* <label for="inputPassword6" class="col-form-label">About Place</label> */}
                                                <div class="row">
                                                    <div className='col-lg-12 col-sm-12 '>
                                                        <div className='row'>
                                                            {formerror.AboutPlaceError && <p className='text-center text-danger'>{formerror.AboutPlaceError}</p>}
                                                            <div class="col-lg-2 col-md-3 mb-1">
                                                                <label class="col-form-label required">Select City</label>
                                                            </div>
                                                            <div class="col-lg-4 mb-1 col-md-4">
                                                                {/* <Select
                                                                    className="dropdown select_dropdown"
                                                                    name='CityD'
                                                                    value={aboutplacecitydatalist?.filter(function (aboutplacecitydatalist) {
                                                                        return aboutplacecitydatalist.value === cityvalue;
                                                                    })}// set list of the data
                                                                    // value={aboutplacecitydatalist.find(({ value }) => value === cityvalue)}
                                                                    options={aboutplacecitydatalist}
                                                                    onChange={handlecityonchange}
                                                                    isSearchable
                                                                    isDisabled={disabled ? true : false}
                                                                /> */}
                                                                <CustomSelection
                                                                    datalist={aboutplacecitydatalist}
                                                                    fieldid={cityvalue}
                                                                    SelectionChange={handlecityonchange}
                                                                    isdisable={disabled}
                                                                    isactivedisable={aboutcitydisable}
                                                                    issearchable={true}
                                                                />
                                                                {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div className='col-lg-12 col-sm-12 '>
                                                        <div className='row'>
                                                            <div class="col-lg-2 col-md-3 mb-1">
                                                                <label class="col-form-label required">City Details</label>
                                                            </div>
                                                            <div class="col-lg-10 mb-1 col-md-9">

                                                                <RichTextBox field="CityDetailsD" fieldvalue={formvalue.CityDetailsD} tabIndex={1} OnChangeofRichBox={handlerichtextbox} textconfig={config} />
                                                                <JoditEditor
                                                                        className='col-12'
                                                                        ref={editor}
                                                                        name="CityDetailsD"
                                                                        config={config}
                                                                        value={formvalue.CityDetailsD}
                                                                        tabIndex={1} // tabIndex of textarea
                                                                        onChange={handlerichtextbox}

                                                                    />
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    {/* <div className='col-lg-6 col-sm-6 '>
                                                        <div className='row'>
                                                            <div class="col-lg-4 col-md-12 col-sm-12 mb-lg-1">
                                                                <label class="col-form-label required">Upload City Image</label>

                                                            </div>
                                                            <div class="col-lg-8 col-md-12 col-sm-12 mb-lg-1">
                                                                <div class="input-group  mb-1">
                                                                    <input
                                                                        name='CityImage'
                                                                        type="file"
                                                                        id="txtcityimages"
                                                                        onChange={handleUploadImageLoad}
                                                                        class="form-control btn_add"
                                                                        accept=".png,.jpg"
                                                                        disabled={disabled ? true : false}
                                                                    />
                                                                    <label class="input-group-text" for="txtcityimages">Browse</label>

                                                                </div>
                                                                {(placeimagename && aboutplacebtntext === 'Update') && <p className='text-start'>{placeimagename}</p>}
                                                                {imageError && <p className='text-danger text-start'>{imageError}</p>}

                                                                {fileselected &&
                                                                    (
                                                                        <div className="col-lg-3 col-md-3 col-6 mx-auto mt-2">
                                                                            <img
                                                                                src={URL.createObjectURL(fileselected)} className="img_thumb w-100" alt=''
                                                                            />
                                                                            <button className='closebtnimg' onClick={() => removeImage()} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }}
                                                                            // disabled={disabled ? true : false} 
                                                                            >&times;</button>
                                                                        </div>
                                                                    )}
                                                                {(!fileselected && placeimagepath !== '') &&
                                                                    (
                                                                        <div className="col-lg-3 col-md-3 col-6 mx-auto mt-2">
                                                                            <img
                                                                                src={placeimagepath} className="img_thumb w-100" alt=''
                                                                            />
                                                                            <button className='closebtnimg' onClick={() => removeImage()} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }}
                                                                            // disabled={disabled ? true : false} 
                                                                            >&times;</button>
                                                                        </div>
                                                                    )}

                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    <div className='col-lg-12 col-sm-12 '>
                                                        <div className='row'>
                                                            <div class="col-lg-2 col-md-3 mb-1">
                                                                <label class="col-form-label required">City Details</label>
                                                            </div>
                                                            <div class="col-lg-10 mb-1 col-md-9">

                                                                <RichTextBox field="CityDetailsD" fieldvalue={formvalue.CityDetailsD !== null ? formvalue.CityDetailsD : ''} tabIndex={1} OnChangeofRichBox={handlerichtextbox} textconfig={config} />
                                                                {/* <textarea className='col-12' rows='4' value={aboutCityDetail} readOnly disabled={disabled ? true : false} ></textarea> */}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div className='col-lg-12 col-sm-12 '> */}
                                                    <div className='col-lg-6 col-sm-6'>
                                                        <div className='row'>
                                                            <div class="col-lg-4 col-md-12 col-sm-12 mb-lg-1">
                                                                <label class="col-form-label">City Image</label>

                                                            </div>
                                                            <div class="col-lg-8 col-md-12 col-sm-12 mb-lg-1">
                                                                {/* <div class="input-group  mb-1">
                                                                    <input
                                                                        //ref={inputRef}
                                                                        name='CityImage'
                                                                        type="file"
                                                                        id="txtcityimages"
                                                                        onChange={handleUploadImageLoad}
                                                                        class="form-control btn_add"
                                                                        accept=".png,.jpg"
                                                                        disabled={disabled ? true : false}
                                                                    />
                                                                    <label class="input-group-text" for="txtcityimages">Browse</label>

                                                                </div> */}
                                                                {/* {(placeimagename && aboutplacebtntext === 'Update') && <p className='text-start'>{placeimagename}</p>} */}
                                                                {/* {imageError && <p className='text-danger text-start'>{imageError}</p>} */}

                                                                {/* {fileselected &&
                                                                    (
                                                                        <div className="col-lg-3 col-md-3 col-6 mx-auto mt-2">
                                                                            <img
                                                                                src={URL.createObjectURL(fileselected)} className="img_thumb w-100" alt=''
                                                                            />
                                                                            <button className='closebtnimg' onClick={() => removeImage()} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }}
                                                                            >&times;</button>
                                                                        </div>
                                                                    )} */}
                                                                {/* {(!fileselected && placeimagepath !== '') &&
                                                                    (
                                                                        <div className="col-lg-3 col-md-3 col-6 mx-auto mt-2">
                                                                            <img
                                                                                src={placeimagepath} className="img_thumb w-100" alt=''
                                                                            />
                                                                            <button className='closebtnimg' onClick={() => removeImage()} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }}
                                                                            >&times;</button>
                                                                        </div>
                                                                    )} */}
                                                                {(placeimagepath && placeimagepath !== 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/null' && placeimagepath !== 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/') &&
                                                                    (
                                                                        <>
                                                                            {/* <div className="col-lg-3 col-md-3 col-6 mt-2 mb-2">
                                                                                <img src={placeimagepath} className="img_thumb w-100 city_image" alt='' />
                                                                            </div> */}

                                                                            <div className="col-lg-3 col-md-3 col-6 my-2 cityimage">
                                                                                <img src={placeimagepath} className="cityimage" alt='' />
                                                                            </div>

                                                                            {/* <div className="col-lg-3 col-md-3 col-6 ">
                                                                                <img src={placeimagepath} className="img_thumb w-100" alt='' />
                                                                            </div> */}
                                                                        </>
                                                                    )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 mb-1 col-md-12 d-flex justify-content-center">
                                                        <button type="button" onClick={CityClick} disabled={disabled ? true : false} class="btn btn-primary mx-1" id="btnAboutPlaceAdd">{aboutplacebtntext}</button>
                                                    </div>
                                                    <div className='mb-1 col-lg-12 col-md-12 mt-3'>
                                                        <table id="data" className="table table-hover" >
                                                            <thead>
                                                                <tr>
                                                                    <th>City
                                                                    </th>
                                                                    {/* <th>City Details
                                                                    </th> */}
                                                                    <th className='text-end pe-4'>Edit
                                                                    </th>
                                                                    <th className='text-end pe-4'>Remove
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="location-data">
                                                                {datacitydetails?.map((element, index) => {

                                                                    if (datacitycounter > 0) {
                                                                        return (
                                                                            <tr class="ReactTable" key={index}>
                                                                                <td >{element.CityName}</td>
                                                                                {/* <td >{element.CityDetails}</td> */}
                                                                                {/* <td className='d-none'>{element.InclusionTypeId}</td> */}
                                                                                <td className='text-end'><button type="button" onClick={(e) => { cityeditdata(element.SeqNo, e) }} class="btn btn-primary mx-1" id="btnedit" disabled={disabled ? true : false}>Edit</button></td>
                                                                                <td className='text-end'><button type="button" onClick={(e) => { cityremovev(element.SeqNo, e) }} class="btn btn-primary mx-1" id="btnAdd" disabled={disabled ? true : false}>Remove</button></td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                })}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {(Shown.Visibility === true) &&

                                <div class="accordion mt-3" id="accordionPanelsStayOpenExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="panelsStayOpen-headingVisibility">
                                            <button class="accordion-button collapsed border_set" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseVisibility" aria-expanded="false" aria-controls="panelsStayOpen-collapseVisibility">
                                                Home page Section Visibility
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseVisibility" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingVisibility">
                                            <div class="accordion-body">
                                                <div className='col-lg-12 col-12 col-md-12 '>
                                                    <div className='row'>
                                                        <div className='col-lg-6 col-sm-6 '>
                                                            <div className='row'>
                                                                <div class="col-lg-1 mb-1 col-md-12">
                                                                    <input className="form-check-input mt-2 me-2" type="checkbox" id="checkboxNoLabel"
                                                                        value={formvalue.IsHotDeals} onChange={onChange} disabled={disabled ? true : false} checked={hotdeals ? true : false}
                                                                        aria-label="..." name="IsHotDeals" />
                                                                </div>
                                                                <div class="col-lg-4 col-md-12 mb-1">
                                                                    <label class="col-form-label">Hot Deals</label>
                                                                </div>
                                                                <div class="col-lg-6 mb-1 col-md-12">
                                                                    <input type="text" id="inputPassword6" class="form-control"
                                                                        name="HotOfferPercentage"
                                                                        value={formvalue.HotOfferPercentage}
                                                                        onChange={onChange}
                                                                        disabled={disabled ? true : false}
                                                                        placeholder="Enter the Offer %"
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 col-sm-6 '>
                                                            <div className='row'>
                                                                <div class="col-lg-1 mb-1 col-md-12">
                                                                    {/* <input class="form-check-input" type="checkbox" name ="SectionVisibility" value={formvalue.IsBestPackage} onChange={onChange} 
                                                                    id="flexCheckChecked" disabled={disabled ? true :false} checked={bestpackage ? true : false}/> */}
                                                                    <input className="form-check-input mt-2 me-2" type="checkbox" id="checkboxNoLabel"
                                                                        aria-label="..." name="IsBestDestination"
                                                                        value={formvalue.IsBestDestination} onChange={onChange} disabled={disabled ? true : false} checked={isbestdestinatio ? true : false} />
                                                                </div>
                                                                <div class="col-lg-11 mb-1 col-md-12">
                                                                    <label class="col-form-label">Explore the Best Destinations Around the World</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 col-sm-6 '>
                                                            <div className='row'>
                                                                <div class="col-lg-1 mb-1 col-md-12">
                                                                    <input className="form-check-input mt-2 me-2" type="checkbox" id="checkboxNoLabel"
                                                                        value={formvalue.IsInternationalDestination} onChange={onChange} disabled={disabled ? true : false} checked={isinternationaldestination ? true : false}
                                                                        aria-label="..." name="IsInternationalDestination" />
                                                                </div>
                                                                <div class="col-lg-11 mb-1 col-md-12">
                                                                    <label class="col-form-label">International Destinations</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 col-sm-6 '>
                                                            <div className='row'>
                                                                <div class="col-lg-1 mb-1 col-md-12">
                                                                    <input className="form-check-input mt-2 me-2" type="checkbox" id="checkboxNoLabel"
                                                                        value={formvalue.IsTrendingHoliday} onChange={onChange} disabled={disabled ? true : false} checked={istrendingholiday ? true : false}
                                                                        aria-label="..." name="IsTrendingHoliday" />
                                                                </div>
                                                                <div class="col-lg-11 mb-1 col-md-12">
                                                                    <label class="col-form-label">Trending Holidays in India</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            }
                            {(Shown.Price === true) &&

                                <div class="accordion mt-3" id="accordionPanelsStayOpenExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="panelsStayOpen-headingPricing">
                                            <button class="accordion-button collapsed border_set" type="button" id="PricingError" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsePricing" aria-expanded="false" aria-controls="panelsStayOpen-collapsePricing">
                                                Pricing
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapsePricing" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingPricing">
                                            <div class="accordion-body">
                                                {/* <div className='col-lg-12 col-12 col-md-12 '>
                                                    <div className='row'>
                                                        <div className='col-lg-6 col-sm-6 '>
                                                            <div className='row'>
                                                                <div class="col-lg-4 col-md-12 mb-1">
                                                                    <label class="col-form-label">Children</label>
                                                                </div>
                                                                <div class="col-lg-6 mb-1 col-md-12">
                                                                    <input type="text" id="inputPassword6" class="form-control"
                                                                        name="HotOfferPercentage"
                                                                        value={formvalue.HotOfferPercentage}
                                                                        onChange={onChange}
                                                                        disabled={disabled ? true : false}
                                                                        placeholder="Enter the Offer %"
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div class="row px-3 mb-3">
                                                    <div className='form_border'>
                                                        <label class="col-form-label fw-bold">Children</label>
                                                        <div class="row pb-3">
                                                            <div className='col-lg-6 col-sm-6 '>
                                                                <div className='row'>
                                                                    <div class="col-lg-4 col-md-12 mb-1">
                                                                        <label class="col-form-label required">Price</label>
                                                                    </div>
                                                                    <div class="col-lg-8 mb-1 col-md-12">
                                                                        <input type="text" id="inputPassword6" class="form-control"
                                                                            name="ChildrenPrice"
                                                                            value={childprice}
                                                                            maxLength={10}
                                                                            onChange={(e) => handleChildPrice(e)}
                                                                            disabled={disabled ? true : false}
                                                                        />
                                                                        {formerror.ChildrenPrice && <p className='text-danger text-start'>{formerror.ChildrenPrice}</p>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            }
                        </div>

                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-4 col-md-12 mb-1">
                                    <label class="col-form-label required">Package Basic price</label>
                                </div>
                                <div class="col-lg-8 mb-1 col-md-12">
                                    <input type="text" id="inputPassword6" class="form-control"
                                        name="PackageBasicPrice"
                                        value={formvalue.PackageBasicPrice}
                                        // onChange={onChange}
                                        //disabled={disabled ? true : false}
                                        placeholder="Package Basic price"
                                        readOnly
                                    />
                                    {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-4 col-md-12 mb-1">
                                    <label class="col-form-label required">Add Mark Up</label>
                                </div>
                                <div class="col-lg-8 mt-1 mb-1 col-md-12">
                                    <div class="form-check-inline">
                                        <input class="form-check-input" type="radio" name="flexRadioMarkup" value={0} key={0} id="flexRadioMarkup1" onChange={handlefixtypetax} checked={gsttype === 'FIX' ? true : false} disabled={disabled ? true : false} />
                                        <label class="form-check-label ms-1" for="flexRadioMarkup1">
                                            Fix
                                        </label>
                                    </div>
                                    <div class="form-check-inline">
                                        <input class="form-check-input" type="radio" name="flexRadioMarkup" value={1} key={1} id="flexRadioMarkup2" onChange={handlegsttypechange} checked={gsttype === 'GST' ? true : false} disabled={disabled ? true : false} />
                                        <label class="form-check-label ms-1" for="flexRadioMarkup2">
                                            Percentage
                                        </label>
                                    </div>
                                    <div class="col-lg-8 mb-1 col-md-12">
                                        <input type="text" id="inputPassword6" class="form-control"
                                            name="PackageFixPrice"
                                            value={formvalue.PackageFixPrice}
                                            onChange={onChange}
                                            disabled={disabled ? true : false}
                                            placeholder="Package Fix Price"
                                        //readOnly
                                        />

                                    </div>
                                    {formerror.MarkupvalueError && <p className='text-danger text-start'>{formerror.MarkupvalueError}</p>}
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-4 col-md-12 mb-1">
                                    <label class="col-form-label required">GST</label>
                                </div>
                                <div class="col-lg-8 mb-1 col-md-12">
                                    <Select
                                        className="dropdown select_dropdown"
                                        value={gstlist?.filter(function (gstlist) {
                                            return gstlist.value === gstid;
                                        })}// set list of the data
                                        options={gstlist}
                                        // value={suppliernamelist.filter(function (suppliernamelist) {
                                        // return suppliernamelist.value === supplierid;
                                        // })}// set list of the data
                                        // onChange={handlesupplierchange} // assign onChange function
                                        isDisabled
                                        onChange={handlegstonchange}
                                        isSearchable
                                    />
                                    {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-4 col-md-12 mb-1">
                                    <label class="col-form-label required">Package Final Price</label>
                                </div>
                                <div class="col-lg-8 mb-1 col-md-12">
                                    <input type="text" id="inputPassword6" class="form-control"
                                        name="PackageFinalPrice"
                                        value={pkgfinalprice}
                                        //onChange={onChange}
                                        disabled={disabled ? true : false}
                                        placeholder="Package Final Price"
                                        readOnly
                                    />
                                    {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <button type="button" class="btn btn-primary mx-1" id="btnbasicdraft"
                            onClick={(e) => onBasicDraftSubmit(e)} disabled={basicdraftdisabled ? true : false} style={{ display: isbasicShown ? 'block' : 'none' }}>
                            Save As Draft
                        </button>
                        <button type="button" class="btn btn-primary mx-1" id="btnAdd" style={{ display: isAddDraftShown ? 'block' : 'none' }}
                            onClick={(e) => onDraftSubmit(e)} disabled={editdisabled ? true : false}
                        >
                            Save As Draft
                        </button>
                        <button type="button" class="btn btn-primary mx-1" id="btnAdd" style={{ display: isAddShown ? 'block' : 'none' }}
                            //disabled={true}//disabled={editdisabled ? false : true}
                            onClick={(e) => onSubmitData(e)} disabled={editdisabled ? true : false}
                        >
                            Submit
                        </button>

                        <button type="button" class="btn btn-primary mx-1" id="btnClear"
                            onClick={handleclear} disabled={cleardisabled ? true : false}
                        >Clear</button>

                        <button type="button" class="btn btn-primary mx-1" id="btnVisible"
                            onClick={handleBackButton} disabled={backdisable ? true : false}
                        >Back</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Packagemaster