import React from 'react';
import { useEffect, useState } from "react";
import Select from 'react-select';
import "../../assets/css/master.css"
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../services/ApiCallingUserServices';
import Switch from 'react-switch';
import exportFromJSON from 'export-from-json'
import { onlyAlphabets, useSortableData, generatePDF, isValidImage, isValidEmail, onlyNumeric } from '../commonClass/CommonValidation'
import { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { loaderActions } from "../../_actions/loader.actions";
import { ConfirmationModal } from '../../customControl/ConfirmationModal';
import { ClearLocalStorage, GetLocalStorage } from '../commonClass/Session';

const Useradd = () => {
    const user = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [editdisabled, setEditDisabled] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [rololist, setRoleList] = useState([]);
    const [roleid, setRoleId] = useState(0);
    const [genderlist, setGenderList] = useState([]);
    const [genderid, setGenderId] = useState(0);
    const [buttonaddeditText, setButtonAddEditText] = useState("Add");
    const [retrivedata, setRetriveData] = useState([]);
    const [activeId, setActiveId] = useState(false);
    const [disable, setDisabled] = useState(false)
    const [passwordType, setPasswordType] = useState(false);
    const [departmentList, setDepartmentList] = useState([]);
    const [departmentid, setDepartmentId] = useState(0);
    const [formdata, setFormDate] = useState({
        FirstName: '',
        MiddleName: '',
        LastName: '',
        UserEmail: '',
        ContactNo: '',
        GenderId: 0,
        UserName: '',
        RoleId: 0,
        DepartmentId: 0,
        IsActive: false,
        Password: '',
        ConfirmPassword: '',
        UserMasterId: 0,
        UserType: 0
    })
    const handleRetrive = async () => {
        setButtonAddEditText("Update");
        setEditDisabled(true)
        setPasswordType(true)
        const UserId = user?.state?.Id;
        const UserById = await getAllData('/auth/v1/getuserlistdatabyid?usermasterid=' + UserId);
        setRetriveData(UserById.data);
    }
    const getUserRoleLit = async () => {
        await getAllData('/auth/v1/getuserrolelist?UserType=' + formdata.UserType).then((roledata) => {
            setRoleList(roledata.data?.map((el) => ({
                label: el.RoleName,
                value: el.RoleId
            })))
            if (user.state.Id > 0) {
                handleRetrive();
                setDisabled(true);
            }
        })
    }
    const getGenderList = async () => {
        await getAllData('/auth/v1/getusergenderlist').then((genderdata) => {
            setGenderList(genderdata.data?.map((el) => ({
                label: el.Gender,
                value: el.GenderId
            })))
        })
    }
    const getDepartmentList = async () => {
        await getAllData('/auth/v1/getuserdepartmentlist?UserType=' + formdata.UserType).then((departmentdata) => {
            setDepartmentList(departmentdata.data?.map((el) => ({
                label: el.DepartmentName,
                value: el.DepartmentId
            })))
        })
    }
    const RetriveData = () => {
        retrivedata?.map(obj => {
            setFormDate({
                ...formdata,
                FirstName: obj.FirstName,
                MiddleName: obj.MiddleName,
                LastName: obj.LastName,
                UserEmail: obj.Email,
                ContactNo: obj.ContactNo,
                GenderId: obj.GenderId,
                UserName: obj.UserName,
                RoleId: obj.RoleId,
                DepartmentId: obj.DepartmentId,
                IsActive: obj.IsActive,
                Password: obj.Password,
                ConfirmPassword: obj.Password,
                UserMasterId: obj.UserMasterID
            })
            setActiveId(obj.IsActive);
            setGenderId(obj.GenderId);
            setRoleId(obj.RoleId);
            setDepartmentId(obj.DepartmentId);
        })
    }
    const onChange = (e) => {
        var result;
        if ([e.target.name][0] === 'FirstName' || [e.target.name][0] === "MiddleName" || [e.target.name][0] === "LastName") {
            result = onlyAlphabets(e.target.value);
        }
        else if ([e.target.name][0] === "ContactNo") {
            result = onlyNumeric(e.target.value);
        }
        else {
            result = e.target.value
        }
        setFormDate({ ...formdata, [e.target.name]: result });
        setFormErrors({});
        setEditDisabled(false)
    }
    const handleRoleChange = (e) => {
        setFormErrors({});
        setRoleId(e.value);
        setFormDate({ ...formdata, RoleId: e.value })
        setEditDisabled(false)
    }
    const handleGenderChange = (e) => {
        setFormErrors({});
        setGenderId(e.value);
        setFormDate({ ...formdata, GenderId: e.value })
        setEditDisabled(false)
    }
    const handleDepartmentChange = (e) => {
        setFormErrors({});
        setDepartmentId(e.value);
        setFormDate({ ...formdata, DepartmentId: e.value })
        setEditDisabled(false)
    }
    const handleisActive = (e) => {
        if (buttonaddeditText === 'Add') {
            formdata.IsActive = e
            setActiveId(!activeId);
            setFormErrors({});
        }
        else {
            setModalShow(true);
        }
    }
    const handleConfirmation = (e) => {
        if (e === true) {
            formdata.IsActive = !activeId
            setActiveId(!activeId)
            setEditDisabled(false)
        }
        setModalShow(false);
    }
    const validateData = () => {
        let error = {};
        let status = true;
        if (formdata.FirstName?.trim()?.length === 0 || formdata.FirstName == null) {
            error.FirstName = "First name is required";
            status = false;
        }
        if (formdata.LastName?.trim()?.length === 0 || formdata.LastName == null) {
            error.LastName = " Last name is required";
            status = false;
        }
        if (formdata.UserEmail?.trim()?.length === 0 || formdata.UserEmail == null) {
            error.UserEmail = "Email Id is required";
            status = false;
        }
        if (formdata.UserEmail?.trim()?.length > 0 && !isValidEmail(formdata.UserEmail)) {
            error.UserEmail = "Email Id should in a format abc@xyz.com";
            status = false;
        }
        if (formdata.ContactNo?.trim()?.length === 0 || formdata.ContactNo == null) {
            error.ContactNo = "Mobile number is required";
            status = false;
        }
        if (formdata.ContactNo?.trim()?.length > 0 && formdata.ContactNo?.trim()?.length < 10) {
            error.ContactNo = "Mobile number should be 10 digit long";
            status = false;
        }
        if (formdata.UserName?.trim()?.length === 0 || formdata.UserName == null) {
            error.UserName = "Username is required";
            status = false;
        }
        if (formdata.DepartmentId === 0 || formdata.DepartmentId === null) {
            error.DepartmentId = "Kindly select department name.";
            status = false;
        }
        if (formdata.RoleId === 0) {
            error.RoleId = " Please select role";
            status = false;
        }
        if (formdata.Password?.trim()?.length === 0 || formdata.Password == null) {
            error.Password = "Kindly enter the password";
            status = false;
        }
        if (formdata.Password?.trim()?.length > 0) {
            let passreg = new RegExp(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_]).+$/g);
            if (passreg.test(formdata.Password) === false || formdata.Password?.trim()?.length < 8) {
                error.Password = "Password must contain at least 1 upper case, 1 number, 1 special character and minimum 8 character long";
                status = false;
            }
        }
        if (formdata.Password?.trim()?.length > 0 && formdata.ConfirmPassword?.trim()?.length === 0) {
            error.ConfirmPassword = " Kindly re-enter the password";
            status = false;
        }
        if (formdata.ConfirmPassword?.trim()?.length > 0 && formdata.Password?.trim()?.length > 0 && formdata.Password !== formdata.ConfirmPassword) {
            error.ConfirmPassword = "Enter password mismatch";
            status = false;
        }
        setFormErrors(error);
        return status;

    }
    const handlesavadata = async () => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            if (!validateData()) {
                return;
            }
            else {
                let status = false;
                let data = false;
                const UserData = await postUsersData("/auth/v1/insertorupdateadminuserlist", formdata);
                status = UserData.status;
                data = UserData.data;
                if ((!status && data === null) || (!status && !data)) {
                    dispatch({ type: 'ALERTING', message: UserData.message })
                    return;
                }
                else {
                    if (status && data > 0 && formdata.UserMasterId > 0) {
                        dispatch({ type: 'ALERTINGMESSAGE', message: UserData.message })
                        clearData();
                        // navigate('/Packagelist');
                    }
                    else if (status && data > 0 && formdata.UserMasterId === 0) {
                        dispatch({ type: 'ALERTINGMESSAGE', message: UserData.message })
                        clearData();
                    }
                    else {
                        dispatch({ type: 'ALERTINGMESSAGE', message: UserData.message })
                        clearData();
                        return;
                    }
                }
            }
        }

    }
    const clearData = () => {
        setButtonAddEditText("Add")
        setFormErrors({});
        setFormDate({
            ...formdata,
            FirstName: '',
            MiddleName: '',
            LastName: '',
            UserEmail: '',
            ContactNo: '',
            GenderId: 0,
            UserName: '',
            RoleId: 0,
            DepartmentId: 0,
            IActive: false,
            Password: '',
            ConfirmPassword: '',
            UserMasterId: 0
        })
        setGenderId(0);
        setRoleId(0);
        setDepartmentId(0);
        setActiveId(false);
        setEditDisabled(false)
    }
    useEffect(() => {
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            if (user.state != null) {
                getUserRoleLit();
                getGenderList();
                getDepartmentList();
                setEditDisabled(user.state.editdisabled);
            }
            else {
                navigate('/Userlist')
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
    }, []);
    useEffect(() => {
        if (retrivedata?.length === 1) {
            RetriveData();
            dispatch(loaderActions.end());
        }
    }, [retrivedata]);
    const RoleId = useRef(null);
    const DepartmentId = useRef(null);
    useEffect(() => {
        // Be sure that sequence of form fields and sequence of formErrors variables/fields are same, because every time focusing is done by the formErrors Array's top value. 
        if (Object.keys(formErrors).length > 0) {

            let id = Object.keys(formErrors)[0];
            if (id === "RoleId" || id === "DepartmentId") {
                eval(id)?.current?.focus();
            }
            else {
                let doc = document.getElementById(id);
                if (doc !== null)
                    doc.focus();
            }
        }

    }, [formErrors]);
    return (
        <>
            <div class="container mt-4 heading_color">
                <h3>User Registration</h3>
            </div>
            <div class="container form_border py-5 my-3">
                <form action="" >
                    <div class="mb-3 row mx-1">
                        <div class="col-lg-6 col-md-6 col-sm-12 mb-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-12 col-sm-12">
                                    <label for="inputPassword6" class="col-form-label required">First Name</label>
                                </div>
                                <div class=" col-lg-8 col-md-12 col-sm-12">
                                    <input type="text" id="FirstName" class="form-control"
                                        name="FirstName"
                                        maxLength={20}
                                        value={formdata.FirstName}
                                        onChange={onChange}
                                        // disabled={disabled ? true : false}
                                        placeholder="First Name"
                                    />
                                    {formErrors.FirstName && <p className='text-danger text-start pb-0'>{formErrors.FirstName}</p>}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 mb-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-12 col-sm-12">
                                    <label for="inputPassword6" class="col-form-label">Middle Name</label>
                                </div>
                                <div class=" col-lg-8 col-md-12 col-sm-12">
                                    <input type="text" id="MiddleName" class="form-control"
                                        name="MiddleName"
                                        maxLength={20}
                                        value={formdata.MiddleName}
                                        onChange={onChange}
                                        // disabled={disabled ? true : false}
                                        placeholder="Middle Name"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 mb-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-12 col-sm-12">
                                    <label for="inputPassword6" class="col-form-label required">Last Name</label>
                                </div>
                                <div class=" col-lg-8 col-md-12 col-sm-12">
                                    <input type="text" id="LastName" class="form-control"
                                        name="LastName"
                                        maxLength={20}
                                        value={formdata.LastName}
                                        onChange={onChange}
                                        // disabled={disabled ? true : false}
                                        placeholder="Last Name"
                                    />
                                    {formErrors.LastName && <p className='text-danger text-start pb-0'>{formErrors.LastName}</p>}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 mb-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-12 col-sm-12">
                                    <label for="inputPassword6" class="col-form-label required">Email Address</label>
                                </div>
                                <div class=" col-lg-8 col-md-12 col-sm-12">
                                    <input type="text" id="UserEmail" class="form-control"
                                        name="UserEmail"
                                        maxLength={150}
                                        value={formdata.UserEmail}
                                        onChange={onChange}
                                        // disabled={disabled ? true : false}
                                        placeholder="Email Address"
                                    />
                                    {formErrors.UserEmail && <p className='text-danger text-start'>{formErrors.UserEmail}</p>}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 mb-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-12 col-sm-12">
                                    <label for="inputPassword6" class="col-form-label required">Mobile Number</label>
                                </div>
                                <div class=" col-lg-8 col-md-12 col-sm-12">
                                    <div class="prefix_div">
                                        <span class="mobileprefix">+91</span>
                                        <input class="form-control mobilecutoffinput" id='ContactNo' type="text"
                                            name="ContactNo"
                                            maxLength={10}
                                            value={formdata.ContactNo}
                                            onChange={onChange}
                                            // disabled={disabled ? true : false}
                                            placeholder="Mobile Number"
                                        />
                                    </div>
                                    {formErrors.ContactNo && <p className='text-danger text-start'>{formErrors.ContactNo}</p>}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 mb-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-12 col-sm-12">
                                    <label for="inputPassword6" class="col-form-label">Gender</label>
                                </div>
                                <div class=" col-lg-8 col-md-12 col-sm-12">
                                    <Select
                                        id='genderid'
                                        className="dropdown select_dropdown"
                                        options={genderlist}
                                        value={genderlist?.filter(function (genderlist) {
                                            return genderlist.value === genderid;
                                        })}
                                        isSearchable
                                        onChange={handleGenderChange} // assign onChange function
                                    // isDisabled={disabled}
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 mb-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-12 col-sm-12">
                                    <label for="inputPassword6" class="col-form-label required">User Name</label>
                                </div>
                                <div class=" col-lg-8 col-md-12 col-sm-12">
                                    <input type="text" id="UserName" class="form-control"
                                        name="UserName"
                                        value={formdata.UserName}
                                        maxLength={30}
                                        onChange={onChange}
                                        disabled={disable}
                                        placeholder="User Name"
                                    />
                                    {formErrors.UserName && <p className='text-danger text-start'>{formErrors.UserName}</p>}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 mb-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-12 col-sm-12">
                                    <label for="inputPassword6" class="col-form-label required">Department</label>
                                </div>
                                <div class=" col-lg-8 col-md-12 col-sm-12">
                                    <Select
                                        className="dropdown select_dropdown"
                                        options={departmentList}
                                        ref={DepartmentId}
                                        id='DepartmentId'
                                        value={departmentList?.filter(function (departmentList) {
                                            return departmentList.value === departmentid;
                                        })}// set list of the data
                                        onChange={handleDepartmentChange} // assign onChange function
                                        // isDisabled={disabled ? true : false}
                                        isSearchable={true}
                                    />
                                    {formErrors.DepartmentId && <p className='text-danger text-start'>{formErrors.DepartmentId}</p>}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 mb-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-12 col-sm-12">
                                    <label for="inputPassword6" class="col-form-label required">Role</label>
                                </div>
                                <div class=" col-lg-8 col-md-12 col-sm-12">
                                    <Select
                                        className="dropdown select_dropdown"
                                        options={rololist}
                                        ref={RoleId}
                                        id='RoleId'
                                        value={rololist?.filter(function (rololist) {
                                            return rololist.value === roleid;
                                        })}// set list of the data
                                        onChange={handleRoleChange} // assign onChange function
                                        // isDisabled={disabled ? true : false}
                                        isSearchable={true}
                                    />
                                    {formErrors.RoleId && <p className='text-danger text-start'>{formErrors.RoleId}</p>}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 mb-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-12 col-sm-12">
                                    <label for="inputPassword6" class="col-form-label">Active</label>
                                </div>
                                <div class=" col-lg-8 col-md-12 col-sm-12">
                                    <Switch
                                        onChange={handleisActive}
                                        checked={activeId}
                                        className="react-switch"
                                    />
                                </div>
                                <ConfirmationModal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    noconfirm={() => handleConfirmation(false)}
                                    yesconfirm={() => handleConfirmation(true)}
                                />
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 row mx-1">
                        <div class="col-lg-6 col-md-6 col-sm-12 mb-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-12 col-sm-12">
                                    <label for="inputPassword6" class="col-form-label required">Password</label>
                                </div>
                                <div class=" col-lg-8 col-md-12 col-sm-12">
                                    <input type={`${passwordType ? 'password' : 'text'}`} id="Password" class="form-control"
                                        name="Password"
                                        autoComplete='off'
                                        value={formdata.Password}
                                        onChange={onChange}
                                        disabled={disable}
                                        placeholder="Password"
                                    />
                                    {formErrors.Password && <p className='text-danger text-start'>{formErrors.Password}</p>}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 mb-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-12 col-sm-12">
                                    <label for="inputPassword6" class="col-form-label required">Confirm Password</label>
                                </div>
                                <div class=" col-lg-8 col-md-12 col-sm-12">
                                    <input type={`${passwordType ? 'password' : 'text'}`} id="ConfirmPassword" class="form-control"
                                        name="ConfirmPassword"
                                        autoComplete='off'
                                        value={formdata.ConfirmPassword}
                                        onChange={onChange}
                                        disabled={disable}
                                        placeholder="Confirm Password"
                                    />
                                    {formErrors.ConfirmPassword && <p className='text-danger text-start'>{formErrors.ConfirmPassword}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <button type="button" class="btn btn-primary mx-1" onClick={handlesavadata} id="btnAdd" disabled={editdisabled} >{buttonaddeditText}</button>
                        <button type="button" class="btn btn-primary mx-1" disabled={disable} onClick={clearData} id="btnClear" >Clear</button>
                        <button type="button" class="btn btn-primary mx-1" onClick={() => navigate('/Userlist')} id="btnClear" >Back</button>
                    </div>
                    <br />
                </form>
            </div>
        </>
    )
}

export default Useradd