import { S3 } from 'aws-sdk';
import {AccessKeyId,SecretAccessKey,Region} from '../constants/global'

var AWS = require('aws-sdk');
var accessKeyId = AccessKeyId;
var secretAccessKey = SecretAccessKey;
var region = Region;  //Singapoor region by default
var spacesEndpoint = new AWS.Endpoint(region + '.digitaloceanspaces.com');

var bucketName = 'static-ethics';
const albumPhotosKey = "etm/";

export async  function ImageUpload(pathdire,imagename,image)
{
    var s3 = new AWS.S3({
        endpoint: spacesEndpoint,
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey
    });

   
    const photoKey = albumPhotosKey + pathdire + imagename;

    var params = {
        ACL: 'public-read',
        Bucket: bucketName,
        Key: photoKey,
        Body:image
    };
    // var options = {
    //     partSize: 10 * 1024 * 1024, // 10 MB
    //     queueSize: 10
    // };

    // await s3.putObject(params)
    // .on('httpUploadProgress', (evt) => {
    //     console.log('progress' , (evt.loaded / evt.total) * 100);
    // })
    // .send((err) => {
    //     if (err) console.log(err)
    // })
    await s3.putObject(params)
        .promise()
        .then(res => {
         console.log(`Upload succeeded - `, res);
         return true;
        })
        .catch(err => {
         console.log("Upload failed:", err);
         return false;
        });


}

export async function ImageDelete(imageName)
{
    // aws.config.update({accessKeyId: process.env.AWS_ACCESS_KEY_ID, secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY});
    var s3 = new AWS.S3({
        endpoint: spacesEndpoint,
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey
    });
    const photoKey = albumPhotosKey + imageName;

    var params = {
        Bucket:  bucketName,
        Key: photoKey
    };
    // await s3.deleteObject(params,function(err,data){
    //     if (err)    console.log(err,err.stack);
    //     else        console.log("Res:",data);
    // }).promise();
    // await s3.deleteObject(params).send((err) => {
    //     if (err) console.log(err)
    // })
    await s3.deleteObject(params).promise().then((data) => console.log('sucessfully deleted ' + imageName , data)).catch((err)=> console.log('err: ' , err));
   

   
}

export async function DownloadImg (imageName)
{
    var s3 = new AWS.S3({
        endpoint: spacesEndpoint,
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey
    });
    const photoKey = albumPhotosKey + imageName;

    var params = {
        Bucket:  bucketName,
        Key: photoKey
    };
    
    const data = await s3.getObject(params, function(err, data) {
        if (!err) {
            //console.log(data); // successful response
             /*
            data = {
             AcceptRanges: "bytes", 
             ContentLength: 3191, 
             ContentType: "image/jpeg", 
             ETag: "\"6805f2cfc46c0f04559748bb039d69ae\"", 
             LastModified: <Date Representation>, 
             Metadata: {
             }, 
             TagCount: 2, 
             VersionId: "null"
            }
            */
         } else {
            //console.log(err); // an error occurred
         }     
     
     }).promise();
     console.log(data)
     return data;
}

export async  function ViewImage (imageName)
{
    var s3 = new AWS.S3({
        endpoint: spacesEndpoint,
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey
    });
    const photoKey = albumPhotosKey + imageName;

    var params = {
        Bucket:  bucketName
    };

    await s3.listObjects(params,function(err,data){

        if(!err)
        {
            console.log(data)
        }
        else
        {
            console.log(err)
        }

    }).promise();
} 
