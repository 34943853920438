import React, { useEffect, useMemo, useRef, useState } from 'react'
import Select from 'react-select';
import Switch from 'react-switch';
import CustomSelection from '../../../../customControl/CustomSelection';
import RichTextBox from '../../../../customControl/RichTextBox';
import { PhoneNmber, isValidEmail, numberAndSpecialCharacter } from '../../../commonClass/CommonValidation';
import { getAllData, patchUsersData, postUsersData, verifytoken } from '../../../../services/ApiCallingUserServices';
import { ConfirmationModal } from '../../../../customControl/ConfirmationModal';
import { htmlToText } from 'html-to-text';
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../../../commonClass/Session';
import { loaderActions } from '../../../../_actions/loader.actions';

const defaultObject = {
  AttractionName: "",
  LocationId: "",
  AttractionTypeId: "",
  AttractionThemeId: "",
  AttractionCategoryId: "",
  LongDescription: "",
  ShortDescription: "",
  Latitude: "",
  Longitude: "",
  EmailId: "",
  LandlineNo: "",
  MobileNo: "",
  Address: "",
  Pincode: "",
  Website: "",
  IsActive: false
}

function BasicInformation({
  disabled,
  attraction,
  editdisabled,
  clearDisable,
  viewtype,
  attractionId,
  attractionsList,
  userRights
}) {
  const navigate = useNavigate();
  const [buttonaddeditText, setbuttonaddeditText] = useState(viewtype);
  const [richboxheight, setRichBoxHeight] = useState(false)
  const dispatch = useDispatch();

  const editAttraction = viewtype !== 'Add' && attraction ? {
    AttractionBasicId: attraction?.AttractionBasicId || '',
    AttractionName: attraction.AttractionName || "",
    LocationId: attraction.LocationId || "",
    AttractionTypeId: attraction.AttractionTypeId || "",
    AttractionThemeId: attraction.AttractionThemeId || "",
    AttractionCategoryId: attraction.AttractionCategoryId || "",
    LongDescription: attraction.LongDescription || "",
    ShortDescription: attraction.ShortDescription || "",
    Latitude: attraction.Latitude || "",
    Longitude: attraction.Longitude || "",
    EmailId: attraction.EmailId || "",
    LandlineNo: attraction.LandlineNo || "",
    MobileNo: attraction.MobileNo || "",
    Address: attraction.Address || "",
    Pincode: attraction.Pincode || "",
    Website: attraction.Website || "",
    IsActive: attraction.IsActive || false
  } : "";

  const [city, setCity] = useState([]);
  const [allLocation, setAllLocation] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [themeList, setThemeList] = useState([]);
  const [BasicInformation, setBaisicInformation] = useState(viewtype === 'Add' ? defaultObject : editAttraction);

  const [showModel, setShowModel] = useState(false);
  const [errors, setErrors] = useState({});
  const [globleError, setGlobalError] = useState('');
  const [isEditDisable, setIsEditDisable] = useState(editdisabled);
  const [backDisable, setBackDisable] = useState(false);

  // const [disabled, setDisabled] = useState(false);

  const [cityActiveDisable, setCityActiveDisable] = useState({ IsActive: true });
  const [typesActiveDisable, setTypesActiveDisable] = useState({ IsActive: true });
  const [themeActiveDisable, setThemeActiveDisable] = useState({ IsActive: true });

  const handleOnChange = (name, value) => {
    setIsEditDisable(false);
    setBaisicInformation({ ...BasicInformation, [name]: value });
    setErrors({})
  }

  const handleRichtext = (name, value) => {
    const str = value.replace('<p><br></p>', '')
    const html = htmlToText(str);

    if (html !== htmlToText(BasicInformation[name])) {
      setIsEditDisable(false);
      setBaisicInformation({ ...BasicInformation, [name]: value });
    }
  }

  const getCityOptions = async () => {
    const MenuItem = await getAllData("/master/v1/getattractionbasiccity");
    setCity(MenuItem.data);

    if (buttonaddeditText !== 'Add' && attraction) {
      const currentCity = MenuItem.data?.find(city => city.LocationId === attraction.LocationId);
      setCityActiveDisable({
        label: currentCity?.City,
        value: currentCity?.LocationId,
        IsActive: buttonaddeditText === 'Add' ? false : currentCity?.IsActive
      })
    }
    dispatch(loaderActions.end());
  };

  const getAttractionGetOtherAttraction = async () => {

    let statusoftoken = await verifytoken();
    if (statusoftoken) {

      const otherSelectOption = await getAllData("/master/v1/getotherattraction");
      const { data } = otherSelectOption

      setTypeList(data[0].attractionTypeList);
      setCategoryList(data[0].attractionCategoryList);
      setThemeList(data[0].attractionThemeList);

      if (buttonaddeditText !== 'Add' && attractionId) {

        const currentType = data[0]?.attractionTypeList?.find((type) => type.attractionTypeId === attraction.AttractionTypeId);
        const currentTheme = data[0].attractionThemeList.find((theme) => theme.attractionThemeId === attraction.AttractionThemeId);

        setTypesActiveDisable({
          label: currentType?.typeName,
          value: currentType?.attractionTypeId,
          IsActive: buttonaddeditText === 'Add' ? false : currentType?.isActive
        })

        setThemeActiveDisable({
          label: currentTheme?.themeName,
          value: currentTheme?.attractionThemeId,
          IsActive: buttonaddeditText === 'Add' ? false : currentTheme?.isActive
        });
      }
      getCityOptions();
    }
    else {
      ClearLocalStorage();
      dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
    }
  };

  useEffect(() => {
    dispatch(loaderActions.start());
    // console.log(GetLocalStorage(), 'GetLocalStorage()')
    // let token = GetLocalStorage();
    // if (token && token.userName && token.token) {
    // }
    // else {
    //   ClearLocalStorage();
    //   dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
    // }
    getAttractionGetOtherAttraction();
  }, [attractionId, attraction])


  const cityOptions = useMemo(() => city?.filter(city => city.IsActive).map((city) => ({
    label: city.City,
    value: city.LocationId,
    IsActive: city.IsActive
  }
  )), [city, buttonaddeditText]);

  const getCityOption = (cityOptions) => {
    if (buttonaddeditText === 'Add') {
      return cityOptions
    } else {
      let currentCity = cityActiveDisable
      return currentCity?.IsActive === true ? cityOptions : [...cityOptions, cityActiveDisable]
    }
  }

  const themeOption = useMemo(() => themeList.filter(list => list.isActive).map((list) => (
    {
      label: list.themeName,
      value: list.attractionThemeId,
      IsActive: list.isActive
    }
  )), [themeList, buttonaddeditText]);


  const getThemeOption = (themeOption) => {
    if (buttonaddeditText === 'Add') {
      return themeOption
    } else {
      let currentTheme = themeActiveDisable
      return currentTheme?.IsActive === true ? themeOption : [...themeOption, themeActiveDisable]
    }
  }

  const categoryListOption = useMemo(() => categoryList.map((list) => ({
    label: list.categoryName,
    value: list.attractionCategoryId,
    IsActive: list.isActive
  })), [categoryList]);


  const typeListOption = useMemo(() => typeList.filter(list => list.isActive).map((list) => ({
    label: list.typeName,
    value: list.attractionTypeId,
    IDBDatabasesActive: list.isActive
  })), [typeList, buttonaddeditText]);


  const getAttractionType = (themeOption) => {
    if (buttonaddeditText === 'Add') {
      return typeListOption
    } else {
      let currentType = typesActiveDisable
      return currentType?.IsActive === true ? themeOption : [...themeOption, typesActiveDisable]
    }
  }


  const getalllocationbyCity = async (locationid) => {
    if (locationid) {
      const locid = { LocationId: locationid }
      await postUsersData('/master/v1/getlocationbyid', locid).then((AllLocationData) => {
        setAllLocation(AllLocationData.data);
      });
    }
  }

  useEffect(() => {
    BasicInformation?.LocationId && getalllocationbyCity(BasicInformation?.LocationId)
  }, [BasicInformation?.LocationId])


  const continent = BasicInformation?.LocationId ?
    allLocation?.map(value => ({
      label: value.continentName,
      value: 1
    })) : []

  const country = BasicInformation?.LocationId ? allLocation?.map(value => ({
    label: value.countryName,
    value: 1
  })) : []

  const region = BasicInformation?.LocationId ? allLocation?.map(value => ({
    label: value.regionName,
    value: 1
  })) : []

  const states = BasicInformation?.LocationId ? allLocation?.map(value => ({
    label: value.stateName,
    value: 1
  })) : []

  const LocationId = useRef(null);
  const AttractionName = useRef(null);
  const AttractionTypeId = useRef(null);
  const AttractionCategoryId = useRef(null);
  const AttractionThemeId = useRef(null);
  const Latitude = useRef(null);
  const Longitude = useRef(null);
  const EmailId = useRef(null)

  const checkValidation = (details) => {

    const validationRules = [
      { field: 'LocationId', require: true, message: 'Kindly select the city' },
      { field: 'AttractionName', require: true, message: 'Kindly enter Attraction Name' },
      { field: 'AttractionTypeId', require: true, message: 'Kindly Select the Attraction Type' },
      { field: 'AttractionCategoryId', require: true, message: 'Kindly select the attraction category' },
      { field: 'AttractionThemeId', require: true, message: 'Kindly select the attraction theme' },
      { field: 'Latitude', require: false, message: 'Kindly enter the latitude', condition: !details.Latitude.trim() && details.Longitude.toString().trim() },
      { field: 'Longitude', require: false, message: 'Kindly enter the Longitude', condition: details.Latitude.trim() && !details.Longitude.toString().trim() },
      {
        field: 'EmailId',
        require: false,
        message: 'Kindly enter the valid email Id',
        condition: details.EmailId && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(details.EmailId),
      },
    ];

    // const isDuplicate = attractionsList.some((attraction) => attraction.AttractionName.toLowerCase().trim() === details.AttractionName.toLowerCase().trim());


    const errors = validationRules.reduce((acc, rule) => {
      if ((!details[rule.field].toString().trim() && rule.require) || (rule.condition !== undefined && rule.condition)) {
        acc[rule.field] = rule.message;
      };

      // if (isDuplicate && rule.field === 'AttractionName') {
      //   acc['AttractionName'] = "You can not enter duplicate value."
      // };

      return acc;
    }, {});

    if (Object.entries(errors).length > 0) {
      if (errors.LocationId ||
        errors.AttractionTypeId ||
        errors.AttractionCategoryId ||
        errors.AttractionThemeId
      ) {
        eval(Object.entries(errors)[0][0]).current.focus();
      } else {
        let doc = document.getElementById(Object.entries(errors)[0][0]);
        if (doc !== null)
          doc.focus();
      }
    }

    setErrors(errors);
    return Object.keys(errors).length > 0;
  };


  const policydetailsconfig = useMemo(
    () => ({
      readonly: false,
      height: '100%',
      maxHeight: richboxheight === true ? 200 : 600,
      width: '100%',
      statusbar: false,
      disablePlugins: ["source"],
      removeButtons: ["about", "image", "link", "select", "video"],
      toolbarAdaptive: false,
      defaultMode: "1",
      disabled: disabled ? true : false
    }),
    [disabled, richboxheight]
  );

  useEffect(() => {
    const richtexteboxheight = document.getElementById("layoutSidenav_content").clientWidth
    if (richtexteboxheight > 912) {
      setRichBoxHeight(true)
    }
    else {
      setRichBoxHeight(false)
    }
  }, []);

  const handleIsActive = (value) => {
    if (value === true) {
      handleOnChange('IsActive', !BasicInformation.IsActive)
      setShowModel(false);
    } else {
      setShowModel(false)
    }
  };

  const handleClear = () => {
    setBaisicInformation(defaultObject);
    setErrors({})
    setGlobalError('')
  };

  const handleBack = () => {
    handleClear();
    setIsEditDisable(true)
    navigate('/AttractionsList');
  }


  const submitBasicInformation = async (e) => {
    let cloneBaiicInfo = { ...BasicInformation };
    cloneBaiicInfo = {
      ...cloneBaiicInfo,
      ['AttractionName']: BasicInformation.AttractionName.trim()
    }

    e.preventDefault();
    let statusoftoken = await verifytoken();
    if (!checkValidation(BasicInformation)) {
      if (statusoftoken) {
        if (buttonaddeditText === 'Add') {
          const basiceInfo = await postUsersData('/master/v1/insertattractionbasic', cloneBaiicInfo);

          if (basiceInfo.status) {
            dispatch({ type: 'ALERTINGMESSAGE', message: basiceInfo.message });
            handleClear()
          } else {
            setGlobalError(basiceInfo.message);
          }
        } else {
          const basiceInfoUpdate = await patchUsersData('/master/v1/updateattractionbasic', cloneBaiicInfo);
          if (basiceInfoUpdate.status) {
            dispatch({ type: 'ALERTINGMESSAGE', message: basiceInfoUpdate.message });
            setIsEditDisable(true)
            handleClear()
          } else {
            setGlobalError(basiceInfoUpdate.message);
          }
        }

      } else {
        ClearLocalStorage();
        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
      }
    }
  };

  return (
    <div class="container form_border py-5 my-3">
      <form action="" >
        <div class="mb-3 row mx-1">
          {
            globleError && <p className='text-danger text-center'>{globleError}</p>
          }
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="row">
              <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                <div class="col-lg-12 col-md-12">
                  <label htmlFor="inputPassword6" class="col-form-label required">Continent</label>
                </div>
                <div class="col-lg-12 mb-1 col-md-12">
                  <Select className="dropdown select_dropdown"
                    isDisabled
                    value={continent?.find(({ value }) => value === 1)}
                    options={continent}
                  />
                </div>
              </div>

              <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                <div class="col-lg-12 col-md-12">
                  <label htmlFor="inputPassword6" class="col-form-label required">Country</label>
                </div>
                <div class="col-lg-12 mb-1 col-md-12">
                  <Select className="dropdown select_dropdown"
                    isDisabled
                    value={country?.find(({ value }) => value === 1)}
                    options={country}
                  />
                </div>
              </div>

              <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                <div class="col-lg-12 col-md-12">
                  <label htmlFor="inputPassword6" class="col-form-label required">Region</label>
                </div>
                <div class="col-lg-12 mb-1 col-md-12">
                  <Select className="dropdown select_dropdown"
                    isDisabled
                    value={region?.find(({ value }) => value === 1)}
                    options={region}
                  />
                </div>
              </div>

              <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                <div class="col-lg-12 col-md-12">
                  <label htmlFor="inputPassword6" class="col-form-label required">State</label>
                </div>
                <div class="col-lg-12 mb-1 col-md-12">
                  <Select className="dropdown select_dropdown"
                    isDisabled
                    value={states?.find(({ value }) => value === 1)}
                    options={states}
                  />
                </div>
              </div>

              <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                <div class="col-lg-12 col-md-12">
                  <label htmlFor="inputPassword6" class="col-form-label required">City</label>
                </div>
                <div class="col-lg-12 mb-1 col-md-12">
                  <CustomSelection
                    selId='LocationId'
                    datalist={getCityOption(cityOptions)}
                    selRef={LocationId}
                    fieldid={BasicInformation?.LocationId}
                    SelectionChange={(value) => handleOnChange('LocationId', value.value)}
                    isdisable={disabled}
                    isactivedisable={!cityActiveDisable.IsActive}
                    issearchable={true}
                  />
                  {errors.LocationId && <p className='text-danger text-start'>{errors.LocationId}</p>}
                </div>
              </div>
            </div>

            <div className='row mt-md-3 mt-lg-3 mt-1 mx-lg-2'>
              <div className='col-lg-6'>
                <div className='row'>
                  <div className='col-lg-4 col-md-12 col-sm-12'>
                    <label htmlFor='AttractionName' className="col-form-label required">Attraction Name</label>
                  </div>
                  <div className='col-lg-7 col-md-12 col-sm-12 mb-1'>
                    <div className="form-lg-check form-lg-check-inline w-100">
                      <input class="form-control"
                        type="text"
                        id='AttractionName'
                        name="AttractionName"
                        ref={AttractionName}
                        value={BasicInformation?.AttractionName}
                        onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                        disabled={disabled ? true : false}
                        maxLength={150}
                        placeholder='Attraction Name'
                      />
                      {errors.AttractionName && <p className='text-danger text-start'>{errors.AttractionName}</p>}
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-lg-6'>
                <div className='row'>
                  <div className='col-lg-4 col-md-12 col-sm-12'>
                    <label className="col-form-label required">Attraction Type</label>
                  </div>
                  <div
                    className='col-lg-8 col-md-12 col-sm-12 mb-1'
                  >
                    <CustomSelection
                      selId='AttractionTypeId'
                      datalist={getAttractionType(typeListOption)}
                      selRef={AttractionTypeId}
                      fieldid={BasicInformation.AttractionTypeId}
                      SelectionChange={(val) => handleOnChange('AttractionTypeId', val.value)}
                      isdisable={disabled}
                      isactivedisable={!typesActiveDisable.IsActive}
                      issearchable={true}
                      placeholder='Attraction Type'
                    />
                    {errors.AttractionTypeId && <p className='text-danger text-start'>{errors.AttractionTypeId}</p>}

                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-md-3 mt-lg-3 mt-1 mx-lg-2'>
              <div className='col-lg-6'>
                <div className='row'>
                  <div className='col-lg-4 col-md-12 col-sm-12'>
                    <label className="col-form-label required">Attraction Category</label>
                  </div>
                  <div className='col-lg-7 col-md-12 col-sm-12 mb-1'>
                    <div className=" form-lg-check form-lg-check-inline w-100">
                      <Select
                        className="dropdown select_dropdown"
                        id='AttractionCategoryId'
                        name="AttractionCategoryId"
                        ref={AttractionCategoryId}
                        options={categoryListOption}
                        value={BasicInformation.AttractionCategoryId ? categoryListOption.find((option) => option.value === BasicInformation?.AttractionCategoryId) : []}
                        onChange={(val) => handleOnChange('AttractionCategoryId', val.value)}
                        isDisabled={disabled ? true : false}
                        placeholder='Attraction Category'
                      />
                      {errors.AttractionCategoryId && <p className='text-danger text-start'>{errors.AttractionCategoryId}</p>}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='row'>
                  <div className='col-lg-4 col-md-12 col-sm-12'>
                    <label className="col-form-label required">Attraction Theme</label>
                  </div>
                  <div className='col-lg-8 col-md-12 col-sm-12 mb-1'>
                    <CustomSelection
                      selId='AttractionThemeId'
                      selRef={AttractionThemeId}
                      datalist={getThemeOption(themeOption)}
                      fieldid={BasicInformation.AttractionThemeId}
                      SelectionChange={(val) => handleOnChange('AttractionThemeId', val.value)}
                      isdisable={disabled}
                      isactivedisable={!themeActiveDisable.IsActive}
                      issearchable={true}
                      placeholder='Attraction Theme'
                    />
                    {errors.AttractionThemeId && <p className='text-danger text-start'>{errors.AttractionThemeId}</p>}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mt-md-3 mt-lg-3 mt-1 mx-lg-2'>
              <div className='col-lg-2'>
                <label className="col-form-label">Long Description</label>
              </div>
              <div className='col-lg-10 mb-1'>
                <RichTextBox field="LongDescription"
                  fieldvalue={BasicInformation?.LongDescription}
                  tabIndex={1}
                  OnChangeofRichBox={(value) => handleRichtext('LongDescription', value)}
                  textconfig={policydetailsconfig}
                />
              </div>
            </div>

            <div className='row mt-md-3 mt-lg-3 mt-1 mx-lg-2'>
              <div className='col-lg-2'>
                <label className="col-form-label">Short Description</label>
              </div>
              <div className='col-lg-10 mb-1'>
                <RichTextBox field="ShortDescription"
                  fieldvalue={BasicInformation?.ShortDescription}
                  tabIndex={1}
                  OnChangeofRichBox={(e) => handleRichtext('ShortDescription', e)}
                  textconfig={policydetailsconfig}
                />
              </div>
            </div>

            <div className='row mt-md-3 mt-lg- mt-1 mx-lg-2'>
              <div className='col-lg-6'>
                <div className='row'>
                  <div className='col-lg-4 col-md-12 col-sm-12'>
                    <label className="col-form-label">Latitude</label>
                  </div>
                  <div className='col-lg-7 col-md-12 col-sm-12'>
                    <div className=" form-lg-check form-lg-check-inline mb-1 w-100">
                      <input class="form-control"
                        type="text"
                        id='Latitude'
                        name="Latitude"
                        ref={Latitude}
                        value={BasicInformation?.Latitude}
                        onChange={(e) => {
                          if (numberAndSpecialCharacter(e.target.value)) {
                            handleOnChange(e.target.name, e.target.value);
                          } else {
                            handleOnChange(e.target.name, BasicInformation.Latitude);
                          }
                        }}
                        // disabled={disabled ? true : false}
                        placeholder='Latitude'
                        maxLength={20}
                        disabled={disabled}
                      />
                      {errors.Latitude && <p className='text-danger text-start'>{errors.Latitude}</p>}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='row'>
                  <div className='col-lg-4 col-md-12 col-sm-12'>
                    <label htmlFor='Longitude' className="col-form-label">Longitude</label>
                  </div>
                  <div className='col-lg-8 col-md-12 col-sm-12 mb-1'>
                    <div className=" form-lg-check form-lg-check-inline w-100">
                      <input class="form-control"
                        type="text"
                        id='Longitude'
                        name="Longitude"
                        ref={Longitude}
                        value={BasicInformation.Longitude}
                        onChange={(e) => {
                          if (numberAndSpecialCharacter(e.target.value)) {
                            handleOnChange(e.target.name, e.target.value);
                          } else {
                            handleOnChange(e.target.name, BasicInformation.Longitude);
                          }
                        }}
                        // disabled={disabled ? true : false}
                        placeholder='Longitude'
                        maxLength={20}
                        disabled={disabled}
                      />
                      {errors.Longitude && <p className='text-danger text-start'>{errors.Longitude}</p>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row mt-md-3 mt-lg-3 mt-1 mx-lg-2'>
              <div className='col-lg-6'>
                <div className='row'>
                  <div className='col-lg-4 col-md-12 col-sm-12'>
                    <label className="col-form-label ">Email ID</label>
                  </div>
                  <div className='col-lg-7 col-md-12 col-sm-12'>
                    <div className=" form-lg-check form-lg-check-inline mb-1 w-100">
                      <input class="form-control"
                        type="text"
                        id='EmailId'
                        name="EmailId"
                        ref={EmailId}
                        value={BasicInformation.EmailId}
                        onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                        // disabled={disabled ? true : false}
                        placeholder='Email ID'
                        maxLength={150}
                        disabled={disabled}
                      />
                      {errors.EmailId && <p className='text-danger text-start'>{errors.EmailId}</p>}
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-lg-6'>
                <div className='row'>
                  <div className='col-lg-4 col-md-12 col-sm-12'>
                    <label className="col-form-label">Landline Number</label>
                  </div>
                  <div className='col-lg-8 col-md-12 col-sm-12'>
                    <div className=" form-lg-check mb-1 form-lg-check-inline w-100">
                      <input class="form-control"
                        type="text"
                        id='LandlineNo'
                        name="LandlineNo"
                        value={BasicInformation.LandlineNo}
                        onChange={(e) => handleOnChange(e.target.name, PhoneNmber(e.target.value))}
                        // disabled={disabled ? true : false}
                        placeholder='Landline Number'
                        maxLength={15}
                        disabled={disabled}
                      />

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-md-3 mt-lg-3 mt-1 mx-lg-2'>
              <div className='col-lg-6'>
                <div className='row'>
                  <div className='col-lg-4 col-md-12 col-sm-12'>
                    <label className="col-form-label">Mobile Number</label>
                  </div>
                  <div className='col-lg-7 col-md-12 col-sm-12'>
                    <div className="form-lg-check mb-1 form-lg-check-inline w-100">
                      <input className="form-control"
                        type="text"
                        id='MobileNo'
                        name="MobileNo"
                        value={BasicInformation.MobileNo}
                        onChange={(e) => handleOnChange(e.target.name, PhoneNmber(e.target.value))}
                        // disabled={disabled ? true : false}
                        placeholder='Mobile Number'
                        maxLength={10}
                        disabled={disabled}
                      />

                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='row'>
                  {/* <div className='col-lg-4 col-md-12 col-sm-12'>
                    <label className="col-form-label required">Landline Number</label>
                  </div>
                  <div className='col-lg-8 col-md-12 col-sm-12'>
                    <div className=" form-lg-check form-lg-check-inline w-100">
                      <input class="form-control"
                        type="text"
                        id='SupplierName'
                        name="SupplierName"

                        placeholder='Landline Number'
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className='row mt-md-3 mt-lg-3 mt-1 mx-lg-2'>
              <div className='col-lg-2'>
                <label className="col-form-label">Address</label>
              </div>
              <div className='col-lg-10'>
                <div className=" form-lg-check form-lg-check-inline w-100">
                  <textarea class="form-control"
                    id="Address"
                    rows="3"
                    name="Address"
                    value={BasicInformation.Address}
                    onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                    // disabled={disabled ? true : false}
                    placeholder='Address'
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>

            <div className='row mt-md-3 mt-lg-3 mt-1 mx-lg-2'>
              <div className='col-lg-6'>
                <div className='row'>
                  <div className='col-lg-4 col-md-12 col-sm-12'>
                    <label className="col-form-label">Pincode</label>
                  </div>
                  <div className='col-lg-7 col-md-12 col-sm-12'>
                    <div className=" form-lg-check form-lg-check-inline w-100">
                      <input className="form-control"
                        type="text"
                        id='Pincode'
                        name="Pincode"
                        value={BasicInformation.Pincode}
                        onChange={(e) => handleOnChange(e.target.name, PhoneNmber(e.target.value))}
                        // disabled={disabled ? true : false}
                        placeholder='Pincode'
                        maxLength={6}
                        disabled={disabled}
                      />
                      {/* {formErrors.SupplierName && <p className='text-danger text-start'>{formErrors.SupplierName}</p>} */}
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-lg-6'>
                <div className='row'>
                  <div className='col-lg-4 col-md-12 col-sm-12'>
                    <label className="col-form-label">Website</label>
                  </div>

                  <div className='col-lg-8 col-md-12 col-sm-12'>
                    <div className=" form-lg-check form-lg-check-inline w-100">
                      <input className="form-control"
                        type="text"
                        id='Website'
                        name="Website"
                        value={BasicInformation.Website}
                        onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                        // disabled={disabled ? true : false}
                        placeholder='Website'
                        maxLength={3000}
                        disabled={disabled}
                      />
                      {/* {formErrors.SupplierName && <p className='text-danger text-start'>{formErrors.SupplierName}</p>} */}
                    </div>
                  </div>

                </div>
              </div>
            </div>


            <div className='row mt-md-3 mt-lg-3 mt-1 mx-lg-2'>
              <div className="col-lg-2 col-md-2 col-sm-12">
                <label htmlFor="inputPassword6" className="col-form-label"> Active</label>
              </div>

              <div className=" col-lg-10 col-md-10 col-sm-12  mt-md-1 align-self-start">
                <Switch
                  checked={BasicInformation.IsActive}
                  name='IsActive'
                  className="react-switch"
                  value={BasicInformation.IsActive}
                  onChange={(e) => {
                    if (buttonaddeditText === 'Add') {
                      handleOnChange('IsActive', !BasicInformation.IsActive);
                    }
                    else {
                      setShowModel(true)
                    }
                  }}
                  disabled={disabled}
                />

                <ConfirmationModal
                  show={showModel}
                  onHide={() => setShowModel(false)}
                  noconfirm={() => handleIsActive(false)}
                  yesconfirm={() => handleIsActive(true)}
                />
                {/* {formErrors.BannerName && <p className='text-danger text-start'>{formErrors.BannerName}</p>} */}
              </div>

            </div>
            {/* userRights */}
            <div class="mt-5 d-flex justify-content-center">
              {
                buttonaddeditText === 'Add' ?
                  (<button type="button" class="btn btn-primary mx-1" id="btnAdd"
                    onClick={(e) => submitBasicInformation(e)}
                    disabled={!userRights?.URightAdd || disabled}
                  >Add</button>) :
                  (<button type="button" class="btn btn-primary mx-1" id="btnAdd"
                    onClick={(e) => submitBasicInformation(e)}
                    disabled={isEditDisable ? true : false || disabled || !userRights?.URightEdit}
                  >Update</button>)
              }


              <button type="button" class="btn btn-primary mx-1" id="btnClear"
                onClick={handleClear}
                disabled={clearDisable ? true : false}
              >Clear</button>
              <button type="button" class="btn btn-primary mx-1" id="btnVisible"
                onClick={handleBack}
                disabled={backDisable ? true : false}
              >Back</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default BasicInformation