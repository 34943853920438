import React, { useMemo, useState } from 'react'
import { isValidImageFormate, onlyNumeric } from '../../../commonClass/CommonValidation';
import { ImageDelete, ImageUpload } from '../../../../ImageUpload/ImageCommon';

function getDateCombination() {
    var today = new Date();
    let date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
    let time = today.getHours().toString() + (today.getMinutes() + 1).toString() + today.getSeconds().toString();
    return date + '_' + time;
};


function NearByFacilityAndList(
    {
        label,
        facilityType,
        additionInfo,
        setAdditionInfo,
        isEditDisable,
        setIsEditDisable,
        disabled
    }
) {

    const mediacollection = useState([{
        DocName: '',
        FilePath: '',
        FileName: '',
        SeqNo: 0
    }]);

    const [datacollection, setDataCollection] = useState(mediacollection);
    const [counter, setCounter] = useState(0);

    const [nearByFacilities, setNearByFacilities] = useState({
        Hospital: {
            facilityName: '', kMs: ''
        },
        PoliceStation: {
            facilityName: '', kMs: ''
        },
        Shopping: {
            facilityName: '', kMs: ''
        },
        Gallery: {
            facilityName: '',
            GalleryImage: '',
            imagePath: ''
        }
    });
    const [errors, setErrors] = useState({})

    const NearByFacilitiesOnChange = (type, name, value) => {

        let result;
        if (name === 'kMs') {
            result = Number(value)
        }
        else {
            result = value
        }
        setIsEditDisable(false)
        setNearByFacilities({
            ...nearByFacilities,
            [type]: {
                ...nearByFacilities[type],
                [name]: result
            }
        })
    };

    const getErrorMessage = (stationType) => {
        switch (stationType) {
            case "Hospital":
                return "Kindly enter the hospital name"
            case "PoliceStation":
                return "Kindly enter the police station name"
            case "Shopping":
                return "Kindly enter the complex/market name"
            case "port":
                return "Kindly select the port"
            default:
                return "";
        }
    };
    const getPlaceholder = (stationType) => {
        switch (stationType) {
            case "Shopping":
                return "Complex/Market Name"
            default:
                return "Name";
        }
    };


    const handleValidationOfAccessPoint = (type) => {
        const { facilityName, kMs } = nearByFacilities[type];
        let error = {}
        let status = false
        if (!facilityName && !kMs) {
            status = true;
        }
        else if (!facilityName && kMs) {
            error[`${facilityType}_facilityName`] = getErrorMessage(type)
            status = true;
        }
        else if (facilityName && !kMs) {
            error[`${facilityType}_kMs`] = "Kindly specify the distance in Kms"
            status = true;
        }
        else if (additionInfo.NearByFacilities[type].length >= 10) {
            error['maxEntery'] = "Maximum allowed entry is 10"
            status = true;
        }
        else if (additionInfo.NearByFacilities[type]?.length > 0) {
            if (additionInfo.NearByFacilities[type]?.filter(dup => dup?.facilityName?.toLowerCase() === facilityName?.toLowerCase())?.length !== 0) {
                error['Duplicate'] = 'Data already exist';
                status = true;
            }
        }

        if (Object.keys(error).length > 0) {
            let id = Object.keys(error)[0];
            let doc = document.getElementById(id);
            if (doc !== null)
                doc.focus();
        }

        setErrors(error)
        // return Object.entries(error).length > 0
        return status
    }


    const onAddNormalField = (type, facilityDetails) => {
        const isErrors = handleValidationOfAccessPoint(type)
        if (!isErrors) {
            const facilityLength = additionInfo.NearByFacilities[type].length + 1;

            setAdditionInfo({
                ...additionInfo,
                NearByFacilities: {
                    ...additionInfo.NearByFacilities,
                    [type]: [...additionInfo.NearByFacilities[type],
                    { ...facilityDetails[type], seqNo: facilityLength }]
                }
            });

            setNearByFacilities({
                ...nearByFacilities, [type]: {
                    facilityName: '', kMs: ''
                }
            });
        }
    };


    const [filesize, setFileSize] = useState(0)
    const [filename, setFileName] = useState(0)

    const handleUploadImageLoad = (e, type = facilityType) => {

        setIsEditDisable(false);

        const files = e.target.files[0];
        let fileSize = files.size / 1024;
        fileSize = fileSize / 1024
        setFileSize(fileSize)
        setFileName(files.name)

        if (nearByFacilities[type].ImagePath) {
            // removeImage(newAddOn.ImagePath);
        }
        if (fileSize <= 5 && isValidImageFormate(files.name)) {
            const fileID = getDateCombination();
            const filename = fileID + '_' + '1' + '_' + (files.name.replace(/ /g, "_")).replace(/-/g, '');

            setNearByFacilities({
                ...nearByFacilities,
                [type]: {
                    ...nearByFacilities[type],
                    GalleryImage: files,
                    imageName: filename,
                    imagePath: 'CMS/attractionList/additionInformation/nearByFacilities/' + filename
                }
            })
            setErrors({ ...errors, imagePath: '' });
        }
        else {
            setErrors({ ...errors, imagePath: 'Kindly upload image in Jpeg or PNG format with max size 5 mb' })
            // document.getElementById("addonImange").value = "";
            return;
        }
    };

    const handleimageupload = async (pathdirectory, filename, file) => {
        await ImageUpload(pathdirectory, filename, file);
    };

    const handleimageDelete = async (filename) => {
        await ImageDelete(filename);
    };


    const validationGallery = (type) => {
        const { facilityName, imagePath } = nearByFacilities[type];
        let error = {}
        let status = false
        if (!facilityName && !imagePath) {
            status = true;
        }
        if (imagePath && (filesize >= 5 || !isValidImageFormate(filename))) {
            error['imagePath'] = "Kindly upload image in Jpeg or PNG format with max size 5 mb"
            status = true;
        }
        else if (additionInfo.NearByFacilities[type].length >= 10) {
            error['maxEntery'] = "Maximum allowed entry is 10"
            status = true;
        }
        else if (additionInfo.NearByFacilities[type]?.length > 0) {
            if (additionInfo.NearByFacilities[type]?.filter(dup => dup?.imagePath?.toLowerCase() === imagePath?.toLowerCase())?.length !== 0) {
                error['Duplicate'] = 'Data already exist';
                status = true;
            }
        }
        if (Object.keys(error).length > 0) {
            let id = Object.keys(error)[0];
            let doc = document.getElementById(id);
            if (doc !== null)
                doc.focus();
        }

        setErrors(error)
        return status
    }

    const onAddGalleryField = (type, facilityDetails) => {
        const isErrors = validationGallery(type)
        if (!isErrors) {
            const facilityLength = additionInfo.NearByFacilities[type].length + 1;

            setAdditionInfo({
                ...additionInfo,
                NearByFacilities: {
                    ...additionInfo.NearByFacilities,
                    [type]: [...additionInfo.NearByFacilities[type],
                    { ...facilityDetails[type], seqNo: facilityLength }]
                }
            });

            setNearByFacilities({
                ...nearByFacilities, [type]: {
                    facilityName: '', imagePath: '', GalleryImage: '', ImageName: ''
                }
            });
            document.getElementById("imagePath").value = "";
        }
    };

    const onAddStation = (type, facilityDetails) => {
        if (facilityType !== 'Gallery') {
            onAddNormalField(type, facilityDetails)
        } else {
            onAddGalleryField(type, facilityDetails)
            handleimageupload(
                'CMS/attractionList/additionInformation/nearByFacilities/',
                nearByFacilities[type]?.ImageName,
                nearByFacilities[type]?.GalleryImage
            )
        }
    }

    const handleRemoveAccessPoint = (type, seqNo) => {
        const facility = { ...additionInfo?.NearByFacilities };
        const getImageDetails = facility?.[type].find((Image) => Image.seqNo === seqNo)
        facility[type] = facility?.[type].filter((stopPoint) => stopPoint.seqNo !== seqNo)
            .map((accessPoint, index) => ({ ...accessPoint, seqNo: index + 1 }));
        if (facilityType !== 'Gallery') {
            setAdditionInfo({
                ...additionInfo,
                NearByFacilities: facility
            })
        } else {
            handleimageDelete(getImageDetails.imagePath);
            setAdditionInfo({
                ...additionInfo,
                NearByFacilities: facility
            })
        }
        setIsEditDisable(false)
    };


    return (
        <>
            {errors.Duplicate && <div className='text-danger text-start text-center'>{errors.Duplicate}</div>}
            {errors.maxEntery && <div className='text-danger text-start text-center'>{errors.maxEntery}</div>}
            <div className='row my-3 sm:d-flex justify-content-center;  d-flex align-items-center'>
                <div className='col-lg-2 col-md-12 col-sm-12 col-10'>
                    <label className="col-form-label ">{label}</label>
                </div>

                <div className='col-lg-9 col-md-9 col-sm-12'>
                    <div className='row'>

                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className=" form-lg-check form-lg-check-inline w-100">
                                <input class="form-control"
                                    type="text"
                                    id={`${facilityType}_facilityName`}
                                    name="facilityName"
                                    placeholder={getPlaceholder(facilityType)}
                                    maxLength={150}
                                    value={nearByFacilities?.[facilityType]?.facilityName}
                                    onChange={(e) => { NearByFacilitiesOnChange(facilityType, e.target.name, e.target.value) }}
                                    disabled={disabled ? true : false}
                                />
                                {errors[`${facilityType}_facilityName`] && <div className='text-danger text-start'>{errors[`${facilityType}_facilityName`]}</div>}
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 mt-lg-0 mt-md-0 mt-2'>
                            <div className=" form-lg-check form-lg-check-inline w-100">
                                {facilityType !== 'Gallery' ?
                                    (<input class="form-control"
                                        type="text"
                                        id={`${facilityType}_kMs`}
                                        name="kMs"
                                        placeholder='KMs'
                                        maxLength={3}
                                        value={nearByFacilities?.[facilityType]?.kMs}
                                        onChange={(e) => { NearByFacilitiesOnChange(facilityType, e.target.name, onlyNumeric(e.target.value)) }}
                                        disabled={disabled ? true : false}
                                    />) : (
                                        <div class="input-group">
                                            <input
                                                name='Affiliationsfile'
                                                type="file"
                                                id="imagePath"
                                                //  onChange={handleOnChange}
                                                class="form-control btn_add"
                                                accept=".jpeg,.png"
                                                // onChange={handleuploadAffilitionCertifycate}
                                                onChange={handleUploadImageLoad}
                                                disabled={disabled ? true : false}
                                            />
                                            <label class="input-group-text" htmlFor="falicitygalleryfile">Browse</label>
                                        </div>
                                    )
                                }

                                {errors[`${facilityType}_kMs`] && <div className='text-danger text-start'>{errors[`${facilityType}_kMs`]}</div>}
                                {errors.imagePath && <div className='text-danger text-start'>{errors.imagePath}</div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-1 col-md-2 col-sm-12 mt-lg-0 mt-md-0 mt-2 text-center'>
                    <button type="button"
                        onClick={() => onAddStation(facilityType, nearByFacilities)}
                        className="btn btn-primary mx-1" id="btncontactAdd"
                        disabled={disabled ? true : false}
                    >Add</button>
                </div>
            </div>
            {
                facilityType !== 'Gallery' ?
                    (
                        <>
                            {additionInfo.NearByFacilities?.[facilityType].map((facility) => (
                                <div className='row my-3 d-flex align-items-center'>
                                    <div className='col-lg-2 d-hidden '>
                                        {/* <label className="col-form-label "><b>Access Points</b></label> */}
                                    </div>
                                    <div className='col-lg-9 col-md-9 col-sm-12'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                                <div className=" form-lg-check form-lg-check-inline w-100">
                                                    <input class="form-control"
                                                        type="text"
                                                        id='SupplierName'
                                                        name="SupplierName"
                                                        value={facility.facilityName}
                                                        disabled
                                                    />
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-lg-0 mt-md-0 mt-2'>
                                                <div className=" form-lg-check form-lg-check-inline w-100">
                                                    <input class="form-control"
                                                        type="text"
                                                        id='SupplierName'
                                                        name="SupplierName"
                                                        value={facility.kMs}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-1 col-md-2 col-sm-12 mt-lg-0 mt-md-0 mt-2 text-center'>
                                        <button type="button"
                                            onClick={(e) => handleRemoveAccessPoint(facilityType, facility.seqNo)}
                                            className="btn btn-primary mx-1" id="btncontactAdd"
                                            disabled={disabled ? true : false}
                                        >x</button>
                                    </div>
                                </div>
                            ))}
                        </>
                    )
                    :
                    (
                        <>

                            {additionInfo.NearByFacilities?.[facilityType].map((element, index) => (
                                <>
                                    <div className='row my-3 d-flex align-items-center'>
                                        <div className='col-lg-2 d-hidden '>
                                            {/* <label className="col-form-label "><b>Access Points</b></label> */}
                                        </div>
                                        <div className='col-lg-9 col-md-9 col-sm-12'>
                                            <div className='row'>
                                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                                    <div className=" form-lg-check form-lg-check-inline w-100">
                                                        <input class="form-control"
                                                            type="text"
                                                            id='SupplierName'
                                                            name="SupplierName"
                                                            value={element.facilityName || ''}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-lg-6 col-md-6 col-sm-12 mt-lg-0 mt-md-0 mt-2'>
                                                    <div class="input-group  mb-1">
                                                        <input
                                                            type="text"
                                                            key={index}
                                                            // value={element.ImageName || ''}
                                                            value={element.imageName || ''}
                                                            //onChange={handleOnChange}
                                                            readOnly
                                                            class="form-control btn_add"
                                                            id="inputGroupFile02" />
                                                        <label class="input-group-text" for="inputGroupFile02">Browse</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-1 col-md-2 col-sm-12 mt-lg-0 mt-md-0 mt-2 text-center'>
                                            <button type="button"
                                                onClick={(e) => handleRemoveAccessPoint(facilityType, element.seqNo)}
                                                className="btn btn-primary mx-1" id="btncontactAdd"
                                                disabled={disabled ? true : false}
                                            >x</button>
                                        </div>
                                    </div>

                                </>


                            ))}
                        </>
                    )
            }

        </>
    )
}

export default NearByFacilityAndList