import { loaderConstants } from "../_constants/loader.constants";
let initialState = {load:false};
export function loader(state = initialState, actions) {
    switch (actions.type) {
        
        case loaderConstants.LOADER_START:
            return {load:true};
        case loaderConstants.LOADER_END:
            return {load:false};
        default:
            return state;
    }
}