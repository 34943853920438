import { clearable, tripSupplierCollection, userConstatnt } from "../_constants/user.constant";


const initialValues = {
    tba: []
}
const clearinitial = {
    clear: false,
    clearerror: false
}
const tripSupplierCollectioninitial = {
    supplierCollection: true
}

export function userstatus(state = initialValues, actions) {
    switch (actions.type) {
        case userConstatnt.USERDATA:
            return { tba: actions.tba };
        default:
            return state;
    }
}
export function menuid(state = 0, actions) {
    switch (actions.type) {
        case userConstatnt.MENUID:
            return { menuId: actions.menuId };
        default:
            return state;
    }
}

export function isClear(state = clearinitial, actions) {
    switch (actions.type) {
        case clearable.CLEARTRUE:
            return { clear: true, clearerror: false };
        case clearable.CLEARFALSE:
            return { clear: false, clearerror: false };
        case clearable.CLEARERRORTRUE:
            return { clear: false, clearerror: true };
        case clearable.CLEARERRORFALSE:
            return { clear: false, clearerror: false };
        default:
            return state;
    }
}

export function isTripSupplierCollection(state = tripSupplierCollectioninitial, actions) {
    switch (actions.type) {
        case tripSupplierCollection.YES:
            return { supplierCollection: true };
        case tripSupplierCollection.NO:
            return { supplierCollection: false };
        default:
            return state;
    }
}