import React, { useRef } from 'react';
import { useEffect, useState } from "react";
import Select from 'react-select';
import "../../assets/css/master.css"
import { getAllData, postUsersData, verifytoken } from '../../services/ApiCallingUserServices';
import Switch from 'react-switch';
import { onlyNumeric } from '../commonClass/CommonValidation';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../commonClass/Session';
import { useDispatch } from "react-redux";
import { loaderActions } from "../../_actions/loader.actions";
import { ConfirmationModal, Intracitymodal } from '../../customControl/ConfirmationModal';
import CustomSelection from '../../customControl/CustomSelection';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';

const Intracitymaster = () => {
    const dispatch = useDispatch();
    const Trip = useLocation();
    const navigate = useNavigate();
    const [allCity, setallCity] = useState([]);
    const [citydata, setCityData] = useState([]);
    const [citydisable, setCityDisable] = useState(false);
    const [cityid, setCity] = useState(0);
    const [allvehicle, setAllVehicle] = useState([]);
    const [vehicledate, setVehicleData] = useState([]);
    const [vehicleid, setVehicleId] = useState(0);
    const [alllocation, setAllLocation] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [intracityShow, setIntracityShow] = useState(false)
    const [activeId, setActiveId] = useState(false);
    const [dayid, setDayId] = useState(0);
    const [retrivedata, setRetriveData] = useState([]);
    const [buttonaddeditText, setButtonAddEditText] = useState("Submit");
    const [disabled, setDisabled] = useState();
    const [cleardisable, setClearDisable] = useState(true);
    const [editdisabled, setEditDisabled] = useState(false);
    const [backdisable, setBackDisable] = useState(false);
    const [vehicledisable, setVehicleDisable] = useState(false);
    const [tripdaydata, setTripDayDate] = useState([]);
    const [startTime, setStartTime] = useState(null);
    const [allsupplier, setAllSupplier] = useState([])
    const [supplierdata, setSupplierData] = useState([]);
    const [supplierdisable, setSupplierDisable] = useState(false)
    const [supplierid, setSupplierId] = useState(0);
    const [tripdaytemp, setTripDayTemp] = useState(0);

    const [form, setForm] = useState({
        TripMasterId: 0,
        TripDays: 0,
        TripName: "",
        IsActive: false,
        TripDetail: [],
        UserId: 0,
    });


    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            form.UserId = token?.userMasterID
            if (Trip.state != null) {
                getCityData();
                getallvehical();
                getTripDay();
                getSupplier();
                setDayId(Trip.state.TripMasterId);
                clear();
                setDisabled(Trip.state.disabled);
                setEditDisabled(Trip.state.editdisabled);
            }
            else {
                navigate('/Intracitylistmaster')
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, []);

    const RetriveData = () => {
        retrivedata?.map(obj => {
            form.TripMasterId = obj.tripMasterId
            form.TripName = obj.tripName
            form.TripDays = obj.tripDays
            form.IsActive = obj.isActive
            form.TripDetail = obj.tripDetail
            // Check In-Active Data and add in Dropdown...
            let allcitylist = allCity?.filter(IsAct => IsAct.IsActive === true);
            setCityData(allCity?.filter(IsAct => IsAct.IsActive === true));
            setCityDisable(false);
            if (!allcitylist?.find(IsCity => IsCity.LocationId === obj.tripDetail.filter(set => set.seqNo === 1)[0].locationId)) {
                setCityDisable(true)
                setCityData((prvCityData) => prvCityData?.length > 0 ? [...prvCityData, allCity?.find(isCity => isCity.LocationId === obj.tripDetail.filter(set => set.seqNo === 1)[0].locationId)] : [])

            }
            setCity(obj.tripDetail.filter(set => set.seqNo === 1)[0].locationId);
            getalllocationbyCity(obj.tripDetail.filter(set => set.seqNo === 1)[0].locationId);
            let allvehiclelist = allvehicle?.filter(IsAct => IsAct.IsActive === true);
            setVehicleData(allvehicle?.filter(IsAct => IsAct.IsActive === true));
            setVehicleDisable(false);
            if (!allvehiclelist?.find(IsCity => IsCity.VehicleDetailId === obj.tripDetail.filter(set => set.seqNo === 1)[0].vehicleDetailId)) {
                setVehicleDisable(true)
                setVehicleData((prvCityData) => prvCityData?.length > 0 ? [...prvCityData, allvehicle?.find(isCity => isCity.VehicleDetailId === obj.tripDetail.filter(set => set.seqNo === 1)[0].vehicleDetailId)] : [])
            }
            let allsupplierlist = allsupplier?.filter(IsAct => IsAct.IsActive === true);
            setSupplierData(allsupplier?.filter(IsAct => IsAct.IsActive === true));
            setSupplierDisable(false);
            if (!allsupplierlist?.find(IsCity => IsCity.SupplierId === obj.tripDetail.filter(set => set.seqNo === 1)[0].supplierId)) {
                setSupplierDisable(true)
                setSupplierData((prvCityData) => prvCityData?.length > 0 ? [...prvCityData, allsupplier?.find(isCity => isCity.SupplierId === obj.tripDetail.filter(set => set.seqNo === 1)[0].supplierId)] : [])
            }
            setSupplierId(obj.tripDetail.filter(set => set.seqNo === 1)[0].supplierId)
            setVehicleId(obj.tripDetail.filter(set => set.seqNo === 1)[0].vehicleDetailId);
            setStartTime((obj.tripDetail.filter(set => set.seqNo === 1)[0].startTime) === null ? null : dayjs(obj.tripDetail.filter(set => set.seqNo === 1)[0].startTime))
            setActiveId(obj.isActive);
            setDayId(obj.tripDays);
            setTripDayTemp(obj.tripDays)
        });
    }

    const handleRetrive = async () => {
        setButtonAddEditText("Update");
        const TripMasterId = Trip.state.TripMasterId;
        const tripbyid = await postUsersData('/master/v1/gettripdatabyid', { TripMasterId });
        setRetriveData(tripbyid.data);
    }
    useEffect(() => {
        if (retrivedata?.length === 1) {
            RetriveData();
            dispatch(loaderActions.end());
        }
    }, [retrivedata]);
    const getCityData = async () => {
        await getAllData("/master/v1/gettripcity").then((CityData) => {
            setallCity(CityData.data);
            setCityData(CityData.data?.filter(IsAct => IsAct.IsActive === true));
            if (Trip.state.TripMasterId > 0) {
                handleRetrive();
            }
            else {
                setClearDisable(false);
                dispatch(loaderActions.end());
            }
        });
    }
    const cityList = citydata?.map(value => ({
        label: value.City,
        value: value.LocationId
    }));
    const getTripDay = async () => {
        await getAllData("/master/v1/gettripdays").then((Tripdata) => {
            setTripDayDate(Tripdata.data?.map((el) => ({
                label: el.TripValue,
                value: el.TripId
            })))
        });
    }
    const getSupplier = async () => {
        await getAllData("/master/v1/gettripsupplier").then((Supplier) => {
            setAllSupplier(Supplier.data)
            setSupplierData(Supplier.data.filter(IsAct => IsAct.IsActive === true))
        });
    }
    const supplierList = supplierdata?.map(value => ({
        label: value.SupplierName,
        value: value.SupplierId
    }));

    const getallvehical = async () => {
        await getAllData('/master/v1/gettripvehicle').then((VehicleData) => {
            setAllVehicle(VehicleData.data);
            setVehicleData(VehicleData.data?.filter(IsAct => IsAct.IsActive === true));
        })
    }
    const vehicleList = vehicledate?.map(value => ({
        label: value.VehicleName,
        value: value.VehicleDetailId
    }));

    const handlecity = (e, el) => {
        setForm({
            ...form, TripDetail: form.TripDetail.map((ele) => {
                if (ele.seqNo === el) {
                    ele.locationId = e.value
                }
                return ele;
            })
        })
        setCity(e.value);
        getalllocationbyCity(e.value);
        setFormErrors('');
        setEditDisabled(false);

    }
    const handleTripDay = (e) => {
        if (dayid === 0) {
            var rows = [], i = 0, len = e.value;
            while (++i <= len) rows.push(i);
            setForm({
                ...form, TripDays: e.value, TripDetail: rows.map((el) => ({
                    locationId: 0,
                    dayHours: 0,
                    startTime: null,
                    dayDescription: '',
                    supplierId: 0,
                    vehicleDetailId: 0,
                    price: 0,
                    seqNo: el
                }))

            });
            setTripDayTemp(e.value)
            setCity(0);
            setVehicleId(0);
            setSupplierId(0);
            setStartTime(null)
            setDayId(e.value);
        }
        else {
            setForm({ ...form, TripDays: e.value })
            var rows = [], i = 0, len = e.value;
            while (++i <= len) rows.push(i);
            setIntracityShow(true);
        }
        setFormErrors('');
        const element = document.querySelectorAll(".active:last-child")[0];
        element?.classList.remove("active");
        document.getElementById('tabl1')?.classList.add("active");
        document.getElementById('tab1').click();
    }
    const handleDayConfirmation = (e) => {
        if (e === true) {
            var rows = [], i = 0, len = form.TripDays;
            while (++i <= len) rows.push(i);
            setForm({ ...form, TripDetail: [] })
            setTimeout(() => {
                setForm({
                    ...form, TripDetail: rows.map((el) => ({
                        locationId: 0,
                        dayHours: 0,
                        startTime: null,
                        dayDescription: '',
                        supplierId: 0,
                        vehicleDetailId: 0,
                        price: 0,
                        seqNo: el
                    }))
                })
            }, 0);
            setEditDisabled(false);
            setFormErrors('');
            setCity(0);
            setSupplierId(0);
            setVehicleId(0);
            setDayId(form.TripDays);
            setStartTime(null);
            const element = document.querySelectorAll(".active:last-child")[0];
            element?.classList.remove("active");
            document.getElementById('tabl1')?.classList.add("active");
            document.getElementById('tab1').click();
        }
        else {
            setDayId(tripdaytemp);
            setForm({ ...form, TripDays: tripdaytemp })
        }
        setIntracityShow(false);
        setFormErrors('');
    }
     const closepopup = () =>{
        setIntracityShow(false);
        handleDayConfirmation(false)
     }
    const handlesupplier = (e, el) => {
        setForm({
            ...form, TripDetail: form.TripDetail.map((ele) => {
                if (ele.seqNo === el) {
                    ele.supplierId = e.value
                }
                return ele;
            })
        })
        setFormErrors('');
        setSupplierId(e.value);
        setEditDisabled(false);
    }
    const handleVehiclechange = (e, el) => {
        setForm({
            ...form, TripDetail: form.TripDetail.map((ele) => {
                if (ele.seqNo === el) {
                    ele.vehicleDetailId = e.value
                }
                return ele;
            })
        })
        setVehicleId(e.value);
        setFormErrors('');
        setEditDisabled(false);
    }
    const getalllocationbyCity = async (locationid) => {
        const locid = { LocationId: locationid }
        const AllLocationData = await postUsersData('/master/v1/getlocationbyid', locid)
        setAllLocation(AllLocationData.data);
    }
    const continent = alllocation?.map(value => ({
        label: value.continentName,
        value: 1
    })
    );

    const country = alllocation?.map(value => ({
        label: value.countryName,
        value: 1
    })
    );

    const region = alllocation?.map(value => ({
        label: value.regionName,
        value: 1
    })
    );

    const state = alllocation?.map(value => ({
        label: value.stateName,
        value: 1
    })
    );
    const handleDeparture = (time, el) => {
        setEditDisabled(true);
        let x = new Date(time)
        let hours = x.getHours();
        let minut = x.getMinutes();
        if (hours < 10) {
            hours = '0' + hours
        }
        if (minut < 10) {
            minut = '0' + minut
        }
        setForm({
            ...form, TripDetail: form.TripDetail.map((ele) => {
                if (ele.seqNo === el) {
                    ele.startTime = formatDate(new Date()) + "T" + hours + ':' + minut + ":00"
                }
                if (time == null) {
                    ele.startTime = null
                }
                return ele;
            })
        })
        setFormErrors('');
        setStartTime(time);
        setEditDisabled(false);
    }
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return date === null ? null : [year, month, day].join('-');
    }

    const validate = (values) => {
        setIsSubmit(true);
        const error = {}
        if (!values.TripName || values.TripName?.trim()?.length === 0) {
            error.TripName = "Kindly enter trip name";
            setIsSubmit(false);
        }
        if (values.TripDays === 0) {
            error.TripDays = "Kindly select the number of days";
            setIsSubmit(false);
        }
        values.TripDetail.map((items) => {
            if (items.locationId === 0) {
                error.locationId = "Kindly select the city";
                setIsSubmit(false);
            }
            if (!items.dayDescription || items.dayDescription.trim().length === 0) {
                error.dayDescription = "Kindly enter description";
                setIsSubmit(false);
            }
            if (items.supplierId === 0) {
                error.supplierId = "Kindly select the supplier"
                setIsSubmit(false);
            }
            if (items.vehicleDetailId === 0) {
                error.vehicleDetailId = "Kindly select the vehicle";
                setIsSubmit(false);
            }
            if (items.price === 0 || items.price === '') {
                error.price = "Kindly enter price";
                setIsSubmit(false);
            }
            if (items.dayHours > 20 && items.dayHours !== '') {
                error.dayHours = "Hours should less than 20 and more than 0";
                setIsSubmit(false);
            }
            if (items.dayHours < 1 && items.dayHours !== '') {
                error.dayHours = "Hours should less than 20 and more than 0";
                setIsSubmit(false);
            }
        })

        return error;
    }

    const onChange = (e, el) => {
        var result;
        if ([e.target.name][0] === 'price' || [e.target.name][0] === 'dayHours') {
            result = onlyNumeric(e.target.value) === "" ? "" : Number(onlyNumeric(e.target.value))
        }
        else {
            result = e.target.value;
        }
        setForm({
            ...form, TripDetail: form.TripDetail.map((ele) => {
                if (ele.seqNo === el) {
                    if (e.target.name === 'dayHours') {
                        ele.dayHours = result
                    }
                    if (e.target.name === 'dayDescription') {
                        ele.dayDescription = result
                    }
                    if (e.target.name === 'price') {
                        ele.price = result
                    }
                }
                return ele;
            })
        })
        setEditDisabled(false);
        setFormErrors('');
    }

    const handletrip = (e) => {
        var result;
        if ([e.target.name][0] === 'price' || [e.target.name][0] === 'dayHours') {
            result = onlyNumeric(e.target.value)
        }
        else {
            result = e.target.value;
        }
        setForm({ ...form, [e.target.name]: result });
        setEditDisabled(false);
        setFormErrors('');
        setIsSubmit(false)
    }

    const LocationId = useRef(null);
    const Vehicleid = useRef(null);
    const SupplierId = useRef(null)

    useEffect(() => {
        // Be sure that sequence of form fields and sequence of formErrors variables/fields are same, because every time focusing is done by the formErrors Array's top value. 
        if (Object.keys(formErrors)?.length === 0 && isSubmit) {
            setDisabled(true);
            setClearDisable(true);
            setEditDisabled(true);
            setBackDisable(true);
            handleSubmitData();
        }
        if (Object.keys(formErrors).length > 0) {

            let id = Object.keys(formErrors)[0];
            if (id === "keys") {
                eval(id).current.focus();
            }
            else {
                let doc = document.getElementById(id);
                if (doc !== null)
                    doc.focus();
            }
        }

    }, [formErrors]);

    // const handleClearClick = () => {
    //     clear();
    // }
    function clear() {
        setIsSubmit(false);
        setFormErrors('');
        setForm({
            ...form, TripName: '', IsActive: false, TripDays: 0, TripDetail: []
        });
        setCity();
        setAllLocation([]);
        setVehicleId();
        setActiveId(false);
        setDayId(0)
        setAllVehicle([]);
        getCityData();
        getallvehical();
        setCityDisable(false);
        setVehicleDisable(false);
        setCityData([]);
        setVehicleData([]);
    }
    const handleisActive = (e) => {
        if (buttonaddeditText === 'Submit') {
            form.IsActive = e
            setActiveId(!activeId)
            setFormErrors('');
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (e) => {
        if (e === true) {
            form.IsActive = !activeId
            setActiveId(!activeId)
            setEditDisabled(false);
            setFormErrors('');
        }
        setModalShow(false);
    }

    const onSubmitForm = e => {
        e.preventDefault();
        setFormErrors(validate(form));
    };

    const handleSubmitData = async () => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            const tripdata = await postUsersData('/master/v1/inserttripdata', form)
            const status = tripdata.status;
            if (!tripdata.status && !tripdata.data) {
                dispatch({ type: 'ALERTING', message: tripdata.message });
                setDisabled(false);
                setClearDisable(false);
                setEditDisabled(false);
                setBackDisable(false);
                return;
            }
            if (status) {
                dispatch({ type: 'ALERTINGMESSAGE', message: tripdata.message })
                clear();
                setDisabled(false);
                setClearDisable(false);
                setEditDisabled(false);
                setBackDisable(false);
            }
            else {
                dispatch({ type: 'ALERTINGMESSAGE', message: tripdata.message })
                setDisabled(false);
                setClearDisable(false);
                setEditDisabled(false);
                setBackDisable(false);
                return;
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }

    //#endregion 
    const ValidateData = (values, e) => {
        const items = values.TripDetail.filter(obj => obj.seqNo === e)
        let status = true;
        const error = {}
        if (items[0].locationId === 0) {
            error.locationId = "Kindly select the city";
            status = false;
        }
        if (!items[0].dayDescription || values.dayDescription?.trim()?.length === 0) {
            error.dayDescription = "Kindly enter description";
            status = false;
        }
        if (items[0].supplierId === 0) {
            error.supplierId = "Kindly select the supplier"
            status = false;
        }
        if (items[0].vehicleDetailId === 0) {
            error.vehicleDetailId = "Kindly select the vehicle";
            status = false;
        }
        if (items[0].price === 0 || items[0].price === '') {
            error.price = "Kindly enter price";
            status = false;
        }
        if (items[0].dayHours > 20 && items[0].dayHours !== '') {
            error.dayHours = "Hours should less than 20 and more than 0";
            status = false;
        }
        if (items[0].dayHours < 1 && items[0].dayHours !== '') {
            error.dayHours = "Hours should less than 20 and more than 0";
            status = false;
        }
        setFormErrors(error);
        return status
    }

    const handlebackClick = () => {
        clear();
        navigate('/Intracitylistmaster');
    }
    const handletabback = (e) => {
        dispatch(loaderActions.start());
        setForm({
            ...form, TripDetail: form.TripDetail.map((ele) => {
                if (ele.seqNo === e - 1 && ele.locationId > 0 && ele.vehicleDetailId > 0 && ele.supplierId) {
                    let allcitylist = allCity?.filter(IsAct => IsAct.IsActive === true);
                    setCityData(allCity?.filter(IsAct => IsAct.IsActive === true));
                    setCityDisable(false);
                    if (!allcitylist?.find(IsCity => IsCity.LocationId === ele.locationId)) {
                        setCityDisable(true)
                        setCityData((prvCityData) => prvCityData?.length > 0 ? [...prvCityData, allCity?.find(isCity => isCity.LocationId === ele.locationId)] : [])

                    }
                    getalllocationbyCity(ele.locationId);
                    let allvehiclelist = allvehicle?.filter(IsAct => IsAct.IsActive === true);
                    setVehicleData(allvehicle?.filter(IsAct => IsAct.IsActive === true));
                    setVehicleDisable(false);
                    if (!allvehiclelist?.find(IsCity => IsCity.VehicleDetailId === ele.vehicleDetailId)) {
                        setVehicleDisable(true)
                        setVehicleData((prvCityData) => prvCityData?.length > 0 ? [...prvCityData, allvehicle?.find(isCity => isCity.VehicleDetailId === ele.vehicleDetailId)] : [])
                    }
                    let allsupplierlist = allsupplier?.filter(IsAct => IsAct.IsActive === true);
                    setSupplierData(allsupplier?.filter(IsAct => IsAct.IsActive === true));
                    setSupplierDisable(false);
                    if (!allsupplierlist?.find(IsCity => IsCity.SupplierId === ele.supplierId)) {
                        setSupplierDisable(true)
                        setSupplierData((prvCityData) => prvCityData?.length > 0 ? [...prvCityData, allsupplier?.find(isCity => isCity.SupplierId === ele.supplierId)] : [])
                    }
                    setCity(ele.locationId);
                    setSupplierId(ele.supplierId);
                    setVehicleId(ele.vehicleDetailId);
                    setStartTime((ele.startTime) === null ? null : dayjs(ele.startTime))
                }
                return ele;
            })
        })
        setFormErrors('');
        dispatch(loaderActions.end());
        document.getElementById(`tab${e - 1}`).click();
        document.getElementById(`tabl${e}`).classList.remove("active");
        document.getElementById(`tabl${e - 1}`).classList.add("active");
    }
    const handletabnext = (e) => {
        if (!ValidateData(form, e)) {
            return;
        }
        else {
            dispatch(loaderActions.start());
            setForm({
                ...form, TripDetail: form.TripDetail.map((ele) => {
                    if (ele.seqNo === e + 1) {
                        getalllocationbyCity(0);
                        setCity(0);
                        setSupplierId(0);
                        setVehicleId(0);
                        if (ele.locationId > 0) {
                            let allcitylist = allCity?.filter(IsAct => IsAct.IsActive === true);
                            setCityData(allCity?.filter(IsAct => IsAct.IsActive === true));
                            setCityDisable(false);
                            if (!allcitylist?.find(IsCity => IsCity.LocationId === ele.locationId)) {
                                setCityDisable(true)
                                setCityData((prvCityData) => prvCityData?.length > 0 ? [...prvCityData, allCity?.find(isCity => isCity.LocationId === ele.locationId)] : [])

                            }
                            getalllocationbyCity(ele.locationId);
                            setCity(ele.locationId);
                        }
                        if (ele.vehicleDetailId > 0) {
                            let allvehiclelist = allvehicle?.filter(IsAct => IsAct.IsActive === true);
                            setVehicleData(allvehicle?.filter(IsAct => IsAct.IsActive === true));
                            setVehicleDisable(false);
                            if (!allvehiclelist?.find(IsCity => IsCity.VehicleDetailId === ele.vehicleDetailId)) {
                                setVehicleDisable(true)
                                setVehicleData((prvCityData) => prvCityData?.length > 0 ? [...prvCityData, allvehicle?.find(isCity => isCity.VehicleDetailId === ele.vehicleDetailId)] : [])
                            }
                            setSupplierId(ele.supplierId);
                        }
                        if (ele.supplierId > 0) {
                            let allsupplierlist = allsupplier?.filter(IsAct => IsAct.IsActive === true);
                            setSupplierData(allsupplier?.filter(IsAct => IsAct.IsActive === true));
                            setSupplierDisable(false);
                            if (!allsupplierlist?.find(IsCity => IsCity.SupplierId === ele.supplierId)) {
                                setSupplierDisable(true)
                                setSupplierData((prvCityData) => prvCityData?.length > 0 ? [...prvCityData, allsupplier?.find(isCity => isCity.SupplierId === ele.supplierId)] : [])
                            }
                            setVehicleId(ele.vehicleDetailId);
                        }
                        setStartTime((ele.startTime) === null ? null : dayjs(ele.startTime));
                    }
                    return ele;
                })
            })
            setFormErrors('');
            dispatch(loaderActions.end());
            document.getElementById(`tab${e + 1}`).click();
            document.getElementById(`tabl${e}`).classList.remove("active");
            document.getElementById(`tabl${e + 1}`).classList.add("active");
        }
    }
    const handleseqdataclear = (e) => {
        setForm({
            ...form, TripDetail: form.TripDetail.map((ele) => {
                if (ele.seqNo === e) {
                    ele.locationId = 0
                    ele.dayHours = 0
                    ele.startTime = null
                    ele.dayDescription = ''
                    ele.supplierId = 0
                    ele.vehicleDetailId = 0
                    ele.price = 0
                }
                return ele;
            })
        })
        setCity(0);
        setSupplierId(0);
        setVehicleId(0);
        setFormErrors('');
        setStartTime(null);
        setIsSubmit(false);
        setAllLocation([]);
        setCityDisable(false);
        setVehicleDisable(false);
        setSupplierDisable(false);
        setCityData([]);
        setallCity([])
        setAllVehicle([]);
        setVehicleData([]);
        setAllSupplier([]);
        setSupplierData([])
        getallvehical();
        getCityData();
        getSupplier()
    }
    return (
        <>

            <div className="container mt-4 heading_color">
                <h3>Intracity Master</h3>
            </div>
            <div className="container form_border py-5 my-3">
                <form action="" >
                    <div className="mb-3 row mx-1">
                        <div className='row'>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div className="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" className="col-form-label required">Trip Name</label>
                                    </div>
                                    <div className=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <input className="form-control"
                                            type="text"
                                            id='TripName'
                                            name="TripName"
                                            value={form.TripName}
                                            onChange={handletrip}
                                            disabled={disabled ? true : false}
                                            placeholder='Trip Name'
                                            maxLength={150}
                                        />
                                        {formErrors.TripName && <p className='text-danger text-start'>{formErrors.TripName}</p>}
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div className="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" className="col-form-label required">No of Days</label>
                                    </div>
                                    <div className=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <Select
                                            className="dropdown select_dropdown"
                                            options={tripdaydata}
                                            value={tripdaydata?.filter(function (tripdaydata) {
                                                return tripdaydata.value === dayid;
                                            })}// set list of the dataalue 
                                            id='dayid'
                                            onChange={handleTripDay} // assign onChange function
                                            isDisabled={disabled ? true : false}
                                            isSearchable={true}

                                        />
                                        <Intracitymodal
                                            show={intracityShow}
                                            onHide={closepopup}
                                            yesintraconfirm={() => handleDayConfirmation(true)}
                                            nointraconfirm={() => handleDayConfirmation(false)}
                                        />
                                        {formErrors.TripDays && <p className='text-danger text-start'>{formErrors.TripDays}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div className="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" className="col-form-label">Active</label>
                                    </div>
                                    <div className=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <Switch
                                            onChange={handleisActive}
                                            checked={activeId}
                                            className="react-switch"
                                            disabled={disabled ? true : false}
                                        // disabled={disabled ? true : false}
                                        />
                                    </div>
                                    {/* <div className=" col-auto col-lg-4"> */}
                                    <ConfirmationModal
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                        noconfirm={() => handleConfirmation(false)}
                                        yesconfirm={() => handleConfirmation(true)}
                                    />

                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="nav nav-tabs border-0 mx-3 mt-3" id="myTab" role="tablist">
                        {form.TripDetail.map((el) => (
                            <li className="nav-item" role="presentation">
                                <button className={`nav-link  ${el.seqNo === 1 ? 'active' : ''} intacity_border text-dark`} disabled id={`tabl${el.seqNo}`} data-bs-toggle="tab" data-bs-target={`#homel${el.seqNo}`} type="button" role="tab" aria-controls={`homel${el.seqNo}`} aria-selected="true">Day-{el.seqNo}</button>
                            </li>
                        ))}
                    </ul>
                    <ul className="nav nav-tabs border-0 mx-3 mt-3 d-none" id="myTab" role="tablist">
                        {form.TripDetail.map((el) => (
                            <li className="nav-item" role="presentation">
                                <button className={`nav-link  ${el.seqNo === 1 ? 'active' : ''} intacity_border text-dark`} id={`tab${el.seqNo}`} data-bs-toggle="tab" data-bs-target={`#home${el.seqNo}`} type="button" role="tab" aria-controls={`home${el.seqNo}`} aria-selected="true">Day-{el.seqNo}</button>
                            </li>
                        ))}
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        {form.TripDetail.map((ele) => (
                            <div className={`tab-pane fade ${ele.seqNo === 1 ? 'show active' : ''}`} id={`home${ele.seqNo}`} role="tabpanel" aria-labelledby={`home${ele.seqNo}`}>
                                <div className=" form_border py-5 mb-3">
                                    <div className="mb-3 row mx-1">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="row">
                                                <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                                                    <div className="col-lg-12 col-md-12">
                                                        <label for="inputPassword6" className="col-form-label required">Continent</label>
                                                    </div>
                                                    <div className="col-lg-12 mb-1 col-md-12">
                                                        <Select className="dropdown select_dropdown"
                                                            isDisabled
                                                            value={continent?.find(({ value }) => value === 1)}
                                                            options={continent}
                                                        />
                                                    </div>

                                                </div>
                                                <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                                                    <div className="col-lg-12 col-md-12">
                                                        <label for="inputPassword6" className="col-form-label required">Country</label>
                                                    </div>
                                                    <div className="col-lg-12 mb-1 col-md-12">
                                                        <Select className="dropdown select_dropdown"
                                                            isDisabled
                                                            value={country?.find(({ value }) => value === 1)}
                                                            options={country} />
                                                    </div>

                                                </div>
                                                <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                                                    <div className="col-lg-12 col-md-12">
                                                        <label for="inputPassword6" className="col-form-label required">Region</label>
                                                    </div>
                                                    <div className="col-lg-12 mb-1 col-md-12">
                                                        <Select className="dropdown select_dropdown"
                                                            isDisabled
                                                            value={region?.find(({ value }) => value === 1)}
                                                            options={region} />
                                                    </div>
                                                </div>
                                                <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                                                    <div className="col-lg-12 col-md-12">
                                                        <label for="inputPassword6" className="col-form-label required">State</label>
                                                    </div>
                                                    <div className="col-lg-12 mb-1 col-md-12">
                                                        <Select className="dropdown select_dropdown"
                                                            isDisabled
                                                            value={state?.find(({ value }) => value === 1)}
                                                            options={state} />
                                                    </div>
                                                </div>
                                                <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                                                    <div className="col-lg-12 col-md-12">
                                                        <label for="inputPassword6" className="col-form-label required">City</label>
                                                    </div>
                                                    <div className="col-lg-12 mb-1 col-md-12">
                                                        <CustomSelection
                                                            datalist={cityList}
                                                            selId='LocationId'
                                                            selRef={LocationId}
                                                            fieldid={cityid}
                                                            SelectionChange={(e) => handlecity(e, ele.seqNo)}
                                                            isdisable={disabled}
                                                            isactivedisable={citydisable}
                                                            issearchable={true}
                                                        />
                                                        {formErrors.locationId && <p className='text-danger text-start'>{formErrors.locationId}</p>}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-1 mt-4 col-lg-11 col-md-12 mx-lg-5 px-2">
                                        <div className='row'>
                                            <div className='col-lg-6 col-sm-6 '>
                                                <div className='row'>
                                                    <div className="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                        <label for="inputPassword6" className="col-form-label">Hours</label>
                                                    </div>
                                                    <div className=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                        <input className="form-control"
                                                            type="text"
                                                            id='dayHours'
                                                            name="dayHours"
                                                            value={ele.dayHours}
                                                            onChange={(e) => onChange(e, ele.seqNo)}
                                                            disabled={disabled ? true : false}
                                                            placeholder='Hours'
                                                            maxLength={2}
                                                        />
                                                        {formErrors.dayHours && <p className='text-danger text-start'>{formErrors.dayHours}</p>}
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-sm-6 '>
                                                <div className='row'>
                                                    <div className="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                        <label for="inputPassword6" className="col-form-label">Start Time</label>
                                                    </div>
                                                    <div className=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">

                                                        <TimePicker id='DepTime' className='form-control defo_border' disabled={disabled ? true : false} placeholder='--:--' value={startTime} format={'HH:mm'} onChange={(e) => handleDeparture(e, ele.seqNo)} />
                                                        {formErrors.SupplierName && <p className='text-danger text-start'>{formErrors.SupplierName}</p>}
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='col-lg-12 col-sm-12 '>
                                                <div className='row'>
                                                    <div className="col-lg-3 col-md-12 col-sm-12 mb-lg-1">
                                                        <label for="inputPassword6" className="col-form-label required">Description</label>
                                                    </div>
                                                    <div className=" col-lg-9 col-md-12 col-sm-12 mb-lg-1 mb-md-2">
                                                        <textarea className="form-control"
                                                            id="dayDescription"
                                                            rows="2"
                                                            name="dayDescription"
                                                            value={ele.dayDescription}
                                                            disabled={disabled ? true : false}
                                                            onChange={(e) => onChange(e, ele.seqNo)}
                                                            placeholder='Description'
                                                            maxLength={2500}
                                                        >

                                                        </textarea>
                                                        {formErrors.dayDescription && <p className='text-danger text-start'>{formErrors.dayDescription}</p>}
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-sm-6 '>
                                                <div className='row'>
                                                    <div className="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                        <label for="inputPassword6" className="col-form-label required">Select Supplier</label>
                                                    </div>
                                                    <div className=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                        <CustomSelection
                                                            datalist={supplierList}
                                                            selId='SupplierId'
                                                            selRef={SupplierId}
                                                            fieldid={supplierid}
                                                            SelectionChange={(e) => handlesupplier(e, ele.seqNo)}
                                                            isdisable={disabled}
                                                            isactivedisable={supplierdisable}
                                                            issearchable={true}
                                                        />
                                                        {formErrors.supplierId && <p className='text-danger text-start'>{formErrors.supplierId}</p>}
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-sm-6'>
                                                <div className='row'>
                                                    <div className="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                        <label for="inputPassword6" className="col-form-label required">State Vehicle</label>
                                                    </div>
                                                    <div className=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                        <CustomSelection
                                                            datalist={vehicleList}
                                                            selId='Vehicleid'
                                                            selRef={Vehicleid}
                                                            fieldid={vehicleid}
                                                            SelectionChange={(e) => handleVehiclechange(e, ele.seqNo)}
                                                            isdisable={disabled}
                                                            isactivedisable={vehicledisable}
                                                            issearchable={true}
                                                        />
                                                        {formErrors.vehicleDetailId && <p className='text-danger text-start'>{formErrors.vehicleDetailId}</p>}
                                                    </div>

                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-sm-6 '>
                                                <div className='row'>
                                                    <div className="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                        <label for="inputPassword6" className="col-form-label required">Price</label>
                                                    </div>
                                                    <div className=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                        <input type="text"
                                                            id="price"
                                                            className="form-control"
                                                            name='price'
                                                            value={ele.price}
                                                            disabled={disabled ? true : false}
                                                            onChange={(e) => onChange(e, ele.seqNo)}
                                                            maxLength={5}
                                                            placeholder='Price' />
                                                        {formErrors.price && <p className='text-danger text-start'>{formErrors.price}</p>}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 d-flex justify-content-center">
                                        {ele.seqNo !== dayid ? (<button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={() => handletabnext(ele.seqNo)}>Next</button>) : (
                                            <button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={onSubmitForm} disabled={editdisabled ? true : false}>{buttonaddeditText}</button>
                                        )}
                                        {/* {ele.seqNo !== dayid ? ( */}
                                        <button type="button" className="btn btn-primary mx-1" id="btnClear" onClick={() => handleseqdataclear(ele.seqNo)} disabled={cleardisable ? true : false}>Clear</button>
                                        {/* ):(<button type="button" className="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick} disabled={cleardisable ? true : false}>Clear</button>)} */}

                                        {ele.seqNo === 1 ? (<button type="button" className="btn btn-primary mx-1" id="btnVisible" onClick={handlebackClick} disabled={backdisable ? true : false}>Back</button>) : (
                                            <button type="button" className="btn btn-primary mx-1" id="btnVisible" onClick={() => handletabback(ele.seqNo)}>Previous</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))

                        }
                    </div>

                </form>
            </div>
        </>
    )
}

export default Intracitymaster