import React, { useEffect, useRef, useState } from 'react'
import Usergeneratedservice from './Usergeneratedservice';
import Standardservice from './Standardservice';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../commonClass/Session';
import { useDispatch } from 'react-redux';
import { getAllData, postUsersData } from '../../services/ApiCallingUserServices';

const Tripplanner = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const [disabled, setDisabled] = useState(false);

    const [checkServiceType, setCheckServiceType] = useState(1)

    const [ServiceTypeData, setServiceTypeData] = useState([])
    // const [data, setData] = useState([])

    const getplannerservicetype = async () => {
        const servicetype = await getAllData('/master/v1/getplannerservicetype')
        setServiceTypeData(servicetype?.data?.filter(obj => obj.IsActive === true))
    }


    const handleServiceTypeChange = (id, e) => {
        setCheckServiceType(id)
    }

    const retrievedata = async (PlannerId) => {
        if (PlannerId > 0) {
            const retrieveTripData = await postUsersData('/master/v1/getplannerbyid', { PlannerId })
            setCheckServiceType(retrieveTripData?.data[0]?.PlannerServiceTypeId)
            // setData(retrieveTripData?.data[0])
        }
    }

    useEffect(() => {
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            if (location?.state != null) {
                if (location?.state?.viewtype === "Update" || location?.state?.viewtype === "View") {
                    setDisabled(true);
                }
                getplannerservicetype()
                retrievedata(location?.state?.tripplannerId)
            }
            else {
                navigate('/Tripplannerlist')
            }
        } else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, [])


    return (
        <>
            <div className="container mt-4 heading_color">
                <h3>Trip Planner</h3>
            </div>
            <div className="container form_border py-5 mb-3">

                <div className="mb-3">
                    <form action="">
                        <div className="mb-3 row mx-1">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <div className="row">
                                            <div className="col-lg-2 col-md-12 mb-1">
                                                <label className="col-form-label required" htmlFor="VehicleName">Service Type</label>
                                            </div>
                                            <div className="col-lg-10 mb-1 col-md-12 align-self-center">
                                                {ServiceTypeData && ServiceTypeData?.map((obj, index) => {
                                                    return (
                                                        <div className="form-check-inline col-lg-6 col-md-6 col-12 me-0">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="ServiceTypeRadio"
                                                                id={"ServiceTypeRadio" + obj?.PlannerServiceTypeId}
                                                                value={obj?.PlannerServiceTypeId}
                                                                key={obj?.PlannerServiceTypeId}
                                                                checked={obj?.PlannerServiceTypeId === checkServiceType ? true : false}
                                                                onChange={(e) => handleServiceTypeChange(obj?.PlannerServiceTypeId, e)}
                                                                disabled={disabled ? true : false}
                                                            />
                                                            <label className="form-check-label ms-1" htmlFor={"ServiceTypeRadio" + obj?.PlannerServiceTypeId} >{obj?.PlannerServiceType}</label>
                                                        </div>
                                                    );

                                                })}
                                            </div>
                                        </div>
                                    </div>


                                    {
                                        checkServiceType === 1 ?
                                            (
                                                <>
                                                    <div className="my-3">
                                                        <Standardservice id={1} location={location} />
                                                    </div>
                                                </>
                                            )
                                            :
                                            (<></>)
                                    }
                                    {
                                        checkServiceType === 2 ?
                                            (
                                                <>
                                                    <div className="my-3">
                                                        <Usergeneratedservice id={2} location={location} />
                                                    </div>
                                                </>
                                            )
                                            :
                                            (<></>)
                                    }
                                </div>
                            </div>
                        </div>


                    </form>
                </div>

            </div>
        </>
    )
}

export default Tripplanner
