// import * as XLSX from 'xlsx';
import moment from 'moment/moment';
import ExcelJS from 'exceljs'
import { htmlToText } from 'html-to-text';

function ExportExcel(displayName, headerName, dataCollection, sheetName, fileName) {
    let headerrow = displayName
    let rowcolname = headerName
    let coldata = [];
    if (headerrow != null) {
        coldata.push(headerrow)
        dataCollection.forEach(element => {
            let celldata = []
            rowcolname.map((obj) => {
                Object.keys(element).filter(ele => ele === obj).map((key) => {
                    if (key.toLowerCase() === 'isactive') {
                        celldata.push(element[key] === true ? 'Active' : 'InActive')
                    }
                    else if (key.toLowerCase() === 'departuredate' || key.toLowerCase() === 'arrivaldate' || key.toLowerCase() === 'pnrdate' || key.toLowerCase() === 'createddate' || key.toLowerCase() === 'createdon' || key.toLowerCase() === 'txndate') {
                        celldata.push(moment(element[key]).format('DD/MM/YYYY'))
                    }
                    else if (key.toLowerCase() === 'departuretime' || key.toLowerCase() === 'airrivaltime' || key.toLowerCase() === 'hours') {
                        //else if (key.toLowerCase() === 'departuretime' || key.toLowerCase() === 'airrivaltime') {
                        celldata.push(moment(element[key]).format('HH:mm'))
                    }
                    else if (key.toLowerCase() === 'hatchback' || key.toLowerCase() === 'sedan' || key.toLowerCase() === 'suv' || key.toLowerCase() === 'muv' || key.toLowerCase() === 'tempo' || key.toLowerCase() === 'semisleepervolvo'
                        || key.toLocaleLowerCase() === "rating") {
                        celldata.push(element[key])
                    }
                    else if (key.toLowerCase() === "policydetails") {
                        celldata.push(htmlToText(element[key]))
                    }
                    else {
                        celldata.push(element[key])
                    }
                })
            })
            coldata.push(celldata)
        });

        // var wb = XLSX.utils.book_new(),
        //     ws = XLSX.utils.aoa_to_sheet(coldata);

        // XLSX.utils.book_append_sheet(wb, ws, sheetName);
        // XLSX.writeFile(wb, fileName);

        const wb = new ExcelJS.Workbook();
        const ws = wb.addWorksheet(sheetName);
        ws.addRows(coldata);
        const firstRow = ws.getRow(1);
        firstRow.font = { bold: true }

        // Generate a file and download it
        wb.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.click();
        });


    }
}
export { ExportExcel }