import { loaderConstants } from '../_constants/loader.constants';

export const loaderActions = {
    start,
    end
};

function start() {
    return { type: loaderConstants.LOADER_START };
}

function end() {
    return { type: loaderConstants.LOADER_END };
}
