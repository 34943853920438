import React, { useMemo } from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from 'react-select';
import "../../assets/css/master.css"
import { getAllData, postUsersData } from '../../services/ApiCallingUserServices';
import { onlyNumeric } from '../commonClass/CommonValidation';
import { useLocation } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import { useDispatch, useSelector } from "react-redux";
import RichTextBox from '../../customControl/RichTextBox';
// import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { errorActions } from '../../_actions/error.action';
import CustomTimePicker from '../../customControl/CustomTimePicker';
import CustomSelection from '../../customControl/CustomSelection';
import { loaderActions } from '../../_actions/loader.actions';

const VendorItinerary = (props) => {
    const errorstatus = useSelector(state=>state.errorStatus.isError);
    const itinerarystatus = useSelector(state=>state.errorStatus.isItinerary);

    const {
        editdisable,
        flightvisibleflag,
        handleItineraryDetailAdd,
        handleItineraryDisable,
        itinerarydetail,
        retriveflag
    }=props
    const dispatch = useDispatch();

    const [flightvisible, setFlightVisibled] = useState(true);

    const [formvalue, setFormValue] = useState([]);
    const [flightfromid, setFlightFromId] = useState(0);
    const [flighttoid, setFlightToId] = useState(0);
    const [airlinelist, setAirlineList] = useState([]);
    const [airlineid, setAirlineId] = useState(0);
    const [flightnolist, setFlightNoList] = useState([]);
    
    const [flightno, setFlightNo] = useState('');
    const [supplierlist, setSupplierList] = useState([]);
    const [airlineclasslist, setAirlineClassList] = useState([]);
    const [flightdata, setFlightData] = useState([]);
    const [classid, setClassId] = useState(0);
    const [supplierid, setSupplierId] = useState(0);
    const [itineraryid, setItineraryId] = useState(0);

    const [dataitinerary, setDataItinerary] = useState([]);
    const [dataitinerarycounter, setDataItineraryCounter] = useState(0);
    const [itinerarybtntext, setItineraryBtnText] = useState('Add');

    const [departureTime, setDepartureTime] = useState("--:--");
    const [arrivalTime, setArrivalTime] = useState("--:--");
    const [hoursTime, setHoursTime] = useState("--:--");
    const [formerror, setFormError] = useState({});

    const [hotelnameid, setHotelNameId] = useState(0);
    const [hotelroomtypeid, setHotelRoomTypeId] = useState(0);

    const [vehicalistdata, setVehicalListData] = useState([]);
    const [vehicalprice, setVehicalPrice] = useState('');
    const [vehicaltypeid, setVehicalTypeId] = useState(0);
    const [sightseeingid, setSightSeeingId] = useState(0);
    const [noofpaxid, setNoOfPaxId] = useState(0);

    // const [hotelroomprice, setHotelRoomPrice] = useState(0);

    const [fromflightdisable, setFromFlightDisable] = useState(false)
    const [toflightdisable, setToFlightDisable] = useState(false)
    const [hoteldisable, setHotelDisable] = useState(false)
    const [sightseeingdisable, setSightseeingDisable] = useState(false)
    const [roomtypelist, setRoomTypeList] = useState([]);
    const [roompricelist, setRoomPriceList] = useState([]);
    const [sightseeingname, setSightseeingName] = useState('');
    const [hotelrating, setHotelRating] = useState();
    const [hotelname, setHotelName] = useState('');

    const [vehicaltypelist, setVehicalTypeList] = useState([]);
    const [allsightseeing, setAllSightseeing] = useState([]);

    const [fromflight, setFlightfrom] = useState([]);
    const [allflightfrom, setAllFilghtFrom] = useState([]);
    const [toflight, setFlightTo] = useState([]);
    const [allflightto, setAllFilghtTo] = useState([]);
    const [allhotel, setAllHotel] = useState([]);
    const [hotelnamelist, setHotelNameList] = useState([]);
    const [sightseeinglist, setSightSeeingList] = useState([]);
    // const [vehicaltypelist, setVehicalTypeList] = useState([]);

    const [richboxheight, setRichBoxHeight] = useState(false);

    const [flightopen, setFlightOpen] = useState();
    const [hotelopen, setHotelOpen] = useState();
    const [flightbuttontext, setFlightbuttontext] = useState('');
    const [hotelbuttontext, setHotelbuttontext] = useState('');
    const [sightseeingbuttontext, setSightseeingbuttontext] = useState('');
    const [sightseeingopen, steSightseeingOpen] = useState();

    const packageD = useLocation();

    const getpackagepoint = async () => {
        await getAllData('/master/vendor/v1/vengetpackagelocationcity').then((packagepointlist) => {
            setFlightfrom(packagepointlist.data?.filter(IsAct => IsAct.IsActive === true));
            setFlightTo(packagepointlist.data?.filter(IsAct => IsAct.IsActive === true));
            setAllFilghtFrom(packagepointlist.data);
            setAllFilghtTo(packagepointlist.data);
        });
    }

    const gethotelnamelist = async () => {
        const roomtype = await getAllData('/master/vendor/v1/vengetpackagehotel');
        setAllHotel(roomtype.data);
        setHotelNameList(roomtype.data?.filter(IsAct => IsAct.IsActive === true));
    }
    const getsightseeinglistdata = async () => {
        const sightseeingD = await getAllData('/master/vendor/v1/vengetpackagesightseeing');
        setSightSeeingList(sightseeingD.data?.filter(IsAct => IsAct.IsActive === true));
        setAllSightseeing(sightseeingD.data)
    }
    const sightseeinglistdata = sightseeinglist?.map((value) => ({
        label: value.SightseeingName,
        value: value.SightseeingId
    }));
    const hotelnamelistdata = hotelnamelist?.map((value) => ({
        label: value.Name,
        value: value.HotelId
    }));

    const flightfrom = fromflight?.map((value) => ({
        label: value.City,
        value: value.LocationId
    }));
    const flightto = toflight?.map((value) => ({
        label: value.City,
        value: value.LocationId
    }));


    function formatTime(date) {

        var d = new Date(date),

            hour = d.getHours(),
            minu = d.getMinutes();


        if (minu.toString().length < 2)
            minu = '0' + minu;
        if (hour.toString().length < 2)
            hour = '0' + hour;

        if (date === null) {
            minu = '';
            hour = '';
        }

        return [hour, minu].join(':');
    }

    const getflightnodata = async (id) => {
        const flightnolistD = await getAllData('/master/vendor/v1/vengetpackageairlinedetail?airlineId=' + id + '&dstid=' + flightfromid + '&arvid=' + flighttoid);
        const flightlistdata = flightnolistD.data?.map((value) => ({
            value: value.FlightNo,
            label: value.FlightNo
        })) || []
        setFlightData(flightnolistD.data);
        setFlightNoList(flightlistdata);
    }
    const getAirlineData = async (fromid, toid) => {
        const airlinelistD = await getAllData('/master/vendor/v1/vengetpackageairlinebydstarv?dstid=' + fromid + '&arvid=' + toid);
        const airlinedatalist = airlinelistD.data?.map((value) => ({
            value: value.AirlineId,
            label: value.AirlineName
        })) || [];
        //resetflightselection();
        setAirlineList(airlinedatalist);
    }

    const handleFlightFromchange = (e) => {
        // e.preventDefault();
        setFlightFromId(e.value);
        if (flighttoid > 0) {
            resetflightselection();
            //clearFlightDetail();
            getAirlineData(e.value, flighttoid);
        }
    }
    const handleFlightToChange = (e) => {
        setFlightToId(e.value);
        if (flightfromid > 0) {
            resetflightselection();
            //clearFlightDetail();
            getAirlineData(flightfromid, e.value);
        }
    }

    const resetflightselection = () => {

        setAirlineId(0);
        setFlightData([]);
        setFlightNoList([]);
        setFlightNo('');
        formvalue.DepartureTime = null
        formvalue.AirrivalTime = null
        formvalue.DepartureTimeD = ''
        formvalue.ArrivalTimeD = ''

        setArrivalTime("--:--")
        setDepartureTime("--:--")
        setHoursTime("--:--");
        formvalue.Duration = ''
        formvalue.FlightPrice = ''
        setSupplierList([]);
        setAirlineClassList([]);
        setAirlineList([]);

    }
    const resetflightairlineselection = () => {

        // setAirlineId(0);
        // setFlightData([]);
        // setFlightNoList([]);
        setFlightNo('');
        formvalue.DepartureTime = null
        formvalue.AirrivalTime = null
        formvalue.DepartureTimeD = ''
        formvalue.ArrivalTimeD = ''

        setArrivalTime("--:--")
        setDepartureTime("--:--")
        setHoursTime("--:--");

        // {formvalue.Duration = ''}
        formvalue.FlightPrice = ''
        setSupplierList([]);
        setAirlineClassList([]);
        // setAirlineList([]);

    }
    const handleAirlineChange = (e) => {
        if (airlineid !== e.value) {
            resetflightairlineselection();
        }
        setAirlineId(e.value);
        getflightnodata(e.value);
    }

    const handleFlightNoChange = (e) => {
        setFlightNo(e.value);
        flightdata?.filter(obj => obj.FlightNo === e.value).map((value) => {
            formvalue.DepartureTime = formatTime(value.DepartureTime)
            formvalue.AirrivalTime = formatTime(value.AirrivalTime)
            formvalue.DepartureTimeD = formatTime(value.DepartureTime)
            formvalue.ArrivalTimeD = formatTime(value.AirrivalTime)
            setArrivalTime(formatTime(value.AirrivalTime))
            setDepartureTime(formatTime(value.DepartureTime))
            formvalue.Duration = value.Duration

            const supplierlist = flightdata?.filter(obj => obj.FlightNo === e.value).map((value) => ({
                value: value.SupplierId,
                label: value.SupplierName
            })) || [];

            const classlist = flightdata?.filter(obj => obj.FlightNo === e.value).map((value) => ({
                value: value.AirlineClassId,
                label: value.AirlineClassName
            })) || [];

            setSupplierList(supplierlist);
            setAirlineClassList(classlist);
        })
    }
    const handleclasschange = (e) => {
        setClassId(e.value)
    }
    const handlesupplierchange = (e) => {
        setSupplierId(e.value);
    }

    const handleitinerary = (e) => {
        const error = {}
        if (formvalue.Day.trim() !== '' && formvalue.DaySchedule.trim() !== '') {

            // if (formvalue.Day.trim().length > 500 || formvalue.DaySchedule.trim().length > 5000) {
            if (formvalue.Day.trim().length > 500) {
                if (formvalue.Day.length > 500) {
                    error.ItineraryDayError = 'Maximum character limit is 500 characters.';
                    setFormError(error);
                }
                // alert(formvalue.DaySchedule.length)
                // if (formvalue.DaySchedule.trim().length > 5000) {
                //     error.ItineraryDayScheduleError = 'Maximum character limit is 5000 characters.';
                //     setFormError(error);
                // }
                dispatch(errorActions.start())
                return;
                // setIsSubmit(false);
            }
            else {
                // setDataItineraryCounter(dataitinerarycounter + 1);
                AddItineraryData();
            }

        }
        // if (formvalue.Day !== '' && formvalue.DaySchedule !== '')
        // {

        // }
        else {
            error.ItineraryError = 'Please enter all mandatory fields';
            dispatch(errorActions.start())
            setFormError(error);
            return;
        }
        
    }
    const validation =() =>{
        const error = {}

        error.ItineraryError = 'Please enter itinerary detail';
        let incllopased = document?.getElementById("ItineraryError")
        if (incllopased.ariaExpanded === "false")
            document.querySelector("#ItineraryError").click();
        setFormError(error);
        dispatch(errorActions.start())        
        
    }
    const AddItineraryData = () => {
        if (packageD.state.PackageId === 0) {

            if (itinerarybtntext === 'Add') {
                // let Day = formvalue.Day
                // let DayScheduleD = formvalue.DaySchedule
                // let AirrivalTime = formvalue.ArrivalTimeD
                // let DepartureTime = formvalue.DepartureTimeD
                // let HotelPrice = formvalue.HotelPrice
                // let SightseeingId = formvalue.SightseeingId
                // let FlightPrice = formvalue.FlightPrice
                // let fromid = flightfromid;
                // let toid = flighttoid;
                // let supid = supplierid;
                // let clsid = classid;
                // let vehitypeid = vehicaltypeid;
                // let price = vehicalprice;

                setDataItineraryCounter(dataitinerarycounter+1)
                setDataItinerary(current => [...current, {
                    SeqNo: dataitinerarycounter+1,
                    Day: formvalue.Day,
                    DaySchedule: formvalue.DaySchedule,
                    FlightFromLocationId: flightfromid,
                    FlightToLocationId: flighttoid,
                    FlightNo: flightno ? flightno : "",
                    ArrivalTime: (formvalue.ArrivalTimeD !== null && formvalue.ArrivalTimeD !== '' && formvalue.ArrivalTimeD !== '--:--') ? "2022-11-10T" + formvalue.ArrivalTimeD + ":00" : null,
                    ArrivalTimeD: (formvalue.ArrivalTimeD !== null && formvalue.ArrivalTimeD !== '' && formvalue.ArrivalTimeD !== '--:--') ? "2022-11-10T" + formvalue.ArrivalTimeD + ":00" : null,
                    DepartureTime: (formvalue.DepartureTimeD !== null && formvalue.DepartureTimeD !== '' && formvalue.DepartureTimeD !== '--:--') ? "2022-11-10T" + formvalue.DepartureTimeD + ":00" : null,
                    DepartureTimeD: (formvalue.DepartureTimeD !== null && formvalue.DepartureTimeD !== '' && formvalue.DepartureTimeD !== '--:--') ? "2022-11-10T" + formvalue.DepartureTimeD + ":00" : null,
                    ClassId: classid,
                    SupplierId: supplierid,
                    FlightPrice: Number(formvalue.FlightPrice),
                    HotelId: hotelnameid,
                    RoomTypeId: hotelroomtypeid,
                    NoOfPax: noofpaxid,

                    HotelPrice: formvalue.HotelPrice ? formvalue.HotelPrice : 0,
                    SightseeingId: formvalue.SightseeingId,
                    VehicleId: vehicaltypeid,
                    SightseeingPrice: vehicalprice === "" ? 0 : vehicalprice,
                    AirlineId: Number(airlineid)
                }]);

                handleItineraryDetailAdd(current => [...current, {
                    SeqNo: dataitinerarycounter+1,
                    Day: formvalue.Day,
                    DaySchedule: formvalue.DaySchedule,
                    FlightFromLocationId: flightfromid,
                    FlightToLocationId: flighttoid,
                    FlightNo: flightno ? flightno : "",
                    ArrivalTime: (formvalue.ArrivalTimeD !== null && formvalue.ArrivalTimeD !== '' && formvalue.ArrivalTimeD !== '--:--') ? "2022-11-10T" + formvalue.ArrivalTimeD + ":00" : null,
                    ArrivalTimeD: (formvalue.ArrivalTimeD !== null && formvalue.ArrivalTimeD !== '' && formvalue.ArrivalTimeD !== '--:--') ? "2022-11-10T" + formvalue.ArrivalTimeD + ":00" : null,
                    DepartureTime: (formvalue.DepartureTimeD !== null && formvalue.DepartureTimeD !== '' && formvalue.DepartureTimeD !== '--:--') ? "2022-11-10T" + formvalue.DepartureTimeD + ":00" : null,
                    DepartureTimeD: (formvalue.DepartureTimeD !== null && formvalue.DepartureTimeD !== '' && formvalue.DepartureTimeD !== '--:--') ? "2022-11-10T" + formvalue.DepartureTimeD + ":00" : null,
                    ClassId: classid,
                    SupplierId: supplierid,
                    FlightPrice: Number(formvalue.FlightPrice),
                    HotelId: hotelnameid,
                    RoomTypeId: hotelroomtypeid,
                    NoOfPax: noofpaxid,

                    HotelPrice: formvalue.HotelPrice ? formvalue.HotelPrice : 0,
                    SightseeingId: formvalue.SightseeingId,
                    VehicleId: vehicaltypeid,
                    SightseeingPrice: vehicalprice === "" ? 0 : vehicalprice,
                    AirlineId: Number(airlineid)
                }])
                dispatch(errorActions.itineraryincrement());
            }
            else {
                if (itineraryid > 0) {
                    handleeditofitinerary(itineraryid);
                    handleItineraryDisable(false);
                    dispatch(errorActions.resetallstatusflag());    
                }
            }
        }
        else {
            // let dinc = dataitinerary?.filter(obj => obj?.SeqNo > 0);

            // let len = dinc.length;
            if (itineraryid === 0 && formvalue.Day.trim() !== '' && formvalue.DaySchedule.trim() !== '') {
                setDataItineraryCounter(dataitinerarycounter+1)
                setDataItinerary([...dataitinerary, {
                    SeqNo: dataitinerarycounter+1,
                    Day: formvalue.Day,
                    DaySchedule: formvalue.DaySchedule,
                    FlightFromLocationId: flightfromid,
                    FlightToLocationId: flighttoid,
                    FlightNo: flightno ? flightno : "",
                    ArrivalTime: (formvalue.ArrivalTimeD !== null && formvalue.ArrivalTimeD !== '' && formvalue.ArrivalTimeD !== '--:--') ? "2022-11-10T" + formvalue.ArrivalTimeD + ":00" : null,
                    DepartureTime: (formvalue.DepartureTimeD !== null && formvalue.DepartureTimeD !== '' && formvalue.DepartureTimeD !== '--:--') ? "2022-11-10T" + formvalue.DepartureTimeD + ":00" : null,
                    ArrivalTimeD: (formvalue.ArrivalTimeD !== null && formvalue.ArrivalTimeD !== '' && formvalue.ArrivalTimeD !== '--:--') ? "2022-11-10T" + formvalue.ArrivalTimeD + ":00" : null,
                    DepartureTimeD: (formvalue.DepartureTimeD !== null && formvalue.DepartureTimeD !== '' && formvalue.DepartureTimeD !== '--:--') ? "2022-11-10T" + formvalue.DepartureTimeD + ":00" : null,
                    ClassId: classid,
                    SupplierId: supplierid,
                    FlightPrice: Number(formvalue.FlightPrice),
                    HotelId: hotelnameid,
                    RoomTypeId: hotelroomtypeid,
                    NoOfPax: noofpaxid,

                    HotelPrice: formvalue.HotelPrice ? formvalue.HotelPrice : 0,
                    SightseeingId: formvalue.SightseeingId,
                    VehicleId: vehicaltypeid,
                    SightseeingPrice: vehicalprice === "" ? 0 : vehicalprice,
                    AirlineId: Number(airlineid)
                }])
                handleItineraryDetailAdd([...dataitinerary, {
                    SeqNo: dataitinerarycounter+1,
                    Day: formvalue.Day,
                    DaySchedule: formvalue.DaySchedule,
                    FlightFromLocationId: flightfromid,
                    FlightToLocationId: flighttoid,
                    FlightNo: flightno ? flightno : "",
                    ArrivalTime: (formvalue.ArrivalTimeD !== null && formvalue.ArrivalTimeD !== '' && formvalue.ArrivalTimeD !== '--:--') ? "2022-11-10T" + formvalue.ArrivalTimeD + ":00" : null,
                    ArrivalTimeD: (formvalue.ArrivalTimeD !== null && formvalue.ArrivalTimeD !== '' && formvalue.ArrivalTimeD !== '--:--') ? "2022-11-10T" + formvalue.ArrivalTimeD + ":00" : null,
                    DepartureTime: (formvalue.DepartureTimeD !== null && formvalue.DepartureTimeD !== '' && formvalue.DepartureTimeD !== '--:--') ? "2022-11-10T" + formvalue.DepartureTimeD + ":00" : null,
                    DepartureTimeD: (formvalue.DepartureTimeD !== null && formvalue.DepartureTimeD !== '' && formvalue.DepartureTimeD !== '--:--') ? "2022-11-10T" + formvalue.DepartureTimeD + ":00" : null,
                    ClassId: classid,
                    SupplierId: supplierid,
                    FlightPrice: Number(formvalue.FlightPrice),
                    HotelId: hotelnameid,
                    RoomTypeId: hotelroomtypeid,
                    NoOfPax: noofpaxid,

                    HotelPrice: formvalue.HotelPrice ? formvalue.HotelPrice : 0,
                    SightseeingId: formvalue.SightseeingId,
                    VehicleId: vehicaltypeid,
                    SightseeingPrice: vehicalprice === "" ? 0 : vehicalprice,
                    AirlineId: Number(airlineid)
                }])
                handleItineraryDisable(false);
                dispatch(errorActions.itineraryincrement());
            }
            else if (itineraryid > 0 && formvalue.Day.trim() !== '' && formvalue.DaySchedule.trim() !== '') {

                handleeditofitinerary(itineraryid);
                handleItineraryDisable(false);
                dispatch(errorActions.resetallstatusflag());
            }
        }
        
    }
   
    const handleeditofitinerary = (id) => {

        const newList = dataitinerary?.map((item) => {

            if (item.SeqNo === id) {
                const updatedItem = {
                    ...item,
                    Day: formvalue.Day,
                    DaySchedule: formvalue.DaySchedule,
                    FlightFromLocationId: flightfromid,
                    FlightToLocationId: flighttoid,
                    FlightNo: flightno ? flightno : "",
                    ArrivalTime: (formvalue.ArrivalTimeD !== null && formvalue.ArrivalTimeD !== '' && formvalue.ArrivalTimeD !== '--:--') ? "2022-11-10T" + formvalue.ArrivalTimeD + ":00" : null,
                    DepartureTime: (formvalue.DepartureTimeD !== null && formvalue.DepartureTimeD !== '' && formvalue.DepartureTimeD !== '--:--') ? "2022-11-10T" + formvalue.DepartureTimeD + ":00" : null,
                    ArrivalTimeD: (formvalue.ArrivalTimeD !== null && formvalue.ArrivalTimeD !== '' && formvalue.ArrivalTimeD !== '--:--') ? "2022-11-10T" + formvalue.ArrivalTimeD + ":00" : "",
                    DepartureTimeD: (formvalue.DepartureTimeD !== null && formvalue.DepartureTimeD !== '' && formvalue.DepartureTimeD !== '--:--') ? "2022-11-10T" + formvalue.DepartureTimeD + ":00" : "",
                    ClassId: classid,
                    SupplierId: supplierid,
                    FlightPrice: Number(formvalue.FlightPrice),
                    HotelId: hotelnameid,
                    RoomTypeId: hotelroomtypeid,
                    NoOfPax: noofpaxid,

                    HotelPrice: Number(formvalue.HotelPrice),
                    SightseeingId: formvalue.SightseeingId,
                    VehicleId: vehicaltypeid,
                    SightseeingPrice: vehicalprice === "" ? 0 : vehicalprice,
                    AirlineId: Number(airlineid)
                    //IsChecked: !item.IsChecked,
                };

                return updatedItem;
            }

            return item;
        });
        setDataItinerary(newList);
        handleItineraryDetailAdd(newList)
    }

    const removeitinerary = (id, e) => {

        // const olddata = dataitinerary?.filter((result) => result.SeqNo !== id);
        // setDataItinerary(olddata);
        setDataItinerary((oldState) => oldState?.filter((item) => item.SeqNo !== id));
        handleItineraryDetailAdd((oldState) => oldState?.filter((item) => item.SeqNo !== id));
        dispatch(errorActions.itinerarydecrement());
        dispatch(errorActions.resetallstatusflag());
        handleItineraryDisable(false);
        // setEditDisabled(false);

    }

    const handleclearItinerary = () => {
        ClearItineraryDetail();
    }

    const ClearItineraryDetail = () => {
        setItineraryBtnText('Add');
        setItineraryId(0);
        setHotelName('Hotel Name');
        setSightseeingName('Sightseeing Name');
        setHotelRating(0);

        formvalue.Rating = 0
        formvalue.Day = ''
        formvalue.DaySchedule = ''
        formvalue.AirrivalTime = null
        formvalue.DepartureTime = null
        formvalue.ArrivalTimeD = ''
        formvalue.DepartureTimeD = ''
        formvalue.HotelPrice = ''
        formvalue.SightseeingId = 0
        formvalue.FlightPrice = ''
        setArrivalTime("--:--");
        setDepartureTime("--:--");
        setHoursTime("--:--");
        setAirlineList([]);
        setFlightNoList([]);
        setAirlineClassList([]);
        setSupplierList([]);
        setRoomTypeList([]);
        setRoomPriceList([]);
        setVehicalListData([]);

        setFlightFromId(0);
        setFlightToId(0);
        setClassId(0);
        setSupplierId(0);
        setVehicalTypeId(0);
        setVehicalPrice(0);


        formvalue.Duration = ''
        formvalue.PickUpPoint = ''
        formvalue.DropPoint = ''
        formvalue.ItineraryDetails = ''
        formvalue.HotelPrice = ''
        formvalue.Hours = ''
        setFlightNo('');
        setAirlineId(0);
        setHotelNameId(0);
        setHotelRoomTypeId(0);
        setSightSeeingId(0);
        setNoOfPaxId(0);
        // setHotelRoomPrice(0);
        setFormError('');
        setFromFlightDisable(false)
        setToFlightDisable(false)
        setHotelDisable(false)
        setSightseeingDisable(false)
        const richtext = document.querySelector('.dayschedule .jodit-wysiwyg');
        if (richtext !== null) {
            document.querySelector('.dayschedule .jodit-wysiwyg').innerHTML = null;
        }
    }

    const handleSightseenhandlechangeretrive = async (id, fromid, toid) => {
        allsightseeing?.filter(obj => obj.SightseeingId === id).map((value) => {

            formvalue.SightseeingId = id
            formvalue.PickUpPoint = value.PickUpPoint
            formvalue.DropPoint = value.DropPoint
            formvalue.Hours = value.Hours
            formvalue.ItineraryDetails = value.ItineraryDetails
            setHoursTime(formatTime(value.Hours))
            // if (ele.Hours === null) {
            //     setHoursTime("--:--");
            // }
            // else {
            //     { formvalue.Hours = formatTime(ele.Hours) }
            //     setArrivalTime(formatTime(ele.ArrivalTime))
            // }
        })
        const SightseeingId = formvalue.SightseeingId;
        const vehicallist = await postUsersData('/master/vendor/v1/vengetpackagesightseeingdetail', { SightseeingId });
        const vehichallistD = vehicallist.data?.map((value) => ({
            label: value.VehicleType,
            value: value.VehicleId
        }));
        setVehicalListData(vehichallistD);
        setVehicalTypeList(vehicallist.data);
        setSightSeeingId(id);
    }
    const getflightnodataforedit = async (id, fromid, toid, flightno) => {
        const flightnolistD = await getAllData('/master/vendor/v1/vengetpackageairlinedetail?airlineId=' + id + '&dstid=' + fromid + '&arvid=' + toid);
        const flightlistdata = flightnolistD.data?.map((value) => ({
            value: value.FlightNo,
            label: value.FlightNo
        })) || []
        setFlightNoList(flightlistdata);
        setFlightData(flightnolistD.data)

        flightnolistD.data?.filter(obj => obj.FlightNo === flightno).map((value) => {
            formvalue.DepartureTime = formatTime(value.DepartureTime)
            formvalue.AirrivalTime = formatTime(value.AirrivalTime)
            formvalue.Duration = value.Duration

        });

        const supplierlist = flightnolistD.data?.filter(obj => obj.FlightNo === flightno).map((value) => ({
            value: value.SupplierId,
            label: value.SupplierName
        })) || [];

        const classlist = flightnolistD.data?.filter(obj => obj.FlightNo === flightno).map((value) => ({
            value: value.AirlineClassId,
            label: value.AirlineClassName
        })) || [];

        setSupplierList(supplierlist);
        setAirlineClassList(classlist);
    }
    useEffect(() => {
        getpackagepoint();
        gethotelnamelist();
        getsightseeinglistdata();
        getflightnodata();
        // getAirlineData();
    },[]);
    const handlehotelchangeedit = async (id, roomid) => {
        const HotelId = id
        const hoteldetailbyid = await postUsersData('/master/vendor/v1/vengetpackagehoteldetail', { HotelId });
        const roomdetail = hoteldetailbyid.data.roomTypeDetail?.map((value) => ({
            label: value.RoomTypeName,
            value: value.RoomTypeId
        }));
        setRoomTypeList(roomdetail);


        const roompricedetail = hoteldetailbyid.data.priceDetail?.filter(obj => obj.RoomTypeId === roomid).map((value) => ({
            label: value.NoOfPax,
            value: value.NoOfPax
        }));
        setRoomPriceList(roompricedetail);
        setHotelRoomTypeId(roomid);
    }
    const handleEditItinerary = (id, e) => {
        ClearItineraryDetail();
        setItineraryId(id);
        setItineraryBtnText('Update');
        handleItineraryDisable(false);
        dataitinerary?.filter(obj => obj.SeqNo === id).map((ele, index) => {
            formvalue.Day = ele.Day
            formvalue.DaySchedule = ele.DaySchedule
            setFlightFromId(ele.FlightFromLocationId);
            setFlightToId(ele.FlightToLocationId);
            if (ele.FlightFromLocationId !== 0 && ele.FlightToLocationId !== 0) {
                getAirlineData(ele.FlightFromLocationId, ele.FlightToLocationId);
                setAirlineId(ele.AirlineId);
                getflightnodataforedit(ele.AirlineId, ele.FlightFromLocationId, ele.FlightToLocationId, ele.FlightNo)
                setFlightNo(ele.FlightNo);
                setAirlineId(ele.AirlineId);

                setClassId(ele.ClassId);
                setSupplierId(ele.SupplierId);
                formvalue.FlightPrice = ele.FlightPrice
                let allfromflightlist = allflightfrom?.filter(IsAct => IsAct.IsActive === true);
                setFlightfrom(allflightfrom?.filter(IsAct => IsAct.IsActive === true));
                setFromFlightDisable(false);
                if (!allfromflightlist?.find(Isfromlist => Isfromlist.LocationId === ele.FlightFromLocationId)) {
                    setFromFlightDisable(true)
                    setFlightfrom((prvfromData) => prvfromData?.length > 0 ? [...prvfromData, allflightfrom?.find(isFrom => isFrom.LocationId === ele.FlightFromLocationId)] : [])

                }
                let allytoflightlist = allflightto?.filter(IsAct => IsAct.IsActive === true);
                setFlightTo(allflightto?.filter(IsAct => IsAct.IsActive === true));
                setToFlightDisable(false);
                if (!allytoflightlist?.find(Istolist => Istolist.LocationId === ele.FlightToLocationId)) {
                    setToFlightDisable(true)
                    setFlightTo((prvtoData) => prvtoData?.length > 0 ? [...prvtoData, allflightto?.find(isTo => isTo.LocationId === ele.FlightToLocationId)] : [])

                }
            }
            if (ele.HotelId > 0) {
                allhotel?.filter(obj => obj.HotelId === ele.HotelId).map(value => {
                    setHotelName(value.Name);
                    setHotelRating(value.Rating);
                });
                setHotelNameId(ele.HotelId);

                let allhotellist = allhotel?.filter(IsAct => IsAct.IsActive === true);
                setHotelNameList(allhotel?.filter(IsAct => IsAct.IsActive === true));
                setHotelDisable(false)
                if (!allhotellist?.find(Ishotellist => Ishotellist.HotelId === ele.HotelId)) {
                    setHotelDisable(true)
                    setHotelNameList((prvhotelData) => prvhotelData?.length > 0 ? [...prvhotelData, allhotel?.find(isHotel => isHotel.HotelId === ele.HotelId)] : [])
                }

                handlehotelchangeedit(ele.HotelId, ele.RoomTypeId);
                //handleroomtypechangeretrive(ele.HotelId,ele.RoomTypeId);
                formvalue.HotelPrice = ele.HotelPrice



                setNoOfPaxId(ele.NoOfPax);

                //setHotelNameId(ele.HotelId);   
            }
            if (ele.HotelId === 0) {
                setHotelDisable(false);
            }
            formvalue.AirrivalTime = ele.ArrivalTime
            formvalue.DepartureTime = ele.DepartureTime


            if (ele.ArrivalTime === null || ele.DepartureTime === null) {
                setDepartureTime("--:--");
                setArrivalTime("--:--");
            }
            else {
                formvalue.ArrivalTimeD = formatTime(ele.ArrivalTime)
                formvalue.DepartureTimeD = formatTime(ele.DepartureTime)
                setDepartureTime(formatTime(ele.DepartureTime))
                setArrivalTime(formatTime(ele.ArrivalTime))
            }

            formvalue.HotelPrice = ele.HotelPrice
            formvalue.SightseeingId = ele.SightseeingId
            if (ele.SightseeingId > 0) {
                //setVehicalTypeId(ele.SightseeingId);
                handleSightseenhandlechangeretrive(ele.SightseeingId);
                allsightseeing?.filter(obj => obj.SightseeingId === ele.SightseeingId).map(value => {
                    setSightseeingName(value.SightseeingName);
                });

                setVehicalTypeId(ele.VehicleId);

                formvalue.VehicalPrice = ele.SightseeingPrice

                let allsightseeinglist = allsightseeing?.filter(IsAct => IsAct.IsActive === true);
                setSightSeeingList(allsightseeing?.filter(IsAct => IsAct.IsActive === true));
                setSightseeingDisable(false)
                if (!allsightseeinglist?.find(Issightlist => Issightlist.SightseeingId === ele.SightseeingId)) {
                    setSightseeingDisable(true)
                    setSightSeeingList((prvsightData) => prvsightData?.length > 0 ? [...prvsightData, allsightseeing?.find(isSight => isSight.SightseeingId === ele.SightseeingId)] : [])
                }
                setVehicalPrice(ele.SightseeingPrice);
            }
        })
    }
    const handleDayScheduleChange = (e) => {
        const str = e.replace('<p><br></p>', '')
        formvalue.DaySchedule = str
    // handleItineraryDisable(false);
    }

    const dayscheduleconfig = useMemo(() => ({
        readonly: false, // all options from https://xdsoft.net/jodit/doc/,
        height: '100%',
        width: '100%',
        statusbar: false,
        disablePlugins: ["source"],
        removeButtons: ["about", "image", "link", "select", "video"],
        height: 'auto',
        maxHeight: richboxheight === true ? 200 : 500,
        toolbarAdaptive: false,
        //autofocus: true,
        defaultMode: "1",
        disabled: editdisable ? true : false
    }), [editdisable]);


    const handleFlight = () => {
        setFlightOpen(!flightopen)
        setFlightbuttontext(!flightbuttontext)
    }
    const handleHotel = () => {
        setHotelOpen(!hotelopen)
        setHotelbuttontext(!hotelbuttontext)

    }
    const handleSightseeing = () => {
        steSightseeingOpen(!sightseeingopen)
        setSightseeingbuttontext(!sightseeingbuttontext)
    }

    const handleonchangeofTime = (fieldname, times) => {
        if (fieldname === 'ArrivalTimeD') {
            // { formvalue.ArrivalTime = "1900-01-01T" + times + ":00"}
            formvalue.ArrivalTimeD = times
            setArrivalTime(times)
            dispatch(errorActions.resetallstatusflag())
            // if ('INPUT' === document.activeElement.tagName) {
            //     handleItineraryDisable(false);
            // }
          
        }
        else if (fieldname === 'DepartureTimeD') {
            // { formvalue.AirrivalTime = formvalue.ArrivalDate + "T" + times + ":00" }
            // { formvalue.AirrTime = times }
            // { formvalue.DepartureTime = "1900-01-01T" + times + ":00"}
            formvalue.DepartureTimeD = times
            setDepartureTime(times)
            dispatch(errorActions.resetallstatusflag())
            // if ('INPUT' === document.activeElement.tagName) {
            //     handleItineraryDisable(false);
            // }
        }
        else if (fieldname === 'Hours') {
            // { formvalue.AirrivalTime = formvalue.ArrivalDate + "T" + times + ":00" }
            // { formvalue.AirrTime = times }
            formvalue.Hours = times
            setHoursTime(times)
            dispatch(errorActions.resetallstatusflag())
            // if ('INPUT' === document.activeElement.tagName) {
            //     handleItineraryDisable(false);
            // }
        }

    }
    const handlehotelchange = async (id) => {
        const HotelId = id
        const hoteldetailbyid = await postUsersData('/master/vendor/v1/vengetpackagehoteldetail', { HotelId });
        const roomdetail = hoteldetailbyid.data.roomTypeDetail?.map((value) => ({
            label: value.RoomTypeName,
            value: value.RoomTypeId
        }));
        setRoomTypeList(roomdetail);

    }
    const handlehotelnamechange = (e) => {
        hotelnamelist?.filter(obj => obj.HotelId === e.value).map(value => {
            setHotelName(value.Name);
            setHotelRating(value.Rating);
        });
        setHotelNameId(e.value);
        handlehotelchange(e.value);

        formvalue.HotelPrice = ''
        setNoOfPaxId(0);
        // setHotelRoomPrice('');
        setRoomPriceList([]);
        setHotelRoomTypeId(0);
    }
    const handleRoomTypeChange = async (e) => {
        const HotelId = hotelnameid;
        const hoteldetailbyid = await postUsersData('/master/vendor/v1/vengetpackagehoteldetail', { HotelId });
        const roompricedetail = hoteldetailbyid.data.priceDetail?.filter(obj => obj.RoomTypeId === e.value).map((value) => ({
            label: value.NoOfPax,
            value: value.NoOfPax
        })) || [];

        if (hotelroomtypeid !== e.value) {
            setNoOfPaxId(0);
            formvalue.HotelPrice = ''
        }
        // setHotelRoomPrice('');
        setRoomPriceList(roompricedetail);
        setHotelRoomTypeId(e.value);
    }
    const handleNoofpaxchange = async (e) => {
        const HotelId = hotelnameid
        const hoteldetailbyid = await postUsersData('/master/vendor/v1/vengetpackagehoteldetail', { HotelId });
        const roompricedetail = hoteldetailbyid.data.priceDetail?.filter(obj => obj.RoomTypeId === hotelroomtypeid && obj.NoOfPax === e.value).map((value) => {
            return value.Price
        });
        formvalue.HotelPrice = roompricedetail[0]
        setNoOfPaxId(e.value);
        // setHotelRoomPrice(roompricedetail[0]);
    }
    const handlesightseeingchange = async (e) => {
        sightseeinglist?.filter(obj => obj.SightseeingId === e.value).map((value) => {
            formvalue.SightseeingId = e.value
            formvalue.PickUpPoint = value.PickUpPoint
            formvalue.DropPoint = value.DropPoint
            formvalue.Hours = value.Hours
            formvalue.ItineraryDetails = value.ItineraryDetails
            setHoursTime(formatTime(value.Hours))
            setSightseeingName(value.SightseeingName);
        })
        const SightseeingId = formvalue.SightseeingId;
        const vehicallist = await postUsersData('/master/vendor/v1/vengetpackagesightseeingdetail', { SightseeingId });
        const vehichallistD = vehicallist.data?.map((value) => ({
            label: value.VehicleType,
            value: value.VehicleId
        })) || [];
        setVehicalTypeId(0);
        formvalue.VehicalPrice = ''
        setVehicalPrice('');
        setVehicalListData(vehichallistD);
        setVehicalTypeList(vehicallist.data);
        setSightSeeingId(e.value);
    }

    const handlevehicaltype = (e) => {
        const pricedata = vehicaltypelist?.filter((obj) => obj.VehicleId === e.value).map((value) => {
            return value.Price
        });
        setVehicalTypeId(e.value);
        formvalue.VehicalPrice = pricedata[0]
        setVehicalPrice(pricedata[0]);
    }

    const onChange = (e) => {
        var result;

        if ([e.target.name][0] === 'FlightPrice') {
            result = isNaN(e.target.value) ? 0 : Number(onlyNumeric(e.target.value))
        }
        else {
            result = e.target.value;
        }

        setFormValue({ ...formvalue, [e.target.name]: result });
       
        dispatch(errorActions.resetallstatusflag())
        // handleItineraryDisable(false);
    }
    useEffect(() => {
        const richtexteboxheight = document.getElementById("layoutSidenav_content").clientWidth
        if (richtexteboxheight > 912) {
            setRichBoxHeight(true)
        }
        else {
            setRichBoxHeight(false)
        }
    }, []);

    useEffect(() => {

        // handlegstcalculation();
        // handleItineraryDisable(false);
        ClearItineraryDetail();

    }, [dataitinerary]);

    useEffect(()=>{
        if(retriveflag)
        {
            setDataItinerary(itinerarydetail);
            // setFlightVisibled(flightvisibleflag);
            setDataItineraryCounter(itinerarydetail?.length)
        }
    },[itinerarydetail,retriveflag])
    useEffect(() => {
        if (!errorstatus) {
            setFormError('')
        }
    }, [errorstatus])
    useEffect(()=>{
        if(itinerarystatus)
        {
            validation()
        }
    },[itinerarystatus])
    
    return (
        <>
            <div class="accordion-item mb-3">
                <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                    <button class="accordion-button collapsed" type="button" id="ItineraryError" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                        Itinerary
                    </button>
                </h2>
                <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                    <div class="accordion-body">
                        <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                            <div class="row">
                                {/* <label for="inputPassword6" class="col-form-label">Itinerary</label> */}
                                <div class="row">
                                    {formerror.ItineraryError && <p className='text-danger text-center'>{formerror.ItineraryError}</p>}
                                    <div className='col-lg-12 col-sm-12 '>

                                        <div className='row'>

                                            <div class="col-lg-2 col-md-12 mb-1">
                                                <label class="col-form-label required">Enter Day</label>
                                            </div>
                                            <div class="col-lg-10 mb-1 col-md-12">
                                                <textarea type="text"
                                                    id="inputPassword6"
                                                    class="form-control"
                                                    name="Day"
                                                    value={formvalue.Day}
                                                    onChange={onChange}
                                                    disabled={editdisable}
                                                    placeholder="Enter Day"
                                                />
                                                {formerror.ItineraryDayError && <p className='text-danger text-start'>{formerror.ItineraryDayError}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-sm-12 '>
                                        <div className='row'>
                                            <div class="col-lg-2 col-md-3 mb-1">
                                                <label class="col-form-label required">Day Schedule</label>
                                            </div>
                                            <div class="col-lg-10 mb-1 col-md-9 dayschedule">
                                                {/* <input type="text" id="inputPassword6" class="form-control"
                                                                    name="DaySchedule"
                                                                    value={formvalue.DaySchedule}
                                                                    onChange={onChange}
                                                                    disabled={disabled ? true : false}
                                                                    placeholder="Day Schedule"
                                                                /> */}
                                                <RichTextBox field="DaySchedule" fieldvalue={formvalue.DaySchedule} tabIndex={1} OnChangeofRichBox={handleDayScheduleChange} textconfig={dayscheduleconfig} />
                                                {formerror.ItineraryDayScheduleError && <p className='text-danger text-start'>{formerror.ItineraryDayScheduleError}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-sm-12 '>
                                        <div className={`${flightvisibleflag ? '' : 'd-none'} row`} >
                                            <div class="col-lg-2 col-md-12 mb-1">
                                                <label class="col-form-label">Add Flight</label>
                                            </div>
                                            <div class="col-lg-1 col-md-2 mb-1">
                                                <label class="col-form-label">From</label>
                                            </div>
                                            <div class="col-lg-3 mb-1 col-md-4">
                                              
                                                <CustomSelection
                                                    datalist={flightfrom}
                                                    fieldid={flightfromid}
                                                    SelectionChange={handleFlightFromchange}
                                                    isdisable={editdisable}
                                                    isactivedisable={fromflightdisable}
                                                    issearchable={true}
                                                />
                                                {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                            </div>
                                            <div class="col-lg-1 col-md-2 mb-1">
                                                <label class="col-form-label">To</label>
                                            </div>
                                            <div class="col-lg-3 mb-1 col-md-4">
                                            
                                                <CustomSelection
                                                    datalist={flightto}
                                                    fieldid={flighttoid}
                                                    SelectionChange={handleFlightToChange}
                                                    isdisable={editdisable}
                                                    isactivedisable={toflightdisable}
                                                    issearchable={true}
                                                />
                                                {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                            </div>
                                            <div class="col-lg-2 mb-1 col-md-12">
                                                <button type="button" class="btn btn-primary mx-1" onClick={handleFlight} id="btnAdd"><i class={`fa fa-plus ${flightbuttontext ? "fa-minus" : "fa-plus"}`} aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-lg-12 col-sm-12 mt-3 mb-2 py-2 form_border ms-2 ${flightopen ? 'd-block' : 'd-none'} ${flightvisibleflag ? '' : 'd-none'}`}>
                                        <div className='row'>
                                            <div class="col-lg-12 col-md-12 mb-1">
                                                <label class="col-form-label ">Flight Details</label>
                                            </div>
                                            <div className='col-lg-12 col-sm-12 '>
                                                <div className='row'>
                                                    <div class="col-lg-1 col-md-2 mb-1">
                                                        <label class="col-form-label ">From</label>
                                                    </div>
                                                    <div class="col-lg-3 mb-1 col-md-3">
                                                        <Select
                                                            className="dropdown select_dropdown"
                                                            options={flightfrom}
                                                            value={flightfrom?.filter(function (flightfrom) {
                                                                return flightfrom.value === flightfromid;
                                                            })}
                                                            // onChange={handlesupplierchange} // assign onChange function
                                                            isDisabled
                                                            isSearchable
                                                        />
                                                        {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                    </div>
                                                    <div class="col-lg-1 col-md-2 mb-1">
                                                        <label class="col-form-label ">To</label>
                                                    </div>
                                                    <div class="col-lg-3 mb-1 col-md-3">
                                                        <Select
                                                            className="dropdown select_dropdown"
                                                            options={flightto}
                                                            value={flightto?.filter(function (flightto) {
                                                                return flightto.value === flighttoid;
                                                            })}// set list of the data
                                                            // onChange={handlesupplierchange} // assign onChange function
                                                            isDisabled
                                                            isSearchable
                                                        />
                                                        {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                    </div>
                                                    <div class="col-lg-1 col-md-3 mb-1">
                                                        <label class="col-form-label ">Duration</label>
                                                    </div>
                                                    <div class="col-lg-3 mb-1 col-md-6">
                                                        <input type="text" id="inputPassword6" class="form-control"
                                                            name="Duration"
                                                            value={formvalue.Duration}
                                                            onChange={onChange}
                                                            disabled
                                                            placeholder="Duration"
                                                        />
                                                        {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-sm-6 '>
                                                <div className='row'>
                                                    <div class="col-lg-4 col-md-12 mb-1">
                                                        <label class="col-form-label "> Select Airline Name</label>
                                                    </div>
                                                    <div class="col-lg-8 mb-1 col-md-12">
                                                        <Select
                                                            className="dropdown select_dropdown"
                                                            options={airlinelist}
                                                            value={airlinelist?.filter(function (airlinelist) {
                                                                return airlinelist.value === airlineid;
                                                            })}// set list of the data
                                                            onChange={handleAirlineChange} // assign onChange function
                                                            isDisabled={editdisable}
                                                            isSearchable
                                                        />
                                                        {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-sm-6 '>
                                                <div className='row'>
                                                    <div class="col-lg-4 col-md-12 mb-1">
                                                        <label class="col-form-label ">Flight No</label>
                                                    </div>
                                                    <div class="col-lg-8 mb-1 col-md-12">
                                                        <Select
                                                            className="dropdown select_dropdown"
                                                            options={flightnolist}
                                                            value={flightnolist?.filter(function (flightnolist) {
                                                                return flightnolist.value === flightno;
                                                            })}// set list of the data
                                                            onChange={handleFlightNoChange} // assign onChange function
                                                            isDisabled={editdisable ? true : false}
                                                            isSearchable
                                                        />
                                                        {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-sm-6 '>
                                                <div className='row'>
                                                    <div class="col-lg-4 col-md-12 mb-1">
                                                        <label class="col-form-label ">Arrival Time</label>
                                                    </div>
                                                    <div class="col-lg-8 mb-1 col-md-12">
                                                        {/* <input type="time" id="inputPassword6" class="form-control"
                                                                            name="ArrivalTimeD"
                                                                            value={formvalue.ArrivalTimeD}
                                                                            onChange={onChange}
                                                                            disabled
                                                                        /> */}
                                                        <CustomTimePicker classid="ArrivalTimeD" filedvalue={arrivalTime} isDisable={true} handleOnChange={(e) => handleonchangeofTime("ArrivalTimeD", e)} />
                                                        {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-sm-6 '>
                                                <div className='row'>
                                                    <div class="col-lg-4 col-md-12 mb-1">
                                                        <label class="col-form-label ">Departure Time</label>
                                                    </div>
                                                    <div class="col-lg-8 mb-1 col-md-12">
                                                      
                                                        <CustomTimePicker classid="DepartureTimeD" filedvalue={departureTime} isDisable={true} handleOnChange={(e) => handleonchangeofTime("DepartureTimeD", e)} />
                                                        {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-sm-6 '>
                                                <div className='row'>
                                                    <div class="col-lg-4 col-md-12 mb-1">
                                                        <label class="col-form-label ">Select Class</label>
                                                    </div>
                                                    <div class="col-lg-8 mb-1 col-md-12">
                                                        <Select
                                                            className="dropdown select_dropdown"
                                                            options={airlineclasslist}
                                                            value={airlineclasslist?.filter(function (airlineclasslist) {
                                                                return airlineclasslist.value === classid;
                                                            })}// set list of the data
                                                            onChange={handleclasschange} // assign onChange function
                                                            isDisabled={editdisable ? true : false}
                                                            isSearchable
                                                        />
                                                        {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-sm-6 '>
                                                <div className='row'>
                                                    <div class="col-lg-4 col-md-12 mb-1">
                                                        <label class="col-form-label ">Select Supplier</label>
                                                    </div>
                                                    <div class="col-lg-8 mb-1 col-md-12">
                                                        <Select
                                                            className="dropdown select_dropdown"
                                                            options={supplierlist}
                                                            value={supplierlist?.filter(function (supplierlist) {
                                                                return supplierlist.value === supplierid;
                                                            })}// set list of the data
                                                            onChange={handlesupplierchange} // assign onChange function
                                                            isDisabled={editdisable ? true : false}
                                                            isSearchable
                                                        />
                                                        {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-sm-6 '>
                                                <div className='row'>
                                                    <div class="col-lg-4 col-md-12 mb-1">
                                                        <label class="col-form-label ">Price</label>
                                                    </div>
                                                    <div class="col-lg-8 mb-1 col-md-12">
                                                        <input type="text" id="inputPassword6" class="form-control"
                                                            name="FlightPrice"
                                                            //disabled
                                                            value={formvalue.FlightPrice}
                                                            onChange={onChange}
                                                            disabled={editdisable ? true : false}
                                                        />
                                                        {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-sm-6 '>
                                        <div className='row'>
                                            <div class="col-lg-2 col-md-12 mb-1">
                                                <label class="col-form-label">Add Hotels<br /> Or Similar Hotels</label>
                                            </div>
                                            <div class="col-lg-4 mb-1 col-md-12">
                                          
                                                <CustomSelection
                                                    datalist={hotelnamelistdata}
                                                    fieldid={hotelnameid}
                                                    SelectionChange={handlehotelnamechange}
                                                    isdisable={editdisable}
                                                    isactivedisable={hoteldisable}
                                                    issearchable={true}
                                                />
                                                {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                            </div>
                                            <div class="col-lg-2 mb-1 col-md-12">
                                                <button type="button" class="btn btn-primary mx-1" onClick={() => handleHotel()} id="btnAdd"><i class={`fa fa-plus ${hotelbuttontext ? "fa-minus" : "fa-plus"}`} aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-lg-12 col-sm-12 mt-3 mb-2 py-2 form_border ms-2 ${hotelopen ? 'd-block' : 'd-none'}`}>
                                        <div className='row'>
                                            <div class="col-lg-12 col-md-12 mb-1">
                                                <label class="col-form-label ">{hotelname}
                                                    <Rating
                                                        className='ms-2'
                                                        size={20}
                                                        allowFraction
                                                        readonly
                                                        initialValue={hotelrating}
                                                    />
                                                </label>
                                            </div>
                                            <div className='col-lg-6 col-sm-6 '>
                                                <div className='row'>
                                                    <div class="col-lg-4 col-md-12 mb-1">
                                                        <label class="col-form-label ">Select Room Type</label>
                                                    </div>
                                                    <div class="col-lg-8 mb-1 col-md-12">
                                                        <Select
                                                            className="dropdown select_dropdown"
                                                            options={roomtypelist}
                                                            value={roomtypelist?.filter(function (roomtypelist) {
                                                                return roomtypelist.value === hotelroomtypeid;
                                                            })}// set list of the data
                                                            onChange={handleRoomTypeChange} // assign onChange function
                                                            isDisabled={editdisable ? true : false}
                                                            isSearchable
                                                        />
                                                        {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-sm-6 '>
                                                <div className='row'>
                                                    <div class="col-lg-4 col-md-12 mb-1">
                                                        <label class="col-form-label ">No Of Pax</label>
                                                    </div>
                                                    <div class="col-lg-8 mb-1 col-md-12">
                                                        <Select
                                                            className="dropdown select_dropdown"
                                                            options={roompricelist}
                                                            value={roompricelist?.filter(function (roompricelist) {
                                                                return roompricelist.value === noofpaxid;
                                                            })}// set list of the data
                                                            onChange={handleNoofpaxchange} // assign onChange function
                                                            isDisabled={editdisable ? true : false}
                                                            isSearchable
                                                        />
                                                        {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-sm-6 '>
                                                <div className='row'>
                                                    <div class="col-lg-4 col-md-12 mb-1">
                                                        <label class="col-form-label ">Price</label>
                                                    </div>
                                                    <div class="col-lg-8 mb-1 col-md-12">
                                                        <input type="text" id="inputPassword6" class="form-control"
                                                            name="RoomPriceD"
                                                            disabled
                                                            value={formvalue.HotelPrice}
                                                            onChange={onChange}
                                                        />
                                                        {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-sm-6 '>
                                        <div className='row'>
                                            <div class="col-lg-2 col-md-12 mb-1">
                                                <label class="col-form-label">Add Sightseeing</label>
                                            </div>
                                            <div class="col-lg-4 mb-1 col-md-12">
                                              
                                                <CustomSelection
                                                    datalist={sightseeinglistdata}
                                                    fieldid={sightseeingid}
                                                    SelectionChange={handlesightseeingchange}
                                                    isdisable={editdisable}
                                                    isactivedisable={sightseeingdisable}
                                                    issearchable={true}
                                                />
                                                {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                            </div>
                                            <div class="col-lg-2 mb-1 col-md-12">
                                                <button type="button" class="btn btn-primary mx-1" onClick={handleSightseeing} id="btnAdd"><i class={`fa fa-plus ${sightseeingbuttontext ? "fa-minus" : "fa-plus"}`} aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-lg-12 col-sm-12 mt-3 mb-2 py-2 form_border ms-2 ${sightseeingopen ? 'd-block' : 'd-none'}`}>
                                        <div className='row'>
                                            <div class="col-lg-12 col-md-12 mb-1">
                                                <label class="col-form-label ">{sightseeingname}</label>
                                            </div>
                                            <div className='col-lg-12 col-sm-12 '>
                                                <div className='row'>
                                                    <div class="col-lg-1 col-md-2 mb-1">
                                                        <label class="col-form-label ">Pick Up Point</label>
                                                    </div>
                                                    <div class="col-lg-3 mb-1 col-md-3">
                                                        <input type="text" id="inputPassword6" class="form-control"
                                                            name="PickupPoint"
                                                            disabled
                                                            value={formvalue.PickUpPoint}
                                                            placeholder='PickUpPoint'
                                                        />
                                                     
                                                        {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                    </div>
                                                    <div class="col-lg-1 col-md-2 mb-1">
                                                        <label class="col-form-label ">Drop Point</label>
                                                    </div>
                                                    <div class="col-lg-3 mb-1 col-md-3">
                                                        <input type="text" id="inputPassword6" class="form-control"
                                                            name="DropPoint"
                                                            disabled
                                                            value={formvalue.DropPoint}
                                                            placeholder="DropPoint"
                                                        />
                                                        {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                    </div>
                                                    <div class="col-lg-1 col-md-3 mb-1">
                                                        <label class="col-form-label ">Duration</label>
                                                    </div>
                                                    <div class="col-lg-3 mb-1 col-md-6">

                                                        <CustomTimePicker classid="Hours" filedvalue={hoursTime} isDisable={true} handleOnChange={(e) => handleonchangeofTime("Hours", e)} />
                                                        {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-12 col-sm-12 '>
                                                <div className='row'>
                                                    <div class="col-lg-2 col-md-12 mb-1">
                                                        <label class="col-form-label">Itinerary Details</label>
                                                    </div>
                                                    <div class="col-lg-10 mb-1 col-md-12">
                                                        <textarea type='text' id="inputPassword6" class="form-control"
                                                            name="ItineraryDetails"
                                                            value={formvalue.ItineraryDetails}
                                                            disabled
                                                        ></textarea>
                                                        {/* {formErrors.DepartureTime && <p className='text-danger text-start'>{formErrors.DepartureTime}</p>} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-12 col-sm-6 '>
                                                <div className='row'>
                                                    <div class="col-lg-2 col-md-12 mb-1">
                                                        <label class="col-form-label "> Select Vehicle</label>
                                                    </div>
                                                    <div class="col-lg-4 mb-1 col-md-12">
                                                        <Select
                                                            className="dropdown select_dropdown"
                                                            options={vehicalistdata}
                                                            value={vehicalistdata?.filter(function (vehicalistdata) {
                                                                return vehicalistdata.value === vehicaltypeid;
                                                            })}// set list of the data
                                                            onChange={handlevehicaltype} // assign onChange function
                                                            isDisabled={editdisable ? true : false}
                                                            isSearchable

                                                        />
                                                        {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-sm-6 '>
                                                <div className='row'>
                                                    <div class="col-lg-4 col-md-12 mb-1">
                                                        <label for="inputPassword7" class="col-form-label ">Price</label>
                                                    </div>
                                                    <div class="col-lg-8 mb-1 col-md-12">
                                                        <input type="text" id="inputPassword7" class="form-control"
                                                            name="VehicalPrice"
                                                            disabled
                                                            //value={formvalue.VehicalPrice}
                                                            value={vehicalprice}
                                                            placeholder="Vehicle Price"
                                                        />
                                                        {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center mt-3">
                                        <button type="button" class="btn btn-primary mx-1" id="btnAdd" disabled={editdisable}
                                            onClick={handleitinerary}
                                        // onClick={onSubmitForm} disabled={editdisabled ? false : true}
                                        >
                                            {itinerarybtntext}
                                        </button>
                                        <button type="button" class="btn btn-primary mx-1" id="btnClear" disabled={editdisable}
                                            onClick={handleclearItinerary}
                                        >Clear</button>
                                    </div>
                                    <div class="my-3 row mx-1">
                                        <div class="col-lg-12">
                                            <div class="mb-3 row mx-1 form_border py-3">
                                                <div class="col-lg-12 mb-1">
                                                    <div class="row">
                                                        <div class="col-lg-12 mb-1 table-scrollable">
                                                            <table id="data" className="table table-hover" >
                                                                <thead>
                                                                    <tr>
                                                                        <th>Day
                                                                        </th>
                                                                        {/* <th>Day Schedule
                                                                                        </th> */}
                                                                        <th className='text-end pe-4'>Edit
                                                                        </th>
                                                                        <th className='text-end pe-4'>Remove
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody id="location-data">
                                                                    {dataitinerary?.map((element, index) => {
                                                                       
                                                                            return (
                                                                                <tr class="ReactTable" key={index}>
                                                                                    <td>{element.Day}</td>
                                                                                    {/* <td>{element.DaySchedule}</td> */}
                                                                                    <td className='text-end'><button type="button" onClick={(e) => { handleEditItinerary(element.SeqNo, e) }} class="btn btn-primary mx-1" id="btnEdit" disabled={editdisable}>Edit</button></td>
                                                                                    <td className='text-end'><button type="button" onClick={(e) => { removeitinerary(element.SeqNo, e) }} class="btn btn-primary mx-1" id="btnAdd" disabled={editdisable}>Remove</button></td>
                                                                                </tr>
                                                                            );
                                                                        
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VendorItinerary