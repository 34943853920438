import React, { useEffect, useState } from 'react'
import CustomSelection from '../../customControl/CustomSelection'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker';
import { TimePicker } from 'antd';
import Switch from 'react-switch';
import { ConfirmationModal } from '../../customControl/ConfirmationModal';
import { getAllData } from '../../services/ApiCallingUserServices';
import { useDispatch } from 'react-redux';
import { ClearLocalStorage, GetLocalStorage } from '../commonClass/Session';
import { loaderActions } from '../../_actions/loader.actions';
import dayjs from 'dayjs';

const BusInventoryMaster = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const mediacollection = useState([{
        DocName: '',
        FilePath: '',
        FileName: '',
        SeqNo: 0
    }]);
    const [datacollection, setDataCollection] = useState(mediacollection);
    const [counter, setCounter] = useState(0);

    const [disabled, setDisabled] = useState(false);
    const [editdisabled, setEditDisabled] = useState(false);
    const [cleardisable, setClearDisable] = useState(false);
    const [backdisable, setBackDisable] = useState(false);

    const [buttonaddeditText, setButtonAddEditText] = useState("Add");

    const [modalShow, setModalShow] = useState(false)
    const [activeId, setActiveId] = useState(false);

    const [formValue, setFormValue] = useState({
        InventoryBusId: 0,
        TravelScopeId: 1,
        TravelTypeId: 1,
        PackageId: 1,
        VPackageId: 0,
        SupplierId: 0,
        SupplierCode: "",
        BusName: "",
        BusTypeId: 0,

        DepartureDate: "",
        DepartureTime: "",
        DepartureLocationId: 0,
        // BoardingPointID: 0,
        // BoardingPointAddress: "",
        DepartureAddress: "",
        ContactNumber: "",

        ArrivalDate: "",
        ArrivalTime: "",
        ArrivalLocationId: 0,
        // DroppingPointID: 0,
        // DroppingPointAddress: "",
        TotalDuration: "",
        ArrivalAddress: "",

        ImageName: "",
        ImagePath: "",

        IsActive: false,
        UserId: 0
    })

    const [TravelScopeData, setTravelScopeData] = useState([])
    const [TripTypeData, setTripTypeData] = useState([])
    const [CityListData, setCityListData] = useState([])
    const [SupplierListData, setSupplierListData] = useState([])
    const [BusTypeListData, setBusTypeListData] = useState([])

    const getotherinventorybus = async () => {
        const otherinventorybus = await getAllData("/master/v1/getotherinventorybus")

        setTravelScopeData(otherinventorybus?.data[0]?.travelScopeList)
        setTripTypeData(otherinventorybus?.data[0]?.travelTypeList)
        setBusTypeListData(otherinventorybus?.data[0]?.inventoryBusTypeList)
        setSupplierListData(otherinventorybus?.data[0]?.supplierList)
        setCityListData(otherinventorybus?.data[0]?.locationList)
    }


    const SupplierList = SupplierListData?.filter(act => act.isActive === true).map(obj => ({
        label: obj?.supplierName,
        value: obj?.supplierId
    }))

    const BusTypeList = BusTypeListData?.filter(act => act.isActive === true).map(obj => ({
        label: obj?.busType,
        value: obj?.busTypeId
    }))

    const CityList = CityListData?.filter(act => act.isActive === true).map(obj => ({
        label: obj?.city,
        value: obj?.locationId
    }))


    const [checkTravelScop, setCheckTravelScop] = useState(1)
    const handleTravelScopeChange = (id, e) => {
        setCheckTravelScop(id)
    }

    const [checkTripType, setCheckTripType] = useState(1)
    const handleTripTypeChange = (id, e) => {
        setCheckTripType(id)
    }

    const [supplierID, setSupplierID] = useState(0)
    const handleSupplierList = (e) => {
        setSupplierID(e?.value)
        const code = SupplierListData?.filter(act => act.isActive === true && e?.value === act.supplierId).map(obj => obj.supplierCode)?.find(x => x)
        formValue.SupplierCode = code
    }

    const handleBusChange = (e) => {
        setFormValue({ ...formValue, BusName: e.target.value })
    }

    const [bustypeID, setBustypeID] = useState(0)
    const handleBusType = (e) => {
        setBustypeID(e?.value)
    }


    const [departureDate, setDepartureDate] = useState(null)
    const [arrivalDate, setArrivalDate] = useState(null)
    const handleDateChange = (name, e) => {

        if (name === "Departuredate") {
            setDepartureDate(e)
        }
        if (name === "Arrivaldate") {
            setArrivalDate(e)
        }
    }


    const [departureTime, setDepartureTime] = useState(null)
    // const [arrivalTime, setArrivalTime] = useState(dayjs("1900-01-01T17:18:00"))
    const [arrivalTime, setArrivalTime] = useState(null)
    const handleTimeChange = (name, e) => {

        if (name === "DepartureTime") {
            setDepartureTime(e)
        }
        if (name === "ArrivalTime") {
            setArrivalTime(e)
        }

    }

    useEffect(() => {
        if (departureDate != null && arrivalDate != null && departureTime != null && arrivalTime != null) {
            console.log(departureDate, 'departureDate')
            console.log(arrivalDate, 'arrivalDate')
            console.log(departureTime, 'departureTime')
            console.log(arrivalTime, 'arrivalTime')
            // if (departureTime.$H < 10) {
            //     departureTime.$H = '0' + departureTime.$H;
            // }
            // if (departureTime.$m < 10) {
            //     departureTime.$m = '0' + departureTime.$m;
            // }
            // if (arrivalTime.$H < 10) {
            //     arrivalTime.$H = '0' + arrivalTime.$H;
            // }
            // if (arrivalTime.$m < 10) {
            //     arrivalTime.$m = '0' + arrivalTime.$m;
            // }

            let date1 = new Date(departureDate).getFullYear() + '-'
                + new Date(departureDate).getMonth() + 1 + '-'
                + (new Date(departureDate).getDate() >= 10 ? new Date(departureDate).getDate() : '0' + new Date(departureDate).getDate())
                + 'T' + (departureTime.$H >= 10 ? departureTime.$H : '0' + departureTime.$H)
                + ":" + (departureTime.$m >= 10 ? departureTime.$m : '0' + departureTime.$m) + ":00";

            let date2 = new Date(arrivalDate).getFullYear() + '-'
                + new Date(arrivalDate).getMonth() + 1 + '-'
                + (new Date(arrivalDate).getDate() >= 10 ? new Date(arrivalDate).getDate() : '0' + new Date(arrivalDate).getDate())
                + 'T' + (arrivalTime.$H >= 10 ? arrivalTime.$H : '0' + arrivalTime.$H)
                + ":" + (arrivalTime.$m >= 10 ? arrivalTime.$m : '0' + arrivalTime.$m) + ":00";

            getTotalDuration(date1, date2)
        }
    }, [departureDate, arrivalDate, departureTime, arrivalTime])

    const getTotalDuration = (date1, date2) => {
        console.log(date1, 'date1')
        console.log(date2, 'date2')
        let dt1 = new Date(date1)
        let dt2 = new Date(date2)

        let diff = (dt2.getTime() - dt1.getTime()) / 1000;
        // diff /= (60 * 60);
        let time = diff / 3600
        console.log(diff, 'diff')
        let HourMinute = JSON.stringify(time).split('.')
        console.log(HourMinute, 'HourMinute')
        let hour = HourMinute[0]
        let minute = Math.round((time - hour) * 60)
        console.log(hour, 'hour')
        console.log(minute, 'minute')
        console.log(Math.round(minute), 'Math.round(minute)')
        const duration = (hour >= 10 ? hour : '0' + hour) + ":" + (minute >= 10 ? minute : '0' + minute)
        console.log(duration, 'duration')
        setFormValue({ ...formValue, TotalDuration: duration })
    }

    const [depcityID, setDepcityID] = useState(0)
    const handleDepartureCity = (e) => {
        setDepcityID(e?.value)
    }

    const [arricityID, setArricityID] = useState(0)
    const handleArrivalCity = (e) => {
        setArricityID(e?.value)
    }

    const handleUploadDocumentName = (e) => {
        setFormValue({ ...formValue, ImageName: e.target.value })
    }

    const [documentsize, setDocumentSize] = useState(0)
    const [documentName, setDocumentName] = useState('')
    const [documentFile, setDocumentFile] = useState('')
    const [fileType, setFileType] = useState('')

    const handleUploadDocument = (e) => {
        console.log(e.target.files, "files")
        const file = e.target.files[0]
        let ImgPath = 'CMS/GIT_Inventory/Bus/' + file.name
        // setFormValue({...formValue,ImagePath:})
        setDocumentSize(Number((e.target.files[0].size / 1048576).toFixed(2)))
        setDocumentName(file.name)
        setDocumentFile(e.target.files[0])
        setFileType(e.target.files[0].type)
    }

    const handleChange = () => {

    }

    const openDatePicker = (e) => {
        //open date picker on click date icon 
        document.querySelector("#" + e).click();
    }

    //#region Active/Inactive button
    const handleisActive = (e) => {
        if (buttonaddeditText === 'Add') {
            formValue.IsActive = e
            setActiveId(!activeId)
            // setFormError({ ...formError, duplicate: '', faqcategoryname: '' })
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (e) => {
        if (e === true) {
            formValue.IsActive = !activeId
            setActiveId(!activeId)
            setEditDisabled(true);
            // setFormError({ ...formError, duplicate: '', faqcategoryname: '' })
        }
        setModalShow(false);
    }
    //#endregion


    //#region Add/Update Button

    const handleAdd = () => {

        formValue.TravelScopeId = checkTravelScop
        formValue.TravelTypeId = checkTripType
        formValue.SupplierId = supplierID
        formValue.BusTypeId = bustypeID
        formValue.DepartureLocationId = depcityID
        formValue.ArrivalLocationId = arricityID

        console.log(formValue, 'formValue')

    }

    //#endregion


    //#region back button
    const handlebackClick = () => {
        clear();
        navigate('/Businventorylist');
    }
    //#endregion
    //#region Clear form
    const handleClear = () => {
        clear();
    }
    function clear() {

        // setIsSubmit(false);
        setButtonAddEditText('Add');
        resetDisableState();
        // setHotelRoomPrice([]);
        setFormValue({
            ...formValue, TravelScopeId: 0, TravelTypeId: 0, PackageId: 0, VPackageId: 0, SupplierId: 0, SupplierCode: "", BusName: "", BusTypeId: 0,
            DepartureDate: "", DepartureTime: "", DepartureLocationId: 0, BoardingPointID: 0, BoardingPointAddress: "", DepartureAddress: "", ContactNumber: "",
            ArrivalDate: "", ArrivalTime: "", ArrivalLocationId: 0, DroppingPointID: 0, DroppingPointAddress: "", TotalDuration: "", ArrivalAddress: "",
            ImageName: "", ImagePath: "", IsActive: false
        });
        setCheckTravelScop(0)
        setCheckTripType(0)
        setSupplierID(0)
        setBustypeID(0)
        setDepartureDate(null)
        setArrivalDate(null)
        setDepartureTime(null)
        setArrivalTime(null)
        setDepcityID(0)
        setArricityID(0)

        resetDisableState();
    }
    function resetDisableState() {
        setDisabled(false);
        // setBannerImgDisable(false);
        // setBannerVideoDisable(false);
        // setEditDisabled(false);
        // setAddContactNoDisable(false);
        // setAddManagementNoDisable(false);
        // setAddSpokeNoDisable(false);
        // setHotelEmailAddDisable(false);
        setBackDisable(false);
        setClearDisable(false);

    }
    //#endregion

    useEffect(() => {
        // dispatch(loaderActions.start());
        // setFormError("");
        // setDuplicateValue("");
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            // getvehiclelistdetails().then(() => {
            //     dispatch(loaderActions.end());
            // });
            getotherinventorybus()
            formValue.UserId = token?.userMasterID
        } else {
            ClearLocalStorage();
            alert("Your Session Expired. Please Login Again!");
            navigate("/");
        }

    }, [])

    return (
        <>

            <div className="container mt-4 heading_color">
                <h3>Bus Inventory</h3>
            </div>
            <div className="container form_border py-5 my-3">
                <form action="" >
                    <div className="mb-3 row mx-1">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row">

                                <div className="mb-3">
                                    <div className="col-lg-12 col-sm-12">
                                        <div className="row">
                                            <div className="col-lg-6 col-sm-6 ">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-12 mb-1">
                                                        <label className="col-form-label required">Travel Scope</label>
                                                    </div>
                                                    <div className="col-lg-8 my-1 col-md-12">

                                                        {TravelScopeData && TravelScopeData?.map((obj, index) => {
                                                            return (<div className="form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="TravelScopeRadios"
                                                                    id={"TravelScopeRadio" + obj?.travelScopeId}
                                                                    value={obj?.travelScopeId}
                                                                    key={obj?.travelScopeId}
                                                                    checked={obj?.travelScopeId === checkTravelScop ? true : false}
                                                                    onChange={(e) => handleTravelScopeChange(obj?.travelScopeId, e)}
                                                                    disabled={disabled ? true : false}
                                                                />
                                                                <label className="form-check-label ms-1">{obj?.travelScope}</label>
                                                                {/* <label className="form-check-label ms-1" for="flexRadioLevel4">{checkedvalueofpackagetypevalue}</label> */}
                                                            </div>
                                                            );

                                                        })}

                                                        {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-sm-12">
                                        <div className="row">

                                            <div className="col-lg-6 col-sm-6 ">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-12 mb-1">
                                                        <label className="col-form-label required">Trip Type</label>
                                                    </div>
                                                    <div className="col-lg-8 my-1 col-md-12">

                                                        {TripTypeData && TripTypeData?.map((obj, index) => {
                                                            return (<div className="form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="TravelTypeRadio"
                                                                    id={"TravelTypeRadio" + obj?.travelTypeId}
                                                                    value={obj?.travelTypeId}
                                                                    key={obj?.travelTypeId}
                                                                    checked={obj?.travelTypeId === checkTripType ? true : false}
                                                                    onChange={(e) => handleTripTypeChange(obj?.travelTypeId, e)}
                                                                    disabled={disabled ? true : false}
                                                                />
                                                                <label className="form-check-label ms-1">{obj?.travelType}</label>
                                                                {/* <label className="form-check-label ms-1" for="flexRadioLevel4">{checkedvalueofpackagetypevalue}</label> */}
                                                            </div>
                                                            );

                                                        })}

                                                        {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-sm-6 ">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-12 mb-1">
                                                        <label className="col-form-label required">Enter Package Name</label>
                                                    </div>
                                                    <div className="col-lg-8 my-1 col-md-12">
                                                        <Select
                                                            className="dropdown"
                                                            // options={serachoption}
                                                            // value={serachoption?.filter(function (serachoption) {
                                                            //     return serachoption.value === searchby;
                                                            // })}// set list of the dataalue 
                                                            // onChange={handleSearchBy} // assign onChange function
                                                            isDisabled={disabled ? true : false}

                                                        />
                                                        {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-sm-12">
                                        <div className="row">

                                            <div className="col-lg-6 col-sm-6 ">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-12 mb-1">
                                                        <label className="col-form-label required">Supplier Name</label>
                                                    </div>
                                                    <div className="col-lg-8 my-1 col-md-12">
                                                        <Select
                                                            className="dropdown"
                                                            options={SupplierList}
                                                            value={SupplierList?.filter(function (supplier) {
                                                                return supplier.value === supplierID;
                                                            })}// set list of the dataalue 
                                                            onChange={(e) => handleSupplierList(e)} // assign onChange function
                                                            isDisabled={disabled ? true : false}

                                                        />

                                                        {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-sm-6 ">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-12 mb-1">
                                                        <label className="col-form-label required">Supplier Code</label>
                                                    </div>
                                                    <div className="col-lg-8 my-1 col-md-12">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="suppliercode"
                                                            name="SupplierCode"
                                                            value={formValue.SupplierCode}
                                                            disabled
                                                        />
                                                        {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-sm-12">
                                        <div className="row">

                                            <div className="col-lg-6 col-sm-6 ">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-12 mb-1">
                                                        <label className="col-form-label required">Bus Name</label>
                                                    </div>
                                                    <div className="col-lg-8 my-1 col-md-12">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="busname"
                                                            name="BusName"
                                                            value={formValue.BusName}
                                                            onChange={(e) => handleBusChange(e)}
                                                            disabled={disabled ? true : false}
                                                        />
                                                        {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-sm-6 ">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-12 mb-1">
                                                        <label className="col-form-label required">Bus Type</label>
                                                    </div>
                                                    <div className="col-lg-8 my-1 col-md-12">
                                                        <Select
                                                            className="dropdown"
                                                            options={BusTypeList}
                                                            value={BusTypeList?.filter(function (bustype) {
                                                                return bustype.value === bustypeID;
                                                            })}// set list of the dataalue 
                                                            onChange={(e) => handleBusType(e)} // assign onChange function
                                                            isDisabled={disabled ? true : false}

                                                        />
                                                        {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>


                                <div className="col-lg-12 col-sm-12 mt-5">
                                    <div className="row">
                                        <h3 className="">Departure City</h3>
                                    </div>
                                </div>
                                <div className="form_border">
                                    <div className="my-4">
                                        <div className="col-lg-12 col-sm-12">
                                            <div className="row">

                                                <div className="col-lg-6 col-sm-6">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Date & Time</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <div className="row">
                                                                <div className="col-lg-6 col-sm-6">
                                                                    <div className="text-container">
                                                                        <DatePicker
                                                                            dateFormat="dd/MM/yyyy"
                                                                            className='form-control'
                                                                            id='Departuredate'
                                                                            selected={departureDate}
                                                                            onChange={(e) => handleDateChange('Departuredate', e)}
                                                                            disabled={disabled ? true : false}
                                                                            placeholderText="dd/mm/yyyy"
                                                                            // onInputClick={true}
                                                                            minDate={new Date()}
                                                                            maxDate={new Date(new Date().getFullYear() + 20, 11, 30)}
                                                                            autoComplete='off'
                                                                        />
                                                                        <span className="example-custom-input calendarBtn" onClick={(e) => openDatePicker("Departuredate")} id="calendarBtn1" Disabled={disabled ? true : false} ><i className="fa fa-calendar"></i></span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-sm-6">
                                                                    {/* <TimePicker id='DepTime' className='form-control defo_border' disabled={disabled ? true : false} placeholder='--:--' value={departureTime} format={'HH:mm'} onChange={handleDeparture} /> */}
                                                                    <TimePicker
                                                                        id='DepartureTime'
                                                                        className='form-control defo_border'
                                                                        disabled={disabled ? true : false}
                                                                        placeholder='--:--'
                                                                        format={'HH:mm'}
                                                                        value={departureTime}
                                                                        onChange={(e) => handleTimeChange('DepartureTime', e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">City</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <Select
                                                                className="dropdown"
                                                                options={CityList}
                                                                value={CityList?.filter(function (depcity) {
                                                                    return depcity.value === depcityID;
                                                                })}// set list of the dataalue 
                                                                onChange={handleDepartureCity} // assign onChange function
                                                                isDisabled={disabled ? true : false}

                                                            />
                                                            {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-sm-12">
                                            <div className="row">

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Boarding Point</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <Select
                                                                className="dropdown"
                                                                // options={serachoption}
                                                                // value={serachoption?.filter(function (serachoption) {
                                                                //     return serachoption.value === searchby;
                                                                // })}// set list of the dataalue 
                                                                // onChange={handleSearchBy} // assign onChange function
                                                                isDisabled={disabled ? true : false}

                                                            />

                                                            {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Boarding Point Address</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <textarea
                                                                className='form-control'
                                                                id="boardingpointaddress"
                                                                name="BoardingPointAddress"
                                                                rows="1"
                                                                value={formValue.BoardingPointAddress}
                                                                onChange={handleChange}
                                                                disabled={disabled ? true : false}
                                                            >

                                                            </textarea>
                                                            {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-sm-12">
                                            <div className="row">

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Contact Number</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                id="contactnumber"
                                                                name="ContactNumber"
                                                                value={formValue.ContactNumber}
                                                                onChange={handleChange}
                                                                disabled={disabled ? true : false}
                                                            />
                                                            {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-12 col-sm-12 mt-5">
                                    <div className="row">
                                        <h3 className="">Arrival City</h3>
                                    </div>
                                </div>
                                <div className="form_border mb-4">
                                    <div className="my-4">

                                        <div className="col-lg-12 col-sm-12">
                                            <div className="row">

                                                <div className="col-lg-6 col-sm-6">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Date & Time</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <div className="row">
                                                                <div className="col-lg-6 col-sm-6">
                                                                    <div className="text-container">
                                                                        <DatePicker
                                                                            dateFormat="dd/MM/yyyy"
                                                                            className='form-control'
                                                                            id='Arrivaldate'
                                                                            selected={arrivalDate}
                                                                            onChange={(e) => handleDateChange('Arrivaldate', e)}
                                                                            disabled={disabled ? true : false}
                                                                            placeholderText="dd/mm/yyyy"
                                                                            // onInputClick={true}
                                                                            minDate={new Date()}
                                                                            maxDate={new Date(new Date().getFullYear() + 20, 11, 30)}
                                                                            autoComplete='off'
                                                                        />
                                                                        <span className="example-custom-input calendarBtn" onClick={(e) => openDatePicker("Arrivaldate")} id="calendarBtn1" Disabled={disabled ? true : false} ><i className="fa fa-calendar"></i></span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-sm-6">
                                                                    {/* <TimePicker id='DepTime' className='form-control defo_border' disabled={disabled ? true : false} placeholder='--:--' value={departureTime} format={'HH:mm'} onChange={handleDeparture} /> */}
                                                                    <TimePicker
                                                                        id='ArrivalTime'
                                                                        className='form-control defo_border'
                                                                        disabled={disabled ? true : false}
                                                                        placeholder='--:--'
                                                                        format={'HH:mm'}
                                                                        value={arrivalTime}
                                                                        onChange={(e) => handleTimeChange('ArrivalTime', e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">City</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <Select
                                                                className="dropdown"
                                                                options={CityList}
                                                                value={CityList?.filter(function (arricity) {
                                                                    return arricity.value === arricityID;
                                                                })}// set list of the dataalue 
                                                                onChange={handleArrivalCity} // assign onChange function
                                                                isDisabled={disabled ? true : false}

                                                            />
                                                            {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-sm-12">
                                            <div className="row">

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Dropping Point</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <Select
                                                                className="dropdown"
                                                                // options={serachoption}
                                                                // value={serachoption?.filter(function (serachoption) {
                                                                //     return serachoption.value === searchby;
                                                                // })}// set list of the dataalue 
                                                                // onChange={handleSearchBy} // assign onChange function
                                                                isDisabled={disabled ? true : false}

                                                            />

                                                            {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Dropping Point Address</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <textarea
                                                                className='form-control'
                                                                name="DroppingPointAddress"
                                                                id="droppingpointaddress"
                                                                rows="1"
                                                                value={formValue.DroppingPointAddress}
                                                                onChange={handleChange}
                                                                disabled={disabled ? true : false}
                                                            >

                                                            </textarea>
                                                            {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-sm-12">
                                            <div className="row">

                                                <div className="col-lg-6 col-sm-6 ">
                                                    {/* <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Contact Number</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                id="contactnumber"
                                                                name="ContactNumber"
                                                                value={formValue.ContactNumber}
                                                                onChange={handleChange}
                                                                disabled={disabled ? true : false}
                                                            /> */}
                                                    {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                    {/* </div>
                                                    </div> */}
                                                </div>

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Total Duration</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                id="totalduration"
                                                                name="TotalDuration"
                                                                defaultValue={formValue.TotalDuration}
                                                                // onChange={handleChange}
                                                                disabled={disabled ? true : false}
                                                            />
                                                            {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className='d-lg-flex'>
                                    <div className="mt-1 col-lg-12 col-sm-12">
                                        <div className='row'>
                                            <div className="col-lg-2 col-md-4 col-sm-12 mb-lg-1 mb-md-1">
                                                <label htmlFor="imagename" className="col-form-label">Upload Document</label>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-12 mb-1">
                                                <input
                                                    name='ImageName'
                                                    type="text"
                                                    id="imagename"
                                                    className="form-control"
                                                    value={formValue.ImageName || ''}
                                                    onChange={(e) => handleUploadDocumentName(e)}
                                                    disabled={disabled ? true : false}
                                                    placeholder='Upload Document'
                                                />
                                                {/* {formErrors.Affiliations && <p className='text-danger text-start'>{formErrors.Affiliations}</p>}
                                                        {formafflitionerror && <p className='text-danger text-start'>{formafflitionerror}</p>} */}
                                            </div>
                                            <div className="col-lg-5 col-md-4 col-sm-12 mb-lg-1 mb-md-1">
                                                <div className="input-group  mb-1">
                                                    <input
                                                        name='UploadDocumentfile'
                                                        type="file"
                                                        id="UploadDocumentfile"
                                                        className="form-control btn_add"
                                                        accept=".pdf"
                                                        onChange={(e) => handleUploadDocument(e)}
                                                        disabled={disabled ? true : false}
                                                    />
                                                    <label className="input-group-text" htmlFor="UploadDocumentfile">Browse</label>

                                                </div>
                                                {/* {affilitionstypeerror && <p className='text-danger text-start'>{affilitionstypeerror}</p>} */}
                                            </div>
                                            <div className="mb-1 col-lg-1 col-md-12 col-sm-12 me-lg-5">
                                                <div className="col-lg-1 col-md-12 col-sm-12 justify-content-md-center d-lg-block d-md-flex mb-lg-1">
                                                    {/* <button type="button" onClick={handleClick} className="btn btn-primary mx-1" id="btnAdd" disabled={addisenableaff ? false : true}>Add</button> */}
                                                    <button type="button" className="btn btn-primary mx-lg-1" id="btnAdd" >Add</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {datacollection?.map((element, index) => {
                                    if (counter > 0) {
                                        return (
                                            <div className='d-lg-flex d-md-flex' key={index}>
                                                <div className="mb-1 col-lg-10 ms-lg-5">
                                                    <div className='row'>
                                                        <div className=" col-lg-3 mb-1"></div>
                                                        <div className=" col-lg-3 col-md-6 col-sm-12 mb-1">
                                                            <input
                                                                // onChange={handleOnChange}
                                                                className="form-control"
                                                                key={index}
                                                                value={element.DocName || ''}
                                                                readOnly
                                                                type="text"
                                                            ></input>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 mb-lg-1 mb-md-1">
                                                            <div className="input-group  mb-1">
                                                                <input
                                                                    type="text"
                                                                    key={index}
                                                                    value={element.FileName || ''}
                                                                    //onChange={handleOnChange}
                                                                    readOnly
                                                                    className="form-control btn_add"
                                                                    id="inputGroupFile02" />
                                                                <label className="input-group-text" htmlFor="inputGroupFile02">Browse</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-1 mt-lg-0 mt-md-1 col-lg-2 me-lg-5">
                                                    <div className="col-lg-1 col-md-2 col-sm-12 mb-lg-1">
                                                        {/* <button type="button" className="btn btn-primary mx-1" id="btnRemove" onClick={(e) => { removeAffilitionDoc(element.SeqNo, e); }} disabled={disabled ? true : false}>&times;</button> */}
                                                        <button type="button" className="btn btn-primary mx-1" id="btnRemove" disabled={disabled ? true : false}>&times;</button>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }

                                })}

                                <div className="col-lg-6 col-sm-6">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12 col-sm-12">
                                            <label htmlFor="active" className="col-form-label">Active</label>
                                        </div>
                                        <div className="col-auto col-lg-8 " disabled={false}>
                                            <Switch
                                                id='active'
                                                onChange={handleisActive}
                                                checked={activeId}
                                                className="react-switch"
                                                disabled={disabled ? true : false}
                                            />
                                        </div>
                                        <ConfirmationModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            noconfirm={() => handleConfirmation(false)}
                                            yesconfirm={() => handleConfirmation(true)}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="mt-5 d-flex justify-content-center">
                        {/* <button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={(e) => onSubmitForm(e)} disabled={editdisabled ? true : false}>{buttonaddeditText}</button> */}
                        <button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={handleAdd} disabled={editdisabled ? true : false}>{buttonaddeditText}</button>
                        <button type="button" className="btn btn-primary mx-1" id="btnClear" onClick={handleClear} disabled={cleardisable ? true : false} >Clear</button>
                        <button type="button" className="btn btn-primary mx-1" id="btnVisible" onClick={handlebackClick} disabled={backdisable ? true : false}>Back</button>
                    </div>

                </form>
            </div>
        </>
    )
}

export default BusInventoryMaster
