import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import Switch from "react-switch";
import { generatePDF, onlyNumeric } from "../commonClass/CommonValidation";
import { useNavigate } from "react-router-dom";
import { getAllData, postUsersData, verifytoken } from "../../services/ApiCallingUserServices";
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../_actions/loader.actions";
import { ClearLocalStorage, GetLocalStorage } from "../commonClass/Session";
import { ExportExcel } from "../commonClass/ExportExcel";
import { ConfirmationModal } from '../../customControl/ConfirmationModal'
import CustomTable from '../../customControl/CustomTable';

const BusVehicleMaster = (props) => {
    const { vehicleoptionId } = props
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [activeId, setActiveId] = useState(false);
    const [btntxt, setBtnTxt] = useState("Add");
    const [editdisabled, setEditDisabled] = useState(true);
    const [disable, setDisable] = useState(false);
    const [fieldDisable, setFieldDisable] = useState(false);

    const [vehicleDataList, setVehicleDataList] = useState([]);

    const [filteritems, setFilterItems] = useState([]);

    const [searchby, setSerachBy] = useState("6");
    const [search, setSearch] = useState("");

    const [duplicateValue, setDuplicateValue] = useState();
    const [clrshow, setsclshow] = useState(false);

    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);

    const [formValue, setFormValue] = useState(
        {
            VehicleDetailId: 0,
            VehicleHeaderId: 0,
            VehicleName: '',
            VehicleTypeId: 0,
            NoOfSeats: 0,
            IsActive: false,
        }
    )
    const [formError, setFormError] = useState('')
    const [isSubmit, setIsSubmit] = useState(false)
    const [SelectTypeId, setSelectTypeId] = useState(0)


    //#region On Change Methods
    const handleChange = (e) => {
        setFormError('')
        let result;
        if ([e.target.name][0] === "NoOfSeats") {
            result = Number(onlyNumeric(e.target.value));
        }
        if ([e.target.name][0] === "VehicleName") {
            result = e.target.value
        }
        setDuplicateValue("");
        setEditDisabled(true);
        setFormValue({ ...formValue, [e.target.name]: result });
    };

    // const selectTypeOption = [
    //     { label: 'AC', value: 1 },
    //     { label: 'Non AC', value: 2 },
    // ]

    const [selectTypeOption, setSelectTypeOption] = useState([])
    const getvehicletypelist = async () => {
        await getAllData('/master/v1/getvehicletypelist').then(response => {
            setSelectTypeOption(response.data)
        })
    }

    const handleSelectTypeChange = (e) => {
        setFormError('')
        setSelectTypeId(e.value)
        formValue.VehicleTypeId = e.value
        setDuplicateValue("");
        setEditDisabled(true);
    }

    const handleisActive = (e) => {
        // if(editdisabled){
        if (btntxt === 'Add') {
            formValue.IsActive = !activeId
            setActiveId(!activeId)
            setFormError('')
            setDuplicateValue("");
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (e) => {
        //setYesNoConfirm(e)
        if (e === true) {
            formValue.IsActive = !activeId
            setActiveId(!activeId)
            setEditDisabled(true);
            setFormError('')
            setDuplicateValue("");
        }
        setModalShow(false);
    }
    //#endregion

    //#region validations
    const validation = (value) => {
        setIsSubmit(true)
        let error = {}
        if (value.VehicleName?.trim()?.length === 0) {
            error.VehicleName = 'Kindly enter the Bus Name'
            setIsSubmit(false)
        }
        // if (value.VehicleName?.trim()?.length > 0 && value.VehicleName?.trim()?.length < 2) {
        //     error.VehicleName = 'Minimum length is 2 characters'
        //     setIsSubmit(false)
        // }
        if (SelectTypeId === 0) {
            error.selectTypeId = 'Kindly Select Type'
            setIsSubmit(false)
        }
        if (value.NoOfSeats?.length === 0 || value.NoOfSeats === 0) {
            error.NoOfSeats = 'Kindly enter the No. Of Seats'
            setIsSubmit(false)
        }
        return error;
    };

    const selectTypeId = useRef()

    useEffect(() => {
        if (Object.keys(formError)?.length === 0 && isSubmit) {
            handleSubmitData();
        }
        if (Object.keys(formError)?.length > 0) {
            let id = Object.keys(formError)[0]
            if (id === 'selectTypeId') {
                selectTypeId.current.focus()
            }
            else {
                document.getElementById(id)?.focus()
            }
        }

    }, [formError])
    //#endregion

    //#region Add/Update button
    const handleAdd = e => {
        e.preventDefault();
        setFormError(validation(formValue));
    };

    const handleSubmitData = async () => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            // if (formValue.VehicleName?.trim()?.length > 0 && SelectTypeId !== 0 && formValue.NoOfSeats !== 0) {
            //     let filterData;
            //     const TypeName = selectTypeOption?.filter((type) => type.value === SelectTypeId).map(obj => obj.label).find(x => x)
            //     if (btntxt === "Add") {
            //         filterData = vehicleDataList?.filter((row) =>
            //             row.VehicleName.toLowerCase() === formValue.VehicleName.trim().toLowerCase() &&
            //             row.VehicleType.toLowerCase() === TypeName.toLowerCase() &&
            //             row.NoOfSeats === formValue.NoOfSeats);
            //         if (filterData?.length > 0) {
            //             setDuplicateValue("You can not enter duplicate value.");
            //             setIsSubmit(false)
            //             return;
            //         }
            //     }
            //     else {
            //         filterData = vehicleDataList?.filter((row) =>
            //             row.VehicleDetailId !== formValue.VehicleDetailId &&
            //             row.VehicleName.toLowerCase() === formValue.VehicleName.trim().toLowerCase() &&
            //             row.VehicleType.toLowerCase() === TypeName.toLowerCase() &&
            //             row.NoOfSeats === formValue.NoOfSeats);
            //         if (filterData?.length > 0) {
            //             setDuplicateValue("You can not enter duplicate value.");
            //             setIsSubmit(false)
            //             return;
            //         }
            //     }
            // }
            if (statusoftoken) {
                setDisable(true);
                setEditDisabled(false);
                formValue.VehicleHeaderId = 2;
                formValue.VehicleName = formValue.VehicleName.trim()
                const VehicleData = await postUsersData("/master/v1/insertorupdatevehicledetails", formValue);

                if (VehicleData.status) {
                    if (VehicleData.data > 0) {
                        setitems()
                        dispatch({ type: 'ALERTING', message: VehicleData.message })
                        clear();
                    }
                    else {
                        dispatch({ type: 'ALERTING', message: VehicleData.message })
                        return;
                    }
                }
                else {
                    dispatch({ type: 'ALERTING', message: VehicleData.message })
                    return;
                }
            }
            else {
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
        else {
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }
    //#endregion

    //#region clear button
    const handleClear = (e) => {
        clear();
    };

    function clear() {
        setIsSubmit(false)
        setFormError('')
        setFormValue({ ...formValue, VehicleName: '', VehicleDetailId: 0, VehicleHeaderId: 0, VehicleTypeId: 0, NoOfSeats: 0, IsActive: false });
        setSelectTypeId(0)
        setDuplicateValue("");
        setSearch("");
        setActiveId(false);
        getvehiclelistdetails();
        setBtnTxt("Add");
        setEditDisabled(true);
        setDisable(false);
        setFieldDisable(false);
        setSerachBy("6");
        setsclshow(false)
    }
    //#endregion

    //#region searching column vice
    const busoption = [
        { value: "1", label: "Bus Name" },
        { value: "2", label: "Select type" },
        { value: "3", label: "No. of seats" },
        { value: "4", label: "Active" },
        // { value: "5", label: "Create Date" },
        { value: "6", label: "All" },
    ];

    // Getting Vehicle Data from API--------------------------------------------------------------

    const getvehiclelistdetails = async () => {
        const VehicleData = await getAllData("/master/v1/getvehiclelistdetails?VehicleHeaderId=" + vehicleoptionId);
        setVehicleDataList(VehicleData.data);
        // setVehicleDataColumn(VehicleData.data[0].vehicleColumn);
    };

    // Getting Vehicle Data from API--------------------------------------------------------------

    const setitems = async () => {
        const VehicleData = await getAllData("/master/v1/getvehiclelistdetails?VehicleHeaderId=" + vehicleoptionId);
        setFilterItems(VehicleData.data);
    }


    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    };

    const handleChangeSearch = (e) => {
        setSearch(e.target.value);
        setsclshow(true)
        if (e.target.value === "") {
            getvehiclelistdetails();
            setsclshow(false)
        }
    };

    const clearsearch = () => {
        setSearch("");
        setsclshow(false);
        getvehiclelistdetails();
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            HandleChangeClickSearch();
        }
    };

    const HandleChangeClickSearch = () => {
        if (typeof search === "string" && search?.trim()?.length !== 0 && searchby > 0) {
            setitems()
            var filteritem = filteritems;
            var filterData;
            if (searchby === "1") {
                filterData = filteritem?.filter((row) => row.VehicleName.toLowerCase().includes(search.toLowerCase()));
                setVehicleDataList(filterData);
            }
            else if (searchby === "2") {
                if (search.toLowerCase() === "ac".toLowerCase()) {
                    filterData = filteritem?.filter((row) => row.VehicleType.toLowerCase() === 'AC'.toLowerCase());
                }
                else if (search.toLowerCase() === "inactive".toLowerCase()) {
                    filterData = filteritem?.filter((row) => row.VehicleType.toLowerCase() === 'Non Ac'.toLowerCase());
                }
                else if ("ac".toLowerCase().includes(search.toLowerCase())) {
                    filterData = filteritem?.filter(
                        (row) => row.VehicleType.toLowerCase() === 'AC'.toLowerCase() || row.VehicleType.toLowerCase() === 'Non Ac'.toLowerCase()
                    );
                }
                else if ("non ac".toLowerCase().includes(search.toLowerCase())) {
                    filterData = filteritem?.filter((row) => row.VehicleType.toLowerCase() === 'Non Ac'.toLowerCase());
                }
                setVehicleDataList(filterData === undefined ? [] : filterData);
            }
            if (searchby === "3") {
                filterData = filteritem?.filter((row) => JSON.stringify(row.NoOfSeats).toLowerCase().includes(search.toLowerCase()));
                setVehicleDataList(filterData);
            }
            else if (searchby === "4") {
                if (search.toLowerCase() === "active".toLowerCase()) {
                    filterData = filteritem?.filter((row) => row.IsActive === true);
                }
                else if (search.toLowerCase() === "inactive".toLowerCase()) {
                    filterData = filteritem?.filter((row) => row.IsActive === false);
                }
                else if ("active".toLowerCase().includes(search.toLowerCase())) {
                    filterData = filteritem?.filter((row) => row.IsActive === true || row.IsActive === false);
                }
                else if ("inactive".toLowerCase().includes(search.toLowerCase())) {
                    filterData = filteritem?.filter((row) => row.IsActive === false);
                }
                setVehicleDataList(filterData === undefined ? [] : filterData);
            }
            else if (searchby === "6") {
                if (search.toLowerCase() === "active".toLowerCase()) {
                    filterData = filteritem?.filter(
                        (row) =>
                            row.VehicleName.toLowerCase().includes(search.toLowerCase()) ||
                            JSON.stringify(row.NoOfSeats).toLowerCase().includes(search.toLowerCase()) ||
                            formatDate(row.CreatedOn)?.toLowerCase().includes(search?.toLowerCase()) ||
                            row.IsActive === true
                    );
                }
                else if (search.toLowerCase() === "inactive".toLowerCase()) {
                    filterData = filteritem?.filter(
                        (row) =>
                            row.VehicleName.toLowerCase().includes(search.toLowerCase()) ||
                            JSON.stringify(row.NoOfSeats).toLowerCase().includes(search.toLowerCase()) ||
                            formatDate(row.CreatedOn)?.toLowerCase().includes(search?.toLowerCase()) ||
                            row.IsActive === false
                    );
                }
                else if ("active".toLowerCase().includes(search.toLowerCase())) {
                    filterData = filteritem?.filter(
                        (row) =>
                            row.VehicleName.toLowerCase().includes(search.toLowerCase()) ||
                            JSON.stringify(row.NoOfSeats).toLowerCase().includes(search.toLowerCase()) ||
                            formatDate(row.CreatedOn)?.toLowerCase().includes(search?.toLowerCase()) ||
                            row.IsActive === true ||
                            row.IsActive === false
                    );
                }
                else if ("inactive".toLowerCase().includes(search.toLowerCase())) {
                    filterData = filteritem?.filter(
                        (row) =>
                            row.VehicleName.toLowerCase().includes(search.toLowerCase()) ||
                            JSON.stringify(row.NoOfSeats).toLowerCase().includes(search.toLowerCase()) ||
                            formatDate(row.CreatedOn)?.toLowerCase().includes(search?.toLowerCase()) ||
                            row.IsActive === false
                    );
                }
                else if (search.toLowerCase() === "ac".toLowerCase()) {
                    filterData = filteritem?.filter(
                        (row) =>
                            row.VehicleName.toLowerCase().includes(search.toLowerCase()) ||
                            JSON.stringify(row.NoOfSeats).toLowerCase().includes(search.toLowerCase()) ||
                            formatDate(row.CreatedOn)?.toLowerCase().includes(search?.toLowerCase()) ||
                            row.VehicleType.toLowerCase() === 'AC'.toLowerCase()
                    );
                }
                else if (search.toLowerCase() === "non ac".toLowerCase()) {
                    filterData = filteritem?.filter(
                        (row) =>
                            row.VehicleName.toLowerCase().includes(search.toLowerCase()) ||
                            JSON.stringify(row.NoOfSeats).toLowerCase().includes(search.toLowerCase()) ||
                            formatDate(row.CreatedOn)?.toLowerCase().includes(search?.toLowerCase()) ||
                            row.VehicleType.toLowerCase() === 'Non AC'.toLowerCase()
                    );
                }
                else if ("ac".toLowerCase().includes(search.toLowerCase())) {
                    filterData = filteritem?.filter(
                        (row) =>
                            row.VehicleName.toLowerCase().includes(search.toLowerCase()) ||
                            JSON.stringify(row.NoOfSeats).toLowerCase().includes(search.toLowerCase()) ||
                            formatDate(row.CreatedOn)?.toLowerCase().includes(search?.toLowerCase()) ||
                            row.VehicleType.toLowerCase() === 'AC'.toLowerCase() || row.VehicleType.toLowerCase() === 'Non AC'.toLowerCase()
                    );
                }
                else if ("non ac".toLowerCase().includes(search.toLowerCase())) {
                    filterData = filteritem?.filter(
                        (row) =>
                            row.VehicleName.toLowerCase().includes(search.toLowerCase()) ||
                            JSON.stringify(row.NoOfSeats).toLowerCase().includes(search.toLowerCase()) ||
                            formatDate(row.CreatedOn)?.toLowerCase().includes(search?.toLowerCase()) ||
                            row.VehicleType.toLowerCase() === 'Non AC'.toLowerCase()
                    );
                }
                else {
                    filterData = filteritem?.filter(
                        (row) =>
                            row.VehicleName.toLowerCase().includes(search.toLowerCase()) ||
                            formatDate(row.CreatedOn)?.toLowerCase().includes(search?.toLowerCase()) ||
                            JSON.stringify(row.NoOfSeats).toLowerCase().includes(search.toLowerCase())
                    );
                }
                setVehicleDataList(filterData);
            }
        }
    };
    //#endregion

    //#region Data Grid Columns with Edit, View And Retrieve
    // const columns = vehicleDataColumn.map((columnName) => ({
    //     name: columnName.name,
    //     selector: (row) => columnName.fieldName === 'isActive' ? (row.isActive ? 'Active' : 'InActive') : (columnName.fieldName === 'createdDate' ? formatDate(row.createdDate) : (columnName.fieldName === "updatedDate" ? formatDate(row.UpdatedDate) : (columnName.name === 'Edit' ? <i className="fa fa-edit" onClick={() => handleEdit(row.VehicleDetailId)}></i> : (columnName.name === 'View' ? <i className="fa fa-eye" onClick={() => handleView(row.VehicleDetailId)}></i> : row[columnName.fieldName])))),
    //     // selector: (row) => columnName.name === 'Vehicle Name' ? row.vehicleType : (columnName.name === 'Pax Capacity' ? row.paxCapacity : (columnName.name === 'Active' ? (row.isActive ? "Active" : "InActive") : (columnName.name === 'CreatedDate' ? formatDate(row.createdDate) : (columnName.name === 'Edit' ? <i className="fa fa-edit" onClick={() => handleEdit(row.VehicleDetailId)}></i> : <i className="fa fa-eye" onClick={() => handleView(row.VehicleDetailId)}></i>)))),
    //     sortable: columnName.sortable,
    //     reorder: columnName.reorder,
    //     float: columnName.float,
    //     omit: columnName.visibility ? false : true,
    // }));

    //Columns
    const columns = [
        {
            name: "Bus Name",
            sortable: true,
            float: "left",
            selector: (row) => row.VehicleName
        },
        {
            name: "Select Type",
            sortable: true,
            float: "left",
            selector: (row) => row.VehicleType
        },
        {
            name: "No. Of Seats",
            sortable: true,
            float: "left",
            selector: (row) => row.NoOfSeats
        },
        {
            name: "Active",
            sortable: true,
            float: "left",
            selector: (row) => row.IsActive ? "Active" : "InActive"
        },
        {
            name: "Create Date",
            sortable: true,
            float: "left",
            selector: (row) => formatDate(row.CreatedOn)
        },
        {
            name: "Edit",
            selector: (row) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEdit(row.VehicleDetailId)}></i></button>
        },
        {
            name: "View",
            selector: (row) => <i className="fa fa-eye" onClick={() => handleView(row.VehicleDetailId)}></i>
        },
    ];

    const handleEdit = (id) => {
        setDisable(true);
        setFieldDisable(false);
        setEditDisabled(false);
        setBtnTxt("Update");
        RetriveData(id);
    };

    const handleView = (id) => {
        setDisable(true);
        setFieldDisable(true);
        setEditDisabled(false);
        RetriveData(id);
    };

    const RetriveData = async (id) => {
        const VehicleDataEditDetail = await getAllData('/master/v1/getvehicledetailsbyid?VehicleDetailId=' + id)
        setFormError('')
        setDuplicateValue("");
        VehicleDataEditDetail.data?.map((obj) => {
            if (obj.VehicleDetailId === id) {
                formValue.VehicleName = obj.VehicleName;
                formValue.VehicleTypeId = obj.VehicleTypeId;
                formValue.VehicleDetailId = obj.VehicleDetailId;
                formValue.NoOfSeats = obj.NoOfSeats;
                formValue.IsActive = obj.IsActive;
                setActiveId(obj.IsActive);
                setSelectTypeId(obj.VehicleTypeId);
            }
        });
        // setFormValue({ ...formValue, formValue });
    }

    function formatDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [day, month, year].join("-");
    }
    //#endregion

    //#region Exports
    const PDFExport = () => {
        const columns = [
            "Bus Name",
            "Select Type",
            "No. Of Seats",
            "Active",
        ];
        let rows = [];

        for (let i = 0; i < vehicleDataList?.length; i++) {
            let temp = [
                vehicleDataList[i].VehicleName,
                vehicleDataList[i].VehicleType,
                vehicleDataList[i].NoOfSeats,
                `${vehicleDataList[i].IsActive ? 'Active' : 'InActive'}`,
            ];
            rows = [...rows, temp]
        }
        generatePDF(columns, rows, 'Bus', 'Bus')
    }

    const ExcelExport = () => {

        const dispalyname = ['Bus Name', 'Select Type', 'No. Of Seats', 'Active'];
        const fields = ['VehicleName', 'VehicleType', 'NoOfSeats', 'IsActive'];
        ExportExcel(dispalyname, fields, vehicleDataList, 'Bus', 'Bus.xls')
        // ExportExcel(vehicleDataColumn,vehicleDataColumn,vehicleDataList,'VehicleMaster','VehicleMaster.xls')
    }
    //#endregion

    //#region UseEffect
    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            getvehiclelistdetails().then(() => {
                dispatch(loaderActions.end());
            });
            setitems()
        }
        else {
            ClearLocalStorage();
            alert("Your Session Expired. Please Login Again!");
            navigate("/");
        }
    }, []);

    useEffect(() => {
        getvehicletypelist()
    }, [])
    useEffect(() => {
        // let MENUID = GetMenuIdFromStorage()
        // let viewrights = false;
        // user?.filter(x => x.MenuId === MENUID).map((obj) => {
        //   viewrights = obj.URightView;
        //   setUserAdd(obj.URightAdd);
        //   setUserEdit(obj.URightEdit);
        //   setUserExport(obj.URightExport);
        //   if (!viewrights) {
        //     navigate('/dashboard');
        // }
        // });
        // let MENUID = GetMenuIdFromStorage()
        let pagelink = user?.find(x => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);

        });
    }, [user])
    //#endregion

    return (
        <>
            <div className="mb-3 mt-5">
                <form action="">
                    <div className="mb-3 row mx-1">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row">
                                {duplicateValue && (<div className="text-danger pb-3 text-center">{duplicateValue} </div>)}

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label className="col-form-label required" htmlFor="VehicleName">Bus Name</label>
                                        </div>
                                        <div className="col-lg-8 mb-1 col-md-12">
                                            <input
                                                className="form-control"
                                                type="text"
                                                id="VehicleName"
                                                name="VehicleName"
                                                value={formValue.VehicleName}
                                                onChange={handleChange}
                                                placeholder="Bus Name"
                                                maxLength={200}
                                                disabled={fieldDisable ? true : false}
                                            />
                                            {formError.VehicleName && (<div className="text-danger"> {formError.VehicleName} </div>)}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label class="col-form-label required">Select Type</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            <Select
                                                ref={selectTypeId}
                                                id="StateId"
                                                className="dropdown select_dropdown"
                                                options={selectTypeOption}
                                                value={selectTypeOption?.filter(function (types) {
                                                    return types.value === SelectTypeId;
                                                })}
                                                onChange={handleSelectTypeChange}
                                                isDisabled={fieldDisable ? true : false}
                                                isSearchable
                                            />
                                            {formError.selectTypeId && (<div className="text-danger"> {formError.selectTypeId} </div>)}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label className="col-form-label required" htmlFor="NoOfSeats">No. Of Seats</label>
                                        </div>
                                        <div className="col-lg-8 mb-1 col-md-12">
                                            <input
                                                className="form-control"
                                                type="text"
                                                id="NoOfSeats"
                                                name="NoOfSeats"
                                                value={formValue.NoOfSeats === 0 ? '' : formValue.NoOfSeats}
                                                onChange={handleChange}
                                                placeholder="No. Of Seats"
                                                maxLength={3}
                                                disabled={fieldDisable ? true : false}
                                            />
                                            {formError.NoOfSeats && (<div className="text-danger"> {formError.NoOfSeats} </div>)}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className='col-lg-6 col-sm-6'>
                                        <div className='row'>
                                            <div className="col-lg-4 col-md-12 mb-1">
                                                <label className="col-form-label">Active</label>
                                            </div>
                                            <div className="col-auto col-lg-8" disabled={false}>
                                                <Switch
                                                    onChange={handleisActive}
                                                    checked={activeId}
                                                    className="react-switch"
                                                    disabled={fieldDisable ? true : false}
                                                />
                                            </div>
                                            <ConfirmationModal
                                                show={modalShow}
                                                onHide={() => setModalShow(false)}
                                                noconfirm={() => handleConfirmation(false)}
                                                yesconfirm={() => handleConfirmation(true)}
                                            />

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center mb-4">
                        {useradd === false && btntxt === "Add" ?
                            <button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={handleAdd} disabled={!useradd}>{btntxt}</button> :
                            <button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={handleAdd} disabled={editdisabled ? false : true} >{btntxt}</button>}
                        <button type="button" className="btn btn-primary mx-1" id="btnClear" onClick={handleClear}>Clear</button>
                    </div>

                    <div className="row">
                        <div className="d-lg-flex d-md-flex d-sm-block justify-content-end">
                            <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={busoption}
                                    defaultValue={busoption[4]}
                                    value={busoption?.filter(function (serach) {
                                        return serach.value === searchby;
                                    })}
                                    onChange={handleSearchBy}
                                    isDisabled={disable ? true : false}
                                ></Select>
                            </div>
                            <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input type="text" onKeyDown={onKeyDownHandler} id="btnSearchText" className="form-control br-4" value={search} placeholder="Enter keyword" disabled={disable ? true : false} onChange={handleChangeSearch} />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={disable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div className="p-2 text-center">
                                <button
                                    type="button"
                                    value={search}
                                    className="btn btn-primary mx-1"
                                    onClick={HandleChangeClickSearch}
                                    disabled={disable ? true : false}
                                >
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>

                    <CustomTable
                        columns={columns}
                        data={vehicleDataList}
                        PdfExport={PDFExport}
                        ExportToExcel={ExcelExport}
                        disabled={!userexport}
                    />
                </form>
            </div>
        </>
    )
}

export default BusVehicleMaster
