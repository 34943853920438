import { createRoot } from 'react-dom/client';
import CMSC from './components/admin/CMS';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Login from './components/CMS/Login';
import './assets/css/homepage.css';
import { store } from "./_helpers/store";
import { Provider } from 'react-redux';
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);


// 👇️ wrap App in Router
root.render(
  <Provider store={store}>
  <Router>
    <Routes>
      <Route index path='/' element={<Login />} />
      <Route exact path='/*' element={<CMSC />}/>
      <Route index path='/Logout' element={<Login />}/>
    </Routes>
    {/* <ImageUploading/> */}
  </Router>
  </Provider>
);
