import React from 'react';
import { useEffect, useState, useRef } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from 'react-select';
import "../../assets/css/master.css"
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../services/ApiCallingUserServices';
import Switch from 'react-switch';
import { isValidEmail, NumericWithDecimal, onlyNumeric, PhoneNmber, isValidImage, isValidVideo, onlyAlphabets, isValidPhonenumber } from '../commonClass/CommonValidation';
import { ImageDelete, ImageUpload } from '../../ImageUpload/ImageCommon';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../commonClass/Session';
import { Rating } from 'react-simple-star-rating';
import { useDispatch } from "react-redux";
import { loaderActions } from "../../_actions/loader.actions";
import { ConfirmationModal } from '../../customControl/ConfirmationModal';
import CustomSelection from '../../customControl/CustomSelection';
import { GetMenuIdFromStorage } from '../commonClass/Menudect';

const Hotelmaster = () => {

    const dispatch = useDispatch();
    const [duplicatedataerror, setDuplicateDataError] = useState('');


    const [cityid, setCityId] = useState();
    const [disabled, setDisabled] = useState(false);
    const [citydata, setCityData] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [activeId, setActiveId] = useState();

    const [tragetname, setTargetName] = useState('');

    const [hotelcontactnoerror, setHotelContactNoError] = useState('');

    const [spokenameerror, setSpokeNameError] = useState('');
    const [spokenumbererror, setSpokeNumberError] = useState('');

    const [managementnameerror, setManagementNameError] = useState('');
    const [mangementnumbererror, setManagementNumberError] = useState('');

    const [hotelemailerror, setHotelEmailError] = useState('');

    const [btncontactadddisable, setAddContactNoDisable] = useState(false);
    const [btnspokeadddisable, setAddSpokeNoDisable] = useState(false);
    const [btnmanagementNoadddisable, setAddManagementNoDisable] = useState(false);
    const [btnhotelemailadddisable, setHotelEmailAddDisable] = useState(false);

    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const [priceroomerror, setPriceRoomError] = useState('');

    const [hotelReservationDupError, sethotelReservationDupError] = useState('');
    const [hotelMngtDupError, sethotelMngtDupError] = useState('');

    const [editdisabled, setEditDisabled] = useState(false);
    const [buttonaddeditText, setButtonAddEditText] = useState("Add");
    const [rating, setRating] = useState(0)
    const [retrivedata, setRetriveData] = useState([]);
    const [allcity, setAllCity] = useState([]);
    const [citydisable, setCityDisable] = useState(false);

    const HotelRoomPrice = useState([{
        RoomTypeId: 0,
        RoomType: '',
        NoOfAllocation: '',
        RoomPricingDetail: [],
        RoomPhotos: []
    }]);
    const [hotelroomprice, setHotelRoomPrice] = useState(HotelRoomPrice);

    const [hotelroomtypedata, setHotelRoomType] = useState([]);

    const [formvalue, setFormValue] = useState({
        HotelId: 0,
        LocationId: 0,
        Rating: 0,
        Name: "",
        Address: "",
        GoogleLocation: "",
        Area: "",
        IsActive: false,
        HotelContactNo: [],
        HotelReservation: [],
        HotelManagementContactNo: [],
        HotelEmail: [],
        HotelDocument: [],
        HotelPricing: [],

        HotelContactNoList: [],
        HotelReservationList: [],
        HotelManagementContactNoList: [],
        HotelEmailList: [],
        HotelDocumentList: [],
        HotelPricingList: [],

        HotelContactNumber: "",
        ReservationNameD: '',
        ReservationNumberD: '',
        ManagementNameD: '',
        ManagementNumberD: '',
        HotelEmailD: '',
        RoomTypeD: '',
        RoomAllocation: 0,
        UserId: 0

    });
    const [cleardisable, setClearDisable] = useState(false);
    const [backdisable, setBackDisable] = useState(false);

    const hotel = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            if (hotel.state !== null) {
                //if (Window.$name && Window.$userToken) {
                setDisabled(hotel.state.disabled);
                setBannerImgDisable(hotel.state.editdisabled);
                setBannerVideoDisable(hotel.state.editdisabled);
                setEditDisabled(hotel.state.editdisabled);
                getCityData();
                // gethoteldatalist();
                // put it into getcityData for check loader issue
                // gethotelroomtype();

            }
            else {
                navigate('/Hotellistmaster')
            }


        }
        else {
            ClearLocalStorage();
            // alert('Your Session Expired. Please Login Again!');
            // navigate('/');
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, []);

    useEffect(() => {
        if (hotelroomtypedata?.length > 0) {
            
            if (hotel?.state?.HotelId > 0) {
                setClearDisable(true);
                handleRetrive();
            }
            else
                setClearDisable(false);
        }

    }, [hotelroomtypedata]);

    // //#region  binding data table
    // const gethoteldatalist = async () => {
    //     const hotellistD = await getAllData('/general/v1/getallhotel');
    //     setHotelList(hotellistD.data);
    // }


    // //#endregion

    //#region city data binding
    const gethotelroomtype = async () => {
        const hoteldata = await getAllData('/master/v1/gethotelroomtype');
        setHotelRoomType(hoteldata.data);
    }

    const hotelroomtype = hotelroomtypedata ? hotelroomtypedata?.map(value => ({
        label: value.RoomTypeName,
        value: value.RoomTypeId
    })) : [];


    const getCityData = async () => {
        await getAllData('/master/v1/gethotellocationcity').then(response => {
            setCityData(response.data?.filter(IsAct => IsAct.IsActive === true));
            setAllCity(response.data);
            // setCityList(citydata?.filter(IsAct => IsAct.IsActive === true)?.map((value)=>({
            //     label: value.City,
            //     value: value.LocationId
            // })))
            // setStateList(citydata?.filter(IsAct => IsAct.IsActive === true)?.map((value) => ({
            //     label: value.StateName,
            //     value: value.LocationId
            // })));
            // setContinentList(citydata?.filter(IsAct => IsAct.IsActive === true)?.map((value) => ({
            //     label: value.ContinentName,
            //     value: value.LocationId
            // })));
            // setCountryList(citydata?.filter(IsAct => IsAct.IsActive === true)?.map((value) => ({
            //     label: value.CountryName,
            //     value: value.LocationId
            // })));
            // setRegionListList(citydata?.filter(IsAct => IsAct.IsActive === true)?.map((value) => ({
            //     label: value.RegionName,
            //     value: value.LocationId
            // })));
            gethotelroomtype();
        })
    }

    const cityList = citydata?.map(value => ({
        label: value.City,
        value: value.LocationId
    }));


    const stateList = citydata ? citydata?.map(value => ({
        label: value.StateName,
        value: value.LocationId
    })) : [];

    const continentList = citydata ? citydata?.map(value => ({
        label: value.ContinentName,
        value: value.LocationId
    })) : [];

    const countryList = citydata ? citydata?.map(value => ({
        label: value.CountryName,
        value: value.LocationId
    })) : [];

    const regionList = citydata ? citydata?.map(value => ({
        label: value.RegionName,
        value: value.LocationId
    })) : [];

    const handlecity = (e) => {
        // setNoOfPaxError('');
        // setRoomPriceError('');
        setFormErrors1([]);
        setSpokeNumberError('');
        setSpokeNameError('');
        setManagementNameError('');
        setManagementNumberError('');
        setHotelEmailError('');
        setHotelContactNoError('');
        setRoomTypeError('');
        setRoomAllocationError('');
        setPriceRoomError('');
        setImageError('');
        setVideoError('');
        setImageRoomError('');
        sethotelReservationDupError('');
        sethotelMngtDupError('');

        setFormErrors('');
        setImageCountError('');
        setEditDisabled(false);
        formvalue.LocationId = e.value
        setCityId(e.value)
    }

    //#endregion

    //#region handle change , active event

    const handleisActive = (e) => {
        if (buttonaddeditText === 'Add') {
            formvalue.IsActive = e
            setActiveId(!activeId)
            handleresetallfield();
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (e) => {
        //setYesNoConfirm(e)
        if (e === true) {
            formvalue.IsActive = !activeId
            setActiveId(!activeId)
            handleresetallfield();
        }
        setModalShow(false);
    }
    // Catch Rating value
    const handleRating = (rate) => {
        formvalue.Rating = rate
        setRating(rate);
        handleresetallfield();
    }
    const [roomtypeid, setRoomTypeId] = useState(0);

    const handleRoomtypeChange = (e) => {
        formvalue.RoomTypeD = e.label
        setRoomTypeId(e.value);
        handleresetallfield();
        setFormErrors1([]);
    }

    const handleresetallfield = () => {

        // setNoOfPaxError('');
        // setRoomPriceError('');
        // setFormErrors1([]);
        setSpokeNumberError('');
        setSpokeNameError('');
        setManagementNameError('');
        setManagementNumberError('');
        setHotelEmailError('');
        setHotelContactNoError('');
        setRoomTypeError('');
        setRoomAllocationError('');
        setPriceRoomError('');
        sethotelReservationDupError('');
        sethotelMngtDupError('');
        setFormErrors('');
        setImageError('');
        setVideoError('');
        setImageRoomError('');
        setEditDisabled(false);
        setImageCountError('');
    }


    //#endregion

    //#region HotelContactnumber collection

    const hotelcontactnocollection = useState([{
        ContactNumber: '',
        SeqNo: 0
    }]);
    const [datahotelcontactnocollection, setHotelContactNoDataCollection] = useState(hotelcontactnocollection);
    const [datahotelcontactnocounter, setHotelContactNoCounter] = useState(0);
    const ContactClick = () => {
        if (formvalue.HotelContactNumber.trim().length > 0) {
            let result = datahotelcontactnocollection?.find(function (item) {
                return item.ContactNumber === formvalue.HotelContactNumber;
            })
            if (result ? result.length === 0 : 0 === 0) {
                if (isValidPhonenumber(formvalue.HotelContactNumber)) {
                    setHotelContactNoCounter(datahotelcontactnocounter + 1);
                }
                else {
                    setHotelContactNoError('Please enter valid contact no');
                }
            }

            else {
                setHotelContactNoError('Contact number already exist.');
            }
        }

        else {
            setHotelContactNoError('Please enter contact no');
        }

    };

    function AddDataRowForHotelContactNo() {

        if (tragetname === 'Add') {
            if (datahotelcontactnocounter === 1) {
                setHotelContactNoDataCollection(current => [{ SeqNo: datahotelcontactnocounter, ContactNumber: formvalue.HotelContactNumber }]);
            }
            else {
                setHotelContactNoDataCollection(current => [...current, { SeqNo: datahotelcontactnocounter, ContactNumber: formvalue.HotelContactNumber }]);
            }
        }
    }
    const AddDataRowForHotelContactNoAtRetrive = () => {
        // let len = datahotelcontactnocollection?.length || 0;
        if (formvalue.HotelContactNumber.trim().length > 0) {
            if (datahotelcontactnocounter === 1) {

                setHotelContactNoDataCollection(current => [{ SeqNo: datahotelcontactnocounter, ContactNumber: formvalue.HotelContactNumber }]);
            }
            else {
                setHotelContactNoDataCollection(current => [...current, { SeqNo: datahotelcontactnocounter, ContactNumber: formvalue.HotelContactNumber }]);
            }
        }
    }

    useEffect(() => {
        if (datahotelcontactnocounter > 0) {
            if (tragetname.length > 0)
                AddDataRowForHotelContactNo();
            else {
                AddDataRowForHotelContactNoAtRetrive();
            }
        }

    }, [datahotelcontactnocounter]);

    useEffect(() => {
        setFormValue({ ...formvalue, HotelContactNumber: '' });
        if (hotel?.state?.viewtype === 'View') {
            setAddContactNoDisable(true);
        }
        else if (datahotelcontactnocollection?.length > 3) {
            setAddContactNoDisable(true);
        }
        else {
            setAddContactNoDisable(false);
        }
    }, [datahotelcontactnocollection]);

    const contactremovev = (id, e) => {
        setHotelContactNoDataCollection((oldState) => oldState?.filter((item) => item.SeqNo !== id));
        setEditDisabled(false);
    }

    //#endregion

    //#region Reservation Spoke Details region
    //const [reservationtargetname , setReservationTargetName] = useState('');

    const ReservationSpokedetails = useState([{
        ReservationName: '',
        ReservationNumber: '',
        SeqNo: 0
    }]);
    const [datareservationspokedetails, setReservationSpokeDetails] = useState(ReservationSpokedetails);
    const [datareservationspokecounter, setReservationSpokeCounter] = useState(0);

    const ReservationClick = () => {
        if (formvalue.ReservationNameD.trim().length > 50 && formvalue.ReservationNumberD.trim().length === 0) {
            setSpokeNameError('Maximum 50 characters are allowed.');
            setSpokeNumberError('Please enter reservation spoke number');
        }
        // else if (formvalue.ReservationNameD.trim().length > 0 && formvalue.ReservationNumberD.trim().length > 0 && isNaN(formvalue.ReservationNumberD)) {
        //     setSpokeNumberError('Only Numbers are allowed in this field.');
        // }
        else if (formvalue.ReservationNameD.trim().length > 0 && formvalue.ReservationNumberD.trim().length > 0) {
            if (formvalue.ReservationNameD.trim().length < 51) {
                if (datareservationspokedetails?.filter(IsDup => IsDup.ReservationName === formvalue.ReservationNameD.trim() && IsDup.ReservationNumber === formvalue.ReservationNumberD.trim())?.length === 0) {
                    if (isValidPhonenumber(formvalue.ReservationNumberD)) {
                        setReservationSpokeCounter(datareservationspokecounter + 1);
                    }
                    else {
                        setSpokeNumberError('Please enter valid contact no');
                    }
                }
                else {
                    sethotelReservationDupError('You cannot add duplicate details.');
                    return
                }
            }
            else
                setSpokeNameError('Maximum 50 characters are allowed.');
        }
        else if (formvalue.ReservationNameD.trim().length > 0 && formvalue.ReservationNumberD.trim().length === 0) {
            setSpokeNumberError('Please enter reservation spoke number');
        }


        else if (formvalue.ReservationNameD.trim().length === 0 && formvalue.ReservationNumberD.trim().length > 0) {
            setSpokeNameError('Please enter reservation spoke name');
        }


    };

    function AddDataRowForReservationSpokeDetails() {
        if (buttonaddeditText === 'Add') {
            if (formvalue.ReservationNameD.trim().length > 0 && formvalue.ReservationNumberD.trim().length > 0) {
                if (datareservationspokecounter === 1) {
                    setReservationSpokeDetails(current => [{ SeqNo: datareservationspokecounter, ReservationName: formvalue.ReservationNameD, ReservationNumber: formvalue.ReservationNumberD }]);
                }
                else {
                    setReservationSpokeDetails(current => [...current, { SeqNo: datareservationspokecounter, ReservationName: formvalue.ReservationNameD, ReservationNumber: formvalue.ReservationNumberD }]);
                }
            }
        }
        else {
            let len = datareservationspokedetails?.length || 0;

            if (formvalue.ReservationNameD.trim().length > 0 && formvalue.ReservationNumberD.trim().length > 0) {
                if (len === 0) {
                    setReservationSpokeDetails(current => [{ SeqNo: datareservationspokecounter, ReservationName: formvalue.ReservationNameD, ReservationNumber: formvalue.ReservationNumberD }]);
                }
                else {
                    setReservationSpokeDetails(current => [...current, { SeqNo: datareservationspokecounter, ReservationName: formvalue.ReservationNameD, ReservationNumber: formvalue.ReservationNumberD }]);
                }
            }
        }
    }
    const reservationremovev = (id, e) => {
        setReservationSpokeDetails((oldState) => oldState?.filter((item) => item.SeqNo !== id));
        setEditDisabled(false);
    }

    useEffect(() => {
        if (datareservationspokecounter > 0)
            AddDataRowForReservationSpokeDetails();
    }, [datareservationspokecounter]);

    useEffect(() => {
        setFormValue({ ...formvalue, ReservationNameD: '', ReservationNumberD: '' });
        if (hotel?.state?.viewtype === 'View') {
            setAddSpokeNoDisable(true);
        }
        else if (datareservationspokedetails.length > 3) {
            setAddSpokeNoDisable(true);
        }
        else {
            setAddSpokeNoDisable(false);
        }
    }, [datareservationspokedetails]);
    //#endregion

    //#region Management Contact No region
    const ManagementContactNo = useState([{
        ManagementName: '',
        ManagementNumber: '',
        SeqNo: 0
    }]);
    const [datamanagementcontactno, setManagementContactNo] = useState(ManagementContactNo);
    const [datamanagementcontactcounter, setManagementContactCounter] = useState(0);

    const ManagementClick = () => {
        if (formvalue.ManagementNameD.trim().length > 50 && formvalue.ManagementNumberD.trim().length === 0) {
            setManagementNameError('Maximum 50 characters are allowed.');
            setManagementNumberError('Please enter management number');

        }
        // else if (formvalue.ManagementNameD.trim().length > 0 && formvalue.ManagementNumberD.trim().length > 0 && isNaN(formvalue.ManagementNumberD)) {
        //     setManagementNumberError('Only Numbers are allowed in this field.');
        // }
        else if (formvalue.ManagementNameD.trim().length > 0 && formvalue.ManagementNumberD.trim().length > 0) {
            if (formvalue.ManagementNameD.trim().length < 51) {
                if (datamanagementcontactno?.filter(IsDup => IsDup.ManagementName === formvalue.ManagementNameD.trim() && IsDup.ManagementNumber === formvalue.ManagementNumberD.trim())?.length === 0) {
                    if (isValidPhonenumber(formvalue.ManagementNumberD)) {
                        setManagementContactCounter(datamanagementcontactcounter + 1);
                    }
                    else {
                        setManagementNumberError('Please enter valid contact no');
                    }
                }
                else {
                    sethotelMngtDupError('You cannot add duplicate details.');
                    return
                }
            }
            else
                setManagementNameError('Maximum 50 characters are allowed.');
        }
        else if (formvalue.ManagementNameD.trim().length > 0 && formvalue.ManagementNumberD.trim().length === 0) {
            setManagementNumberError('Please enter management number');
        }

        else if (formvalue.ManagementNameD.trim().length === 0 && formvalue.ManagementNumberD.trim().length > 0) {
            setManagementNameError('Please enter management name');
        }

        //setManagementContactCounter(datamanagementcontactcounter + 1);
    };
    function AddDataRowForManagementContactNo() {
        if (buttonaddeditText === 'Add') {
            if (formvalue.ManagementNameD.trim().length > 0 && formvalue.ManagementNumberD.trim().length > 0) {
                if (datamanagementcontactcounter === 1) {
                    setManagementContactNo(current => [{ SeqNo: datamanagementcontactcounter, ManagementName: formvalue.ManagementNameD, ManagementNumber: formvalue.ManagementNumberD }]);
                }
                else {
                    setManagementContactNo(current => [...current, { SeqNo: datamanagementcontactcounter, ManagementName: formvalue.ManagementNameD, ManagementNumber: formvalue.ManagementNumberD }]);
                }
            }
        }
        else {
            let len = datamanagementcontactno?.length || 0;
            if (formvalue.ManagementNameD.trim().length > 0 && formvalue.ManagementNumberD.trim().length > 0) {
                if (len === 0) {
                    setManagementContactNo(current => [{ SeqNo: datamanagementcontactcounter, ManagementName: formvalue.ManagementNameD, ManagementNumber: formvalue.ManagementNumberD }]);
                }
                else {
                    setManagementContactNo(current => [...current, { SeqNo: datamanagementcontactcounter, ManagementName: formvalue.ManagementNameD, ManagementNumber: formvalue.ManagementNumberD }]);
                }
            }
        }


    }

    useEffect(() => {
        if (datamanagementcontactcounter > 0)
            AddDataRowForManagementContactNo();
    }, [datamanagementcontactcounter]);

    useEffect(() => {
        setFormValue({ ...formvalue, ManagementNameD: '', ManagementNumberD: '' });

        if (hotel?.state?.viewtype === 'View') {
            setAddManagementNoDisable(true);
        }
        else if (datamanagementcontactno.length > 3) {
            setAddManagementNoDisable(true);
        }
        else {
            setAddManagementNoDisable(false);
        }
    }, [datamanagementcontactno]);

    const managementremovev = (id, e) => {
        setManagementContactNo((oldState) => oldState?.filter((item) => item.SeqNo !== id));
        setEditDisabled(false);
    }
    //#endregion

    //#region Hotel Email ID

    const HotelEmailnocollection = useState([{
        HotelEmail: '',
        SeqNo: 0
    }]);
    const [datahotelemailcollection, setHotelEmailDataCollection] = useState(HotelEmailnocollection);
    const [datahotelemailcounter, setHotelEmailCounter] = useState(0);

    const EmailClick = () => {
        if (formvalue.HotelEmailD.trim().length > 0) {
            let result = datahotelemailcollection?.find(function (item) {
                return item.HotelEmail === formvalue.HotelEmailD;
            })
            if (result ? result.length === 0 : 0 === 0) {
                if (isValidEmail(formvalue.HotelEmailD)) {
                    setHotelEmailCounter(datahotelemailcounter + 1);
                }
                else {
                    setHotelEmailError('Please enter correct email');
                }
            }
            else {
                setHotelEmailError('Email already exist');
            }
        }

        else {
            setHotelEmailError('Please enter Hotel Email.');
        }
    };
    function AddDataRowForHotelEmailNo() {
        if (buttonaddeditText === 'Add') {
            if (formvalue.HotelEmailD.trim().length > 0) {
                if (datahotelemailcounter === 1) {
                    setHotelEmailDataCollection(current => [{ SeqNo: datahotelemailcounter, HotelEmail: formvalue.HotelEmailD }]);
                }
                else {
                    setHotelEmailDataCollection(current => [...current, { SeqNo: datahotelemailcounter, HotelEmail: formvalue.HotelEmailD }]);
                }
            }
        }
        else {
            let len = datahotelemailcollection?.length || 0;

            if (formvalue.HotelEmailD.trim().length > 0) {
                if (len === 0) {
                    setHotelEmailDataCollection(current => [{ SeqNo: datahotelemailcounter, HotelEmail: formvalue.HotelEmailD }]);
                }
                else {
                    setHotelEmailDataCollection(current => [...current, { SeqNo: datahotelemailcounter, HotelEmail: formvalue.HotelEmailD }]);
                }
            }
        }

    }

    useEffect(() => {
        if (datahotelemailcounter > 0)
            AddDataRowForHotelEmailNo();
    }, [datahotelemailcounter]);
    useEffect(() => {
        setFormValue({ ...formvalue, HotelEmailD: '' });
        if (hotel?.state?.viewtype === 'View') {
            setHotelEmailAddDisable(true);
        }
        else if (datahotelemailcollection.length > 3) {
            setHotelEmailAddDisable(true);
        }
        else {
            setHotelEmailAddDisable(false);
        }
    }, [datahotelemailcollection]);
    const emailremovev = (id, e) => {
        setHotelEmailDataCollection((oldState) => oldState?.filter((item) => item.SeqNo !== id));
        setEditDisabled(false);
    }
    //#endregion

    //#region image upload
    const BannerList = useState([
        {
            DocPath: '',
            DocName: '',
            DocType: 0
        }
    ]);

    const [bannerdoclist, setBannerDocList] = useState(BannerList);
    const [bannerImage, setBannerImage] = useState();
    const [bannerVideo, setBannerVideo] = useState();
    const [bannercount, setBannerCount] = useState(0);
    const [bannerimgdisable, setBannerImgDisable] = useState(false);
    const [bannervideodisable, setBannerVideoDisable] = useState(false);
    const [imageError, setImageError] = useState('');
    const [videoError, setVideoError] = useState('');

    const handleUploadImageLoad = (e) => {
        if (isValidImage(e.target.files[0].name)) {
            const files = e.target.files;
            const filecom = getDateCombination();
            const filename = filecom + '_1_' + (files[0].name.replace(/ /g, "_")).replace(/-/g, '');
            setBannerImage(e.target.files[0]);
            updatebannerlist(1);
            if (bannercount === 0)
                setBannerDocList([{ DocName: filename, DocPath: 'CMS/Hotel/' + filename, DocType: 1 }]);
            else
                setBannerDocList([...bannerdoclist, { DocName: filename, DocPath: 'CMS/Hotel/' + filename, DocType: 1 }]);

            setBannerCount(bannercount + 1);
            setBannerImgDisable(true);
            handleresetallfield();
            setBannerImgPath('');
            setBannerImgName('');
            setVideoError('');
            setDuplicateDataError('');
        }
        else {
            document.getElementById("txtbannerimages").value = "";
            setFormValue({ ...formvalue, DocName: '', DocPath: '' });
            // updatebannerlist(0);
            setBannerImgPath('');
            setFormErrors('');
            setBannerImgName('');
            setDuplicateDataError('');
            setImageError("Please select Only Image");
            setIsSubmit(false);
            return;
        }
    }


    const handleVideoUpload = (e) => {

        if (isValidVideo(e.target.files[0].name)) {
            const files = e.target.files;
            const filecom = getDateCombination();
            const filename = filecom + '_1_' + (files[0].name.replace(/ /g, "_")).replace(/-/g, '');
            setBannerVideo(e.target.files[0]);
            updatebannerlist(2);
            if (bannercount === 0)
                setBannerDocList([{ DocName: filename, DocPath: 'CMS/Hotel/' + filename, DocType: 2 }]);
            else
                setBannerDocList([...bannerdoclist, { DocName: filename, DocPath: 'CMS/Hotel/' + filename, DocType: 2 }]);

            setBannerCount(bannercount + 1);
            setBannerVideoDisable(true);
            // setNoOfPaxError('');
            // setRoomPriceError('');
            // setFormErrors1([]);
            setSpokeNumberError('');
            setSpokeNameError('');
            setManagementNameError('');
            setManagementNumberError('');
            setHotelEmailError('');
            setHotelContactNoError('');
            setRoomTypeError('');
            setRoomAllocationError('');
            setPriceRoomError('');
            // setBannerImgVideoError('');
            // setHotelContactError('');
            // setHotelEmailListError('');
            sethotelReservationDupError('');
            sethotelMngtDupError('');
            setFormErrors('');
            setImageRoomError('');
            setImageCountError('');
            setBannerVideoPath('');
            setBannerVideoName('');
            setImageError('');
            setVideoError('');
            setDuplicateDataError('');
            setEditDisabled(false);
        }
        else {
            document.getElementById("txtbannerVideo").value = "";
            setFormValue({ ...formvalue, DocName: '', DocPath: '' });
            setBannerVideoPath('');
            setFormErrors('');
            setBannerVideoName('');
            setDuplicateDataError('');
            setVideoError("Please select Only Video")
            setIsSubmit(false);
            return;
        }


    }
    useEffect(() => { }, [bannercount]);
    const removeImage = () => {
        handleresetallfield();
        document.getElementById("txtbannerimages").value = "";
        updatebannerlist(1);
        setBannerImage();
        setBannerImgPath('');
        setBannerImgName('');
        setBannerImgDisable(false);
    };
    const removeVideo = () => {
        handleresetallfield();
        document.getElementById("txtbannerVideo").value = "";
        updatebannerlist(2);
        setBannerVideo();
        setBannerVideoPath('');
        setBannerVideoName('');
        setBannerVideoDisable(false);
    }

    const updatebannerlist = (type) => {
        setBannerDocList((oldState) => oldState?.filter((item) => Number(item.DocType) !== Number(type)));
    }

    function getDateCombination() {
        var today = new Date();
        let date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
        let time = today.getHours().toString() + (today.getMinutes() + 1).toString() + today.getSeconds().toString();
        return date + '_' + time;
    }



    //#endregion

    //#region pricing region

    const pricing = useState([{
        SeqNo: 0,
        RoomType: '',
        RoomTypeId: 0,
        RoomAllocation: '',
        NoOfPax: '',
        RoomPrice: '',
        RoomIsActive: false

    }]);

    const [datapricing, setPricing] = useState(pricing);
    const [datapricingcounter, setPricingCounter] = useState(0);
    const [roomtypeerror, setRoomTypeError] = useState('');
    const [roomallocation, setRoomAllocationError] = useState('');

    const RoomTypeClick = () => {
        handleresetallfield();
        const roomtypename = datapricing?.filter(obj => obj.RoomType === formvalue.RoomTypeD);

        if (roomtypename.length > 0) {
            setPriceRoomError('Room type is already added.');
        }
        //formvalue.RoomAllocation.trim().length === 0
        else if (formvalue.RoomTypeD.trim().length === 0 && Number(formvalue.RoomAllocation) === 0) {
            setRoomTypeError('Please select Room Type');
            setRoomAllocationError('Please enter no of allocation');
        }
        else if (formvalue.RoomTypeD.trim().length > 0 && Number(formvalue.RoomAllocation) === 0) {
            if (formvalue.RoomTypeD.trim().length > 50)
                setRoomTypeError('Maximum 50 characters are allowed.');
            setRoomAllocationError('Please enter no of allocation');
        }
        // else if(formvalue.RoomTypeD.trim().length > 0  && formvalue.RoomAllocation.trim().length === 0)
        // {
        //     setRoomAllocationError('Please enter no of allocation');
        // }
        else if (formvalue.RoomTypeD.trim().length === 0 && Number(formvalue.RoomAllocation) > 0) {
            setRoomTypeError('Please select Room Type');
        }
        else if (formvalue.RoomTypeD.trim().length > 50 && Number(formvalue.RoomAllocation) > 0) {
            setRoomTypeError('Maximum 50 characters are allowed.');
            //setRoomTypeLabel(formvalue.RoomTypeD);
        }
        else if (Number(formvalue.RoomAllocation) > 0 && isNaN(formvalue.RoomAllocation)) {
            setRoomAllocationError("Only Numbers are allowed in this field.");
        }
        else if (formvalue.RoomTypeD.trim().length > 0 && Number(formvalue.RoomAllocation) > 0) {
            // cnt= datapricing?.find(obj => obj.RoomType === formvalue.RoomTypeD);
            // if(cnt === 0 || cnt === 'undefined')
            setPricingCounter(datapricingcounter + 1);
            // else
            //     setRoomTypeError('You cant enter duplicate Room Type');
        }

    };
    function AddDataRowForPricing() {
        if (buttonaddeditText === 'Add') {
            if (formvalue.RoomTypeD.trim().length > 0 && Number(formvalue.RoomAllocation) > 0) {
                if (datapricingcounter === 1) {
                    //setHotelRoomPrice(current => [{RoomTypeId : formvalue.RoomTypeD, NoOfAllocation : formvalue.RoomAllocation}])

                    setHotelRoomPrice(current => [{ RoomTypeId: roomtypeid, RoomType: formvalue.RoomTypeD, NoOfAllocation: Number(formvalue.RoomAllocation) }])
                    setPricing(current => [{ SeqNo: datapricingcounter, RoomType: formvalue.RoomTypeD, RoomTypeId: roomtypeid, RoomAllocation: formvalue.RoomAllocation, NoOfPax: '', RoomPrice: '', RoomIsActive: false }]);
                }
                else {
                    setHotelRoomPrice(current => [...current, { RoomTypeId: roomtypeid, RoomType: formvalue.RoomTypeD, NoOfAllocation: Number(formvalue.RoomAllocation) }])
                    setPricing(current => [...current, { SeqNo: datapricingcounter, RoomType: formvalue.RoomTypeD, RoomTypeId: roomtypeid, RoomAllocation: formvalue.RoomAllocation, NoOfPax: '', RoomPrice: '', RoomIsActive: false }]);
                }
            }
        }
        else {
            let len = datapricing?.length || 0;

            if (formvalue.RoomTypeD.trim().length > 0 && Number(formvalue.RoomAllocation) > 0) {
                if (len === 0) {
                    //setHotelRoomPrice(current => [{RoomTypeId : formvalue.RoomTypeD, NoOfAllocation : formvalue.RoomAllocation}])

                    setHotelRoomPrice(current => [{ RoomTypeId: roomtypeid, RoomType: formvalue.RoomTypeD, NoOfAllocation: Number(formvalue.RoomAllocation) }])
                    setPricing(current => [{ SeqNo: datapricingcounter, RoomType: formvalue.RoomTypeD, RoomTypeId: roomtypeid, RoomAllocation: formvalue.RoomAllocation, NoOfPax: '', RoomPrice: '', RoomIsActive: false }]);
                }
                else {
                    setHotelRoomPrice(current => [...current, { RoomTypeId: roomtypeid, RoomType: formvalue.RoomTypeD, NoOfAllocation: Number(formvalue.RoomAllocation) }])
                    setPricing(current => [...current, { SeqNo: datapricingcounter, RoomType: formvalue.RoomTypeD, RoomTypeId: roomtypeid, RoomAllocation: formvalue.RoomAllocation, NoOfPax: '', RoomPrice: '', RoomIsActive: false }]);
                }
            }
        }
    }
    useEffect(() => {
        if (datapricingcounter > 0)
            AddDataRowForPricing();
    }, [datapricingcounter]);

    useEffect(() => {
        setFormValue({ ...formvalue, RoomAllocation: '', RoomTypeD: '' });
        setRoomTypeId(0);
    }, [datapricing]);
    //#endregion

    //#region Room region
    const roomtype = useState([{
        RoomType: '',
        NoofPax: '',
        Price: '',
        RoomActive: '',
        SeqNo: 0
    }]);
    const [dataroomtype, setRoomType] = useState(roomtype);
    const [dataroomtypecounter, setRoomTypeCounter] = useState(0);
    const [roomactiveId, setRoomActiveId] = useState(0);
    const [noofpax, setNoPax] = useState('');
    const [rmnoofpax, setRmNoOfPax] = useState('');
    const [rmtype, setRmType] = useState('');
    const [rmprice, setRmPrice] = useState('');
    const [rmisactive, setRmIsActive] = useState(false);
    const [roompriceaspax, setRoomPriceAsPax] = useState('');

    // const [noofpaxerror, setNoOfPaxError] = useState('');
    // const [roompriceerror, setRoomPriceError] = useState('');

    const [formErrors1, setFormErrors1] = useState([]);

    // const [roomdisabled, setRoomDisabled] = useState(false);
    const AddRoom = (RoomType, NoOfPax, RoomPrice, RoomIsActive, RoomTypeId) => {
        handleresetallfield();
        //setPricing({...datapricing , ['NoOfPax']: '' , ['RoomPrice']: '',['RoomIsActive']: false});
        //setFormValue({...formvalue,['RoomAllocation']:'' ,['RoomTypeD'] : '' });
        if (NoOfPax.trim().length === 0 && RoomPrice.trim().length === 0) {
            let i = (formErrors1.filter((obj) => obj.errorType === 1 && obj.RoomTypeId === RoomTypeId))
            let j = (formErrors1.filter((obj) => obj.errorType === 2 && obj.RoomTypeId === RoomTypeId))
            if (i.length === 0 && j.length === 0) {
                setFormErrors1(current => [...current, { errorMessage: 'Please enter No of Pax', RoomType: RoomType, RoomTypeId: RoomTypeId, errorType: 1 }]);
                setFormErrors1(current => [...current, { errorMessage: 'Please enter Room Price', RoomType: RoomType, RoomTypeId: RoomTypeId, errorType: 2 }]);
            }
        }
        else if (NoOfPax.trim().length > 0 && RoomPrice.trim().length === 0) {
            // setRoomPriceError('Please enter Room Price');
            let i = (formErrors1.filter((obj) => obj.errorType === 2 && obj.RoomTypeId === RoomTypeId))
            if (i.length === 0) {
                setFormErrors1(current => [...current, { errorMessage: 'Please enter Room Price', RoomType: RoomType, RoomTypeId: RoomTypeId, errorType: 2 }]);
            }
        }
        else if (NoOfPax.trim().length === 0 && RoomPrice.trim().length > 0) {
            // setNoOfPaxError('Please enter No of Pax');
            let i = (formErrors1.filter((obj) => obj.errorType === 1 && obj.RoomTypeId === RoomTypeId))
            if (i.length === 0) {
                setFormErrors1(current => [...current, { errorMessage: 'Please enter No of Pax', RoomType: RoomType, RoomTypeId: RoomTypeId, errorType: 1 }]);
            }
        }
        else if ((NoOfPax?.trim().length > 0 && isNaN(NoOfPax)) || (RoomPrice?.trim().length > 0 && isNaN(RoomPrice))) {

            if (NoOfPax?.trim().length > 0 && isNaN(NoOfPax)) {
                let i = (formErrors1.filter((obj) => obj.errorType === 1 && obj.RoomTypeId === RoomTypeId))
                if (i.length === 0) {
                    setFormErrors1(current => [...current, { errorMessage: 'Only Numbers are allowed in this field.', RoomType: RoomType, RoomTypeId: RoomTypeId, errorType: 1 }]);
                }
            }

            if (RoomPrice?.trim().length > 0 && isNaN(RoomPrice)) {
                let j = (formErrors1.filter((obj) => obj.errorType === 2 && obj.RoomTypeId === RoomTypeId))
                if (j.length === 0) {
                    setFormErrors1(current => [...current, { errorMessage: 'Only Numbers are allowed in this field.', RoomType: RoomType, RoomTypeId: RoomTypeId, errorType: 2 }]);

                }
            }


        }

        else if (Number(NoOfPax) > 0 && Number(RoomPrice) > 0) {
            const noofpaxcount = dataroomtype?.filter(obj => obj.RoomType === RoomType && Number(obj.NoofPax) === Number(NoOfPax));

            if (noofpaxcount.length === 0) {

                if (NoOfPax.trim().length === 0 && RoomPrice.trim().length === 0) {
                    let i = (formErrors1.filter((obj) => obj.errorType === 1 && obj.RoomTypeId === RoomTypeId))
                    let j = (formErrors1.filter((obj) => obj.errorType === 2 && obj.RoomTypeId === RoomTypeId))
                    if (i.length === 0 && j.length === 0) {
                        setFormErrors1(current => [...current, { errorMessage: 'Please enter No of Pax', RoomType: RoomType, RoomTypeId: RoomTypeId, errorType: 1 }]);
                        setFormErrors1(current => [...current, { errorMessage: 'Please enter Room Price', RoomType: RoomType, RoomTypeId: RoomTypeId, errorType: 2 }]);
                    }

                }
                else if (NoOfPax.trim().length > 0 && RoomPrice.trim().length === 0) {
                    let i = (formErrors1.filter((obj) => obj.errorType === 2 && obj.RoomTypeId === RoomTypeId))
                    if (i.length === 0) {
                        setFormErrors1(current => [...current, { errorMessage: 'Please enter Room Price', RoomType: RoomType, RoomTypeId: RoomTypeId, errorType: 2 }]);
                    }
                }
                else if (NoOfPax.trim().length === 0 && RoomPrice.trim().length > 0) {
                    let i = (formErrors1.filter((obj) => obj.errorType === 1 && obj.RoomTypeId === RoomTypeId))
                    if (i.length === 0) {
                        setFormErrors1(current => [...current, { errorMessage: 'Please enter No of Pax', RoomType: RoomType, RoomTypeId: RoomTypeId, errorType: 1 }]);
                    }
                }
                else if (NoOfPax.trim().length > 0 && RoomPrice.trim().length > 0) {
                    setRmType(RoomType);
                    setRmNoOfPax(NoOfPax);
                    setRmPrice(RoomPrice);
                    setRmIsActive(RoomIsActive);
                    setRoomTypeCounter(dataroomtypecounter + 1);
                }
            }
            else {
                // let i = (formErrors1.filter((obj) => obj.errorType === 1 && obj.RoomTypeId === RoomTypeId))
                let j = (formErrors1.filter((obj) => obj.errorType === 1 && obj.RoomType === RoomType && obj.RoomTypeId === RoomTypeId))
                if (j.length === 0) {
                    setFormErrors1(current => [...current, { errorMessage: 'Pax count price already added', RoomType: RoomType, RoomTypeId: RoomTypeId, errorType: 1 }]);

                }
            }
        }
        else {

            setFormErrors1(current => [...current, { errorMessage: 'Please enter no of pax and price grater than zero', RoomType: RoomType, RoomTypeId: RoomTypeId, errorType: 1 }]);
            return;
        }

    };
    function AddDataRowForRoom() {
        if (buttonaddeditText === 'Add') {
            if (noofpax.trim().length > 0 && roompriceaspax.trim().length > 0) {
                if (dataroomtypecounter === 1) {
                    //setHotelRoomPrice(current => [{RoomType : formvalue.RoomTypeD, RoomAllocation : formvalue.RoomAllocation}])
                    setRoomType(current => [{ SeqNo: dataroomtypecounter, RoomType: rmtype, NoofPax: rmnoofpax, Price: rmprice, RoomActive: rmisactive }]);
                }
                else {
                    //setHotelRoomPrice(current => [{RoomType : formvalue.RoomTypeD, RoomAllocation : formvalue.RoomAllocation}])
                    setRoomType(current => [...current, { SeqNo: dataroomtypecounter, RoomType: rmtype, NoofPax: rmnoofpax, Price: rmprice, RoomActive: rmisactive }]);
                }

            }
        }
        else {
            let len = dataroomtype?.length || 0;
            if (noofpax.trim().length > 0 && roompriceaspax.trim().length > 0) {
                if (len === 0) {
                    //setHotelRoomPrice(current => [{RoomType : formvalue.RoomTypeD, RoomAllocation : formvalue.RoomAllocation}])
                    setRoomType(current => [{ SeqNo: dataroomtypecounter, RoomType: rmtype, NoofPax: rmnoofpax, Price: rmprice, RoomActive: rmisactive }]);
                }
                else {
                    //setHotelRoomPrice(current => [{RoomType : formvalue.RoomTypeD, RoomAllocation : formvalue.RoomAllocation}])
                    setRoomType(current => [...current, { SeqNo: dataroomtypecounter, RoomType: rmtype, NoofPax: rmnoofpax, Price: rmprice, RoomActive: rmisactive }]);
                }

            }
        }


        //
    }
    useEffect(() => {
        if (dataroomtypecounter > 0)
            AddDataRowForRoom();
    }, [dataroomtypecounter]);
    useEffect(() => {

    }, [dataroomtype]);

    const handleChange = (index, evnt) => {
        handleresetallfield();
        setFormErrors1([]);
        const { name, value } = evnt.target;
        const list = [...datapricing];

        if ([evnt.target.name][0] === 'NoOfPax') {
            list[index][name] = onlyNumeric(value);
            setNoPax(onlyNumeric(value));
        }
        if ([evnt.target.name][0] === 'RoomPrice') {
            list[index][name] = NumericWithDecimal(value);
            setRoomPriceAsPax(value);
        }

        setPricing(list);

    }
    const handleRoomisActive = (index, evnt) => {
        const l = { name: 'RoomIsActive', value: evnt }
        const { name, value } = l;
        const list = [...datapricing];
        list[index][name] = value;
        setRoomActiveId(!roomactiveId);
        setPricing(list);

        handleresetallfield();
        setFormErrors1([]);
    }

    const Roomsremovev = (id) => {
        handleresetallfield();
        setRoomType((oldState) => oldState?.filter((item) => item.SeqNo !== id));
    };

    const uploadimagelist = useState([{
        id: 0,
        roomtype: '',
        title: '',
        imageurl: '',
        imgpath: '',
        upimage: '',
        isfocus: false
    }]);
    const [uploadimage, setUploadImage] = useState(uploadimagelist);
    const [countimg, setCountImg] = useState(1);
    const [deletefilelist, setDeleteFileList] = useState([]);
    const [imgcounterror, setImageCountError] = useState('');
    const [imageRoomError, setImageRoomError] = useState('');

    const handleUploadRoomImageLoad = (RoomType, e) => {
        if (isValidImage(e.target.files[0].name)) {
            handleresetallfield();
            const files = e.target.files;
            const filecom = getDateCombination();
            const filename = filecom + '_' + '1' + '_' + (files[0].name.replace(/ /g, "_")).replace(/-/g, '');
            let lenlist = uploadimage?.filter(obj => obj.roomtype === RoomType);
            setCountImg(countimg + 1);
            if (lenlist?.length < 10) {
                if (buttonaddeditText === 'Add') {
                    if (countimg === 1)
                        setUploadImage(current => [{ id: countimg, roomtype: RoomType, title: filename, imgpath: 'CMS/Hotel/' + filename, imageurl: URL.createObjectURL(files[0]), upimage: files[0] }]);
                    else
                        setUploadImage(current => [...current, { id: countimg, roomtype: RoomType, title: filename, imgpath: 'CMS/Hotel/' + filename, imageurl: URL.createObjectURL(files[0]), upimage: files[0] }]);
                }
                else {
                    let cnt = uploadimage?.length || 0;

                    if (cnt === 0)
                        setUploadImage(current => [{ id: countimg, roomtype: RoomType, title: filename, imgpath: 'CMS/Hotel/' + filename, imageurl: URL.createObjectURL(files[0]), upimage: files[0] }]);
                    else {
                        setUploadImage(current => [...current, { id: countimg, roomtype: RoomType, title: filename, imgpath: 'CMS/Hotel/' + filename, imageurl: URL.createObjectURL(files[0]), upimage: files[0] }]);
                    }
                }

            }
            else {
                setImageCountError('You can upload only 10 Image');
            }

            setBannerDelImgPath('');
            setImageRoomError('');
            setDuplicateDataError('');
        }
        else {
            document.querySelector(".txtIncorporationCertiPath").value = "";
            setFormValue({ ...formvalue, imgpath: '' });
            setBannerDelImgPath('');
            setFormErrors('');
            setDuplicateDataError('');
            setImageRoomError("Please select Only Image")
            setIsSubmit(false);
            return;
        }



    }
    useEffect(() => {

    }, [uploadimage]);

    const removeRoomImage = (id, e) => {
        handleresetallfield();

        if (buttonaddeditText !== 'Add') {
            uploadimage?.map((obj) => {

                if (obj.id === id) {
                    setDeleteFileList([...deletefilelist, { FileName: obj.imgpath }])
                }
            });
        }
        setUploadImage((oldState) => oldState?.filter((item) => item.id !== id));
        setEditDisabled(false);
        document.querySelector(".txtIncorporationCertiPath").value = "";
        //setCountImg(countimg-1);
    };
    //#endregion

    //#region Submit , insert , edit
    const CityId = useRef(null);
    const RoomTypeEmptyError = useRef(null);

    const handleSubmit = async (e) => {
        let statusoftoken = await verifytoken();
        if (buttonaddeditText === 'Add') {
            if (statusoftoken) {
                const hoteldata = await postUsersData('/master/v1/inserthotel', formvalue);
                let status = false;
                let data = false;
                status = hoteldata.status;
                data = hoteldata.data;
                if (!status && data === null) {
                    dispatch({ type: 'ALERTINGMESSAGE', message: hoteldata.message })
                    // dispatch({ type: 'ALERTING', message: 'Try again Later!' })
                    // alert('Try again Later!');
                    resetDisableState();
                    setIsSubmit(false);
                    return;
                }
                else if (!status && !data) {
                    setDuplicateDataError(hoteldata.message);
                    resetDisableState();
                    setIsSubmit(false);
                    return;
                }
                else {
                    if (status && data > 0) {
                        try {
                            try {
                                await handleimageupload('CMS/Hotel/', bannerdoclist[0].DocName, bannerImage);
                            }

                            catch (err) {
                                // alert(err)
                                dispatch({ type: 'ALERTINGMESSAGE', message: err })
                                // dispatch({ type: 'ALERTING', message: err })
                                setDisabled(false);
                                setBannerImgDisable(false);
                                setBannerVideoDisable(false);
                                setEditDisabled(false);
                                return;
                            }
                            try {
                                const bannerlistx = bannerdoclist?.filter(obj => obj.DocType === 2).map(obj => {
                                    return obj.DocName?.trim()?.length;
                                });
                                if (bannerlistx?.length > 0) {
                                    await handleimageupload('CMS/Hotel/', bannerdoclist[1].DocName, bannerVideo);
                                }
                                // await handleimageupload('CMS/Hotel/', bannerdoclist[1].DocName, bannerVideo);
                            }
                            catch (err) {
                                // alert(err);
                                dispatch({ type: 'ALERTINGMESSAGE', message: err })
                                // dispatch({ type: 'ALERTING', message: err })
                                setDisabled(false);
                                setBannerImgDisable(false);
                                setBannerVideoDisable(false);
                                setEditDisabled(false);
                                return;
                            }
                            for (let i = 0; i < uploadimage.length; i++) {
                                const path = 'CMS/Hotel/';
                                await handleimageupload(path, uploadimage[i].title, uploadimage[i].upimage);
                            }
                            //setitems();
                            setIsSubmit(false);
                            dispatch({ type: 'ALERTINGMESSAGE', message: hoteldata.message })
                            // dispatch({ type: 'ALERTING', message: 'Record saved successfully.' })
                            // alert('Record saved successfully.');
                            // clear();
                            // navigate('/Hotellistmaster');
                        }
                        catch (err) {
                            dispatch({ type: 'ALERTINGMESSAGE', message: 'Image is not uploading successfully' })
                            // dispatch({ type: 'ALERTING', message: 'Image is not uploading successfully' })
                            // alert('Image is not uploading successfully');
                            setIsSubmit(false);
                            resetDisableState();
                            return;
                        }

                    }
                    else {
                        dispatch({ type: 'ALERTINGMESSAGE', message: hoteldata.message })
                        // dispatch({ type: 'ALERTING', message: 'Record not saved successfully' })
                        // alert('Record not saved successfully');
                        resetDisableState();
                        setIsSubmit(false);
                        return;
                    }
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                // alert('Your Session Expired. Please Login Again!');
            }
        }
        else {
            if (statusoftoken) {
                const updatehoteldata = await patchUsersData('/master/v1/updatehotel', formvalue);
                let updatestatus = false;
                let updatedata = false;
                updatestatus = updatehoteldata.status;
                updatedata = updatehoteldata.data;

                if (!updatestatus && updatedata === null) {
                    // alert('Try again Later!');
                    dispatch({ type: 'ALERTINGMESSAGE', message: updatehoteldata.message })
                    // dispatch({ type: 'ALERTING', message: 'Try again Later!' })
                    setIsSubmit(false);
                    return;
                }
                if (!updatestatus && !updatedata) {
                    setDuplicateDataError('You can not enter duplicate values.');
                    setIsSubmit(false);
                    return;
                }
                if (updatestatus && updatedata > 0) {
                    try {
                        const bannerlistx = bannerdoclist?.filter(obj => obj.DocType === 2).map(obj => {
                            return obj.DocName
                        });

                        const bannerimgsx = bannerdoclist?.filter(obj => obj.DocType === 1).map((obj, index) => {
                            return obj.DocName;
                        });
                        if (bannerimgname.length === 0) {
                            await handleimageupload('CMS/Hotel/', bannerimgsx[0], bannerImage);
                        }
                        if (bannerlistx?.length > 0) {
                            await handleimageupload('CMS/Hotel/', bannerlistx[0], bannerVideo);
                        }
                    }
                    catch (err) {
                        // alert(err)
                        dispatch({ type: 'ALERTINGMESSAGE', message: err })
                        // dispatch({ type: 'ALERTING', message: err })
                        setDisabled(false);
                        setBannerImgDisable(false);
                        setBannerVideoDisable(false);
                        setEditDisabled(false);
                        return;
                    }
                    try {
                        if (bannerimgname?.length === 0) {
                            await handleimageDelete(bannerdelimgpath);
                        }
                        if (bannervideoname?.length === 0) {
                            await handleimageDelete(bannerdelvideopath);
                        }
                    }
                    catch (err) {
                        // alert(err)
                        dispatch({ type: 'ALERTINGMESSAGE', message: err })
                        // dispatch({ type: 'ALERTING', message: err })
                        setDisabled(false);
                        setBannerImgDisable(false);
                        setBannerVideoDisable(false);
                        setEditDisabled(false);
                        return;
                    }
                    const deletefilelistlen = deletefilelist?.length || 0;
                    for (let i = 0; i < deletefilelistlen; i++) {
                        try {
                            // const path = 'CMS/Hotel/';

                            await handleimageDelete(deletefilelist[i].FileName);
                        }
                        catch (err) {
                            // alert(err)
                            dispatch({ type: 'ALERTINGMESSAGE', message: err })
                            // dispatch({ type: 'ALERTING', message: err })
                            setDisabled(false);
                            setBannerImgDisable(false);
                            setBannerVideoDisable(false);
                            setEditDisabled(false);
                            return;
                        }

                    }
                    const uplimglist = uploadimage?.filter((obj) => obj.upimage !== '')
                    for (let i = 0; i < uplimglist?.length; i++) {
                        try {
                            const path = 'CMS/Hotel/';
                            await handleimageupload(path, uplimglist[i].title, uplimglist[i].upimage);
                        }
                        catch (err) {
                            // alert(err)
                            dispatch({ type: 'ALERTINGMESSAGE', message: err })
                            // dispatch({ type: 'ALERTING', message: err })
                            setDisabled(false);
                            setBannerImgDisable(false);
                            setBannerVideoDisable(false);
                            setEditDisabled(false);
                            return;
                        }

                    }

                    //setitems();
                    dispatch({ type: 'ALERTINGMESSAGE', message: updatehoteldata.message })
                    // dispatch({ type: 'ALERTING', message: 'Record Updated successfully.' })
                    // alert('Record Updated successfully.');
                    // clear();
                    // navigate('/Hotellistmaster');

                }
                else {
                    // alert('Record not Updated successfully');
                    dispatch({ type: 'ALERTINGMESSAGE', message: updatehoteldata.message })
                    // dispatch({ type: 'ALERTING', message: 'Record not Updated successfully' })
                    resetDisableState();
                    setIsSubmit(false);
                    return;
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                // alert('Your Session Expired. Please Login Again!');
            }
        }
    }
    useEffect(() => {
        if (Object.keys(formErrors)?.length === 0 && isSubmit) {
            formvalue.HotelPricing = hotelroomprice
            formvalue.HotelContactNo = datahotelcontactnocollection?.map(obj => ({
                MobileNo: obj.ContactNumber
            }))
            formvalue.HotelReservation = datareservationspokedetails?.map(obj => ({
                Name: obj.ReservationName,
                MobileNo: obj.ReservationNumber
            }))
            formvalue.HotelManagementContactNo = datamanagementcontactno?.map(obj => ({
                Name: obj.ManagementName,
                MobileNo: obj.ManagementNumber
            }))
            formvalue.HotelEmail = datahotelemailcollection?.map(obj => ({
                EmailId: obj.HotelEmail
            }))
            formvalue.HotelDocument = bannerdoclist?.filter((item) => item.DocPath !== '')?.map(obj => ({
                DocumentPath: obj.DocPath,
                DocumentName: obj.DocName,
                DocumentType: obj.DocType
            }))


            setBackDisable(true);
            handleSubmit();

        }

    }, [hotelroomprice]);

    const onSubmitForm = (e) => {
        e.preventDefault();
        setFormErrors(ValidateData(formvalue));
    }

    useEffect(() => {
        if (Object.keys(formErrors)?.length === 0 && isSubmit) {
            setDisabled(true);
            setBannerImgDisable(true);
            setBannerVideoDisable(true);
            setEditDisabled(true);
            setAddContactNoDisable(true);
            setHotelEmailAddDisable(true);
            handleDocumentCollection();
        }

        if (Object.keys(formErrors)?.length > 0) {
            let id = Object.keys(formErrors)[0];

            if (id === "CityId" || id === "RoomTypeEmptyError") {
                if (roomtypeid === 0) {
                    eval(id).current.focus();
                }
                else {
                    let doc1 = document.getElementById("RoomAllocation");
                    if (doc1 !== null)
                        doc1.focus();
                }
            }
            else {
                let doc = document.getElementById(id);
                if (doc !== null)
                    doc.focus();
            }

        }
    }, [formErrors]);

    useEffect(() => {

    }, [isSubmit]);

    const handleDocumentCollection = () => {


        datapricing.forEach((price) => {

            const pricing = dataroomtype?.filter(value => value.RoomType === price.RoomType)?.map(obj => ({
                NoOfPax: Number(obj.NoofPax), Price: Number(obj.Price), IsActive: obj.RoomActive

            }));
            setHotelRoomPrice(prevState => {
                const newState = prevState?.map(obj => {
                    // 👇️ if id equals 2, update country property
                    if (obj.RoomType === price.RoomType) {
                        return { ...obj, RoomPricingDetail: pricing };
                    }

                    // 👇️ otherwise return object as is
                    return obj;
                });

                return newState;
            })


        })

        datapricing.forEach((price) => {
            const upimg = uploadimage?.filter(value => value.roomtype === price.RoomType)?.map(obj => ({
                PhotoPath: obj.imgpath, PhotoName: obj.title, SequenceId: Number(obj.id)
            }));

            setHotelRoomPrice(prevState => {
                const newState = prevState?.map(obj => {
                    // 👇️ if id equals 2, update country property
                    if (obj.RoomType === price.RoomType) {
                        return { ...obj, RoomPhotos: upimg };
                    }

                    // 👇️ otherwise return object as is
                    return obj;
                });

                return newState;
            })
        })

    }
    //#endregion

    //#region  handle image upload to server and delete
    const handleimageupload = async (pathdirectory, filename, file) => {
        await ImageUpload(pathdirectory, filename, file);
    }
    const handleimageDelete = async (filename) => {
        await ImageDelete(filename);
    }
    //#endregion

    //#region  handle retrive data
    const handleRetrive = async () => {
        setButtonAddEditText("Update");
        const HotelId = hotel?.state?.HotelId;
        const HotelById = await postUsersData('/master/v1/gethotelbyid', { HotelId });
        setRetriveData(HotelById.data);
    }


    useEffect(() => {

        if (retrivedata?.length === 1) {
            RetriveData();
            dispatch(loaderActions.end());
        }

    }, [retrivedata]);


    const [bannerimgpath, setBannerImgPath] = useState('');
    const [bannerimgname, setBannerImgName] = useState('');
    const [bannerdelimgpath, setBannerDelImgPath] = useState('');

    const [bannervideopath, setBannerVideoPath] = useState('');
    const [bannerdelvideopath, setBannerDelVideoPath] = useState('');
    const [bannervideoname, setBannerVideoName] = useState('');
    // const [roomtyperetriveimg, setRoomTypeRetriveImage] = useState([]);


    const RetriveData = () => {
        document.getElementById("txtbannerimages").value = "";
        document.getElementById("txtbannerVideo").value = "";

        setBannerImage('');
        setBannerVideo('');
        setFormErrors('');
        setImageError('');
        setVideoError('');
        setImageRoomError('');
        setImageCountError('');
        setDuplicateDataError('');

        retrivedata?.map(obj => {
            formvalue.HotelId = obj.hotelId
            formvalue.Name = obj.name
            formvalue.Address = obj.address
            formvalue.Area = obj.area
            formvalue.LocationId = obj.locationId
            formvalue.Rating = obj.rating
            formvalue.GoogleLocation = obj.googleLocation
            formvalue.IsActive = obj.isActive
            formvalue.HotelContactNoList = obj.hotelContactNo
            formvalue.HotelReservationList = obj.hotelReservation
            formvalue.HotelManagementContactNoList = obj.hotelManagementContactNo
            formvalue.HotelEmailList = obj.hotelEmail
            formvalue.HotelDocumentList = obj.hotelDocument
            formvalue.HotelPricingList = obj.hotelPricing
            // Check In-Active Data and add in Dropdown...
            let allcitylist = allcity?.filter(IsAct => IsAct.IsActive === true);
            setCityData(allcity?.filter(IsAct => IsAct.IsActive === true));
            setCityDisable(false);
            if (!allcitylist?.find(IsCity => IsCity.LocationId === obj.locationId)) {
                setCityDisable(true)
                setCityData((prvCityData) => prvCityData?.length > 0 ? [...prvCityData, allcity?.find(isCity => isCity.LocationId === obj.locationId)] : [])
            }
            setActiveId(obj.isActive);
            setRating(obj.rating);
            setCityId(obj.locationId);

        });

        const HotelcontactNo = formvalue.HotelContactNoList?.map((obj, noseqno) => ({
            ContactNumber: obj.mobileNo,
            SeqNo: noseqno + 1
        }));

        formvalue.HotelContactNo = HotelcontactNo
        setHotelContactNoDataCollection(HotelcontactNo);
        setHotelContactNoCounter(HotelcontactNo.length);

        const ReservSpokeDetail = formvalue.HotelReservationList?.filter(value => value.name !== '')?.map((obj, reseqno) => ({
            ReservationName: obj.name,
            ReservationNumber: obj.mobileNo,
            SeqNo: reseqno + 1
        }));

        formvalue.HotelReservation = ReservSpokeDetail
        setReservationSpokeDetails(ReservSpokeDetail);
        setReservationSpokeCounter(ReservSpokeDetail.length);

        const managementno = formvalue.HotelManagementContactNoList?.filter(value => value.name !== '')?.map((obj, manseqno) => ({
            ManagementName: obj.name,
            ManagementNumber: obj.mobileNo,
            SeqNo: manseqno + 1
        }));
        formvalue.HotelManagementContactNo = managementno
        setManagementContactNo(managementno);
        setManagementContactCounter(managementno.length);

        const hotelemail = formvalue.HotelEmailList?.map((obj, emailsqno) => ({
            HotelEmail: obj.emailId,
            SeqNo: emailsqno + 1
        }));
        formvalue.HotelEmail = hotelemail
        setHotelEmailDataCollection(hotelemail);
        setHotelEmailCounter(hotelemail?.length);

        formvalue.HotelDocumentList?.map((obj, index) => {
            if (obj.documentType === 1) {
                setBannerImgPath('https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + obj.documentPath);
                setBannerImgName(obj.documentName);
                setBannerDelImgPath(obj.documentPath);
                setBannerCount(index + 1);
            }
            if (obj.documentType === 2) {
                setBannerVideoPath('https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + obj.documentPath);
                setBannerVideoName(obj.documentName);
                setBannerDelVideoPath(obj.documentPath);
                setBannerCount(index + 1);
            }
        })
        let cntbanner = 0;

        let bannerdoc = [];
        for (const childone of formvalue.HotelDocumentList) {
            cntbanner = cntbanner + 1

            bannerdoc = [...bannerdoc,
            {
                'DocName': childone.documentName, 'DocPath': childone.documentPath, 'DocType': childone.documentType
            }
            ]

        }

        setBannerDocList(bannerdoc);
        const bennerimgpath = formvalue.HotelDocumentList?.map((obj) => ({
            DocumentPath: obj.documentPath,
            DocumentName: obj.documentName,
            DocumentType: obj.documentType
        }));


        formvalue.HotelDocument = bennerimgpath

        let price = formvalue?.HotelPricingList?.map(value => ({
            hotelPricingId: value.hotelPricingId,
            roomTypeId: value.roomTypeId,
            noOfAllocation: value.noOfAllocation
        }))



        let roompricing = formvalue?.HotelPricingList?.map(value => {
            return value.roomPricingDetail
        });



        let roomimg = formvalue?.HotelPricingList?.map(value => {
            return value.roomPhotos
        });
        let cnt = 0;

        let hotelpricinglist = [];
        let pricinglist = [];
        let roompricelist = [];
        let roomimglist = [];

        for (const child of price) {
            const roomname = hotelroomtypedata?.filter(obj => obj.RoomTypeId === child.roomTypeId)?.map(value => {
                return value.RoomTypeName
            });

            cnt = cnt + 1;

            pricinglist =
                [...pricinglist,
                {
                    'SeqNo': cnt, 'RoomType': roomname[0], 'RoomTypeId': child.roomTypeId,
                    'RoomAllocation': child.noOfAllocation,
                    'NoOfPax': '', 'RoomPrice': '', 'RoomIsActive': false
                }
                ]
            hotelpricinglist =
                [...hotelpricinglist,
                {
                    'RoomTypeId': child.roomTypeId,
                    'RoomType': roomname[0],
                    'NoOfAllocation': Number(child.noOfAllocation)
                }
                ]

            const len = roompricing?.length;
            let rpcnt = 0;
            for (let index = 0; index < len; index++) {
                for (let child1 of roompricing[index]) {
                    rpcnt = rpcnt + 1;
                    if (child1.hotelPricingId === child.hotelPricingId) {
                        roompricelist = [...roompricelist, {
                            'RoomType': roomname[0],
                            'NoofPax': child1.noOfPax,
                            'Price': child1.price,
                            'RoomActive': child1.isActive,
                            'SeqNo': rpcnt
                        }]
                    }


                }
            }
            const lenimg = roomimg?.length;
            let lencnt = 0
            for (let imgindex = 0; imgindex < lenimg; imgindex++) {

                for (let child2 of roomimg[imgindex]) {
                    lencnt = lencnt + 1
                    if (child2.hotelPricingId === child.hotelPricingId) {
                        setCountImg(countimg + 1)
                        roomimglist = [...roomimglist, {
                            'id': lencnt,
                            'roomtype': roomname[0],
                            'title': child2.photoName,
                            'imageurl': 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + child2.photoPath,
                            'imgpath': child2.photoPath,
                            'upimage': '',
                            'isfocus': false
                        }]
                    }

                }

            }

        }
        //datapricing
        setHotelRoomPrice(hotelpricinglist);
        setPricing(pricinglist);
        setPricingCounter(pricinglist?.length);
        setRoomType(roompricelist);
        setRoomTypeCounter(roompricelist?.length);
        setUploadImage(roomimglist);
        // commented by Aishvarya on 5-06-2023 because there state value never used
        // setRoomTypeRetriveImage(roomimglist);

        if (formvalue.HotelDocumentList?.filter(IsVideo => IsVideo.documentType === 2)?.length === 0) {
            if (hotel?.state?.viewtype !== 'View') {
                setBannerVideoDisable(false);
            }
        }
    }
    //#endregion

    //#region validate all section

    const ValidateData = (values) => {
        const error = {}
        setIsSubmit(true);

        if (values.LocationId === 0) {
            error.CityId = "Please select city";
            setIsSubmit(false);
        }
        if (values.Name?.trim()?.length === 0) {
            error.HotelName = "Please enter Hotel Name";
            setIsSubmit(false);
        }
        if (values.Name?.trim()?.length > 50) {
            error.HotelName = "Maximum length is 50 characters.";
            setIsSubmit(false);
        }
        if (values.Rating === 0) {
            error.Rating = "Please select rating.";
        }
        if (values.Address?.trim()?.length === 0) {
            error.HotelAddress = "Please enter Hotel Address";
            setIsSubmit(false);
        }
        if (values.Address?.trim()?.length > 250) {
            error.HotelAddress = "Maximum length is 250 characters.";
            setIsSubmit(false);
        }
        if (values.GoogleLocation?.trim()?.length === 0) {
            error.GoogleLocation = "Please enter Google Location";
            setIsSubmit(false);
        }
        if (values.Area?.trim()?.length === 0) {
            error.AreaLandmark = "Please enter Area/Landmark";
            setIsSubmit(false);
        }
        if (values.Area?.trim()?.length > 50) {
            error.AreaLandmark = "Maximum length is 50 characters.";
            setIsSubmit(false);
        }
        const hotelcontact = datahotelcontactnocollection?.filter(obj => {
            return obj.SeqNo > 0;
        });
        const hotelemail = datahotelemailcollection?.filter(obj => {
            return obj.SeqNo > 0;
        });
        // const roompricinglist = dataroomtype?.filter(obj => {
        //     return obj.SeqNo > 0;
        // });
        // const imglist = uploadimage?.filter(obj => {
        //     return obj.id > 0;
        // });
        //const bannerlistx = bannerdoclist?.filter(obj => obj.DocType === 1);
        const bannerlistx = bannerdoclist?.filter(obj => obj.DocType === 1).map(obj => {
            return obj.DocName?.trim()?.length;
        });
        if (hotelcontact?.length === 0) {
            // setHotelContactError('Please add at least one hotel contact number');
            error.HotelContactNumber = "Please add at least one hotel contact number";
            setIsSubmit(false);
        }
        // else {
        //     setHotelContactError('');
        // }

        if (hotelemail?.length === 0) {
            // setHotelEmailListError('Please add at least one hotel email');
            error.HotelEmailD = "Please add at least one hotel email"
            setIsSubmit(false);
        }
        // else {
        //     setHotelEmailListError('');
        // }
        if (bannerlistx?.length !== 1) {
            // setBannerImgVideoError('Please select Banner Image');
            error.txtbannerimages = "Please select Banner Image"
            setIsSubmit(false);
        }
        // else {
        //     setBannerImgVideoError('');
        // }

        let datapricingcount = datapricing?.filter(obj => obj.SeqNo > 0);

        if (datapricingcount?.length === 0) {
            //setPriceRoomError('Please enter at least one room type details');
            error.RoomTypeEmptyError = 'Please add atleast one Room type details';
            setIsSubmit(false);
        }

        const eqSet = (xs, ys) => [...xs].every((x) => ys.includes(x));

        let roomtypelist = datapricing?.map(obj => {
            return obj.RoomType
        });
        //let roompricelist = new Map();
        let roompricelist = dataroomtype?.map(obj => {
            return obj.RoomType
        });

        let uplimg = uploadimage?.map(obj => {
            return obj.roomtype
        })

        let roompriceflag = eqSet(roomtypelist, roompricelist);
        let roomimgflag = eqSet(roomtypelist, uplimg);

        if (!roompriceflag || !roomimgflag) {
            setPriceRoomError("Please enter proper detail for Room and Price");
            setIsSubmit(false);
        }
        else {
            setPriceRoomError('');
        }

        //setBannerImgVideoError
        return error;
    }

    //#endregion

    //#region Clear form
    const handlebackClick = () => {
        clear();
        navigate('/Hotellistmaster');
    }
    const handleClear = () => {
        clear();
    }
    function clear() {

        setIsSubmit(false);
        setButtonAddEditText('Add');
        resetDisableState();
        setHotelRoomPrice([]);
        setFormValue({
            ...formvalue, HotelId: 0, LocationId: 0, Rating: 0,
            Name: '', Address: '', GoogleLocation: '', Area: '',
            IsActive: false, HotelContactNo: [], HotelReservation: [],
            HotelManagementContactNo: [], HotelEmail: [], HotelDocument: [],
            HotelPricing: [], HotelContactNumber: '', ReservationNameD: '',
            ReservationNumberD: '', ManagementNameD: '', ManagementNumberD: '',
            HotelEmailD: '', RoomTypeD: '', RoomAllocation: 0, UserId: 0
        });
        setPricing([]);
        setRoomType([]);
        setBannerDocList([]);
        setUploadImage([]);
        // setNoOfPaxError('');
        // setRoomPriceError('');
        setFormErrors1([]);
        setSpokeNumberError('');
        setSpokeNameError('');
        setManagementNameError('');
        setManagementNumberError('');
        setHotelEmailError('');
        setHotelContactNoError('');
        setRoomTypeError('');
        setRoomAllocationError('');
        setPriceRoomError('');
        setFormErrors('');
        setImageError('');
        setVideoError('');
        setImageRoomError('');
        setImageCountError('');
        setCityId(0);
        setHotelContactNoDataCollection([]);
        setReservationSpokeDetails([]);
        setManagementContactNo([]);
        setHotelEmailDataCollection([]);
        setBannerImage();
        setBannerVideo();
        setRating(0);
        resetDisableState();
        setDuplicateDataError('');
        document.getElementById("txtbannerimages").value = "";
        document.getElementById("txtbannerVideo").value = "";
        setActiveId(false);
        setDuplicateDataError('');
        setCityDisable(false);
    }
    function resetDisableState() {
        setDisabled(false);
        setBannerImgDisable(false);
        setBannerVideoDisable(false);
        setEditDisabled(false);
        setAddContactNoDisable(false);
        setAddManagementNoDisable(false);
        setAddSpokeNoDisable(false);
        setHotelEmailAddDisable(false);
        setBackDisable(false);
        setClearDisable(false);

    }
    //#endregion

    const onChange = (e) => {

        handleresetallfield();
        setFormErrors1([]);
        var result;
        if ([e.target.name][0] === 'HotelContactNumber' || [e.target.name][0] === 'ReservationNumberD' || [e.target.name][0] === 'ManagementNumberD') {
            result = PhoneNmber(e.target.value);
            //result = normalizeInput(e.target.value, formvalue.HotelContactNumber) 
        }
        else if ([e.target.name][0] === 'ReservationNameD' || [e.target.name][0] === 'ManagementNameD') {
            result = onlyAlphabets(e.target.value)
        }

        else if ([e.target.name][0] === 'RoomAllocation') {
            result = Number(onlyNumeric(e.target.value));
        }
        else {
            result = e.target.value;
        }
        setFormValue({ ...formvalue, [e.target.name]: result });
        setDuplicateDataError('');

    }


    return (
        <>

            <div class="container mt-4 heading_color">
                <h3>Hotel Master</h3>
            </div>
            <div class="container form_border py-5 my-3">
                <form action="" >
                    <div class="mb-3 row mx-1">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            {duplicatedataerror && <p className='text-danger text-center'>{duplicatedataerror}</p>}
                            <div class="row">

                                <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                                    <div class="col-lg-12 col-md-12">
                                        <label for="inputPassword6" class="col-form-label required">Continent</label>
                                    </div>
                                    <div class="col-lg-12 mb-1 col-md-12">
                                        <Select className="dropdown select_dropdown"
                                            isDisabled
                                            value={continentList?.find(({ value }) => value === cityid)}
                                            options={continentList} />
                                    </div>
                                </div>
                                <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                                    <div class="col-lg-12 col-md-12">
                                        <label for="inputPassword6" class="col-form-label required">Country</label>
                                    </div>
                                    <div class="col-lg-12 mb-1 col-md-12">
                                        <Select className="dropdown select_dropdown"
                                            isDisabled
                                            value={countryList?.find(({ value }) => value === cityid)}
                                            options={countryList} />
                                    </div>
                                </div>
                                <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                                    <div class="col-lg-12 col-md-12">
                                        <label for="inputPassword6" class="col-form-label required">Region</label>
                                    </div>
                                    <div class="col-lg-12 mb-1 col-md-12">
                                        <Select className="dropdown select_dropdown"
                                            isDisabled
                                            value={regionList?.find(({ value }) => value === cityid)}
                                            options={regionList} />
                                    </div>
                                </div>
                                <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                                    <div class="col-lg-12 col-md-12">
                                        <label for="inputPassword6" class="col-form-label required">State</label>
                                    </div>
                                    <div class="col-lg-12 mb-1 col-md-12">
                                        <Select className="dropdown select_dropdown"
                                            isDisabled
                                            value={stateList?.find(({ value }) => value === cityid)}
                                            options={stateList} />
                                    </div>
                                </div>
                                <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                                    <div class="col-lg-12 col-md-12">
                                        <label for="inputPassword6" class="col-form-label required">City</label>
                                    </div>
                                    <div class="col-lg-12 mb-1 col-md-12">
                                        {/* <Select className="dropdown select_dropdown"
                                            options={cityList}
                                            value={cityList?.filter(function (cityList) {
                                                return cityList.value === cityid;
                                            })}// set list of the data
                                            onChange={handlecity}// assign onChange function
                                            isDisabled={disabled ? true : false}
                                        /> */}
                                        <CustomSelection
                                            selId="CityId"
                                            selRef={CityId}
                                            datalist={cityList}
                                            fieldid={cityid}
                                            SelectionChange={handlecity}
                                            isdisable={disabled}
                                            isactivedisable={citydisable}
                                            issearchable={true}
                                        />
                                        {formErrors.CityId && <p className='text-danger text-start'>{formErrors.CityId}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-1 mt-4 col-lg-11 mx-lg-5">
                        <div className='row'>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label required">Hotel Name</label>
                                    </div>
                                    <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <input type="text" id="HotelName" placeholder='Hotel Name' name='Name' value={formvalue.Name} onChange={onChange} class="form-control" disabled={disabled ? true : false} />
                                        {formErrors.HotelName && <p className='text-danger text-start'>{formErrors.HotelName}</p>}
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label required">Address</label>
                                    </div>
                                    <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <textarea rows={3} type="text" placeholder='Address' name='Address' id="HotelAddress" class="form-control" value={formvalue.Address} onChange={onChange} disabled={disabled ? true : false} />
                                        {formErrors.HotelAddress && <p className='text-danger text-start'>{formErrors.HotelAddress}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6'>
                                <div className='row'>
                                    <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label required">Rating</label>
                                    </div>
                                    <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1" id="Rating">
                                        <Rating
                                            onClick={handleRating}
                                            size={50}
                                            allowFraction
                                            readonly={disabled ? true : false}
                                            initialValue={rating}
                                        />
                                        {formErrors.Rating && <p className='text-danger text-start'>{formErrors.Rating}</p>}
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label required">Google Location</label>
                                    </div>
                                    <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <input type="text" placeholder='Google Location' id="GoogleLocation" name='GoogleLocation' value={formvalue.GoogleLocation} onChange={onChange} class="form-control" disabled={disabled ? true : false} />
                                        {formErrors.GoogleLocation && <p className='text-danger text-start'>{formErrors.GoogleLocation}</p>}
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label required">Area/ Landmark</label>
                                    </div>
                                    <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <input type="text" placeholder='Area/Landmark' id="AreaLandmark" class="form-control" name='Area' value={formvalue.Area} onChange={onChange} disabled={disabled ? true : false} />
                                        {formErrors.AreaLandmark && <p className='text-danger text-start'>{formErrors.AreaLandmark}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label">Active</label>
                                    </div>
                                    <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1 mb-md-1">
                                        <Switch
                                            onChange={handleisActive}
                                            checked={activeId}
                                            className="react-switch"
                                            disabled={disabled ? true : false}
                                        />
                                    </div>
                                    {/* <div class=" col-auto col-lg-4"> */}
                                    <ConfirmationModal
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                        noconfirm={() => handleConfirmation(false)}
                                        yesconfirm={() => handleConfirmation(true)}
                                    />

                                    {/* </div> */}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className=' form_border mb-2 px-2'>
                        <div className='d-lg-flex'>
                            <div class="mb-1 col-lg-11 ms-lg-5 mt-3">
                                <div className='row '>
                                    {formErrors.HotelContactNumber && <p className='text-danger text-center'>{formErrors.HotelContactNumber}</p>}
                                    {/* {hotelcontacterror && <p className='text-danger text-center'>{hotelcontacterror}</p>} */}
                                    <div class="col-lg-4 col-md-4 col-sm-12 mb-lg-1 mb-md-1">
                                        <label for="inputPassword6" class="col-form-label required">Hotel Contact Number</label>
                                    </div>
                                    <div class=" col-lg-4 col-md-4 col-sm-12 mb-1">
                                        <input
                                            name='HotelContactNumber'
                                            type="text"
                                            value={formvalue.HotelContactNumber || ''}
                                            onChange={onChange}
                                            id="HotelContactNumber"
                                            class="form-control"
                                            placeholder='Hotel Contact Number'
                                            maxLength={16}
                                            disabled={disabled ? true : false}
                                        />
                                        {hotelcontactnoerror && <p className='text-danger text-start'>{hotelcontactnoerror}</p>}
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-11 justify-content-end d-lg-block d-md-flex mb-lg-1">
                                        <button type="button" onClick={ContactClick} class="btn btn-primary mx-1" id="btncontactAdd" disabled={btncontactadddisable ? true : false}>Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {datahotelcontactnocollection?.map((element, index) => {
                            if (datahotelcontactnocounter > 0) {
                                return (
                                    <div className='d-lg-flex d-md-flex' key={index}>
                                        <div class="mb-1 col-lg-11 col-md-12 ms-lg-5">
                                            <div className='row'>
                                                <div class=" col-lg-4 col-md-4 mb-1"></div>
                                                <div class=" col-lg-3 col-md-4 col-sm-12 mb-1">
                                                    <input
                                                        // onChange={handleOnChange}
                                                        name='ContactNumber'
                                                        class="form-control"
                                                        key={index}
                                                        value={element.ContactNumber || ''}
                                                        type="text"
                                                        readOnly
                                                    />
                                                </div>
                                                <div class="col-lg-4 col-md-2 col-sm-12 justify-content-start d-lg-block mb-lg-1">
                                                    <button type="button" onClick={(e) => { contactremovev(element.SeqNo, e) }} class="btn btn-primary mx-1" id="btncontactremoveAdd" disabled={disabled ? true : false} >Remove</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            return (<div className='d-none'></div>);
                        })}
                    </div>
                    <div className=' form_border mb-2 px-2'>
                        <div className='d-lg-flex'>
                            <div class="mb-1 col-lg-11 ms-lg-5 mt-3">
                                <div className='row'>
                                    {hotelReservationDupError && <p className='text-danger text-center'>{hotelReservationDupError}</p>}
                                    <div class="col-lg-3 col-md-12 col-sm-12 mb-lg-1 mb-md-1">
                                        <label for="inputPassword6" class="col-form-label ">Reservation Spoke Details</label>
                                    </div>
                                    <div class="col-lg-1 col-md-2 col-sm-12 mb-lg-1 mb-md-1">
                                        <label for="inputPassword6" class="col-form-label">Name</label>
                                    </div>
                                    <div class=" col-lg-3 col-md-3 col-sm-12 mb-1">
                                        <input
                                            name='ReservationNameD'
                                            type="text"
                                            value={formvalue.ReservationNameD || ''}
                                            onChange={onChange}
                                            id="inputPassword6"
                                            class="form-control"
                                            placeholder='Name'
                                            disabled={disabled ? true : false} />
                                        {spokenameerror && <p className='text-danger text-start'>{spokenameerror}</p>}
                                    </div>
                                    <div class="col-lg-1 col-md-2 col-sm-12 mb-lg-1 mb-md-1">
                                        <label for="inputPassword6" class="col-form-label ">Number</label>
                                    </div>
                                    <div class=" col-lg-3 col-md-3 col-sm-12 mb-1">
                                        <input
                                            name='ReservationNumberD'
                                            type="text"
                                            value={formvalue.ReservationNumberD || ''}
                                            onChange={onChange}
                                            id="inputPassword6"
                                            class="form-control"
                                            placeholder='Reservation Number'
                                            maxLength={16}
                                            disabled={disabled ? true : false} />
                                        {spokenumbererror && <p className='text-danger text-start'>{spokenumbererror}</p>}
                                    </div>
                                    <div class="col-lg-1 col-md-2 col-sm-12 justify-content-lg-end d-lg-block d-md-flex justify-content-md-end mb-lg-1">
                                        <button type="button" onClick={ReservationClick} class="btn btn-primary mx-1" id="btnreservationAdd" disabled={btnspokeadddisable ? true : false} >Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {datareservationspokedetails?.map((element, index) => {
                            if (datareservationspokecounter > 0) {
                                return (
                                    <div className='d-lg-flex d-md-flex' key={index}>
                                        <div class="mb-1 col-lg-11 col-md-12 ms-lg-5">
                                            <div className='row'>
                                                <div class=" col-lg-4 col-md-2 mb-1"></div>
                                                <div class=" col-lg-3 col-md-3 col-sm-12 mb-1">
                                                    <input
                                                        name='ReservationName'
                                                        class="form-control"
                                                        key={index}
                                                        value={element.ReservationName}
                                                        type="text"
                                                        readOnly
                                                    />
                                                </div>
                                                <div class=" col-lg-3 col-md-3 col-sm-12 mb-1">
                                                    <input
                                                        name='ReservationNumber'
                                                        class="form-control"
                                                        key={index}
                                                        value={element.ReservationNumber}
                                                        type="text"
                                                        readOnly
                                                    />
                                                </div>
                                                <div class="col-lg-2 col-md-3 col-sm-12 justify-content-start mb-lg-1">
                                                    <button type="button" onClick={(e) => { reservationremovev(element.SeqNo, e) }} class="btn btn-primary mx-1" id="btnreservationremoveAdd" disabled={disabled ? true : false}>Remove</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            return (<div className='d-none'></div>);
                        })}
                    </div>
                    <div className=' form_border mb-2 px-2'>
                        <div className='d-lg-flex'>
                            <div class="mb-1 col-lg-11 col-md-12 ms-lg-5 mt-3">
                                <div className='row'>
                                    {hotelMngtDupError && <p className='text-danger text-center'>{hotelMngtDupError}</p>}
                                    <div class="col-lg-3 col-md-12 col-sm-12 mb-lg-1 mb-md-1">
                                        <label for="inputPassword6" class="col-form-label ">Management Contact No.</label>
                                    </div>
                                    <div class="col-lg-1 col-md-2 col-sm-12 mb-lg-1 mb-md-1">
                                        <label for="inputPassword6" class="col-form-label ">Name</label>
                                    </div>
                                    <div class=" col-lg-3 col-md-3 col-sm-12 mb-1">
                                        <input
                                            name='ManagementNameD'
                                            type="text"
                                            value={formvalue.ManagementNameD}
                                            onChange={onChange}
                                            id="inputPassword6"
                                            class="form-control"
                                            placeholder='Name'
                                            disabled={disabled ? true : false} />
                                        {managementnameerror && <p className='text-danger text-start'>{managementnameerror}</p>}
                                    </div>
                                    <div class="col-lg-1 col-md-2 col-sm-12 mb-lg-1 mb-md-1">
                                        <label for="inputPassword6" class="col-form-label ">Number</label>
                                    </div>
                                    <div class=" col-lg-3 col-md-3 col-sm-12 mb-1">
                                        <input
                                            name='ManagementNumberD'
                                            type="text"
                                            value={formvalue.ManagementNumberD}
                                            onChange={onChange}
                                            id="inputPassword6"
                                            class="form-control"
                                            placeholder='Management Number'
                                            maxLength={16}
                                            disabled={disabled ? true : false} />
                                        {mangementnumbererror && <p className='text-danger text-start'>{mangementnumbererror}</p>}
                                    </div>
                                    <div class="col-lg-1 col-md-2 col-sm-12 justify-content-lg-end d-lg-block d-md-flex justify-content-md-end mb-lg-1">
                                        <button type="button" onClick={ManagementClick} class="btn btn-primary mx-1" id="btnmanagementAdd" disabled={btnmanagementNoadddisable ? true : false} >Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {datamanagementcontactno?.map((element, index) => {
                            if (datamanagementcontactcounter > 0) {
                                return (
                                    <div className='d-lg-flex d-md-flex' key={index}>
                                        <div class="mb-1 col-lg-11 col-md-12 ms-lg-5">
                                            <div className='row'>
                                                <div class=" col-lg-4 col-md-2 mb-1"></div>
                                                <div class=" col-lg-3 col-md-3 col-sm-12 mb-1">
                                                    <input
                                                        name='ManagementName'
                                                        class="form-control"
                                                        key={index}
                                                        value={element.ManagementName}
                                                        type="text"
                                                        readOnly
                                                    />
                                                </div>
                                                <div class=" col-lg-3 col-md-3 col-sm-12 mb-1">
                                                    <input
                                                        name='ManagementNumber'
                                                        class="form-control"
                                                        key={index}
                                                        value={element.ManagementNumber}
                                                        type="text"
                                                        readOnly
                                                    />
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-12 justify-content-start mb-lg-1">
                                                    <button type="button" onClick={(e) => { managementremovev(element.SeqNo, e) }} class="btn btn-primary mx-1" id="btnmanagementremoveAdd" disabled={disabled ? true : false}>Remove</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            return (<div className='d-none'></div>);
                        })}
                    </div>
                    <div className=' form_border mb-2 px-2'>
                        <div className='d-lg-flex'>
                            <div class="mb-1 col-lg-11 col-md-12 ms-lg-5 mt-3">
                                <div className='row'>
                                    {formErrors.HotelEmailD && <p className='text-danger text-center'>{formErrors.HotelEmailD}</p>}
                                    {/* {hotelemaillisterror && <p className='text-danger text-center'>{hotelemaillisterror}</p>} */}

                                    <div class="col-lg-4 col-md-4 col-sm-12 mb-lg-1 mb-md-1">
                                        <label for="inputPassword6" class="col-form-label required">Hotel Email ID</label>
                                    </div>
                                    <div class=" col-lg-4 col-md-4 col-sm-12 mb-1">
                                        <input
                                            name='HotelEmailD'
                                            type="text"
                                            onChange={onChange}
                                            value={formvalue.HotelEmailD}
                                            id="HotelEmailD"
                                            class="form-control"
                                            placeholder='Hotel Email ID'
                                            disabled={disabled ? true : false} />
                                        {hotelemailerror && <p className='text-danger text-start'>{hotelemailerror}</p>}
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-12 justify-content-end d-lg-block d-md-flex mb-lg-1">
                                        <button type="button" onClick={EmailClick} class="btn btn-primary mx-1" id="btnemailAdd" disabled={btnhotelemailadddisable ? true : false}>Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {datahotelemailcollection?.map((element, index) => {
                            if (datahotelemailcounter > 0) {
                                return (
                                    <div className='d-lg-flex d-md-flex' key={index}>
                                        <div class="mb-1 col-lg-11 col-md-12 ms-lg-5">
                                            <div className='row'>
                                                <div class=" col-lg-4 col-md-4 mb-1"></div>
                                                <div class=" col-lg-4 col-md-4 col-sm-12 mb-1">
                                                    <input
                                                        // onChange={handleOnChange}
                                                        name='HotelEmail'
                                                        class="form-control"
                                                        key={index}
                                                        value={element.HotelEmail || ''}
                                                        type="text"
                                                        readOnly
                                                    />
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-12 justify-content-start mb-lg-1">
                                                    <button type="button" onClick={(e) => { emailremovev(element.SeqNo, e) }} class="btn btn-primary mx-1" id="btnemailremoveAdd" disabled={disabled ? true : false}>Remove</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            return (<div className='d-none'></div>);
                        })}
                    </div>
                    <div class="mb-1 mt-4 col-lg-11 mx-lg-5">
                        <div className='row'>
                            {formErrors.txtbannerimages && <p className='text-danger text-center'>{formErrors.txtbannerimages}</p>}
                            {/* {bannerimgvideoerror && <p className='text-danger text-center'>{bannerimgvideoerror}</p>} */}
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label required">Hotel Banner Image</label>
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <div class="input-group  mb-1">
                                            <input
                                                name='HotelBannerImage'
                                                type="file"
                                                id="txtbannerimages"
                                                onChange={handleUploadImageLoad}
                                                class="form-control btn_add"
                                                accept=".png,.jpg"
                                                // disabled={disabled ? true : false}
                                                disabled={bannerimgdisable ? true : false}
                                            />
                                            <label class="input-group-text" for="txtbannerimages">Browse</label>
                                        </div>
                                        {imageError && <p className='text-danger text-start'>{imageError}</p>}
                                        {/* {bannerimgname && <p className='text-start'>{bannerimgname}</p>} */}
                                        {bannerimgname && <p className='text-start overflow-hidden' data-bs-toggle="tooltip" data-bs-placement="left" title={bannerimgname}>
                                            {bannerimgname}</p>}
                                        {bannerImage &&
                                            (
                                                <div className="col-lg-5 col-md-4 col-5 mx-auto mt-2">
                                                    <img
                                                        src={URL.createObjectURL(bannerImage)} className="img_thumbhotel w-100" alt=""
                                                    />
                                                    <button onClick={() => removeImage()} type="button" className='mt-0 pt-0 fw-bold fs-5 closebtnimg' data-close style={{ position: "absolute", background: 'none', border: 'none' }}
                                                        disabled={disabled ? true : false}
                                                    >&times;</button>
                                                </div>
                                            )}
                                        {(!bannerImage && bannerimgpath?.trim()?.length > 0) && (
                                            <div className="col-lg-5 col-md-4 col-5 mx-auto mt-2" >
                                                <img
                                                    src={bannerimgpath} className="img_thumbhotel w-100" alt=""
                                                />
                                                <button className='closebtnimg' onClick={() => removeImage()} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }}
                                                    disabled={disabled ? true : false}
                                                >&times;</button>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label">Hotel Video</label>
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <div class="input-group  mb-1">

                                            <input
                                                class="form-control btn_add"
                                                name='bannerVideo'
                                                id='txtbannerVideo'
                                                type="file"
                                                onChange={handleVideoUpload}
                                                accept=".mov,.mp4,.mp3,.wmv,.avi"
                                                //disabled={disabled ? true : false}
                                                disabled={bannervideodisable ? true : false}
                                            />
                                            <label class="input-group-text" for="txtbannerVideo">Browse</label>
                                            {/* {bannervideoname && <p className='text-start'>{bannervideoname}</p>} */}
                                        </div>
                                        {videoError && <p className='text-danger text-start'>{videoError}</p>}
                                        {bannervideoname && <p className='text-start overflow-hidden' data-bs-toggle="tooltip" data-bs-placement="left" title={bannervideoname}>
                                            {bannervideoname}</p>}
                                        {/* <div class="blo-img">
                              <video controls width="372" height="300" autoPlay="false" class="bg-dark embed-responsive embed-responsive-4by3">
                                <source src={`assets/video/${oCol.headingDetail}`}  type="video/mp4" />
                              </video>
                            </div> */}
                                        {bannerVideo && (
                                            <div className="col-lg-5 col-md-4 col-5 mx-auto mt-2">
                                                <video
                                                    className="embed-responsive embed-responsive-4by3 video_thumb"
                                                    width="100%"
                                                    controls
                                                    autoPlay={false}
                                                //src={URL.createObjectURL(bannerVideo)}
                                                >
                                                    <source src={URL.createObjectURL(bannerVideo)} />
                                                </video>
                                                <button onClick={() => removeVideo()} type="button" className='mt-0 pt-0 fw-bold fs-5 closebtnimg' data-close style={{ objectposition: "right top;", position: "absolute", background: 'none', border: 'none' }}
                                                    disabled={disabled ? true : false}
                                                >&times;</button>
                                            </div>
                                        )}
                                        {(!bannerVideo && bannervideopath?.trim()?.length > 0) && (
                                            <div className="col-lg-5 col-md-4 col-5 mx-auto mt-2">
                                                <video
                                                    className="embed-responsive embed-responsive-4by3 video_thumb"
                                                    width="100%"
                                                    controls
                                                    autoPlay={false}
                                                //src={URL.createObjectURL(bannerVideo)}
                                                >
                                                    <source src={bannervideopath} />
                                                </video>
                                                <button onClick={() => removeVideo()} type="button" className='mt-0 pt-0 closebtnimg' data-close style={{ objectposition: "right top;", fontSize: "36px", position: "absolute", background: 'none', border: 'none' }}
                                                    disabled={disabled ? true : false}
                                                >&times;</button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=' form_border mb-2 px-2'>
                        <div className='d-lg-flex'>
                            <div class="mb-1 col-lg-11 ms-lg-5 mt-3">
                                <div className='row'>
                                    {formErrors.RoomTypeEmptyError && <p className='text-danger text-center'>{formErrors.RoomTypeEmptyError}</p>}
                                    {priceroomerror && <p className='text-danger text-center'>{priceroomerror}</p>}
                                    <div class="col-lg-2 col-md-3 col-sm-12 mb-lg-1 mb-md-1">
                                        <label for="inputPassword6" class="col-form-label required">Room Type</label>
                                    </div>

                                    <div class=" col-lg-3 col-md-3 col-sm-12 mb-1">
                                        {/* <input
                                            name='RoomTypeD'
                                            type="text"
                                            onChange={onChange}
                                            value={formvalue.RoomTypeD || ''}
                                            id="inputPassword6"
                                            class="form-control"
                                            placeholder='Room Type' 
                                            disabled={disabled ? true : false}/> */}

                                        <Select className="dropdown select_dropdown"
                                            ref={RoomTypeEmptyError}
                                            id="RoomTypeEmptyError"
                                            options={hotelroomtype}
                                            value={hotelroomtype?.filter(function (hotelroomtype) {
                                                return hotelroomtype.value === roomtypeid;
                                            })}// set list of the data
                                            onChange={handleRoomtypeChange}// assign onChange function
                                            isDisabled={disabled ? true : false}
                                        />
                                        {roomtypeerror && <p className='text-danger text-start'>{roomtypeerror}</p>}
                                    </div>
                                    <div class="col-lg-2 col-md-3 col-sm-12 mb-lg-1 mb-md-1">
                                        <label for="inputPassword6" class="col-form-label required">No of Allocation</label>
                                    </div>
                                    <div class=" col-lg-3 col-md-3 col-sm-12 mb-1">
                                        <input
                                            name='RoomAllocation'
                                            type="text"
                                            onChange={onChange}
                                            value={formvalue.RoomAllocation || ''}
                                            id="RoomAllocation"
                                            class="form-control"
                                            placeholder='No of Allocation'
                                            disabled={disabled ? true : false} />
                                        {roomallocation && <p className='text-danger text-start'>{roomallocation}</p>}
                                    </div>
                                    <div class="col-lg-2 col-md-12 col-sm-12 justify-content-lg-end justify-content-md-center d-lg-block d-md-flex mb-lg-1">
                                        <button type="button" onClick={RoomTypeClick} class="btn btn-primary mx-1" id="btnroomtypeAdd" disabled={disabled ? true : false}>Add</button>
                                    </div>
                                </div>

                                {/* {datapricing?.map((element, index) => { */}
                                {datapricing?.map((element, index) => {

                                    if (datapricingcounter > 0) {
                                        return (
                                            <div className=' form_border mb-2 mt-1 px-2' key={index}>
                                                {/* {formErrors.RoomPricingError && <div className='text-danger text-center'  key={index} for={element.RoomType}>{formErrors.RoomPricingError}</div>} */}
                                                <div className='pt-1 col-lg-12 col-md-12 col-sm-12 ms-lg-3'>
                                                    <label for="inputPassword6" class="col-form-label">{element.RoomType}</label>
                                                </div>
                                                <div class="mb-1 col-lg-11 col-md-12 ms-lg-5 mt-3">
                                                    <div className='row'>

                                                        <div class="col-lg-2 col-md-3 col-sm-12 mb-lg-1 mb-md-1">
                                                            <label for="inputPassword6" class="col-form-label required">No of Pax</label>
                                                        </div>
                                                        <div class="col-lg-2 col-md-3 col-sm-12 mb-1">
                                                            <input
                                                                name='NoOfPax'
                                                                type="text"
                                                                onChange={(evnt) => handleChange(index, evnt)}
                                                                value={element.NoOfPax || ''}
                                                                id='txtNoOfPax'
                                                                class="form-control"
                                                                placeholder='No of Pax'
                                                                disabled={disabled ? true : false} />
                                                            {/* {noofpaxerror && <p className='text-danger text-start' >{noofpaxerror}</p>} */}
                                                            {formErrors1?.filter((errobj) => errobj.RoomTypeId === element.RoomTypeId && errobj.errorType === 1)?.map((err) => {
                                                                return (<>
                                                                    <p className='text-danger text-start'>{err.errorMessage}</p>
                                                                </>)
                                                            })}
                                                        </div>
                                                        <div class="col-lg-1 col-md-3 col-sm-12 mb-lg-1 mb-md-1">
                                                            <label for="inputPassword6" class="col-form-label required">Price</label>
                                                        </div>
                                                        <div class=" col-lg-2 col-md-3 col-sm-12 mb-1">
                                                            <input
                                                                name='RoomPrice'
                                                                type="text"
                                                                onChange={(evnt) => handleChange(index, evnt)}
                                                                value={element.RoomPrice || ''}
                                                                id="txtRoomPrice"
                                                                class="form-control"
                                                                placeholder='Price'
                                                                disabled={disabled ? true : false} />
                                                            {/* {roompriceerror && <p className='text-danger text-start'>{roompriceerror}</p>} */}
                                                            {formErrors1?.filter((errobj) => errobj.RoomTypeId === element.RoomTypeId && errobj.RoomType === element.RoomType && errobj.errorType === 2)?.map((err) => {
                                                                return (<>
                                                                    <p className='text-danger text-start'>{err.errorMessage}</p>
                                                                </>)
                                                            })}
                                                        </div>
                                                        <div class="col-lg-2 col-md-3 col-sm-6 mb-lg-1">
                                                            <label for="inputPassword6" class="col-form-label">Active</label>
                                                        </div>
                                                        <div class=" col-lg-2 col-md-3 col-sm-6 mb-lg-1 mb-md-1 mb-1">
                                                            {/* <Switch
                                                            onChange={handleRoomisActive}
                                                            checked={roomactiveId}
                                                            className="react-switch"
                                                        // disabled={roomdisabled ? true : false}
                                                        /> */}
                                                            <Switch
                                                                name='RoomIsActive'
                                                                onChange={(evnt) => handleRoomisActive(index, evnt)}
                                                                checked={element.RoomIsActive || false}
                                                                className="react-switch"
                                                                disabled={disabled ? true : false}
                                                            />
                                                        </div>
                                                        <div class="col-lg-1 col-md-6 col-sm-12 justify-content-end d-lg-block d-md-flex mb-lg-1">
                                                            <button type="button" onClick={() => AddRoom(element.RoomType, element.NoOfPax, element.RoomPrice, element.RoomIsActive, element.RoomTypeId)} class="btn btn-primary mx-1" id="btnroomAdd" disabled={disabled ? true : false}>Add</button>
                                                        </div>
                                                        {/* <div class="col-lg-2 col-md-3 col-sm-12 mb-lg-1 mb-md-1"></div>
                                                    <div class="col-lg-2 col-md-3 col-sm-12 mb-lg-1 mb-md-1">
                                                        <label for="inputPassword6" class="col-form-label">No of Pax</label>
                                                    </div>
                                                     */}
                                                    </div>

                                                    <div className='mb-1 col-lg-12 col-md-12 col-sm-12 mt-3 table_scroll'>
                                                        <table id="data" className="table table-hover" >
                                                            <thead>
                                                                <tr>
                                                                    <th>Room Type
                                                                    </th>
                                                                    <th>No of Pax
                                                                    </th>
                                                                    <th>Price
                                                                    </th>
                                                                    <th>Active
                                                                    </th>
                                                                    <th>Remove
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="location-data">
                                                                {dataroomtype?.map((element1, index) => {
                                                                    if (dataroomtypecounter > 0) {
                                                                        if (element.RoomType === element1.RoomType) {
                                                                            return (
                                                                                <tr class="ReactTable" key={index}>
                                                                                    <td>{element.RoomType}</td>
                                                                                    <td>{element1.NoofPax}</td>
                                                                                    <td>{element1.Price}</td>
                                                                                    <td>
                                                                                        {`${element1.RoomActive ? 'Active' : 'InActive'}`}
                                                                                    </td>
                                                                                    <td><button type="button" onClick={(e) => { Roomsremovev(element1.SeqNo, e) }} class="btn btn-primary mx-1" id="btnroomremoveAdd" disabled={disabled ? true : false}>Remove</button></td>
                                                                                </tr>
                                                                            );
                                                                        }

                                                                    }
                                                                    return (<div className='d-none'></div>);
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className='row'>
                                                        <div class="col-lg-4 col-md-3 col-sm-12 mb-lg-1 mb-md-1">
                                                            <div class="input-group">
                                                                <input type="file"
                                                                    class="form-control btn_add txtIncorporationCertiPath"
                                                                    id={element.RoomType}
                                                                    name='IncorporationCertiPath'
                                                                    accept="image/x-png,image/gif,image/jpeg"
                                                                    disabled={disabled ? true : false}
                                                                    //onChange={handleuploadIncorpration}
                                                                    onChange={(e) => handleUploadRoomImageLoad(element.RoomType, e)}
                                                                />
                                                                {/* <label class="input-group-text" for="txtIncorporationCertiPath">Browse</label> */}
                                                                <label class="input-group-text" onClick={(e) => handleUploadRoomImageLoad(element.RoomType, e)} for={element.RoomType}>Browse</label>
                                                            </div>
                                                            {imgcounterror && <p className='text-danger text-start'>{imgcounterror}</p>}
                                                            {imageRoomError && <p className='text-danger text-start'>{imageRoomError}</p>}
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12'>
                                                        <div class="row">

                                                            {uploadimage?.map((imgelement, index) => {
                                                                if (countimg >= 2 && element.RoomType === imgelement.roomtype) {

                                                                    return (
                                                                        <div className="col-lg-2 col-md-3 col-6 mx-auto" key={index} >
                                                                            <img src={imgelement.imageurl} alt={imgelement.name} key={index} className="img_thumbhotel w-100 mb-1" />
                                                                            <button className='closebtnimg' onClick={(e) => removeRoomImage(imgelement.id, e)} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }} disabled={disabled ? true : false} >&times;</button>

                                                                        </div>

                                                                    );
                                                                }
                                                                return (<div className='d-none'></div>);
                                                            })}
                                                            {/* {roomtyperetriveimg?.map((imgelement, index) => {
                                                            if(element.RoomType === imgelement.roomtype)
                                                            {

                                                                return (
                                                                            <div className="col-lg-2 col-md-3 col-6 mx-auto" key={index} >
                                                                                <img src={imgelement.imageurl} alt={imgelement.name} key = {index} className="img-fluid" style={{width:"80%" ,height:"80%"}}/>
                                                                                <button onClick={() => removeRoomImage(imgelement.id)} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background:'none' , border:'none'}} disabled={disabled ? true : false} >&times;</button> 
                                                                                
                                                                            </div>

                                                                        );
                                                            }
                                                           
                                                        })} */}
                                                        </div>
                                                    </div>
                                                    {/* </div> */}
                                                    {/* <div class="col-lg-2 col-md-12 col-sm-12 justify-content-start mb-lg-1">
                                                        <button type="button" onClick={(e) => { RoomTyperemovev(element.SeqNo, e) }} class="btn btn-primary mx-1" id="btnAdd" >Remove</button>
                                                    </div> */}

                                                </div>
                                            </div>
                                        );
                                    }
                                    return (<div className='d-none'></div>);
                                })}

                                {/* <div className=' form_border mb-2'>
                                    <div className='d-lg-flex'>
                                        <div class="mb-1 col-lg-10 ms-lg-5 mt-3">

                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div class="mt-5 d-flex justify-content-center">
                        <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={(e) => onSubmitForm(e)} disabled={editdisabled ? true : false}>{buttonaddeditText}</button>
                        <button type="button" class="btn btn-primary mx-1" id="btnClear" onClick={handleClear} disabled={cleardisable ? true : false} >Clear</button>
                        <button type="button" class="btn btn-primary mx-1" id="btnVisible" onClick={handlebackClick} disabled={backdisable ? true : false}>Back</button>
                    </div>

                </form>
            </div>
        </>
    )
}

export default Hotelmaster