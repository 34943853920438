import React from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from 'react-select';
import "../../assets/css/master.css"
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../services/ApiCallingUserServices';
import Switch from 'react-switch';
import { generatePDF, isValidImage } from '../commonClass/CommonValidation'
import { ImageDelete, ImageUpload } from '../../ImageUpload/ImageCommon';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../commonClass/Session';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../_actions/loader.actions";
import { ExportExcel } from '../commonClass/ExportExcel';
import { ConfirmationModal } from '../../customControl/ConfirmationModal';
import CustomSelection from '../../customControl/CustomSelection';
import CustomTable from '../../customControl/CustomTable';

const Gallerymaster = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const inputRef = useRef();
    const [modalShow, setModalShow] = useState(false);
    const [activeId, setActiveId] = useState();
    const [editdisabled, setEditDisabled] = useState(true);
    const [buttonaddeditText, setButtonAddEditText] = useState("Add");
    const [disabled, setDisabled] = useState(false);
    const [cityerrorMessage, setCityErrorMessage] = useState('');
    const [imagenameerrorMessage, setImageNameErrorMessage] = useState('');
    const [themeerrorMessage, setThemeErrorMessage] = useState('');
    const [imageerrorMessage, setImageErrorMessage] = useState('');
    const [galleryduplicateerrorMessage, setGalleryDuplicateErrorMessage] = useState('');
    const [gallaryalllocation, setGallayAllLocation] = useState([]);
    const [themelistdate, setThemeListData] = useState([]);
    const [themeid, setThemeId] = useState(0);
    const [gallerydelete, setgalleryDelete] = useState('');

    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('6');
    const [serchdisable, setSerachDisable] = useState(false);
    const [filteritems, setFilterItems] = useState([]);
    const [clrshow, setsclshow] = useState(false);
    const [citydisable, setCityDisable] = useState(false);
    const [themedisable, setThemeDisable] = useState(false)
    const [allCity, setallCity] = useState([]);
    const [alltheme, setallTheme] = useState([]);
    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);
    const [statelistdata, setStateListData] = useState([{
        value: 0,
        lable: ''
    }]);
    const [cityid, SetCityId] = useState(0);
    const [gallerylistdata, setGalleryListData] = useState([]);
    // const [airportduplicateerrorMessage, setAirportDuplicateErrorMessage] = useState('');
    const [formValue, setFormValue] = useState(
        {
            GalleryId: 0,
            ImageDisplayName: '',
            ImagePath: '',
            ImageFileName: '',
            LocationId: 0,
            ThemeId: 0,
            IsActive: false
        }
    );
    const serachoption = [
        {
            label: 'City',
            value: '1'
        },
        {
            label: 'State',
            value: '2'
        },
        {
            label: 'Image Name',
            value: '3'
        },
        {
            label: 'Theme Name',
            value: '4'
        },
        {
            label: 'Active',
            value: '5'
        },
        // {
        //     label: 'Created Date',
        //     value: '7'
        // },
        {
            label: 'All',
            value: '6'
        },

    ];
    const columns = [

        {
            name: 'City',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (gallery) => gallery.city
        },
        {
            name: 'State',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (gallery) => gallery.stateName
        },
        {
            name: 'Image Name',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (gallery) => gallery.imageDisplayName
        },
        {
            name: 'Theme Name',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (gallery) => gallery.themeName
        },
        {
            name: 'Active',
            sortable: true,
            float: "left",
            selector: (gallery) => gallery.isActive ? 'Active' : 'InActive'
        },
        {
            name: 'Created Date',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (gallery) => formatDate(gallery.createdDate)
        },
        {
            name: 'Edit',
            // float:"right",
            selector: (gallery) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEditMode(gallery.galleryId)}></i></button>
        },
        {
            name: 'View',
            // float:"right",
            selector: (gallery) => <i className="fa fa-eye" onClick={() => handleViewMode(gallery.galleryId)}></i>
        }
    ];

    //#region  use effect section
    const getGalleryDataList = async () => {
        const gallerylistdata = await getAllData("/master/v1/getallgallery");
        setGalleryListData(gallerylistdata.data);
    }

    // const getalllocationData = async () => {
    //     const getalllocation = await getAllData('/general/v1/getgallerycity');
    //     setallCity(getalllocation.data);
    //     setGallayAllLocation(getalllocation.data?.filter(IsAct => IsAct.isActive === true));
    // }

    const getalllocationData = async () => {
        await getAllData('/master/v1/getgallerycity').then((getalllocation) => {
            getGalleryDataList();
            setallCity(getalllocation.data);
            setGallayAllLocation(getalllocation.data?.filter(IsAct => IsAct.isActive === true));
            dispatch(loaderActions.end());
        });

    }

    const cityList = gallaryalllocation?.map(value => ({
        label: value.city,
        value: value.locationId
    }));
    const getState = (locationId) => {

        const StateList = allCity?.filter(city => city.locationId === locationId)?.map(value => (
            {
                label: value.stateName,
                value: 1
            }
        ));
        setStateListData(StateList);
    }

    const getthemelistdata = async () => {
        const themelistdata = await getAllData('/master/v1/getgallerytheme');
        setThemeListData(themelistdata.data?.filter(IsAct => IsAct.isActive === true));
        setallTheme(themelistdata.data);
    }
    const themelist = themelistdate?.map(value => ({
        label: value.themeName,
        value: value.themeId
    }));

    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            //if (Window.$name && Window.$userToken) {
            getthemelistdata();
            getalllocationData();
            // getGalleryDataList().then(()=>{dispatch(loaderActions.end())});
            setitems();
        }
        else {
            ClearLocalStorage();
            // alert('Your Session Expired. Please Login Again!');
            // navigate('/');
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, []);
    useEffect(() => {
        // let MENUID = GetMenuIdFromStorage()
        // let viewrights = false;
        // user?.filter(x => x.MenuId === MENUID).map((obj) => {
        //     viewrights = obj.URightView;
        //     setUserAdd(obj.URightAdd);
        //     setUserEdit(obj.URightEdit);
        //     setUserExport(obj.URightExport);
        //     if (!viewrights) {
        //         navigate('/dashboard');
        //     }
        // });
        // let MENUID = GetMenuIdFromStorage()
        // let mid = user?.find(x => x.MenuId === MENUID);
        let pagelink = user?.find(x =>{
            if( x.PageLink === window.location.pathname)
                return  x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);
        if (mid?.URightView === false && mid?.URightView!== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);
          
        });
    }, [user])
    useEffect(() => {

    }, [imagenameerrorMessage, cityerrorMessage, imageerrorMessage]);
    useEffect(() => {

    }, [buttonaddeditText]);
    //#endregion

    //#region handle city and state data and theme



    const handleCitychange = (e) => {
        setImageError('');
        setCityErrorMessage('');
        setGalleryDuplicateErrorMessage('');
        setImageNameErrorMessage('');
        setThemeErrorMessage('');
        setImageErrorMessage('');
        formValue.LocationId = e.value
        SetCityId(e.value);
        getState(e.value);
        setDisabled(false);
        setEditDisabled(true);
    }

    const handleThemechange = (e) => {
        setImageError('');
        setCityErrorMessage('');
        setGalleryDuplicateErrorMessage('');
        setImageNameErrorMessage('');
        setThemeErrorMessage('');
        setImageErrorMessage('');
        formValue.ThemeId = e.value
        setThemeId(e.value);
        setDisabled(false);
        setEditDisabled(true);
    }
    //#endregion

    //#region onchage and active handling
    const onChange = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
        setEditDisabled(true);
        setCityErrorMessage('');
        setGalleryDuplicateErrorMessage('');
        setImageNameErrorMessage('');
        setThemeErrorMessage('');
        setImageErrorMessage('');
        setImageError('');

        //setDestinationName(e.target.value);
    };
    // const handleisActive = (e) => {

    //     { formValue.IsActive = e }
    //     setActiveId(!activeId)
    //     setEditDisabled(true);
    // }
    const handleisActive = (e) => {
        if (buttonaddeditText === 'Add') {
            formValue.IsActive = e
            setActiveId(!activeId)
            setCityErrorMessage('');
            setGalleryDuplicateErrorMessage('');
            setImageNameErrorMessage('');
            setThemeErrorMessage('');
            setImageErrorMessage('');
            setImageError('');
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (e) => {
        //setYesNoConfirm(e)
        if (e === true) {
            formValue.IsActive = !activeId
            setActiveId(!activeId)
            setEditDisabled(true);
            setCityErrorMessage('');
            setGalleryDuplicateErrorMessage('');
            setImageNameErrorMessage('');
            setThemeErrorMessage('');
            setImageErrorMessage('');
            setImageError('');
        }
        setModalShow(false);
    }
    //#endregion

    //#region clear handling
    const handleClearClick = () => {
        // window.location.reload(true);

        clear();
    }
    function clear() {

        setCityErrorMessage('');
        setGalleryDuplicateErrorMessage('');
        setImageNameErrorMessage('');
        setThemeErrorMessage('');
        setImageErrorMessage('');
        setImageError('');
        setGalleryFileName('');
        setFormValue({ ...formValue, GalleryId: 0, ImageDisplayName: '', ImagePath: '', ImageFileName: '', LocationId: 0, ThemeId: 0, IsActive: false });
        setGallayAllLocation([]);
        getalllocationData();
        setActiveId(0);
        setSelectedFile();
        SetCityId(0);
        getState(0);
        setThemeId(0);
        setSerachBy('6');
        setSearchValue('');
        setSerachDisable(false);
        setGalleryDuplicateErrorMessage('');
        setDisabled(false);
        setEditDisabled(true);
        getGalleryDataList();
        setgalleryDelete('');
        setButtonAddEditText('Add');
        setGalleryPath('');
        setCityDisable(false);
        setThemeDisable(false);
        document.getElementById("txtgalleryimages").value = "";
        setsclshow(false)
    }
    //#endregion


    //#region handle add,edit and retrive
    const handleSubmitData = async () => {
        let statusoftoken = await verifytoken();
        if (buttonaddeditText === 'Add') {
            if (statusoftoken) {
                if (!validateData()) {
                    return;
                }
                else {
                    let filterdata;
                    filterdata = gallerylistdata?.filter(issec => issec.imageDisplayName.toLowerCase() === formValue.ImageDisplayName.toLowerCase() && issec.locationId === formValue.LocationId && issec.themeId === formValue.ThemeId);
                    if (filterdata?.length > 0) {
                        setGalleryDuplicateErrorMessage('You can not enter duplicate value.');
                        return;
                    }
                    else {
                        if (statusoftoken) {
                            const GalleryData = await postUsersData("/master/v1/insertgallery", formValue);
                            //setInclusionListData(InclusionData.data);
                            if (!GalleryData.status && !GalleryData.data) {
                                setGalleryDuplicateErrorMessage(GalleryData.message);
                                return;
                            }
                            if (GalleryData.status) {
                                if (GalleryData.data > 0) {
                                    //setitems();
                                    try {
                                        await handleimageupload('CMS/Gallery/', formValue.ImageFileName, fileselected);
                                        setitems();
                                        dispatch({ type: 'ALERTING', message: GalleryData.message })
                                        // alert('Record saved successfully.');
                                        clear();
                                    }
                                    catch (err) {
                                        dispatch({ type: 'ALERTING', message: 'Try again later!', err })
                                        // alert('Try again later!', err)
                                        setDisabled(false);
                                        setEditDisabled(true);
                                    }
                                    //window.location.reload(false);
                                    //clear();
                                }
                                else {
                                    dispatch({ type: 'ALERTING', message: GalleryData.message })
                                    // alert('Record not saved successfully')
                                    return;
                                }
                            }
                            else {
                                dispatch({ type: 'ALERTING', message: GalleryData.message })
                                // alert('Something went wrong!')
                                return;
                            }
                        }
                        else {
                            ClearLocalStorage();
                            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                        }
                    }

                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }

        }
        else {
            if (statusoftoken) {
                if (!validateData()) {
                    return;
                }
                else {
                    let filterdata;
                    filterdata = gallerylistdata?.filter(issec => issec.galleryId !== formValue.GalleryId && issec.imageDisplayName.toLowerCase() === formValue.ImageDisplayName.toLowerCase() && issec.locationId === formValue.LocationId && issec.themeId === formValue.ThemeId);
                    if (filterdata?.length > 0) {
                        setGalleryDuplicateErrorMessage('You can not enter duplicate value.');
                        return;
                    }
                    if (statusoftoken) {
                        const GalleryData = await patchUsersData("/master/v1/updategallery", formValue);
                        //setInclusionListData(InclusionData.data);
                        if (!GalleryData.status && !GalleryData.data) {
                            setGalleryDuplicateErrorMessage(GalleryData.message);
                            return;
                        }
                        if (GalleryData.status) {
                            if (GalleryData.data > 0) {
                                //setitems();
                                try {
                                    if (gallerypath.length === 0) {
                                        await handleimageupload('CMS/Gallery/', formValue.ImageFileName, fileselected);
                                    }
                                    try {
                                        if (gallerypath.length === 0) {
                                            await handleimageDelete(gallerydelete);
                                        }
                                    }
                                    catch (err) {
                                        dispatch({ type: 'ALERTING', message: 'Image delete transaction failed', err })
                                        // alert('Image delete transaction failed', err);
                                        setDisabled(false);
                                        setEditDisabled(true);
                                        return;
                                    }
                                    setitems();
                                    dispatch({ type: 'ALERTING', message: GalleryData.message })
                                    // alert('Record updated successfully.');
                                    clear();
                                }
                                catch (err) {
                                    dispatch({ type: 'ALERTING', message: 'Image save transaction faild!', err })
                                    // alert('Image save transaction faild!', err);
                                    setDisabled(false);
                                    setEditDisabled(true);
                                    return;
                                }
                                //setitems();
                                //window.location.reload(false);
                                //clear();
                            }
                            else {
                                dispatch({ type: 'ALERTING', message: GalleryData.message })
                                // alert('Record not updated successfully')
                                return;
                            }
                        }
                    }
                    else {
                        ClearLocalStorage();
                        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                    }
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
    }
    const CityError = useRef(null);
    const ImageIdError = useRef(null);

    const validateData = () => {

        let flag = true;
        let error = [];
        if (cityid === 0) {
            setCityErrorMessage("Please select city");
            error.push('CityError')
            flag = false;
        }

        if (typeof formValue.ImageDisplayName === 'string' && formValue.ImageDisplayName?.trim()?.length === 0) {
            setImageNameErrorMessage("Please enter Image Name");
            error.push('ImageDisplayName')
            flag = false;
        }
        if (typeof formValue.ImageDisplayName === 'string' && formValue.ImageDisplayName?.trim()?.length > 50) {
            setImageNameErrorMessage("Maximum length is 50 characters");
            error.push('ImageDisplayName')
            flag = false;
        }

        if (themeid === 0) {
            setThemeErrorMessage("Please select theme");
            error.push('ImageIdError')
            flag = false;
        }
        if (typeof formValue.ImagePath === 'string' && formValue.ImagePath?.trim()?.length === 0) {
            setImageErrorMessage("Please select Gallery Image");
            error.push('txtgalleryimages')
            setImageError('');
            flag = false;
        }

        if (error[0] === "CityError" || error[0] === "ImageIdError") {
            eval(error[0]).current.focus();
        }
        else {
            let doc = document.getElementById(error[0]);
            if (doc !== null)
                doc.focus();
        }
        return flag;
    }

    const handleEditMode = (id) => {
        setDisabled(false);
        setEditDisabled(false);
        setSerachDisable(true);
        setButtonAddEditText("Update");
        RetriveData(id);
    }
    const handleViewMode = (id) => {
        setDisabled(true);
        setEditDisabled(false);
        setSerachDisable(true);
        RetriveData(id);
    }
    function RetriveData(id) {
        document.getElementById("txtgalleryimages").value = "";
        setCityErrorMessage('');
        setImageNameErrorMessage('');
        setThemeErrorMessage('');
        setImageErrorMessage('');
        setImageError('');
        // setSelectedFile('');
        // setFormErrors('');
        setGalleryDuplicateErrorMessage('');
        gallerylistdata?.map(obj => {
            if (obj.galleryId === id) {
                // let active = !obj.isActive;
                formValue.ImageDisplayName = obj.imageDisplayName
                formValue.ImagePath = obj.imagePath
                formValue.ImageFileName = obj.imageFileName
                formValue.GalleryId = obj.galleryId
                formValue.IsActive = obj.isActive
                let allcitylist = allCity?.filter(IsAct => IsAct.isActive === true);

                setGallayAllLocation(allCity?.filter(IsAct => IsAct.isActive === true));
                setCityDisable(false);
                if (!allcitylist?.find(IsCity => IsCity.locationId === obj.locationId)) {
                    setCityDisable(true)
                    setGallayAllLocation((prvCityData) => prvCityData?.length > 0 ? [...prvCityData, allCity?.find(isCity => isCity.locationId === obj.locationId)] : [])
                    // setCityData((prvCityData) => [...prvCityData, allCity?.find(isCity => isCity.locationId === obj.locationId)])

                }
                let allthemelist = alltheme?.filter(IsAct => IsAct.isActive === true);
                setThemeListData(alltheme?.filter(IsAct => IsAct.isActive === true));
                setThemeDisable(false);
                if (!allthemelist?.find(IsCity => IsCity.themeId === obj.themeId)) {
                    setThemeDisable(true)
                    setThemeListData((prvCityData) => prvCityData?.length > 0 ? [...prvCityData, alltheme?.find(isCity => isCity.themeId === obj.themeId)] : [])
                    // setCityData((prvCityData) => [...prvCityData, allCity?.find(isCity => isCity.locationId === obj.locationId)])

                }
                formValue.LocationId = obj.locationId
                formValue.ThemeId = obj.themeId
                SetCityId(obj.locationId);
                getState(obj.locationId);
                setThemeId(obj.themeId);
                setActiveId(obj.isActive);
                setGalleryPath('https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + obj.imagePath);
                setGalleryFileName(obj.imageFileName);
                setgalleryDelete(obj.imagePath);

            }
        });
        setFormValue({ ...formValue, formValue })

    }

    //#endregion

    //#region image upload

    const [gallerypath, setGalleryPath] = useState('');
    const [galleryfilename, setGalleryFileName] = useState();
    const [fileselected, setSelectedFile] = useState();
    const [imageError, setImageError] = useState('');

    const handleUploadImageLoad = (e) => {
        if (isValidImage(e.target.files[0].name)) {
            const files = e.target.files;
            const filecom = getDateCombination();
            const filename = filecom + '_' + '1' + '_' + (files[0].name.replace(/ /g, "_")).replace(/-/g, '');
            formValue.ImageFileName = filename
            formValue.ImagePath = 'CMS/Gallery/' + filename
            setSelectedFile(e.target.files[0]);
            setEditDisabled(true);
            setGalleryPath('');
            setGalleryFileName('');
            setImageError('');
            setGalleryDuplicateErrorMessage('');
        }
        else {
            document.getElementById("txtgalleryimages").value = "";
            setFormValue({ ...formValue, ImageFileName: '', ImagePath: '' });
            setGalleryPath('');
            setCityErrorMessage('');
            setImageNameErrorMessage('');
            setThemeErrorMessage('');
            setImageErrorMessage('');
            setGalleryFileName('');
            setGalleryDuplicateErrorMessage('');
            setImageError("Please select Only Image")
            return;
        }
    }
    useEffect(() => {
        setCityErrorMessage('');
        setGalleryDuplicateErrorMessage('');
        setImageNameErrorMessage('');
        setThemeErrorMessage('');
        setImageErrorMessage('');
        setImageError('');
    }, [fileselected]);

    const removeImage = () => {
        document.getElementById("txtgalleryimages").value = "";
        formValue.ImagePath = ''
        formValue.ImageFileName = ''
        setGalleryPath('');
        setSelectedFile();
        setEditDisabled(true);
        setGalleryFileName('');
    };
    function getDateCombination() {
        var today = new Date();
        let date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
        let time = today.getHours().toString() + (today.getMinutes() + 1).toString() + today.getSeconds().toString();
        return date + '_' + time;
    }
    const handleimageupload = async (pathdirectory, filename, file) => {
        await ImageUpload(pathdirectory, filename, file);
    }
    const handleimageDelete = async (filename) => {
        await ImageDelete(filename);
    }
    //#endregion

    //#region PDF and Excel Export
    function PdfExport() {
        //print
        const columns = [
            "City",
            "State",
            "Image Name",
            "Theme Name",
            "Active",
            "Created Date"
        ];
        var rows = [];

        for (let i = 0; i < gallerylistdata?.length; i++) {
            var temp = [
                gallerylistdata[i].city,
                gallerylistdata[i].stateName,
                gallerylistdata[i].imageDisplayName,
                gallerylistdata[i].themeName,
                `${gallerylistdata[i].isActive ? 'Active' : 'InActive'}`,
                formatDate(gallerylistdata[i].createdDate)
            ];
            rows = [...rows, temp]
        }
        generatePDF(columns, rows, "GalleryMaster", "Gallery Master")
    }
    // function ExportToExcel() {
    //     const fields = ['city', 'stateName', 'imageDisplayName', 'themeName', 'isActive'];
    //     const data = gallerylistdata;
    //     const fileName = 'Gallerymasterexcel'
    //     const exportType = 'xls'

    //     exportFromJSON({ data, fileName, fields, exportType })
    // }
    const ExportToExcel = () => {
        const dispalyname = ['City', 'State', 'Image Name', 'Theme Name', 'Active', 'Created Date'];
        const fields = ['city', 'stateName', 'imageDisplayName', 'themeName', 'isActive', 'createdDate'];
        ExportExcel(dispalyname, fields, gallerylistdata, "GalleryMaster", "GalleryMaster.xls");
    }

    //#endregion

    //#region handle serach value


    const setitems = async () => {
        const GalleryListData = await getAllData("/master/v1/getallgallery");
        setFilterItems(GalleryListData.data);
    }

    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }
    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            getGalleryDataList();
            setsclshow(false)
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleSearch();
        }
    };

    const handleSearch = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            setitems();
            var filteritem = filteritems;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec.city.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec.stateName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '3') {
                filterdata = filteritem?.filter(issec => issec.imageDisplayName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '4') {
                filterdata = filteritem?.filter(issec => issec.themeName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '7') {
                filterdata = filteritem?.filter(issec => formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '5') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.isActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.isActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.isActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => formatDate(issec.createdDate).toLowerCase().includes("%#$&%^"));
                }

            }
            else if (searchby === '6') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.city.toLowerCase().includes(searchvalue.toLowerCase()) || issec.stateName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.imageDisplayName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.themeName.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.city.toLowerCase().includes(searchvalue.toLowerCase()) || issec.stateName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.imageDisplayName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.themeName.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.city.toLowerCase().includes(searchvalue.toLowerCase()) || issec.stateName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.imageDisplayName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.themeName.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.city.toLowerCase().includes(searchvalue.toLowerCase()) || issec.stateName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.imageDisplayName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.themeName.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => issec.city.toLowerCase().includes(searchvalue.toLowerCase()) || issec.stateName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.imageDisplayName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.themeName.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || 'active'.toLowerCase().includes(searchvalue.toLowerCase()) || 'inactive'.toLowerCase().includes(searchvalue.toLowerCase()));
                }
            }
            setGalleryListData(filterdata)
        }
    }

    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getGalleryDataList();
    }
    //#endregion
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }
    return (
        <>
            <div class="container mt-4 heading_color">
                <h3>Gallery Master</h3>
            </div>
            <div class="container form_border py-5 my-3">
                <form action="" >
                    <div class="mb-3 row mx-1">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="row">
                                {galleryduplicateerrorMessage && <div className="text-danger pb-3 text-center"> {galleryduplicateerrorMessage} </div>}
                                <div className='col-md-6 mb-lg-1'>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 col-sm-12">
                                            <label for="inputPassword6" class="col-form-label required">Select City</label>
                                        </div>
                                        <div class=" col-lg-8 col-md-12 col-sm-12">
                                            {/* <Select className="dropdown select_dropdown"
                                                options={cityList}
                                                value={cityList?.filter(function (cityList) {
                                                    return cityList.value === cityid;
                                                })}
                                                onChange={handleCitychange}
                                                isDisabled={disabled ? true : false}
                                            // options ={city}
                                            /> */}
                                            <CustomSelection
                                                selId="CityError"
                                                selRef={CityError}
                                                datalist={cityList}
                                                fieldid={cityid}
                                                SelectionChange={handleCitychange}
                                                isdisable={disabled}
                                                isactivedisable={citydisable}
                                                issearchable={true}
                                            />
                                            {cityerrorMessage && <div className="text-danger"> {cityerrorMessage} </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 mb-lg-1'>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 col-sm-12">
                                            <label for="inputPassword6" class="col-form-label required">State</label>
                                        </div>
                                        <div class=" col-lg-8 col-md-12 col-sm-12">
                                            <Select className="dropdown select_dropdown"
                                                isDisabled
                                                value={statelistdata?.find(({ value }) => value === 1)}
                                                options={statelistdata}
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 mb-lg-1'>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 col-sm-12">
                                            <label for="inputPassword6" class="col-form-label required">Enter Image Name</label>
                                        </div>
                                        <div class=" col-lg-8 col-md-12 col-sm-12">
                                            <input type="text" id="ImageDisplayName" class="form-control"
                                                name="ImageDisplayName"
                                                value={formValue.ImageDisplayName}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                                placeholder="Enter Image Name"
                                            />
                                            {imagenameerrorMessage && <div className="text-danger"> {imagenameerrorMessage} </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 mb-lg-1'>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 col-sm-12">
                                            <label for="inputPassword6" class="col-form-label required">Theme</label>
                                        </div>
                                        <div class=" col-lg-8 col-md-12 col-sm-12">

                                            {/* <Select className="dropdown select_dropdown"
                                                // isDisabled
                                                value={themelist?.filter(function (themelist) {
                                                    return themelist.value === themeid;
                                                })}
                                                onChange={handleThemechange}
                                                options={themelist}
                                                isDisabled={disabled ? true : false}
                                            /> */}
                                            <CustomSelection
                                                selId="ImageIdError"
                                                selRef={ImageIdError}
                                                datalist={themelist}
                                                fieldid={themeid}
                                                SelectionChange={handleThemechange}
                                                isdisable={disabled}
                                                isactivedisable={themedisable}
                                                issearchable={true}
                                            />
                                            {themeerrorMessage && <div className="text-danger"> {themeerrorMessage} </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 mb-lg-1'>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12">
                                            <label for="inputPassword6" class="col-form-label required">Upload Image</label>
                                        </div>
                                        <div class="col-lg-8 col-md-12 col-sm-12 mb-lg-1 mb-md-1">
                                            <div class="input-group">
                                                <input
                                                    ref={inputRef}
                                                    name='GalleryImage'
                                                    type="file"
                                                    id="txtgalleryimages"
                                                    onChange={handleUploadImageLoad}
                                                    class="form-control btn_add"
                                                    accept=".png,.jpg"
                                                    // onChange={handleuploadAffilitionCertifycate}
                                                    disabled={disabled ? true : false}
                                                />
                                                <label class="input-group-text" for="txtgalleryimages">Browse</label>

                                            </div>
                                            {imageerrorMessage && <div className="text-danger"> {imageerrorMessage} </div>}
                                            {imageError && <p className='text-danger text-start'>{imageError}</p>}
                                            {/* {galleryfilename && <p className='text-start'>{galleryfilename}</p>} */}
                                            {galleryfilename && <p className='text-start overflow-hidden' data-bs-toggle="tooltip" data-bs-placement="left" title={galleryfilename}>
                                                {galleryfilename}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 mb-lg-1'>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 col-sm-12">
                                            <label for="inputPassword6" class="col-form-label">Active</label>
                                        </div>
                                        <div class="col-auto col-lg-8">

                                            <Switch
                                                onChange={handleisActive}
                                                checked={activeId}
                                                className="react-switch"
                                                disabled={disabled ? true : false}
                                            />
                                        </div>
                                        {/* <div class=" col-auto col-lg-4"> */}
                                        <ConfirmationModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            noconfirm={() => handleConfirmation(false)}
                                            yesconfirm={() => handleConfirmation(true)}
                                        />

                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            {/* {uploadimage.map((element) => {
                                return (
                                    <div className="col-lg-4 col-md-3 col-6 mx-auto mt-2" >
                                        <img src={element.imageurl} alt={element.name} className="img-fluid w-100" />
                                        <a onClick={() => removeImage(element.id)} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", }}>&times;</a>
                                    </div>

                                );
                            })} */}

                            {fileselected &&
                                (
                                    <div className="col-lg-2 col-md-3 col-6 mx-auto mt-2">
                                        <img
                                            src={URL.createObjectURL(fileselected)} className="img-fluid w-100" alt=''
                                        />
                                        <button onClick={() => removeImage()} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }} disabled={disabled ? true : false} >&times;</button>
                                    </div>
                                )}
                            {(!fileselected && gallerypath?.trim()?.length > 0) && (
                                <div className="col-lg-2 col-md-3 col-6 mx-auto mt-2" >
                                    <img
                                        src={gallerypath} className="img-fluid w-100" alt=''
                                    />
                                    <button onClick={() => removeImage()} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }} disabled={disabled ? true : false} >&times;</button>
                                </div>
                            )}

                        </div>
                    </div>
                    <div class="d-flex justify-content-center mb-4">
                        {useradd === false && buttonaddeditText === "Add" ?
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={handleSubmitData} disabled={!useradd}>{buttonaddeditText}</button> :
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={handleSubmitData} disabled={editdisabled ? false : true}>{buttonaddeditText}</button>}
                        <button type="button" class="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick} >Clear</button>
                    </div>
                    <div class="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}

                                />
                            </div>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input type="text" onKeyDown={onKeyDownHandler} id="btnSearchText" class="form-control" placeholder='Enter keyword' name="SearchValue" onChange={handleChangeSearchValue} value={searchvalue || ''} disabled={serchdisable ? true : false} />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div class="p-2 text-center">
                                <button type="button" class="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleSearch}>Search</button>
                            </div>
                        </div>

                    </div>
                    <CustomTable
                        columns={columns}
                        data={gallerylistdata}
                        PdfExport={PdfExport}
                        ExportToExcel={ExportToExcel}
                        disabled={!userexport}
                    />
                </form>
            </div >
        </>
    )
}

export default Gallerymaster