import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { alertaction } from "../_actions/alert.actions";
import { useNavigate } from "react-router-dom";
export const Alert = ({ alert, alertmessage, session, message }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const ref = useRef()
    const showHideClassName = alert || session || alertmessage ? "modal display-block" : "modal display-none";
    const closeModel = () => {
        dispatch(alertaction.close())
        if (session) {
            navigate('/');
        }
        if (alertmessage) {
            navigate(-1);
        }
    }

    useEffect(() => {
        ref?.current?.focus()
    })

    return (
        <>
            <div className={showHideClassName}>
                <section className="modal-main">
                    <div className="modal-dialog modal-dialog-centered modal-sm">
                        <div className="modal-content">
                            <div className="modal-header border-0 bg-primary session_headermodal">
                                {/* <div className="float-start col-11"> */}
                                <h5 className="modal-title session_title mx-auto">Message Alert!</h5>
                                {/* </div> */}
                                {/* <div className="float-end col-1"> */}
                                <button type="button" id="closemodal" className="btn-close closebtn_position" data-bs-dismiss="modal" aria-label="Close" onClick={closeModel} >
                                    {/* <i className="fa-solid fa-xmark"></i> */}
                                </button>
                                {/* </div> */}
                            </div>
                            <div className="modal-body justify-content-start d-flex row py-0">
                                <p className='text-center fw-bold my-1'>
                                    {message}
                                </p>
                            </div>
                            <div className="modal-footer py-1 border-0 d-flex justify-content-center align-items-center">
                                <div className="">
                                    <button ref={ref} type="button" className="btn btn-primary" onClick={closeModel} >OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};