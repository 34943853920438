import React, { useMemo, useRef, useState } from 'react'
import Select from 'react-select';
import { isNumeric } from 'validator';
import { onlyNumeric } from '../../../commonClass/CommonValidation';
import CustomSelection from '../../../../customControl/CustomSelection';

function AccessPointAndList({
    stationType,
    dataRetriveList,
    additionInfo,
    setAdditionInfo,
    accessPointList,
    setAccessPointList, isEditDisable, setIsEditDisable, disabled
}) {

    const [accessPoints, setAccessPoints] = useState({
        trainStation: {
            accessPointId: '', kMs: ''
        },
        busStation: {
            accessPointId: '', kMs: ''
        },
        airPort: {
            accessPointId: '', kMs: ''
        },
        port: {
            accessPointId: '', kMs: ''
        }
    });

    const [accessPointsLabel, setAccessPointsLabel] = useState({
        trainStation: {
            accessPointLabel: '', kMs: ''
        },
        busStation: {
            accessPointLabel: '', kMs: ''
        },
        airPort: {
            accessPointLabel: '', kMs: ''
        },
        port: {
            accessPointLabel: '', kMs: ''
        }
    });
    const [errors, setErrors] = useState({})
    const [isChange, setIsChange] = useState(false)

    const AccessOnChange = (stationType, name, value) => {
        setIsChange(true)
        setErrors({})
        setIsEditDisable(false)

        if (name === 'kMs') {

            setAccessPoints({
                ...accessPoints,
                [stationType]: {
                    ...accessPoints[stationType],
                    [name]: Number(value)
                }
            })


            setAccessPointsLabel({
                ...accessPointsLabel,
                [stationType]: {
                    ...accessPointsLabel[stationType],
                    kMs: Number(value)
                }
            })
        }
        else {

            setAccessPoints({
                ...accessPoints,
                [stationType]: {
                    ...accessPoints[stationType],
                    [name]: value.value
                }
            })

            setAccessPointsLabel({
                ...accessPointsLabel,
                [stationType]: {
                    ...accessPointsLabel[stationType],
                    accessPointLabel: value.label
                }
            })
        }
    };

    const getErrorMessage = (stationType) => {
        switch (stationType) {
            case "trainStation":
                return "Kindly select the railway station"
            case "busStation":
                return "Kindly select the bus stand"
            case "airPort":
                return "Kindly select the airport"
            case "port":
                return "Kindly select the port"
            default:
                return "";
        }
    };

    const getPlaceHolder = (stationType) => {
        switch (stationType) {
            case "trainStation":
                return "Select Railway Station"
            case "busStation":
                return "Select Bus Stand"
            case "airPort":
                return "Select Airport"
            case "port":
                return "Select Port"
            default:
                return "";
        }
    };

    const getOptions = (stationType) => {
        let railwayOptions = accessPointList?.railwayStationList?.map(obj => ({
            label: obj.railwayStationName,
            value: obj.accessPointRailwayId
        }))
        let busOptions = accessPointList?.busStandList?.map(obj => ({
            label: obj.busStandName,
            value: obj.accessPointBusId
        }))
        let airportOptions = accessPointList?.airportList?.map(obj => ({
            label: obj.airportName,
            value: obj.accessPointAirportId
        }))
        let portOptions = accessPointList?.portList?.map(obj => ({
            label: obj.portName,
            value: obj.accessPointPortId
        }))

        switch (stationType) {
            case "trainStation":
                return railwayOptions
            case "busStation":
                return busOptions
            case "airPort":
                return airportOptions
            case "port":
                return portOptions
            default:
                return "";
        }
    };


    const train = useRef()
    const bus = useRef()
    const air = useRef()
    const port = useRef()

    const getRef = (stationType) => {
        switch (stationType) {
            case "trainStation":
                return train
            case "busStation":
                return bus
            case "airPort":
                return air
            case "port":
                return port
            default:
                return "";
        }
    }

    useMemo(() => {
        getOptions()
    }, [accessPointList])


    const handleValidationOfAccessPoint = (stationType) => {
        const { accessPointId, kMs } = accessPoints[stationType];
        let error = {}
        let status = false
        if (!accessPointId && !kMs) {
            // error['accessPointId'] = getErrorMessage(stationType)
            status = true;
            setIsChange(false)
        }
        else if (!accessPointId && kMs) {
            error['accessPointId'] = getErrorMessage(stationType)
            status = true;
            setIsChange(false)
        }
        else if (accessPointId && !kMs) {
            // `${stationType}-kMs`
            // error['kMs'] = "Kindly specify the distance in Kms"
            error[`${stationType}-kMs`] = "Kindly specify the distance in Kms"
            status = true;
            setIsChange(false)
        }
        else if (additionInfo.AccessPoints[stationType]?.length >= 10) {
            error['maxEntery'] = "Maximum allowed entry is 10"
            status = true;
            setIsChange(false)
        }
        else if (additionInfo.AccessPoints[stationType]?.length > 0) {
            if (additionInfo.AccessPoints[stationType]?.filter(dup => dup?.accessPointId === accessPointId)?.length !== 0) {
                error['Duplicate'] = 'Data already exist';
                status = true;
                setIsChange(false)
            }
        }
        setErrors(error)

        if (Object.keys(error).length > 0) {
            let id = Object.keys(error)[0];
            let doc = document.getElementById(id);
            if (doc !== null)
                doc.focus();
            else if (id === 'accessPointId') {
                if (stationType === 'trainStation') {
                    eval(train).current.focus();
                }
                else if (stationType === 'busStation') {
                    eval(bus).current.focus();
                }
                else if (stationType === 'airPort') {
                    eval(air).current.focus();
                }
                else if (stationType === 'port') {
                    eval(port).current.focus();
                }
            }
        }

        return status
    }

    const onAddStation = (stationType, accesDetails) => {
        const isErrors = handleValidationOfAccessPoint(stationType)
        if (!isErrors) {
            const accessPointLength = additionInfo.AccessPoints[stationType].length + 1;
            const accessPointLabelLength = additionInfo.AccessPointsLabels[stationType].length + 1;

            setAdditionInfo({
                ...additionInfo,
                AccessPoints: {
                    ...additionInfo.AccessPoints,
                    [stationType]: [...additionInfo.AccessPoints[stationType],
                    {
                        ...accessPoints[stationType], seqNo: accessPointLength
                    }
                    ]
                },
                AccessPointsLabels: {
                    ...additionInfo.AccessPointsLabels,
                    [stationType]: [...additionInfo.AccessPointsLabels[stationType],
                    {
                        ...accessPointsLabel[stationType], seqNo: accessPointLabelLength
                    }
                    ]
                }
            });

            setAccessPoints({
                ...accessPoints, [stationType]: {
                    accessPointId: '', kMs: ''
                }
            })
            setAccessPointsLabel({
                ...accessPoints, [stationType]: {
                    accessPointLabel: '', kMs: ''
                }
            })
        }
    };

    const handleRemoveAccessPoint = (stationType, seqNo) => {
        setIsEditDisable(false)
        setErrors({})
        const accessPoint = { ...additionInfo?.AccessPoints };
        const accessPointLabel = { ...additionInfo?.AccessPointsLabels };

        accessPoint[stationType] = accessPoint?.[stationType].filter((stopPoint) => stopPoint.seqNo !== seqNo)
            .map((accessPoint, index) => ({ ...accessPoint, seqNo: index + 1 }));

        accessPointLabel[stationType] = accessPointLabel?.[stationType].filter((stopPoint) => stopPoint.seqNo !== seqNo)
            .map((accessPointlabel, index) => ({ ...accessPointlabel, seqNo: index + 1 }));

        setAdditionInfo({
            ...additionInfo,
            AccessPoints: accessPoint,
            AccessPointsLabels: accessPointLabel
        })
    };

    return (
        <>
            {errors.maxEntery && <div className='text-danger text-start text-center mt-2'>{errors.maxEntery}</div>}
            {errors.Duplicate && <div className='text-danger text-start text-center mt-2'>{errors.Duplicate}</div>}
            <div className='row my-3  d-flex align-items-center'>
                <div className='col-lg-2 col-md-12 col-sm-12'>
                    {
                        stationType === "trainStation" && <label className="col-form-label "><b>Access Points</b></label>
                    }
                </div>
                <div className='col-lg-9 col-md-9 col-sm-12'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-8 col-sm-12'>
                            <div className=" form-lg-check form-lg-check-inline w-sm-100">
                                {/* <Select className="dropdown select_dropdown"
                                id={stationType}
                                value={accessPoints?.[stationType].accessPointId}
                                // options={[{ label: 'vadodara', value: '2' }]}
                                options={getOptions(stationType)}
                                onChange={(val) => { AccessOnChange(stationType, 'accessPointId', val) }}
                                placeholder={getPlaceHolder(stationType)}
                            /> */}
                                <CustomSelection
                                    datalist={getOptions(stationType)}
                                    selId={stationType}
                                    selRef={getRef(stationType)}
                                    fieldid={accessPoints?.[stationType].accessPointId}
                                    SelectionChange={(val) => { AccessOnChange(stationType, 'accessPointId', val) }}
                                    isdisable={disabled}
                                    // isactivedisable={citydisable}
                                    issearchable={true}
                                    placeholder={getPlaceHolder(stationType)}
                                />

                                {errors.accessPointId && <div className='text-danger text-start'>{errors.accessPointId}</div>}
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-4 col-sm-12 mt-lg-0 mt-md-0 mt-2'>
                            <div className=" form-lg-check form-lg-check-inline w-sm-100">
                                <input class="form-control"
                                    type="text"
                                    id={`${stationType}-kMs`}
                                    name="kMs"
                                    value={accessPoints?.[stationType].kMs}
                                    onChange={(e) => {
                                        AccessOnChange(stationType, e.target.name, onlyNumeric(e.target.value))
                                    }}
                                    maxLength={3}
                                    placeholder='KMs'
                                    disabled={disabled ? true : false}
                                />
                                {errors[`${stationType}-kMs`] && <div className={` ${isChange === true ? 'd-none' : 'd-block'} text-danger text-start`}>{errors[`${stationType}-kMs`]}</div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-1 col-md-2 col-sm-12 mt-lg-0 mt-md-0 mt-2 text-center'>
                    <button type="button"
                        onClick={() => onAddStation(stationType, accessPoints)}
                        className="btn btn-primary mx-1" id="btncontactAdd"
                        disabled={disabled ? true : false}
                    >Add</button>
                </div>
            </div>

            {/* {additionInfo?.AccessPoints?.[stationType]?.map((accesspoint) => ( */}
            {additionInfo?.AccessPointsLabels?.[stationType]?.map((accesspoint) => (
                <div className='row my-3  d-flex align-items-center'>
                    <div className='col-lg-2 d-hidden '>
                        {/* <label className="col-form-label "><b>Access Points</b></label> */}
                    </div>
                    <div className='col-lg-9 col-md-9 col-sm-12'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-8 col-sm-12'>
                                <div className=" form-lg-check form-lg-check-inline w-100">
                                    <input class="form-control"
                                        type="text"
                                        id='SupplierName'
                                        name="SupplierName"
                                        value={accesspoint?.accessPointLabel}
                                        // onChange={onChange}
                                        disabled
                                    />
                                    {/* {formErrors.SupplierName && <p className='text-danger text-start'>{formErrors.SupplierName}</p>} */}
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-4 col-sm-12 mt-lg-0 mt-md-0 mt-2'>
                                <div className=" form-lg-check form-lg-check-inline w-100">
                                    <input class="form-control"
                                        type="text"
                                        id='SupplierName'
                                        name="SupplierName"
                                        value={accesspoint.kMs}
                                        // onChange={onChange}
                                        disabled
                                    />
                                    {/* {formErrors.SupplierName && <p className='text-danger text-start'>{formErrors.SupplierName}</p>} */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-1 col-md-2 col-sm-12 mt-lg-0 mt-md-0 mt-2 text-center'>
                        <button type="button"
                            onClick={(e) => handleRemoveAccessPoint(stationType, accesspoint.seqNo)}
                            className="btn btn-primary mx-1" id="btncontactAdd"
                            disabled={disabled ? true : false}
                        >x</button>
                    </div>
                </div>
            ))
            }
        </>
    )
}

export default AccessPointAndList