import React, { useMemo, useState } from 'react'
import Select from 'react-select';
import NearByFacilityAndList from './NearByFacilityAndList';

function NearByFacilities(
    {
        additionInfo,
        setAdditionInfo,
        facilityData, isEditDisable, setIsEditDisable, disabled
    }
) {

    const [nearByFacilities, setNearByFacilities] = useState({
        Hospital: {
            name: '', KMs: ''
        },
        PoliceStation: {
            name: '', KMs: ''
        },
        Shopping: {
            name: '', KMs: ''
        },
        Gallery: {
            name: '', KMs: ''
        }
    });

    const facilityDataList = facilityData?.map(obj => ({
        label: obj?.facilityName,
        value: obj?.attractionFacilityId,
    }))


    const getOptions = useMemo(() => {
        let optios;
        if (additionInfo.AttractionFacilityCollection.length > 0) {
            optios = facilityData.reduce((acc, option) => {
                const checkIsSelected = additionInfo?.AttractionFacilityCollection.some((selectedOption) => selectedOption.attractionFacilityId === option.attractionFacilityId)
                if (option.isActive || checkIsSelected)
                    acc.push({
                        label: option?.facilityName,
                        value: option?.attractionFacilityId,
                    })

                return acc
            }, [])
        } else {
            optios = facilityData.reduce((acc, option) => {
                if (option.isActive) {
                    acc.push({
                        label: option?.facilityName,
                        value: option?.attractionFacilityId,
                    })
                }

                return acc
            }, [])
        }
        return optios
    }, [facilityData])

    const getSeletedOption = (options, selectedOptions) => {
        return selectedOptions?.reduce((acc, facility) => {
            const isSelected = options.find((option) => option.value === facility.attractionFacilityId);
            if (isSelected?.value) {
                acc.push(isSelected)
            };
            return acc
        }, [])
    }


    const handleFacilities = (val) => {
        const getFacilities = val.reduce((acc, facility, index) => {
            acc.push({ attractionFacilityId: facility.value });
            return acc
        }, [])

        setIsEditDisable(false);
        setAdditionInfo({
            ...additionInfo,
            AttractionFacilityCollection: getFacilities
        });
    };

    return (
        <div className='container form_border py-5 my-4'>
            <form action=''>
                <div className='col-lg-12 col-md-12 col-sm-12 px-lg-4 px-md-4'>

                    <div className='row mb-3'>
                        <div className='col-lg-2 col-md-12 col-sm-12'>
                            <label className="col-form-label fw-bold">Available Facilities</label>
                        </div>

                        <div className='col-lg-4 col-md-12 col-sm-12 mb-1'>
                            <div className=" form-lg-check form-lg-check-inline w-100">
                                <Select
                                    className="dropdown select_dropdown"
                                    id='AttractionFacilitieId'
                                    name="AttractionFacilitieId"
                                    options={getOptions}
                                    value={facilityDataList.length > 0 && getSeletedOption(facilityDataList, additionInfo.AttractionFacilityCollection)}
                                    onChange={(val) => handleFacilities(val)}
                                    isOptionDisabled={(option) => additionInfo?.AttractionFacilityCollection?.length >= 10}
                                    placeholder='Select Facilities'
                                    isMulti
                                    isDisabled={disabled ? true : false}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-2 col-md-12 col-sm-12'>
                        <label className="col-form-label "><b>Near by Facilities</b></label>
                    </div>

                    {/* Hospital  */}
                    <NearByFacilityAndList
                        label="Hospital Name"
                        facilityType="Hospital"
                        additionInfo={additionInfo}
                        setAdditionInfo={setAdditionInfo}
                        isEditDisable={isEditDisable}
                        setIsEditDisable={setIsEditDisable}
                        disabled={disabled}
                    />

                    {/* Police Station */}
                    <NearByFacilityAndList
                        label="Police Station"
                        facilityType="PoliceStation"
                        additionInfo={additionInfo}
                        setAdditionInfo={setAdditionInfo}
                        isEditDisable={isEditDisable}
                        setIsEditDisable={setIsEditDisable}
                        disabled={disabled}
                    />

                    {/* Shopping */}
                    <NearByFacilityAndList
                        label="Shopping"
                        facilityType="Shopping"
                        additionInfo={additionInfo}
                        setAdditionInfo={setAdditionInfo}
                        isEditDisable={isEditDisable}
                        setIsEditDisable={setIsEditDisable}
                        disabled={disabled}
                    />

                    {/* Gallery */}
                    <NearByFacilityAndList
                        label="Gallery"
                        facilityType="Gallery"
                        additionInfo={additionInfo}
                        setAdditionInfo={setAdditionInfo}
                        isEditDisable={isEditDisable}
                        setIsEditDisable={setIsEditDisable}
                        disabled={disabled}
                    />

                </div>
            </form>


        </div>
    )
}

export default NearByFacilities