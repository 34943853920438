import React, { useRef } from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from 'react-select';
import "../../assets/css/master.css"
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../services/ApiCallingUserServices';
import Switch from 'react-switch';
import { alphaNumeric, onlyNumeric } from '../commonClass/CommonValidation';
import validator from 'validator';
import { ImageDelete, ImageUpload } from '../../ImageUpload/ImageCommon';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../commonClass/Session';
import { useDispatch } from "react-redux";
import { loaderActions } from "../../_actions/loader.actions";
import { ConfirmationModal } from '../../customControl/ConfirmationModal';
import CustomSelection from '../../customControl/CustomSelection';
import SupplierAccount from './SupplierAccount';
import SupplierAccountManagement from './SupplierAccountManagement'

const Suppliermaster = () => {
    const dispatch = useDispatch();
    //const spplierId = spplierId;
    const supplier = useLocation();
    const navigate = useNavigate();
    const [citydata, setCityData] = useState([]); //locationId,city
    const [cityid, setCity] = useState(0);
    const [locationid, setLocationId] = useState(0);
    const [alllocation, setAllLocation] = useState([]);
    const [allstate, setAllState] = useState([]);
    const [counter, setCounter] = useState(0);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [addisenableaff, setAddIsEnableAff] = useState(true);
    const [incorporationaddfile, setIncorporationAddFile] = useState();
    const [pannoaddfile, setpannoaddfile] = useState();
    const [gstnaddfile, setgstnaddfile] = useState();
    const [incorporationfiletype, setIncorporationType] = useState('');
    const [pannofiletype, setpannofiletype] = useState('');
    const [gstinfiletype, setgstinfiletype] = useState('');
    const [affilitioncerty, setAffilitionCertity] = useState('');
    //const [affilitioncertylist, setAffilitionCertityList] = useState([]);
    const [affilitionstype, setAffilitionType] = useState('');
    const [affilitionstypeerror, setAffilitionTypeError] = useState('');
    const [tragetname, setTargetName] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [activeId, setActiveId] = useState(false);
    const [formafflitionerror, setFormAfflitionError] = useState('');
    const [supplierid, setSupplierId] = useState(0);
    const [retrivedata, setRetriveData] = useState([]);
    const [buttonaddeditText, setButtonAddEditText] = useState("Add");
    const [incorporationfile, setIncorporationFile] = useState();
    const [pannofile, setpannofile] = useState();
    const [gstinfile, setgstinfile] = useState();
    const [incorporationfilename, setIncorporationFileName] = useState();
    const [pannofilename, setpannofilename] = useState();
    const [gstnfilename, setgstnfilename] = useState();
    const [incorporationdelete, setIncorporationDelete] = useState('');
    const [pannodelete, setpannodelete] = useState('');
    const [gstndelete, setgstndelete] = useState('');
    const [disabled, setDisabled] = useState();
    const [deletefilelist, setDeleteFileList] = useState([]);
    const [cleardisable, setClearDisable] = useState(true);
    const [editdisabled, setEditDisabled] = useState(false);
    const [backdisable, setBackDisable] = useState(false);
    const [allCity, setallCity] = useState([]);
    const [statedate, setStateData] = useState([]);
    const [citydisable, setCityDisable] = useState(false);
    const [statedisable, setStateDisable] = useState(false);
    const [suppliertypedata, setSupplierTypeDate] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState();

    const mediacollection = useState([{
        DocName: '',
        FilePath: '',
        FileName: '',
        SeqNo: 0
    }]);
    const mediacollectionlist = useState([{
        File: '',
        FileName: '',
        SeqNo: 0
    }]);
    const [datacollection, setDataCollection] = useState(mediacollection);
    const [filedatacollection, setFileDataCollection] = useState(mediacollectionlist);

    const [form, setForm] = useState({
        supplierId: 0,
        SupplierTypeId: [],
        SupplierName: "",
        HOId: 0,
        Website: "",
        AboutSupplier: "",
        IncorporationNo: "",
        IncorporationCertiPath: "",
        IsActive: false,
        SupplierAddressId: "0",
        LocationId: 0,
        OfficeNo: "",
        HouseNo: "",
        BuildingNo: "",
        BuildingName: "",
        Street: "",
        Locality: "",
        Landmark: "",
        Pincode: "",
        Affiliations: '',
        PANNo: "",
        panDocPath: "",
        GSTNNo: "",
        gstnDocPath: "",
        datacollection: [],
        // tempdata down
        TravelD: '',
        PaymentValueD: '',
        datapaymentdetails: [],
    });

    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            //if (Window.$name && Window.$userToken) {
            if (supplier.state != null) {
                getCityData();
                getallstate();
                getSupplierType();
                setSupplierId(supplier.state.supplierId);
                clear();
                setDisabled(supplier.state.disabled);
                setEditDisabled(supplier.state.editdisabled);
            }
            else {
                navigate('/Supplierlist')
            }
        }
        else {
            ClearLocalStorage();
            // alert('Your Session Expired. Please Login Again!');
            // navigate('/');
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, []);



    useEffect(() => {

    }, [form]);

    const RetriveData = () => {
        retrivedata?.map(obj => {
            form.supplierId = obj.supplierId
            form.SupplierName = obj.supplierName
            form.HOId = obj.hoId
            form.Website = obj.website
            form.AboutSupplier = obj.aboutSupplier
            form.IncorporationNo = obj.incorporationNo
            form.IncorporationCertiPath = obj.incorporationCertiPath
            form.IsActive = obj.isActive
            form.LocationId = obj.locationId
            form.OfficeNo = obj.officeNo
            form.HouseNo = obj.houseNo
            form.BuildingNo = obj.buildingNo
            form.BuildingName = obj.buildingName
            form.Street = obj.street
            form.Locality = obj.locality
            form.Landmark = obj.landmark
            form.Pincode = obj.pincode
            form.PANNo = obj.panNo
            form.panDocPath = obj.panDocPath
            form.GSTNNo = obj.gstnNo
            form.gstnDocPath = obj.gstnDocPath
            form.datacollection = obj.dataCollection
            form.SupplierTypeId = obj.supplierTypeId
            // Check In-Active Data and add in Dropdown...
            let allcitylist = allCity?.filter(IsAct => IsAct.isActive === true);
            setCityData(allCity?.filter(IsAct => IsAct.isActive === true));
            setCityDisable(false);
            if (!allcitylist?.find(IsCity => IsCity.locationId === obj.locationId)) {
                setCityDisable(true)
                setCityData((prvCityData) => prvCityData?.length > 0 ? [...prvCityData, allCity?.find(isCity => isCity.locationId === obj.locationId)] : [])
                // setCityData((prvCityData) => [...prvCityData, allCity?.find(isCity => isCity.locationId === obj.locationId)])

            }
            setCity(obj.locationId);
            getalllocationbyCity(obj.locationId);
            let allstatelist = allstate?.filter(IsAct => IsAct.isActive === true);
            setStateData(allstate?.filter(IsAct => IsAct.isActive === true));
            setStateDisable(false);
            if (!allstatelist?.find(IsCity => IsCity.stateId === obj.stateId)) {
                setStateDisable(true)
                setStateData((prvCityData) => prvCityData?.length > 0 ? [...prvCityData, allstate?.find(isCity => isCity.stateId === obj.stateId)] : [])

            }
            setLocationId(obj.stateId);

            setIncorporationFile(obj.incorporationCertiPath?.split("/").pop());
            setIncorporationDelete(obj.incorporationCertiPath);
            setIncorporationType(obj.incorporationCertiPath?.split(".").pop());

            setpannofile(obj.panDocPath?.split("/").pop());
            setpannodelete(obj.panDocPath);
            setpannofiletype(obj.panDocPath?.split(".").pop());

            setgstinfile(obj.gstnDocPath?.split("/").pop());
            setgstndelete(obj.gstnDocPath);
            setgstinfiletype(obj.gstnDocPath?.split(".").pop());
            setActiveId(obj.isActive);
            if (obj.supplierTypeId == null) {
                setSelectedOptions([])
            }
            else {
                setSelectedOptions(suppliertypedata?.filter(t => obj.supplierTypeId?.includes(t.value)))
            }
        });
        const seqnolist = form.datacollection?.map((obj) => {
            return obj.seqNo;
        });
        if (form.datacollection !== null) {
            const max = Math.max(...seqnolist);
            setCounter(max);
        }
        else {
            setCounter(0);
        }
    }

    const handleRetrive = async () => {
        setButtonAddEditText("Update");
        const supplierId = supplier.state.supplierId;
        const supplierbyid = await postUsersData('/master/v1/getsupplierbyid', { supplierId });
        setRetriveData(supplierbyid.data);
    }
    useEffect(() => {
        if (retrivedata?.length === 1) {
            RetriveData();
            dispatch(loaderActions.end());
        }
    }, [retrivedata]);
    const getCityData = async () => {
        await getAllData("/master/v1/getairportcity").then((CityData) => {
            setallCity(CityData.data);
            setCityData(CityData.data?.filter(IsAct => IsAct.isActive === true));
            if (supplier.state.supplierId > 0) {
                //setSupplierId(supplier.state.supplierId);
                handleRetrive();
            }
            else {
                setClearDisable(false);
                dispatch(loaderActions.end());
            }
        });
    }
    const cityList = citydata?.map(value => ({
        label: value.city,
        value: value.locationId
    }));
    const getSupplierType = async () => {
        await getAllData("/master/v1/getsuppliertype").then((Typedata) => {
            setSupplierTypeDate(Typedata.data?.map((el) => ({
                label: el.label,
                value: el.value
            })))
        });
    }

    const getallstate = async () => {
        const allstatelist = await getAllData('/master/v1/gethostate');
        setAllState(allstatelist.data);
        setStateData(allstatelist.data?.filter(IsAct => IsAct.isActive === true));
    }
    const stateList = allstate?.map(value => ({
        label: value.stateName,
        value: value.stateId
    }));

    const handlecity = (e) => {
        form.LocationId = e.value
        //setLocationId(e.value);
        setCity(e.value);
        getalllocationbyCity(e.value);
        setFormErrors('');
        setFormAfflitionError('');
        setEditDisabled(false);
        // e === null ? setCity(0) : setCity(e.value);
        // e === null ? getalllocationbyCity(0) : getalllocationbyCity(e.value);
    }
    const handleSupplierType = (e) => {
        setFormErrors('');
        setForm({ ...form, SupplierTypeId: e.map((el) => el.value) })
        setSelectedOptions(e);
        setEditDisabled(false);
    }
    const handlestatechange = (e) => {
        form.HOId = e.value
        //setCity(e.value);
        setLocationId(e.value);
        setFormErrors('');
        setEditDisabled(false);
    }
    const getalllocationbyCity = async (locationid) => {
        const locid = { LocationId: locationid }
        const AllLocationData = await postUsersData('/master/v1/getlocationbyid', locid)
        setAllLocation(AllLocationData.data);
    }
    const continent = alllocation?.map(value => ({
        label: value.continentName,
        value: 1
    })
    );

    const country = alllocation?.map(value => ({
        label: value.countryName,
        value: 1
    })
    );

    const region = alllocation?.map(value => ({
        label: value.regionName,
        value: 1
    })
    );

    const state = alllocation?.map(value => ({
        label: value.stateName,
        value: 1
    })
    );

    const validate = (values) => {
        setIsSubmit(true);
        const error = {}
        if (values.LocationId === 0) {
            error.LocationId = "Please select city";
            setIsSubmit(false);
        }
        if (values.SupplierTypeId === null || values.SupplierTypeId.length === 0) {
            error.SupplierTypeId = "Kindly select the supplier type"
            setIsSubmit(false);
        }
        if (!values.SupplierName || values.SupplierName?.trim()?.length === 0) {
            error.SupplierName = "Please enter Name of the company";
            setIsSubmit(false);
        }
        if (values.SupplierName?.length > 150) {
            error.SupplierName = "Maximum length is 150 characters";
            setIsSubmit(false);
        }
        if (values.HOId === 0) {
            error.HOId = "Please select State of Incorporation";
            setIsSubmit(false);
        }
        if (!values.OfficeNo) {
            error.OfficeNo = "Please enter office Numbers";
            setIsSubmit(false);
        }

        if (values.OfficeNo?.length > 15) {
            error.OfficeNo = "Maximum 15 digits are allowed";
            setIsSubmit(false);
        }
        if (isNaN(values.OfficeNo)) {
            error.OfficeNo = "Only Numbers are allowed in this field.";
            setIsSubmit(false);
        }
        if (values.BuildingName?.length > 50) {
            error.BuildingName = "Maximum 50 characters can be entered.";
            setIsSubmit(false);
        }
        if (!values.Street.trim()) {
            error.Street = "Please enter street";
            setIsSubmit(false);
        }
        if (values.Street?.length > 50) {
            error.Street = "Maximum 50 characters can be entered.";
            setIsSubmit(false);
        }
        if (values.Locality?.length > 50) {
            error.Locality = "Maximum 50 characters can be entered.";
            setIsSubmit(false);
        }
        if (values.Landmark?.length > 50) {
            error.Landmark = "Maximun 50 characters can be entered.";
            setIsSubmit(false);
        }
        if (!values.Pincode.trim()) {
            error.Pincode = "Please enter pincode";
            setIsSubmit(false);
        }
        if (values.Pincode.length > 10) {
            error.Pincode = "Maximum 10 digits are allowed.";
            setIsSubmit(false);
        }
        if (isNaN(values.Pincode)) {
            error.Pincode = "Only Numbers are allowed in this field.";
            setIsSubmit(false);
        }

        if (values.Website?.length > 0 && !validator.isURL(values.Website)) {
            if (!validator.isURL(values.Website)) {
                // if (!validateURL(values.Website)) {
                error.Website = "Only website links can be added here.";
                setIsSubmit(false);
            }
        }
        // if (!values.Website) {
        //     error.Website = "Only website links can be added here.";
        //     setIsSubmit(false);
        // }
        // if (!values.AboutSupplier) {
        //     error.AboutSupplier = "Please enter about Supplier";
        //     setIsSubmit(false);
        // }
        // if (!values.IncorporationNo || values.IncorporationNo.trim().length === 0) {
        //     error.IncorporationNo = "Please enter incorporation no";
        //     form.IncorporationNo = '';
        //     setIsSubmit(false);
        // }
        if (values.IncorporationNo?.length > 20) {
            error.IncorporationNo = "Maximum length is 20 characters.";
            setIsSubmit(false);
        }
        if (values.IncorporationCertiPath?.length > 0 && incorporationfiletype !== "pdf") {
            error.IncorporationCertiPath = "Only PDF file can be uploaded.";
            setIsSubmit(false);
        }
        if (values.PANNo?.length > 10) {
            error.PANNo = "Maximum length is 10 characters.";
            setIsSubmit(false);
        }
        else {
            if (values.PANNo?.length > 0) {
                let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
                if (regex.test(values.PANNo) === false) {
                    error.PANNo = "Please enter Valid PAN Number.";
                    setIsSubmit(false);
                }
            }
        }
        if (values.panDocPath?.length > 0 && pannofiletype !== "pdf") {
            error.panDocPath = "Only PDF file can be uploaded.";
            setIsSubmit(false);
        }
        if (values.GSTNNo?.length > 15) {
            error.GSTNNo = "Maximum length is 15 characters.";
            setIsSubmit(false);
        }
        else {
            if (values.GSTNNo?.length > 0) {
                let regex = new RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}Z[0-9A-Z]{1}$/);
                if (regex.test(values.GSTNNo) === false) {
                    error.GSTNNo = "Please enter Valid GSTN Number.";
                    setIsSubmit(false);
                }
            }
        }
        if (values.gstnDocPath?.length > 0 && gstinfiletype !== "pdf") {
            error.gstnDocPath = "Only PDF file can be uploaded.";
            setIsSubmit(false);
        }

        // if (!values.IncorporationCertiPath) {
        //     error.IncorporationCertiPath = "Please select Incorporation certificate";
        //     setIsSubmit(false);
        // }
        // if (!values.HouseNo) {
        //     error.HouseNo = "Please enter house number";
        //     setIsSubmit(false);
        // }
        // if (!values.BuildingName) {
        //     error.BuildingName = "Please enter building name";
        //     setIsSubmit(false);
        // }


        // if (!values.Locality) {
        //     error.Locality = "Please enter locality";
        //     setIsSubmit(false);
        // }

        // if (!values.Landmark) {
        //     error.Landmark = "Please enter landmark";
        //     setIsSubmit(false);
        // }
        // if (!values.Website) {
        //     error.Website = "Please enter website link.";
        //     setIsSubmit(false);
        // }
        // else {
        //     if (!validator.isURL(values.Website)) {
        //     // if (!validateURL(values.Website)) {
        //         error.Website = "Only website links can be added here.";
        //         setIsSubmit(false);
        //     }
        // }
        // if (!values.AboutSupplier) {
        //     error.AboutSupplier = "Please enter about supplier.";
        //     setIsSubmit(false);
        // }
        // if (values.AboutSupplier.length > 500) {
        //     error.AboutSupplier = "Maximum length of the box is 500 characters.";
        //     setIsSubmit(false);
        // }

        //(affilitioncerty.name?.length || 0) > 0Object.keys(obj).length
        const datacollectionlen = datacollection?.filter(obj => {
            return obj.SeqNo > 0;
        });
        // if (datacollectionlen.length === 0) {
        //     setFormAfflitionError("Please add at least one affiliation document.");
        //     setIsSubmit(false);
        // }
        // else {
        //     setFormAfflitionError('');
        // }
        return error;
    }

    const onChange = (e) => {
        var result;
        if ([e.target.name][0] === 'OfficeNo') {
            result = onlyNumeric(e.target.value)
        }
        else if ([e.target.name][0] === 'PANNo') {
            result = alphaNumeric(e.target.value.toUpperCase())
            // result = result.toUpperCase()
        }
        else if ([e.target.name][0] === 'GSTNNo') {
            result = alphaNumeric(e.target.value.toUpperCase())
        }
        else {
            result = e.target.value;
        }
        setForm({ ...form, [e.target.name]: result });
        // const { name, value } = e.target;
        // setForm({ ...form, [name]: value });
        setEditDisabled(false);
        setFormErrors('');
        setFormAfflitionError('');
        if (affilitionstype !== 'pdf')
            setAffilitionType('');
        setAffilitionTypeError('');
    }

    useEffect(() => {

    }, [affilitioncerty]);

    const handleuploadIncorpration = (e) => {
        const files = e.target.files;
        const filecom = getDateCombination();
        const filename = filecom + '_' + '1' + '_' + (files[0].name.replace(/ /g, "_")).replace(/-/g, '');
        form.IncorporationCertiPath = 'CMS/SupplierDetail/IncorprationCertificate/' + filename
        setIncorporationAddFile(e.target.files[0]);
        setIncorporationFileName(filename);
        setIncorporationType(e.target.value.split(".").pop());
        setFormErrors('');
        setIncorporationFile('');
        setEditDisabled(false);
    }

    const handleuploadPANNo = (e) => {
        const files = e.target.files;
        const filecom = getDateCombination();
        const filename = filecom + '_' + '1' + '_' + (files[0].name.replace(/ /g, "_")).replace(/-/g, '');
        form.panDocPath = 'CMS/SupplierDetail/PANNoCertificate/' + filename
        setpannoaddfile(e.target.files[0]);
        setpannofilename(filename);
        setpannofiletype(e.target.value.split(".").pop());
        setFormErrors('');
        setpannofile('');
        setEditDisabled(false);
    }

    const handleuploadGSTNNo = (e) => {
        const files = e.target.files;
        const filecom = getDateCombination();
        const filename = filecom + '_' + '1' + '_' + (files[0].name.replace(/ /g, "_")).replace(/-/g, '');
        form.gstnDocPath = 'CMS/SupplierDetail/GSTNCertificate/' + filename
        setgstnaddfile(e.target.files[0]);
        setgstnfilename(filename);
        setgstinfiletype(e.target.value.split(".").pop());
        setFormErrors('');
        setgstinfile('');
        setEditDisabled(false);
    }

    const handleuploadAffilitionCertifycate = (e) => {
        setEditDisabled(false);
        setAffilitionType(e.target.value.split(".").pop());
        setAffilitionCertity(e.target.files[0]);
        setAffilitionTypeError('');
        setFormErrors('');
        setFormAfflitionError('');
    }

    const handleClick = () => {
        const datacollectionlen = datacollection?.filter(obj => {
            return obj.SeqNo > 0;
        });
        if (datacollectionlen?.length > 0) {
            setFormAfflitionError('');
        }
        setTargetName('btnAdd');

        if ((affilitioncerty.name?.length || 0) > 0 && affilitionstype !== 'pdf') {
            setAffilitionTypeError('Only PDF file can be uploaded.');
        }

        // if (affilitionstype === 'pdf' && form.Affiliations.trim().length === 0 && (affilitioncerty.name?.length || 0) > 0) {
        //     setFormAfflitionError('This field should not be empty');
        //     form.Affiliations = '';
        // }


        if ((affilitionstype === 'pdf' && (affilitioncerty.name?.length || 0) > 0) || form.Affiliations.trim().length > 0) {
            setCounter(counter + 1);
        }

        // if (affilitionstype !== 'pdf') {
        //     setFormAfflitionError('This field should not be empty');
        //     form.Affiliations = '';
        // }
        // if (affilitionstype === 'pdf') {
        //     setCounter(counter + 1);
        // }
    };

    const removeAffilitionDoc = (id, e) => {
        setTargetName(e.target.id);//btnRemove
        datacollection?.map((obj) => {

            if (obj.SeqNo === id) {
                setDeleteFileList([...deletefilelist, { FileName: obj.FilePath }])
            }
        });
        setDataCollection((oldState) => oldState?.filter((item) => item.SeqNo !== id));
        setFileDataCollection((oldState) => oldState?.filter((item) => item.SeqNo !== id));
        setEditDisabled(false);
        //setCounter(counter - 1);

    };

    function AddDataCollectionToRow() {
        if (tragetname === 'btnAdd') {
            if (counter > 0) {
                setFormAfflitionError('');
                var filepath = "";
                var filename = "";
                if ((affilitioncerty.name?.length || 0) > 0) {
                    const filecom = getDateCombination();
                    filename = filecom + '_' + counter + '_' + (affilitioncerty.name.replace(/ /g, "_")).replace(/-/g, '');
                    filepath = 'CMS/SupplierDetail/AffilitionCertificate/' + filename;
                }

                if (counter === 1) {
                    setDataCollection(current => [{ SeqNo: counter, DocName: form.Affiliations, FileName: filename, FilePath: filepath }]);
                    setFileDataCollection(current => [{ SeqNo: counter, FileName: filename, File: affilitioncerty }]);
                }
                else {
                    setDataCollection(current => [...current, { SeqNo: counter, DocName: form.Affiliations, FileName: filename, FilePath: filepath }]);
                    setFileDataCollection(current => [...current, { SeqNo: counter, FileName: filename, File: affilitioncerty }]);
                }


                setAffilitionCertity("");
                // const filecom = getDateCombination();
                // const filename = filecom + '_' + counter + '_' + (affilitioncerty.name.replace(/ /g, "_")).replace(/-/g, '');
                // const filepath = 'CMS/SupplierDetail/AffilitionCertificate/' + filename;


                // if (counter === 1) {
                //     setDataCollection(current => [{ SeqNo: counter, DocName: form.Affiliations, FileName: filename, FilePath: filepath }]);
                //     setFileDataCollection(current => [{ SeqNo: counter, FileName: filename, File: affilitioncerty }]);
                // }
                // else {
                //     setDataCollection(current => [...current, { SeqNo: counter, DocName: form.Affiliations, FileName: filename, FilePath: filepath }]);
                //     setFileDataCollection(current => [...current, { SeqNo: counter, FileName: filename, File: affilitioncerty }]);
                // }
            }
        }

    }

    useEffect(() => {
        if (tragetname?.length > 0)
            AddDataCollectionToRow();
        else {
            AddDataCollectionRowAtRetrive();
        }
    }, [counter]);

    function AddDataCollectionRowAtRetrive() {
        form.datacollection?.map(obj => {
            const colllen = datacollection?.length || 0;
            if (colllen === 2) {

                setDataCollection(current => [{ SeqNo: obj.seqNo, DocName: obj.docName, FileName: obj.fileName, FilePath: obj.filePath }]);
            }
            else {
                setDataCollection(current => [...current, { SeqNo: obj.seqNo, DocName: obj.docName, FileName: obj.fileName, FilePath: obj.filePath }]);
            }

        });

    }
    // useEffect(() => {

    // },[retrivedata]);
    useEffect(() => {
        if (supplier.state != null) {
            setForm({ ...form, ['Affiliations']: '' });
            document.getElementById("txtAffiliationsfile").value = "";
            setAffilitionType('');
            if (supplier.state.viewtype === 'View') {
                setAddIsEnableAff(false);
            }
            else if (datacollection?.length > 5) {
                setAddIsEnableAff(false);
            }
            else {
                setAddIsEnableAff(true);
            }
        }

    }, [datacollection]);

    const LocationId = useRef(null);
    const HOId = useRef(null);

    useEffect(() => {
        // Be sure that sequence of form fields and sequence of formErrors variables/fields are same, because every time focusing is done by the formErrors Array's top value. 
        if (Object.keys(formErrors)?.length === 0 && isSubmit) {
            setDisabled(true);
            setClearDisable(true);
            setEditDisabled(true);
            setBackDisable(true);
            handleSubmitData();
        }
        if (Object.keys(formErrors).length > 0) {

            let id = Object.keys(formErrors)[0];
            if (id === "LocationId" || id === "HOId") {
                eval(id).current.focus();
            }
            else {
                let doc = document.getElementById(id);
                if (doc !== null)
                    doc.focus();
            }
        }

    }, [formErrors]);
    useEffect(() => {

    }, [isSubmit]);

    const handleClearClick = () => {
        clear();
    }
    function clear() {
        setIsSubmit(false);
        setFormErrors('');
        setForm({
            ...form, ['SupplierName']: '', ['HOId']: 0, ['Website']: '', ['AboutSupplier']: '', ['IncorporationNo']: '',
            ['IncorporationCertiPath']: '', ['IsActive']: false, ['LocationId']: 0, ['OfficeNo']: '', ['HouseNo']: '',
            ['BuildingNo']: '', ['BuildingName']: '', ['Street']: '', ['Locality']: '', ['Landmark']: '', ['Pincode']: '',
            ['PANNo']: '', ['GSTNNo']: '', ['Affiliations']: '', ['SupplierTypeId']: []
        });
        setCounter(0);
        setCity();
        setAllLocation([]);
        setDataCollection([]);
        setFileDataCollection([]);
        setLocationId();
        setAffilitionType('');
        setAffilitionTypeError('');
        setFormAfflitionError('');
        setAddIsEnableAff(true);
        setAffilitionCertity('');
        setActiveId(false);
        setSelectedOptions([])
        setIncorporationFile('');
        setIncorporationFileName('');
        setIncorporationAddFile('');
        setSupplierId(0);
        setAllState([]);
        setIncorporationType('');
        setIncorporationDelete('');
        setDeleteFileList([]);
        document.getElementById("txtIncorporationCertiPath").value = "";
        document.getElementById("txtpanDocPath").value = "";
        document.getElementById("txtgstnDocPath").value = "";
        document.getElementById("txtAffiliationsfile").value = "";
        getCityData();
        getallstate();
        setCityDisable(false);
        setStateDisable(false)
        setCityData([]);
        setStateData([]);
    }
    // const handleisActive = (e) => {
    //     { form.IsActive = e }
    //     setActiveId(!activeId)
    //     setEditDisabled(false);
    // }
    const handleisActive = (e) => {
        if (buttonaddeditText === 'Add') {
            form.IsActive = e
            setActiveId(!activeId)
            setFormErrors('');
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (e) => {
        //setYesNoConfirm(e)
        if (e === true) {
            form.IsActive = !activeId
            setActiveId(!activeId)
            setEditDisabled(false);
            setFormErrors('');
        }
        setModalShow(false);
    }

    const onSubmitForm = e => {
        e.preventDefault();
        setFormErrors(validate(form));
        //handleSubmitData();
    };

    const handleSubmitData = async () => {
        let statusoftoken = await verifytoken();
        form.datacollection = datacollection?.map(value => ({
            SeqNo: value.SeqNo,
            DocName: value.DocName,
            FileName: value.FileName,
            FilePath: value.FilePath
        }));
        if (buttonaddeditText === 'Add') {
            if (statusoftoken) {
                //alert('Data Inserted Successfully');
                const SupplierData = await postUsersData('/master/v1/insertsupplier', form)
                const status = SupplierData.status;
                if (status) {
                    try {
                        try {
                            await handleimageupload('CMS/SupplierDetail/PANNoCertificate/', pannofilename, pannoaddfile);
                        }
                        catch (err1) {
                            dispatch({ type: 'ALERTINGMESSAGE', message: err1 })
                            // dispatch({ type: 'ALERTING', message: err1 })
                            // alert(err1);
                            setDisabled(false);
                            setClearDisable(false);
                            setEditDisabled(false);
                            setBackDisable(false);
                        }
                        try {
                            await handleimageupload('CMS/SupplierDetail/GSTNCertificate/', gstnfilename, gstnaddfile);
                        }
                        catch (err2) {
                            dispatch({ type: 'ALERTINGMESSAGE', message: err2 })
                            // dispatch({ type: 'ALERTING', message: err2 })
                            // alert(err2);
                            setDisabled(false);
                            setClearDisable(false);
                            setEditDisabled(false);
                            setBackDisable(false);
                        }
                        try {
                            await handleimageupload('CMS/SupplierDetail/IncorprationCertificate/', incorporationfilename, incorporationaddfile);
                        }
                        catch (err) {
                            dispatch({ type: 'ALERTINGMESSAGE', message: err })
                            // dispatch({ type: 'ALERTING', message: err })
                            // alert(err);
                            setDisabled(false);
                            setClearDisable(false);
                            setEditDisabled(false);
                            setBackDisable(false);
                            return;
                        }
                    }
                    catch (err) {
                        dispatch({ type: 'ALERTINGMESSAGE', message: err })
                        // dispatch({ type: 'ALERTING', message: err })
                        // alert(err);
                        setDisabled(false);
                        setClearDisable(false);
                        setEditDisabled(false);
                        setBackDisable(false);
                        return;
                    }
                    const filelen = filedatacollection?.length;
                    for (var i = 0; i < filelen; i++) {
                        try {
                            await handleimageupload('CMS/SupplierDetail/AffilitionCertificate/', filedatacollection[i].FileName, filedatacollection[i].File);
                        }
                        catch (err) {
                            dispatch({ type: 'ALERTINGMESSAGE', message: err })
                            // dispatch({ type: 'ALERTING', message: err })
                            // alert(err);
                            setDisabled(false);
                            setClearDisable(false);
                            setEditDisabled(false);
                            setBackDisable(false);
                            return;
                        }

                    }

                    dispatch({ type: 'ALERTINGMESSAGE', message: SupplierData.message })
                    // dispatch({ type: 'ALERTING', message: 'Record saved successfully.' })
                    // alert('Record saved successfully.');
                    clear();
                    setDisabled(false);
                    setClearDisable(false);
                    setEditDisabled(false);
                    setBackDisable(false);
                    // navigate('/supplierlist');
                }
                else {
                    dispatch({ type: 'ALERTINGMESSAGE', message: SupplierData.message })
                    // dispatch({ type: 'ALERTING', message: 'Try again later!' })
                    // alert('Try again later!');
                    setDisabled(false);
                    setClearDisable(false);
                    setEditDisabled(false);
                    setBackDisable(false);
                    return;
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
        else {
            if (statusoftoken) {
                const SupplierData = await patchUsersData('/master/v1/updatesupplier', form)
                const status = SupplierData.status;
                //const  status = true;
                if (status) {
                    try {

                        try {
                            if (pannofilename?.length > 0) {
                                await handleimageupload('CMS/SupplierDetail/PANNoCertificate/', pannofilename, pannoaddfile);

                            }


                        }
                        catch (err1) {
                            dispatch({ type: 'ALERTINGMESSAGE', message: err1 })
                            // dispatch({ type: 'ALERTING', message: err1 })
                            // alert(err1);
                            setDisabled(false);
                            setClearDisable(false);
                            setEditDisabled(false);
                            setBackDisable(false);
                            return;
                        }
                        try {
                            if (gstnfilename?.length > 0) {
                                await handleimageupload('CMS/SupplierDetail/GSTNCertificate/', gstnfilename, gstnaddfile);
                            }


                        }
                        catch (err2) {
                            dispatch({ type: 'ALERTINGMESSAGE', message: err2 })
                            // dispatch({ type: 'ALERTING', message: err2 })
                            // alert(err2);
                            setDisabled(false);
                            setClearDisable(false);
                            setEditDisabled(false);
                            setBackDisable(false);
                            return;
                        }



                        try {
                            if (incorporationfile?.length > 0) {
                                await handleimageupload('CMS/SupplierDetail/IncorprationCertificate/', incorporationfilename, incorporationaddfile);

                            }

                        }
                        catch (err) {
                            dispatch({ type: 'ALERTINGMESSAGE', message: err })
                            // dispatch({ type: 'ALERTING', message: err })
                            // alert(err);
                            setDisabled(false);
                            setClearDisable(false);
                            setEditDisabled(false);
                            setBackDisable(false);
                            return;
                        }

                        try {

                            if (pannofilename?.length > 0) {
                                await handleimageDelete(pannodelete);
                            }
                            if (gstnfilename?.length > 0) {
                                await handleimageDelete(gstndelete);
                            }
                            if (incorporationfile?.length > 0) {
                                await handleimageDelete(incorporationdelete);
                            }
                        }
                        catch (err) {
                            dispatch({ type: 'ALERTINGMESSAGE', message: err })
                            // dispatch({ type: 'ALERTING', message: err })
                            // alert(err);
                            setDisabled(false);
                            setClearDisable(false);
                            setEditDisabled(false);
                            setBackDisable(false);
                            return;
                        }
                    }
                    catch (err) {
                        dispatch({ type: 'ALERTINGMESSAGE', message: err })
                        // dispatch({ type: 'ALERTING', message: err })
                        // alert(err);
                        return;
                    }
                    const deletefile = deletefilelist?.length;
                    if (deletefile > 0) {
                        for (var d = 0; d < deletefile; d++) {
                            try {
                                await handleimageDelete(deletefilelist[d].FileName);
                                // await handleimageDelete('20221019_174516_2_ANNEX881056236749953.pdf');
                            }
                            catch (err) {
                                dispatch({ type: 'ALERTINGMESSAGE', message: err })
                                // dispatch({ type: 'ALERTING', message: err })
                                // alert(err);
                                setDisabled(false);
                                setClearDisable(false);
                                setEditDisabled(false);
                                setBackDisable(false);
                                return;
                            }
                        }
                    }

                    const filelen = filedatacollection?.length;
                    for (var i = 0; i < filelen; i++) {
                        try {
                            await handleimageupload('CMS/SupplierDetail/AffilitionCertificate/', filedatacollection[i].FileName, filedatacollection[i].File);
                        }
                        catch (err) {
                            dispatch({ type: 'ALERTINGMESSAGE', message: err })
                            // dispatch({ type: 'ALERTING', message: err })
                            // alert(err);
                            setDisabled(false);
                            setClearDisable(false);
                            setEditDisabled(false);
                            setBackDisable(false);
                            return;
                        }

                    }
                    dispatch({ type: 'ALERTINGMESSAGE', message: SupplierData.message })
                    // dispatch({ type: 'ALERTING', message: 'Record Updated successfully.' })
                    // alert('Record Updated successfully.');
                    clear();
                    // navigate('/supplierlist');
                }
                else {
                    dispatch({ type: 'ALERTINGMESSAGE', message: SupplierData.message })
                    // dispatch({ type: 'ALERTING', message: 'Try again later!' })
                    // alert('Try again later!');
                    setDisabled(false);
                    setClearDisable(false);
                    setEditDisabled(false);
                    setBackDisable(false);
                    return;
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }

    }

    //#region inclusion start
    const [travelvalue, setTravel] = useState([]);
    const [paymentvalue, setPaymentValue] = useState([]);
    const TravelDetails = useState([{
        Travel: '',
        PaymentValue: '',
        SeqNo: 0
    }]);
    const [datapaymentdetails, setPaymentDetails] = useState(TravelDetails);
    const [datapaymentcounter, setPaymentCounter] = useState(0);
    const paymentClick = () => {
        setPaymentCounter(datapaymentcounter + 1);
    };
    function AddDataRowForPaymentDetails() {
        if (datapaymentcounter === 1) {
            setPaymentDetails(current => [{ SeqNo: datapaymentcounter, Travel: form.TravelD, PaymentValue: form.PaymentValueD }]);
        }
        else {
            setPaymentDetails(current => [...current, { SeqNo: datapaymentcounter, Travel: form.TravelD, PaymentValue: form.PaymentValueD }]);
        }

    }
    useEffect(() => {
        AddDataRowForPaymentDetails();
    }, [datapaymentcounter]);
    const Paymentremovev = (id, e) => {
        //setCounter(counter + -1);
        setPaymentDetails((oldState) => oldState.filter((item) => item.SeqNo !== id));
    }
    const options = [
        { value: 1, label: 'Chocolate' },
        { value: 2, label: 'Strawberry' },
        { value: 3, label: 'Vanilla' }
    ]
    const handlePaymentvaluechange = (e) => {
        form.TravelD = e.label
        const Paymenttype = [
            { value: 1, label: e.label }
        ]
        setTravel(Paymenttype);
        //setInclusion(current => [...current , {vehichaltype}]);
        //vehicaltypevalue
    }
    const handlepaymentvalue = (e) => {
        form.PaymentValueD = e.label
        const Paymentvaluetype = [
            { value: 1, label: e.label }
        ]
        setPaymentValue(Paymentvaluetype);
    }
    //#endregion 

    const handlebackClick = () => {
        clear();
        navigate('/Supplierlist');
    }
    const handleimageupload = async (pathdirectory, filename, file) => {
        await ImageUpload(pathdirectory, filename, file);
    }
    const handleimageDelete = async (filename) => {
        await ImageDelete(filename);
    }
    function getDateCombination() {
        var today = new Date();
        let date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
        let time = today.getHours().toString() + (today.getMinutes() + 1).toString() + today.getSeconds().toString();
        return date + '_' + time;
    }

    return (
        <>

            <div class="container mt-4 heading_color">
                <h3>Supplier Management</h3>
            </div>
            <div className='container mt-3 tab_section px-0'>
                <ul class="nav nav-pills nav-justified mb-3 pt-3" id="pills-tab" role="tablist">
                    <li class="nav-item mx-lg-5 mx-md-1 mx-5 mt-1" role="presentation">
                        <a class="nav-link active" id="pills-profile-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-about"
                            aria-selected="true"><strong>Supplier Profile</strong></a>
                    </li>
                    <li class="nav-item mx-lg-5 mx-md-1 mx-5  mt-1" role="presentation" >
                        <a class="nav-link" id="pills-Account-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-Account" type="button" role="tab" aria-controls="pills-tips"
                            aria-selected="false"><strong >Account Details</strong></a>
                    </li>
                    <li class="nav-item mx-lg-5 mx-md-1 mx-5  mt-1" role="presentation">
                        <a class="nav-link" id="pills-Management-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-Management" type="button" role="tab" aria-controls="pills-club"
                            aria-selected="false"><strong >ETM Account Management</strong></a>
                    </li>
                </ul>
            </div>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-profile" role="tabpanel"
                    aria-labelledby="pills-profile-tab">
                    <div class="container form_border py-5 my-3">
                        <form action="" >
                            <div class="mb-3 row mx-1">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="row">
                                        <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                                            <div class="col-lg-12 col-md-12">
                                                <label for="inputPassword6" class="col-form-label required">Continent</label>
                                            </div>
                                            <div class="col-lg-12 mb-1 col-md-12">
                                                <Select className="dropdown select_dropdown"
                                                    isDisabled
                                                    value={continent?.find(({ value }) => value === 1)}
                                                    options={continent}
                                                />
                                            </div>

                                        </div>
                                        <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                                            <div class="col-lg-12 col-md-12">
                                                <label for="inputPassword6" class="col-form-label required">Country</label>
                                            </div>
                                            <div class="col-lg-12 mb-1 col-md-12">
                                                <Select className="dropdown select_dropdown"
                                                    isDisabled
                                                    value={country?.find(({ value }) => value === 1)}
                                                    options={country} />
                                            </div>

                                        </div>
                                        <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                                            <div class="col-lg-12 col-md-12">
                                                <label for="inputPassword6" class="col-form-label required">Region</label>
                                            </div>
                                            <div class="col-lg-12 mb-1 col-md-12">
                                                <Select className="dropdown select_dropdown"
                                                    isDisabled
                                                    value={region?.find(({ value }) => value === 1)}
                                                    options={region} />
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                                            <div class="col-lg-12 col-md-12">
                                                <label for="inputPassword6" class="col-form-label required">State</label>
                                            </div>
                                            <div class="col-lg-12 mb-1 col-md-12">
                                                <Select className="dropdown select_dropdown"
                                                    isDisabled
                                                    value={state?.find(({ value }) => value === 1)}
                                                    options={state} />
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                                            <div class="col-lg-12 col-md-12">
                                                <label for="inputPassword6" class="col-form-label required">City</label>
                                            </div>
                                            <div class="col-lg-12 mb-1 col-md-12">
                                                {/* <Select className="dropdown select_dropdown"
                                            options={cityList}
                                            value={cityList?.filter(function (cityList) {
                                                return cityList.value === cityid;
                                            })}// set list of the data
                                            onChange={handlecity}// assign onChange function
                                            isDisabled={disabled ? true : false}
                                        /> */}
                                                <CustomSelection
                                                    datalist={cityList}
                                                    selId='LocationId'
                                                    selRef={LocationId}
                                                    fieldid={cityid}
                                                    SelectionChange={handlecity}
                                                    isdisable={disabled}
                                                    isactivedisable={citydisable}
                                                    issearchable={true}
                                                />
                                                {formErrors.LocationId && <p className='text-danger text-start'>{formErrors.LocationId}</p>}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-1 mt-4 col-lg-10 mx-lg-5">
                                <div className='row'>
                                    <div className='col-lg-6 col-sm-6 '>
                                        <div className='row'>
                                            <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                <label for="inputPassword6" class="col-form-label required">Supplier Type</label>
                                            </div>
                                            <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                <Select
                                                    className="dropdown select_dropdown"
                                                    options={suppliertypedata}
                                                    // value={suppliertypedata?.filter(function (suppliertypedata) {
                                                    // return suppliertypedata.value === typeid;
                                                    // })}// set list of the dataalue 
                                                    value={selectedOptions}
                                                    onChange={handleSupplierType} // assign onChange function
                                                    isDisabled={disabled ? true : false}
                                                    isSearchable={true}
                                                    isMulti

                                                />
                                                {formErrors.SupplierTypeId && <p className='text-danger text-start'>{formErrors.SupplierTypeId}</p>}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-sm-6'>
                                        <div className='row'>
                                            <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                <label for="inputPassword6" class="col-form-label required">State of Incorporation</label>
                                            </div>
                                            <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                {/* <Select className="dropdown select_dropdown"
                                            options={stateList}
                                            value={stateList?.filter(function (stateList) {
                                                return stateList.value === locationid;
                                            })}// set list of the data
                                            name="HOId"
                                            onChange={handlestatechange}
                                            isDisabled={disabled ? true : false}
                                        /> */}
                                                <CustomSelection
                                                    datalist={stateList}
                                                    selId='HOId'
                                                    selRef={HOId}
                                                    fieldid={locationid}
                                                    SelectionChange={handlestatechange}
                                                    isdisable={disabled}
                                                    isactivedisable={statedisable}
                                                    issearchable={true}
                                                />
                                                {formErrors.HOId && <p className='text-danger text-start'>{formErrors.HOId}</p>}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-sm-6 '>
                                        <div className='row'>
                                            <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                <label for="inputPassword6" class="col-form-label required">Name of Company</label>
                                            </div>
                                            <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                <input class="form-control"
                                                    type="text"
                                                    id='SupplierName'
                                                    name="SupplierName"
                                                    value={form.SupplierName}
                                                    onChange={onChange}
                                                    disabled={disabled ? true : false}
                                                    placeholder='Company Name'
                                                />
                                                {formErrors.SupplierName && <p className='text-danger text-start'>{formErrors.SupplierName}</p>}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-sm-6 '>
                                        <div className='row'>
                                            <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                <label for="inputPassword6" class="col-form-label required">Office Number</label>
                                            </div>
                                            <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                <input type="text"
                                                    id="OfficeNo"
                                                    class="form-control"
                                                    name='OfficeNo'
                                                    value={form.OfficeNo}
                                                    disabled={disabled ? true : false}
                                                    onChange={onChange}
                                                    placeholder='Office Number' />
                                                {formErrors.OfficeNo && <p className='text-danger text-start'>{formErrors.OfficeNo}</p>}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-sm-6 '>
                                        <div className='row'>
                                            <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                <label for="inputPassword6" class="col-form-label">Flat/House Number</label>
                                            </div>
                                            <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                <input type="text"
                                                    id="HouseNo"
                                                    class="form-control"
                                                    name="HouseNo"
                                                    value={form.HouseNo}
                                                    disabled={disabled ? true : false}
                                                    onChange={onChange}
                                                    placeholder='Flat/House Number' />
                                                {formErrors.HouseNo && <p className='text-danger text-start'>{formErrors.HouseNo}</p>}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-sm-6 '>
                                        <div className='row'>
                                            <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                <label for="inputPassword6" class="col-form-label">Floor/Building Name</label>
                                            </div>
                                            <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                <input type="text"
                                                    id="BuildingName"
                                                    class="form-control"
                                                    name="BuildingName"
                                                    value={form.BuildingName}
                                                    disabled={disabled ? true : false}
                                                    onChange={onChange}
                                                    placeholder='Floor/Building Name'
                                                />
                                                {formErrors.BuildingName && <p className='text-danger text-start'>{formErrors.BuildingName}</p>}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-sm-6 '>
                                        <div className='row'>
                                            <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                <label for="inputPassword6" class="col-form-label">Floor/Building Number</label>
                                            </div>
                                            <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                <input type="text"
                                                    id="BuildingNo"
                                                    class="form-control"
                                                    name="BuildingNo"
                                                    value={form.BuildingNo}
                                                    disabled={disabled ? true : false}
                                                    onChange={onChange}
                                                    placeholder='Floor/Building Number' />
                                                {formErrors.BuildingNo && <p className='text-danger text-start'>{formErrors.BuildingNo}</p>}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-sm-6 '>
                                        <div className='row'>
                                            <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                <label for="inputPassword6" class="col-form-label required">Colony/Street</label>
                                            </div>
                                            <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                <input type="text"
                                                    id="Street"
                                                    class="form-control"
                                                    name='Street'
                                                    value={form.Street}
                                                    disabled={disabled ? true : false}
                                                    onChange={onChange}
                                                    placeholder='Colony/Street' />
                                                {formErrors.Street && <p className='text-danger text-start'>{formErrors.Street}</p>}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-sm-6 '>
                                        <div className='row'>
                                            <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                <label for="inputPassword6" class="col-form-label">Locality</label>
                                            </div>
                                            <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                <input type="text"
                                                    id="Locality"
                                                    class="form-control"
                                                    name='Locality'
                                                    value={form.Locality}
                                                    disabled={disabled ? true : false}
                                                    onChange={onChange}
                                                    placeholder='Locality' />
                                                {formErrors.Locality && <p className='text-danger text-start'>{formErrors.Locality}</p>}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-sm-6 '>
                                        <div className='row'>
                                            <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                <label for="inputPassword6" class="col-form-label">Landmark</label>
                                            </div>
                                            <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                <input type="text"
                                                    id="Landmark"
                                                    class="form-control"
                                                    name="Landmark"
                                                    value={form.Landmark}
                                                    disabled={disabled ? true : false}
                                                    onChange={onChange}
                                                    placeholder='Landmark' />
                                                {formErrors.Landmark && <p className='text-danger text-start'>{formErrors.Landmark}</p>}
                                            </div>

                                        </div>
                                    </div>
                                    {/*empty div for row blocking */}
                                    <div></div>
                                    <div className='col-lg-6 col-sm-6 '>
                                        <div className='row'>
                                            <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                <label for="inputPassword6" class="col-form-label required">Pincode</label>
                                            </div>
                                            <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                <input type="text"
                                                    id="Pincode"
                                                    class="form-control"
                                                    name="Pincode"
                                                    value={form.Pincode}
                                                    disabled={disabled ? true : false}
                                                    onChange={onChange}
                                                    placeholder='Pincode' />
                                                {formErrors.Pincode && <p className='text-danger text-start'>{formErrors.Pincode}</p>}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-sm-6 '>
                                        <div className='row'>
                                            <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                <label for="inputPassword6" class="col-form-label">Website URL</label>
                                            </div>
                                            <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                                <input type="text"
                                                    id="Website"
                                                    class="form-control"
                                                    name="Website"
                                                    value={form.Website}
                                                    disabled={disabled ? true : false}
                                                    onChange={onChange}
                                                    placeholder='Website URL' />
                                                {formErrors.Website && <p className='text-danger text-start'>{formErrors.Website}</p>}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-sm-12 '>
                                        <div className='row'>
                                            <div class="col-lg-3 col-md-12 col-sm-12 mb-lg-1">
                                                <label for="inputPassword6" class="col-form-label">About Company</label>
                                            </div>
                                            <div class=" col-lg-9 col-md-12 col-sm-12 mb-lg-1 mb-md-2">
                                                <textarea class="form-control"
                                                    id="textAreaExample3"
                                                    rows="2"
                                                    name="AboutSupplier"
                                                    value={form.AboutSupplier}
                                                    disabled={disabled ? true : false}
                                                    onChange={onChange}
                                                    placeholder='About Company'
                                                >

                                                </textarea>
                                                {/* {formErrors.AboutSupplier && <p className='text-danger text-start'>{formErrors.AboutSupplier}</p>} */}
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-12 mb-lg-1 mb-md-1">
                                        <label for="inputPassword6" class="col-form-label">Incorporation Number</label>
                                    </div>
                                    <div class=" col-lg-3 col-md-4 col-sm-12 mb-1">
                                        <input type="text"
                                            id="IncorporationNo"
                                            class="form-control"
                                            name="IncorporationNo"
                                            value={form.IncorporationNo || ''}
                                            disabled={disabled ? true : false}
                                            onChange={onChange}
                                            placeholder='Incorporation Number'
                                        />
                                        {formErrors.IncorporationNo && <p className='text-danger text-start'>{formErrors.IncorporationNo}</p>}
                                    </div>
                                    <div class="col-lg-6 col-md-4 col-sm-12 mb-lg-1 mb-md-1">
                                        <div class="input-group">
                                            <input type="file"
                                                class="form-control btn_add"
                                                id="txtIncorporationCertiPath"
                                                name='IncorporationCertiPath'
                                                accept='.pdf'
                                                disabled={disabled ? true : false}
                                                onChange={handleuploadIncorpration}
                                            />
                                            <label class="input-group-text" for="txtIncorporationCertiPath">Browse</label>

                                        </div>
                                        {incorporationfile && <p className='text-start'>{incorporationfile}</p>}
                                        {formErrors.IncorporationCertiPath && <p className='text-danger text-start'>{formErrors.IncorporationCertiPath}</p>}
                                    </div>

                                    {/* PAN No */}
                                    <div class="col-lg-3 col-md-4 col-sm-12 mb-lg-1 mb-md-1">
                                        <label for="inputPassword6" class="col-form-label">PAN Number</label>
                                    </div>
                                    <div class=" col-lg-3 col-md-4 col-sm-12 mb-1">
                                        <input type="text"
                                            id="PANNo"
                                            class="form-control"
                                            name="PANNo"
                                            value={form.PANNo || ''}
                                            disabled={disabled ? true : false}
                                            onChange={onChange}
                                            placeholder='PAN Number'
                                        />
                                        {formErrors.PANNo && <p className='text-danger text-start'>{formErrors.PANNo}</p>}
                                    </div>
                                    <div class="col-lg-6 col-md-4 col-sm-12 mb-lg-1 mb-md-1">
                                        <div class="input-group">
                                            <input type="file"
                                                class="form-control btn_add"
                                                id="txtpanDocPath"
                                                name='panDocPath'
                                                accept='.pdf'
                                                disabled={disabled ? true : false}
                                                onChange={handleuploadPANNo}
                                            />
                                            <label class="input-group-text" for="txtpanDocPath">Browse</label>

                                        </div>
                                        {pannofile && <p className='text-start'>{pannofile}</p>}
                                        {formErrors.panDocPath && <p className='text-danger text-start'>{formErrors.panDocPath}</p>}
                                    </div>

                                    {/* GSTN No */}
                                    <div class="col-lg-3 col-md-4 col-sm-12 mb-lg-1 mb-md-1">
                                        <label for="inputPassword6" class="col-form-label">GSTN Number</label>
                                    </div>
                                    <div class=" col-lg-3 col-md-4 col-sm-12 mb-1">
                                        <input type="text"
                                            id="GSTNNo"
                                            class="form-control"
                                            name="GSTNNo"
                                            value={form.GSTNNo || ''}
                                            disabled={disabled ? true : false}
                                            onChange={onChange}
                                            placeholder='GSTN Number'
                                        />
                                        {formErrors.GSTNNo && <p className='text-danger text-start'>{formErrors.GSTNNo}</p>}
                                    </div>
                                    <div class="col-lg-6 col-md-4 col-sm-12 mb-lg-1 mb-md-1">
                                        <div class="input-group">
                                            <input type="file"
                                                class="form-control btn_add"
                                                id="txtgstnDocPath"
                                                name='gstnDocPath'
                                                accept='.pdf'
                                                disabled={disabled ? true : false}
                                                onChange={handleuploadGSTNNo}
                                            />
                                            <label class="input-group-text" for="txtgstnDocPath">Browse</label>

                                        </div>
                                        {gstinfile && <p className='text-start'>{gstinfile}</p>}
                                        {formErrors.gstnDocPath && <p className='text-danger text-start'>{formErrors.gstnDocPath}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='d-lg-flex'>
                                <div class="mb-1 col-lg-10 ms-lg-5">
                                    <div className='row'>
                                        <div class="col-lg-3 col-md-4 col-sm-12 mb-lg-1 mb-md-1">
                                            <label for="inputPassword6" class="col-form-label">Affiliations</label>
                                        </div>
                                        <div class=" col-lg-3 col-md-4 col-sm-12 mb-1">
                                            <input
                                                name='Affiliations'
                                                type="text"
                                                // onChange={handleOnChange}
                                                id="txtAffiliations"
                                                class="form-control"
                                                value={form.Affiliations || ''}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                                placeholder='Affiliations'
                                            />
                                            {formErrors.Affiliations && <p className='text-danger text-start'>{formErrors.Affiliations}</p>}
                                            {formafflitionerror && <p className='text-danger text-start'>{formafflitionerror}</p>}
                                        </div>
                                        <div class="col-lg-6 col-md-4 col-sm-12 mb-lg-1 mb-md-1">
                                            <div class="input-group  mb-1">
                                                <input
                                                    name='Affiliationsfile'
                                                    type="file"
                                                    id="txtAffiliationsfile"
                                                    //  onChange={handleOnChange}
                                                    class="form-control btn_add"
                                                    accept=".pdf"
                                                    onChange={handleuploadAffilitionCertifycate}
                                                    disabled={disabled ? true : false}
                                                />
                                                <label class="input-group-text" for="txtAffiliationsfile">Browse</label>

                                            </div>
                                            {affilitionstypeerror && <p className='text-danger text-start'>{affilitionstypeerror}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-1 col-lg-1 col-md-12 col-sm-12 me-lg-5">
                                    <div class="col-lg-1 col-md-12 col-sm-12 justify-content-md-center d-lg-block d-md-flex mb-lg-1">
                                        <button type="button" onClick={handleClick} class="btn btn-primary mx-1" id="btnAdd" disabled={addisenableaff ? false : true}>Add</button>
                                    </div>
                                </div>
                            </div>
                            {datacollection?.map((element, index) => {
                                if (counter > 0) {
                                    return (
                                        <div className='d-lg-flex d-md-flex' key={index}>
                                            <div class="mb-1 col-lg-10 ms-lg-5">
                                                <div className='row'>
                                                    <div class=" col-lg-3 mb-1"></div>
                                                    <div class=" col-lg-3 col-md-6 col-sm-12 mb-1">
                                                        <input
                                                            // onChange={handleOnChange}
                                                            class="form-control"
                                                            key={index}
                                                            value={element.DocName || ''}
                                                            readOnly
                                                            type="text"
                                                        ></input>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-12 mb-lg-1 mb-md-1">
                                                        <div class="input-group  mb-1">
                                                            <input
                                                                type="text"
                                                                key={index}
                                                                value={element.FileName || ''}
                                                                //onChange={handleOnChange}
                                                                readOnly
                                                                class="form-control btn_add"
                                                                id="inputGroupFile02" />
                                                            <label class="input-group-text" for="inputGroupFile02">Browse</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb-1 mt-lg-0 mt-md-1 col-lg-2 me-lg-5">
                                                <div class="col-lg-1 col-md-2 col-sm-12 mb-lg-1">
                                                    <button type="button" class="btn btn-primary mx-1" id="btnRemove" onClick={(e) => { removeAffilitionDoc(element.SeqNo, e); }} disabled={disabled ? true : false}>&times;</button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }

                            })}

                            <div class="mb-1 mt-4 col-lg-10 mx-lg-5">
                                <div class='row'>
                                    <div class="col-lg-3 col-md-4 col-sm-6 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label">Active</label>
                                    </div>
                                    <div class=" col-lg-2 col-md-6 col-sm-6 mb-lg-1 mb-md-1">
                                        <Switch
                                            onChange={handleisActive}
                                            checked={activeId}
                                            className="react-switch"
                                            disabled={disabled ? true : false}
                                        // disabled={disabled ? true : false}
                                        />
                                    </div>
                                    {/* <div class=" col-auto col-lg-4"> */}
                                    <ConfirmationModal
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                        noconfirm={() => handleConfirmation(false)}
                                        yesconfirm={() => handleConfirmation(true)}
                                    />

                                    {/* </div> */}
                                </div>
                            </div>
                            <div class="mt-5 d-flex justify-content-center">
                                <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={onSubmitForm} disabled={editdisabled ? true : false}>{buttonaddeditText}</button>
                                <button type="button" class="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick} disabled={cleardisable ? true : false}>Clear</button>
                                <button type="button" class="btn btn-primary mx-1" id="btnVisible" onClick={handlebackClick} disabled={backdisable ? true : false}>Back</button>
                            </div>
                            <div class="mb-1 col-lg-10 ms-lg-5">
                                <div class="mt-3 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-center ">
                                    <button type="button" class="btn btn-primary mx-1" id="btnClear" style={{ visibility: 'hidden' }}>Proceed</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="tab-pane fade show" id="pills-Account" role="tabpanel"
                    aria-labelledby="pills-Account-tab">
                    <SupplierAccount SupplierId={supplier.state?.supplierId} IsDisable={supplier.state?.disabled} />
                </div>
                <div class="tab-pane fade show" id="pills-Management" role="tabpanel"
                    aria-labelledby="pills-Management-tab">
                    <SupplierAccountManagement SupplierId={supplier.state?.supplierId} IsDisable={supplier.state?.disabled} />
                </div>
            </div>
        </>
    )
}

export default Suppliermaster