import { Url} from '../constants/global';
import {GetLocalStorage} from '../components/commonClass/Session';

export async function verifytoken()
{
    let status=false;
    try{
        let token=GetLocalStorage();
        // const s1 = await fetch(Url+'/general/user/tokenVerify',{
        //     headers: {'Authorization': `Bearer ${token.token}`}
        //   })
        const response = await fetch(Url+'/auth/v1/tokenVerify',{
            headers: {'Authorization': `Bearer ${token.token}`}
          })
        const data = await response.json()
        status = true;
        return status;
    }
    catch(error)
    {
        status = false;
        return status;
    }
}


export async function getAllData(data)
{
    try{
        let token=GetLocalStorage();
    //    console.log( Window.$userToken);
       //console.log( window.$userToken);
        const url = Url+data;
        //  let token= Window.$userToken;
        const response = await fetch(url, {
            headers: {'Access-Control-Allow-Origin': '*','Authorization': `Bearer ${token.token}`}
          });
        const TAGData = await response.json();
        return TAGData;
    }
    catch(error)
    {
        return [];
    }
}
export async function getDataByPara(dataparam,getparam)
{
    try{
        const urlparam = Url+dataparam;
        // var queryString = Object.keys(param).map(key => key + '=' + param[key]).join('&');
        // alert (urlparam+"?"+queryString)
        
        try{
           // const geturl= Url + urlparam;
            const getresponse =await fetch(urlparam, 
            {
                    method: 'POST', // or 'PUT'
                    headers: {
                                // Authentication: `Bearer ${Window.$userToken}`,
                               'Content-Type': 'application/json',
                            },
                    body: JSON.stringify(getparam),
                   
            });
            const getTAGData = await getresponse.json();
            return getTAGData;
        }
        catch(error)
        {
            return [];
        }

    }
    catch(error)
    {
        return [];
    }
   
}
export async function postUsersData(postsurl,postdata)
{
    try{
        let token=GetLocalStorage();
        const posturl= Url + postsurl;
        const postresponse =await fetch(posturl, 
        {
                method: 'POST', // or 'PUT'
                headers: {
                            'Authorization': `Bearer ${token.token}`,
                            'Access-Control-Allow-Origin': '*',
                           'Content-Type': 'application/json'
                        },
                body: JSON.stringify(postdata),
        });
        const PostTAGData = await postresponse.json();
        return PostTAGData;
    }
    catch(error)
    {
        return [];
    }
}
export async function patchUsersData(patchsurls,patchdata)
{
    try{
        let token=GetLocalStorage();
        const patchsurl= Url + patchsurls;
        const patchresponse =await fetch(patchsurl, 
        {
                method: 'PATCH', // or 'PUT'
                headers: {
                            'Authorization': `Bearer ${token.token}`,
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json',
                        },
                body: JSON.stringify(patchdata),
        });
        const patchTAGData = await patchresponse.json();
        return patchTAGData;
    }
    catch(error)
    {
        return [];
    }
}