import React from 'react'
import '../../assets/css/cmslandingpage.css';
import { useState, useEffect } from "react";
import { getAllData, getDataByPara } from '../../services/ApiCallingUserServices';
import { useNavigate, Navigate } from 'react-router-dom';
import { SetLocalStorage } from '../commonClass/Session';
import ReactLoading from 'react-loading';
import { useStore } from "react-redux";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../_actions/loader.actions";
import { uservalueaction } from "../../_actions/user.action"
import { Modal } from '../../_helpers/Modal';
import { Oval } from "react-loader-spinner";
// import RegisterUser from '../../_actions/RegisterAction';
const Login = () => {
    const dispatch = useDispatch();
    const store = useStore().getState();
    var flag = false;
    const navigate = useNavigate();
    const [form, setForm] = useState({
        userName: "",
        password: "",
        UserType: 0
    });
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [wrongpsw, setWrongPsw] = useState('');
    const [texttype, setTextType] = useState('password');
    const [eye, seteye] = useState(true);
    const [type, settype] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [loginError, setLoginError] = useState('');
    const [usermain, setUserMain] = useState([]);

    const onUpdateField = e => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };
    //Window.$name = '';

    const onSubmitForm = e => {
        e.preventDefault();
        setFormErrors(validate(form));

    };

    useEffect(() => {
        setWrongPsw('');
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            handleSubmitData();
            // setLoginError('Please Wait for Login');
            setDisabled(true);
        }
    }, [formErrors]);

    const validate = (values) => {
        const errors = {};
        setIsSubmit(true);
        if (!values.userName) {
            errors.userName = "Please Enter UserID";
            setIsSubmit(false);
        }
        if (!values.password) {
            errors.password = "Please enter correct password";
            setIsSubmit(false);
        }
        // else if (values.password.length > 10) {
        //     errors.password = "Password cannot exceed more than 10 characters";
        // }
        return errors;
    };
    const getUserById = async (userDatas) => {
        await getAllData('/auth/v1/getuserrightsforvisibility?UserId=' + userDatas.userMasterID).then((userdata) => {
            setUserMain(userdata.data);
            dispatch({ type: 'USERDATA', tba: userdata.data })
        })
    }

    const handleSubmitData = async () => {
        dispatch(loaderActions.start());
        const userData = await getDataByPara('/auth/v1/getuserlogin', form)
        const userDatad = userData.data;
        //localStorage.setItem('user', JSON.stringify(userData.data));
        let userName = '';
        let userId = 0;
        if (userData.status && form.password === userData.data.password) {
            SetLocalStorage(userData.data);
            getUserById(userData.data);
            // Window.$userToken = userData.data.token;

            // console.log('token', Window.$userToken)  
            userId = userData.data.userMasterID;
            userName = userData.data.userName;
            // userDatad.map(obj => {
            //     userId = obj.userMasterID;
            //     userName = obj.userName;
            // });
            // Window.$name = userName;
            // dispatch(loaderActions.end());
            navigate('/cmsdashboard/*', { state: { userName: userName } });
        }
        else {
            dispatch(loaderActions.end());
            const error = {};
            error.wrongpsw = "Please enter correct  User ID or password";
            setFormErrors(error);
            setDisabled(false);
            //setWrongPsw("Please enter correct  User ID or correct password");
        }

        setLoginError('');
    }
    const Eye = () => {
        if (texttype === "password") {
            setTextType("text");
            seteye(false);
            settype(true);
        }
        else {
            setTextType("password");
            seteye(true);
            settype(false);
        }
    }
    return (
        <>
            <Modal show={store.loader.load}>
                <div className="loader">
                    <Oval
                        height={200}
                        width={200}
                        color="#a74343"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#a74343"
                        strokeWidth={3}
                        strokeWidthSecondary={3}


                    /></div>
            </Modal>
            <div id="layoutAuthentication">
                <div id="layoutAuthentication_content">
                    <main>
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-5">
                                    <div class="card login_card shadow-lg border-0 rounded-lg mt-5">

                                        <div class="card-header login_header">
                                            <h6 class="text-center font-weight-light my-4">CMS/Admin</h6>
                                            <h3 class="text-center font-weight-light my-4">Login</h3>
                                        </div>
                                        <div class="card-body">
                                            <form onSubmit={onSubmitForm}>
                                                <div className='row'>
                                                    <div class="col-xxl-2 col-lg-3 col-md-12 col-sm-12"></div>
                                                    <div class="col-xxl-10 col-lg-9 col-md-12 col-sm-12">
                                                        {loginError && <p className='text-danger text-start'>{loginError}</p>}
                                                        {formErrors.wrongpsw && <p className='text-danger text-start'>{formErrors.wrongpsw}</p>}
                                                    </div>
                                                    <div class="col-xxl-2 col-lg-3 col-md-12 col-sm-12 mb-lg-1">
                                                        <label for="userid" class="col-form-label" >User Id</label>
                                                    </div>
                                                    <div class="col-xxl-10 col-lg-9 col-md-12 col-sm-12 mb-lg-1">
                                                        <input class="form-control"
                                                            type="text"
                                                            placeholder="User Id"
                                                            name="userName"
                                                            value={form.userName}
                                                            onChange={onUpdateField}
                                                            disabled={disabled ? true : false} />
                                                        {formErrors.userName && <p className='text-danger text-start'>{formErrors.userName}</p>}
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div class="col-xxl-2 col-lg-3 col-md-12 col-sm-12 mb-lg-1">
                                                        <label for="passwordfield" class="col-form-label" >Password</label>
                                                    </div>
                                                    <div class="col-xxl-10 col-lg-9 col-md-12 col-sm-12 mb-lg-1">
                                                        <input class="form-control password_valid" id="password_valid"
                                                            type={texttype}
                                                            name="password"
                                                            placeholder="Password"
                                                            value={form.password}
                                                            onChange={onUpdateField}
                                                            disabled={disabled ? true : false}
                                                        />
                                                        {/* <label for="inputPassword">Password</label> */}
                                                        <i onClick={Eye} className={`fa ${eye ? "fa-eye-slash" : "fa-eye"} p-viewer`} ></i>
                                                        {formErrors.password && <p className='text-danger text-start'>{formErrors.password}</p>}

                                                    </div>
                                                </div>
                                                <div className=''>
                                                    {/* {wrongpsw && <p className='text-danger'> {wrongpsw} </p>} */}
                                                    {/* <p className='text-danger text-start'>{formErrors.wrongpsw}</p> */}
                                                    <div class="d-flex justify-content-end">

                                                        <a class="small forgotpsw" href="">Forgot Password?</a>
                                                        {/* <button class="btn btn-primary" href="index.html">Login</button> */}
                                                    </div>
                                                    <div class="d-flex align-items-center justify-content-center mt-4 mb-0">
                                                        {/* <a class="small" href="password.html">Forgot Password?</a> */}
                                                        {/* <p className='text-danger text-start'>{formErrors.wrongpsw}</p> */}

                                                        <button class="btn btn-primary" disabled={disabled ? true : false}>Login</button>
                                                        {/* <input type="hidden" defaultValue={process.env.REACT_APP_API_URL} /> */}
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                        {/* <div class="card-footer text-center py-3">
                                            <div class="small"><a href="register.html">Need an account? Sign up!</a></div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>

            </div>

        </>
    )
}
export default Login