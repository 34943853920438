import { useEffect, useState } from "react";
import { ClearLocalStorage, GetLocalStorage } from '../commonClass/Session';
import { verifytoken } from "../../services/ApiCallingUserServices";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { GetMenuIdFromStorage, SetMenuIdToStorage } from "../commonClass/Menudect";

const Dashboard = () => {
   const dispatch = useDispatch()
   const navigate = useNavigate();
   const [username, setUsername] = useState('');
   const user = useSelector(store => store.userstatus.tba);
   // let tokenm=GetLocalStorage();

   // useEffect(() => {
   //      if(!tokenm && !tokenm?.userName && !tokenm?.token){
   //      //if (!Window.$name && !Window.$userToken) {


   //       ClearLocalStorage(); 
   //       dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
   //       // alert('Your Session Expired. Please Login Again!');
   //       // navigate('/');
   //   }
   //   else{
   //    async function verifyTokenValue () {
   //       let statusoftoken = await verifytoken();
   //       if(!statusoftoken){
   //          ClearLocalStorage();
   //          dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
   //          // alert('Your Session Expired. Please Login Again!');
   //          // navigate('/');
   //       }
   //   }
   //   verifyTokenValue();
   //   }

   // },[]);

   useEffect(() => {
      const tokenm = GetLocalStorage();
      setUsername((tokenm !== null || tokenm !== undefined || tokenm !== '') ? tokenm.userName : '');

      async function verifyTokenValue() {
         let statusoftoken = await verifytoken();
         if (!statusoftoken) {
           
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            // alert('Your Session Expired. Please Login Again!');
            // navigate('/');
         }
        
      }
      verifyTokenValue();
     
   }, []);



   return (
      <>
         <div class="container-fluid d-flex justify-content-center align-items-center h-100">
            <h1 class="text_color">Welcome {username}</h1>
         </div>
      </>
   )
}

export default Dashboard;