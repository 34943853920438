import Select from 'react-select';

const CustomSelection = (props) => {

    const {
        datalist,
        fieldid,
        SelectionChange,
        isdisable,
        isactivedisable,
        issearchable,
        isclearable,
        selId,
        selRef,
        placeholder,
        isMulti=false
    } = props
    const customStyles = {
        control: (base, state) => ({
            ...base,
            borderColor: (state.isDisabled && isactivedisable) ? "red" : state.isFocused ? "#a74343" : "#ced4da",
            boxShadow: (state.isDisabled && isactivedisable) ? "none" : state.isFocused ? "0 0 0 0.2rem rgba(167, 40, 40, 0.25) !important" : "#ced4da",

            "&:hover": {
                borderColor: "none",
                boxShadow: "none"
            },
            "&:focus": {
                borderColor: "#a74343 !important",
                boxShadow: "0 0 0 0.2rem rgba(167, 40, 40, 0.25) !important"
            }

        })
    };
    const onchange = (e) => {
        SelectionChange(e)
    }
    return (
        // <>
        <Select
            id={selId}
            ref={selRef}
            isMulti={isMulti}
            className="dropdown select_dropdown"
            options={datalist}
            value={datalist?.filter(function (datalist) {
                return datalist.value === fieldid;
            })}// set list of the data
            onChange={onchange} // assign onChange function
            // isDisabled={(isdisable) ? true : false}
            isDisabled={(isdisable || isactivedisable) ? true : false}
            isSearchable={issearchable}
            styles={customStyles}
            isClearable={isclearable}
            placeholder={placeholder}
        />
        // </>
    );
}
export default CustomSelection;