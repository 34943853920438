export const errorConstants={
    ERROR_TRUE:'GLOBAL_ERROR_TRUE',
    ERROR_FALSE:'GLOBAL_ERROR_FALSE',
    SUBMITE_STATUS_TRUE:'GLOBAL_SUBMIT_STATUS_TRUE',
    SUBMITE_STATUS_FALSE:'GLOBAL_SUBMIT_STATUS_FALSE',

    INCLUSION_STATUS_TRUE               :'GLOBAL_INCLUSION_STATUS_TRUE',
    INCLUSION_STATUS_FALSE              :'GLOBAL_INCLUSION_STATUS_FALSE',
    EXCLUSION_STATUS_TRUE               :'GLOBAL_EXCLUSION_STATUS_TRUE',
    EXCLUSION_STATUS_FALSE              :'GLOBAL_EXCLUSION_STATUS_FALSE',
    POLICY_STATUS_TRUE                  :'GLOBAL_POLICY_STATUS_TRUE',
    POLICY_STATUS_FALSE                 :'GLOBAL_POLICY_STATUS_FALSE',
    ABOUTPLACE_STATUS_TRUE              :'GLOBAL_ABOUTPLACE_STATUS_TRUE',
    ABOUTPLACE_STATUS_FALSE             :'GLOBAL_ABOUTPLACE_STATUS_FALSE',
    ITINERARY_STATUS_TRUE               :'GLOBAL_ITINERARY_STATUS_TRUE',
    ITINERARY_STATUS_FALSE              :'GLOBAL_ITINERARY_STATUS_FALSE',

    INCLUSION_INCREMENT_COUNTER         : 'GLOBAL_INCLUSION_INCREMENT_COUNT',
    EXCLUSION_INCREMENT_COUNTER         : 'GLOBAL_EXCLUSION_INCREMENT_COUNT',
    POLICY_INCREMENT_COUNTER            : 'GLOBAL_POLICY_INCREMENT_COUNT',
    ABOUTPLACE_INCREMENT_COUNTER        : 'GLOBAL_ABOUTPLACE_INCREMENT_COUNT',
    ITINERARY_INCREMENT_COUNTER         : 'GLOBAL_ITINERARY_INCREMENT_COUNT',
   
    INCLUSION_DECREMENT_COUNTER         : 'GLOBAL_INCLUSION_DECREMENT_COUNT',
    EXCLUSION_DECREMENT_COUNTER         : 'GLOBAL_EXCLUSION_DECREMENT_COUNT',
    POLICY_DECREMENT_COUNTER            : 'GLOBAL_POLICY_DECREMENT_COUNT',
    ABOUTPLACE_DECREMENT_COUNTER        : 'GLOBAL_ABOUTPLACE_DECREMENT_COUNT',
    ITINERARY_DECREMENT_COUNTER         : 'GLOBAL_ITINERARY_DECREMENT_COUNT',
   
    INCLUSION_RESET_COUNTER             : 'GLOBAL_INCLUSION_RESET_COUNT',
    EXCLUSION_RESET_COUNTER             : 'GLOBAL_EXCLUSION_RESET_COUNT',
    POLICY_RESET_COUNTER                : 'GLOBAL_POLICY_RESET_COUNT',
    ABOUTPLACE_RESET_COUNTER            : 'GLOBAL_ABOUTPLACE_RESET_COUNT',
    ITINERARY_RESET_COUNTER             : 'GLOBAL_ITINERARY_RESET_COUNT',

    OVERVIEW_DURATION_COUNTER           : 'GLOBAL_OVERVIEW_DURATION_COUNT',
    OVERVIEW_DURATION_RESET_COUNTER     : 'GLOBAL_OVERVIEW_DURATION_RESET_COUNT',

    OVERVIEW_DURATIONFROM_COUNTER       : 'GLOBAL_OVERVIEW_DURATIONFROM_COUNT',
    OVERVIEW_DURATIONFROM_RESET_COUNTER : 'GLOBAL_OVERVIEW_DURATIONFROM_RESET_COUNT',

    OVERVIEW_DURATIONTO_COUNTER         : 'GLOBAL_OVERVIEW_DURATIONTO_COUNT',
    OVERVIEW_DURATIONTO_RESET_COUNTER   : 'GLOBAL_OVERVIEW_DURATIONTO_RESET_COUNT',

    OVERVIEW_VISITFROM_COUNTER          : 'GLOBAL_OVERVIEW_VISITFROM_COUNT',
    OVERVIEW_VISITFROM_RESET_COUNTER    : 'GLOBAL_OVERVIEW_VISITFROM_RESET_COUNT',

    OVERVIEW_VISITTO_COUNTER            : 'GLOBAL_OVERVIEW_VISITTO_COUNT',
    OVERVIEW_VISITTO_RESET_COUNTER      : 'GLOBAL_OVERVIEW_VISITTO_RESET_COUNT',
    
    OVERVIEW_THEME_COUNTER              : 'GLOBAL_OVERVIEW_THEME_COUNT',
    OVERVIEW_THEME_RESET_COUNTER        : 'GLOBAL_OVERVIEW_THEME_RESET_COUNT',

    OVERVIEW_CITY_DETAIL_COUNTER        : 'GLOBAL_OVERVIEW_CITY_DETAIL_COUNTER',
    OVERVIEW_CITY_DETAIL_RESET_COUNTER  : 'GLOBAL_OVERVIEW_CITY_DETAIL_RESET_COUNTER',
    
    OVERVIEW_NIGHT_PENDING_COUNTER       : 'GLOBAL_OVERVIEW_NIGHT_PENDING_COUNT',
    OVERVIEW_NIGHT_PENDING_RESET_COUNTER : 'GLOBAL_OVERVIEW_NIGHT_PENDING_RESET_COUNT',
    
    
    OVERVIEW_STATUS_TRUE:'GLOBAL_OVERVIEW_STATUS_TRUE',
    OVERVIEW_STATUS_FALSE:'GLOBAL_OVERVIEW_STATUS_FALSE',
    

    PRICING_STATUS_TRUE:'PRICING_STATUS_TRUE',
    PRICING_STATUS_FALSE:'PRICING_STATUS_FALSE',

    PRICING_SUPPLIER : 'PRICING_SUPPLIER',
    PRICING_SUPPLIER_RESET : 'PRICING_SUPPLIER_RESET',

    PRICING_TRANSFER : 'PRICING_TRANSFER',
    PRICING_TRANSFER_RESET : 'PRICING_TRANSFER_RESET',

    PRICING_JOINING_COST : 'PRICING_JOINING_COST',
    PRICING_JOINING_COST_RESET : 'PRICING_JOINING_COST_RESET',

    PRICING_OPERATION_COST : 'PRICING_OPERATION_COST',
    PRICING_OPERATION_COST_RESET : 'PRICING_OPERATION_COST_RESET',

    PRICING_TOUR : 'PRICING_TOUR',
    PRICING_TOUR_RESET : 'PRICING_TOUR_RESET',

    PRICING_CHILDREN : 'PRICING_CHILDREN',
    PRICING_CHILDREN_RESET : 'PRICING_CHILDREN_RESET',


    RESET_ALL_STATUS_FLAG : 'GLOBAL_RESET_ALL_STATUS_FLAG',
    RESET_ALL_COUNTERS : 'GLOBAL_RESET_ALL_COUNTERS',
   

}