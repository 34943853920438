import JoditEditor from "jodit-react";
import { useRef } from "react";
import "../../src/assets/css/commoncontrol.css";


const RichTextBox =(props)=>{
        const richtextref = useRef(null);
        
        const {
            field,
            fieldvalue,
            tabIndex = 1,
            textconfig,
            OnChangeofRichBox
        } = props;

        const handleOnChange = (e)=>{
            OnChangeofRichBox(e)
        }
        return(
           
                <JoditEditor
                    className='col-12'
                    ref={richtextref}
                    name = {field}
                    id = {field}
                    value = {fieldvalue}
                    tabIndex = {tabIndex}
                    onChange = {handleOnChange}
                    config={textconfig}
                />
         
        );
}
export default RichTextBox;