import React, { useMemo } from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../assets/css/master.css"
import { getAllData, } from '../../services/ApiCallingUserServices';
import { isValidImage } from '../commonClass/CommonValidation';
import RichTextBox from '../../customControl/RichTextBox';
import "react-datepicker/dist/react-datepicker.css";
import { htmlToText } from 'html-to-text';
import CustomSelection from '../../customControl/CustomSelection';
import { useDispatch, useSelector } from 'react-redux';
import { errorActions } from '../../_actions/error.action';
import { loaderActions } from '../../_actions/loader.actions';

const VendorAbout = (props) => {
    const {
        handleAboutAdd,
        handleAboutPlaceDisable,
        handleDeleteList,
        retriveflag,
        aboutdatalist,
        editableflag,
        ischecked
    } = props
    const errorStatus = useSelector(state => state.errorStatus.isError);
    const aboutStatus = useSelector(state => state.errorStatus.isAboutPlace);
    const dispatch = useDispatch();
    // const dispatch = useDispatch();
    const [aboutplacecitylist, setAboutPlaceCityList] = useState([]);
    const [citylabel, setCityLable] = useState('');
    const [deletefilelist, setDeleteFileList] = useState([]);
    const [aboutplaceid, setAboutPlaceId] = useState(0);
    const [aboutseqno, setAboutSeqNo] = useState(0)
    const [datacitydetails, setCityDetails] = useState([]);
    const [aboutplaceerror, setAboutPlaceError] = useState('')
    const [aboutcitydisable, setAboutCityDisable] = useState(false)
    const [allaboutcity, setAllAboutCity] = useState([]);
    const [fileselected, setSelectedFile] = useState();
    const [placeimagename, setImageName] = useState('');
    const [placeimagepath, setImagePath] = useState('');
    const [retrivecomplete, setRetriveComplete] = useState(0);
    const [richboxheight, setRichBoxHeight] = useState(false);
    const [aboutplacebtntext, setAboutPlaceBtnText] = useState('Add');
    const [placedetails, setPlaceDetails] = useState('');

    const getaboutplacecity = async () => {
        await getAllData('/master/v1/getpackagelocationcity').then((response) => {
            setAboutPlaceCityList(response.data?.filter(IsAct => IsAct.IsActive === true));
            setAllAboutCity(response.data);
        });

    }
    const aboutplacecitydatalist = aboutplacecitylist?.map((value) => ({
        label: value.City,
        value: value.LocationId
    }));

    const handlecityonchange = (e) => {
        setAboutPlaceError('');
        setAboutPlaceId(e.value);
        setCityLable(e.label);
        dispatch(errorActions.resetallstatusflag())
        getaboutplacedetailsfromlocmaster(e.value)
    }
    const [aboutCityDetail, setAboutCityDetail] = useState('')
    const getaboutplacedetailsfromlocmaster = async (id) => {

        await getAllData('/master/vendor/v1/vengetpkgaboutplacedetails?CityId=' + id).then(res => {
            res.data?.map(obj => {
                // formvalue.CityD = obj.LocationId
                // formvalue.CityDetailsD = htmlToText(obj.CityDetail)
                setAboutPlaceId(obj.LocationId)
                setPlaceDetails(obj.CityDetail)
                setAboutCityDetail(htmlToText(obj.CityDetail))
                setImagePath('https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + obj.PlaceImagePath)
                setImageName(obj.PlaceImageName)
            })
        })
    }

    const handleeditaboutplacedetail = (id) => {
        const newList = datacitydetails?.map((item) => {
            if (item.SeqNo === id) {
                const updatedItem = {
                    ...item,
                    LocationId: aboutplaceid,
                    CityName: citylabel,
                    CityDetails: placedetails,
                    PlaceImageName: placeimagename,
                    PlaceImagePath: placeimagepath,
                    PlaceImage: fileselected
                    //IsChecked: !item.IsChecked,
                };
                return updatedItem;
            }

            return item;
        });
        setCityDetails(newList);
        handleAboutAdd(newList);
        // setAboutPlaceId(-1);  // when click on Add button the aboutplaceid is not 0 (i.e. abouteplaceid = -1). So it's not display the validation message and Add the data into Grid table.
        setAboutPlaceId(0);
        setPlaceDetails('');
        setSelectedFile();
        setImageName('');
        setImagePath('');
        setAboutCityDetail('')
        setAboutPlaceBtnText('Add')
        const input = document.getElementById('txtcityimages');
        if (input) {
            input.value = "";
        }
        dispatch(errorActions.resetallstatusflag())
    }

    const CityClick = () => {
        // let index = datacitydetails?.find((obj) => obj.LocationId === aboutplaceid) || []
        // if (index.LocationId > 0) {
        //     setAboutPlaceError('You can not add duplicate value')
        //     return;
        // }
        const abc = datacitydetails?.map(value => {
            return value.LocationId;
        })
        const duplicatecity = abc.includes(aboutplaceid)
        if (aboutplacebtntext === 'Add') {
            if (aboutplaceid === 0) {
                dispatch(errorActions.start());
                setAboutPlaceError('Please enter all required field');
                return;
            }
            else if (placedetails === null) {
                dispatch(errorActions.start());
                setAboutPlaceError('Kindly add city details in location master');
                return;
            }
            else {
                if (duplicatecity) {
                    dispatch(errorActions.start());
                    setAboutPlaceError('City already exist');
                    return;
                }
                setAboutSeqNo(aboutseqno + 1)
                handleAboutAdd([...datacitydetails, { SeqNo: aboutseqno + 1, CityName: citylabel, LocationId: aboutplaceid, CityDetails: placedetails, PlaceImageName: placeimagename, PlaceImagePath: placeimagepath, PlaceImage: fileselected }])
                setCityDetails([...datacitydetails, { SeqNo: aboutseqno + 1, CityName: citylabel, LocationId: aboutplaceid, CityDetails: placedetails, PlaceImageName: placeimagename, PlaceImagePath: placeimagepath, PlaceImage: fileselected }]);
                // setAboutPlaceId(-1); // when click on Add button the aboutplaceid is not 0 (i.e. abouteplaceid = -1). So it's not display the validation message and Add the data into Grid table.
                setAboutPlaceId(0);
                setPlaceDetails('');
                setSelectedFile();
                setImageName('');
                setImagePath('');
                setAboutCityDetail('')
                const input = document.getElementById('txtcityimages');
                if (input) {
                    input.value = "";
                }

                handleAboutPlaceDisable(false);
                dispatch(errorActions.aboutplaceincrement())
                dispatch(errorActions.resetallstatusflag())
            }

        }
        if (aboutplacebtntext !== 'Add') {
            if (aboutplaceid === 0) {
                dispatch(errorActions.start());
                setAboutPlaceError('Please enter all required field');
                return;
            }
            else if (placedetails === null) {
                dispatch(errorActions.start());
                setAboutPlaceError('Kindly add city details in location master');
                return;
            }
            else {
                if (checkduplicatecity !== aboutplaceid) {
                    if (duplicatecity) {
                        dispatch(errorActions.start());
                        setAboutPlaceError('City already exist');
                        return;
                    }
                }
                dispatch(errorActions.resetallstatusflag());
                handleeditaboutplacedetail(seqnoedit);
                handleAboutPlaceDisable(false);
            }
        }


    };

    const cityremovev = (id, e) => {
        if (aboutplacebtntext !== 'Add') {
            datacitydetails?.map((obj) => {
                if (obj.SeqNo === id) {
                    setDeleteFileList([...deletefilelist, { ImagePath: obj.PlaceImagePath }])
                    handleDeleteList([...deletefilelist, { ImagePath: obj.PlaceImagePath }])
                }
            });
        }
        handleAboutAdd((oldState) => oldState?.filter((item) => item.SeqNo !== id));
        setCityDetails((oldState) => oldState?.filter((item) => item.SeqNo !== id));

        setAboutPlaceId(0);
        setPlaceDetails('');
        setSelectedFile();
        setImagePath('');
        setAboutCityDetail('')
        setAboutPlaceBtnText('Add');
        const input = document.getElementById('txtcityimages');
        if (input) {
            input.value = "";
        }

        setAboutPlaceError('');

        handleAboutPlaceDisable(false);

        dispatch(errorActions.aboutplacedecrement());
        dispatch(errorActions.resetallstatusflag());



    }

    const [seqnoedit, setSeqNoEdit] = useState(0)
    const [checkduplicatecity, setCheckDuplicateCity] = useState(0)
    const cityeditdata = (id, e) => {
        dispatch(errorActions.resetallstatusflag());
        setAboutPlaceBtnText('Update');
        datacitydetails?.filter(obj => obj.SeqNo === id).map((ele, index) => {

            // if (ele.PlaceImage !== '') {
            //     // setCityLable(ele.CityName);
            //     setAboutPlaceId(ele.LocationId);
            //     setPlaceDetails(ele.CityDetails)
            //     setRetriveComplete(htmlToText(ele.CityDetails).length);
            //     setSelectedFile(ele.PlaceImage);
            //     setImageName(ele.PlaceImageName);
            //     setImagePath(ele.PlaceImagePath);
            //     // setAboutPlaceId(id);
            // }
            // else {
            setSeqNoEdit(ele.SeqNo)
            setCityLable(ele.CityName);
            setAboutPlaceId(ele.LocationId);
            setCheckDuplicateCity(ele.LocationId)
            // formvalue.CityD = ele.City
            // formvalue.CityName = ele.CityName
            setPlaceDetails(ele.CityDetails)
            setAboutCityDetail(htmlToText(ele.CityDetails))
            setRetriveComplete(htmlToText(ele.CityDetails).length);
            setSelectedFile(ele.PlaceImage);
            setImageName(ele.PlaceImageName);
            setImagePath(ele.PlaceImagePath);
            // setAboutPlaceId(id);
            let allaboutcitylist = allaboutcity?.filter(IsAct => IsAct.IsActive === true);
            setAboutPlaceCityList(allaboutcity?.filter(IsAct => IsAct.IsActive === true));
            setAboutCityDisable(false);
            if (!allaboutcitylist?.find(IsAboutCity => IsAboutCity.LocationId === ele.LocationId)) {
                setAboutCityDisable(true)
                setAboutPlaceCityList((prvAboutdata) => prvAboutdata?.length > 0 ? [...prvAboutdata, allaboutcity?.find(iscity => iscity.LocationId === ele.LocationId)] : [])

            }
            // }
        }
        )

    }
    const handlerichtextbox = (e) => {
        // const str = e.replace('<p><br></p>', '')

        // setPlaceDetails(str)

        // const html = htmlToText(str);
        // if (html.length !== (retrivecomplete)) {
        // }
        // setAboutPlaceError('');

    }
    const config = useMemo(() => ({
        readonly: true, // all options from https://xdsoft.net/jodit/doc/,
        // height: '100%',
        width: '100%',
        statusbar: false,
        removeButtons: ["link", "about"],
        toolbarAdaptive: false,
        height: 'auto',
        maxHeight: richboxheight === true ? 200 : 500,
        disablePlugins: ["source"],
        disabled: retriveflag ? false : false,
    }), [retriveflag]);

    useEffect(() => {
        const richtexteboxheight = document.getElementById("layoutSidenav_content").clientWidth
        if (richtexteboxheight > 912) {
            setRichBoxHeight(true)
        }
        else {
            setRichBoxHeight(false)
        }
    }, []);

    const [imageError, setImageError] = useState('');

    function getDateCombination() {
        var today = new Date();
        let date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
        let time = today.getHours().toString() + (today.getMinutes() + 1).toString() + today.getSeconds().toString();
        return date + '_' + time;
    }
    const handleUploadImageLoad = (e) => {
        // const files = e.target.files;
        if (isValidImage(e.target.files[0].name)) {
            const files = e.target.files;
            const filecom = getDateCombination();
            const filename = filecom + '_' + '1' + '_' + (files[0].name.replace(/ /g, "_")).replace(/-/g, '');
            setImageName(filename);
            setImagePath('CMS/Package/Place/' + filename);
            setSelectedFile(e.target.files[0]);
            setImageError('');
        }
        else {
            const input = document.getElementById('txtcityimages');
            if (input) {
                input.value = "";
            }
            dispatch(errorActions.start());
            setImageError("Please select Only Image");
            return;
        }
        setAboutPlaceError('');
        dispatch(errorActions.end());
    }

    const removeImage = () => {
        //setUploadImage((oldState) => oldState.filter((item) => item.id !== id));
        setImageName('');
        setImagePath('');
        dispatch(errorActions.end());
        const input = document.getElementById('txtcityimages');
        if (input) {
            input.value = "";
        }
        setSelectedFile();
        setAboutPlaceError('');
    };

    const validation = () => {
        setAboutPlaceError('Please enter about place detail')
        let aboutcllopased = document.getElementById("AboutPlaceError")
        if (aboutcllopased.ariaExpanded === "false")
            document.querySelector("#AboutPlaceError").click();
        // dispatch(errorActions.submitend());
        dispatch(errorActions.start())
    }
    useEffect(() => {
        getaboutplacecity()
    }, [])
    useEffect(() => {
        if (retriveflag) {
            setCityDetails(aboutdatalist)
            setAboutSeqNo(aboutdatalist.length)
        }
        else (
            setAboutCityDisable(retriveflag)
        )

    }, [aboutdatalist, retriveflag])

    useEffect(() => {
        if (!errorStatus) {
            setImageError('');
            setAboutPlaceError('');
        }
    }, [errorStatus])

    useEffect(() => {
        if (aboutStatus && ischecked) {
            validation()
        }
    }, [aboutStatus])

    return (
        <>
            <div class="accordion-item mb-3">
                <h2 class="accordion-header" id="panelsStayOpen-headingSeven">
                    <button class="accordion-button collapsed" type="button" id="AboutPlaceError" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded="false" aria-controls="panelsStayOpen-collapseSeven">
                        About Place
                    </button>
                </h2>
                <div id="panelsStayOpen-collapseSeven" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSeven">
                    <div class="accordion-body">
                        <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                            <div class="row">
                                {/* <label for="inputPassword6" class="col-form-label">About Place</label> */}
                                <div class="row">
                                    <div className='col-lg-12 col-sm-12 '>
                                        <div className='row'>
                                            {aboutplaceerror && <p className='text-center text-danger'>{aboutplaceerror}</p>}
                                            <div class="col-lg-2 col-md-3 mb-1">
                                                <label class="col-form-label required">Select City</label>
                                            </div>
                                            <div class="col-lg-4 mb-1 col-md-9">

                                                <CustomSelection
                                                    datalist={aboutplacecitydatalist}
                                                    fieldid={aboutplaceid}
                                                    SelectionChange={handlecityonchange}
                                                    isdisable={editableflag}
                                                    isactivedisable={aboutcitydisable}
                                                    issearchable={true}
                                                />
                                                {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='col-lg-12 col-sm-12 '>
                                        <div className='row'>
                                            <div class="col-lg-2 col-md-3 mb-1">
                                                <label class="col-form-label required">City Details</label>
                                            </div>
                                            <div class="col-lg-10 mb-1 col-md-9">

                                                <RichTextBox field="CityDetailsD" fieldvalue={placedetails} tabIndex={1} OnChangeofRichBox={handlerichtextbox} textconfig={config} />

                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className='col-lg-6 col-sm-12 '>
                                        <div className='row'>
                                            <div class="col-lg-4 col-md-3 col-sm-12 mb-lg-1">
                                                <label class="col-form-label required">Upload City Image</label>
                                            </div>
                                            <div class="col-lg-8 col-md-9 col-sm-12 mb-lg-1">
                                                <div class="input-group  mb-1">
                                                    <input
                                                        name='CityImage'
                                                        type="file"
                                                        id="txtcityimages"
                                                        onChange={handleUploadImageLoad}
                                                        class="form-control btn_add"
                                                        accept=".png,.jpg"
                                                        disabled={editableflag ? true : false}
                                                    />
                                                    <label class="input-group-text" for="txtcityimages">Browse</label>

                                                </div>
                                                {imageError && <p className='text-danger text-start'>{imageError}</p>}

                                                {fileselected &&
                                                    (
                                                        <div className="col-lg-3 col-md-3 col-6 mx-auto mt-2">
                                                            <img
                                                                src={URL.createObjectURL(fileselected)} className="img_thumb w-100" alt=''
                                                            />
                                                            <button className='closebtnimg' onClick={() => removeImage()} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }}
                                                            // disabled={disabled ? true : false} 
                                                            >&times;</button>
                                                        </div>
                                                    )}
                                                {(!fileselected && placeimagepath !== '') &&
                                                    (
                                                        <div className="col-lg-3 col-md-3 col-6 mx-auto mt-2">
                                                            <img
                                                                src={placeimagepath} className="img_thumb w-100" alt=''
                                                            />
                                                            <button className='closebtnimg' onClick={() => removeImage()} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }}
                                                            // disabled={disabled ? true : false} 
                                                            >&times;</button>
                                                        </div>
                                                    )}

                                            </div>
                                        </div>
                                    </div> */}

                                    <div className='col-lg-12 col-sm-12 '>
                                        <div className='row'>
                                            <div class="col-lg-2 col-md-3 mb-1">
                                                <label class="col-form-label required">City Details</label>
                                            </div>
                                            <div class="col-lg-10 mb-1 col-md-9">
                                                <RichTextBox field="CityDetailsD" fieldvalue={placedetails !== null ? placedetails : ''} tabIndex={1} OnChangeofRichBox={handlerichtextbox} textconfig={config} />
                                                {/* <textarea className='col-12' rows='4' value={aboutCityDetail} readOnly disabled={editableflag ? true : false} ></textarea> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-lg-6 col-sm-6'>
                                        <div className='row'>
                                            <div class="col-lg-4 col-md-12 col-sm-12 mb-lg-1">
                                                <label class="col-form-label">City Image</label>

                                            </div>
                                            <div class="col-lg-8 col-md-12 col-sm-12 mb-lg-1">
                                                {(placeimagepath && placeimagepath !== 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/null' && placeimagepath !== 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/') &&
                                                    (
                                                        <>
                                                            {/* <div className="col-lg-3 col-md-3 col-6 mt-2 mb-2">
                                                                                <img src={placeimagepath} className="img_thumb w-100 city_image" alt='' />
                                                                            </div> */}

                                                            <div className="col-lg-3 col-md-3 col-6 my-2 cityimage">
                                                                <img src={placeimagepath} className="cityimage" alt='' />
                                                            </div>

                                                            {/* <div className="col-lg-3 col-md-3 col-6 ">
                                                                                <img src={placeimagepath} className="img_thumb w-100" alt='' />
                                                                            </div> */}
                                                        </>
                                                    )}

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 mb-1 col-md-12 d-flex justify-content-center">
                                        <button type="button" onClick={CityClick} disabled={editableflag ? true : false} class="btn btn-primary mx-1" id="btnAboutPlaceAdd">{aboutplacebtntext}</button>
                                    </div>
                                    <div className='mb-1 col-lg-12 col-md-12 mt-3'>
                                        <table id="data" className="table table-hover" >
                                            <thead>
                                                <tr>
                                                    <th>City
                                                    </th>
                                                    {/* <th>City Details
                                                                    </th> */}
                                                    <th className='text-end pe-4'>Edit
                                                    </th>
                                                    <th className='text-end pe-4'>Remove
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody id="location-data">
                                                {datacitydetails?.map((element, index) => {
                                                    // if (datacitycounter > 0) {
                                                    return (
                                                        <tr class="ReactTable" key={index}>
                                                            <td >{element.CityName}</td>
                                                            {/* <td >{element.CityDetails}</td> */}
                                                            {/* <td className='d-none'>{element.InclusionTypeId}</td> */}
                                                            <td className='text-end'><button type="button"
                                                                onClick={(e) => { cityeditdata(element.SeqNo, e) }}
                                                                class="btn btn-primary" id="btnedit" disabled={editableflag}>Edit</button></td>
                                                            <td className='text-end'><button type="button" onClick={(e) => { cityremovev(element.SeqNo, e) }} class="btn btn-primary mx-1" id="btnAdd" disabled={editableflag}>Remove</button></td>
                                                        </tr>
                                                    );
                                                    // }
                                                })}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VendorAbout