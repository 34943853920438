import React, { useRef } from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from 'react-select';
import "../../assets/css/master.css"
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../services/ApiCallingUserServices';
import Switch from 'react-switch';
import { onlyAlphabets, generatePDF } from '../commonClass/CommonValidation'
import { ClearLocalStorage, GetLocalStorage } from '../commonClass/Session';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../_actions/loader.actions";
import { ExportExcel } from '../commonClass/ExportExcel';
import { ConfirmationModal } from '../../customControl/ConfirmationModal';
import CustomSelection from '../../customControl/CustomSelection';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../customControl/CustomTable';

const Airportmaster = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    /////////////////////declartion of variable ///////////////////////////////////////
    //#region declartion of variable
    const [postdata, setPostData] = useState([]);
    const [locationId, setLocationId] = useState();
    const [modalShow, setModalShow] = useState(false);
    const [activeId, setActiveId] = useState();
    const [airportlistdata, setAirportListData] = useState([]);
    // const [taglistdataP, setTagListDataP] = useState([]);
    const [buttonaddeditText, setButtonAddEditText] = useState("Add");
    const [disabled, setDisabled] = useState(false);
    const [editdisabled, setEditDisabled] = useState(true);
    const [citydata, setCityData] = useState([]);

    const [airporterrorMessage, setAirportErrorMessage] = useState('');
    const [airporlengerrorMessage, setAirportlengErrorMessage] = useState('');
    const [airportduplicateerrorMessage, setAirportDuplicateErrorMessage] = useState('');
    const [cityerrorMessage, setCityErrorMessage] = useState('');

    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('4');

    // const [destinationId, setDestinationId] = useState();
    // const [rowbackgroundonhover, setRowBackGround] = useState();

    const [serchdisable, setSerachDisable] = useState(false);
    const [filteritems, setFilterItems] = useState([]);
    const [clrshow, setsclshow] = useState(false);
    const [allCity, setallCity] = useState([]);
    const [citydisable, setCityDisable] = useState(false);
    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);

    const serachoption = [
        {
            label: 'Airport Name',
            value: '1'
        },
        {
            label: 'City',
            value: '2'
        },
        {
            label: 'Active',
            value: '3'
        },
        // {
        //     label: 'Created Date',
        //     value: '5'
        // },
        {
            label: 'All',
            value: '4'
        },

    ];
    const [formValue, setFormValue] = useState(
        {
            AirportId: 0,
            AirportName: '',
            LocationId: 0,
            IsActive: false,
            city: ''
        }
    );
    //#endregion
    /////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////set form date for add, edit and view////////////////////


    //////////////////////////////use effect section //////////////////////////////////
    //#region  use effect section

    // useEffect(() => {

    // }, [airporterrorMessage, airporlengerrorMessage, cityerrorMessage]);
    useEffect(() => {
        //getLanguageData();
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            //if (Window.$name && Window.$userToken) {
            getCityData();
            // getAirportDataList().then(() => { dispatch(loaderActions.end()) });;
            setitems();
        }
        else {
            ClearLocalStorage();
            // alert('Your Session Expired. Please Login Again!');
            // navigate('/');
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, [])
    useEffect(() => {
        // let MENUID = GetMenuIdFromStorage()
        // let mid = user?.find(x => x.MenuId === MENUID);
        let pagelink = user?.find(x => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        }
        // let viewrights= false;
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            // viewrights = obj.URightView;
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);

        });



    }, [user])


    useEffect(() => {
    }, [postdata])
    useEffect(() => {

    }, [buttonaddeditText]);
    //#endregion
    //////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////////////////////validate , add,edit, and retrive data ////////////////////////
    //#region validate , add,edit, and retrive data
    const CityError = useRef(null);
    const validateData = () => {
        // setTagErrorMessage('');
        // setSectionErrorMessage('');
        // setTaglengErrorMessage('');
        let error = []
        if (typeof formValue.AirportName === 'string' && formValue.AirportName.trim().length === 0) {
            setAirportErrorMessage('Please enter Airport name.')
            error.push('AirportName');
        }
        if (typeof formValue.AirportName === 'string' && formValue.AirportName.trim().length > 50) {
            setAirportlengErrorMessage('Maximum length allowed for Airport name is 50 characters.');
            error.push('AirportName');
        }
        if (formValue.LocationId <= '0') {
            setCityErrorMessage('Please select city.');
            error.push('CityError')
            //setErrorMessage({...errormessage,[errormessage.sectionerrorMessage] : 'Please select section.'})
        }
        if (error[0] === "CityError") {
            eval(error[0]).current.focus()
        }
        else {
            document.getElementById(error[0]).focus();
        }

    }
    const handleAddClick = async () => {
        let statusoftoken = await verifytoken();
        if (buttonaddeditText === "Add") {
            if (statusoftoken) {
                if (formValue.AirportName.trim().length === 0 || formValue.AirportName.trim().length > 50 || formValue.LocationId <= '0') {
                    validateData();
                    return;
                }

                else {
                    if ((typeof formValue.AirportName === 'string' && formValue.AirportName.trim().length > 0) && (typeof formValue.LocationId > '0')) {
                        let filterdata;
                        filterdata = airportlistdata?.filter(issec => issec.airportName.toLowerCase() === formValue.AirportName.toLowerCase() && issec.locationId === formValue.LocationId);
                        if (filterdata?.length > 0) {
                            setAirportDuplicateErrorMessage('You can not enter duplicate value.');
                            return;
                        }
                    }
                    if (statusoftoken) {
                        const AirportData = await postUsersData("/master/v1/insertairport", formValue);
                        setPostData(AirportData.data);
                        if (!AirportData.status && !AirportData.data) {
                            setAirportDuplicateErrorMessage(AirportData.message);
                            return;
                        }
                        if (AirportData.status) {
                            if (AirportData.data > 0) {
                                setitems();
                                dispatch({ type: 'ALERTING', message: AirportData.message })
                                // alert('Airport master Record saved successfully');
                                //window.location.reload(false);
                                clear();
                            }
                            else {
                                dispatch({ type: 'ALERTING', message: AirportData.message })
                                // alert('Airport master Record not saved successfully')
                            }
                        }
                        else {
                            dispatch({ type: 'ALERTING', message: AirportData.message })
                            // alert('Something went wrong!')
                        }
                    }
                    else {
                        ClearLocalStorage();
                        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                    }
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }


        }
        else {
            if (statusoftoken) {
                if (formValue.AirportName.trim().length === 0 || formValue.AirportName.trim().length > 50 || formValue.LocationId <= '0') {
                    validateData();
                    return;
                }

                else {
                    if ((typeof formValue.AirportName === 'string' && formValue.AirportName.trim().length > 0) && (typeof formValue.LocationId > '0')) {

                        let filterdata;
                        filterdata = airportlistdata?.filter(issec => issec.airportId !== formValue.AirportId && issec.airportName.toLowerCase() === formValue.AirportName.toLowerCase() && issec.locationId === formValue.LocationId);
                        if (filterdata.length > 0) {
                            setAirportDuplicateErrorMessage('You can not enter duplicate value..');
                            return;
                        }
                    }
                    if (statusoftoken) {
                        const AirportData = await patchUsersData("/master/v1/updateairport", formValue);
                        setPostData(AirportData.data);
                        if (!AirportData.status && !AirportData.data) {
                            setAirportDuplicateErrorMessage(AirportData.message);
                            return;
                        }
                        if (AirportData.status) {
                            setitems();
                            dispatch({ type: 'ALERTING', message: AirportData.message })
                            // alert('Airport master Record updated successfully');
                            //window.location.reload(false);
                            clear();
                        }
                        else {
                            dispatch({ type: 'ALERTING', message: AirportData.message })
                            // alert('Something went wrong!')
                        }
                    }
                    else {
                        ClearLocalStorage();
                        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                    }
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }

        }

    }
    const handleEditMode = (id) => {
        setDisabled(false);
        setEditDisabled(false);
        setSerachDisable(true);
        setButtonAddEditText("Update");
        RetriveData(id);
    }
    const handleViewMode = (id) => {
        setDisabled(true);
        setEditDisabled(false);
        setSerachDisable(true);
        RetriveData(id);
    }

    function RetriveData(id) {
        setAirportErrorMessage('');
        setAirportlengErrorMessage('');
        setCityErrorMessage('');
        setAirportDuplicateErrorMessage('');

        airportlistdata?.map(obj => {
            if (obj.airportId === id) {
                // let active = !obj.inActive;
                formValue.AirportName = obj.airportName
                formValue.AirportId = obj.airportId
                // Check In-Active Data and add in Dropdown...
                let allcitylist = allCity?.filter(IsAct => IsAct.isActive === true);
                setCityData(allCity?.filter(IsAct => IsAct.isActive === true));
                setCityDisable(false);
                if (!allcitylist?.find(IsCity => IsCity.locationId === obj.locationId)) {
                    setCityDisable(true)
                    setCityData((prvCityData) => prvCityData?.length > 0 ? [...prvCityData, allCity?.find(isCity => isCity.locationId === obj.locationId)] : [])
                    // setCityData((prvCityData) => [...prvCityData, allCity?.find(isCity => isCity.locationId === obj.locationId)])

                }
                formValue.LocationId = obj.locationId
                // { formValue.LanguageId = obj.languageId }
                formValue.IsActive = obj.isActive
                // setLanguageId(obj.languageId);
                setLocationId(obj.locationId);
                setActiveId(obj.isActive);
            }
        });
        setFormValue({ ...formValue, formValue })
    }
    //#endregion
    //////////////////////////////////////////////////////////////////////////////////////

    ///////////////////////fill dropdown and handle input value//////////////////////////
    //#region fill dropdown and handle input value
    const onChange = (e) => {
        var result;
        if ([e.target.name][0] === 'AirportName') {

            result = onlyAlphabets(e.target.value)
        }
        // else {
        //     result != e.target.value;
        // }

        setEditDisabled(true);
        setAirportErrorMessage('');
        setAirportlengErrorMessage('');
        setAirportDuplicateErrorMessage('');
        setCityErrorMessage('');
        setFormValue({ ...formValue, [e.target.name]: result });
        //setDestinationName(e.target.value);
    };

    const getAirportDataList = async () => {
        const airportlistdata = await getAllData("/master/v1/getallairport");
        //setTagListDataP(taglistdata.data);
        setAirportListData(airportlistdata.data);
    }

    const getCityData = async () => {
        //http://115.124.96.40:9041/general/v1/getactivesection
        await getAllData("/master/v1/getairportcity").then((CityData) => {
            getAirportDataList()
            setallCity(CityData.data);
            setCityData(CityData.data?.filter(IsAct => IsAct.isActive === true));
            dispatch(loaderActions.end());
        });
    }

    const handleSectionChange = (e) => {
        formValue.LocationId = e.value
        setLocationId(e.value)
        setEditDisabled(true);

        //alert(buttonaddeditText)
        setAirportErrorMessage('');
        setAirportlengErrorMessage('');
        setCityErrorMessage('');
        setAirportDuplicateErrorMessage('');
        //{formValue.SectionName = e.label}

    }
    // const handleisActive = (e) => {
    //     { formValue.IsActive = e }
    //     setActiveId(!activeId)
    //     setEditDisabled(true);
    // }
    const handleisActive = (e) => {
        if (buttonaddeditText === 'Add') {
            formValue.IsActive = e
            setActiveId(!activeId)
            setAirportErrorMessage('');
            setAirportlengErrorMessage('');
            setCityErrorMessage('');
            setAirportDuplicateErrorMessage('');
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (e) => {
        //setYesNoConfirm(e)
        if (e === true) {
            formValue.IsActive = !activeId
            setActiveId(!activeId)
            setEditDisabled(true);
            setAirportErrorMessage('');
            setAirportlengErrorMessage('');
            setCityErrorMessage('');
            setAirportDuplicateErrorMessage('');
        }
        setModalShow(false);
    }

    const cityList = citydata?.map(value => ({
        label: value.city,
        value: value.locationId
    }));

    const handleClearClick = () => {
        // window.location.reload(true);

        clear();

    }
    function clear() {

        setFormValue({ ...formValue, 'AirportName': '', 'LocationId': 0, 'IsActive': false });
        setCityData([]);
        getCityData();
        setLocationId();
        setActiveId(false);
        //{ formValue.InActive = false}
        getAirportDataList();
        setButtonAddEditText('Add');
        setDisabled(false);
        setEditDisabled(true);
        setSerachDisable(false);
        setSerachBy('4');
        setSearchValue('');
        setAirportErrorMessage('');
        setAirportlengErrorMessage('');
        setCityErrorMessage('');
        setAirportDuplicateErrorMessage('');
        setsclshow(false);
        setCityDisable(false);

    }

    //#endregion ///////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////////////serch and export sction////////////////////////////////////////////
    //#region handling search 

    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }

    const setitems = async () => {
        const airportlistdata = await getAllData("/master/v1/getallairport");
        setFilterItems(airportlistdata.data);
        //return DestinationListData.data;
    }
    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            getAirportDataList();
            setsclshow(false)
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleClickSearchValue();
        }
    };

    const handleClickSearchValue = () => {
        if (typeof searchvalue === 'string' && searchvalue.trim().length !== 0 && searchby > 0) {
            setitems();
            var filteritem = filteritems;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec.airportName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec.city.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '5') {
                filterdata = filteritem?.filter(issec => formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '3') {
                if (searchvalue.toLocaleLowerCase() === 'active'.toLocaleLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.isActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLocaleLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.isActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.isActive === false);
                }
                // else {
                //     filterdata = filteritem;
                // }
                else {
                    filterdata = filteritem?.filter(issec => formatDate(issec.createdDate).toLowerCase().includes("%#$&%^"));
                }

            }
            else if (searchby === '4') {
                if (searchvalue.toLocaleLowerCase() === 'active'.toLocaleLowerCase()) {
                    //filterdata = filteritem.filter(issec => issec.inActive === false);
                    filterdata = filteritem?.filter(issec => issec.airportName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.city.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLocaleLowerCase()) {
                    //filterdata = filteritem.filter(issec => issec.inActive === true);
                    filterdata = filteritem?.filter(issec => issec.airportName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.city.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.airportName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.city.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    //filterdata = filteritem.filter(issec => issec.inActive === true);
                    filterdata = filteritem?.filter(issec => issec.airportName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.city.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => issec.airportName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.city.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()));
                }

            }
            setAirportListData(filterdata)
        }


    }

    function PdfExport() {
        //print
        const columns = [
            "Airport Name",
            "City Name",
            "Active",
            "Created Date"
        ];
        var rows = [];

        for (let i = 0; i < airportlistdata.length; i++) {
            /*for (var key in json[i]) {
              var temp = [key, json[i][key]];
              rows.push(temp);
            }*/
            var temp = [
                airportlistdata[i].airportName,
                airportlistdata[i].city,
                `${airportlistdata[i].isActive ? 'Active' : 'InActive'}`,
                formatDate(airportlistdata[i].createdDate)
            ];
            rows = [...rows, temp]
            // rows.push(temp);
        }
        generatePDF(columns, rows, "AirportMaster", "Airport Master")
    }

    const ExportToExcel = () => {
        const dispalyname = ['Airport Name', 'City', 'Active', 'Created Date'];
        const fields = ['airportName', 'city', 'isActive', 'createdDate'];
        ExportExcel(dispalyname, fields, airportlistdata, "AirportMaster", "AirportMaster.xls");
    }

    //#endregion
    //////////////////////////////////////////////////////////////////////////////////////

    ///////////////////////////handle table Pagination///////////////////////////////////
    //#region table Pagination
    const columns = [

        {
            name: 'Airport Name',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (airport) => airport.airportName
        },

        {
            name: 'City',
            sortable: true,
            float: "left",
            selector: (airport) => airport.city
        },
        {
            name: 'Active',
            sortable: true,
            float: "left",
            // selector: location => <td style={{ textAlign: "center", width: "5%" }}>
            //     {`${location.inActive ? 'InActive' : 'Active'}`}</td>
            selector: (airport) => airport.isActive ? 'Active' : 'InActive'
        },
        {
            name: 'Created Date',
            sortable: true,
            float: "left",
            selector: (airport) => formatDate(airport.createdDate)
        },
        {
            name: 'Edit',
            // float:"right",
            // selector: location => < td style={{ textAlign: "center", width: "5%", cursor:"pointer" }} onClick={() => handleEditMode(location.tagId)}> <i className="fa fa-edit"></i></td >
            selector: (airport) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEditMode(airport.airportId)}></i></button>
        },
        {
            name: 'View',
            // float:"right",
            // selector: location => <td style={{ textAlign: "center", width: "5%", cursor:"pointer" }} onClick={() => handleViewMode(location.tagId)}><i className="fa fa-eye"></i></td>
            selector: (airport) => <i className="fa fa-eye" onClick={() => handleViewMode(airport.airportId)}></i>
        }
    ];



    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getAirportDataList();
    }
    //#endregion
    ////////////////////////////////////////////////////////////////////////////////////////

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }


    return (
        <>
            <div class="container mt-4 heading_color">
                <h3>Airport Master</h3>
            </div>
            <div class="container form_border py-5 my-3">
                <form action="" >
                    <div class="mb-3 row mx-1">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="row">
                                {airportduplicateerrorMessage && <div className="text-danger pb-3 text-center"> {airportduplicateerrorMessage} </div>}
                                <div class="col-lg-2 col-md-12 col-sm-12">
                                    <label for="inputPassword6" class="col-form-label required">Airport Name</label>
                                </div>
                                <div class=" col-lg-4 col-md-8 col-sm-12">
                                    <input type="text" id="AirportName" class="form-control"
                                        name="AirportName"
                                        value={formValue.AirportName}
                                        onChange={onChange}
                                        disabled={disabled ? true : false}
                                        placeholder="Airport Name"
                                    />
                                    {airporterrorMessage && <div className="text-danger"> {airporterrorMessage} </div>}
                                    {airporlengerrorMessage && <div className="text-danger"> {airporlengerrorMessage} </div>}
                                </div>
                                {/* <div class="col-lg-2 col-md-12 mb-1">
                                    <label for="inputPassword6" class="col-form-label">Language</label>
                                </div>
                                <div class="col-lg-4 mb-1 col-md-8">
                                    <Select
                                        className="dropdown select_dropdown"
                                        options={languageList}
                                        // set list of the data
                                        value={languageList.filter(function (languageList) {
                                            return languageList.value === languageId;
                                        })}
                                        onChange={handleLanguageeChange} // assign onChange function
                                        isDisabled={disabled}
                                    />
                                </div> */}

                                <div class="col-lg-2 col-md-12 mb-1">
                                    <label for="inputPassword6" class="col-form-label required">City</label>
                                </div>
                                <div class="col-lg-4 mb-1 col-md-8">
                                    {/* <Select
                                        className="dropdown select_dropdown"
                                        options={cityList}
                                        value={cityList?.filter(function (cityList) {
                                            return cityList.value === locationId;
                                        })}// set list of the data
                                        onChange={handleSectionChange} // assign onChange function
                                        isDisabled={disabled ? true : false}
                                        isSearchable={true}
                                    // isClearable
                                    /> */}
                                    <CustomSelection
                                        selId="CityError"
                                        selRef={CityError}
                                        datalist={cityList}
                                        fieldid={locationId}
                                        SelectionChange={handleSectionChange}
                                        isdisable={disabled}
                                        isactivedisable={citydisable}
                                        issearchable={true}
                                    />
                                    {cityerrorMessage && <div className="text-danger"> {cityerrorMessage} </div>}
                                </div>
                                <div class="col-lg-2 col-md-12 col-sm-12">
                                    <label for="inputPassword6" class="col-form-label">Active</label>
                                </div>
                                <div class="col-auto col-lg-4" disabled={false}>
                                    {/* <input class="form-check-input mt-2" type="checkbox" id="checkboxNoLabel" checked={activeId}
                                        aria-label="..." onChange={handleisActive} disabled={disabled} /> */}
                                    <Switch
                                        onChange={handleisActive}
                                        checked={activeId}
                                        className="react-switch"
                                        disabled={disabled ? true : false}
                                    />
                                </div>
                                {/* <div class=" col-auto col-lg-4"> */}
                                <ConfirmationModal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    noconfirm={() => handleConfirmation(false)}
                                    yesconfirm={() => handleConfirmation(true)}
                                />

                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center mb-4">
                        {useradd === false && buttonaddeditText === "Add" ?
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={!useradd}>{buttonaddeditText}</button>
                            : <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={editdisabled ? false : true}>{buttonaddeditText}</button>}
                        <button type="button" class="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick} >Clear</button>
                    </div>
                    <div class="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}

                                />
                            </div>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input type="text" onKeyDown={onKeyDownHandler} id="btnSearchText" class="form-control" placeholder='Enter keyword' name="SearchValue" onChange={handleChangeSearchValue} value={searchvalue} disabled={serchdisable ? true : false} />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div class="p-2 text-center">
                                <button type="button" class="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleClickSearchValue} >Search</button>
                            </div>
                        </div>

                    </div>

                    <CustomTable
                        columns={columns}
                        data={airportlistdata}
                        PdfExport={PdfExport}
                        ExportToExcel={ExportToExcel}
                        disabled={!userexport}
                    />
                </form>
            </div>
        </>
    )
}

export default Airportmaster