import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { ClearLocalStorage, GetLocalStorage } from "../commonClass/Session";
import { verifytoken } from "../../services/ApiCallingUserServices";

const WelcomePage = () => {
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');

    useEffect(() => {
        const tokenm = GetLocalStorage();
        setUsername((tokenm !== null || tokenm !== undefined || tokenm !== '') ? tokenm.userName : '');
  
        async function verifyTokenValue() {
           let statusoftoken = await verifytoken();
           if (!statusoftoken) {
              ClearLocalStorage();
              dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
              // alert('Your Session Expired. Please Login Again!');
              // navigate('/');
           }
        }
        verifyTokenValue();
        // setUsername(usrname);
     }, [])
    return(
        <>
         <div class="container-fluid d-flex justify-content-center align-items-center h-100">
            <h1 class="text_color">Welcome {username}</h1>
         </div>
        </>
    );
}
export default WelcomePage;