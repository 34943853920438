import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "../../../../assets/css/commoncontrol.css";

function PopUp(props) {
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='modelheader bg-primary header_closebtn'>
                <Modal.Title id="contained-modal-title-vcenter" className='header_text mx-auto h5'>
                    Message Alert!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='modalbody'>
                <p className='text-center fw-bold my-1'>
                    Are you sure you want to go back without saving?
                </p>
            </Modal.Body>
            <Modal.Footer className='modalfooter'>
                <div className='mx-auto'>
                    <Button variant="primary" className='mx-2 px-3' onClick={props.yesconfirm}>
                        Yes
                    </Button>
                    <Button variant="primary" className='mx-2 px-3' onClick={props.noconfirm}>
                        No
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default PopUp