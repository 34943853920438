import React from 'react'
import { useEffect, useState } from "react";
import "../../assets/css/master.css";
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { getAllData, patchUsersData, postUsersData, verifytoken } from '../../services/ApiCallingUserServices';
import { loaderActions } from '../../_actions/loader.actions';
import { useDispatch, useSelector } from "react-redux";
import { onlyNumeric } from '../commonClass/CommonValidation';
import { ClearLocalStorage } from '../commonClass/Session';
import { GetMenuIdFromStorage } from '../commonClass/Menudect';
import { useNavigate } from 'react-router-dom';

const InventoryManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [packstartdate, setPackStartDate] = useState(null);
    const [packenddate, setPackEndDate] = useState(null);
    const [filterstartdate, setFilterStartDate] = useState(null);
    const [filterstartend, setFilterEndDate] = useState(null);
    const [sqe, setSqe] = useState("");
    const [packagedata, setPackageData] = useState([])
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [packageType, setPackageType] = useState([]);
    const [typeId, setTypeId] = useState(0);
    const [createdfor, setCeatedFor] = useState([]);
    const [createdId, setCreatedId] = useState(0);
    const [formErrors, setFormErrors] = useState({});
    const [prevdisable, setPrevDisable] = useState(false);
    const [nextDisable, setNextDisable] = useState(false);
    const [updateIntDisable, setUpdateIntDisable] = useState(true)
    const [isRequest, setIsRequest] = useState(true);
    const [isBlackOut, setIsBlackOut] = useState(true);
    const [packageCategoryName, setPackageCategoryName] = useState('');
    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);
    const [updatedisable, setUpDateDisable] = useState(false)
    const [filterPackage, setFilterPackage] = useState({
        PackageTypeId: 0,
        CreateForId: 0,
        StartDate: '',
        EndDate: ''
    })
    const [filterdatalist, setFilterDatalList] = useState([]);
    const [packId, setPackId] = useState(0);
    const [packagename, setPackageName] = useState('')
    const [packagid, setPackageId] = useState({
        PackageId: null,
        VPackageId: null,
        PageNo: 1
    })
    const [form, setForm] = useState({
        InventoryId: 0,
        PkgInvCount: null,
        IsBlackOut: false,
        IsOnRequest: false,
        StartDate: '',
        EndDate: ''
    })

    const hadlesetdata = (e) => {
        setFormErrors({});
        setSqe("");
        if (e != null) {
            setSqe(parseInt(e.target.id))
            tableData?.filter(obj => obj.inventoryDetailId === parseInt(e.target.id)).map((value) => {
                form.PkgInvCount = value.pkgInventoryCount
                setPackStartDate(new Date(value.inventoryDate))
                setPackEndDate(new Date(value.inventoryDate))
                form.IsBlackOut = value.isBlackOut
                form.IsOnRequest = value.isOnRequest
                var d = new Date();
                d.setHours(0, 0, 0, 0);
                if (d > new Date(value.inventoryDate)) {
                    setUpDateDisable(true)
                    // console.log("inventoryDate", new Date(value.inventoryDate))
                    // console.log("inventoryDate1", d)
                }
                else {
                    setUpDateDisable(false)
                }
            })
        }
    }


    const setMultiData = () => {
        setUpDateDisable(false)
        if (packagid.PackageId > 0 || packagid.VPackageId > 0) {
            setFormErrors({});
            var d = new Date();
            d.setHours(0, 0, 0, 0);
            if (d < new Date(packagedata.startDate)) {
                setPackStartDate(new Date(packagedata.startDate));
                setUpDateDisable(false)
            }
            else if (d > new Date(packagedata.endDate)) {
                setPackStartDate(new Date(packagedata.startDate));
                setUpDateDisable(true)
            }
            else {
                setPackStartDate(new Date());
                setUpDateDisable(false)
            }
            setPackEndDate(new Date(packagedata.endDate));
            setForm({ ...form, IsBlackOut: false, IsOnRequest: false, PkgInvCount: packagedata.pkgInvCount })
        }
    }
    const getPackagebyId = async () => {
        await postUsersData('/master/v1/getinventoryretrive', packagid).then((packagebyid) => {
            setTotalPageCount(packagebyid.data[0].totalPage);
            setTableData(packagebyid.data[0].pkgInventoryDetails);
            setPackageData(packagebyid.data[0]);
            setPackStartDate(new Date(packagebyid.data[0].startDate));
            setPackEndDate(new Date(packagebyid.data[0].endDate));
            setForm({ ...form, InventoryId: packagebyid.data[0].inventoryId, PkgInvCount: packagebyid.data[0].pkgInvCount })
            if (packagebyid.data[0].totalPage === 1) {
                setPrevDisable(true);
                setNextDisable(true);
            }
            if (packagid.PageNo === packagebyid.data[0].totalPage) {
                setNextDisable(true);
            }
            else {
                setNextDisable(false);
            }
            dispatch(loaderActions.end());
        });

    }
    const getAvdfilterdata = async () => {
        await getAllData('/master/v1/getinventoryfilter').then((avddata) => {
            setPackageType(avddata.data.packageTypeList?.map((el) => ({
                label: el.PackageTypeName,
                value: el.PackageTypeId
            })))
            setCeatedFor(avddata.data.createFor?.map((el) => ({
                label: el.CreateFor,
                value: el.CreateForId
            })))
        })
    }
    const getPackagebtFilter = async () => {
        filterPackage.StartDate = filterPackage.StartDate === '' ? null : filterPackage.StartDate
        filterPackage.EndDate = filterPackage.EndDate === '' ? null : filterPackage.EndDate

        await postUsersData('/master/v1/getpackagefilter', filterPackage).then((filterdata) => {
            if (filterdata.data != null) {
                setFilterDatalList(filterdata.data?.map((filter) => ({
                    label: filter.PackageName,
                    value: filterPackage.CreateForId === 1 ? filter.PackageId : filter.VPackageId,
                    packageCategoryName: filter.PackageCategoryName
                })));
            }
            else {
                setFilterDatalList([]);
            }
        });
    }
    const ValidateFilter = () => {
        let status = true;
        let error = {}
        // if (filterPackage.StartDate === '' || filterPackage.EndDate === '' || filterstartend === null || filterstartdate === null) {
        //     error.daterange = "Kindly select the date range";
        //     status = false;
        // }
        if (filterPackage.PackageTypeId === 0) {
            error.packagetype = "Kindly select the package type";
            status = false;
        }
        if (filterPackage.CreateForId === 0) {
            error.createfor = "Kindly select the created for";
            status = false;
        }
        if (packId === 0) {
            error.packname = "Kindly select the package name";
            status = false;
        }
        setFormErrors(error);
        return status;

    }
    const [tempCreateid, setTempCreateId] = useState(0);
    const [temptypeid, setTempTypeId] = useState(0);
    const [temppackid, setTempPackId] = useState(0);
    const [tempdatalist, setTempDataList] = useState([]);
    const [tempfilterstart, setTempFilterstart] = useState('');
    const [tempFilterEnd, setTempFilterEnd] = useState('');

    const filterpackage = () => {
        if (!ValidateFilter()) {
            return;
        }
        else {
            setTempDataList([])
            setTempCreateId(0);
            setTempTypeId(0);
            setTempFilterstart('');
            setTempFilterEnd('');
            setTempPackId(0)
            dispatch(loaderActions.start());
            getPackagebyId();
            if (filterPackage.CreateForId === 1) {
                setPackageId({ ...packagid, PackageId: packId, PageNo: 1, VPackageId: null })
            }
            if (filterPackage.CreateForId === 2) {
                setPackageId({ ...packagid, VPackageId: packId, PageNo: 1, PackageId: null })
            }
            if (packId > 0) {
                filterdatalist.filter(pkgname => pkgname.value === packId).map((pkgdata) => {
                    setPackageName(pkgdata.label);
                    setPackageCategoryName(pkgdata.packageCategoryName)
                    setTempPackId(pkgdata.value)
                })
                setTempDataList(filterdatalist)
                setTempCreateId(filterPackage.CreateForId);
                setTempTypeId(filterPackage.PackageTypeId);
                setTempFilterstart(filterPackage.StartDate);
                setTempFilterEnd(filterPackage.EndDate);
                setUpdateIntDisable(false);
                document.getElementById("filterClose").click();
            }
        }
    }
    useEffect(() => {
        getAvdfilterdata();
    }, [])
    useEffect(() => {
        let pagelink = user?.find(x => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);

        });
    }, [user])
    useEffect(() => {
        if (packenddate != null) {
            setForm({ ...form, StartDate: formatDate(packstartdate), EndDate: formatDate(packenddate) })
        }
        if (packenddate === null && filterPackage.CreateForId > 0 && filterPackage.PackageTypeId > 0) {
            filterPackage.EndDate = ''
            getPackagebtFilter();
        }

    }, [packenddate])
    useEffect(() => {
        if (filterstartend != null) {
            setFilterPackage({ ...filterPackage, StartDate: formatDate(filterstartdate), EndDate: formatDate(filterstartend) })
        }
        if (filterstartend === null && filterPackage.CreateForId > 0 && filterPackage.PackageTypeId > 0) {
            filterPackage.EndDate = ''
            getPackagebtFilter();
        }
        if (filterstartdate === null && filterPackage.CreateForId > 0 && filterPackage.PackageTypeId > 0) {
            filterPackage.StartDate = ''
            getPackagebtFilter();
        }
    }, [filterstartend])

    //#region handle Change
    const handleChange = (e) => {
        setFormErrors({});
        var result;
        if ([e.target.name][0] === 'PkgInvCount') {
            result = onlyNumeric(e.target.value) === "" ? "" : Number(onlyNumeric(e.target.value))
        }
        else {
            result = e.target.value;
        }
        setForm({ ...form, [e.target.name]: result });

    }
    const handleRequest = (e) => {
        setFormErrors({});
        setForm({ ...form, [e.target.name]: e.target.checked, IsBlackOut: false });
    }
    const handleblackout = (e) => {
        setFormErrors({});
        setForm({ ...form, [e.target.name]: e.target.checked, IsOnRequest: false });
    }
    const handleUpdateDate = (dates) => {
        setFormErrors({});
        const [startDate, endDate] = dates;
        setPackEndDate(endDate);
        setPackStartDate(startDate);
    }
    const handletypechange = (e) => {
        setFormErrors({});
        setTypeId(e.value);
        setFilterPackage({ ...filterPackage, PackageTypeId: e.value });
        setPackId(0);
    }
    const handleCreatedchange = (e) => {
        setFilterPackage({ ...filterPackage, CreateForId: e.value });
        setFormErrors({});
        setCreatedId(e.value);
        setPackId(0);
    }
    const handlefilterdate = (dates) => {
        setPackId(0)
        setFormErrors({});
        const [startDate, endDate] = dates;
        setFilterEndDate(endDate);
        setFilterStartDate(startDate);
    }
    const handlepackagenamechange = (e) => {
        setFormErrors({});
        setPackId(e.value);
        setNextDisable(false);
    }

    //#endregion handle Change

    const ValidateUpdate = () => {
        let status = true;
        let error = {}
        if (form.StartDate === '' || form.EndDate === '' || packstartdate === null || packenddate === null) {
            error.InventoryDate = "Kindly select the date";
            status = false;
        }
        if (form.PkgInvCount === '') {
            error.PkgInvCount = "Kindly add the package inventory";
            status = false;
        }
        if (form.PkgInvCount > packagedata.pkgInvCount) {
            error.PkgInvCount = "Count should not exceed package inventory";
            status = false;
        }
        setFormErrors(error);
        return status;
    }
    const savedata = () => {
        if (!ValidateUpdate()) {
            return;
        }
        else {
            saveInventorydata();
            // if (sqe > 0) {
            //     tableData?.filter(obj => obj.inventoryDetailId === sqe).map((value) => {
            //         value.pacakageInventoryCount = packageint.PacakageInventoryCount
            //         value.isOnRequest = packageint.IsOnRequest
            //         value.isBlackOut = packageint.IsBlackOut
            //     })
            // }
            // else {
            //     tableData.filter(obj => new Date(obj.inventoryDate) >= new Date(dateRange[0]) && new Date(obj.inventoryDate) <= new Date(dateRange[1])).map((val) => {
            //         val.pacakageInventoryCount = packageint.PacakageInventoryCount
            //         // val.isOnRequest = packageint.IsOnRequest
            //     })
            // }
        }
        document.getElementById("closemodal1").click()
        setSqe("");
        setFormErrors({});
    }

    const saveInventorydata = async () => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            let status = false;
            const IntData = await patchUsersData('/master/v1/inventoryupdate', form)
            status = IntData.status;
            if (status !== 400) {
                try {
                    if (IntData.status) {
                        dispatch({ type: 'ALERTING', message: IntData.message })
                        getPackagebyId()
                    }

                }
                catch (err) {
                    dispatch({ type: 'ALERTING', message: err })
                    return;
                }
            }
            else {
                dispatch({ type: 'ALERTING', message: IntData.message })
                return;
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
    }
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = '' + d.getFullYear()

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return date === null ? null : [year, month, day].join('-');
    }
    useEffect(() => {
        if (filterPackage.CreateForId > 0 && filterPackage.PackageTypeId > 0) {
            getPackagebtFilter();
        }

    }, [filterPackage])
    useEffect(() => {
        if (packagid.PackageId > 0 || packagid.VPackageId > 0 || packId > 0) {
            if (packagid.PageNo === 1) {
                setPrevDisable(true);
            }
            if (packagid.PageNo === totalPageCount) {
                setNextDisable(true);
            }
            getPackagebyId();
        }
    }, [packagid])

    const nextRecored = () => {
        setFormErrors({});
        setPrevDisable(false);
        if (packagid.PageNo <= totalPageCount) {
            setPackageId({ ...packagid, PageNo: packagid.PageNo + 1 })
        }
    }
    const previousRecored = () => {
        setFormErrors({});
        setNextDisable(false);
        if (packagid.PageNo > 1) {
            setPackageId({ ...packagid, PageNo: packagid.PageNo - 1 })
        }
    }
    const clearmodal = () => {
        // setPackageInt({ ...packageint, InventoryDetailId: 0, InventoryDate: '', IsBlackOut: false, IsOnRequest: false })
        // setDateRange([null, null]);
        setFormErrors({});
    }
    const filterclose = () => {
        setFormErrors({});
    }
    const filterOpen = () => {
        setFormErrors({});
        if (temppackid > 0 && (packagid.PackageId > 0 || packagid.VPackageId > 0)) {
            setCreatedId(tempCreateid);
            setTypeId(temptypeid);
            setFilterStartDate(tempfilterstart === null ? null : new Date(tempfilterstart));
            setFilterEndDate(tempFilterEnd === null ? null : new Date(tempFilterEnd));
            setFilterPackage({ ...filterPackage, PackageTypeId: temptypeid, CreateForId: tempCreateid, StartDate: tempfilterstart, EndDate: tempFilterEnd })
            if (tempCreateid === 1) {
                setPackId(temppackid);
            }
            if (tempCreateid === 2) {
                setPackId(temppackid)
            }
        }
    }
    const clearfilterName = () => {
        // setFormErrors({});
    }
    const clearFilter = () => {
        setFormErrors({});
        setFilterPackage({
            ...filterPackage,
            PackageTypeId: 0,
            CreateForId: 0,
            StartDate: '',
            EndDate: ''
        });
        setCreatedId(0);
        setTypeId(0);
        setPackId(0);
        setFilterEndDate(null);
        setFilterStartDate(null);
        setFilterDatalList([]);
    }
    const openDatePicker = (e) => {
        //open date picker on click date icon 
        document.querySelector("#" + e).click();
    };


    return (
        <>
            <div className="container mt-4 heading_color">
                <div className='row'>
                    <div className='col-lg-6 col-md-5 col-sm-12'>
                        <h3>Inventory Management</h3>
                    </div>
                    <div className='col-lg-6 col-md-7 col-sm-12 text-end'>
                        <div className='d-flex justify-content-end color_code'>
                            <span className='bg_available spancolor_size mx-1'>
                            </span>
                            <span>Available</span>
                            <span className='bg_SoldOut spancolor_size mx-1'>
                            </span>
                            <span>Sold Out</span>
                            <span className='bg_OnRequest spancolor_size mx-1'>
                            </span>
                            <span>On Request</span>
                            <span className='bg_BlackOut spancolor_size mx-1'>
                            </span>
                            <span>Black Out</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container form_border py-3 mb-3 mt-2">
                <form action="">
                    <div className='row mb-3'>
                        <div className="col-4 d-flex justify-content-start">
                            {(useredit === false) || (useradd === false) ?
                                <button type="button" disabled={true} className="btn btn-primary mx-1" id="btnAdd" onClick={setMultiData} data-bs-toggle="modal" data-bs-target="#InventoryModal">Update Inventory</button> :
                                <button type="button" disabled={updateIntDisable} className="btn btn-primary mx-1" id="btnAdd" onClick={setMultiData} data-bs-toggle="modal" data-bs-target="#InventoryModal">Update Inventory</button>}
                        </div>
                        <div className="col-8 d-flex justify-content-end">
                            {packagename && <div className='filter_section mx-2'>{packagename} <button type='button' onClick={clearfilterName} className='filter_buttonclose ms-1 bg-danger border-0 text-white'><i class="fa fa-times" aria-hidden="true"></i></button></div>}
                            {/* <div className='filter_section mx-2'>3 Night Goa Regular <button type='button' className='filter_buttonclose ms-1 bg-danger border-0 text-white'><i class="fa fa-times" aria-hidden="true"></i></button></div> */}
                            {/* <div className='filter_section mx-2'>3 Night Goa Regular <button type='button' className='filter_buttonclose ms-1 bg-danger border-0 text-white'><i class="fa fa-times" aria-hidden="true"></i></button></div> */}
                            <button type="button" className="btn btn-primary mx-1" onClick={filterOpen} id="btnAdd" data-bs-toggle="modal" data-bs-target="#FilterModal"><i className="fa fa-filter" aria-hidden="true"></i></button>
                        </div>
                    </div>
                    <div className='overflow-auto'>
                        <table className="table table-bordered tableborder_color">
                            <thead className='tableheaderborder_color'>
                                {tableData.length > 0 ?
                                    (<tr>
                                        <th scope="col">Package Variance</th>
                                        {tableData.map((el) => (
                                            <th className='text-center' scope="col">{el.monthName}</th>
                                        ))}
                                    </tr>) :
                                    (<tr>
                                        <th scope="col" width="268px">Package Variance</th>
                                        <td className='text-center'></td>
                                        <td className='text-center'></td>
                                        <td className='text-center'></td>
                                        <td className='text-center'></td>
                                        <td className='text-center'></td>
                                        <td className='text-center'></td>
                                        <td className='text-center'></td>
                                        <td className='text-center'></td>
                                        <td className='text-center'></td>
                                        <td className='text-center'></td>

                                    </tr>
                                    )}
                            </thead>
                            <tbody className='table_body'>
                                {tableData.length > 0 ?
                                    (<tr>
                                        <th scope="row">Package Category</th>
                                        {tableData.map((el) => (
                                            (useredit === false || useradd === false) ? <td data-bs-toggle="modal" data-bs-target="" onClick={hadlesetdata} id={el.inventoryDetailId} className={`py-1 ${(el.isBlackOut === true ? "bg_BlackOut" : "bg_available")} ${(el.isOnRequest === true ? "bg_OnRequest" : "bg_available")} ${(el.pkgInventoryCount === 0 ? "bg_SoldOut" : "bg_available")}`}><span id={el.inventoryDetailId} className='bg-white tabspan_width'>{el.isBlackOut === false ? el.pkgInventoryCount : "X"}</span></td> :
                                                <td data-bs-toggle="modal" data-bs-target="#InventoryModal" onClick={hadlesetdata} id={el.inventoryDetailId} className={`py-1 ${(el.isBlackOut === true ? "bg_BlackOut" : "bg_available")} ${(el.isOnRequest === true ? "bg_OnRequest" : "bg_available")} ${(el.pkgInventoryCount === 0 ? "bg_SoldOut" : "bg_available")}`}><span id={el.inventoryDetailId} className='bg-white tabspan_width'>{el.isBlackOut === false ? el.pkgInventoryCount : "X"}</span></td>

                                            // <td data-bs-toggle="modal" data-bs-target="#InventoryModal" onClick={hadlesetdata} id="col2" className='bg_SoldOut py-1'><span id="col2" className='bg-white tabspan_width'>{colValue.col2}</span></td>
                                            // <td data-bs-toggle="modal" data-bs-target="#InventoryModal" onClick={hadlesetdata} id="col3" className='bg_OnRequest py-1'><span id="col3" className='bg-white tabspan_width'>{colValue.col3}</span></td>
                                            // <td data-bs-toggle="modal" data-bs-target="#InventoryModal" onClick={hadlesetdata} id="col4" className='bg_BlackOut py-1'><span id="col4" className='bg-white tabspan_width'>{colValue.col4}</span></td>
                                            // <td data-bs-toggle="modal" data-bs-target="#InventoryModal" onClick={hadlesetdata} id="col5" className='bg_available py-1'><span id="col5" className='bg-white tabspan_width'>{colValue.col5}</span></td>
                                        ))}
                                    </tr>) :
                                    (<tr>
                                        <th scope="row">Package Category</th>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>)}
                                {/* {tableData.length > 0 ?
                                    (<tr>
                                        <th scope="row">Package Service</th>
                                        {tableData.map((el) => (
                                            (useredit === false || useradd === false) ? <td data-bs-toggle="modal" data-bs-target="" onClick={hadlesetdata} id={el.inventoryDetailId} className={`py-1 ${(el.isBlackOut === true ? "bg_BlackOut" : "bg_available")} ${(el.isOnRequest === true ? "bg_OnRequest" : "bg_available")} ${(el.pkgInventoryCount === 0 ? "bg_SoldOut" : "bg_available")}`}><span id={el.inventoryDetailId} className='bg-white tabspan_width'>{el.isBlackOut === false ? el.pkgInventoryCount : "X"}</span></td> :
                                                <td></td>
                                        ))}
                                    </tr>) :
                                    (<tr>
                                        <th scope="row">Package Service</th>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>)} */}
                                {tableData.length > 0 ?
                                    (<tr>
                                        <th scope="row">Package Service</th>
                                        {tableData.map((el) => (
                                            <td></td>
                                        ))}
                                    </tr>) :
                                    (<tr>
                                        <th scope="row">Package Service</th>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>)}
                                {tableData.length > 0 ?
                                    (<tr>
                                        <th scope="row"><i className="fa fa-user inventorycolor px-2" aria-hidden="true"></i><span className='ms-2'>INR  <span className=" btn-primary mx-1 px-1">Individual</span></span></th>
                                        {tableData.map((el) => (
                                            <td className='text-center'>{el.individualValue}</td>
                                        ))}
                                    </tr>) :
                                    (<tr>
                                        <th scope="row"><i className="fa fa-user inventorycolor px-2" aria-hidden="true"></i><span className='ms-2'>INR  <span className=" btn-primary mx-1 px-1">Individual</span></span></th>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>)}
                                {tableData.length > 0 ?
                                    (<tr>
                                        <th scope="row"><i className="fa fa-users inventorycolor px-2" aria-hidden="true"></i><span className='ms-1'>INR  <span className=" btn-primary mx-1 px-1">Twin</span></span></th>
                                        {tableData.map((el) => (
                                            <td className='text-center'>{el.twinValue}</td>
                                        ))}
                                    </tr>) :
                                    (<tr>
                                        <th scope="row"><i className="fa fa-user inventorycolor px-2" aria-hidden="true"></i><span className='ms-2'>INR  <span className=" btn-primary mx-1 px-1">Individual</span></span></th>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>)}
                            </tbody>
                        </table>
                    </div>
                    <div className="modal fade" id="InventoryModal" tabIndex="1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-header px-1 border-0 inventory_headermodal">
                                    <div className="float-start col-11">
                                        <h5 className="modal-title inventory_title text-center ms-5" id="exampleModalLabel">Update Inventory</h5>
                                    </div>
                                    <div className="float-end text-end col-1">
                                        <button type="button" id='closemodal1' onClick={clearmodal} className="btn-close closebtn_position" data-bs-dismiss="modal" aria-label="Close">
                                            {/* <i className="fa-solid fa-xmark"></i> */}
                                            </button>
                                    </div>
                                </div>
                                <div className="modal-body px-1">
                                    <div className=" row mx-0">
                                        {/* {flightduplicateerrorMessage && <p className='text-danger pb-3 text-center'>{flightduplicateerrorMessage}</p>} */}
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div className="col-lg-12 col-md-12 mb-1">
                                                    <label for="inputPassword6" className="col-form-label required pb-0">Package Type</label>
                                                </div>
                                                <div className="col-lg-12 mb-1 col-md-12">
                                                    <Select
                                                        id="Supplierid"
                                                        className="dropdown select_dropdown"
                                                        options={packageType}
                                                        value={packageType?.filter(function (packageType) {
                                                            return packageType.value === temptypeid;
                                                        })}
                                                        onChange={handletypechange} // assign onChange function
                                                        isDisabled
                                                        isSearchable
                                                    />
                                                    {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div className="col-lg-12 col-md-12 mb-1">
                                                    <label for="inputPassword6" className="col-form-label required pb-0">Created For</label>
                                                </div>
                                                <div className="col-lg-12 mb-1 col-md-12">
                                                    <Select
                                                        id="Supplierid"
                                                        className="dropdown select_dropdown"
                                                        options={createdfor}
                                                        value={createdfor?.filter(function (createdfor) {
                                                            return createdfor.value === tempCreateid;
                                                        })}
                                                        onChange={handleCreatedchange} // assign onChange function
                                                        isDisabled
                                                        isSearchable
                                                    />
                                                    {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div className="col-lg-12 col-md-12 mb-1">
                                                    <label for="inputPassword6" className="col-form-label required pb-0">Package Name</label>
                                                </div>
                                                <div className="col-lg-12 mb-1 col-md-12">
                                                    <Select
                                                        id="Supplierid"
                                                        className="dropdown select_dropdown"
                                                        options={tempdatalist}
                                                        value={tempdatalist?.filter(function (tempdatalist) {
                                                            return tempdatalist.value === temppackid;
                                                        })}
                                                        onChange={handlepackagenamechange} // assign onChange function
                                                        isDisabled
                                                        isSearchable
                                                    />
                                                    {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div className="col-lg-12 col-md-12 mb-1">
                                                    <label for="inputPassword6" className="col-form-label required pb-0">Select Date</label>
                                                </div>
                                                <div className="col-lg-12 mb-1 col-md-12">
                                                    <div className="text-container">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            className='form-control'
                                                            id='updatedate'
                                                            selected={packstartdate}
                                                            placeholderText="dd/mm/yyyy"
                                                            onInputClick={false}
                                                            selectsRange
                                                            autoComplete='off'
                                                            // minDate={new Date()}
                                                            minDate={new Date() < new Date(packagedata.startDate) ? new Date(packagedata.startDate) : new Date()}
                                                            maxDate={new Date(packagedata.endDate)}
                                                            startDate={packstartdate}
                                                            endDate={packenddate}
                                                            // onChange={(update) => {
                                                            //     setDateRange(update);
                                                            // }}
                                                            onChange={handleUpdateDate}
                                                            isClearable={true}
                                                            disabled={updatedisable}
                                                            selectsDisabledDaysInRange
                                                        />
                                                        <span className="example-custom-input calendarBtn"
                                                            onClick={(e) => openDatePicker("updatedate")}
                                                            id="calendarBtn1"
                                                        ><i className="fa fa-calendar"></i></span>
                                                    </div>
                                                    {formErrors.InventoryDate && <p className='text-danger text-start'>{formErrors.InventoryDate}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div className="col-lg-12 col-md-12 mb-1">
                                                    <label for="inputPassword6" className="col-form-label required pb-0">Package Catgory</label>
                                                </div>
                                                <div className="col-lg-12 mb-1 col-md-12">
                                                    <input type='text' disabled
                                                        value={packageCategoryName}
                                                        className="form-control" />
                                                    {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div className="col-lg-12 col-md-12 mb-1">
                                                    <label for="inputPassword6" className="col-form-label required pb-0">Package Inventory</label>
                                                </div>
                                                <div className="col-lg-12 mb-1 col-md-12">
                                                    <input type='text'
                                                        name='PkgInvCount'
                                                        maxLength={2}
                                                        value={form.PkgInvCount}
                                                        onChange={handleChange}
                                                        placeholder='Package Inventory'
                                                        className="form-control" />
                                                    {formErrors.PkgInvCount && <p className='text-danger text-start'>{formErrors.PkgInvCount}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div className="col-lg-12 col-md-12 mb-1">
                                                    <label for="inputPassword6" className="col-form-label required pb-0">Package Service</label>
                                                </div>
                                                <div className="col-lg-12 mb-1 col-md-12">
                                                    <input type='text' disabled
                                                        // value={suppliercode}
                                                        placeholder='Flight'
                                                        className="form-control" />
                                                    {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div className="col-lg-12 col-md-12 mb-1">
                                                    <label for="inputPassword6" className="col-form-label required pb-0">Flight Inventory</label>
                                                </div>
                                                <div className="col-lg-12 mb-1 col-md-12">
                                                    <input type='text'
                                                        name='abc'
                                                        // value={packageint.PacakageInventoryCount}
                                                        // onChange={handleChange}
                                                        placeholder='Flight Inventory'
                                                        className="form-control" />
                                                    {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div className="col-lg-4 mb-1 col-md-12">
                                                    <label class="form-check-label" >
                                                        On Request
                                                    </label>
                                                </div>
                                                <div className="col-lg-8 col-md-12 mb-1">
                                                    <input class="form-check-input" name='IsOnRequest' onClick={handleRequest} checked={form.IsOnRequest === isRequest} value={isRequest} type="checkbox" id="Request" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div className="col-lg-4 mb-1 col-md-12">
                                                    <label class="form-check-label">
                                                        Is Black Out
                                                    </label>
                                                </div>
                                                <div className="col-lg-8 col-md-12 mb-1">
                                                    <input class="form-check-input" name='IsBlackOut' onClick={handleblackout} checked={form.IsBlackOut === isBlackOut} value={isBlackOut} type="checkbox" id="BlackOut" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" d-flex justify-content-center mt-1">
                                            <button type="button" disabled={updatedisable} className="btn btn-primary mx-1" onClick={savedata} id="btnAdd">Update</button>
                                            <button type="button" className="btn btn-primary mx-1" onClick={clearmodal} id="btnClear" data-bs-dismiss="modal" >Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="FilterModal" tabIndex="1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-header px-1 border-0 inventory_headermodal">
                                    <div className="float-start col-11">
                                        <h5 className="modal-title inventory_title text-center ms-5" id="exampleModalLabel">Advance Filter</h5>
                                    </div>
                                    <div className="float-end text-end col-1">
                                        <button type="button" onClick={filterclose} id='filterClose' className="btn-close closebtn_position" data-bs-dismiss="modal" aria-label="Close">
                                            {/* <i className="fa-solid fa-xmark"></i> */}
                                            </button>
                                    </div>
                                </div>
                                <div className="modal-body px-1">
                                    <div className=" row mx-0">
                                        {/* {flightduplicateerrorMessage && <p className='text-danger pb-3 text-center'>{flightduplicateerrorMessage}</p>} */}
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div className="col-lg-12 col-md-12 mb-1">
                                                    <label for="inputPassword6" className="col-form-label required pb-0">Package Type</label>
                                                </div>
                                                <div className="col-lg-12 mb-1 col-md-12">
                                                    <Select
                                                        id="Supplierid"
                                                        // ref={Supplierid}
                                                        className="dropdown select_dropdown"
                                                        options={packageType}
                                                        value={packageType?.filter(function (packageType) {
                                                            return packageType.value === typeId;
                                                        })}
                                                        onChange={handletypechange} // assign onChange function
                                                        // isDisabled={disabled ? true : false}
                                                        isSearchable
                                                    />
                                                    {formErrors.packagetype && <p className='text-danger text-start mb-0'>{formErrors.packagetype}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div className="col-lg-12 col-md-12 mb-1">
                                                    <label for="inputPassword6" className="col-form-label required pb-0">Created For</label>
                                                </div>
                                                <div className="col-lg-12 mb-1 col-md-12">
                                                    <Select
                                                        id="Supplierid"
                                                        // ref={Supplierid}
                                                        className="dropdown select_dropdown"
                                                        options={createdfor}
                                                        value={createdfor?.filter(function (createdfor) {
                                                            return createdfor.value === createdId;
                                                        })}
                                                        onChange={handleCreatedchange} // assign onChange function
                                                        // isDisabled={disabled ? true : false}
                                                        isSearchable
                                                    />
                                                    {formErrors.createfor && <p className='text-danger text-start mb-0'>{formErrors.createfor}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div className="col-lg-12 col-md-12 mb-1">
                                                    <label for="inputPassword6" className="col-form-label pb-0">Select Date</label>
                                                </div>
                                                <div className="col-lg-12 mb-1 col-md-12">

                                                    <div className="text-container">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            className='form-control'
                                                            id='selectdate'
                                                            selected={filterstartdate}
                                                            placeholderText="dd/mm/yyyy"
                                                            selectsRange={true}
                                                            autoComplete='off'
                                                            showMonthDropdown
                                                            startDate={filterstartdate}
                                                            endDate={filterstartend}
                                                            onChange={handlefilterdate}
                                                            isClearable={true}
                                                        />
                                                        <span className="example-custom-input calendarBtn"
                                                            onClick={(e) => openDatePicker("selectdate")}
                                                            id="calendarBtn1"
                                                        ><i className="fa fa-calendar"></i></span>
                                                    </div>
                                                    {formErrors.daterange && <p className='text-danger text-start mb-0'>{formErrors.daterange}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div className="col-lg-12 col-md-12 mb-1">
                                                    <label for="inputPassword6" className="col-form-label required pb-0">Package Name</label>
                                                </div>
                                                <div className="col-lg-12 mb-1 col-md-12">
                                                    <Select
                                                        id="Supplierid"
                                                        // ref={Supplierid}
                                                        className="dropdown select_dropdown"
                                                        options={filterdatalist}
                                                        value={filterdatalist?.filter(function (filterdatalist) {
                                                            return filterdatalist.value === packId;
                                                        })}
                                                        onChange={handlepackagenamechange} // assign onChange function
                                                        // isDisabled={disabled ? true : false}
                                                        isSearchable
                                                    />
                                                    {formErrors.packname && <p className='text-danger text-start mb-0'>{formErrors.packname}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" d-flex justify-content-center mt-1">
                                            <button type="button" onClick={filterpackage} className="btn btn-primary mx-1" id="btnAdd">Filter</button>
                                            <button type="button" onClick={clearFilter} className="btn btn-primary mx-1" id="btnClear" >Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(packagid.PackageId > 0 || packagid.VPackageId > 0) && <div className=" d-flex justify-content-end mt-1">
                        <button onClick={previousRecored} disabled={prevdisable} type="button" className="btn btn-primary mx-1" id="btnAdd">Previous 10 Days</button>
                        <button onClick={nextRecored} disabled={nextDisable} type="button" className="btn btn-primary mx-1" id="btnClear" >Next 10 Days</button>
                    </div>}
                </form >
            </div >
        </>
    )
}

export default InventoryManagement