import React from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from 'react-select';
import "../../assets/css/master.css"
import { getAllData } from '../../services/ApiCallingUserServices';
import { generatePDF } from '../commonClass/CommonValidation'
import { useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../commonClass/Session';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../_actions/loader.actions";
import { ExportExcel } from '../commonClass/ExportExcel';
import { errorActions } from '../../_actions/error.action';
import { GetMenuIdFromStorage } from '../commonClass/Menudect';
import CustomTable from '../../customControl/CustomTable'

const VendorPackagelistmaster = () => {
    const dispatch = useDispatch();
    /////////////////////declartion of variable ///////////////////////////////////////
    //#region declartion of variable
    const navigate = useNavigate();
    const [packagedata, setPackageData] = useState([]);
    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('7');
    const [filteritems, setFilterItems] = useState([]);
    const [clrshow, setsclshow] = useState(false);
    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);
    //const { items, requestSort, sortConfig } = useSortableData(taglistdata);

    const serachoption = [
        {
            label: 'State',
            value: '1'
        },
        {
            label: 'Package Name',
            value: '2'
        },
        {
            label: 'Package Code',
            value: '3'
        },
        {
            label: 'Package Type',
            value: '4'
        },
        {
            label: 'Package Level',
            value: '5'
        },
        {
            label: 'Package Category',
            value: '9'
        },
        // {
        //     label: 'Arrival Slot',
        //     value: '6'
        // },
        // {
        //     label: 'Departure Slot',
        //     value: '7'
        // },
        // {
        //     label: 'Package Validity Start',
        //     value: '8'
        // },
        // {
        //     label: 'Package Validity End',
        //     value: '9'
        // },
        // {
        //     label: 'Package Inventory Count',
        //     value: '10'
        // },
        // {
        //     label: 'Package Cutoff Count',
        //     value: '11'
        // },
        // {
        //     label: 'Package Highlights',
        //     value: '12'
        // },
        // {
        //     label: 'Active',
        //     value: '13'
        // },
        // {
        //     label: 'All',
        //     value: '14'
        // },

        {
            label: 'Active',
            value: '6'
        },
        // {
        //     label: 'Created Date',
        //     value: '8'
        // },
        {
            label: 'All',
            value: '7'
        },

    ];

    //#endregion
    /////////////////////////////////////////////////////////////////////////////////////


    ////////////////////////////set form date for add, edit and view////////////////////


    //////////////////////////////use effect section //////////////////////////////////
    //#region  use effect section
    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            //if(Window.$name &&  Window.$userToken)        {
            getPackageDataList().then(() => { dispatch(loaderActions.end()) });;
            setitems();
        }
        else {
            ClearLocalStorage();
            // alert('Your Session Expired. Please Login Again!');
            // navigate('/');
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
        //dispatch(loaderActions.end());
    }, [])
    useEffect(() => {
        let pagelink = user?.find(x => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        }
        let MENUID = GetMenuIdFromStorage()
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);
        });
    }, [user])

    //#endregion
    //////////////////////////////////////////////////////////////////////////////////////////////


    ///////////////////////fill dropdown and handle input value//////////////////////////
    //#region fill dropdown and handle input value


    const getPackageDataList = async () => {
        const packagedata = await getAllData("/master/vendor/v1/vengetallpackage");
        setPackageData((packagedata.data !== null && packagedata.data !== undefined) ? packagedata.data : [])
    }


    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }

    const setitems = async () => {
        const packagedata = await getAllData("/master/vendor/v1/vengetallpackage");
        // const packagedata = await getAllData("/general/v1/getallpackage");
        setFilterItems(packagedata.data);
    }
    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            getPackageDataList();
            setsclshow(false)
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleClickSearchValue();
        }
    };

    const handleClickSearchValue = () => {
        if (typeof searchvalue === 'string' && searchvalue.trim().length !== 0 && searchby > 0) {
            setitems();
            var filteritem = filteritems;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec.StateName?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec.PackageName?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '3') {
                filterdata = filteritem?.filter(issec => issec.PackageCode?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '4') {
                filterdata = filteritem?.filter(issec => issec.PackageTypeName?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '5') {
                filterdata = filteritem?.filter(issec => issec.PackageLevelName?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '8') {
                filterdata = filteritem?.filter(issec => formatDate(issec.CreatedOn)?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '9') {
                filterdata = filteritem?.filter(issec => issec.PackageCategoryName?.toLowerCase().includes(searchvalue.toLowerCase()));
            }

            else if (searchby === '6') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === true || issec.IsActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => formatDate(issec.CreatedOn).toLowerCase().includes("%#$&%^"));
                }


                // else {
                //     filterdata = filteritem;
                // }
            }

            else if (searchby === '7') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    //filterdata = filteritem.filter(issec => issec.inActive === false);
                    filterdata = filteritem?.filter(issec => issec.StateName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageCode?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageTypeName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageLevelName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageCategoryName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.ArrivalSlot?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.DeparturelSlot?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageValidateStart?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageValidateEnd?.toLowerCase().includes(searchvalue.toLowerCase())
                        // || issec.PackageInventoryCount?.toLowerCase().includes(searchvalue.toLowerCase()) 
                        // || issec.PackageCutOffCount?.toLowerCase().includes(searchvalue.toLowerCase()) 
                        || issec.PackageHighlights?.toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDate(issec.CreatedOn)?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.IsActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    //filterdata = filteritem.filter(issec => issec.inActive === true);
                    filterdata = filteritem?.filter(issec => issec.StateName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageCode?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageTypeName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageLevelName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageCategoryName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.ArrivalSlot?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.DeparturelSlot?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageValidateStart?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageValidateEnd?.toLowerCase().includes(searchvalue.toLowerCase())
                        // || issec.PackageInventoryCount?.toLowerCase().includes(searchvalue.toLowerCase()) 
                        // || issec.PackageCutOffCount?.toLowerCase().includes(searchvalue.toLowerCase()) 
                        || issec.PackageHighlights?.toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDate(issec.CreatedOn)?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.IsActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.StateName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageCode?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageTypeName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageLevelName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageCategoryName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.ArrivalSlot?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.DeparturelSlot?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageValidateStart?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageValidateEnd?.toLowerCase().includes(searchvalue.toLowerCase())
                        // || issec.PackageInventoryCount?.toLowerCase().includes(searchvalue.toLowerCase()) 
                        // || issec.PackageCutOffCount?.toLowerCase().includes(searchvalue.toLowerCase()) 
                        || issec.PackageHighlights?.toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDate(issec.CreatedOn)?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.IsActive === true || issec.IsActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    //filterdata = filteritem.filter(issec => issec.inActive === true);
                    filterdata = filteritem.filter(issec => issec.StateName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageCode?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageTypeName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageLevelName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageCategoryName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.ArrivalSlot?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.DeparturelSlot?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageValidateStart?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageValidateEnd?.toLowerCase().includes(searchvalue.toLowerCase())
                        // || issec.PackageInventoryCount?.toLowerCase().includes(searchvalue.toLowerCase()) 
                        // || issec.PackageCutOffCount?.toLowerCase().includes(searchvalue.toLowerCase()) 
                        || issec.PackageHighlights?.toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDate(issec.CreatedOn)?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.IsActive === false);
                }
                else {
                    // filterdata = filteritem.filter(issec => issec.stateName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.PackageName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.PackageCode?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.PackageTypeName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.PackageLevelName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.ArrivalSlot?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.DeparturelSlot?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.PackageValidateStart?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.PackageValidateEnd?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.PackageInventoryCount?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.PackageCutOffCount?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.PackageHighlights?.toLowerCase().includes(searchvalue.toLowerCase()));
                    filterdata = filteritem?.filter(issec => issec.StateName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageCode?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageTypeName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDate(issec.CreatedOn)?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageLevelName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageCategoryName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || 'active'.toLowerCase().includes(searchvalue.toLowerCase())
                        || 'inactive'.toLowerCase().includes(searchvalue.toLowerCase()));
                }
            }
            setPackageData(filterdata)
        }
    }

    function PdfExport() {
        //print
        const columns = [
            "State Name",
            "Package Name",
            "Package Code",
            "Package Type",
            "Package Level",
            "Package Category",
            // "Arrival Slot",
            // "Departure Slot",
            // "Package Validity Start",
            // "Package Validity End",
            // "Package Inventory Count",
            // "Package Cutoff Count",
            // "Package Highlights",
            "Active",
            "Created Date"
        ];
        var rows = [];

        for (let i = 0; i < packagedata?.length; i++) {
            var temp = [
                packagedata[i].StateName,
                packagedata[i].PackageName,
                packagedata[i].PackageCode,
                packagedata[i].PackageTypeName,
                packagedata[i].PackageLevelName,
                packagedata[i].PackageCategoryName,
                // packagedata[i].ArrivalSlot,
                // packagedata[i].DeparturelSlot,
                // formatDate(packagedata[i].PackageValidateStart),
                // formatDate(packagedata[i].PackageValidateEnd),
                // packagedata[i].PackageInventoryCount,
                // packagedata[i].PackageCutOffCount,
                // packagedata[i].PackageHighlights,
                `${packagedata[i].IsActive ? 'Active' : 'InActive'}`,
                formatDate(packagedata[i].CreatedOn)
            ];
            rows = [...rows, temp]
        }
        generatePDF(columns, rows, "VendorPackageMaster", "Vendor Package Master", 'l')
    }
    //#endregion
    //////////////////////////////////////////////////////////////////////////////////////

    ///////////////////////////handle table Pagination///////////////////////////////////
    //#region table Pagination
    const columns = [
        {
            name: 'State',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (pack) => pack.StateName
        },
        {
            name: 'Package Name',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (pack) => pack.PackageName
        },
        {
            name: 'Package Code',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (pack) => pack.PackageCode
        },
        {
            name: 'Package Type',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (pack) => pack.PackageTypeName
        },
        {
            name: 'Package Level',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (pack) => pack.PackageLevelName
        },
        {
            name: 'Package Category',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (pack) => pack.PackageCategoryName
        },
        // ,
        // {
        //     name: 'Arrival Slot',
        //     sortable: true,
        //     //  minWidth: "220px",
        //     reorder: true,
        //     selector: (pack) => pack.ArrivalSlot
        // },
        // {
        //     name: 'Departure Slot',
        //     sortable: true,
        //     //  minWidth: "230px",
        //     reorder: true,
        //     selector: (pack) => pack.DeparturelSlot
        // },
        // {
        //     name: 'Package Validity Start',
        //     sortable: true,
        //     //    minWidth: "150px",
        //     selector: (pack) => pack.PackageValidateStart
        // },
        // {
        //     name: 'Package Validity End',
        //     sortable: true,
        //     // minWidth: "150px",
        //     selector: (pack) => pack.PackageValidateEnd
        // },
        // {
        //     name: 'Package Inventory Count',
        //     sortable: true,
        //     //  minWidth: "180px",
        //     selector: (pack) => pack.PackageInventoryCount
        // },
        // {
        //     name: 'Package Cutoff Count',
        //     sortable: true,
        //     //  minWidth: "150px",
        //     selector: (pack) => pack.PackageCutOffCount
        // },
        // {
        //     name: 'Package Highlights',
        //     sortable: true,
        //     // minWidth: "100px",
        //     selector: (pack) => pack.PackageHighlights
        // },
        {
            name: 'Active',
            sortable: true,
            float: "left",
            selector: (pack) => pack.IsActive ? 'Active' : 'InActive'
        },
        {
            name: 'Created Date',
            sortable: true,
            float: "left",
            selector: (pack) => formatDate(pack.CreatedOn)
        },
        {
            name: 'Edit',
            // float:"right",
            selector: (pack) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEditPackage(pack.VPackageId)}></i></button>
        },
        {
            name: 'View',
            // float:"right",
            selector: (pack) => <i className="fa fa-eye" onClick={() => handleViewPackage(pack.VPackageId)}></i>
        }
    ];



    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getPackageDataList();
    }
    //#endregion
    ////////////////////////////////////////////////////////////////////////////////////////

    const handleOpenPackage = () => {
        dispatch(errorActions.resetallstatusflag());
        dispatch(errorActions.submitend());

        // dispatch(errorActions.inclusionreset());
        // dispatch(errorActions.exclusionreset());
        // dispatch(errorActions.policyreset());
        // dispatch(errorActions.aboutplacereset());
        // dispatch(errorActions.itineraryreset());

        // dispatch(errorActions.overviewdurationreset());
        // dispatch(errorActions.overviewdurationfromreset());
        // dispatch(errorActions.overviewdurationtoreset());
        // dispatch(errorActions.overviewvisitfromreset());
        // dispatch(errorActions.overviewvisittoreset());
        // dispatch(errorActions.overviewthemereset());

        dispatch(errorActions.resetallcounter())


        navigate('/VendorPackagemaster', { state: { disabled: false, editdisabled: false, viewtype: 'Add', PackageId: 0, atfirstdisable: true } });
        // navigate('/NewVendormaster', { state: { disabled: false, editdisabled: false, viewtype: 'Add', PackageId: 0 } });


    }

    const handleEditPackage = (id) => {
        dispatch(loaderActions.start())
        //let PackageId = 0;

        // packagedata.map(obj => {
        //     PackageId = obj.PackageId;
        // });
        dispatch(errorActions.resetallstatusflag());
        dispatch(errorActions.submitend());

        // dispatch(errorActions.inclusionreset());
        // dispatch(errorActions.exclusionreset());
        // dispatch(errorActions.policyreset());
        // dispatch(errorActions.aboutplacereset());
        // dispatch(errorActions.itineraryreset());

        // dispatch(errorActions.overviewdurationreset());
        // dispatch(errorActions.overviewdurationfromreset());
        // dispatch(errorActions.overviewdurationtoreset());
        // dispatch(errorActions.overviewvisitfromreset());
        // dispatch(errorActions.overviewvisittoreset());
        // dispatch(errorActions.overviewthemereset());

        dispatch(errorActions.resetallcounter())
        navigate('/VendorPackagemaster', { state: { disabled: false, editdisabled: true, viewtype: 'Update', PackageId: id, atfirstdisable: false } });
        // navigate('/NewVendormaster', { state: { disabled: false, editdisabled: true, viewtype: 'Update', PackageId: id } });
    }

    const handleViewPackage = (id) => {
        dispatch(loaderActions.start());

        let PackageId = 0;

        packagedata.map((obj) => {
            PackageId = obj.PackageId;
        });
        dispatch(errorActions.resetallstatusflag());
        dispatch(errorActions.submitend());

        // dispatch(errorActions.inclusionreset());
        // dispatch(errorActions.exclusionreset());
        // dispatch(errorActions.policyreset());
        // dispatch(errorActions.aboutplacereset());
        // dispatch(errorActions.itineraryreset());


        // dispatch(errorActions.overviewdurationreset());
        // dispatch(errorActions.overviewdurationfromreset());
        // dispatch(errorActions.overviewdurationtoreset());
        // dispatch(errorActions.overviewvisitfromreset());
        // dispatch(errorActions.overviewvisittoreset());
        // dispatch(errorActions.overviewthemereset());

        dispatch(errorActions.resetallcounter())
        navigate('/VendorPackagemaster', { state: { disabled: true, editdisabled: true, viewtype: 'View', PackageId: id, atfirstdisable: false } });
        // navigate('/NewVendormaster', { state: { disabled: true, editdisabled: true, viewtype: 'View', PackageId: id } });
    }

    const ExportToExcel = () => {
        let dispalyname = ['State Name', 'Package Name', 'Package Code', 'PackageType Name', 'Package Level Name', 'Package Category', 'Active', 'Created Date']
        const rowcolname = ['StateName', 'PackageName', 'PackageCode', 'PackageTypeName', 'PackageLevelName', 'PackageCategoryName', 'IsActive', 'CreatedOn']
        ExportExcel(dispalyname, rowcolname, packagedata, "VendorPackageMaster", "VendorPackageCreation.xls");

    }
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    return (
        <>
            <div class="container mt-4 heading_color">
                <h3>Vendor Package List </h3>
            </div>
            <div class="container form_border py-5 my-3">
                <form action="" >
                    <div class="d-flex justify-content-start ms-lg-3">
                        <button type="button" disabled={!useradd} class="btn btn-primary mx-1" to="Packagemaster" id="btnAdd" onClick={handleOpenPackage}>Create New Package</button>
                    </div>
                    <br />
                    <div class="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    //isDisabled={serchdisable ? true : false}
                                    isSearchable={false}

                                />
                            </div>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input onKeyDown={onKeyDownHandler} type="text" id="btnSearchText" class="form-control" placeholder='Enter keyword' name="SearchValue"
                                        onChange={handleChangeSearchValue}
                                        value={searchvalue}
                                    //disabled={serchdisable ? true : false} 
                                    />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div class="p-2 text-center">
                                <button type="button" class="btn btn-primary mx-1" id="btnSearch"
                                    //disabled={serchdisable ? true : false}
                                    onClick={handleClickSearchValue}
                                >Search</button>
                            </div>
                        </div>

                    </div>

                    <CustomTable
                        columns={columns}
                        data={packagedata}
                        PdfExport={PdfExport}
                        ExportToExcel={ExportToExcel}
                        disabled={!userexport}
                    />

                </form>
            </div>
        </>
    )
}

export default VendorPackagelistmaster