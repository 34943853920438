import { alertConstatnt } from "../_constants/alert.constant";

const initialValues = {
    isSuccess: false,
    isSuccessMessage: false,
    isSessionout: false,
    message: ''
}

export function alertMess(state = initialValues, actions) {
    switch (actions.type) {
        case alertConstatnt.ALERTING:
            return { isSuccess: true, message: actions.message };
        case alertConstatnt.ALERTINGMESSAGE:
            return { isSuccessMessage: true, message: actions.message };
        case alertConstatnt.SESSIONOUT:
            return { isSessionout: true, message: actions.message };
        case alertConstatnt.CLOSE:
            return { isSuccess: false, isSessionout: false, isSuccessMessage:false };
        default:
            return state;
    }
}