/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useRef } from "react";
import "../../assets/css/cmslandingpage.css";
import { useState, useEffect } from "react";
import { Route, Routes, NavLink } from "react-router-dom";
// import Tagmaster from '../admin/Tagmaster';
import Airlinemaster from "../admin/Airlinemaster";
import Airportmaster from "../admin/Airportmaster";
import Locationmaster from "../admin/Locationmaster";
import Dashboard from "../admin/Dashboard";
import Thememaster from "../admin/Thememaster";
// import Typemaster from '../admin/Typemaster';
import Suppliermaster from "./Suppliermaster";
import Supplierlistmaster from "./Supplierlistmaster";
import Bannermaster from "./Bannermaster";
import Gallerymaster from "./Gallerymaster";
import Flightmaster from "./Flightmaster";
import Hotelmaster from "./Hotelmaster";
import Inclusionmaster from "./Inclusionmaster";
import Exclusionmaster from "./Exclusionmaster";
import Policymaster from "./Policymaster";
import Sightseeingmaster from "./Sightseeingmaster";
import { getAllData, postUsersData, verifytoken } from "../../services/ApiCallingUserServices";
import Hotellistmaster from "./Hotellistmaster";
import Packagemaster from "./Packagemaster";
import Packagelistmaster from "./Packagelistmaster";
import Vehicalmaster from "./Vehicalmaster";
import { useNavigate } from "react-router-dom";
import { ClearLocalStorage, GetLocalStorage } from "../commonClass/Session";
import { useDispatch, connect } from "react-redux";
import { loaderActions } from "../../_actions/loader.actions";
import { uservalueaction } from "../../_actions/user.action";
import { Modal } from "../../_helpers/Modal";
import { Oval } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { Alert } from "../../_helpers/Alert";
import VendorPackagelistmaster from "../vendor/VendorPackagelistmaster";
// import NewVendormaster from '../vendor/NewVendormaster';
import VendorPackagemaster from "../vendor/VendorPackagemaster";
import InventoryManagement from "../admin/InventoryManagement";
import Userlist from "../admin/Userlist";
import Useradd from "./Useradd";
import Rolerights from "./Rolerights";
import Userrights from "./Userrights";
import APIconfig from "./APIconfig";
import FAQ from "./FAQ";
import {
    ClearMenuIdFromStorage,
    GetMenuIdFromStorage,
    SetMenuIdToStorage,
} from "../commonClass/Menudect";
import WelcomePage from "./WelcomePage";
import FAQCategory from "./FAQCategory";
import Accesspointmaster from "./Accesspointmaster";
import AttractionsTheme from "../master/AttractionsTheme";
import AttractionType from "../master/AttractionType";
import Department from "../admin/Department";
import AddOnmaster from "./AddOnmaster";
import AttractionsFacilities from "../master/AttractionsFacilities";
import Intracitylistmaster from './Intracitylistmaster';
import Intracitymaster from './Intracitymaster';
import BookingTransactionList from "../BookingTransaction/BookingTransactionList";
import AirInventoryList from "../GITInventoryMaster/AirInventoryList";
import AirInventoryMaster from "../GITInventoryMaster/AirInventoryMaster";
import BusInventoryList from "../GITInventoryMaster/BusInventoryList";
import BusInventoryMaster from "../GITInventoryMaster/BusInventoryMaster";
import TrainInventoryList from "../GITInventoryMaster/TrainInventoryList";
import TrainInventoryMaster from "../GITInventoryMaster/TrainInventoryMaster";
import CruiseInventoryList from "../GITInventoryMaster/CruiseInventoryList";
import CruiseInventoryMaster from "../GITInventoryMaster/CruiseInventoryMaster";
import AttractionsList from "../master/AttractionsList";
import Attractionsmaster from '../master/Attractionsmaster/Attractionsmaster'
import Tripplannerlist from "./Tripplannerlist";
import Standardservice from "./Standardservice";
import Tripplanner from "./Tripplanner";
import JobVacancy from "../Career/JobVacancy/JobVacancy";
import CreateNewJobVacancy from "../Career/JobVacancy/Partial/CreateNewJobVacancy";
import JobApplication from "../Career/JobApplication/JobApplication";
import ApplicationDetails from "../Career/JobApplication/ApplicationDetails";


const CMS = (props) => {
    const dispatch = useDispatch();
    const alertness = useSelector((store) => store.alertMess);
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const [show, setShow] = useState(0);
    const [MenuItem, setMenuItem] = useState([]);
    const [toggle, setToggle] = useState(false);

    const getMenuItem = async () => {
        const userdata = GetLocalStorage()
        let UserId = userdata?.userMasterID;
        let UserType = 0;
        const MenuItem = await postUsersData("/auth/v1/getallmenuitem", { UserId, UserType });
        setMenuItem(MenuItem.data);
    };
    const handleClick = () => {
        setMenuOpen((menuOpen) => !menuOpen);
    };
    const submenutoggle = useRef(null);

    const handleShow = (e, atr, menutype) => {
        //let l = document.getElementsByClassName('collapsed');
        if (e.currentTarget !== undefined) {
            if (menutype === 0) {
                //e.currentTarget.classList.toggle('isactive');
                e.currentTarget.classList.add("isactive");
                setShow(atr);
                setToggle(!toggle);
                scrollDown(atr);

                let element = document.getElementsByClassName("submenutoggle");
                const arr = Array.from(element);
                arr.forEach((element) => element.classList.add("collapsed"));
                let elementsub = document.getElementsByClassName("submenutoggles");
                const arr1 = Array.from(elementsub);
                arr1.forEach((element) => element.classList.add("collapse"));
            } else if (menutype === 2) {
                e.currentTarget.classList.add("isactive");
                setShow(atr);
                setToggle(!toggle);
                scrollDown(atr);

                let element = document.getElementsByClassName("submenutoggle");
                const arr = Array.from(element);
                arr.forEach((element) => element.classList.add("collapsed"));
                let elementsub = document.getElementsByClassName("submenutoggles");
                const arr1 = Array.from(elementsub);
                arr1.forEach((element) => element.classList.add("collapse"));
            } else {
                let element = document.getElementsByClassName("isactive");
                const arr = Array.from(element);
                arr.forEach((element) => element.classList.remove("isactive"));

                e.currentTarget.classList.toggle("collapsed");
                setShow(atr);
                setToggle(!toggle);
                scrollDown(atr);
            }

            // if (e.currentTarget.classList.contains('collapsed')) {
            //     e.classList.remove("collapsed");
            //   } else {
            //     e.classList.add("collapsed");
            //   }
        }
    };
    const scrollDown = (reff) => {
        const submenu = document.getElementsByClassName(reff);
        const arr = Array.from(submenu);
        arr.forEach((element) => element.classList.toggle("collapse"));
    };
    const handleLogout = () => {
        // Window.$name = '';
        // Window.$userToken = '';
        ClearLocalStorage();
        navigate("/");
    };
    const handlemenuid = (menuid) => {
        SetMenuIdToStorage(menuid);
        // dispatch({ type: 'MENUID', menuId: menuid })
    };
    const getUserById = async () => {
        const userdata = GetLocalStorage()
        let UserId = userdata?.userMasterID;
        await getAllData("/auth/v1/getuserrightsforvisibility?UserId=" + UserId).then(
            (userdata) => {
                dispatch({ type: "USERDATA", tba: userdata.data });
            }
        );
    };
    useEffect(() => {

        // getUserById();
    }, []);

    useEffect(() => {
        async function verifyTokenValue() {
            let statusoftoken = await verifytoken();
            if (!statusoftoken) {
                ClearLocalStorage();
                dispatch({
                    type: "SESSIONOUT",
                    message: "Your Session Expired. Please Login Again!",
                });
                // alert('Your Session Expired. Please Login Again!');
                // navigate('/');
                dispatch(loaderActions.end());
            }
            else {
                getUserById();
                getMenuItem();
            }
        }
        verifyTokenValue();
    }, [navigate]);

    useEffect(() => {
        if (MenuItem?.length > 0) {
            dispatch(loaderActions.end());
            let y = false;
            let dash = MenuItem?.filter((x) => {
                if (x.menuType === 0 && x.pageTitle === "Dashboard") return true;
                else return false;
            });
            MenuItem?.filter((x) => x.menuType !== 0)?.map((curr) => {
                curr.menuItemCollection?.filter((obj) => {
                    // debugger
                    // console.log('GetMenuIdFromStorage()', GetMenuIdFromStorage())
                    if (obj.pageLink === window.location.pathname) {
                        y = true;
                        return obj;
                    } else if (window.location.pathname === "/Useradd") {
                        if (obj.menuId === GetMenuIdFromStorage()) {
                            y = true;
                            return obj;
                        }
                    } else if (window.location.pathname === "/Packagedetail") {
                        if (obj.menuId === GetMenuIdFromStorage()) {
                            y = true;
                            return obj;
                        }
                    } else if (window.location.pathname === "/VendorPackagemaster") {
                        if (obj.menuId === GetMenuIdFromStorage()) {
                            y = true;
                            return obj;
                        }
                    } else if (window.location.pathname === "/Hotelmaster") {
                        if (obj.menuId === GetMenuIdFromStorage()) {
                            y = true;
                            return obj;
                        }
                    } else if (window.location.pathname === "/supplierdetail") {
                        if (obj.menuId === GetMenuIdFromStorage()) {
                            y = true;
                            return obj;
                        }
                    }
                    else if (window.location.pathname === '/Intracitymaster') {
                        if (obj.menuId === GetMenuIdFromStorage()) {
                            y = true;
                            return obj
                        }
                    }
                    else if (window.location.pathname === '/Attractionsmaster') {
                        if (obj.menuId === GetMenuIdFromStorage()) {
                            y = true;
                            return obj
                        }
                    }
                    else if (window.location.pathname === '/Businventorymaster') {
                        if (obj.menuId === GetMenuIdFromStorage()) {
                            y = true;
                            return obj
                        }
                    }
                    else if (window.location.pathname === '/CruiseInventoryMaster') {
                        if (obj.menuId === GetMenuIdFromStorage()) {
                            y = true;
                            return obj
                        }
                    }
                    else if (window.location.pathname === '/tripplanner') {
                        if (obj.menuId === GetMenuIdFromStorage()) {
                            y = true;
                            return obj
                        }
                    }
                    else if (window.location.pathname === '/NewJobVacancy') {
                        if (obj.menuId === GetMenuIdFromStorage()) {
                            y = true;
                            return obj
                        }
                    }
                    else if (window.location.pathname === '/ApplicationDetails') {
                        if (obj.menuId === GetMenuIdFromStorage()) {
                            y = true;
                            return obj
                        }
                    }
                    // else if(obj.menuId === GetMenuIdFromStorage()){
                    //     y = true;
                    //     return obj
                    // }
                });
            });
            if (y === false) {
                if (dash?.length > 0) {
                    navigate("/dashboard");
                } else {
                    navigate("/welcomepage");
                }
            }
            // console.log('cms', GetMenuIdFromStorage())

            // console.log('window.location.pathnamey', MenuItem)
        }
    }, [MenuItem]);

    return (
        <>
            <Alert
                alert={alertness.isSuccess}
                alertmessage={alertness.isSuccessMessage}
                session={alertness.isSessionout}
                message={alertness.message}
            ></Alert>

            <Modal show={props.load}>
                <div className="loader">
                    <Oval
                        height={200}
                        width={200}
                        color="#a74343"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#a74343"
                        strokeWidth={3}
                        strokeWidthSecondary={3}
                    />
                </div>
            </Modal>
            <div
                className={`sb-nav-fixed ${menuOpen ? "sb-sidenav-toggled" : "inactive"
                    }`}
            >
                <nav className="sb-topnav navbar navbar-expand">
                    <a className="navbar-brand ps-3" href="index.html"></a>
                    <button
                        className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0"
                        onClick={handleClick}
                        id="sidebarToggle"
                        href="#!"
                    >
                        <i className="fa fa-bars" aria-hidden="true"></i>
                    </button>

                    <ul className="navbar-nav ms-auto me-1 me-md-3 my-2 me-lg-3">
                        {/* <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle setc" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-user-circle-o " aria-hidden="true"></i></a>
                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" onClick={handleLogout}>Logout</a></li>
                            </ul>
                        </li> */}
                        <a
                            className="nav-link setc"
                            role="button"
                            onClick={handleLogout}
                            data-bs-toggle="tooltip"
                            data-bs-placement="left"
                            title="Logout"
                        >
                            <i className="fa fa-2x fa-sign-out" aria-hidden="true"></i>
                        </a>
                    </ul>
                </nav>
                <div id="layoutSidenav">
                    <div id="layoutSidenav_nav">
                        {/* <Menu/> */}
                        <nav
                            className="sb-sidenav accordion sb-sidenav-dark"
                            id="sidenavAccordion"
                        >
                            <div className="sb-sidenav-menu ">
                                <div className="nav border_main">
                                    {MenuItem?.map((curElem) => {
                                        if (curElem.seqNo === 0) {
                                            return (
                                                // <div className="sb-sidenav-menu-heading">{curElem.pageTitle}</div>
                                                <div className="sb-sidenav-menu-heading">
                                                    <img
                                                        className="d-flex mx-auto"
                                                        src="/assets/img/ETM-logo1.png"
                                                        alt="ETM-logo"
                                                        width="50%"
                                                        height="50px"
                                                    ></img>
                                                </div>
                                            );
                                        } else {
                                            if (curElem.menuType === 0) {
                                                return (
                                                    <NavLink
                                                        onClick={(e) =>
                                                            handleShow(e, curElem.parentId, curElem.menuType)
                                                        }
                                                        id={curElem.parentId}
                                                        className={`nav-link link_border align-items-center desh_link `}
                                                        to="/*"
                                                    >
                                                        <div className="sb-nav-link-icon">
                                                            <i
                                                                className="bi bi-speedometer2"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </div>
                                                        {curElem.pageTitle}
                                                    </NavLink>
                                                );
                                            } else if (
                                                curElem.menuItemCollection === null &&
                                                curElem.menuType === 2
                                            ) {
                                                return (
                                                    <NavLink
                                                        onClick={(e) => {
                                                            handleShow(e, curElem.parentId, curElem.menuType);
                                                        }}
                                                        className={`nav-link link_border align-items-center desh_link `}
                                                        to={curElem.pageLink}
                                                    >
                                                        <div className="sb-nav-link-icon">
                                                            <i
                                                                className="bi bi-layers"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </div>
                                                        {curElem.pageTitle}
                                                    </NavLink>
                                                );
                                            } else if (curElem.menuItemCollection === null) {
                                                return (
                                                    <NavLink
                                                        onClick={(e) => {
                                                            handleShow(e, curElem.parentId, curElem.menuType);
                                                        }}
                                                        className={`nav-link link_border master_link  ${show === curElem.parentId ? "" : "collapsed"
                                                            } `}
                                                        to="#"
                                                    >
                                                        <div className="sb-nav-link-icon">
                                                            <i className="bi bi-grid" aria-hidden="true"></i>
                                                        </div>
                                                        {curElem.pageTitle}
                                                        <div className="sb-sidenav-collapse-arrow">
                                                            <i
                                                                className="fa fa-sort-desc"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </div>
                                                    </NavLink>
                                                );
                                            } else {
                                                return (
                                                    <>
                                                        <NavLink
                                                            onClick={(e) => {
                                                                handleShow(
                                                                    e,
                                                                    curElem.parentId,
                                                                    curElem.menuType
                                                                );
                                                            }}
                                                            className={`nav-link link_border master_link collapsed submenutoggle`}
                                                            to="#"
                                                        >
                                                            <div className="sb-nav-link-icon">
                                                                <i
                                                                    className="bi bi-grid"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </div>
                                                            {curElem.pageTitle}
                                                            <div className="sb-sidenav-collapse-arrow">
                                                                <i
                                                                    className="fa fa-sort-desc"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </div>
                                                        </NavLink>

                                                        {curElem.menuItemCollection?.map((oCol) => (
                                                            <div
                                                                ref={submenutoggle}
                                                                className={`${oCol.parentId} collapse submenutoggles`}
                                                                aria-labelledby="headingOne"
                                                            >
                                                                <nav className="sb-sidenav-menu-nested nav link_border">
                                                                    <NavLink
                                                                        className="nav-link border_gbg"
                                                                        onClick={() => handlemenuid(oCol.menuId)}
                                                                        to={oCol.pageLink}
                                                                    >
                                                                        <div className="sb-nav-link-icon">
                                                                            <i
                                                                                className="bi bi-layers"
                                                                                aria-hidden="true"
                                                                            ></i>
                                                                        </div>
                                                                        {oCol.pageTitle}
                                                                    </NavLink>
                                                                </nav>
                                                            </div>
                                                        ))}
                                                    </>
                                                );
                                            }
                                        }
                                    })}
                                </div>
                            </div>
                        </nav>
                    </div>

                    <div id="layoutSidenav_content" className="div_bgcolor">
                        <div className="container-fluid h-100">
                            <Routes>
                                <Route exact path="/*" element={<Dashboard />} />
                                <Route path="/Airlinemaster" element={<Airlinemaster />} />
                                <Route path="/Airportmaster" element={<Airportmaster />} />
                                <Route path="/Locationmaster" element={<Locationmaster />} />
                                {/* <Route path="/tagmaster" element={<Tagmaster />} /> */}
                                <Route path="/thememaster" element={<Thememaster />} />
                                {/* <Route path="/typemaster" element={<Typemaster />} /> */}
                                <Route path="/Supplierlist" element={<Supplierlistmaster />} />
                                <Route path="/supplierdetail" element={<Suppliermaster />} />
                                <Route path="/Bannermaster" element={<Bannermaster />} />
                                <Route path="/Gallerymaster" element={<Gallerymaster />} />
                                <Route path="/Flightmaster" element={<Flightmaster />} />
                                <Route path="/Hotellistmaster" element={<Hotellistmaster />} />
                                <Route path="/Hotelmaster" element={<Hotelmaster />} />
                                <Route path="/Flightmaster" element={<Flightmaster />} />
                                <Route path="/Inclusionmaster" element={<Inclusionmaster />} />
                                <Route path="/Exclusionmaster" element={<Exclusionmaster />} />
                                <Route path="/Policymaster" element={<Policymaster />} />
                                <Route
                                    path="/Sightseeingmaster"
                                    element={<Sightseeingmaster />}
                                />
                                <Route path="/Packagelist" element={<Packagelistmaster />} />
                                <Route path="/Packagedetail" element={<Packagemaster />} />
                                <Route path="/Vehicalmaster" element={<Vehicalmaster />} />
                                <Route
                                    path="/VendorPackagelistmaster"
                                    element={<VendorPackagelistmaster />}
                                />
                                <Route
                                    path="/VendorPackagemaster"
                                    element={<VendorPackagemaster />}
                                />
                                <Route
                                    path="/InventoryManagement"
                                    element={<InventoryManagement />}
                                />
                                <Route path="/Userlist" element={<Userlist />} />
                                <Route path="/Useradd" element={<Useradd />} />
                                <Route path="/Rolerights" element={<Rolerights />} />
                                <Route path="/Userrights" element={<Userrights />} />
                                <Route path="/APIconfig" element={<APIconfig />} />
                                <Route path="/FAQ" element={<FAQ />} />
                                <Route path="/welcomepage" element={<WelcomePage />} />
                                <Route path="/FAQCategory" element={<FAQCategory />} />
                                <Route
                                    path="/Accesspointmaster"
                                    element={<Accesspointmaster />}
                                />
                                <Route
                                    path="/AttractionsTheme"
                                    element={<AttractionsTheme />}
                                />
                                <Route path="/AttractionType" element={<AttractionType />} />
                                <Route path="/Department" element={<Department />} />
                                <Route path="/AddOnmaster" element={<AddOnmaster />} />
                                <Route
                                    path="/AttractionsFacilities"
                                    element={<AttractionsFacilities />}
                                />
                                <Route path='/Intracitylistmaster' element={<Intracitylistmaster />} />
                                <Route path='/Intracitymaster' element={<Intracitymaster />} />
                                <Route path='/BookingTransactionList' element={<BookingTransactionList />} />
                                <Route path='/Airinventorylist' element={<AirInventoryList />} />
                                <Route path='/Airinventorymaster' element={<AirInventoryMaster />} />
                                <Route path='/Businventorylist' element={<BusInventoryList />} />
                                <Route path='/Businventorymaster' element={<BusInventoryMaster />} />
                                <Route path='/Traininventorylist' element={<TrainInventoryList />} />
                                <Route path='/Traininventorymaster' element={<TrainInventoryMaster />} />
                                <Route path='/Cruiseinventorylist' element={<CruiseInventoryList />} />
                                <Route path='/Cruiseinventorymaster' element={<CruiseInventoryMaster />} />

                                <Route path='/AttractionsList' element={<AttractionsList />} />
                                <Route path='/JobVacancy' element={<JobVacancy />} />
                                <Route path='/NewJobVacancy' element={<CreateNewJobVacancy />} />
                                <Route path='/Attractionsmaster' element={<Attractionsmaster />} />
                                <Route path='/Tripplannerlist' element={<Tripplannerlist />} />
                                <Route path='/tripplanner' element={<Tripplanner />} />
                                <Route path='/JobApplication' element={<JobApplication />} />
                                <Route path='/ApplicationDetails' element={<ApplicationDetails />} />
                                {/* {MenuItem.map((curElem) => {
                                    if (curElem.seqNo === 1 && curElem.menuId === curElem.parentId) {
                                        return (
                                            <Route exact path={curElem.pageLink} element={<curElem.otherDetails />} />
                                        )
                                    }
                                    else if (curElem.menuType === 0) {
                                        return (
                                            <Route path={curElem.pageLink} element={<curElem.otherDetails />} />
                                        )
                                    }
                                })} */}
                            </Routes>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
function mapState(state) {
    const { load } = state.loader;
    return { load };
}
const CMSC = connect(mapState)(CMS);
export default CMSC;
// export default CMS
