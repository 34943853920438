import React, { useMemo } from 'react';
import { useEffect, useState} from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from 'react-select';
import "../../assets/css/master.css"
import { getAllData} from '../../services/ApiCallingUserServices';

import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from 'react-redux';
import { errorActions } from '../../_actions/error.action';


const VendorInclusion = (props) => {
    // const submitstatus = useSelector(state=>state.errorStatus.isSubmitting);
    const errorstatus = useSelector(state=>state.errorStatus.isError);
    const inclusionstatus = useSelector(state=>state.errorStatus.isInclusion);

  
    const dispatch = useDispatch();
    const {
        handleInclusionAdd,
        handleInclusionDisable,
        retriveflag,
        inclusiondatalist,
        editableflag
    } = props

    const [inclusionlist, setInclusionList] = useState([]);
    const [inclusionvalue, setInclusion] = useState([]);
    const [inclusionId, setInclusionId] = useState(0);
    const [inclusionseqno, setInclusionSeqNo] = useState(0);
    const [datainclusiondetails, setInclusionDetails] = useState([]);
    const [inclusionduplicateerror, setInclusionDuplicateError] = useState('');
    const getInclusionList = async () => {
        await getAllData('/master/vendor/v1/vengetpackageinclusion').then((response) => {
            setInclusionList(response.data?.map((value) => ({
                label: value.InclusionName,
                value: value.InclusionId
            })));
        });
    }

    const handleinclusionchange = (e) => {
        // setInclusionDuplicateError('');
        const Inclusiontype = [
            { value: e.value, label: e.label }
        ]
        setInclusionId(e.value)
        setInclusion(Inclusiontype);
        handleInclusionDisable(false);
        dispatch(errorActions.resetallstatusflag());
    }

    const InclusionClick = () => {
        let index = datainclusiondetails?.find((obj) => obj.InclsuionId === inclusionId) || []
        if (index.InclsuionId > 0) {
            dispatch(errorActions.start())
            setInclusionDuplicateError('You can not add duplicate value')
            return;
        }
        else if (inclusionvalue.length > 0) {
            setInclusionSeqNo(inclusionseqno + 1)
            handleInclusionAdd([...datainclusiondetails, { SeqNo: inclusionseqno + 1, Inclusion: inclusionvalue[0].label, InclsuionId: inclusionvalue[0].value }])
            setInclusionDetails([...datainclusiondetails, { SeqNo: inclusionseqno + 1, Inclusion: inclusionvalue[0].label, InclsuionId: inclusionvalue[0].value }]);
            setInclusionId(-1)
            dispatch(errorActions.inclusionincrement());
            dispatch(errorActions.resetallstatusflag());
        }
        setInclusion([]);
        handleInclusionDisable(false);
        dispatch(errorActions.end())
    };
    useEffect(() => {
        getInclusionList()
    }, [])
    const validation = ()=>{
        // const inclusioncount = datainclusiondetails?.filter((obj) => obj.SeqNo > 0);
        // if (inclusioncnt > 0) {
            setInclusionDuplicateError('Please select at least one Inclusion') 
            let incllopased = document?.getElementById("InclusionError")
            if (incllopased.ariaExpanded === "false")
                document.querySelector("#InclusionError").click();
            dispatch(errorActions.start())
        // }
    }
    const inclusionremovev = (id, e) => {
        e.preventDefault();
        setInclusionDetails((oldState) => oldState?.filter((item) => item.SeqNo !== id));
        handleInclusionAdd((oldState) => oldState?.filter((item) => item.SeqNo !== id))
        setInclusionDuplicateError('');
        setInclusionId(-1);
        handleInclusionDisable(false);
       
        dispatch(errorActions.inclusiondecrement());
        dispatch(errorActions.resetallstatusflag());
    }
    useEffect(() => {
        if (retriveflag) {
            setInclusionDetails(inclusiondatalist)
            setInclusionSeqNo(inclusiondatalist.length)
        }

    }, [inclusiondatalist, retriveflag])
    useEffect(() => {
        if (!errorstatus) {
            setInclusionDuplicateError('')
        }
    }, [errorstatus])
    useEffect(()=>{
        if(inclusionstatus)
        {
            validation()
        }
    },[inclusionstatus])
    //#endregion end input time type box    
    return (
        <>
            <div class="accordion-item mb-3">
                <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                    <button class="accordion-button collapsed" type="button" id="InclusionError" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                        Inclusion
                    </button>
                </h2>
                <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                        <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                            <div class="row">
                                <div class="row">
                                    <div className='col-lg-12 col-sm-12 '>
                                        <div className='row'>
                                            {inclusionduplicateerror && <p className='text-center text-danger'>{inclusionduplicateerror}</p>}
                                            <div class="col-lg-2 col-md-3 mb-1">
                                                <label class="col-form-label required">Add Inclusion</label>
                                            </div>
                                            <div class="col-lg-4 mb-1 col-md-4">
                                                <Select
                                                    className="dropdown select_dropdown"
                                                    isDisabled={editableflag ? true : false}
                                                    name='InclusionD'
                                                    value={inclusionlist?.filter(({ value }) => value === inclusionId)}
                                                    options={inclusionlist}
                                                    onChange={handleinclusionchange}
                                                    isSearchable
                                                />
                                            </div>
                                            <div class="col-lg-4 mb-1 col-md-4">
                                                <button type="button" onClick={InclusionClick} class="btn btn-primary mx-1" disabled={editableflag ? true : false} id="btnAdd">Add</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mb-1 col-lg-12 col-md-12 mt-3'>
                                        <table id="data" className="table table-hover" >
                                            <thead>
                                                <tr>
                                                    <th>Inclusion
                                                    </th>
                                                    {/* <th className='d-none'>Inclusion Type Id
                                                                    </th> */}
                                                    <th className='text-end pe-4'>Remove
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody id="location-data">
                                                {datainclusiondetails?.map((element, index) => {
                                                    return (
                                                        <tr class="ReactTable" key={index}>
                                                            <td >{element.Inclusion}</td>
                                                            <td className='text-end'><button type="button" onClick={(e) => { inclusionremovev(element.SeqNo, e) }} class="btn btn-primary mx-1" id="btnAdd" disabled={editableflag ? true : false} >Remove</button></td>
                                                        </tr>
                                                    );

                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VendorInclusion