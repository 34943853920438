import React from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../assets/css/master.css"
import { onlyNumeric } from '../commonClass/CommonValidation';
import { useDispatch } from "react-redux";
import { errorActions } from '../../_actions/error.action';


const VendorVisibility = (props) => {
    const {
        handleVisibility,
        editableflag,
        retriveflag,
        isHotDeals,
        hotOfferPercentage,
        isBestDestination,
        isInternationalDestination,
        isTrendingHoliday,
        handlevisibilityDisable
    }=props
    const dispatch = useDispatch();
    const [formvalue,setFormValue] = useState([]);

    // {
    //     IsHotDeals:false,
    //     HotOfferPercentage:0,
    //     IsBestDestination:false,
    //     IsInternationalDestination:false,
    //     IsTrendingHoliday:false

    // }
    const onChange = (e) => {
        var result;
       if ([e.target.name][0] === 'IsHotDeals') {
            // setHotDeals(e.target.checked);
            result = e.target.checked
        }
        else if ([e.target.name][0] === 'HotOfferPercentage') {
            result = isNaN(e.target.value) ? 0 : Number(onlyNumeric(e.target.value))
            formvalue.HotOfferPercentage = result
        }
        else if ([e.target.name][0] === 'IsInternationalDestination') {
            // setIsInternationalDestination(e.target.checked);
            result = e.target.checked
        }
        else if ([e.target.name][0] === 'IsBestDestination') {
            // setIsBestDestination(e.target.checked);
            result = e.target.checked
        }
        else if ([e.target.name][0] === 'IsTrendingHoliday') {
            // setIsTrendingHoliday(e.target.checked);
            result = e.target.checked
        }
      

        setFormValue({ ...formvalue, [e.target.name]: result });
        handleVisibility({ ...formvalue, [e.target.name]: result });
        handlevisibilityDisable(false);
        dispatch(errorActions.resetallstatusflag());
    }

    useEffect(()=>{
        if(retriveflag){
            formvalue.IsHotDeals = isHotDeals
            formvalue.HotOfferPercentage = hotOfferPercentage
            formvalue.IsBestDestination=isBestDestination
            formvalue.IsInternationalDestination = isInternationalDestination
            formvalue.IsTrendingHoliday = isTrendingHoliday
        }
    },[retriveflag])
  
    return (
        <>
            <div class="accordion-item mb-3" id="accordionPanelsStayOpenExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingVisibility">
                        <button class="accordion-button collapsed border_set" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseVisibility" aria-expanded="false" aria-controls="panelsStayOpen-collapseVisibility">
                            Home page Section Visibility
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseVisibility" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingVisibility">
                        <div class="accordion-body">
                            <div className='col-lg-12 col-12 col-md-12 '>
                                <div className='row'>
                                    <div className='col-lg-6 col-sm-6 '>
                                        <div className='row'>
                                            <div class="col-lg-1 mb-1 col-md-12">
                                                <input className="form-check-input mt-2 me-2" type="checkbox" id="checkboxNoLabel"
                                                    value={formvalue.IsHotDeals} onChange={onChange} disabled={editableflag} checked={formvalue.IsHotDeals}
                                                    aria-label="..." name="IsHotDeals" />
                                            </div>
                                            <div class="col-lg-4 col-md-12 mb-1">
                                                <label class="col-form-label">Hot Deals</label>
                                            </div>
                                            <div class="col-lg-6 mb-1 col-md-12">
                                                <input type="text" id="inputPassword6" class="form-control"
                                                    name="HotOfferPercentage"
                                                    value={formvalue.HotOfferPercentage}
                                                    onChange={onChange}
                                                    disabled={editableflag}
                                                    placeholder="Enter the Offer %"
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-sm-6 '>
                                        <div className='row'>
                                            <div class="col-lg-1 mb-1 col-md-12">
                                                {/* <input class="form-check-input" type="checkbox" name ="SectionVisibility" value={formvalue.IsBestPackage} onChange={onChange} 
                                    id="flexCheckChecked" disabled={disabled ? true :false} checked={bestpackage ? true : false}/> */}
                                                <input className="form-check-input mt-2 me-2" type="checkbox" id="checkboxNoLabel"
                                                    aria-label="..." name="IsBestDestination"
                                                    value={formvalue.IsBestDestination} onChange={onChange} disabled={editableflag} checked={formvalue.IsBestDestination} />
                                            </div>
                                            <div class="col-lg-11 mb-1 col-md-12">
                                                <label class="col-form-label">Explore the Best Destinations Around the World</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-sm-6 '>
                                        <div className='row'>
                                            <div class="col-lg-1 mb-1 col-md-12">
                                                <input className="form-check-input mt-2 me-2" type="checkbox" id="checkboxNoLabel"
                                                    value={formvalue.IsInternationalDestination} onChange={onChange} disabled={editableflag} checked={formvalue.IsInternationalDestination}
                                                    aria-label="..." name="IsInternationalDestination" />
                                            </div>
                                            <div class="col-lg-11 mb-1 col-md-12">
                                                <label class="col-form-label">International Destinations</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-sm-6 '>
                                        <div className='row'>
                                            <div class="col-lg-1 mb-1 col-md-12">
                                                <input className="form-check-input mt-2 me-2" type="checkbox" id="checkboxNoLabel"
                                                    value={formvalue.IsTrendingHoliday} onChange={onChange} disabled={editableflag} checked={formvalue.IsTrendingHoliday}
                                                    aria-label="..." name="IsTrendingHoliday" />
                                            </div>
                                            <div class="col-lg-11 mb-1 col-md-12">
                                                <label class="col-form-label">Trending Holidays in India</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
        </>
    )
}

export default VendorVisibility