import React, { useRef } from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from 'react-select';
import "../../assets/css/master.css"
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../services/ApiCallingUserServices';
import Switch from 'react-switch';
import { onlyNumeric } from '../commonClass/CommonValidation';
import { ClearLocalStorage, GetLocalStorage } from '../commonClass/Session';
import { generatePDF } from '../commonClass/CommonValidation'
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../_actions/loader.actions";
import { ExportExcel } from '../commonClass/ExportExcel';
import CustomTimePicker from '../../customControl/CustomTimePicker'
import { ConfirmationModal } from '../../customControl/ConfirmationModal';
import CustomSelection from '../../customControl/CustomSelection';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../customControl/CustomTable';


const Sightseeingmaster = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [postdata, setPostData] = useState([]);
    const [citydata, setCityData] = useState([]);
    const [cityid, setCity] = useState(0);
    // const [locationId, setLocationId] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    const [activeId, setActiveId] = useState();
    // const [alllocation, setAllLocation] = useState([]);
    const [sightseeingListdata, setSightseeingListdata] = useState([]);
    const [buttonaddeditText, setButtonAddEditText] = useState("Add");
    const [disabled, setDisabled] = useState(false);
    const [searchvalue, setSearchValue] = useState();
    // const [formErrors, setFormErrors] = useState({});
    const [searchby, setSerachBy] = useState('12');
    const [vehicallisterror, setVehicalListError] = useState('');

    //const [sightseeingId, setSightseeingId] = useState(0);

    const [serchdisable, setSerachDisable] = useState(false);
    const [filteritems, setFilterItems] = useState([]);
    const [editdisabled, setEditDisabled] = useState(true);

    const [sightseeingerrorMessage, setSightseeingErrorMessage] = useState('');
    const [sightseeingPicerrorMessage, setSightseeingPicErrorMessage] = useState('');
    const [sightseeingDroperrorMessage, setSightseeingDropErrorMessage] = useState('');
    const [sightseeingHourerrorMessage, setsightseeingHourerrorMessage] = useState('');
    const [sightseeingHourlnerrorMessage, setsightseeingHourlnerrorMessage] = useState('');
    const [cityerrorMessage, setCityErrorMessage] = useState('');
    const [ItineraryerrorMessage, setItineraryErrorMessage] = useState('');
    const [vehichaltypeerrorMessage, setVehichalTypeErrorMessage] = useState('');
    const [vehicalpriceerrorMessage, setVehicalPriceErrorMessage] = useState('');
    const [sightseeingduplicateerrorMessage, setSightseeingDuplicateErrorMessage] = useState('');
    const [sightseeingVehicleDuplicateErrorMessage, setSightseeingVehicleDuplicateErrorMessage] = useState('');
    const [clrshow, setsclshow] = useState(false);
    const [allcity, setAllCity] = useState([]);
    const [citydisable, setCityDisable] = useState(false);
    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);

    const [form, setForm] = useState({
        SightseeingId: 0,
        SightseeingName: '',
        LocationId: 0,
        PickupPoint: '',
        DropPoint: '',
        Hours: '',
        HoursD: '',
        ItineraryDetails: '',
        VehicleType: '',
        VehicleTypeId: 0,
        PaxCapacityId: 0,
        PaxCapacity: '',
        Price: '',
        DataCollection: [],
        datacollectionD: []
    });
    const serachoption = [
        {
            label: 'Continent',
            value: '1'
        },
        {
            label: 'Country',
            value: '2'
        },
        {
            label: 'Region',
            value: '3'
        },
        {
            label: 'State',
            value: '4'
        },
        {
            label: 'City',
            value: '5'
        },
        {
            label: 'Sightseeing Name',
            value: '6'
        },
        {
            label: 'Pick Up Point',
            value: '7'
        },
        {
            label: 'DropPoint',
            value: '8'
        },
        {
            label: 'Hours',
            value: '9'
        },
        {
            label: 'ItineraryDetails',
            value: '10'
        },
        {
            label: 'Active',
            value: '11'
        },
        // {
        //     label: 'Created Date',
        //     value: '13'
        // },
        {
            label: 'All',
            value: '12'
        },
    ];
    const columns = [
        {
            name: 'Continent',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (sightseeing) => sightseeing.continentName
            // selector: (sightseeing) => <span data-bs-toggle="tooltip" data-bs-placement="left" title={sightseeing.continentName}>{sightseeing.continentName}</span>


        },
        {
            name: 'Country',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (sightseeing) => sightseeing.countryName
            // selector: (sightseeing) => <span data-bs-toggle="tooltip" data-bs-placement="left" title={sightseeing.countryName}>{sightseeing.countryName}</span>
        },
        {
            name: 'Region',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (sightseeing) => sightseeing.regionName
            // selector: (sightseeing) => <span data-bs-toggle="tooltip" data-bs-placement="left" title={sightseeing.regionName}>{sightseeing.regionName}</span>
        },
        {
            name: 'State',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (sightseeing) => sightseeing.stateName
            // selector: (sightseeing) => <span data-bs-toggle="tooltip" data-bs-placement="left" title={sightseeing.stateName}>{sightseeing.stateName}</span>
        },
        {
            name: 'City',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (sightseeing) => sightseeing.city
            // selector: (sightseeing) => <span data-bs-toggle="tooltip" data-bs-placement="left" title={sightseeing.city}>{sightseeing.city}</span>
        },
        {
            name: 'Sightseeing Name',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (sightseeing) => sightseeing.sightseeingName
            // selector: (sightseeing) => <span data-bs-toggle="tooltip" data-bs-placement="left" title={sightseeing.sightseeingName}>{sightseeing.sightseeingName}</span>
        },
        {
            name: 'Pick Up Point',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (sightseeing) => sightseeing.pickUpPoint
            // selector: (sightseeing) => <span data-bs-toggle="tooltip" data-bs-placement="left" title={sightseeing.pickUpPoint}>{sightseeing.pickUpPoint}</span>
        },
        {
            name: 'Drop Point',
            sortable: true,
            float: "left",
            selector: (sightseeing) => sightseeing.dropPoint
            // selector: (sightseeing) => <span data-bs-toggle="tooltip" data-bs-placement="left" title={sightseeing.dropPoint}>{sightseeing.dropPoint}</span>
        },
        {
            name: 'Hours',
            sortable: true,
            float: "left",
            selector: (sightseeing) => formatTime(sightseeing.hours),
            // selector: (sightseeing) => <span data-bs-toggle="tooltip" data-bs-placement="left" title={sightseeing.hours}>{formatTime(sightseeing.hours)}</span>

        },
        {
            name: 'Itinerary Details',
            sortable: true,
            float: "left",
            selector: (sightseeing) => sightseeing.itineraryDetails
            // selector: (sightseeing) => <span data-bs-toggle="tooltip" data-bs-placement="left" title={sightseeing.itineraryDetails}>{sightseeing.itineraryDetails}</span>

        },
        {
            name: 'Hatchback',
            sortable: true,
            float: "left",
            selector: (sightseeing) => sightseeing.hatchback
            // selector: (sightseeing) => <span data-bs-toggle="tooltip" data-bs-placement="left" title={sightseeing.hatchback}>{sightseeing.hatchback}</span>

        },
        {
            name: 'Sedan',
            sortable: true,
            float: "left",
            selector: (sightseeing) => sightseeing.sedan
            // selector: (sightseeing) => <span data-bs-toggle="tooltip" data-bs-placement="left" title={sightseeing.sedan}>{sightseeing.sedan}</span>

        },
        {
            name: 'Suv',
            sortable: true,
            float: "left",
            selector: (sightseeing) => sightseeing.suv
            // selector: (sightseeing) => <span data-bs-toggle="tooltip" data-bs-placement="left" title={sightseeing.suv}>{sightseeing.suv}</span>

        },
        {
            name: 'Muv',
            sortable: true,
            float: "left",
            selector: (sightseeing) => sightseeing.muv
            // selector: (sightseeing) => <span data-bs-toggle="tooltip" data-bs-placement="left" title={sightseeing.muv}>{sightseeing.muv}</span>

        },
        {
            name: 'Tempo',
            sortable: true,
            float: "left",
            selector: (sightseeing) => sightseeing.tempo
            // selector: (sightseeing) => <span data-bs-toggle="tooltip" data-bs-placement="left" title={sightseeing.tempo}>{sightseeing.tempo}</span>

        },
        {
            name: 'Semi Sleeper Volvo',
            sortable: true,
            float: "left",
            selector: (sightseeing) => sightseeing.semiSleeperVolvo
            // selector: (sightseeing) => <span data-bs-toggle="tooltip" data-bs-placement="left" title={sightseeing.semiSleeperVolvo}>{sightseeing.semiSleeperVolvo}</span>

        },
        {
            name: 'Active',
            sortable: true,
            float: "left",
            selector: (sightseeing) => sightseeing.isActive ? 'Active' : 'InActive'
        },
        {
            name: 'Created Date',
            sortable: true,
            float: "left",
            selector: (sightseeing) => formatDate(sightseeing.createdDate)
            // selector: (sightseeing) => <span data-bs-toggle="tooltip" data-bs-placement="left" title={sightseeing.semiSleeperVolvo}>{sightseeing.semiSleeperVolvo}</span>

        },
        {
            name: 'Edit',
            // float:"right",
            selector: (sightseeing) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEditMode(sightseeing.sightseeingId)}></i></button>
        },
        {
            name: 'View',
            // float:"right",
            selector: (sightseeing) => <i className="fa fa-eye" onClick={() => handleViewMode(sightseeing.sightseeingId)}></i>
        }
    ];
    const getSightseeingDataList = async () => {
        const sightseeingListdata = await getAllData("/master/v1/getallsightseeing");
        setSightseeingListdata(sightseeingListdata.data);

    }
    const getCityData = async () => {
        await getAllData('/master/v1/getsightseeingcity').then((cityData) => {
            getSightseeingDataList();
            setCityData(cityData.data?.filter(IsAct => IsAct.isActive === true));
            setAllCity(cityData.data);
            dispatch(loaderActions.end());
        });

    }
    const getVehichalDataList = async () => {
        const vehichallist = await getAllData('/master/v1/getvehicletype');
        setVehicalData(vehichallist.data)
    }
    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            //if (Window.$name && Window.$userToken) {
            setCity();
            getCityData();
            getVehichalDataList();
            // getSightseeingDataList().then(() => { dispatch(loaderActions.end()) });;
            setitems();
        }
        else {
            ClearLocalStorage();
            // alert('Your Session Expired. Please Login Again!');
            // navigate('/');
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, []);
    useEffect(() => {
        // let MENUID = GetMenuIdFromStorage()
        // let viewrights = false;
        // user?.filter(x => x.MenuId === MENUID).map((obj) => {
        //     viewrights = obj.URightView;
        //     setUserAdd(obj.URightAdd);
        //     setUserEdit(obj.URightEdit);
        //     setUserExport(obj.URightExport);
        //     if (!viewrights) {
        //         navigate('/dashboard');
        //     }
        // });
        // let MENUID = GetMenuIdFromStorage()
        // let mid = user?.find(x => x.MenuId === MENUID);
        let pagelink = user?.find(x => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);

        });

    }, [user])

    useEffect(() => {


    }, [sightseeingPicerrorMessage, vehicallisterror, vehicalpriceerrorMessage, vehichaltypeerrorMessage, sightseeingerrorMessage, sightseeingDroperrorMessage, cityerrorMessage, ItineraryerrorMessage, sightseeingHourerrorMessage, sightseeingHourlnerrorMessage]);
    // useEffect(() => {
    // }, [buttonaddeditText]);
    useEffect(() => {

    }, [postdata]);
    const cityList = citydata?.map(value => ({
        label: value.city,
        value: value.locationId
    }));

    const handlecity = (e) => {
        setItineraryErrorMessage('');
        setSightseeingDropErrorMessage('');
        setSightseeingPicErrorMessage('');
        setsightseeingHourerrorMessage('');
        setsightseeingHourlnerrorMessage('');
        setSightseeingErrorMessage('');
        setSightseeingVehicleDuplicateErrorMessage('');
        setSightseeingDuplicateErrorMessage('');
        setVehicalPriceErrorMessage('');
        setVehicalListError('');
        setVehichalTypeErrorMessage('');
        setCityErrorMessage('');
        form.LocationId = e.value
        //getalllocationbyCity(e.value);
        setCity(e.value);
        setEditDisabled(true);

    }
    // const getalllocationbyCity = async (locationId) => {
    //     const locid = { LocationId: locationId }
    //     const AllLocationData = await postUsersData('/general/v1/getsightseeingcity', locid)
    //     setAllLocation(AllLocationData.data);
    // }
    const continent = citydata?.map(value => ({
        label: value.continentName,
        value: value.locationId
    })
    );

    const country = citydata?.map(value => ({
        label: value.countryName,
        value: value.locationId
    })
    );

    const region = citydata?.map(value => ({
        label: value.regionName,
        value: value.locationId
    })
    );

    const state = citydata?.map(value => ({
        label: value.stateName,
        value: value.locationId
    })
    );
    const onChange = (e) => {
        setItineraryErrorMessage('');
        setVehichalTypeErrorMessage('');
        setVehicalPriceErrorMessage('');
        setVehicalListError('');
        setSightseeingErrorMessage('');
        setSightseeingVehicleDuplicateErrorMessage('');
        setSightseeingDuplicateErrorMessage('');
        setCityErrorMessage('');
        setSightseeingDropErrorMessage('');
        setSightseeingPicErrorMessage('');
        setsightseeingHourerrorMessage('');
        setsightseeingHourlnerrorMessage('');

        // if([e.target.name][0] === 'HoursD')
        // {
        //     //result = formvalue.DepartureDate + "T" + e.target.value
        //     {form.Hours = "2022-11-11T" + e.target.value + ":00:00"}
        // }
        var result;
        if ([e.target.name][0] === 'Price') {
            result = onlyNumeric(e.target.value)
        }
        else {
            result = e.target.value;
        }
        setForm({ ...form, [e.target.name]: result });
        // setForm({ ...form, [e.target.name]:e.target.value });


        setEditDisabled(true);
    }
    // const handleisActive = (e) => {
    //     setItineraryErrorMessage('');
    //     setSightseeingDropErrorMessage('');
    //     setVehichalTypeErrorMessage('');
    //     setVehicalPriceErrorMessage('');
    //     setVehicalListError('');
    //     setSightseeingErrorMessage('');
    //     setSightseeingVehicleDuplicateErrorMessage('');
    //     setSightseeingDuplicateErrorMessage('');
    //     setSightseeingPicErrorMessage('');
    //     setsightseeingHourerrorMessage('');
    //     setsightseeingHourlnerrorMessage('');
    //     setCityErrorMessage('');
    //     { form.IsActive = e }
    //     setActiveId(!activeId)
    //     setEditDisabled(true);
    // }
    const handleisActive = (e) => {
        if (buttonaddeditText === 'Add') {
            form.IsActive = e
            setActiveId(!activeId)
            setItineraryErrorMessage('');
            setSightseeingDropErrorMessage('');
            setVehichalTypeErrorMessage('');
            setVehicalPriceErrorMessage('');
            setVehicalListError('');
            setSightseeingErrorMessage('');
            setSightseeingVehicleDuplicateErrorMessage('');
            setSightseeingDuplicateErrorMessage('');
            setSightseeingPicErrorMessage('');
            setsightseeingHourerrorMessage('');
            setsightseeingHourlnerrorMessage('');
            setCityErrorMessage('');
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (e) => {
        //setYesNoConfirm(e)
        if (e === true) {
            setItineraryErrorMessage('');
            setSightseeingDropErrorMessage('');
            setVehichalTypeErrorMessage('');
            setVehicalPriceErrorMessage('');
            setVehicalListError('');
            setSightseeingErrorMessage('');
            setSightseeingVehicleDuplicateErrorMessage('');
            setSightseeingDuplicateErrorMessage('');
            setSightseeingPicErrorMessage('');
            setsightseeingHourerrorMessage('');
            setsightseeingHourlnerrorMessage('');
            setCityErrorMessage('');
            form.IsActive = !activeId
            setActiveId(!activeId)
            setEditDisabled(true);
        }
        setModalShow(false);
    }

    const CityError = useRef(null);
    const VehicleTypeD = useRef(null);
    const validateData = () => {
        let flag = true;
        let error = [];
        if (form.LocationId <= '0') {
            setCityErrorMessage('Please select city.')
            error.push("CityError");
            flag = false;
        }
        if (typeof form.SightseeingName === 'string' && form.SightseeingName?.trim()?.length === 0) {
            setSightseeingErrorMessage('Please enter Sightseeing Name');
            error.push('SightseeingName');
            flag = false;
        }
        if (typeof form.SightseeingName === 'string' && form.SightseeingName?.trim()?.length > 5000) {
            setSightseeingErrorMessage('Maximum length allowed for Sightseeing Name is 5000 characters.');
            error.push('SightseeingName');
            flag = false;
        }

        if (typeof form.PickupPoint === 'string' && form.PickupPoint?.trim()?.length === 0) {
            setSightseeingPicErrorMessage('Please enter PickUp Point');
            error.push('PickupPoint');
            flag = false;
        }
        if (typeof form.PickupPoint === 'string' && form.PickupPoint?.trim()?.length > 50) {
            setSightseeingPicErrorMessage('Maximum length allowed for Pickup Point is 50 characters.');
            error.push('PickupPoint');
            flag = false;
        }

        if (typeof form.DropPoint === 'string' && form.DropPoint?.trim()?.length === 0) {
            setSightseeingDropErrorMessage('Please enter Drop Point');
            error.push('DropPoint');
            flag = false;
        }

        if (typeof form.DropPoint === 'string' && form.DropPoint?.trim()?.length > 50) {
            setSightseeingDropErrorMessage('Maximum length allowed for Drop Point is 50 characters.');
            error.push('DropPoint');
            flag = false;
        }
        if (form.HoursD === '' || form.HoursD === "--:--") {
            setsightseeingHourerrorMessage('Please enter Hours');
            error.push('Hour')
            flag = false;
        }
        if (valuepass(form.HoursD)) {
            setsightseeingHourerrorMessage('Please enter correct hours');
            error.push('Hour')
            flag = false;
        }
        // if (typeof form.Hours === 'string' && form.Hours?.trim()?.length > 20) {
        //     setsightseeingHourerrorMessage('Maximum 20 characters can be entered');
        //     flag = false;
        // }
        // if (typeof form.Price === 'string' && form.Price.trim().length === 0) {
        //     setVehicalPriceErrorMessage('Please enter Price');
        //     flag = false;
        // }
        if (typeof form.ItineraryDetails === 'string' && form.ItineraryDetails?.trim()?.length === 0) {
            setItineraryErrorMessage('Please enter Itinerary Details');
            error.push('ItineraryDetails')
            flag = false;
        }
        if (typeof form.ItineraryDetails === 'string' && form.ItineraryDetails?.trim()?.length > 5000) {
            setItineraryErrorMessage('Maximum length allowed for Itinerary Details is 5000 characters.');
            error.push('ItineraryDetails')
            flag = false;
        }
        // if (typeof form.ItineraryDetails === 'string' && form.ItineraryDetails.trim().length > 250) {
        //     setItineraryErrorMessage('Maximum length allowed for Itinerary Details is 250 characters.');
        //     flag = false;
        // }
        // if (form.VehicleTypeId <= '0') {
        //     setVehichalTypeErrorMessage('Please select Vehicle Type.')
        //     flag = false;
        // }
        // const collectionlen = datavehicledetails?.length || 0;
        const collectionlen = datavehicledetails?.filter(obj => {
            return obj.SeqNo > 0;
        });
        if (collectionlen.length === 0) {
            setVehicalListError('Please enter at least one vehicle type detail');
            error.push("VehicalList")
            flag = false;
        }
        //----------------- set focus on first field of error--------------------------
        if (error[0] === "CityError") {
            eval(error[0]).current.focus();
        }
        else if (error[0] === "Hours") {
            const clickpass = document.getElementsByClassName("inputWrapper")[0].childNodes[0];
            clickpass.focus();
        }
        else if (error[0] === "VehicalList") {
            if (vehicleId === 0) {
                VehicleTypeD.current.focus();
            }
            else {
                let doc = document.getElementById("Price");
                if (doc !== null)
                    doc.focus();
            }
        }
        else {
            // document.getElementById(error[0]).focus();
            let doc = document.getElementById(error[0]);
            if (doc !== null)
                doc.focus();
        }

        return flag;
    }

    const handleAddClick = async () => {
        let statusoftoken = await verifytoken();
        form.DataCollection = datavehicledetails?.map(value => ({
            VehicleId: Number(value.VehicleTypeId),
            Price: Number(value.Price)
        }));

        if (buttonaddeditText === 'Add') {
            if (statusoftoken) {
                if (datavehicledetailscounter === 0 || form.SightseeingName?.trim()?.length === 0 || form.SightseeingName?.trim()?.length > 50 || form.Price?.trim()?.length === 0 || form.PickupPoint.trim().length === 0 || form.PickupPoint.trim().length > 50 || form.DropPoint.trim().length === 0 || form.DropPoint?.trim()?.length > 50 || form.ItineraryDetails?.trim()?.length === 0 || form.ItineraryDetails.trim().length > 250 || form.Hours?.trim()?.length === 0 || form.Hours?.trim()?.length > 20 || form.LocationId <= '0' || form.VehicleTypeId <= '0') {
                    if (!validateData()) {
                        return;
                    }
                    else {
                        if ((form.SightseeingName?.trim()?.length === 0) && (form.PickupPoint?.trim()?.length === 0) && (form.DropPoint?.trim()?.length === 0) && (form.ItineraryDetails?.trim()?.length === 0) && (form.Hours?.trim()?.length === 0) && (form.LocationId <= '0')) {
                            let filterdata;
                            filterdata = sightseeingListdata?.filter(issec => issec.sightseeingName.toLowerCase() === form.SightseeingName.toLowerCase() && issec.pickUpPoint.toLowerCase() === form.PickupPoint.toLowerCase() && issec.dropPoint.toLowerCase() === form.DropPoint.toLowerCase() && issec.itineraryDetails.toLowerCase() === form.ItineraryDetails.toLowerCase() && issec.locationId === form.LocationId);

                            if (filterdata.length > 0) {
                                setSightseeingDuplicateErrorMessage('You can not enter duplicate value.');
                                return;
                            }
                        }
                        if (statusoftoken) {
                            const SightseeingData = await postUsersData("/master/v1/insertsightseeing", form);
                            setPostData(SightseeingData.data);
                            if (SightseeingData.status === undefined && SightseeingData.data === undefined) {
                                dispatch({ type: 'ALERTING', message: SightseeingData.message })
                                // alert('Something went wrong!')
                                return;
                            }

                            if (!SightseeingData.status && !SightseeingData.data) {
                                setSightseeingDuplicateErrorMessage(SightseeingData.message);
                                return;
                            }
                            if (SightseeingData.status) {
                                if (SightseeingData.data > 0) {
                                    dispatch({ type: 'ALERTING', message: SightseeingData.message })
                                    // alert('Sightseeing master Record saved successfully');
                                    getSightseeingDataList();
                                    clear();
                                }
                                else {
                                    dispatch({ type: 'ALERTING', message: SightseeingData.message })
                                    // alert('Sightseeing master Record not saved successfully')
                                    return;
                                }
                            }
                            else {
                                dispatch({ type: 'ALERTING', message: SightseeingData.message })
                                // alert('Something went wrong!')
                                return;
                            }
                        }
                        else {
                            ClearLocalStorage();
                            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                        }
                    }
                    // return;
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }

        }
        else {
            if (statusoftoken) {
                if (!validateData()) {
                    return;
                }
                else {
                    if ((form.SightseeingName?.trim()?.length === 0) && (form.PickupPoint?.trim()?.length === 0) && (form.DropPoint?.trim()?.length === 0) && (form.ItineraryDetails?.trim()?.length === 0)(form.Hours?.trim()?.length === 0)(form.LocationId <= '0')) {
                        let filterdata;
                        filterdata = sightseeingListdata?.filter(issec => issec.sightseeingName.toLowerCase() === form.SightseeingName.toLowerCase() && issec.pickUpPoint.toLowerCase() === form.PickupPoint.toLowerCase() && issec.dropPoint.toLowerCase() === form.DropPoint.toLowerCase() && issec.itineraryDetails.toLowerCase() === form.ItineraryDetails.toLowerCase() && issec.locationId === form.LocationId);

                        if (filterdata?.length > 0) {
                            setSightseeingDuplicateErrorMessage('You can not enter duplicate value.');
                            return;
                        }
                    }
                    if (statusoftoken) {
                        const SightseeingData = await patchUsersData("/master/v1/updatesightseeing", form);
                        setPostData(SightseeingData.data);
                        if (SightseeingData.status === undefined && SightseeingData.data === undefined) {
                            dispatch({ type: 'ALERTING', message: SightseeingData.message })
                            // alert('Something went wrong!')
                            return;
                        }
                        if (!SightseeingData.status && !SightseeingData.data) {
                            setSightseeingDuplicateErrorMessage(SightseeingData.message);
                            return;
                        }
                        if (SightseeingData.status) {
                            dispatch({ type: 'ALERTING', message: SightseeingData.message })
                            // alert('Sightseeing master Record updated successfully');
                            getSightseeingDataList();
                            clear();
                        }
                        else {
                            dispatch({ type: 'ALERTING', message: SightseeingData.message })
                            // alert('Something went wrong!')
                            return;
                        }
                    }
                    else {
                        ClearLocalStorage();
                        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                    }
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }

        };

    };
    const validateDataV = () => {
        let flag = true;
        if (typeof form.Price === 'string' && form.Price?.trim()?.length === 0) {
            setVehicalPriceErrorMessage('Please enter Price');
            flag = false;
        }
        if (form.VehicleTypeId <= '0') {
            setVehichalTypeErrorMessage('Please select Vehicle Type.');
        }
        return flag;
    }
    const VehicleDetails = useState([{
        VehicleType: '',
        VehicleTypeId: 0,
        PaxCapacity: '',
        PaxCapacityId: 0,
        Price: '',
        SeqNo: 0
    }]);
    const [datavehicledetails, setVehicleDetails] = useState(VehicleDetails);
    const [vehicleId, setVehical] = useState(0);
    const [vehichaldata, setVehicalData] = useState([]);
    const [datavehicledetailscounter, setVehicleDetailsCounter] = useState(0);
    const VehicleClick = () => {
        const numVehiclecount = datavehicledetails?.filter(obj => obj.VehicleTypeId === form.VehicleTypeId);

        // const checkvalue = /[a-z]/g;

        // const count = form.Price?.trim().match(checkvalue)
        // alert(count)
        if (numVehiclecount?.length > 0) {
            setSightseeingVehicleDuplicateErrorMessage('You can not enter duplicate Vehicle')
            return;
        }
        else if (form.Price?.trim()?.length > 0 && isNaN(form.Price)) {
            setVehicalPriceErrorMessage('Only Numbers are allowed in this field.');
            return;
        }
        else if (form.Price?.trim()?.length === 0 || form.VehicleTypeId <= '0') {
            if (!validateDataV())
                return;

        }
        else {
            setVehicleDetailsCounter(datavehicledetailscounter + 1);
            setVehicalListError('');
            setSightseeingDuplicateErrorMessage('');
            setSightseeingVehicleDuplicateErrorMessage('');
            setVehicalPriceErrorMessage('');
        }

    };
    function AddDataRowForVehicleDetails() {
        let priced = form.Price
        if (buttonaddeditText === 'Add') {
            if (datavehicledetailscounter === 1) {
                //let priced = form.Price
                setVehicleDetails(current => [{ SeqNo: datavehicledetailscounter, VehicleType: form.VehicleType, VehicleTypeId: form.VehicleTypeId, PaxCapacity: form.PaxCapacity, PaxCapacityId: form.PaxCapacityId, Price: priced }]);
            }
            else {
                setVehicleDetails(current => [...current, { SeqNo: datavehicledetailscounter, VehicleType: form.VehicleType, VehicleTypeId: form.VehicleTypeId, PaxCapacity: form.PaxCapacity, PaxCapacityId: form.PaxCapacityId, Price: priced }]);
            }
        }
        else {
            // let priced = form.Price
            let cnt = datavehicledetails?.length || 0;
            if (vehicleId > 0) {
                if (cnt === 0) {
                    setVehicleDetails(current => [{ SeqNo: datavehicledetailscounter, VehicleType: form.VehicleType, VehicleTypeId: form.VehicleTypeId, PaxCapacity: form.PaxCapacity, PaxCapacityId: form.PaxCapacityId, Price: priced }]);
                }
                else {
                    setVehicleDetails(current => [...current, { SeqNo: datavehicledetailscounter, VehicleType: form.VehicleType, VehicleTypeId: form.VehicleTypeId, PaxCapacity: form.PaxCapacity, PaxCapacityId: form.PaxCapacityId, Price: priced }]);
                }
            }

        }
    }
    useEffect(() => {
        if (datavehicledetailscounter > 0)
            AddDataRowForVehicleDetails();

    }, [datavehicledetailscounter]);
    useEffect(() => {
        setForm({ ...form, VehicleTypeId: '', PaxCapacityId: '', Price: '' });
        setVehical(0);
    }, [datavehicledetails]);

    const vehicleremovev = (id) => {
        setEditDisabled(true);
        //setForm({ ...form,  ['VehicleTypeId']: 0,})
        setVehicleDetails((oldState) => oldState?.filter((item) => item.SeqNo !== id));
    }

    // const onChange = (e) => {
    //     var result = e.target.value;
    //     setForm({ ...form, [e.target.name]: result });
    // }

    const vehichallist = vehichaldata?.map(value => ({
        label: value.vehicleType,
        value: value.vehicleId
    }))
    const paxCapacityD = vehichaldata?.map(value => ({
        label: value.paxCapacity,
        value: value.vehicleId
    }))
    const handleVehicalchange = (e) => {
        setItineraryErrorMessage('');
        setSightseeingDropErrorMessage('');
        setVehichalTypeErrorMessage('');
        setVehicalPriceErrorMessage('');
        setVehicalListError('');
        setSightseeingErrorMessage('');
        setCityErrorMessage('');
        setSightseeingVehicleDuplicateErrorMessage('');
        setSightseeingDuplicateErrorMessage('');
        setSightseeingPicErrorMessage('');
        setsightseeingHourerrorMessage('');
        setsightseeingHourlnerrorMessage('');
        form.VehicleTypeId = e.value
        form.VehicleType = e.label
        const pax = paxCapacityD?.find(element => element.value === e.value);
        form.PaxCapacity = pax.label
        setVehical(e.value);
        //setVehicalType(current => [...current , {vehichaltype}]);
        //vehicaltypevalue
    }


    const handleEditMode = (id) => {
        setDisabled(false);
        setEditDisabled(false);
        setSerachDisable(true);
        setButtonAddEditText("Update");
        RetriveData(id);
    }

    const handleViewMode = (id) => {
        setDisabled(true);
        setEditDisabled(false);
        setSerachDisable(true);
        setButtonAddEditText("Update");
        RetriveData(id);
    }
    function RetriveData(id) {
        setItineraryErrorMessage('');
        setVehichalTypeErrorMessage('');
        setVehicalPriceErrorMessage('');
        setVehicalListError('');
        setSightseeingErrorMessage('');
        setSightseeingVehicleDuplicateErrorMessage('');
        setCityErrorMessage('');
        setSightseeingDropErrorMessage('');
        setSightseeingPicErrorMessage('');
        setSightseeingDuplicateErrorMessage('');
        setsightseeingHourerrorMessage('');
        setsightseeingHourlnerrorMessage('');
        sightseeingListdata.filter(objid => objid.sightseeingId === id)?.map(obj => {
            setForm({
                ...form,
                SightseeingId: obj.sightseeingId,
                SightseeingName: obj.sightseeingName,
                LocationId: obj.locationId,
                PickupPoint: obj.pickUpPoint,
                DropPoint: obj.dropPoint,
                // form.Hours = formatTime(obj.hours)
                Hours: new Date(obj.hours),
                HoursD: formatTime(obj.hours),
                // { form.HoursD = obj.hours }
                ItineraryDetails: obj.itineraryDetails,
                IsActive: obj.isActive,
                VehicleTypeId: obj.vehicleTypeId,
                datacollectionD: obj.dataCollection,
                // Check In-Active Data and add in Dropdown...

            })
            let allcitylist = allcity?.filter(IsAct => IsAct.isActive === true);
            setCityData(allcity?.filter(IsAct => IsAct.isActive === true));
            setCityDisable(false);
            if (!allcitylist?.find(IsCity => IsCity.locationId === obj.locationId)) {
                setCityDisable(true)
                setCityData((prvCityData) => prvCityData?.length > 0 ? [...prvCityData, allcity?.find(isCity => isCity.locationId === obj.locationId)] : [])

            }
            setCity(obj.locationId);
            //getalllocationbyCity(obj.locationId);
            // setLocationId(obj.locationId);
            setActiveId(obj.isActive);
            setHoursTime(formatTime(obj.hours))

            const vehichallist = obj.dataCollection?.map((obj, index) => ({
                VehicleType: obj.vehicleType,
                VehicleTypeId: obj.vehicleId,
                PaxCapacity: obj.paxCapacity,
                PaxCapacityId: obj.vehicleId,
                Price: obj.price,
                SeqNo: index + 1
            })) || [];
            setVehicleDetails(vehichallist);
            setVehicleDetailsCounter(vehichallist?.length);
        });

    }
    // function formatTime(date){
    //     var d = new Date(date),
    //     // month = '' + (d.getMonth() + 1),
    //     // day = '' + d.getDate(),
    //     // year = d.getFullYear();
    //     hour = d.getHours(),
    //     minu = d.getMinutes();


    //     // if (month.length < 2) 
    //     // month = '0' + month;
    //     // if (day.length < 2) 
    //     // day = '0' + day;

    //     return [hour].join(':');
    // }

    const handleClearClick = () => {
        clear();
    }
    function clear() {
        setItineraryErrorMessage('');
        setVehichalTypeErrorMessage('');
        setVehicalPriceErrorMessage('');
        setVehicalListError('');
        setSightseeingErrorMessage('');
        setSightseeingVehicleDuplicateErrorMessage('');
        setCityErrorMessage('');
        setSightseeingDropErrorMessage('');
        setSightseeingPicErrorMessage('');
        setSightseeingDuplicateErrorMessage('');
        setsightseeingHourerrorMessage('');
        setsightseeingHourlnerrorMessage('');
        // setIsSubmit(false);
        setVehical(0);
        setForm({
            ...form, SightseeingName: '', PickupPoint: '', DropPoint: '', Hours: '', HoursD: '--:--',
            ItineraryDetails: '', IsActive: false, LocationId: 0, Price: '', VehicleTypeId: 0, PaxCapacityId: 0, DataCollection: [],
            datacollectionD: []
        });
        // setSightseeingListdata([])
        getSightseeingDataList();
        setVehicleDetails([]);
        setVehicleDetailsCounter(0);
        setActiveId(false);
        setDisabled(false);
        setEditDisabled(true);
        setSerachDisable(false);
        setSerachBy('12');
        setSearchValue('');
        setCity(0);
        setButtonAddEditText('Add');
        setActiveId(0);
        getCityData();
        getVehichalDataList();
        setEditDisabled(true);
        setHoursTime("--:--");
        setsclshow(false);
        setCityDisable(false);
    }
    const setitems = async () => {
        const sightseeingListdata = await getAllData("/master/v1/getallsightseeing");
        setFilterItems(sightseeingListdata.data);
    }

    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }
    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            getSightseeingDataList();
            setsclshow(false)
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleClickSearchValue();
        }
    };

    const handleClickSearchValue = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            setitems();
            var filteritem = filteritems;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec.continentName?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec.countryName?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '3') {
                filterdata = filteritem?.filter(issec => issec.regionName?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '4') {
                filterdata = filteritem?.filter(issec => issec.stateName?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '5') {
                filterdata = filteritem?.filter(issec => issec.city?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '6') {
                filterdata = filteritem?.filter(issec => issec.sightseeingName?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '7') {
                filterdata = filteritem?.filter(issec => issec.pickUpPoint?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '8') {
                filterdata = filteritem?.filter(issec => issec.dropPoint?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '9') {
                filterdata = filteritem?.filter(issec => issec.hours?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '10') {
                filterdata = filteritem?.filter(issec => issec.itineraryDetails?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '13') {
                filterdata = filteritem?.filter(issec => formatDate(issec.createdDate)?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '11') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.isActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.isActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.isActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => formatDate(issec.createdDate)?.toLowerCase().includes("%#$&%^"));
                }
            }
            else if (searchby === '12') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {

                    filterdata = filteritem?.filter(issec => issec.continentName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.countryName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.regionName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.sightseeingName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.pickUpPoint?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.dropPoint?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.hours?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.itineraryDetails?.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate)?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {

                    filterdata = filteritem?.filter(issec => issec.continentName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.countryName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.regionName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.sightseeingName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.pickUpPoint?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.dropPoint?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.hours?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.itineraryDetails?.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate)?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.continentName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.countryName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.regionName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.sightseeingName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.pickUpPoint?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.dropPoint?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.hours?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.itineraryDetails?.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate)?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {

                    filterdata = filteritem?.filter(issec => issec.continentName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.countryName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.regionName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.sightseeingName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.pickUpPoint?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.dropPoint?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.hours?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.itineraryDetails?.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate)?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => issec.continentName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.countryName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.regionName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.sightseeingName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.pickUpPoint?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.dropPoint?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.hours?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.itineraryDetails?.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate)?.toLowerCase().includes(searchvalue.toLowerCase()) || 'active'.toLowerCase().includes(searchvalue) || 'inactive'.toLowerCase().includes(searchvalue));
                }

            }
            setSightseeingListdata(filterdata)
        }


    }
    function PdfExport() {
        const columns = [
            "Continent",
            "Country",
            "Region",
            "State",
            "City",
            "SightseeingName",
            "PickUpPoint",
            "DropPoint",
            "Hours",
            "ItineraryDetails",
            "Hatchback",
            "Sedan",
            "Suv",
            "Muv",
            "Tempo",
            "Semi Sleeper Volvo",
            "Active",
            "Created Date"
        ];
        var rows = [];

        for (let i = 0; i < sightseeingListdata?.length; i++) {
            var temp = [
                sightseeingListdata[i].continentName,
                sightseeingListdata[i].countryName,
                sightseeingListdata[i].regionName,
                sightseeingListdata[i].stateName,
                sightseeingListdata[i].city,
                sightseeingListdata[i].sightseeingName,
                sightseeingListdata[i].pickUpPoint,
                sightseeingListdata[i].dropPoint,
                formatTime(sightseeingListdata[i].hours),
                sightseeingListdata[i].itineraryDetails,
                sightseeingListdata[i].hatchback,
                sightseeingListdata[i].sedan,
                sightseeingListdata[i].suv,
                sightseeingListdata[i].muv,
                sightseeingListdata[i].tempo,
                sightseeingListdata[i].semiSleeperVolvo,
                `${sightseeingListdata[i].isActive ? 'Active' : 'InActive'}`,
                formatDate(sightseeingListdata[i].createdDate)
            ];
            rows = [...rows, temp]
        }
        generatePDF(columns, rows, "SightseeingMaster", "Sightseeing Master", "l")
    }
    // function ExportToExcel() {
    //     const fields = ['continentName', 'countryName', 'regionName', 'stateName', 'city', 'sightseeingName', 'pickUpPoint', 'dropPoint', 'hours', 'itinerarayDetails', 'hatchback', 'sedan', 'suv', 'muv', 'tempo', 'isActive'];
    //     const data = sightseeingListdata;
    //     const fileName = 'Sightseeingmasterexcel'
    //     const exportType = 'xls'

    //     exportFromJSON({ data, fields, fileName, exportType })
    // }
    const ExportToExcel = () => {
        const dispalyname = ['Continent', 'Country', 'Region', 'State', 'City', 'Sightseeing Name', 'Pick Up Point', 'Drop Point', 'Hours', 'ItineraryDetails', 'Hatchback', 'Sedan', 'Suv', 'Muv', 'Tempo', "Semi Sleeper Volvo", 'Active', "Created Date"];
        const fields = ['continentName', 'countryName', 'regionName', 'stateName', 'city', 'sightseeingName', 'pickUpPoint', 'dropPoint', 'hours', 'itineraryDetails', 'hatchback', 'sedan', 'suv', 'muv', 'tempo', "semiSleeperVolvo", 'isActive', 'createdDate'];
        ExportExcel(dispalyname, fields, sightseeingListdata, "SightseeingMaster", "SightseeingMaster.xls");
    }



    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getSightseeingDataList();
    }
    // const handlepastevent =(e)=> {
    //     e.preventDefault()

    // }
    //#region time format
    const [hoursTime, setHoursTime] = useState("--:--");
    function formatTime(date) {
        var d = new Date(date),
            // month = '' + (d.getMonth() + 1),
            // day = '' + d.getDate(),
            // year = d.getFullYear();
            hour = d.getHours(),
            minu = d.getMinutes();


        if (minu.toString().length < 2)
            minu = '0' + minu;
        if (hour.toString().length < 2)
            hour = '0' + hour;

        // if (month.length < 2) 
        // month = '0' + month;
        // if (day.length < 2) 
        // day = '0' + day;

        return [hour, minu].join(':');
    }
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }
    function ChangeDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const handleonchangeofTime = (fieldname, times) => {
        if (fieldname === 'Hours') {
            setHoursTime(times);
            form.Hours = ChangeDate(new Date()) + "T" + times + ":00"
            form.HoursD = times
            setItineraryErrorMessage('');
            setVehichalTypeErrorMessage('');
            setVehicalPriceErrorMessage('');
            setVehicalListError('');
            setSightseeingErrorMessage('');
            setSightseeingVehicleDuplicateErrorMessage('');
            setSightseeingDuplicateErrorMessage('');
            setCityErrorMessage('');
            setSightseeingDropErrorMessage('');
            setSightseeingPicErrorMessage('');
            setsightseeingHourerrorMessage('');
            setsightseeingHourlnerrorMessage('');
            if (document?.querySelector(".inputWrapper__dropdown")) {
                setEditDisabled(true);
            }
            // if ('INPUT' === document.activeElement.tagName) {
            //     setEditDisabled(true);
            // }
            // else if (document.querySelector(".inputWrapper input").tagName){
            //     setEditDisabled(true);
            // }
        }


        //setEditDisabled(true);
    }
    function valuepass(fildvalue) {
        let status = false;
        const checkvalue = /[-]/g;
        const result = fildvalue.match(checkvalue);
        if (result?.length > 0 && result?.length < 4 && result !== null && result !== undefined) {
            status = true;
        }
        else {
            status = false;
        }
        return status

    }
    //#endregion time format
    return (
        <>

            <div class="container mt-4 heading_color">
                <h3>Sightseeing Master</h3>
            </div>
            <div class="container form_border py-5 my-3">
                <form action="" >
                    <div class="mb-3 row mx-1">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="row">
                                {sightseeingduplicateerrorMessage && <div className="text-danger pb-3 text-center"> {sightseeingduplicateerrorMessage} </div>}
                                <div className='col-lg-2 col-sm-6 mx-lg-auto ms-lg-0'>
                                    <div class="col-lg-12 col-md-12">
                                        <label for="inputPassword6" class="col-form-label required">Continent</label>
                                    </div>
                                    <div class="col-lg-12 mb-1 col-md-12">
                                        <Select className="dropdown select_dropdown"
                                            isDisabled
                                            value={continent?.find(({ value }) => value === cityid)}
                                            options={continent}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                                    <div class="col-lg-12 col-md-12">
                                        <label for="inputPassword6" class="col-form-label required">Country</label>
                                    </div>
                                    <div class="col-lg-12 mb-1 col-md-12">
                                        <Select className="dropdown select_dropdown"
                                            isDisabled
                                            value={country?.find(({ value }) => value === cityid)}
                                            options={country}
                                        />
                                    </div>

                                </div>
                                <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                                    <div class="col-lg-12 col-md-12">
                                        <label for="inputPassword6" class="col-form-label required">Region</label>
                                    </div>
                                    <div class="col-lg-12 mb-1 col-md-12">
                                        <Select className="dropdown select_dropdown"
                                            isDisabled
                                            value={region?.find(({ value }) => value === cityid)}
                                            options={region}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                                    <div class="col-lg-12 col-md-12">
                                        <label for="inputPassword6" class="col-form-label required">State</label>
                                    </div>
                                    <div class="col-lg-12 mb-1 col-md-12">
                                        <Select className="dropdown select_dropdown"
                                            isDisabled
                                            value={state?.find(({ value }) => value === cityid)}
                                            options={state}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-2 col-sm-6 mx-lg-auto me-lg-0'>
                                    <div class="col-lg-12 col-md-12">
                                        <label for="inputPassword6" class="col-form-label required">City</label>
                                    </div>
                                    <div class="col-lg-12 mb-1 col-md-12">
                                        <CustomSelection
                                            selId="CityError"
                                            selRef={CityError}
                                            datalist={cityList}
                                            fieldid={cityid}
                                            SelectionChange={handlecity}
                                            isdisable={disabled}
                                            isactivedisable={citydisable}
                                            issearchable={true}
                                        />
                                        {cityerrorMessage && <p className='text-danger text-start'>{cityerrorMessage}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-1 mt-4 col-lg-11 mx-lg-5">
                        <div className='row'>
                            <div className='col-lg-12 col-sm-12 '>
                                <div className='row'>

                                    <div class="col-lg-3 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label required">Sightseeing Name</label>
                                    </div>
                                    <div class=" col-lg-9 col-md-12 col-sm-12 mb-lg-1">
                                        <textarea type="text"
                                            id="SightseeingName"
                                            class="form-control enquiry_boder py-2 mx-auto"
                                            name="SightseeingName"
                                            value={form.SightseeingName}
                                            onChange={onChange}
                                            disabled={disabled ? true : false}
                                            placeholder='Sightseeing Name'
                                        // maxLength={5000}
                                        />
                                        {sightseeingerrorMessage && <p className='text-danger text-start'>{sightseeingerrorMessage}</p>}
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label required">Pick Up Point</label>
                                    </div>
                                    <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <input type="text"
                                            id="PickupPoint"
                                            class="form-control"
                                            name='PickupPoint'
                                            value={form.PickupPoint}
                                            disabled={disabled ? true : false}
                                            onChange={onChange}
                                            placeholder='Pick Up Point' />
                                        {sightseeingPicerrorMessage && <p className='text-danger text-start'>{sightseeingPicerrorMessage}</p>}
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label required">Drop Point</label>
                                    </div>
                                    <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <input type="text"
                                            id="DropPoint"
                                            class="form-control"
                                            name="DropPoint"
                                            value={form.DropPoint}
                                            disabled={disabled ? true : false}
                                            onChange={onChange}
                                            placeholder='Drop Point' />
                                        {sightseeingDroperrorMessage && <p className='text-danger text-start'>{sightseeingDroperrorMessage}</p>}
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label required">Hours</label>
                                    </div>
                                    <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        {/* <input type="text"
                                            id="inputPassword6"
                                            class="form-control"
                                            name="formformform"
                                            value={form.Hours}
                                            disabled={disabled ? true : false}
                                            onChange={onChange}
                                            placeholder='Hours'
                                        /> */}
                                        <CustomTimePicker classid="Hours" filedvalue={hoursTime} isDisable={disabled ? true : false} handleOnChange={(e) => handleonchangeofTime("Hours", e)} />
                                        {sightseeingHourerrorMessage && <p className='text-danger text-start'>{sightseeingHourerrorMessage}</p>}
                                        {sightseeingHourlnerrorMessage && <p className='text-danger text-start'>{sightseeingHourlnerrorMessage}</p>}
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label">Active</label>
                                    </div>
                                    <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <Switch
                                            onChange={handleisActive}
                                            checked={activeId}
                                            className="react-switch"
                                            disabled={disabled ? true : false}
                                        // disabled={disabled ? true : false}
                                        />
                                    </div>
                                    {/* <div class=" col-auto col-lg-4"> */}
                                    <ConfirmationModal
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                        noconfirm={() => handleConfirmation(false)}
                                        yesconfirm={() => handleConfirmation(true)}
                                    />

                                    {/* </div> */}
                                </div>
                            </div>
                            <div className='col-lg-12 col-sm-12 '>
                                <div className='row'>
                                    <div class="col-lg-3 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label required">Itinerary Details</label>
                                    </div>
                                    {/* <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1"> */}
                                    <div class=" col-lg-9 col-md-12 col-sm-12 mb-lg-1">
                                        <textarea type="text"
                                            id="ItineraryDetails"
                                            class="form-control enquiry_boder py-2 mx-auto"
                                            name="ItineraryDetails"
                                            value={form.ItineraryDetails}
                                            disabled={disabled ? true : false}
                                            onChange={onChange}
                                            placeholder='Itinerary Details'
                                        // maxLength={5000}
                                        />
                                        {ItineraryerrorMessage && <p className='text-danger text-start'>{ItineraryerrorMessage}</p>}
                                    </div>

                                </div>
                            </div>
                            {/* <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label">Active</label>
                                    </div>
                                    <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <Switch
                                            onChange={handleisActive}
                                            checked={activeId}
                                            className="react-switch"
                                            disabled={disabled ? true : false}
                                        // disabled={disabled ? true : false}
                                        />
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <div class="col-lg-12 mx-1">
                            <label for="inputPassword" class=" col-form-label fw-bolder">Vehicle Details</label>
                        </div>
                        <div class="col-lg-12">

                            <div class="mb-3 row mx-1 form_border py-3">
                                <div className='col-lg-12 col-sm-12 '>
                                    {sightseeingVehicleDuplicateErrorMessage && <p className='text-danger text-center'>{sightseeingVehicleDuplicateErrorMessage}</p>}
                                    {vehicallisterror && <p className='text-danger text-center'>{vehicallisterror}</p>}
                                    <div className='row'>
                                        <div class="col-lg-2 col-md-12 col-sm-12 mb-lg-1">
                                            <label for="inputPassword6" class="col-form-label required">Vehicle Type</label>
                                        </div>
                                        <div class=" col-lg-2 col-md-12 col-sm-12 mb-lg-1">
                                            <Select className="dropdown select_dropdown"
                                                id="VehicleTypeD"
                                                ref={VehicleTypeD}
                                                name='VehicleTypeD'

                                                options={vehichallist}
                                                value={vehichallist?.filter(function (vehichallist) {
                                                    return vehichallist.value === vehicleId;
                                                })}// set list of the data
                                                onChange={handleVehicalchange}// assign onChange function
                                                isDisabled={disabled ? true : false}
                                            />
                                            {vehichaltypeerrorMessage && <p className='text-danger text-start'>{vehichaltypeerrorMessage}</p>}
                                        </div>
                                        <div class="col-lg-2 col-md-12 col-sm-12 mb-lg-1">
                                            <label for="inputPassword6" class="col-form-label required">Pax Capacity</label>
                                        </div>
                                        <div class=" col-lg-2 col-md-12 col-sm-12 mb-lg-1">
                                            <Select className="dropdown select_dropdown"
                                                name='PaxCapacityD'
                                                isDisabled
                                                value={paxCapacityD?.find(({ value }) => value === vehicleId)}
                                                options={paxCapacityD}
                                            // onChange={handleCapacitychange}
                                            />
                                            {/* {formErrors.SupplierName && <p className='text-danger text-start'>{formErrors.SupplierName}</p>}  */}
                                        </div>
                                        <div class="col-lg-1 col-md-12 col-sm-12 mb-lg-1">
                                            <label for="inputPassword6" class="col-form-label required">Price</label>
                                        </div>
                                        <div class=" col-lg-2 col-md-12 col-sm-12 mb-lg-1">
                                            <input class="form-control"
                                                id="Price"
                                                type="text"
                                                name="Price"
                                                value={form.Price}
                                                // onPaste={handlepastevent}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                                placeholder='Price'

                                            />
                                            {vehicalpriceerrorMessage && <p className='text-danger text-start'>{vehicalpriceerrorMessage}</p>}
                                        </div>
                                        <div class="col-lg-1 col-md-12 col-sm-12 mb-lg-1">
                                            <button type="button" onClick={VehicleClick} disabled={editdisabled ? false : true} class="btn btn-primary mx-1" id="btnAdd">Add</button>
                                        </div>
                                    </div>
                                    <div className='mb-1 col-lg-12 col-md-12 mt-3 overflow-auto'>
                                        <table id="data" className="table table-hover" >
                                            <thead>
                                                <tr>
                                                    <th>Vehicle Type
                                                    </th>
                                                    <th className='d-none'>Vehicle Type Id
                                                    </th>
                                                    <th>Pax Capacity
                                                    </th>
                                                    <th>Price
                                                    </th>
                                                    <th>Remove
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody id="location-data">
                                                {datavehicledetails?.map((element, index) => {
                                                    if (datavehicledetailscounter > 0) {
                                                        return (
                                                            <tr class="ReactTable" key={index}>
                                                                <td >{element.VehicleType}</td>
                                                                <td className='d-none'>{element.VehicleTypeId}</td>
                                                                <td>{element.PaxCapacity}</td>
                                                                <td>{element.Price}</td>
                                                                <td><button type="button" onClick={(e) => { vehicleremovev(element.SeqNo, e) }} disabled={disabled ? true : false} class="btn btn-primary mx-1" id="btnAdd" >Remove</button></td>
                                                            </tr>
                                                        );
                                                    }
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* {datavehicledetails?.map((element, index) => {
                                    if (datavehicledetailscounter > 0) {
                                        return (
                                            <div className='d-lg-flex d-md-flex' key={index}>
                                                <div class="mb-1 col-lg-12">
                                                    <div className='row'>
                                                        <div class="col-lg-2 col-md-12 col-sm-12 mb-lg-1">
                                                            <label for="inputPassword6" class="col-form-label required">Vehicle Type</label>
                                                        </div>
                                                        <div class=" col-lg-2 col-md-6 col-sm-12 mb-1">

                                                            <Select className="dropdown select_dropdown"
                                                                isDisabled
                                                                value={vehichallist?.find(({ value }) => value === vehicleId)}
                                                                options={vehichallist} />

                                                        </div>
                                                        <div class="col-lg-2 col-md-12 col-sm-12 mb-lg-1">
                                                            <label for="inputPassword6" class="col-form-label required">Pax Capacity</label>
                                                        </div>
                                                        <div class=" col-lg-1 col-md-6 col-sm-12 mb-1">
                                                            <Select className="dropdown select_dropdown"
                                                                name='PaxCapacity'
                                                                isDisabled
                                                                key={index}
                                                                value={paxCapacityD?.find(({ value }) => value === 1)}
                                                                options={paxCapacityD}
                                                            />
                                                        </div>
                                                        <div class="col-lg-1 col-md-12 col-sm-12 mb-lg-1">
                                                            <label for="inputPassword6" class="col-form-label required">Price</label>
                                                        </div>
                                                        <div class=" col-lg-2 col-md-6 col-sm-12 mb-1">
                                                            <input
                                                                name='Price'
                                                                class="form-control"
                                                                key={index}
                                                                value={element.Price}
                                                                type="text"
                                                                readOnly
                                                            />
                                                        </div>
                                                        <div class="col-lg-1 col-md-12 col-sm-12 justify-content-start mb-lg-1">
                                                            <button type="button" onClick={(e) => { vehicleremovev(element.SeqNo, e) }} class="btn btn-primary mx-1" id="btnAdd" >Remove</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                })} */}
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center mb-4">
                        {useradd === false && buttonaddeditText === "Add" ?
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={!useradd}>{buttonaddeditText}</button> :
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={editdisabled ? false : true}>{buttonaddeditText}</button>}
                        <button type="button" class="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick}>Clear</button>
                    </div>
                    <div class="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}

                                />
                            </div>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input type="text" onKeyDown={onKeyDownHandler} id="btnSearchText" class="form-control" name="SearchValue" placeholder='Enter keyword' onChange={handleChangeSearchValue} value={searchvalue} disabled={serchdisable ? true : false} />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div class="p-2">
                                <button type="button" class="btn btn-primary mx-1" id="btnSearch"
                                    disabled={serchdisable ? true : false}
                                    onClick={handleClickSearchValue}
                                >Search</button>
                            </div>
                        </div>

                    </div>
                    
                    <CustomTable
                        columns={columns}
                        data={sightseeingListdata}
                        PdfExport={PdfExport}
                        ExportToExcel={ExportToExcel}
                        disabled={!userexport}
                    />

                </form>
            </div>
        </>
    )
}

export default Sightseeingmaster