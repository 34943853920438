import Switch from 'react-switch';
import Select from 'react-select';
import { useEffect, useRef, useState } from 'react';
import { isValidEmail, onlyAlphabets, onlyNumeric } from '../commonClass/CommonValidation';
import { getAllData, verifytoken } from '../../services/ApiCallingUserServices';
import { postUsersData } from '../../services/ApiCallingUserServices';
import { patchUsersData } from '../../services/ApiCallingUserServices';
import { useNavigate } from 'react-router-dom';
import { ConfirmationModal } from '../../customControl/ConfirmationModal';
import { useDispatch } from 'react-redux';
import { ClearLocalStorage } from '../commonClass/Session';


const SupplierAccount = (props) => {
    const dispatch = useDispatch()
    const {
        SupplierId,
        IsDisable
    } = props
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [activeId, setActiveId] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [savedisabled, setSaveDisabled] = useState(true);
    const [formErrors, setFormErrors] = useState({});
    // const [formafflitionerror, setFormAfflitionError] = useState('');
    // const [addisenableaff, setAddIsEnableAff] = useState(true);

    const [buttonaddeditText, setButtonAddEditText] = useState("Save");
    const [travelvalue, setTravel] = useState(0);
    const [paymentvalue, setPaymentValue] = useState(0);
    const [dTravel, setDTravel] = useState([]);
    const [paymentlistValue, setPaymentListValue] = useState([]);

    const TravelDetails = useState([{
        SeqNo: 0,
        TravelId: 0,
        Travel: '',
        PaymentValueId: 0,
        PaymentValue: ''
    }]);

    const [datapaymentdetails, setPaymentDetails] = useState(TravelDetails);
    const [datapaymentcounter, setPaymentCounter] = useState(0);

    const [addbuttontext, setAddButtonText] = useState('Save')
    const [clearbuttondisable, setClearButtonDisable] = useState(false);

    const [formaccount, setFormAccount] = useState({
        SupplierAccountId: 0,
        SupplierId: 0,
        AccountNumber: '',
        AccountName: '',
        BankName: '',
        IFSCCode: '',
        BranchName: '',
        BranchCode: '',
        PANNo: '',
        MICRNumber: '',
        TANNo: '',
        MobileNo: '',
        Email: '',
        TravelD: '',
        PaymentValueD: '',
        IsActive: false,
        DataCollection: []
    });

    const onChange = (e) => {
        var result;
        if ([e.target.name][0] === "MobileNo" || [e.target.name][0] === "MICRNumber" || [e.target.name][0] === "AccountNumber") {
            result = onlyNumeric(e.target.value);
        }
        else if ([e.target.name][0] === "IFSCCode" || [e.target.name][0] === "PANNo" || [e.target.name][0] === "TANNo") {
            result = (e.target.value).toUpperCase();
        }

        else if ([e.target.name][0] === "BranchName" || [e.target.name][0] === "BankName" || [e.target.name][0] === "AccountName") {
            result = onlyAlphabets(e.target.value)

        }
        else {
            result = e.target.value;
        }
        setFormAccount({ ...formaccount, [e.target.name]: result });
        setFormErrors({});
    }
    const handleIFSCBulr = (e) => {
        setFormAccount({ ...formaccount, BranchCode: e.target.value.slice(-6) });
    }
    const handleisActive = (e) => {
        if (buttonaddeditText === 'Save') {
            formaccount.IsActive = e
            setActiveId(!activeId)
            setFormErrors({});
        }
        else {
            setModalShow(true);
        }
    }
    const handleConfirmation = (e) => {
        //setYesNoConfirm(e)
        if (e === true) {
            formaccount.IsActive = !activeId
            setActiveId(!activeId)
            //setEditDisabled(false);
            setFormErrors({});
        }
        setModalShow(false);
    }

    const getSupplierPayment = async () => {
        await getAllData('/master/v1/getpaymentpolicy').then(response => {
            setDTravel(response.data[0].dTravel.map((value) => ({
                label: value.DTravelValue,
                value: value.DTravelId
            })))
            setPaymentListValue(response.data[0].paymentValue.map((value) => ({
                label: value.SPaymentValue,
                value: value.SPaymentId
            })))
        }
        );
    }

    const handlePaymentvaluechange = (e) => {
        formaccount.TravelD = e.label
        setTravel(e.value);
        setFormErrors({})
    }

    const handlepaymentvalue = (e) => {
        formaccount.PaymentValueD = e.label
        setPaymentValue(e.value);
        setFormErrors({});
    }

    const handleDTravelClick = () => {
        eval('Add').current.blur();
        // if (travelvalue > 0 && paymentvalue > 0) {
        //     setPaymentCounter(datapaymentcounter + 1)
        // }
        const dataTravel = datapaymentdetails.map(val => val.Travel)
        const dtravelValue = dTravel.find(lab => lab.value === travelvalue)
        if (travelvalue > 0 && paymentvalue > 0) {
            let error = {}
            const dt = dataTravel.find(dtValue => dtValue === (dtravelValue.label))
            if (dt === dtravelValue.label) {
                error.DTravel = "You can not select the same value of D-Travel";
            }
            else {
                setPaymentCounter(datapaymentcounter + 1)
            }
            setFormErrors(error);
        }
        else {
            let error = {}
            if (travelvalue === 0) {
                error.DTravel = "Kindly select any of the D-Travel";
            }
            if (paymentvalue === 0) {
                error.PaymentValue = "Kindly select a value";
            }
            setFormErrors(error);
        }
    }

    useEffect(() => {
        if (datapaymentcounter > 0 && travelvalue > 0 && paymentvalue > 0) {
            if (datapaymentcounter === 1) {

                setPaymentDetails(current => [{
                    SeqNo: datapaymentcounter, TravelId: travelvalue, Travel: formaccount.TravelD,
                    PaymentValueId: paymentvalue, PaymentValue: formaccount.PaymentValueD
                }]);
            }
            else {
                setPaymentDetails(current => [...current, {
                    SeqNo: datapaymentcounter, TravelId: travelvalue, Travel: formaccount.TravelD,
                    PaymentValueId: paymentvalue, PaymentValue: formaccount.PaymentValueD
                }]);
            }

            setTravel(0)
            setPaymentValue(0)
        }
    }, [datapaymentcounter])

    const handleremovePayment = (id) => {
        setPaymentDetails((oldState) => oldState?.filter((item) => item.SeqNo !== id));
    }

    const handleAddSupplierAccount = async () => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            if (!validateData()) {
                return;
            }
            formaccount.DataCollection = datapaymentdetails.map((value) => ({
                DTravelId: value.TravelId,
                SPaymentId: value.PaymentValueId
            }))

            if (statusoftoken) {
                const insertsupplier = formaccount.SupplierAccountId === 0 ? await postUsersData('/master/v1/insertsuppaccount', formaccount) : await patchUsersData('/master/v1/updatesuppaccount', formaccount);

                if (insertsupplier.data !== null && insertsupplier.data !== undefined && insertsupplier.data > 0 && insertsupplier.status === true) {
                    formaccount.SupplierAccountId === 0 ? dispatch({ type: 'ALERTINGMESSAGE', message: insertsupplier.message }) : dispatch({ type: 'ALERTINGMESSAGE', message: insertsupplier.message });
                    // formaccount.SupplierAccountId === 0 ? dispatch({ type: 'ALERTING', message: 'Record saved successfully.' }) : dispatch({ type: 'ALERTING', message: 'Record Updated successfully.' });
                    // navigate('/supplierlist');
                }
                else {
                    dispatch({ type: 'ALERTING', message: insertsupplier?.message })
                    // alert(insertsupplier?.message)
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
    }

    const validateData = () => {
        let error = {};
        let status = true;
        if (formaccount.AccountNumber?.trim()?.length < 9) {
            error.AccountNumber = "Kindly enter the correct account number";
            status = false;
        }
        if (formaccount.AccountNumber?.trim()?.length > 0) {
            let regex = new RegExp(/^[0-9]{9,18}$/g)
            if (regex.test(formaccount.AccountNumber) === false) {
                error.AccountNumber = "Kindly enter the correct account number";
                status = false;
            }
        }
        if (formaccount.AccountNumber?.trim()?.length === 0) {
            error.AccountNumber = "Kindly enter the account number";
            status = false;
        }
        if (formaccount.AccountName?.trim().length === 0) {
            error.AccountName = "Kindly Enter your Account Name";
            status = false;
        }
        if (formaccount.BankName?.trim().length === 0) {
            error.BankName = "Kindly Enter your Bank Name";
            status = false;
        }
        if (formaccount.IFSCCode?.trim().length === 0) {
            error.IFSCCode = "Kindly Enter IFSC code";
            status = false;
        }
        if (formaccount.IFSCCode?.trim().length > 0) {
            let regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
            if (regex.test(formaccount.IFSCCode) === false) {
                error.IFSCCode = "Kindly enter the correct IFSC code";
                status = false;
            }
        }
        if (formaccount.BranchName?.trim().length === 0) {
            error.BranchName = "Kindly Enter Branch Name";
            status = false;
        }
        if (formaccount.BranchCode?.trim().length === 0) {
            error.BranchCode = "Kindly Enter your Bank Code";
            status = false;
        }

        // if (formaccount.PANNo?.trim().length === 0) {
        //     error.PANNumber = "Kindly enter the PAN number";
        //     status = false;
        // }
        if (formaccount.PANNo?.trim()?.length > 0) {
            let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
            if (regex.test(formaccount.PANNo) === false) {
                error.PANNumber = "Kindly enter the correct PAN number";
                status = false;
            }
        }

        if (formaccount.MICRNumber?.trim().length === 0) {
            error.MICRNumber = "Kindly enter the MICR number";
            status = false;
        }
        if (formaccount.MICRNumber?.trim().length > 0 && formaccount.MICRNumber?.trim().length < 9) {
            error.MICRNumber = "Kindly enter the correct MICR number";
            status = false;
        }
        // if (formaccount.TANNo?.trim().length === 0) {
        //     error.TANNumber = "Kindly enter the TAN Number";
        //     status = false;
        // }
        if (formaccount.TANNo?.trim().length > 0) {
            let regex = new RegExp(/^[A-Z]{4}[0-9]{5}[A-Z]{1}$/);
            if (regex.test(formaccount.TANNo) === false) {
                error.TANNumber = "Kindly enter the correct TAN Number";
                status = false;
            }
        }
        // if (formaccount.PANNo?.trim()?.length > 0) {

        // }
        if (formaccount.MobileNo?.trim().length === 0) {
            error.MobileNumber = "Kindly enter the mobile number";
            status = false;
        }
        if (formaccount.MobileNo?.trim().length > 0 && formaccount.MobileNo?.trim().length < 10) {
            error.MobileNumber = "Kindly enter the correct the mobile number";
            status = false;
        }
        if (formaccount.Email?.trim().length === 0) {
            error.AccountEmail = "Kindly Enter your Email";
            status = false;
        }
        if (formaccount.Email?.trim().length > 0 && !isValidEmail(formaccount.Email)) {
            error.AccountEmail = "Email id should in a format abc@xyz.com";
            status = false;
        }
        const datacount = datapaymentdetails.filter((obj) => obj.TravelId > 1)
        if (datacount < 1 || datacount === undefined) {
            error.SupplierPayment = "Please enter supplier payment value";
            status = false;
        }
        setFormErrors(error);
        return status;
    }

    const handleClear = () => {
        setFormAccount({
            ...formaccount, AccountNumber: '', AccountName: '', BankName: '', IFSCCode: '', BranchName: '',
            BranchCode: '', PANNo: '', MICRNumber: '', TANNo: '', MobileNo: '', Email: '',
            TravelD: '', PaymentValueD: '', IsActive: false
        });
        setTravel(0)
        setPaymentValue(0);
        setFormErrors({});
        setPaymentCounter(0);
        setPaymentDetails([]);
        setActiveId(false);
    }

    const [supplierNameAccount, setSupplierNameAccount] = useState('')
    const handleRetrive = async () => {
        const supplieridname = await postUsersData('/master/v1/getsupplierbyid', { SupplierId });
        setSupplierNameAccount(supplieridname.data[0].supplierName)
        await postUsersData('/master/v1/getsuppaccountbyid', { "SupplierId": SupplierId }).then(response => {
            response.data?.map((obj) => {
                formaccount.SupplierAccountId = obj.supplierAccountId
                formaccount.SupplierId = obj.supplierId
                formaccount.AccountNumber = obj.accountNumber
                formaccount.AccountName = obj.accountName
                formaccount.BankName = obj.bankName
                formaccount.BranchCode = obj.branchCode
                formaccount.BranchName = obj.branchName
                formaccount.Email = obj.email
                formaccount.IFSCCode = obj.ifscCode
                formaccount.IsActive = obj.isActive
                formaccount.MICRNumber = obj.micrNumber
                formaccount.MobileNo = obj.mobileNo
                formaccount.PANNo = obj.panNo
                formaccount.TANNo = obj.tanNo

                formaccount.DataCollection = obj.dataCollection
                setAddButtonText(obj.supplierAccountId > 0 ? 'Update' : 'Save')
                setButtonAddEditText(obj.supplierAccountId > 0 ? 'Update' : 'Save')
                obj.dataCollection?.map((currobj, index) => {
                    if (index === 0) {
                        setPaymentDetails(current => [{
                            SeqNo: index + 1, TravelId: currobj.dTravelId, Travel: currobj.dTravelValue,
                            PaymentValueId: currobj.sPaymentId, PaymentValue: currobj.sPaymentValue
                        }]);
                    }
                    else {
                        setPaymentDetails(current => [...current, {
                            SeqNo: index + 1, TravelId: currobj.dTravelId, Travel: currobj.dTravelValue,
                            PaymentValueId: currobj.sPaymentId, PaymentValue: currobj.sPaymentValue
                        }]);
                    }
                });
                setPaymentCounter(obj.dataCollection?.length);
                setActiveId(formaccount.IsActive)
                setClearButtonDisable(obj.supplierAccountId > 0 ? true : false)
            })
        });

    }

    const DTravel = useRef(null)
    const PaymentValue = useRef(null)
    const Add = useRef(null)

    useEffect(() => {
        // Be sure that sequence of form fields and sequence of formErrors variables/fields are same, because every time focusing is done by the formErrors Array's top value. 
        if (Object.keys(formErrors).length > 0) {

            let id = Object.keys(formErrors)[0];

            // if (id === "SupplierPayment") {
            //     if (id === "DTravel" || id === "PaymentValue") {
            //         eval(id).current.focus();
            //     }
            // }
            if (id === 'SupplierPayment') {
                if (!(DTravel.current.commonProps.hasValue)) {
                    eval('DTravel').current.focus();
                }
                else if (!(PaymentValue.current.commonProps.hasValue)) {
                    eval('PaymentValue').current.focus();
                }
                else {
                    eval('Add').current.focus();
                }
            }
            else if (id === "DTravel" || id === "PaymentValue") {
                eval(id).current.focus();
            }
            else {
                let doc = document.getElementById(id);
                if (doc !== null)
                    doc.focus();
            }
        }

    }, [formErrors]);

    useEffect(() => {
        getSupplierPayment();
        if (SupplierId > 1) {
            formaccount.SupplierId = SupplierId
            setDisabled(IsDisable)
            setSaveDisabled(IsDisable)
            handleRetrive();
        }

    }, []);

    return (
        <div className="container form_border py-5 my-3">
            <form action="" >
                <div class="mb-1 mt-4 col-lg-10 mx-lg-5">
                    <div className='row'>
                        <div className="col-lg-6 col-sm-6">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <label className='col-form-label required'>Name of Company</label>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <input className='form-control' type="text" value={supplierNameAccount} disabled />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6"></div>
                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <label class="col-form-label required">Account Number</label>
                                </div>
                                <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <input class="form-control"
                                        type="text"
                                        id='AccountNumber'
                                        name="AccountNumber"
                                        value={formaccount.AccountNumber}
                                        onChange={onChange}
                                        disabled={disabled ? true : false}
                                        placeholder='Account Number'
                                        maxLength={18}
                                    />
                                    {formErrors.AccountNumber && <p className='text-danger text-start'>{formErrors.AccountNumber}</p>}
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <label class="col-form-label required">Account Name</label>
                                </div>
                                <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <input class="form-control"
                                        type="text"
                                        id='AccountName'
                                        name="AccountName"
                                        value={formaccount.AccountName}
                                        onChange={onChange}
                                        disabled={disabled ? true : false}
                                        placeholder='Account Name'
                                        maxLength={250}
                                    />
                                    {formErrors.AccountName && <p className='text-danger text-start'>{formErrors.AccountName}</p>}
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <label class="col-form-label required">Bank Name</label>
                                </div>
                                <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <input class="form-control"
                                        type="text"
                                        id='BankName'
                                        name="BankName"
                                        value={formaccount.BankName}
                                        onChange={onChange}
                                        disabled={disabled ? true : false}
                                        placeholder='Bank Name'
                                        maxLength={250}
                                    />
                                    {formErrors.BankName && <p className='text-danger text-start'>{formErrors.BankName}</p>}
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <label class="col-form-label required">IFSC Code</label>
                                </div>
                                <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <input class="form-control"
                                        type="text"
                                        id='IFSCCode'
                                        name="IFSCCode"
                                        value={formaccount.IFSCCode}
                                        onChange={onChange}
                                        disabled={disabled ? true : false}
                                        placeholder='IFSC Code'
                                        onBlur={handleIFSCBulr}
                                        maxLength={11}
                                    />
                                    {formErrors.IFSCCode && <p className='text-danger text-start'>{formErrors.IFSCCode}</p>}
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <label class="col-form-label required">Branch Name</label>
                                </div>
                                <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <input class="form-control"
                                        type="text"
                                        id='BranchName'
                                        name="BranchName"
                                        value={formaccount.BranchName}
                                        onChange={onChange}
                                        disabled={disabled ? true : false}
                                        placeholder='Branch Name'
                                        maxLength={250}
                                    />
                                    {formErrors.BranchName && <p className='text-danger text-start'>{formErrors.BranchName}</p>}
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <label class="col-form-label required">Branch Code</label>
                                </div>
                                <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <input class="form-control"
                                        type="text"
                                        name="BranchCode"
                                        value={formaccount.BranchCode}
                                        // onChange={onChange}
                                        // disabled={disabled ? true : false}
                                        placeholder='Branch Code'
                                        maxLength={6}
                                        disabled
                                    />
                                    {/* {formErrors.BranchCode && <p className='text-danger text-start'>{formErrors.BranchCode}</p>} */}
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <label class="col-form-label">PAN Number</label>
                                </div>
                                <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <input class="form-control"
                                        type="text"
                                        id='PANNumber'
                                        name="PANNo"
                                        value={formaccount.PANNo}
                                        onChange={onChange}
                                        disabled={disabled ? true : false}
                                        placeholder='PAN Number'
                                        maxLength={10}
                                    />
                                    {formErrors.PANNumber && <p className='text-danger text-start'>{formErrors.PANNumber}</p>}
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <label class="col-form-label required">MICR Number</label>
                                </div>
                                <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <input class="form-control"
                                        type="text"
                                        id='MICRNumber'
                                        name="MICRNumber"
                                        value={formaccount.MICRNumber}
                                        onChange={onChange}
                                        disabled={disabled ? true : false}
                                        placeholder='MICR Number'
                                        maxLength={9}
                                    />
                                    {formErrors.MICRNumber && <p className='text-danger text-start'>{formErrors.MICRNumber}</p>}
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <label class="col-form-label">TAN Number</label>
                                </div>
                                <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <input class="form-control"
                                        type="text"
                                        id='TANNumber'
                                        name="TANNo"
                                        value={formaccount.TANNo}
                                        onChange={onChange}
                                        disabled={disabled ? true : false}
                                        placeholder='TAN Number'
                                        maxLength={10}
                                    />
                                    {formErrors.TANNumber && <p className='text-danger text-start'>{formErrors.TANNumber}</p>}
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <label class="col-form-label required">Mobile Number</label>
                                </div>

                                <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <div class="prefix_div">
                                        <span class="mobileprefix">+91</span>
                                        <input class="form-control mobilecutoffinput"
                                            type="text"
                                            id='MobileNumber'
                                            name="MobileNo"
                                            value={formaccount.MobileNo}
                                            onChange={onChange}
                                            maxLength={10}
                                            disabled={disabled ? true : false}
                                        // placeholder='Mobile Number'
                                        />
                                    </div>
                                    {formErrors.MobileNumber && <p className='text-danger text-start'>{formErrors.MobileNumber}</p>}
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <label class="col-form-label required">Account Email</label>
                                </div>
                                <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <input class="form-control"
                                        type="text"
                                        id='AccountEmail'
                                        name="Email"
                                        value={formaccount.Email}
                                        onChange={onChange}
                                        disabled={disabled ? true : false}
                                        placeholder='Account Email'
                                        maxLength={150}
                                    />
                                    {formErrors.AccountEmail && <p className='text-danger text-start'>{formErrors.AccountEmail}</p>}
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <label class="col-form-label">Account Status</label>
                                </div>
                                <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <Switch
                                        onChange={handleisActive}
                                        checked={activeId}
                                        className="react-switch"
                                        disabled={disabled ? true : false}
                                    // disabled={disabled ? true : false}
                                    />
                                    {/* {formErrors.SupplierName && <p className='text-danger text-start'>{formErrors.SupplierName}</p>} */}
                                </div>
                                <ConfirmationModal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    noconfirm={() => handleConfirmation(false)}
                                    yesconfirm={() => handleConfirmation(true)}
                                />

                            </div>
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12 mt-4 mb-2'>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <h5 class="">Supplier Payment Policy</h5>
                            </div>
                        </div>
                        {formErrors.SupplierPayment && <p className='text-danger text-center' style={{ fontSize: '16px' }}>{formErrors.SupplierPayment}</p>}
                    </div>
                </div>
                <div className='d-lg-flex'>
                    <div class="mb-1 col-lg-10 ms-lg-5">
                        <div className='row'>
                            <div class="col-lg-3 col-md-2 col-sm-12 mb-lg-1 mb-md-1">
                                <label class="col-form-label required">D-Travel</label>
                            </div>
                            <div class=" col-lg-3 col-md-4 col-sm-12 mb-1">
                                {/* <Select className="dropdown select_dropdown"
                                                options={stateList}
                                                value={stateList?.filter(function (stateList) {
                                                    return stateList.value === locationid;
                                                })}// set list of the data
                                                name="HOId"
                                                onChange={handlestatechange}
                                                isDisabled={disabled ? true : false}
                                            /> */}
                                <Select
                                    className="dropdown select_dropdown"
                                    id='DTravel'
                                    ref={DTravel}
                                    name='TravelD'
                                    // value={options.find(({ value }) => value === travelvalue)}
                                    value={dTravel?.filter(function (dTravel) {
                                        return dTravel.value === travelvalue;
                                    })}
                                    options={dTravel}
                                    onChange={handlePaymentvaluechange}
                                    isSearchable
                                    isDisabled={disabled ? true : false}
                                />
                                {formErrors.DTravel && <p className='text-danger text-start'>{formErrors.DTravel}</p>}
                                {/* {formafflitionerror && <p className='text-danger text-start'>{formafflitionerror}</p>} */}
                            </div>
                            <div class="col-lg-3 col-md-2 col-sm-12 mb-lg-1 mb-md-1">
                                <label class="col-form-label required">% of Supplier Payment Value </label>
                            </div>
                            <div class=" col-lg-3 col-md-4 col-sm-12 mb-1">

                                <Select
                                    className="dropdown select_dropdown"
                                    id='PaymentValue'
                                    ref={PaymentValue}
                                    name='PaymentValueD'
                                    // value={options.find(({ value }) => value ===paymentvalue)}
                                    value={paymentlistValue?.filter(function (paymentlistValue) {
                                        return paymentlistValue.value === paymentvalue;
                                    })}
                                    options={paymentlistValue}
                                    onChange={handlepaymentvalue}
                                    isSearchable
                                    isDisabled={disabled ? true : false}
                                />
                                {formErrors.PaymentValue && <p className='text-danger text-start'>{formErrors.PaymentValue}</p>}
                                {/* {formafflitionerror && <p className='text-danger text-start'>{formafflitionerror}</p>} */}
                            </div>
                        </div>
                    </div>
                    <div class="mb-1 col-lg-2 col-md-12 col-sm-12 me-lg-5">
                        <div class="col-lg-1 col-md-12 col-sm-12 justify-content-center d-lg-block d-md-flex d-sm-flex mb-lg-1">
                            <button type="button"
                                // onClick={paymentClick} 
                                onClick={handleDTravelClick}
                                class="btn btn-primary mx-1" ref={Add} id="btnAdd" disabled={disabled ? true : false}>Add</button>
                        </div>
                    </div>
                </div>
                <div className='mb-1 col-lg-12 col-md-12 mt-3'>
                    <table id="data" className="table table-hover" >
                        <thead>
                            <tr>
                                <th>D-Travel
                                </th>
                                <th className='text-center'>Supplier Payment Value
                                </th>
                                {/* <th className='d-none'>Inclusion Type Id
                                                                    </th> */}
                                <th className='text-end pe-4'>Remove
                                </th>
                            </tr>
                        </thead>
                        <tbody id="location-data">
                            {datapaymentdetails && datapaymentdetails?.map((element, index) => {
                                if (datapaymentcounter > 0) {
                                    return (
                                        <tr class="ReactTable" key={index}>
                                            <td >{element.Travel}</td>
                                            <td className='text-center'>{element.PaymentValue}</td>
                                            <td className='text-end'><button type="button"
                                                onClick={(e) => { handleremovePayment(element.SeqNo, e) }}
                                                class="btn btn-primary mx-1" id="btnAdd" disabled={disabled ? true : false} >Remove</button></td>
                                        </tr>
                                    );
                                }
                            })}
                        </tbody>
                    </table>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={handleAddSupplierAccount} disabled={savedisabled ? true : false}>{addbuttontext}</button>
                    <button type="button" class="btn btn-primary mx-1" id="btnClear" onClick={handleClear} disabled={clearbuttondisable ? true : false}>Clear</button>
                </div>

            </form>
        </div>
    )
}
export default SupplierAccount;