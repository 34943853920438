import { alertConstatnt } from "../_constants/alert.constant";

export const alertaction = {
    alerting, alertingmessage, session, close
}

function alerting() {
    return {type: alertConstatnt.ALERTING}
}
function alertingmessage() {
    return {type: alertConstatnt.ALERTINGMESSAGE}
}
function session() {
    return {type: alertConstatnt.SESSIONOUT}
}
function close() {
    return {type: alertConstatnt.CLOSE}
}