import { useEffect, useState } from "react";
import { isValidEmail, onlyAlphabets, onlyNumeric } from "../commonClass/CommonValidation";
import { patchUsersData, postUsersData, verifytoken } from "../../services/ApiCallingUserServices";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ClearLocalStorage } from "../commonClass/Session";

const SupplierAccountManagement = (props) => {
    const {
        SupplierId,
        IsDisable
    } = props

    const [disabled, setDisabled] = useState(false);
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState({});
    const [addbuttontext, setAddButtonText] = useState('Save')
    const [clearbuttondisable, setClearButtonDisable] = useState(false);
    const [savedisabled, setSaveDisabled] = useState(true);
    const dispatch = useDispatch()


    const [formaccountmanagement, setFormAccountManager] = useState({
        supplierMngtId: 0,
        supplierId: 0,

        kamName: '',
        kamEmail: '',
        kamMobNo: '',

        amName: '',
        amEmail: '',
        amMobNo: '',


        mName: '',
        mEmail: '',
        mMobileNo: '',

    })

    const onChange = (e) => {

        var result;
        if ([e.target.name][0] === "kamMobNo" || [e.target.name][0] === "amMobNo" || [e.target.name][0] === "mMobileNo") {
            result = onlyNumeric(e.target.value);
        }
        else if ([e.target.name][0] === "kamName" || [e.target.name][0] === "amName" || [e.target.name][0] === "mName") {
            result = onlyAlphabets(e.target.value);
        }
        else {
            result = e.target.value;
        }
        setFormAccountManager({ ...formaccountmanagement, [e.target.name]: result });
        setFormErrors({});

        // props.onHandleChange(e);

    }

    const handleClear = () => {
        setFormAccountManager({
            ...formaccountmanagement, kamName: '', kamEmail: '', kamMobNo: '',
            amName: '', amEmail: '', amMobNo: '',
            mName: '', mEmail: '', mMobileNo: ''
        });
        setFormErrors({});
    }
    const ValidateData = () => {
        let status = true;
        let error = {}

        if (formaccountmanagement.kamEmail?.trim()?.length > 0 && !isValidEmail(formaccountmanagement.kamEmail)) {
            error.kamEmail = "Email id should in a format abc@xyz.com";
            status = false;
        }
        if (formaccountmanagement.kamMobNo?.trim()?.length > 0 && formaccountmanagement.kamMobNo?.trim()?.length < 10) {
            error.kamMobNo = "Kindly enter the correct mobile number";
            status = false;
        }
        if (formaccountmanagement.amEmail?.trim()?.length > 0 && !isValidEmail(formaccountmanagement.amEmail)) {
            error.amEmail = "Email id should in a format abc@xyz.com";
            status = false;
        }
        if (formaccountmanagement.amMobNo?.trim()?.length > 0 && formaccountmanagement.amMobNo?.trim()?.length < 10) {
            error.amMobNo = "Kindly enter the correct mobile number";
            status = false;
        }
        if (formaccountmanagement.mName?.trim()?.length === 0) {
            error.ManagerName = "Kindly Enter your Name";
            status = false;
        }
        if (formaccountmanagement.mEmail?.trim()?.length === 0) {
            error.EmailId = "Kindly Enter your Email";
            status = false;
        }
        if (formaccountmanagement.mEmail?.trim()?.length > 0 && !isValidEmail(formaccountmanagement.mEmail)) {
            error.EmailId = "Email id should in a format abc@xyz.com";
            status = false;
        }
        if (formaccountmanagement.mMobileNo?.trim()?.length === 0) {
            error.MobileNo = "Kindly enter the mobile number";
            status = false;
        }
        if (formaccountmanagement.mMobileNo?.trim()?.length > 0 && formaccountmanagement.mMobileNo?.trim()?.length < 10) {
            error.MobileNo = "Kindly enter the correct mobile number";
            status = false;
        }
        setFormErrors(error);
        return status;
    }
    const handleAccountManagement = async () => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            if (!ValidateData()) {
                return;
            }
            if (statusoftoken) {
                const insertsupplierman = formaccountmanagement.supplierMngtId === 0 ? await postUsersData('/master/v1/insertcontact', formaccountmanagement) : await patchUsersData('/master/v1/updatecontact', formaccountmanagement);

                if (insertsupplierman.data !== null && insertsupplierman.data !== undefined && insertsupplierman.data > 0 && insertsupplierman.status === true) {
                    formaccountmanagement.supplierMngtId === 0 ? dispatch({ type: 'ALERTINGMESSAGE', message: insertsupplierman.message }) : dispatch({ type: 'ALERTINGMESSAGE', message: insertsupplierman.message });
                    // formaccountmanagement.supplierMngtId === 0 ? dispatch({ type: 'ALERTING', message: 'Record saved successfully.' }) : dispatch({ type: 'ALERTING', message: 'Record Updated successfully.' });
                    // navigate('/supplierlist');
                }
                else {
                    dispatch({ type: 'ALERTINGMESSAGE', message: insertsupplierman?.message })
                    // alert(insertsupplierman?.message)
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }
    const [supplierNameAccountManagement, setSupplierNameAccountManagement] = useState('')
    const handleRetrive = async () => {
        const supplieridname = await postUsersData('/master/v1/getsupplierbyid', { SupplierId });
        setSupplierNameAccountManagement(supplieridname.data[0].supplierName)
        await postUsersData('/master/v1/getcontactbyid', { "SupplierId": SupplierId }).then((response) => {
            response.data?.map((currele) => {
                formaccountmanagement.supplierMngtId = currele.supplierMngtId
                formaccountmanagement.supplierId = currele.supplierId

                formaccountmanagement.kamName = currele.kamName
                formaccountmanagement.kamEmail = currele.kamEmail
                formaccountmanagement.kamMobNo = currele.kamMobNo

                formaccountmanagement.amName = currele.amName
                formaccountmanagement.amEmail = currele.amEmail
                formaccountmanagement.amMobNo = currele.amMobNo

                formaccountmanagement.mName = currele.mName
                formaccountmanagement.mEmail = currele.mEmail
                formaccountmanagement.mMobileNo = currele.mMobileNo
                setAddButtonText(formaccountmanagement.supplierMngtId > 0 ? 'Update' : 'Save');
                setClearButtonDisable(formaccountmanagement.supplierMngtId > 0 ? true : false)
            })
        })
    }

    useEffect(() => {
        // Be sure that sequence of form fields and sequence of formErrors variables/fields are same, because every time focusing is done by the formErrors Array's top value. 

        if (Object.keys(formErrors).length > 0) {

            let id = Object.keys(formErrors)[0];

            let doc = document.getElementById(id);
            if (doc !== null)
                doc.focus();
        }

    }, [formErrors]);

    useEffect(() => {
        if (SupplierId > 1) {
            formaccountmanagement.supplierId = SupplierId
            setDisabled(IsDisable)
            setSaveDisabled(IsDisable)
            handleRetrive();
        }

    }, []);
    return (
        <div class="container form_border py-5 my-3">
            <form action="" >
                <div class="mb-1 mt-4 col-lg-11 mx-lg-5">
                    <div className='row'>
                        <div className="col-lg-6 col-sm-6 mb-3">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <label className='col-form-label required'>Name of Company</label>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <input className='form-control' type="text" value={supplierNameAccountManagement} disabled />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6"></div>
                        <div className='col-lg-12 col-md-12 col-sm-12 mb-2'>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <h5 class="">Key Account Manager</h5>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <label class="col-form-label">Full Name</label>
                                </div>
                                <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <input class="form-control"
                                        type="text"
                                        name="kamName"
                                        value={formaccountmanagement.kamName}
                                        onChange={onChange}
                                        disabled={disabled ? true : false}
                                        placeholder='Full Name'
                                        maxLength={250}
                                    />
                                    {/* {formErrors.SupplierName && <p className='text-danger text-start'>{formErrors.SupplierName}</p>} */}
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <label class="col-form-label">Email ID</label>
                                </div>
                                <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <input class="form-control"
                                        type="text"
                                        id="kamEmail"
                                        name="kamEmail"
                                        value={formaccountmanagement.kamEmail}
                                        onChange={onChange}
                                        disabled={disabled ? true : false}
                                        placeholder='Email ID'
                                        maxLength={150}
                                    />
                                    {formErrors.kamEmail && <p className='text-danger text-start'>{formErrors.kamEmail}</p>}
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <label class="col-form-label">Mobile Number</label>
                                </div>
                                <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <div class="prefix_div">
                                        <span class="mobileprefix">+91</span>
                                        <input class="form-control mobilecutoffinput"
                                            type="text"
                                            id="kamMobNo"
                                            name="kamMobNo"
                                            value={formaccountmanagement.kamMobNo}
                                            onChange={onChange}
                                            disabled={disabled ? true : false}
                                            placeholder='Mobile Number'
                                            maxLength={10}
                                        />
                                    </div>
                                    {formErrors.kamMobNo && <p className='text-danger text-start'>{formErrors.kamMobNo}</p>}
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12 mt-3 mb-2'>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <h5 class="">Assistant Manager</h5>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <label class="col-form-label">Full Name</label>
                                </div>
                                <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <input class="form-control"
                                        type="text"
                                        name="amName"
                                        value={formaccountmanagement.amName}
                                        onChange={onChange}
                                        disabled={disabled ? true : false}
                                        placeholder='Full Name'
                                        maxLength={250}
                                    />
                                    {/* {formErrors.SupplierName && <p className='text-danger text-start'>{formErrors.SupplierName}</p>} */}
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <label class="col-form-label">Email ID</label>
                                </div>
                                <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <input class="form-control"
                                        type="text"
                                        id="amEmail"
                                        name="amEmail"
                                        value={formaccountmanagement.amEmail}
                                        onChange={onChange}
                                        disabled={disabled ? true : false}
                                        placeholder='Email ID'
                                        maxLength={150}
                                    />
                                    {formErrors.amEmail && <p className='text-danger text-start'>{formErrors.amEmail}</p>}
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <label class="col-form-label">Mobile Number</label>
                                </div>
                                <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <div class="prefix_div">
                                        <span class="mobileprefix">+91</span>
                                        <input class="form-control mobilecutoffinput"
                                            type="text"
                                            id="amMobNo"
                                            name="amMobNo"
                                            value={formaccountmanagement.amMobNo}
                                            onChange={onChange}
                                            disabled={disabled ? true : false}
                                            placeholder='Mobile Number'
                                            maxLength={10}
                                        />
                                    </div>
                                    {formErrors.amMobNo && <p className='text-danger text-start'>{formErrors.amMobNo}</p>}
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12 mt-3 mb-2'>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <h5 class="">Manager</h5>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <label class="col-form-label required">Full Name</label>
                                </div>
                                <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <input class="form-control"
                                        type="text"
                                        id="ManagerName"
                                        name="mName"
                                        value={formaccountmanagement.mName}
                                        onChange={onChange}
                                        disabled={disabled ? true : false}
                                        placeholder='Full Name'
                                        maxLength={250}
                                    />
                                    {formErrors.ManagerName && <p className='text-danger text-start'>{formErrors.ManagerName}</p>}
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <label class="col-form-label required">Email ID</label>
                                </div>
                                <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <input class="form-control"
                                        type="text"
                                        id="EmailId"
                                        name="mEmail"
                                        value={formaccountmanagement.mEmail}
                                        onChange={onChange}
                                        disabled={disabled ? true : false}
                                        placeholder='Email ID'
                                        maxLength={150}
                                    />
                                    {formErrors.EmailId && <p className='text-danger text-start'>{formErrors.EmailId}</p>}
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-6 '>
                            <div className='row'>
                                <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <label class="col-form-label required">Mobile Number</label>
                                </div>
                                <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                    <div class="prefix_div">
                                        <span class="mobileprefix">+91</span>
                                        <input class="form-control mobilecutoffinput"
                                            type="text"
                                            id="MobileNo"
                                            name="mMobileNo"
                                            value={formaccountmanagement.mMobileNo}
                                            onChange={onChange}
                                            disabled={disabled ? true : false}
                                            placeholder='Mobile Number'
                                            maxLength={10}
                                        />
                                    </div>
                                    {formErrors.MobileNo && <p className='text-danger text-start'>{formErrors.MobileNo}</p>}
                                </div>

                            </div>
                        </div>
                        <div class="d-flex justify-content-center mt-3">
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={handleAccountManagement} disabled={savedisabled ? true : false}>{addbuttontext}</button>
                            <button type="button" class="btn btn-primary mx-1" id="btnClear" onClick={handleClear} disabled={clearbuttondisable ? true : false} >Clear</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default SupplierAccountManagement;