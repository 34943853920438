import React from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from 'react-select';
import "../../assets/css/master.css"
import { getAllData } from '../../services/ApiCallingUserServices';
import { generatePDF } from '../commonClass/CommonValidation'
import { useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../commonClass/Session';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../_actions/loader.actions";
import { ExportExcel } from '../commonClass/ExportExcel';
import CustomTable from '../../customControl/CustomTable';

const Supplierlistmaster = () => {
    const dispatch = useDispatch();
    /////////////////////declartion of variable ///////////////////////////////////////
    //#region declartion of variable
    const navigate = useNavigate();
    // const [postdata, setPostData] = useState([]);
    // const [activeId, setActiveId] = useState();
    const [suplistdata, setSupListData] = useState([]);
    // const [buttonaddeditText, setButtonAddEditText] = useState("Add");
    // const [disabled, setDisabled] = useState(false);
    // const [editdisabled, setEditDisabled] = useState(true);
    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('20');
    const [serchdisable, setSerachDisable] = useState(false);
    const [filteritems, setFilterItems] = useState([]);
    const [clrshow, setsclshow] = useState(false);
    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);
    //const { items, requestSort, sortConfig } = useSortableData(taglistdata);

    const serachoption = [
        {
            label: 'Continent',
            value: '1'
        },
        {
            label: 'Country',
            value: '2'
        },
        {
            label: 'Region',
            value: '3'
        },
        {
            label: 'State',
            value: '4'
        },
        {
            label: 'City',
            value: '5'
        },
        {
            label: 'Name of Company',
            value: '6'
        },
        {
            label: 'Supplier Code',
            value: '21'
        },
        {
            label: 'State of Incorporation',
            value: '7'
        },
        {
            label: 'Office Number',
            value: '8'
        },
        {
            label: 'House Number',
            value: '9'
        },
        {
            label: 'Building Name',
            value: '10'
        },
        {
            label: 'Building Number',
            value: '11'
        },
        {
            label: 'Street',
            value: '12'
        },
        {
            label: 'Locality',
            value: '13'
        },
        {
            label: 'Landmark',
            value: '14'
        },
        {
            label: 'Pincode',
            value: '15'
        },
        {
            label: 'Website',
            value: '16'
        },
        {
            label: 'About Company',
            value: '17'
        },
        {
            label: 'Incorporation Number',
            value: '18'
        },
        {
            label: 'PAN Number',
            value: '22'
        },
        {
            label: 'GSTN Number',
            value: '23'
        },
        {
            label: 'Active',
            value: '19'
        },
        // {
        //     label: 'Created Date',
        //     value: '24'
        // },
        {
            label: 'All',
            value: '20'
        },
    ];
    //#endregion
    /////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////set form date for add, edit and view////////////////////


    //////////////////////////////use effect section //////////////////////////////////
    //#region  use effect section
    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            //if (Window.$name && Window.$userToken) {
            getSupDataList().then(() => { dispatch(loaderActions.end()) });
            setitems();
        }
        else {
            ClearLocalStorage();
            // alert('Your Session Expired. Please Login Again!');
            // navigate('/');
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, [])

    useEffect(() => {
        // let MENUID = GetMenuIdFromStorage()
        // let viewrights = false;
        // user?.filter(x => x.MenuId === MENUID).map((obj) => {
        //     viewrights = obj.URightView;
        //     setUserAdd(obj.URightAdd);
        //     setUserEdit(obj.URightEdit);
        //     setUserExport(obj.URightExport);
        //     if (!viewrights) {
        //         navigate('/dashboard');
        //     }
        // });
        // let MENUID = GetMenuIdFromStorage()
        let pagelink = user?.find(x =>{
            if( x.PageLink === window.location.pathname)
                return  x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);
       
        if (mid?.URightView === false && mid?.URightView!== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);
          
        });
      
    }, [user])
    //#endregion
    //////////////////////////////////////////////////////////////////////////////////////////////


    ///////////////////////fill dropdown and handle input value//////////////////////////
    //#region fill dropdown and handle input value


    const getSupDataList = async () => {
        const suplistdata = await getAllData("/master/v1/getallsupplier");
        setSupListData(suplistdata.data);
    }

    //#endregion
    /////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////////////serch and export sction////////////////////////////////////////////
    //#region handling search 

    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }

    const setitems = async () => {
        const suplistdata = await getAllData("/master/v1/getallsupplier");
        setFilterItems(suplistdata.data);
    }
    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            getSupDataList();
            setsclshow(false)
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleClickSearchValue();
        }
    };

    const handleClickSearchValue = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            setitems();
            var filteritem = filteritems;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec.continentName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec.countryName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '3') {
                filterdata = filteritem?.filter(issec => issec.regionName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '4') {
                filterdata = filteritem?.filter(issec => issec.stateName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '5') {
                filterdata = filteritem?.filter(issec => issec.city.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '6') {
                filterdata = filteritem?.filter(issec => issec.supplierName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '7') {
                filterdata = filteritem?.filter(issec => issec.hoName?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '8') {
                filterdata = filteritem?.filter(issec => issec.officeNo.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '9') {
                filterdata = filteritem?.filter(issec => issec.houseNo.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '10') {
                filterdata = filteritem?.filter(issec => issec.buildingName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '11') {
                filterdata = filteritem?.filter(issec => issec.buildingNo.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '12') {
                filterdata = filteritem?.filter(issec => issec.street.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '13') {
                filterdata = filteritem?.filter(issec => issec.locality.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '14') {
                filterdata = filteritem.filter(issec => issec.landmark.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '15') {
                filterdata = filteritem?.filter(issec => issec.pincode.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '16') {
                filterdata = filteritem?.filter(issec => issec.website.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '17') {
                filterdata = filteritem?.filter(issec => issec.aboutSupplier.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '18') {
                filterdata = filteritem?.filter(issec => issec.incorporationNo.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '22') {
                filterdata = filteritem?.filter(issec => issec?.panNo.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '23') {
                filterdata = filteritem?.filter(issec => issec?.gstnNo.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '21') {
                filterdata = filteritem?.filter(issec => issec.supplierCode.toString().toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '24') {
                filterdata = filteritem?.filter(issec => formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '19') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.isActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.isActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.isActive === false);
                }
                // else {
                //     filterdata = filteritem;
                // }
                else {
                    filterdata = filteritem?.filter(issec => formatDate(issec.createdDate).toLowerCase().includes("%#$&%^"));
                }
            }
            else if (searchby === '20') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    //filterdata = filteritem.filter(issec => issec.inActive === false);
                    filterdata = filteritem?.filter(issec => issec.continentName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.countryName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.regionName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.supplierName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.hoName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.officeNo?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.houseNo?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.buildingName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.buildingNo?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.street?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.locality?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.landmark?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.pincode?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.website?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.aboutSupplier?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.incorporationNo?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.supplierCode?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()) || issec.panNo?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()) || issec.gstnNo?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    //filterdata = filteritem.filter(issec => issec.inActive === true);
                    filterdata = filteritem?.filter(issec => issec.continentName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.countryName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.regionName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.supplierName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.hoName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.officeNo?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.houseNo?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.buildingName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.buildingNo?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.street?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.locality?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.landmark?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.pincode?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.website?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.aboutSupplier?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.incorporationNo?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.supplierCode?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()) || issec.panNo?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()) || issec.gstnNo?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.continentName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.countryName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.regionName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.supplierName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.hoName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.officeNo?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.houseNo?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.buildingName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.buildingNo?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.street?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.locality?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.landmark?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.pincode?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.website?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.aboutSupplier?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.incorporationNo?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.supplierCode?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()) || issec.panNo?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()) || issec.gstnNo?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    //filterdata = filteritem.filter(issec => issec.inActive === true);
                    filterdata = filteritem?.filter(issec => issec.continentName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.countryName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.regionName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.supplierName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.hoName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.officeNo?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.houseNo?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.buildingName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.buildingNo?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.street?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.locality?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.landmark?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.pincode?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.website?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.aboutSupplier?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.incorporationNo?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.supplierCode?.toString()?.toLowerCase()?.includes(searchvalue.toLowerCase()) || issec.panNo?.toString()?.toLowerCase()?.includes(searchvalue.toLowerCase()) || issec.gstnNo?.toString()?.toLowerCase()?.includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => issec.continentName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.countryName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.regionName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.supplierName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.hoName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.officeNo?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.houseNo?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.buildingName?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.buildingNo?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.street?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.locality?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.landmark?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.pincode?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.website?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.aboutSupplier?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.incorporationNo?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.supplierCode?.toString()?.toLowerCase()?.includes(searchvalue.toLowerCase()) || issec.panNo?.toString()?.toLowerCase()?.includes(searchvalue.toLowerCase()) || issec.gstnNo?.toString()?.toLowerCase()?.includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()));
                }

            }
            setSupListData(filterdata)
        }
    }

    function PdfExport() {
        //print
        const columns = [
            "Continent",
            "Country",
            "Region",
            "State",
            "City",
            "Name of Company",
            "Supplier Code",
            "State of Incorporation",
            "Office Number",
            "House Number",
            "Building Name",
            "Building Number",
            "Street",
            "Locality",
            "Landmark",
            "Pincode",
            "Website URL",
            "About Company",
            "Incorporation Number",
            "PAN Number",
            "GSTN Number",
            "Active",
            "Created Date"
        ];
        var rows = [];

        for (let i = 0; i < suplistdata?.length; i++) {
            var temp = [
                suplistdata[i].continentName,
                suplistdata[i].countryName,
                suplistdata[i].regionName,
                suplistdata[i].stateName,
                suplistdata[i].city,
                suplistdata[i].supplierName,
                suplistdata[i].supplierCode,
                suplistdata[i].hoName,
                suplistdata[i].officeNo,
                suplistdata[i].houseNo,
                suplistdata[i].buildingName,
                suplistdata[i].buildingNo,
                suplistdata[i].street,
                suplistdata[i].locality,
                suplistdata[i].landmark,
                suplistdata[i].pincode,
                suplistdata[i].website,
                suplistdata[i].aboutSupplier,
                suplistdata[i].incorporationNo,
                suplistdata[i].panNo,
                suplistdata[i].gstnNo,
                `${suplistdata[i].isActive ? 'Active' : 'InActive'}`,
                formatDate(suplistdata[i].createdDate)
            ];
            rows = [...rows, temp]
        }
        generatePDF(columns, rows, "SupplierMaster", "Supplier Master", 'l')
    }
    // function ExportToExcel() {
    //     const fields = ['continentName', 'countryName', 'regionName', 'stateName', 'city', 'supplierName', 'supplierCode', 'hoName', 'officeNo', 'houseNo', 'buildingName', 'buildingNo', 'street', 'locality', 'landmark', 'pincode', 'website', 'aboutSupplier', 'incorporationNo', 'isActive'];
    //     const data = suplistdata;
    //     const fileName = 'Suppliermasterexcel'
    //     const exportType = 'xls'

    //     exportFromJSON({ data, fileName, fields, exportType })
    // }

    const ExportToExcel = () => {

        const dispalyname = ['Continent', 'Country', 'Region', 'State', 'City', 'Supplier Name', 'Supplier Code', 'State of Incorporation', 'Office No.', 'House No.', 'Building Name', 'Building No.', 'Street', 'Locality', 'Landmark', 'Pincode', 'Website', 'About Company', 'Incorporation No.', 'PAN Number', 'GSTN Number', 'Active', 'Created Date'];
        const fields = ['continentName', 'countryName', 'regionName', 'stateName', 'city', 'supplierName', 'supplierCode', 'hoName', 'officeNo', 'houseNo', 'buildingName', 'buildingNo', 'street', 'locality', 'landmark', 'pincode', 'website', 'aboutSupplier', 'incorporationNo', 'panNo', 'gstnNo', 'isActive', 'createdDate'];
        ExportExcel(dispalyname, fields, suplistdata, "SupplierMaster", "SupplierMaster.xls");
    }
    //#endregion
    //////////////////////////////////////////////////////////////////////////////////////

    ///////////////////////////handle table Pagination///////////////////////////////////
    //#region table Pagination
    const columns = [
        {
            name: 'Continent',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (supplier) => supplier.continentName
        },
        {
            name: 'Country',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (supplier) => supplier.countryName
        },
        {
            name: 'Region',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (supplier) => supplier.regionName
        },
        {
            name: 'State',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (supplier) => supplier.stateName
        },
        {
            name: 'City',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (supplier) => supplier.city
        },
        {
            name: 'Name of Company',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (supplier) => supplier.supplierName
        },
        {
            name: 'Supplier Code',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (supplier) => supplier.supplierCode
        },
        {
            name: 'State of Incorporation',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (supplier) => supplier.hoName
        },
        {
            name: 'Office No.',
            sortable: true,
            float: "left",
            selector: (supplier) => supplier.officeNo
        },
        {
            name: 'House No.',
            sortable: true,
            float: "left",
            selector: (supplier) => supplier.houseNo
        },
        {
            name: 'Building Name',
            sortable: true,
            float: "left",
            selector: (supplier) => supplier.buildingName
        },
        {
            name: 'Building No.',
            sortable: true,
            float: "left",
            selector: (supplier) => supplier.buildingNo
        },
        {
            name: 'Street',
            sortable: true,
            float: "left",
            selector: (supplier) => supplier.street
        },
        {
            name: 'Locality',
            sortable: true,
            float: "left",
            selector: (supplier) => supplier.locality
        },
        {
            name: 'Landmark',
            sortable: true,
            float: "left",
            selector: (supplier) => supplier.landmark
        },
        {
            name: 'Pincode',
            sortable: true,
            float: "left",
            selector: (supplier) => supplier.pincode
        },
        {
            name: 'Website',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (supplier) => supplier.website
        },
        {
            name: 'About Company',
            sortable: true,
            float: "left",
            selector: (supplier) => supplier.aboutSupplier
        },
        {
            name: 'Incorporation No.',
            sortable: true,
            float: "left",
            selector: (supplier) => supplier.incorporationNo
        },
        {
            name: 'PAN Number',
            sortable: true,
            float: "left",
            selector: (supplier) => supplier.panNo
        },
        {
            name: 'GSTN Number',
            sortable: true,
            float: "left",
            selector: (supplier) => supplier.gstnNo
        },
        {
            name: 'Active',
            sortable: true,
            float: "left",
            selector: (supplier) => supplier.isActive ? 'Active' : 'InActive'
        },
        {
            name: 'Created Date',
            sortable: true,
            float: "left",
            selector: (supplier) => formatDate(supplier.createdDate)
        },
        {
            name: 'Edit',
            // float:"right",
            selector: (supplier) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEditSupplier(supplier.supplierId)}></i></button>
        },
        {
            name: 'View',
            // float:"right",
            selector: (supplier) => <i className="fa fa-eye" onClick={() => handleViewSupplier(supplier.supplierId)}></i>
        }
    ];



    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getSupDataList();
    }
    //#endregion
    ////////////////////////////////////////////////////////////////////////////////////////

    const handleOpenSupplier = () => {

        navigate('/supplierdetail', { state: { disabled: false, editdisabled: false, viewtype: 'Add', supplierId: 0 } });
    }

    const handleEditSupplier = (id) => {
        let supplierId = 0;

        suplistdata?.map(obj => {
            supplierId = obj.supplierId;
        });
        navigate('/supplierdetail', { state: { disabled: false, editdisabled: true, viewtype: 'Update', supplierId: id } });
    }

    const handleViewSupplier = (id) => {
        let supplierId = 0;

        suplistdata?.map(obj => {
            supplierId = obj.supplierId;
        });
        navigate('/supplierdetail', { state: { disabled: true, editdisabled: true, viewtype: 'View', supplierId: id } });
    }
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    return (
        <>
            <div class="container mt-4 heading_color">
                <h3>Supplier List</h3>
            </div>
            <div class="container form_border py-5 my-3">
                <form action="" >
                    <div class="d-flex justify-content-start ms-lg-3">
                        <button type="button" disabled={!useradd} class="btn btn-primary mx-1" to="Suppliermaster" id="btnAdd" onClick={handleOpenSupplier} >Create New Supplier</button>
                    </div>
                    <br />
                    <div class="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}

                                />
                            </div>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input onKeyDown={onKeyDownHandler} type="text" id="btnSearchText" class="form-control" placeholder='Enter keyword' name="SearchValue" onChange={handleChangeSearchValue} value={searchvalue} disabled={serchdisable ? true : false} />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div class="p-2 text-center">
                                <button type="button" class="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleClickSearchValue} >Search</button>
                            </div>
                        </div>

                    </div>
                    <CustomTable
                        columns={columns}
                        data={suplistdata}
                        PdfExport={PdfExport}
                        ExportToExcel={ExportToExcel}
                        disabled={!userexport}
                    />

                </form>
             
            </div>
        </>
    )
}

export default Supplierlistmaster