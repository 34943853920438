import React from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../assets/css/master.css"
import { Rating } from 'react-simple-star-rating';
import "react-datepicker/dist/react-datepicker.css";

const VendorSummary = (props) => {
    
    const {
        retriveflag,
        pkgsummarydatalist
    }=props

    const [PkgSummary, setPkgSummary] = useState([]);

    useEffect(() => {
        if (retriveflag) {
            setPkgSummary(pkgsummarydatalist)
        }

    }, [pkgsummarydatalist, retriveflag])
    function formatTime(date) {

        var d = new Date(date),

            hour = d.getHours(),
            minu = d.getMinutes();


        if (minu.toString().length < 2)
            minu = '0' + minu;
        if (hour.toString().length < 2)
            hour = '0' + hour;

        if (date === null) {
            minu = '';
            hour = '';
        }

        return [hour, minu].join(':');
    }
    return (
        <>
           <div class="accordion-item mb-3">
                <h2 class="accordion-header" id="panelsStayOpen-headingSix">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false" aria-controls="panelsStayOpen-collapseSix">
                        Summary
                    </button>
                </h2>
                <div id="panelsStayOpen-collapseSix" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSix">

                    <div id="Summary" class="tabcontentpkg">
                        <div class="summary-inner">
                            {/* <p class="fs-5 text-dark">Summary</p> */}
                            <div class="summaer-box position-relative">
                                {PkgSummary?.map((curElem) => {
                                    return (
                                        <>
                                            <div class="accordion-body">
                                                <div class="summary-title setclr">
                                                    <ul class="p-0 d-flex setclr">
                                                        <li><p class="text-dark m-0 fw-normal">{curElem.day}</p></li>
                                                    </ul>
                                                </div>

                                                {(curElem.flightNo != null && curElem.flightNo !== "") && (
                                                    <div class="day1-box day2box setclr">
                                                        <ul class="row">
                                                            <li class="col-lg-2 col-md-2 col-2 text-center" style={{ width: "100px" }}><img src="assets/img/flight.png" alt='' /> <p class="text-muted">Flight</p></li>
                                                            <li class="col-lg-2 col-md-2 col-12 text-center text-lg-start ps-4">
                                                                <div><p class="m-0 ">{curElem.airlineName}</p><p class="m-0">{curElem.flightNo}</p></div>
                                                            </li>
                                                            <li class="col-lg-2 col-md-2 col-12 text-center text-lg-start ps-4"><p class="fs-5 m-0 text-dark">{formatTime(curElem.departureTime)}</p><p class="fs-6">{curElem.flightFrom}</p></li>
                                                            <li class='col-lg-2 col-md-2 col-12 text-center text-lg-start ps-4'><span class='d-flex align-items-center justify-content-center justify-content-lg-start'>{curElem.duration}</span><div class="d-flex align-items-center justify-content-center justify-content-lg-start"><span class="border_top"></span><img src="assets/img/hand.png" /></div></li>
                                                            <li class="col-lg-2 col-md-2 col-12 text-center text-lg-start ps-4"><p class="fs-5 m-0 text-dark">{formatTime(curElem.arrivalTime)}</p><p class="fs-6">{curElem.flightTo}</p></li>
                                                        </ul>
                                                    </div>)}
                                                {curElem.hotelName != null && (
                                                    <div class="day1-box">
                                                        <ul class="row">
                                                            <li class="col-lg-2 col-md-2 col-2 text-center" style={{ width: "100px" }}><img src="assets/img/hotel.png" class="img-fluid" alt='' /> <p class="text-muted">Hotel</p></li>
                                                            <li class="col-lg-10 col-md-10 col-10 ps-4">
                                                                <div className='row'>
                                                                    <div className='col-lg-4 col-md-6 col-12'>
                                                                        <img src={'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + curElem.documentPath} class="me-2 img-fluid" alt='' /></div>
                                                                    <div className='col-lg-8 col-md-6 col-12'>
                                                                        <p class="m-0 text-dark">{curElem.hotelName}</p>
                                                                        <div class="star mb-2">
                                                                            <ul class="d-flex p-0">
                                                                                <Rating size={16} allowFraction readonly initialValue={curElem.hotelRating} />
                                                                            </ul>
                                                                        </div>
                                                                        <p class="m-0">{curElem.roomType}<br />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>)}

                                                < div class="day1-box">
                                                    <ul class="row">
                                                        <li class="col-lg-2 col-md-2 col-2 text-center" style={{ width: "100px" }}><img src="assets/img/explore.png" alt='' /> <p class="text-muted">Explore</p></li>
                                                        <li className='col-lg-10 col-md-10 col-10 ps-4'>
                                                            <p class="text-dark m-0">{curElem.sightSeeingCity}</p>
                                                            <ul class=" flex-column p-0">
                                                                <li class="p-0">
                                                                    <p class="text-muted mb-0 defualt-unstyled wraptextcont" dangerouslySetInnerHTML={{ __html: curElem.daySchedule }}></p>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default VendorSummary