import React, { useEffect, useMemo, useState } from 'react'

function CodeOfConduct({
    CodeOfConductCollection, setCodeOfConductCollection, isEditDisable, setIsEditDisable, disabled
}) {
    const [codeOfConductDetails, setCodeOfConductDetails] = useState({
        DressCode: "",
        ThingsToCarry: "",
        ThingsNotToCarry: "",
        EntranceRegulations: "",
        SpecialComments: ""
    });

    useMemo(() => {
        setCodeOfConductCollection(codeOfConductDetails)
    }, [codeOfConductDetails])

    useMemo(() => {
        setCodeOfConductDetails(CodeOfConductCollection)
    }, [CodeOfConductCollection])

    const handleOnChange = (e) => {
        setIsEditDisable(false)
        setCodeOfConductDetails({ ...codeOfConductDetails, [e.target.name]: e.target.value });
    };

    return (
        <div className='container form_border py-4 my-4'>
            <form action=''>
                <div className='col-lg-12 col-md-12 col-sm-12 px-lg-4 px-md-4'>

                    <div className='col-lg-2 col-md-12 col-sm-12'>
                        <label className="col-form-label"><b>Code Of Conduct</b></label>
                    </div>
                    <div className='mt-3'>

                        <div className='row mb-3'>
                            <div className='col-lg-2 col-md-12 col-sm-12'>
                                <label className="col-form-label">Dress Code</label>
                            </div>
                            <div className='col-lg-10 col-md-12 col-sm-12'>
                                <textarea class="form-control"
                                    id="DressCode"
                                    rows="4"
                                    name="DressCode"
                                    value={codeOfConductDetails.DressCode}
                                    onChange={(e) => handleOnChange(e)}
                                    placeholder='Dress Code'
                                    disabled={disabled ? true : false}
                                />
                            </div>
                        </div>

                        <div className='row mb-3'>
                            <div className='col-lg-2 col-md-12 col-sm-12'>
                                <label className="col-form-label">Things to carry</label>
                            </div>
                            <div className='col-lg-10 col-md-12 col-sm-12'>
                                <textarea class="form-control"
                                    id="ThingsToCarry"
                                    rows="4"
                                    name="ThingsToCarry"
                                    value={codeOfConductDetails.ThingsToCarry}
                                    onChange={(e) => handleOnChange(e)}
                                    placeholder='Things to carry'
                                    disabled={disabled ? true : false}
                                />
                            </div>
                        </div>

                        <div className='row mb-3'>
                            <div className='col-lg-2 col-md-12 col-sm-12'>
                                <label className="col-form-label">Things not to carry</label>
                            </div>
                            <div className='col-lg-10 col-md-12 col-sm-12'>
                                <textarea class="form-control"
                                    id="ThingsNotToCarry"
                                    rows="4"
                                    name="ThingsNotToCarry"
                                    value={codeOfConductDetails.ThingsNotToCarry}
                                    onChange={(e) => handleOnChange(e)}
                                    placeholder='Things not to carry'
                                    disabled={disabled ? true : false}
                                />
                            </div>
                        </div>

                        <div className='row mb-3'>
                            <div className='col-lg-2 col-md-12 col-sm-12'>
                                <label className="col-form-label">Entrance Regulations</label>
                            </div>
                            <div className='col-lg-10 col-md-12 col-sm-12'>
                                <textarea class="form-control"
                                    id="EntranceRegulations"
                                    rows="4"
                                    name="EntranceRegulations"
                                    value={codeOfConductDetails.EntranceRegulations}
                                    onChange={(e) => handleOnChange(e)}
                                    placeholder='Entrance Regulations'
                                    disabled={disabled ? true : false}
                                />
                            </div>
                        </div>

                        <div className='row mb-3'>
                            <div className='col-lg-2 col-md-12 col-sm-12'>
                                <label className="col-form-label">Special Comments</label>
                            </div>
                            <div className='col-lg-10 col-md-12 col-sm-12'>
                                <textarea class="form-control"
                                    id="SpecialComments"
                                    rows="4"
                                    name="SpecialComments"
                                    value={codeOfConductDetails.SpecialComments}
                                    onChange={(e) => handleOnChange(e)}
                                    placeholder='Special Comments'
                                    disabled={disabled ? true : false}
                                />
                            </div>
                        </div>

                    </div>

                </div>
            </form>
        </div>
    )
}

export default CodeOfConduct