import React, { useState } from 'react'
import Select from 'react-select';
import DataTable from 'react-data-table-component';

const CustomTable = ({
    columns,
    data = [],
    PdfExport = false,
    ExportToExcel = false,
    disabled,
    className
}) => {
 

    return (
        <>
            <div class="mb-3 row mx-1">
                <div class="col-lg-12">
                    <div class="mb-3 row form_border py-3">
                        <div class={`col-lg-12 mb-1 table-scrollable ${className}`}>
                            {data &&
                                <DataTable
                                    // title="Location"
                                    columns={columns}
                                    data={data}
                                    highlightOnHover
                                    pagination
                                    // defaultSortFieldId={1}
                                    paginationPerPage={5}
                                    paginationRowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
                                    // defaultSortFieldID={1}
                                    // striped
                                    responsive
                                />
                            }
                        </div>
                        <div>
                            {
                                typeof PdfExport === 'function' &&
                                <button type='button'
                                    disabled={disabled}
                                    className='border-0 p-0 bg-body me-1 set_disableclr'>
                                    <i className="fa fa-file-pdf-o" aria-hidden="true" style={{ fontSize: "28px", color: "red" }}
                                        onClick={PdfExport}>
                                    </i>
                                </button>
                            }
                            {
                                typeof ExportToExcel === 'function' &&
                                <button type='button'
                                    disabled={disabled}
                                    className='border-0 p-0 bg-body me-1 set_disableclr'
                                >
                                    <i className="fa fa-file-excel-o" aria-hidden="true" style={{ fontSize: "28px", color: "green" }}
                                        onClick={ExportToExcel}>
                                    </i>
                                </button>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomTable