/* eslint-disable no-useless-concat */
/* eslint-disable no-redeclare */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
import React, { useMemo, useRef } from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import '../../assets/css/master.css';
import Select from 'react-select';
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../services/ApiCallingUserServices';
import { onlyAlphabets, generatePDF, isValidImage } from '../commonClass/CommonValidation'
import Switch from 'react-switch';
import { ClearLocalStorage, GetLocalStorage } from '../commonClass/Session';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../_actions/loader.actions";
import { ExportExcel } from '../commonClass/ExportExcel';
import { ConfirmationModal } from '../../customControl/ConfirmationModal';
import { useNavigate } from 'react-router-dom';
import RichTextBox from '../../customControl/RichTextBox';
import { htmlToText } from 'html-to-text';
import { ImageDelete, ImageUpload } from '../../ImageUpload/ImageCommon';
import CustomTable from '../../customControl/CustomTable';

const Locationmaster = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // var store = useContext(ReactReduxContext).store.getState();
    //////////////////////////////declaration of variable/////////////////////////////
    //#region declaration of variable///////////////////////////////////////////////
    const [postdata, setPostData] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [activeId, setActiveId] = useState(false);
    const [locationlistdata, setLocationListData] = useState([]);
    const [buttonaddeditText, setButtonAddEditText] = useState("Add");
    const [disabled, setDisabled] = useState(false);
    const [editdisabled, setEditDisabled] = useState(true);
    const [serchdisable, setSerachDisable] = useState(false);
    const [filteritems, setFilterItems] = useState([]);
    const [continenterrorMessage, setContinentErrorMessage] = useState('');
    const [countryerrorMessage, setCountryErrorMessage] = useState('');
    const [regionerrorMessage, setRegionErrorMessage] = useState('');
    const [stateerrorMessage, setStateErrorMessage] = useState('');
    const [cityerrorMessage, setCityErrorMessage] = useState('');
    const [locationduplicateerrorMessage, setLocationDuplicateErrorMessage] = useState('');
    const [latitudeerrormessage, setLatitideeErrorMessage] = useState('');
    const [longitudeerrorMessage, setLongitudeErrorMessage] = useState('');
    const [continentData, setContinentData] = useState([]);
    const [continentId, setContinentId] = useState(0);

    const [countryData, setCountryData] = useState([]);
    const [countryId, setCountryId] = useState(0);

    const [regionData, setRegionData] = useState([]);
    const [regionId, setRegionId] = useState(0);

    const [stateData, setStateData] = useState([]);
    const [stateId, setStateId] = useState(0);
    const [clrshow, setsclshow] = useState(false);
    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);
    //const { items, requestSort } = useSortableData(locationlistdata);
    const serachoption = [
        {
            label: 'Continent',
            value: '1'
        },
        {
            label: 'Country',
            value: '2'
        },
        {
            label: 'Region',
            value: '3'
        },
        {
            label: 'State',
            value: '4'
        },
        {
            label: 'City',
            value: '5'
        },
        {
            label: 'Latitude',
            value: '6'
        },
        {
            label: 'Longitude',
            value: '7'
        },
        {
            label: 'Active',
            value: '8'
        },
        // {
        //     label: 'Created Date',
        //     value: '10'
        // },
        {
            label: 'All',
            value: '9'
        },

    ];

    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('9');

    //#endregion ////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////use effect section////////////////////////////////
    //#region use effect section
    useEffect(() => {
    }, [continenterrorMessage, countryerrorMessage, regionerrorMessage, stateerrorMessage, cityerrorMessage, latitudeerrormessage, longitudeerrorMessage]);


    const getContinentData = async () => {
        const ContinentData = await getAllData("/master/v1/getlocationcontinent");
        setContinentData(ContinentData.data);
    }
    const continentList = continentData?.map(value => ({
        label: value.continentName,
        value: value.continentId
    }));


    const getCountryData = async () => {
        const CountryData = await getAllData('/master/v1/getlocationcountry');
        setCountryData(CountryData.data);
    }
    const CountryList = countryData?.map(value => ({
        label: value.countryName,
        value: value.countryId
    }));

    const getRegiondata = async () => {
        const Regiondata = await getAllData('/master/v1/getlocationregion');
        setRegionData(Regiondata.data)
    }
    const RegionList = regionData?.map(value => ({
        label: value.regionName,
        value: value.regionId
    }));

    const getStatedata = async () => {
        const Statedata = await getAllData('/master/v1/getlocationstate');
        setStateData(Statedata.data)
    }
    const StateList = stateData?.map(value => ({
        label: value.stateName,
        value: value.stateId
    }));


    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            //if (Window.$name && Window.$userToken) {
            getContinentData();
            getCountryData();
            getRegiondata();
            getStatedata();
            getLocationDataList().then(() => { dispatch(loaderActions.end()) });
            setitems();
            //setTimeout(setLoaderEnd(),5000);
        }
        else {
            ClearLocalStorage();
            // alert('Your Session Expired. Please Login Again!');
            // navigate('/');
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, []);
    useEffect(() => {
        // let MENUID = GetMenuIdFromStorage()
        // let viewrights = false;
        // user?.filter(x => x.MenuId === MENUID).map((obj) => {
        //     viewrights = obj.URightView;
        //     setUserAdd(obj.URightAdd);
        //     setUserEdit(obj.URightEdit);
        //     setUserExport(obj.URightExport);
        //     if (!viewrights) {
        //         navigate('/dashboard');
        //     }
        // });
        // let MENUID = GetMenuIdFromStorage()
        // let mid = user?.find(x => x.MenuId === MENUID);
        let pagelink = user?.find(x => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);

        });
    }, [user])

    const handleContinentChange = (e) => {
        setContinentErrorMessage('');
        setCountryErrorMessage('');
        setRegionErrorMessage('');
        setStateErrorMessage('');
        setCityErrorMessage('');
        setCityDetailsErrorMessage('');
        setImageErrorMessage('');
        setImageError('');
        setLatitideeErrorMessage('');
        setLongitudeErrorMessage('');
        setLocationDuplicateErrorMessage('');
        { formValue.ContinentId = e.value }
        setContinentId(e.value);
        setEditDisabled(true);
    }
    const handleCountryChange = (e) => {
        setContinentErrorMessage('');
        setCountryErrorMessage('');
        setRegionErrorMessage('');
        setStateErrorMessage('');
        setCityErrorMessage('');
        setCityDetailsErrorMessage('');
        setImageErrorMessage('');
        setImageError('');
        setLocationDuplicateErrorMessage('');
        setLatitideeErrorMessage('');
        setLongitudeErrorMessage('');
        { formValue.CountryId = e.value }
        setCountryId(e.value);
        setEditDisabled(true);
    }
    const handleRegionChange = (e) => {
        setContinentErrorMessage('');
        setCountryErrorMessage('');
        setRegionErrorMessage('');
        setStateErrorMessage('');
        setCityErrorMessage('');
        setCityDetailsErrorMessage('');
        setImageErrorMessage('');
        setImageError('');
        setLocationDuplicateErrorMessage('');
        setLatitideeErrorMessage('');
        setLongitudeErrorMessage('');
        { formValue.RegionId = e.value }
        setRegionId(e.value);
        setEditDisabled(true);
    }
    const handleStateChange = (e) => {
        setContinentErrorMessage('');
        setCountryErrorMessage('');
        setRegionErrorMessage('');
        setStateErrorMessage('');
        setCityErrorMessage('');
        setCityDetailsErrorMessage('');
        setImageErrorMessage('');
        setImageError('');
        setLocationDuplicateErrorMessage('');
        setLatitideeErrorMessage('');
        setLongitudeErrorMessage('');
        { formValue.StateId = e.value }
        setStateId(e.value);
        setEditDisabled(true);
    }

    useEffect(() => {
    }, [postdata])
    useEffect(() => {

    }, [buttonaddeditText]);

    //#endregion
    ///////////////////////////////////////////////////////////////////////////////////

    ////////////handle section of table add, edit,retrive/////////////////////////////
    //#region handle section of table add, edit,retrive

    // set form date for add, edit and view
    const [formValue, setFormValue] = useState(
        {
            LocationId: 0,
            ContinentId: 0,
            CountryId: 0,
            RegionId: 0,
            StateId: 0,
            City: '',
            CityDetail: '',
            // PlaceImageName: '',
            PlaceImageName: '',
            // PlaceImagePath: '',
            PlaceImagePath: '',
            Latitude: '',
            Longitude: '',
            IsActive: false,
        }
    );
    //#region set form date for add, edit and view
    const ContinentError = useRef(null);
    const CountryError = useRef(null);
    const RegionError = useRef(null);
    const StateError = useRef(null);

    const validateData = () => {
        let error = []
        if (continentId === 0) {
            setContinentErrorMessage('Please select Continent name.')
            error.push('ContinentError')
        }
        if (countryId === 0) {
            setCountryErrorMessage('Please select Country name.')
            error.push('CountryError')
        }
        if (regionId === 0) {
            setRegionErrorMessage('Please select Region name.')
            error.push('RegionError')
        }
        if (stateId === 0) {
            setStateErrorMessage('Please select State name.')
            error.push('StateError')
        }
        if (typeof formValue.City === 'string' && formValue.City?.trim()?.length === 0) {
            setCityErrorMessage('Please enter City name.')
            error.push('City')
        }
        if (typeof formValue.PlaceImagePath === 'string' && formValue.PlaceImagePath?.trim()?.length === 0) {
            setImageErrorMessage("Please upload City Image.");
            error.push('txtgalleryimages')
            setImageError('');
        }
        if (formValue.PlaceImagePath === null || formValue.PlaceImagePath === '') {
            setImageErrorMessage("Please upload City Image.");
            error.push('txtgalleryimages')
            setImageError('');
        }
        if (typeof formValue.Latitude === 'string' && formValue.Latitude?.trim()?.length > 0) {
            if (typeof formValue.Longitude === 'string' && formValue.Longitude?.trim()?.length === 0) {
                setLongitudeErrorMessage('Please enter Longitude.')
                error.push('Longitude')
            }

        }
        if (typeof formValue.Longitude === 'string' && formValue.Longitude?.trim()?.length > 0) {
            if (typeof formValue.Latitude === 'string' && formValue.Latitude?.trim()?.length === 0) {
                setLatitideeErrorMessage('Please enter Latitude.')
                error.push('Latitude')
            }

        }
        if (typeof formValue.CityDetail === 'string' && formValue.CityDetail?.trim()?.length === 0) {
            setCityDetailsErrorMessage('Please enter City Details.')
            error.push('CityDetail')
        }

        if (error[0] === "ContinentError" || error[0] === "CountryError" || error[0] === "RegionError" || error[0] === "StateError") {
            eval(error[0]).current.focus();
        }
        else {
            let doc = document.getElementById(error[0]);
            if (doc !== null)
                doc.focus();
        }
        // if (typeof formValue.Latitude === 'string' && formValue.Latitude.trim().length === 0) {
        //     setLatitideeErrorMessage('Please enter Latitude.')
        // }
        // if (typeof formValue.Longitude === 'string' && formValue.Longitude.trim().length === 0) {
        //     setLongitudeErrorMessage('Please enter Longitude.')
        // }
    }

    const handleAddClick = async () => {
        let statusoftoken = await verifytoken();
        if (buttonaddeditText === "Add") {
            if (statusoftoken) {
                if (continentId === 0 || countryId === 0 || regionId === 0 || stateId === 0 || formValue.City?.trim()?.length === 0 || (typeof formValue.Longitude === 'string' && formValue.Longitude?.trim()?.length === 0 && formValue.Latitude?.trim()?.length > 0) || (typeof formValue.Latitude === 'string' && formValue.Latitude?.trim()?.length === 0 && formValue.Longitude?.trim()?.length > 0) || (typeof formValue.CityDetail === 'string' && formValue.CityDetail?.trim()?.length === 0) || (typeof formValue.PlaceImagePath === 'string' && formValue.PlaceImagePath?.trim()?.length === 0)) {
                    validateData();
                    return;
                }
                else {

                    if ((continentId === 0) && (countryId === 0) && (regionId === 0) && (stateId === 0) && (typeof formValue.City === 'string' && formValue.City?.trim()?.length > 0)) {
                        var filterdata;
                        filterdata = locationlistdata?.filter(issec => issec.continentId?.toLowerCase() === continentId?.toLowerCase() && issec.countryId?.toLowerCase() === countryId?.toLowerCase() && issec.regionId?.toLowerCase() === regionId?.toLowerCase() && issec.stateId?.toLowerCase() === stateId?.toLowerCase() && issec.city?.toLowerCase() === formValue.City?.toLowerCase());

                        if (filterdata?.length > 0) {
                            setLocationDuplicateErrorMessage('You can not enter duplicate value.');
                            return;
                        }
                    }
                    if (statusoftoken) {
                        const LocationData = await postUsersData("/master/v1/insertlocation", formValue);
                        setPostData(LocationData.data);
                        if (!LocationData.status && !LocationData.data) {
                            setLocationDuplicateErrorMessage(LocationData.message);
                            return;
                        }
                        if (LocationData.status) {
                            if (LocationData.data > 0) {
                                try {
                                    await handleimageupload('CMS/Location/', formValue.PlaceImageName, fileselected);
                                    setitems();
                                    dispatch({ type: 'ALERTING', message: LocationData.message })
                                    // alert('Record saved successfully.');
                                    clear();
                                }
                                catch (err) {
                                    dispatch({ type: 'ALERTING', message: 'Try again later!', err })
                                    // alert('Try again later!', err)
                                    setDisabled(false);
                                    setEditDisabled(true);
                                }
                                // setitems();
                                // dispatch({ type: 'ALERTING', message: LocationData.message })
                                // alert('Location master Record saved successfully');
                                //window.location.reload(false);
                                // clear();
                            }
                            else {
                                dispatch({ type: 'ALERTING', message: LocationData.message })
                                // alert('Location master Record not saved successfully')
                            }
                        }
                        else {
                            dispatch({ type: 'ALERTING', message: LocationData.message })
                            // alert('Something went wrong!' + 'add')
                        }
                    }
                    else {
                        ClearLocalStorage();
                        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                    }

                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
        else {
            if (statusoftoken) {
                if (continentId === 0 || countryId === 0 || regionId === 0 || stateId === 0 || formValue.City?.trim()?.length === 0 || (typeof formValue.Longitude === 'string' && formValue.Longitude?.trim()?.length === 0 && formValue.Latitude?.trim()?.length > 0) || (typeof formValue.Latitude === 'string' && formValue.Latitude?.trim()?.length === 0 && formValue.Longitude?.trim()?.length > 0) || (typeof formValue.CityDetail === 'string' && formValue.CityDetail?.trim()?.length === 0) || (formValue.PlaceImagePath === null || formValue.PlaceImagePath === '')) {
                    validateData();
                    return;
                }
                else {
                    if ((continentId === 0) && (countryId === 0) && (regionId === 0) && (stateId === 0) && (typeof formValue.City === 'string' && formValue.City?.trim()?.length > 0)) {
                        var filterdata;
                        filterdata = locationlistdata?.filter(issec => issec.locationId !== formValue.LocationId && issec.continentId?.toLowerCase() === continentId?.toLowerCase() && issec.countryId?.toLowerCase() === countryId?.toLowerCase() && issec.regionId?.toLowerCase() === regionId?.toLowerCase() && issec.stateId?.toLowerCase() === stateId?.toLowerCase() && issec.city?.toLowerCase() === formValue.City?.toLowerCase());
                        if (filterdata?.length > 0) {
                            setLocationDuplicateErrorMessage('You can not enter duplicate value.');
                            return;
                        }
                    }
                    if (statusoftoken) {
                        const LocationData = await patchUsersData("/master/v1/updatelocation", formValue);
                        setPostData(LocationData.data);
                        if (!LocationData.status && !LocationData.data) {
                            setLocationDuplicateErrorMessage(LocationData.message);
                            return;
                        }

                        if (LocationData.status) {
                            if (LocationData.data > 0) {
                                //setitems();
                                try {
                                    if (placeimagepath.length === 0) {
                                        await handleimageupload('CMS/Location/', formValue.PlaceImageName, fileselected);
                                    }
                                    try {
                                        if (placeimagepath.length === 0) {
                                            await handleimageDelete(placeimagedelete);
                                        }
                                    }
                                    catch (err) {
                                        dispatch({ type: 'ALERTING', message: 'Image delete transaction failed', err })
                                        // alert('Image delete transaction failed', err);
                                        setDisabled(false);
                                        setEditDisabled(true);
                                        return;
                                    }
                                    setitems();
                                    dispatch({ type: 'ALERTING', message: LocationData.message })
                                    // alert('Record updated successfully.');
                                    clear();
                                }
                                catch (err) {
                                    dispatch({ type: 'ALERTING', message: 'Image save transaction faild!', err })
                                    // alert('Image save transaction faild!', err);
                                    setDisabled(false);
                                    setEditDisabled(true);
                                    return;
                                }
                                //setitems();
                                //window.location.reload(false);
                                //clear();
                            }
                            else {
                                dispatch({ type: 'ALERTING', message: LocationData.message })
                                // alert('Record not updated successfully')
                                return;
                            }
                            // setitems();
                            // dispatch({ type: 'ALERTING', message: LocationData.message })
                            // alert('Location master Record updated successfully');
                            // clear();
                        }
                        else {
                            dispatch({ type: 'ALERTING', message: LocationData.message })
                            // alert('Something went wrong!' + 'err')
                        }
                    }
                    else {
                        ClearLocalStorage();
                        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                    }
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
    }


    const handleEditMode = (id) => {
        setDisabled(false);
        setEditDisabled(false);
        setSerachDisable(true);
        setButtonAddEditText("Update");
        RetriveData(id);
    }

    const handleViewMode = (id) => {
        setEditDisabled(false);
        setDisabled(true);
        setSerachDisable(true);
        RetriveData(id);
    }

    function RetriveData(id) {
        setContinentErrorMessage('');
        setCountryErrorMessage('');
        setRegionErrorMessage('');
        setStateErrorMessage('');
        setCityErrorMessage('');
        setCityDetailsErrorMessage('');
        setImageErrorMessage('');
        setImageError('');
        setLatitideeErrorMessage('');
        setLongitudeErrorMessage('');
        setLocationDuplicateErrorMessage('');
        setCityDetailsErrorMessage('');
        setImageErrorMessage('');
        setImageError('');
        locationlistdata?.map(obj => {
            if (obj.locationId === id) {
                { formValue.LocationId = obj.locationId }
                { formValue.ContinentId = obj.continentId }
                { formValue.CountryId = obj.countryId }
                { formValue.RegionId = obj.regionId }
                { formValue.StateId = obj.stateId }
                { formValue.City = obj.city }
                { formValue.Latitude = obj.latitude }
                { formValue.Longitude = obj.longitude }
                { formValue.IsActive = obj.isActive }
                { formValue.CityDetail = obj.cityDetail != null ? obj.cityDetail : '' }
                { formValue.PlaceImageName = obj.placeImageName }
                { formValue.PlaceImagePath = obj.placeImagePath }
                setActiveId(obj.isActive);
                setContinentId(obj.continentId)
                setCountryId(obj.countryId);
                setRegionId(obj.regionId);
                setStateId(obj.stateId);

                setPlaceImagePath('https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + obj.placeImagePath);
                setPlaceImageName(obj.placeImageName);
                setplaceimageDelete(obj.placeImagePath);
            }
        });
        setFormValue({ ...formValue, formValue })
        setRetriveComplete(htmlToText(formValue.CityDetail).length);
    }
    //#endregion //////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////

    ///////////////////////fill dropdown and handle input value//////////////////////////
    //#region fill dropdown and handle input value
    const getLocationDataList = async () => {
        const LocationListData = await getAllData("/master/v1/getalllocation");
        setLocationListData(LocationListData.data);

    }

    const [richboxheight, setRichBoxHeight] = useState(false)
    const [retrivecomplete, setRetriveComplete] = useState(0);
    const [citydetailerrorMessage, setCityDetailsErrorMessage] = useState('');
    const citydetailconfig = useMemo(
        () => ({
            readonly: false, // all options from https://xdsoft.net/jodit/doc/,
            height: '100%',
            maxHeight: richboxheight === true ? 200 : 500,
            width: '100%',
            statusbar: false,
            disablePlugins: ["source"],
            removeButtons: ["about", "link"],
            toolbarAdaptive: false,
            // autofocus: true,
            defaultMode: "1",
            disabled: disabled ? true : false
        }),
        [disabled, richboxheight]
    );

    const handleCityDetailChange = (e) => {
        // setFormError({ ...formError, question: '', answer: '', duplicate: '' })
        setContinentErrorMessage('');
        setCountryErrorMessage('');
        setRegionErrorMessage('');
        setStateErrorMessage('');
        setCityErrorMessage('');
        setCityDetailsErrorMessage('');
        setImageErrorMessage('');
        setImageError('');
        setLatitideeErrorMessage('');
        setLongitudeErrorMessage('');
        setLocationDuplicateErrorMessage('');
        const str = e.replace('<p><br></p>', '')
        formValue.CityDetail = str
        const html = htmlToText(str);
        if (html.length !== (retrivecomplete)) {
            setEditDisabled(true);
        }
    }
    useEffect(() => {
        const richtexteboxheight = document.getElementById("layoutSidenav_content").clientWidth
        if (richtexteboxheight > 1253) {
            setRichBoxHeight(true)
        }
        else {
            setRichBoxHeight(false)
        }
    }, []);

    const [placeimagedelete, setplaceimageDelete] = useState('');
    const [placeimagepath, setPlaceImagePath] = useState('');
    const [placeimagename, setPlaceImageName] = useState();
    const [fileselected, setSelectedFile] = useState();
    const [imageError, setImageError] = useState('');
    const [imageerrorMessage, setImageErrorMessage] = useState('');
    const handleUploadImageLoad = (e) => {
        if (isValidImage(e.target.files[0].name)) {
            const files = e.target.files;
            const filecom = getDateCombination();
            const filename = filecom + '_' + '1' + '_' + (files[0].name.replace(/ /g, "_")).replace(/-/g, '');
            formValue.PlaceImageName = filename
            formValue.PlaceImagePath = 'CMS/Location/' + filename
            setSelectedFile(e.target.files[0]);
            setEditDisabled(true);
            setContinentErrorMessage('');
            setCountryErrorMessage('');
            setRegionErrorMessage('');
            setStateErrorMessage('');
            setCityErrorMessage('');
            setCityDetailsErrorMessage('');
            setImageErrorMessage('');
            setImageError('');
            setLatitideeErrorMessage('');
            setLongitudeErrorMessage('');
            setLocationDuplicateErrorMessage('');
            setPlaceImagePath('');
            setPlaceImageName('');
        }
        else {
            document.getElementById("txtgalleryimages").value = "";
            setFormValue({ ...formValue, PlaceImageName: '', PlaceImagePath: '' });
            setEditDisabled(true);
            setContinentErrorMessage('');
            setCountryErrorMessage('');
            setRegionErrorMessage('');
            setStateErrorMessage('');
            setCityErrorMessage('');
            setCityDetailsErrorMessage('');
            setImageErrorMessage('');
            setLatitideeErrorMessage('');
            setLongitudeErrorMessage('');
            setLocationDuplicateErrorMessage('');
            setImageError("Please select Only Image")
            setPlaceImagePath('');
            setPlaceImageName('');
            return;
        }
    }
    const removeImage = () => {
        document.getElementById("txtgalleryimages").value = "";
        formValue.PlaceImagePath = ''
        formValue.PlaceImageName = ''
        setSelectedFile();
        setEditDisabled(true);
        setPlaceImagePath('');
        setPlaceImageName('');

        setContinentErrorMessage('');
        setCountryErrorMessage('');
        setRegionErrorMessage('');
        setStateErrorMessage('');
        setCityErrorMessage('');
        setCityDetailsErrorMessage('');
        setImageErrorMessage('');
        setImageError('');
        setLatitideeErrorMessage('');
        setLongitudeErrorMessage('');
        setLocationDuplicateErrorMessage('');
    };

    function getDateCombination() {
        var today = new Date();
        let date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
        let time = today.getHours().toString() + (today.getMinutes() + 1).toString() + today.getSeconds().toString();
        return date + '_' + time;
    }
    const handleimageupload = async (pathdirectory, filename, file) => {
        await ImageUpload(pathdirectory, filename, file);
    }
    const handleimageDelete = async (filename) => {
        await ImageDelete(filename);
    }

    const onChange = (e) => {
        var result;
        if ([e.target.name][0] === 'City') {

            result = onlyAlphabets(e.target.value)
        }
        else {
            result = e.target.value
        }

        setFormValue({ ...formValue, [e.target.name]: result });
        setEditDisabled(true);
        setContinentErrorMessage('');
        setCountryErrorMessage('');
        setRegionErrorMessage('');
        setStateErrorMessage('');
        setCityErrorMessage('');
        setCityDetailsErrorMessage('');
        setImageErrorMessage('');
        setImageError('');
        setLatitideeErrorMessage('');
        setLongitudeErrorMessage('');
        setLocationDuplicateErrorMessage('');
    };
    // const handleisActive = (e) => {
    //     { formValue.IsActive = e }
    //     setActiveId(!activeId)
    //     setEditDisabled(true);
    // }
    const handleisActive = (e) => {
        if (buttonaddeditText === 'Add') {
            { formValue.IsActive = e }
            setActiveId(!activeId)
            setContinentErrorMessage('');
            setCountryErrorMessage('');
            setRegionErrorMessage('');
            setStateErrorMessage('');
            setCityErrorMessage('');
            setCityDetailsErrorMessage('');
            setImageErrorMessage('');
            setImageError('');
            setLatitideeErrorMessage('');
            setLongitudeErrorMessage('');
            setLocationDuplicateErrorMessage('');
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (e) => {
        //setYesNoConfirm(e)
        if (e === true) {
            { formValue.IsActive = !activeId }
            setActiveId(!activeId)
            setEditDisabled(true);
            setContinentErrorMessage('');
            setCountryErrorMessage('');
            setRegionErrorMessage('');
            setStateErrorMessage('');
            setCityErrorMessage('');
            setCityDetailsErrorMessage('');
            setImageErrorMessage('');
            setImageError('');
            setLatitideeErrorMessage('');
            setLongitudeErrorMessage('');
            setLocationDuplicateErrorMessage('');
        }
        setModalShow(false);
    }

    const handleClearClick = () => {
        clear();
    }
    function clear() {
        setContinentErrorMessage('');
        setCountryErrorMessage('');
        setStateErrorMessage('');
        setRegionErrorMessage('');
        setCityErrorMessage('');
        setCityDetailsErrorMessage('');
        setImageErrorMessage('');
        setImageError('');
        setLocationDuplicateErrorMessage('');
        setLatitideeErrorMessage('');
        setLongitudeErrorMessage('');
        setFormValue({ ...formValue, Continent: '', Country: '', Region: '', State: '', City: '', CityDetail: '', Latitude: '', Longitude: '', PlaceImagePath: '', PlaceImageName: '', IsActive: false });
        setActiveId(false);
        getLocationDataList();
        setButtonAddEditText('Add');
        setDisabled(false);
        setEditDisabled(true);
        setSerachDisable(false);
        setSerachBy('9');
        setSearchValue('');
        setContinentId(0)
        setCountryId(0);
        setRegionId(0);
        setStateId(0);
        setsclshow(false)
        document.getElementById("txtgalleryimages").value = "";
        setSelectedFile();
        setPlaceImagePath('')
        setPlaceImageName('');
        setplaceimageDelete('');
        if (document.querySelector('.jodit-wysiwyg') !== null) {
            document.querySelector('.jodit-wysiwyg').innerHTML = '';
        }
    }
    //#endregion
    /////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////// handle table pagignation.//////////////////////////////////////////
    //#region table Pagination
    const columns = [
        {
            name: 'Continent',
            sortable: true,
            float: "left",
            selector: (location) => location.continentName,
        },

        {
            name: 'Country',
            sortable: true,
            float: "left",
            selector: (location) => location.countryName
        },
        {
            name: 'Region',
            sortable: true,
            float: "left",
            selector: (location) => location.regionName
        },
        {
            name: 'State',
            sortable: true,
            float: "left",
            selector: (location) => location.stateName
        },
        {
            name: 'City',
            sortable: true,
            float: "left",
            selector: (location) => location.city
        },
        {
            name: 'Latitude',
            sortable: true,
            float: "left",
            selector: (location) => location.latitude
        },
        {
            name: 'Longitude',
            sortable: true,
            float: "left",
            selector: (location) => location.longitude
        },
        {
            name: 'Active',
            sortable: true,
            float: "left",
            selector: (location) => location.isActive ? 'Active' : 'InActive'
        },
        {
            name: 'Created Date',
            sortable: true,
            float: "left",
            selector: (location) => formatDate(location.createdDate)
        },
        {
            name: 'Edit',
            // float:"right",
            selector: (location) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEditMode(location.locationId)}></i></button>
        },
        {
            name: 'View',
            // float:"right",
            selector: (location) => <i className="fa fa-eye" onClick={() => handleViewMode(location.locationId)}></i>
        }
    ];
    //#endregion
    /////////////////////////////////////////////////////////////////////////////////////

    /////////////////////handle search value//////////////////////////////////////////////
    //#region handle search value////////////////////////////////////////////////////////
    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }

    const setitems = async () => {
        const LocationListData = await getAllData("/master/v1/getalllocation");
        setFilterItems(LocationListData.data);
    }

    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            getLocationDataList();
            setsclshow(false)
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleClickSearchValue();
        }
    };

    const handleClickSearchValue = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            setitems();
            var filteritem = filteritems;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec.continentName?.toLowerCase().includes(searchvalue?.toLowerCase()));
            }
            else if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec.countryName?.toLowerCase().includes(searchvalue?.toLowerCase()));
            }
            else if (searchby === '3') {
                filterdata = filteritem?.filter(issec => issec.regionName?.toLowerCase().includes(searchvalue?.toLowerCase()));
            }
            else if (searchby === '4') {
                filterdata = filteritem?.filter(issec => issec.stateName?.toLowerCase().includes(searchvalue?.toLowerCase()));
                filterdata = filteritem?.filter(issec => issec.stateName?.toLowerCase().includes(searchvalue?.toLowerCase()));
            }
            else if (searchby === '5') {
                filterdata = filteritem?.filter(issec => issec.city?.toLowerCase().includes(searchvalue?.toLowerCase()));
            }
            else if (searchby === '6') {
                filterdata = filteritem?.filter(issec => issec.latitude?.toLowerCase().includes(searchvalue?.toLowerCase()));
            }
            else if (searchby === '7') {
                filterdata = filteritem?.filter(issec => issec.longitude?.toLowerCase().includes(searchvalue?.toLowerCase()));
            }
            else if (searchby === '10') {
                filterdata = filteritem?.filter(issec => formatDate(issec.createdDate)?.toLowerCase().includes(searchvalue?.toLowerCase()));
            }
            else if (searchby === '8') {
                if (searchvalue?.toLowerCase() === 'active'?.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.isActive === true);
                }
                else if (searchvalue?.toLowerCase() === 'inactive'?.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.isActive === false);
                }
                else if ('active'?.toLowerCase().includes(searchvalue?.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'?.toLowerCase().includes(searchvalue?.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.isActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => formatDate(issec.createdDate)?.toLowerCase().includes("%#$&%^"));
                }
            }
            else if (searchby === '9') {
                if (searchvalue?.toLowerCase() === 'active'?.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.continentName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.countryName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.regionName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.latitude?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.longitude?.toLowerCase().includes(searchvalue?.toLowerCase()) || formatDate(issec.createdDate)?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.isActive === true);
                }
                else if (searchvalue?.toLowerCase() === 'inactive'?.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.continentName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.countryName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.regionName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.latitude?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.longitude?.toLowerCase().includes(searchvalue?.toLowerCase()) || formatDate(issec.createdDate)?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.isActive === false);
                }
                else if ('active'?.toLowerCase().includes(searchvalue?.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.continentName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.countryName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.regionName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.latitude?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.longitude?.toLowerCase().includes(searchvalue?.toLowerCase()) || formatDate(issec.createdDate)?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'?.toLowerCase().includes(searchvalue?.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.continentName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.countryName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.regionName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.latitude?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.longitude?.toLowerCase().includes(searchvalue?.toLowerCase()) || formatDate(issec.createdDate)?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.isActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => issec.continentName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.countryName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.regionName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.latitude?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.longitude?.toLowerCase().includes(searchvalue?.toLowerCase()) || formatDate(issec.createdDate)?.toLowerCase().includes(searchvalue?.toLowerCase()) || 'active'?.toLowerCase().includes(searchvalue?.toLowerCase()) || 'inactive'?.toLowerCase().includes(searchvalue?.toLowerCase()));
                }
            }
            setLocationListData(filterdata)
        }
    }
    //#endregion
    //////////////////////////////////////////////////////////////////////////////////////

    ////////////////handle export section///////////////////////////////////////////////
    //#region handle export section ////////////////////////////////////////////////////
    function PdfExport() {
        const columns = [
            "Continent",
            "Country",
            "Region",
            "State",
            "City",
            "Latitude",
            "Longitude",
            "Active",
            "Created Date"
        ];
        var rows = [];

        for (let i = 0; i < locationlistdata?.length; i++) {
            var temp = [
                locationlistdata[i].continentName,
                locationlistdata[i].countryName,
                locationlistdata[i].regionName,
                locationlistdata[i].stateName,
                locationlistdata[i].city,
                locationlistdata[i].latitude,
                locationlistdata[i].longitude,
                `${locationlistdata[i].isActive ? 'Active' : 'InActive'}`,
                formatDate(locationlistdata[i].createdDate)
            ];
            rows = [...rows, temp]
        }
        generatePDF(columns, rows, "LocationMaster", "Location Master")
    }
    // function ExportToExcel() {
    //     const fields = ['continentName', 'countryName', 'regionName', 'stateName', 'city', 'latitude', 'longitude', 'isActive'];
    //     const data = locationlistdata;
    //     const fileName = 'Locationmasterexcel'
    //     const exportType = 'xls'

    //     exportFromJSON({ data, fileName, fields, exportType })
    // }

    const ExportToExcel = () => {
        const dispalyname = ['Continent', 'Country', 'Region', 'State', 'City', 'Latitude', 'Longitude', 'Active', 'Created Date'];
        const fields = ['continentName', 'countryName', 'regionName', 'stateName', 'city', 'latitude', 'longitude', 'isActive', 'createdDate'];
        ExportExcel(dispalyname, fields, locationlistdata, "LocationMaster", "LocationMaster.xls");
    }


    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getLocationDataList();
    }
    //#endregion ///////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }
    return (
        <>
            <div class="container mt-4 heading_color">
                <h3>Location Master</h3>
            </div>
            <div class="container form_border py-5 mb-3">
                <form action="" >
                    <div class="mb-3 row mx-1">
                        <div class="col-lg-12 col-md-12 col-sm-12 pe-lg-5">
                            <div class="row">
                                {locationduplicateerrorMessage && <div className="text-danger pb-3 text-center"> {locationduplicateerrorMessage} </div>}
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="ms-lg-5 col-form-label required" >
                                                Continent</label>
                                        </div>
                                        <div class=" col-lg-8 mb-1 col-md-12">
                                            <Select
                                                id='ContinentError'
                                                ref={ContinentError}
                                                className="dropdown select_dropdown"
                                                options={continentList}
                                                value={continentList?.filter(function (continentList) {
                                                    return continentList.value === continentId;
                                                })}// set list of the data
                                                onChange={handleContinentChange} // assign onChange function
                                                isDisabled={disabled ? true : false}
                                                isSearchable={true}

                                            />
                                            {continenterrorMessage && <div className="text-danger"> {continenterrorMessage} </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="ms-lg-5 col-form-label required">Country</label>
                                        </div>
                                        <div class=" col-lg-8 mb-1 col-md-12">
                                            <Select
                                                id='CountryError'
                                                ref={CountryError}
                                                className="dropdown select_dropdown"
                                                options={CountryList}
                                                value={CountryList?.filter(function (CountryList) {
                                                    return CountryList.value === countryId;
                                                })}// set list of the data
                                                onChange={handleCountryChange} // assign onChange function
                                                isDisabled={disabled ? true : false}
                                                isSearchable={true}

                                            />
                                            {countryerrorMessage && <div className="text-danger"> {countryerrorMessage} </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="ms-lg-5 col-form-label required">Region</label>
                                        </div>
                                        <div class=" col-lg-8 mb-1 col-md-12">
                                            <Select
                                                id="RegionError"
                                                ref={RegionError}
                                                className="dropdown select_dropdown"
                                                options={RegionList}
                                                value={RegionList?.filter(function (RegionList) {
                                                    return RegionList.value === regionId;
                                                })}// set list of the data
                                                onChange={handleRegionChange} // assign onChange function
                                                isDisabled={disabled ? true : false}
                                                isSearchable={true}

                                            />
                                            {regionerrorMessage && <div className="text-danger"> {regionerrorMessage} </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="ms-lg-5 col-form-label required">State</label>
                                        </div>
                                        <div class=" col-lg-8 mb-1 col-md-12">
                                            <Select
                                                id='StateError'
                                                ref={StateError}
                                                className="dropdown select_dropdown"
                                                options={StateList}
                                                value={StateList?.filter(function (StateList) {
                                                    return StateList.value === stateId;
                                                })}// set list of the data
                                                onChange={handleStateChange} // assign onChange function
                                                isDisabled={disabled ? true : false}
                                                isSearchable={true}
                                            />
                                            {stateerrorMessage && <div className="text-danger"> {stateerrorMessage} </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="ms-lg-5 col-form-label required">City</label>
                                        </div>
                                        <div class=" col-lg-8 mb-1 col-md-12">
                                            <input type="text"
                                                id="City"
                                                placeholder="City"
                                                class="form-control"
                                                name="City"
                                                value={formValue.City}
                                                onChange={onChange}
                                                disabled={disabled} />
                                            {cityerrorMessage && <div className="text-danger"> {cityerrorMessage} </div>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-6 col-sm-6'>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="ms-lg-5 col-form-label required">Upload City Image</label>
                                        </div>
                                        <div class="col-lg-8 col-md-12 col-sm-12 mb-lg-1 mb-md-1">
                                            <div class="input-group">
                                                <input
                                                    name='CityImage'
                                                    type="file"
                                                    id="txtgalleryimages"
                                                    onChange={handleUploadImageLoad}
                                                    class="form-control btn_add"
                                                    accept=".png,.jpg"
                                                    // onChange={handleuploadAffilitionCertifycate}
                                                    disabled={disabled ? true : false}
                                                />
                                                <label class="input-group-text" for="txtgalleryimages">Browse</label>
                                            </div>
                                            {imageerrorMessage && <div className="text-danger"> {imageerrorMessage} </div>}
                                            {imageError && <p className='text-danger text-start'>{imageError}</p>}
                                            {placeimagename && <p className='text-start overflow-hidden' data-bs-toggle="tooltip" data-bs-placement="left" title={placeimagename}>
                                                {placeimagename}</p>}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 pe-lg-5">
                            <div class="row">
                                <div className='col-lg-6 col-sm-6'>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="ms-lg-5 col-form-label">Latitude</label>
                                        </div>
                                        <div class=" col-lg-8 mb-1 col-md-12">
                                            <input type="text" id="Latitude" placeholder="Latitude" class="form-control" name="Latitude" value={formValue.Latitude} onChange={onChange} disabled={disabled} />
                                            {latitudeerrormessage && <div className="text-danger"> {latitudeerrormessage} </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="ms-lg-5 col-form-label">Longitude</label>
                                        </div>
                                        <div class=" col-lg-8 mb-1 col-md-12">
                                            <input type="text" id="Longitude" placeholder="Longitude" class="form-control" name="Longitude" value={formValue.Longitude} onChange={onChange} disabled={disabled} />
                                            {longitudeerrorMessage && <div className="text-danger"> {longitudeerrorMessage} </div>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-12 col-sm-12 '>
                                    <div className="row">
                                        <div className="col-lg-2 col-md-12 mb-1">
                                            <label htmlFor="Answer" className="ms-lg-5 col-form-label required">City Details</label>
                                        </div>
                                        <div className="col-lg-10 mb-1 col-md-12">
                                            <RichTextBox field="Answer" fieldvalue={formValue.CityDetail} tabIndex={1} OnChangeofRichBox={handleCityDetailChange} textconfig={citydetailconfig} />
                                            {/* {formError.answer && <div className="text-start text-danger"> {formError.answer} </div>} */}
                                            {citydetailerrorMessage && <div className="text-danger"> {citydetailerrorMessage} </div>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="ms-lg-5 col-form-label">Active</label>
                                        </div>
                                        <div class="col-auto col-lg-2 col-md-7 col-sm-12">
                                            <Switch
                                                onChange={handleisActive}
                                                checked={activeId}
                                                className="react-switch"
                                                disabled={disabled}
                                            />
                                        </div>
                                        {/* <div class=" col-auto col-lg-4"> */}
                                        <ConfirmationModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            noconfirm={() => handleConfirmation(false)}
                                            yesconfirm={() => handleConfirmation(true)}
                                        />

                                        {/* </div> */}
                                    </div>
                                </div>


                            </div>

                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            {/* {uploadimage.map((element) => {
                                return (
                                    <div className="col-lg-4 col-md-3 col-6 mx-auto mt-2" >
                                        <img src={element.imageurl} alt={element.name} className="img-fluid w-100" />
                                        <a onClick={() => removeImage(element.id)} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", }}>&times;</a>
                                    </div>

                                );
                            })} */}

                            {fileselected &&
                                (
                                    <div className="col-lg-2 col-md-3 col-6 mx-auto mt-2">
                                        <img
                                            src={URL.createObjectURL(fileselected)} className="img-fluid w-100" alt=''
                                        />
                                        <button onClick={() => removeImage()} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }} disabled={disabled ? true : false} >&times;</button>
                                    </div>
                                )}
                            {(!fileselected && placeimagepath !== 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/null' && placeimagepath !== 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' && placeimagepath?.trim()?.length > 0) && (
                                <div className="col-lg-2 col-md-3 col-6 mx-auto mt-2" >
                                    <img
                                        src={placeimagepath} className="img-fluid w-100" alt=''
                                    />
                                    <button onClick={() => removeImage()} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }} disabled={disabled ? true : false} >&times;</button>
                                </div>
                            )}

                        </div>

                    </div>

                    <div class=" d-flex justify-content-center mb-4">
                        {useradd === false && buttonaddeditText === "Add" ?
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={!useradd}>{buttonaddeditText}</button> :
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={editdisabled ? false : true}>{buttonaddeditText}</button>}
                        <button type="button" class="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick}  >Clear</button>
                    </div>
                    {/* <br /> */}
                    <div class="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    //defaultValue={serachoption[8]}
                                    options={serachoption}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}

                                />
                            </div>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input type="text" onKeyDown={onKeyDownHandler} id="btnSearchText" class="form-control" placeholder='Enter keyword' name="SearchValue" onChange={handleChangeSearchValue} value={searchvalue} disabled={serchdisable ? true : false} />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div class="p-2 text-center">
                                <button type="button" class="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleClickSearchValue} >Search</button>
                            </div>
                        </div>
                    </div>

                    <CustomTable
                        columns={columns}
                        data={locationlistdata}
                        PdfExport={PdfExport}
                        ExportToExcel={ExportToExcel}
                        disabled={!userexport}
                    />

                </form>
            </div >
        </>
    )
}

export default Locationmaster;


