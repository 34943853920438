import { useEffect } from "react";
import TimeInput from "react-time-picker-input";
import "react-time-picker-input/dist/components/TimeInput.css";
import "../../src/assets/css/commoncontrol.css";

const CustomTimePicker = (props) => {
    const {
        filedvalue,
        isDisable,
        handleOnChange,
        classid
    } = props

    const handleTimeChange = (e) => {
        handleOnChange(e);
    }

    const handleTimeIconClick = () => {
        window.addEventListener('click', handleopenclick);
    }

    const handleopenclick = () => {
        const clickpass = document.getElementsByClassName("inputWrapper")[0].childNodes[0];
        clickpass.focus();
        window.removeEventListener('click', handleopenclick);
    }
    useEffect(() => {
        if (isDisable) {
            const disablecolor = document.getElementsByClassName("react-time-input-picker-wrapper");

            for (const box of disablecolor) {
                // ✅ Add multiple classes
                box.classList.add("changecolor");
              
              }
        }
        else {
            const disablecolor = document.getElementsByClassName("react-time-input-picker-wrapper");

            for (const box of disablecolor) {
                // ✅ Add multiple classes
                box.classList.remove("changecolor");
              
              }
        }
    }, [isDisable]);

    return (
        <div className="text-container">
            <TimeInput id={classid} class="" value={filedvalue} disabled={isDisable ? true : false} allowDelete onChange={handleTimeChange} eachInputDropdown />
            {/* <span className="example-custom-input calendarBtn" id="calendarBtn1" onClick={handleTimeIconClick}><i class="fa fa-clock-o"></i></span> */}

        </div>
    );
}
export default CustomTimePicker;
