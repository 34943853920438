import React, { useMemo } from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from 'react-select';
import "../../assets/css/master.css"
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../services/ApiCallingUserServices';
import Switch from 'react-switch';
// import exportFromJSON from 'export-from-json'
import { generatePDF } from '../commonClass/CommonValidation'
import { useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../commonClass/Session';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../_actions/loader.actions";
import { ExportExcel } from '../commonClass/ExportExcel';
import RichTextBox from '../../customControl/RichTextBox';
import { htmlToText } from 'html-to-text';
import { ConfirmationModal } from '../../customControl/ConfirmationModal';
import CustomTable from '../../customControl/CustomTable';

const Policymaster = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // var store = useContext(ReactReduxContext).store.getState();
    const [postdata, setPostData] = useState([]);
    const [modalShow, setModalShow] = useState(false)
    const [activeId, setActiveId] = useState(false);
    const [policylistdata, setPolicyListData] = useState([]);
    const [buttonaddeditText, setButtonAddEditText] = useState("Add");
    const [disabled, setDisabled] = useState(false);
    const [editdisabled, setEditDisabled] = useState(true);
    const [serchdisable, setSerachDisable] = useState(false);
    const [filteritems, setFilterItems] = useState([]);
    const [policynameerrorMessage, setPolicyNameErrorMessage] = useState('');
    const [policynamelengerrorMessage, setPolicyNamelengErrorMessage] = useState('');
    const [policydetailserrorMessage, setPolicyDetailsErrorMessage] = useState('');
    const [policydetaillengserrorMessage, setPolicyDetailslengErrorMessage] = useState('');
    const [policyduplicateerrorMessage, setPolicyDuplicateErrorMessage] = useState('');
    const [retrivecomplete, setRetriveComplete] = useState(0);
    const [clrshow, setsclshow] = useState(false);
    const [richboxheight, setRichBoxHeight] = useState(false);
    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);
    const serachoption = [
        {
            label: 'Policy Name',
            value: '1'
        },
        // {
        //     label: 'Policy Details',
        //     value: '2'
        // },
        {
            label: 'Active',
            value: '3'
        },
        // {
        //     label: 'Created Date',
        //     value: '5'
        // },
        {
            label: 'All',
            value: '4'
        },

    ];

    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('4');
    const [formValue, setFormValue] = useState(
        {
            PolicyId: 1,
            PolicyName: '',
            PolicyDetails: '',
            IsActive: false,
        }
    );
    //////////////////////////////use effect section //////////////////////////////////
    //#region  use effect section

    useEffect(() => {

    }, [policynameerrorMessage, policynamelengerrorMessage, policydetailserrorMessage, policydetaillengserrorMessage]);
    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            // if (Window.$name && Window.$userToken) {
            getPolicyDataList().then(() => { dispatch(loaderActions.end()) });;
            setitems();

        }
        else {
            ClearLocalStorage();
            // alert('Your Session Expired. Please Login Again!');
            // navigate('/');
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, [])

    useEffect(() => {
        // let MENUID = GetMenuIdFromStorage()
        // let viewrights = false;
        // user?.filter(x => x.MenuId === MENUID).map((obj) => {
        //     viewrights = obj.URightView;
        //     setUserAdd(obj.URightAdd);
        //     setUserEdit(obj.URightEdit);
        //     setUserExport(obj.URightExport);
        //     if (!viewrights) {
        //         navigate('/dashboard');
        //     }
        // });
        // let MENUID = GetMenuIdFromStorage()
        // let mid = user?.find(x => x.MenuId === MENUID);
        let pagelink = user?.find(x => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);

        });
    }, [user])

    useEffect(() => {
    }, [postdata])

    //#endregion
    //////////////////////////////////////////////////////////////////////////////////////////////

    const validateData = () => {
        let error = []
        if (typeof formValue.PolicyName === 'string' && formValue.PolicyName?.trim()?.length === 0) {
            setPolicyNameErrorMessage('Please enter Policy name.')
            error.push('PolicyName')
        }
        if (typeof formValue.PolicyName === 'string' && formValue.PolicyName?.trim()?.length > 50) {
            setPolicyNamelengErrorMessage('Maximum length allowed for Policy name is 50 characters.');
            error.push('PolicyName')
        }
        if (typeof formValue.PolicyDetails === 'string' && formValue.PolicyDetails?.trim()?.length === 0) {
            setPolicyDetailsErrorMessage('Please enter Policy Details.');

            error.push('PolicyDetails')
        }
        // if (typeof formValue.PolicyDetails === 'string' && formValue.PolicyDetails?.trim()?.length > 5000) {
        //     setPolicyDetailslengErrorMessage('Maximum length allowed for Policy Details is 5000 characters.');
        //     error.push('PolicyDetails')
        // }
        document.getElementById(error[0]).focus();

    }
    const handleAddClick = async () => {
        let statusoftoken = await verifytoken();
        if (buttonaddeditText === "Add") {
            if (statusoftoken) {
                if (formValue.PolicyName?.trim()?.length === 0 || formValue.PolicyName?.trim()?.length > 50 || formValue.PolicyDetails?.trim()?.length === 0) {
                    validateData();
                    return;
                }
                else {
                    if ((typeof formValue.PolicyName === 'string' && formValue.PolicyName?.trim()?.length > 0) && (typeof formValue.PolicyDetails === 'string' && formValue.PolicyDetails?.trim()?.length > 0)) {
                        let filterdata;
                        filterdata = policylistdata?.filter(issec => issec.policyName.toLowerCase() === formValue.PolicyName.toLowerCase() && issec.policyDetails.toLowerCase() === formValue.PolicyDetails.toLowerCase());
                        if (filterdata?.length > 0) {
                            setPolicyDuplicateErrorMessage('You can not enter duplicate value.');
                            return;
                        }
                    }
                    //console.log('formvalue ', formValue)
                    if (statusoftoken) {
                        const PolicyData = await postUsersData("/master/v1/insertpolicy", formValue);
                        setPostData(PolicyData.data);
                        if (!PolicyData.status && !PolicyData.data) {
                            setPolicyDuplicateErrorMessage(PolicyData.message);
                            return;
                        }
                        if (PolicyData.status) {
                            if (PolicyData.data > 0) {
                                setitems();
                                dispatch({ type: 'ALERTING', message: PolicyData.message })
                                // alert('Policy master Record saved successfully');
                                //window.location.reload(false);
                                clear();

                            }
                            else {
                                dispatch({ type: 'ALERTING', message: PolicyData.message })
                                // alert('Policy master Record not saved successfully')
                                return;
                            }
                        }
                        else {
                            dispatch({ type: 'ALERTING', message: PolicyData.message })
                            // alert('Something went wrong!')
                            return;
                        }
                    }
                    else {
                        ClearLocalStorage();
                        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                    }
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
        else {
            if (statusoftoken) {
                if (formValue.PolicyName?.trim()?.length === 0 || formValue.PolicyName?.trim()?.length > 50 || formValue.PolicyDetails?.trim()?.length === 0) {
                    validateData();
                    return;
                }
                else {
                    if ((typeof formValue.PolicyName === 'string' && formValue.PolicyName.trim().length > 0) && (typeof formValue.PolicyDetails === 'string' && formValue.PolicyDetails.trim().length > 0)) {
                        let filterdata;
                        filterdata = policylistdata?.filter(issec => issec.policyId !== formValue.PolicyId && issec.policyName.toLowerCase() === formValue.PolicyName.toLowerCase() && issec.policyDetails.toLowerCase() === formValue.PolicyDetails.toLowerCase());
                        if (filterdata?.length > 0) {
                            setPolicyDuplicateErrorMessage('You can not enter duplicate value.');
                            return;
                        }
                    }
                    if (statusoftoken) {
                        const PolicyData = await patchUsersData("/master/v1/Updatepolicy", formValue);
                        setPostData(PolicyData.data);
                        if (!PolicyData.status && !PolicyData.data) {
                            setPolicyDuplicateErrorMessage(PolicyData.message);
                            return;
                        }
                        if (PolicyData.status) {
                            setitems();
                            dispatch({ type: 'ALERTING', message: PolicyData.message })
                            // alert('Policy master Record updated successfully');
                            //window.location.reload(false);
                            clear();
                        }
                        else {
                            dispatch({ type: 'ALERTING', message: PolicyData.message })
                            // alert('Something went wrong!' + 'err')
                        }
                    }
                    else {
                        ClearLocalStorage();
                        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                    }
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
    }
    const handleEditMode = (id) => {
        RetriveData(id);
        setDisabled(false);
        setEditDisabled(false);
        setSerachDisable(true);
        setButtonAddEditText("Update");
        RetriveData(id);
    }
    const handleViewMode = (id) => {
        setEditDisabled(false);
        setDisabled(true);
        setSerachDisable(true);
        RetriveData(id);
    }

    function RetriveData(id) {
        setPolicyNameErrorMessage('');
        setPolicyNamelengErrorMessage('');
        setPolicyDetailsErrorMessage('');
        setPolicyDetailslengErrorMessage('');
        setPolicyDuplicateErrorMessage('');
        policylistdata?.map(obj => {
            if (obj.policyId === id) {
                // let active = !obj.isActive;
                formValue.PolicyName = obj.policyName
                formValue.PolicyDetails = obj.policyDetails
                formValue.PolicyId = obj.policyId
                formValue.IsActive = obj.isActive
                setActiveId(obj.isActive);
            }
        });
        setFormValue({ ...formValue, formValue })
        setRetriveComplete(htmlToText(formValue.PolicyDetails).length);
    }
    //#region fill dropdown and handle input value
    const getPolicyDataList = async () => {
        const PolicyListdata = await getAllData("/master/v1/getallpolicy");
        //setDestinationListDataP(DestinationListData.data);
        setPolicyListData(PolicyListdata.data);
    }

    const onChange = (e) => {

        setFormValue({ ...formValue, [e.target.name]: e.target.value });

        setEditDisabled(true);
        setPolicyNameErrorMessage('');
        setPolicyNamelengErrorMessage('');
        setPolicyDetailsErrorMessage('');
        setPolicyDetailslengErrorMessage('');
        setPolicyDuplicateErrorMessage('');
    };
    // const handleisActive = (e) => {
    //     // {formValue.InActive = e.target.checked}
    //     { formValue.IsActive = e }
    //     setActiveId(!activeId)
    //     setEditDisabled(true);
    //     setPolicyNameErrorMessage('');
    //     setPolicyNamelengErrorMessage('');
    //     setPolicyDetailsErrorMessage('');
    //     setPolicyDetailslengErrorMessage('');
    //     setPolicyDuplicateErrorMessage('');
    // }
    const handleisActive = (e) => {
        if (buttonaddeditText === 'Add') {
            formValue.IsActive = e
            setActiveId(!activeId)
            setPolicyNameErrorMessage('');
            setPolicyNamelengErrorMessage('');
            setPolicyDetailsErrorMessage('');
            setPolicyDetailslengErrorMessage('');
            setPolicyDuplicateErrorMessage('');
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (e) => {
        //setYesNoConfirm(e)
        if (e === true) {
            formValue.IsActive = !activeId
            setActiveId(!activeId)
            setEditDisabled(true);
            setPolicyNameErrorMessage('');
            setPolicyNamelengErrorMessage('');
            setPolicyDetailsErrorMessage('');
            setPolicyDetailslengErrorMessage('');
            setPolicyDuplicateErrorMessage('');
        }
        setModalShow(false);
    }

    const handlePolicyDetailsChange = (e) => {
        const str = e.replace('<p><br></p>', '')
        formValue.PolicyDetails = str
        const html = htmlToText(str);
        if (html.length !== (retrivecomplete)) {
            setEditDisabled(true);
        }
    }

    const handleClearClick = () => {
        //window.location.reload();
        clear();

    }
    function clear() {

        setPolicyNameErrorMessage('');
        setPolicyNamelengErrorMessage('');
        setPolicyDetailsErrorMessage('');
        setPolicyDetailslengErrorMessage('');
        setPolicyDuplicateErrorMessage('');
        // setFormValue({ ...formValue, ['PolicyName']: '', ['PolicyDetails']: '', ['IsActive']: false });
        setFormValue({ ...formValue, PolicyName: '', PolicyDetails: '', IsActive: false });
        setActiveId(false);
        //{ formValue.InActive = false}
        getPolicyDataList();
        setButtonAddEditText('Add');
        setDisabled(false);
        setEditDisabled(true);
        setSerachDisable(false);
        setSerachBy('4');
        setSearchValue('');
        setsclshow(false)
        if (document.querySelector('.jodit-wysiwyg') !== null) {
            document.querySelector('.jodit-wysiwyg').innerHTML = '';
        }

    }
    //#endregion

    //#region table Pagination
    const columns = [
        // {
        //     name: 'Sr No',
        //     minWidth: "8%",
        //     selector: (location) => location.destinationId,

        // },
        {
            name: 'Policy Name',
            sortable: true,
            float: "left",
            selector: (policy) => policy.policyName,
            // style:  { backgroundColor: 'green' }
        },

        {
            name: 'Policy Details',
            sortable: true,
            float: "left",
            selector: (policy) => policy.policyDetails,
            cell: (policy) => <div className="text-muted mb-0 defualt-unstyled" dangerouslySetInnerHTML={{ __html: policy.policyDetails }} />,
            omit: true,
        },
        {
            name: 'Active',
            sortable: true,
            float: "left",
            // selector: location => <td style={{ textAlign: "center", width: "5%" }}>
            //     {`${location.inActive ? 'Active' : 'InActive'}`}
            // </td>

            selector: (policy) => policy.isActive ? 'Active' : 'InActive'
        },
        {
            name: 'Created Date',
            sortable: true,
            float: "left",
            selector: (policy) => formatDate(policy.createdDate)
        },
        {
            name: 'Edit',
            // float:"right",
            //selector: location => <td style={{ textAlign: "center", width: "5%", cursor:"pointer" }} onClick={() => handleEditMode(location.destinationId)}> <i className="fa fa-edit"></i></td >
            selector: (policy) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEditMode(policy.policyId)}></i></button>
        },
        {
            name: 'View',
            // float:"right",
            //selector: location => <td style={{ textAlign: "center", width: "5%", cursor:"pointer" }} onClick={() => handleViewMode(location.destinationId)}><i className="fa fa-eye"></i></td>
            selector: (policy) => <i className="fa fa-eye" onClick={() => handleViewMode(policy.policyId)}></i>
        }
    ];
    //#endregion

    //#region handle search value////////////////////////////////////////////////////////
    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }

    const setitems = async () => {
        const PolicyListData = await getAllData("/master/v1/getallpolicy");
        setFilterItems(PolicyListData.data);
    }

    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value);
        setsclshow(true)
        if (e.target.value === '') {
            getPolicyDataList();
            setsclshow(false)
        }

    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleClickSearchValue();
        }
    };

    const handleClickSearchValue = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            setitems();
            var filteritem = filteritems;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec.policyName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec.policyDetails.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '5') {
                filterdata = filteritem?.filter(issec => formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '3') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.isActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.isActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.isActive === false);
                }
                // else {
                //     filterdata = filteritem;
                // }
                else {
                    filterdata = filteritem?.filter(issec => formatDate(issec.createdDate).toLowerCase().includes("%#$&%^"));
                }
            }
            else if (searchby === '4') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.policyName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.policyName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.policyDetails.toLowerCase().includes(searchvalue.toLowerCase()) || issec.policyDetails.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.policyName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.policyName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.policyDetails.toLowerCase().includes(searchvalue.toLowerCase()) || issec.policyDetails.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.policyName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.policyName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.policyDetails.toLowerCase().includes(searchvalue.toLowerCase()) || issec.policyDetails.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.policyName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.policyName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.policyDetails.toLowerCase().includes(searchvalue.toLowerCase()) || issec.policyDetails.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => issec.policyName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.policyName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.policyDetails.toLowerCase().includes(searchvalue.toLowerCase()) || issec.policyDetails.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()));
                }
            }
            setPolicyListData(filterdata)
        }
    }
    //#endregion

    //#region handle export section ////////////////////////////////////////////////////
    function PdfExport() {
        //print
        const columns = [
            "Policy Name",
            "Policy Details",
            "Active",
            "Created Date"
        ];
        var rows = [];

        for (let i = 0; i < policylistdata?.length; i++) {
            var temp = [
                policylistdata[i].policyName,
                htmlToText(policylistdata[i].policyDetails),
                `${policylistdata[i].isActive ? 'Active' : 'InActive'}`,
                formatDate(policylistdata[i].createdDate)
            ];
            rows = [...rows, temp]
        }
        generatePDF(columns, rows, "PolicyMaster", "Policy Master")
    }
    // function ExportToExcel() {
    //     const fields = ['policyName', 'policyDetails', 'isActive'];
    //     const data = policylistdata;
    //     const fileName = 'Policymasterexcel'
    //     const exportType = 'xls'

    //     exportFromJSON({ data, fileName, fields, exportType })
    // }

    const ExportToExcel = () => {
        const dispalyname = ['Policy Name', 'Policy Details', 'Active', 'Created Date'];
        const fields = ['policyName', 'policyDetails', 'isActive', 'createdDate'];
        ExportExcel(dispalyname, fields, policylistdata, "PolicyMaster", "PolicyMaster.xls");
    }



    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getPolicyDataList();
    }
    //#endregion ///////////////////////////////////////////////////////////////////////

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    useEffect(() => {
        const richtexteboxheight = document.getElementById("layoutSidenav_content").clientWidth
        if (richtexteboxheight > 912) {
            setRichBoxHeight(true)
        }
        else {
            setRichBoxHeight(false)
        }
    }, []);

    const policydetailsconfig = useMemo(
        () => ({
            readonly: false, // all options from https://xdsoft.net/jodit/doc/,
            height: '100%',
            maxHeight: richboxheight === true ? 200 : 500,
            width: '100%',
            statusbar: false,
            disablePlugins: ["source"],
            removeButtons: ["about", "image", "link", "select", "video"],
            toolbarAdaptive: false,
            //autofocus: true,
            defaultMode: "1",
            disabled: disabled ? true : false
        }),
        [disabled, richboxheight]
    );

    return (
        <>
            <div class="container mt-4 heading_color">
                <h3>Policy Master</h3>
            </div>
            <div class="container form_border py-5 my-3">
                <form action="" >
                    <div class="mb-3 row mx-1">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="row">
                                <div className='col-md-12'>
                                    <div className='row'>
                                        {policyduplicateerrorMessage && <div className="text-danger pb-3 text-center"> {policyduplicateerrorMessage} </div>}
                                        <div class="col-lg-3 col-md-12 col-sm-12 mb-lg-1">
                                            <label for="inputPassword6" class="col-form-label required">Policy Name</label>
                                        </div>
                                        <div class=" col-lg-9 col-md-12 col-sm-12 mb-lg-1">
                                            <input type="text" id="PolicyName" class="form-control"
                                                name="PolicyName"
                                                value={formValue.PolicyName}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                                placeholder="Policy Name"
                                            />
                                            {policynameerrorMessage && <div className="text-danger"> {policynameerrorMessage} </div>}
                                            {policynamelengerrorMessage && <div className="text-danger"> {policynamelengerrorMessage} </div>}
                                        </div>
                                        <div class="col-lg-3 col-md-12 col-sm-12 mb-lg-1">
                                            <label for="inputPassword6" class="col-form-label required">Policy Details</label>
                                        </div>
                                        <div class=" col-lg-9 col-md-12 col-sm-12 mb-lg-1">

                                            <RichTextBox field="PolicyDetails" fieldvalue={formValue.PolicyDetails} tabIndex={1} OnChangeofRichBox={handlePolicyDetailsChange} textconfig={policydetailsconfig} />
                                            {policydetailserrorMessage && <div className="text-danger"> {policydetailserrorMessage} </div>}
                                            {policydetaillengserrorMessage && <div className="text-danger"> {policydetaillengserrorMessage} </div>}
                                        </div>
                                        <div class="col-lg-3 col-md-12 col-sm-12">
                                            <label for="inputPassword6" class="col-form-label">Active</label>
                                        </div>
                                        <div class="col-auto col-lg-6 " disabled={false}>

                                            <Switch
                                                onChange={handleisActive}
                                                checked={activeId}
                                                className="react-switch"
                                                disabled={disabled ? true : false}
                                            />
                                        </div>
                                        {/* <div class=" col-auto col-lg-4"> */}
                                        <ConfirmationModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            noconfirm={() => handleConfirmation(false)}
                                            yesconfirm={() => handleConfirmation(true)}
                                        />

                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center mb-4">
                        {useradd === false && buttonaddeditText === "Add" ?
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={!useradd}>{buttonaddeditText}</button> :
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={editdisabled ? false : true}>{buttonaddeditText}</button>}
                        <button type="button" class="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick} >Clear</button>
                    </div>
                    <div class="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}
                                />
                            </div>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input type="text" onKeyDown={onKeyDownHandler} id="btnSearchText" class="form-control" placeholder='Enter keyword' name="SearchValue" onChange={handleChangeSearchValue} value={searchvalue} disabled={serchdisable ? true : false} />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div class="p-2 text-center">
                                <button type="button" class="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleClickSearchValue} >Search</button>
                            </div>
                        </div>

                    </div>

                    <CustomTable
                        columns={columns}
                        data={policylistdata}
                        PdfExport={PdfExport}
                        ExportToExcel={ExportToExcel}
                        disabled={!userexport}
                    />
                </form>
            </div>
        </>
    )
}

export default Policymaster