import { errorConstants } from "../_constants/error.constants";

const initialValues = {
    isError: true,
    isSubmitting: false,
    resetallstatus: true,

    isInclusion: false,
    isExclusion: false,
    isPolicy: false,
    isOverView: false,
    isAboutPlace: false,
    isItinerary: false,
    isPricing: false,

    inclusionCount: 0,
    exclusionCount: 0,
    policyCount: 0,
    aboutplaceCount: 0,
    ItineraryCount: 0,

    overviewduration: 0,
    overviewdurationfrom: 0,
    overviewdurationto: 0,
    overviewvisitfrom: 0,
    overviewvisitto: 0,
    overviewtheme: 0,

    overviewcitydetail: 0,
    overviewnightpending: 0,
    overviewcity: 0,
    overviewnight: 0,

    pricingSupplier: 0,
    pricingTransfer: 0,
    pricingJoiningCost: 0,
    pricingOperationCost: 0,
    pricingTour: 0,
    pricingChildren: 0,
}
export function errorStatus(state = initialValues, actions) {
    switch (actions.type) {
        case errorConstants.ERROR_TRUE: return { ...state, isError: true }
        case errorConstants.ERROR_FALSE: return { ...state, isError: false }
        case errorConstants.SUBMITE_STATUS_TRUE: return { ...state, isSubmitting: true }
        case errorConstants.SUBMITE_STATUS_FALSE: return { ...state, isSubmitting: false }

        case errorConstants.RESET_ALL_STATUS_FLAG: return {
            ...state, isInclusion: false, isExclusion: false, isOverView: false,
            isPolicy: false, isAboutPlace: false, isError: false, isItinerary: false, isPricing: false
        }

        case errorConstants.INCLUSION_STATUS_TRUE: return { ...state, isInclusion: true }
        case errorConstants.INCLUSION_STATUS_FALSE: return { ...state, isInclusion: false }
        case errorConstants.EXCLUSION_STATUS_TRUE: return { ...state, isExclusion: true }
        case errorConstants.EXCLUSION_STATUS_FALSE: return { ...state, isExclusion: false }
        case errorConstants.POLICY_STATUS_TRUE: return { ...state, isPolicy: true }
        case errorConstants.POLICY_STATUS_FALSE: return { ...state, isPolicy: false }
        case errorConstants.ABOUTPLACE_STATUS_TRUE: return { ...state, isAboutPlace: true }
        case errorConstants.ABOUTPLACE_STATUS_FALSE: return { ...state, isAboutPlace: false }
        case errorConstants.ITINERARY_STATUS_TRUE: return { ...state, isItinerary: true }
        case errorConstants.ITINERARY_STATUS_FALSE: return { ...state, isItinerary: false }


        case errorConstants.OVERVIEW_STATUS_TRUE: return { ...state, isOverView: true }
        case errorConstants.OVERVIEW_STATUS_FALSE: return { ...state, isOverView: false }

        case errorConstants.INCLUSION_INCREMENT_COUNTER: return { ...state, inclusionCount: state.inclusionCount + 1 }
        case errorConstants.EXCLUSION_INCREMENT_COUNTER: return { ...state, exclusionCount: state.exclusionCount + 1 }
        case errorConstants.POLICY_INCREMENT_COUNTER: return { ...state, policyCount: state.policyCount + 1 }
        case errorConstants.ABOUTPLACE_INCREMENT_COUNTER: return { ...state, aboutplaceCount: state.aboutplaceCount + 1 }
        case errorConstants.ITINERARY_INCREMENT_COUNTER: return { ...state, ItineraryCount: state.ItineraryCount + 1 }

        case errorConstants.INCLUSION_DECREMENT_COUNTER: return { ...state, inclusionCount: state.inclusionCount - 1 }
        case errorConstants.EXCLUSION_DECREMENT_COUNTER: return { ...state, exclusionCount: state.exclusionCount - 1 }
        case errorConstants.POLICY_DECREMENT_COUNTER: return { ...state, policyCount: state.policyCount - 1 }
        case errorConstants.ABOUTPLACE_DECREMENT_COUNTER: return { ...state, aboutplaceCount: state.aboutplaceCount - 1 }
        case errorConstants.ITINERARY_DECREMENT_COUNTER: return { ...state, ItineraryCount: state.ItineraryCount - 1 }

        case errorConstants.INCLUSION_RESET_COUNTER: return { ...state, inclusionCount: 0 }
        case errorConstants.EXCLUSION_RESET_COUNTER: return { ...state, exclusionCount: 0 }
        case errorConstants.POLICY_RESET_COUNTER: return { ...state, policyCount: 0 }
        case errorConstants.ABOUTPLACE_RESET_COUNTER: return { ...state, aboutplaceCount: 0 }
        case errorConstants.ITINERARY_RESET_COUNTER: return { ...state, ItineraryCount: 0 }

        case errorConstants.OVERVIEW_DURATION_COUNTER: return { ...state, overviewduration: 1 }
        case errorConstants.OVERVIEW_DURATION_RESET_COUNTER: return { ...state, overviewduration: 0 }

        case errorConstants.OVERVIEW_DURATIONFROM_COUNTER: return { ...state, overviewdurationfrom: 1 }
        case errorConstants.OVERVIEW_DURATIONFROM_RESET_COUNTER: return { ...state, overviewdurationfrom: 0 }

        case errorConstants.OVERVIEW_DURATIONTO_COUNTER: return { ...state, overviewdurationto: 1 }
        case errorConstants.OVERVIEW_DURATIONTO_RESET_COUNTER: return { ...state, overviewdurationto: 0 }

        case errorConstants.OVERVIEW_VISITFROM_COUNTER: return { ...state, overviewvisitfrom: 1 }
        case errorConstants.OVERVIEW_VISITFROM_RESET_COUNTER: return { ...state, overviewvisitfrom: 0 }

        case errorConstants.OVERVIEW_VISITTO_COUNTER: return { ...state, overviewvisitto: 1 }
        case errorConstants.OVERVIEW_VISITTO_RESET_COUNTER: return { ...state, overviewvisitto: 0 }

        case errorConstants.OVERVIEW_THEME_COUNTER: return { ...state, overviewtheme: 1 }
        case errorConstants.OVERVIEW_THEME_RESET_COUNTER: return { ...state, overviewtheme: 0 }

        case errorConstants.OVERVIEW_CITY_DETAIL_COUNTER: return { ...state, overviewcitydetail: 1 }
        case errorConstants.OVERVIEW_CITY_DETAIL_RESET_COUNTER: return { ...state, overviewcitydetail: 0 }

        case errorConstants.OVERVIEW_NIGHT_PENDING_COUNTER: return { ...state, overviewnightpending: 1 }
        case errorConstants.OVERVIEW_NIGHT_PENDING_RESET_COUNTER: return { ...state, overviewnightpending: 0 }


        case errorConstants.PRICING_STATUS_TRUE: return { ...state, isPricing: true }
        case errorConstants.PRICING_STATUS_FALSE: return { ...state, isPricing: false }

        case errorConstants.PRICING_SUPPLIER: return { ...state, pricingSupplier: 1 }
        case errorConstants.PRICING_SUPPLIER_RESET: return { ...state, pricingSupplier: 0 }

        case errorConstants.PRICING_TRANSFER: return { ...state, pricingTransfer: 1 }
        case errorConstants.PRICING_TRANSFER_RESET: return { ...state, pricingTransfer: 0 }

        case errorConstants.PRICING_JOINING_COST: return { ...state, pricingJoiningCost: 1 }
        case errorConstants.PRICING_JOINING_COST_RESET: return { ...state, pricingJoiningCost: 0 }

        case errorConstants.PRICING_OPERATION_COST: return { ...state, pricingOperationCost: 1 }
        case errorConstants.PRICING_OPERATION_COST_RESET: return { ...state, pricingOperationCost: 0 }

        case errorConstants.PRICING_TOUR: return { ...state, pricingTour: 1 }
        case errorConstants.PRICING_TOUR_RESET: return { ...state, pricingTour: 0 }

        case errorConstants.PRICING_CHILDREN: return { ...state, pricingChildren: 1 }
        case errorConstants.PRICING_CHILDREN_RESET: return { ...state, pricingChildren: 0 }

        case errorConstants.RESET_ALL_COUNTERS: return {
            ...state, inclusionCount: 0,
            exclusionCount: 0,
            policyCount: 0,
            aboutplaceCount: 0,
            ItineraryCount: 0,

            overviewduration: 0,
            overviewdurationfrom: 0,
            overviewdurationto: 0,
            overviewvisitfrom: 0,
            overviewvisitto: 0,
            overviewtheme: 0,
            overviewcitydetail: 0,
            overviewnightpending: 0,
            overviewcity: 0,
            overviewnight: 0,

            pricingSupplier: 0,
            pricingTransfer: 0,
            pricingJoiningCost: 0,
            pricingOperationCost: 0,
            pricingTour: 0,
            pricingChildren: 0,
        }

        default: return state;
    }
}