import { errorConstants } from "../_constants/error.constants";

export const errorActions = {
    start,
    end,
    submitstart,
    submitend,
    resetallstatusflag,

    inclusionstart,
    inclusionend,
    exclusionstart,
    exclusionend,
    policystart,
    policyend,
    aboutplacestart,
    aboutplaceend,
    overviewstart,
    overviewend,
    itinerarystart,
    itineraryend,

    inclusionincrement,
    exclusionincrement,
    ploicyincrement,
    aboutplaceincrement,
    itineraryincrement,

    inclusiondecrement,
    exclusiondecrement,
    policydecrement,
    aboutplacedecrement,
    itinerarydecrement,

    inclusionreset,
    exclusionreset,
    policyreset,
    aboutplacereset,
    itineraryreset,

    overviewdurationset,
    overviewdurationreset,
    overviewdurationfromset,
    overviewdurationfromreset,
    overvievdurationtoset,
    overviewdurationtoreset,
    overviewvisitfromset,
    overviewvisitfromreset,
    overviewvisittoset,
    overviewvisittoreset,
    overviewthemeset,
    overviewthemereset,

    overviewcitydetailset,
    overviewcitydetailreset,
    overviewnightpendingset,
    overviewnightpendingreset,

    pricingtart,
    pricingend,
    pricingsupplier,
    pricingsupplierreset,
    pricingtransfer,
    pricingtransferreset,
    pricingjoiningcost,
    pricingjoiningcostreset,
    pricingoperationcost,
    pricingoperationcostreset,
    pricingtour,
    pricingtourreset,
    pricingchildren,
    pricingchildrenreset,

    resetallcounter
};

function start() {
    return { type: errorConstants.ERROR_TRUE };
}

function end() {
    return { type: errorConstants.ERROR_FALSE };
}
function submitstart() {
    return { type: errorConstants.SUBMITE_STATUS_TRUE };
}

function submitend() {
    return { type: errorConstants.SUBMITE_STATUS_FALSE };
}
function resetallstatusflag() {
    return { type: errorConstants.RESET_ALL_STATUS_FLAG };
}

function inclusionstart() {
    return { type: errorConstants.INCLUSION_STATUS_TRUE };
}

function inclusionend() {
    return { type: errorConstants.INCLUSION_STATUS_FALSE };
}
function exclusionstart() {
    return { type: errorConstants.EXCLUSION_STATUS_TRUE };
}

function exclusionend() {
    return { type: errorConstants.EXCLUSION_STATUS_FALSE };
}

function policystart() {
    return { type: errorConstants.POLICY_STATUS_TRUE };
}

function policyend() {
    return { type: errorConstants.POLICY_STATUS_FALSE };
}

function aboutplacestart() {
    return { type: errorConstants.ABOUTPLACE_STATUS_TRUE };
}

function aboutplaceend() {
    return { type: errorConstants.ABOUTPLACE_STATUS_FALSE };
}

function overviewstart() {
    return { type: errorConstants.OVERVIEW_STATUS_TRUE };
}

function overviewend() {
    return { type: errorConstants.OVERVIEW_STATUS_FALSE };
}
function itinerarystart() {
    return { type: errorConstants.ITINERARY_STATUS_TRUE };
}

function itineraryend() {
    return { type: errorConstants.ITINERARY_STATUS_FALSE };
}


function inclusionincrement() {
    return { type: errorConstants.INCLUSION_INCREMENT_COUNTER }
}
function exclusionincrement() {
    return { type: errorConstants.EXCLUSION_INCREMENT_COUNTER }
}
function ploicyincrement() {
    return { type: errorConstants.POLICY_INCREMENT_COUNTER }
}
function aboutplaceincrement() {
    return { type: errorConstants.ABOUTPLACE_INCREMENT_COUNTER }
}
function itineraryincrement() {
    return { type: errorConstants.ITINERARY_INCREMENT_COUNTER }
}


function inclusiondecrement() {
    return { type: errorConstants.INCLUSION_DECREMENT_COUNTER }
}
function exclusiondecrement() {
    return { type: errorConstants.EXCLUSION_DECREMENT_COUNTER }
}
function policydecrement() {
    return { type: errorConstants.POLICY_DECREMENT_COUNTER }
}
function aboutplacedecrement() {
    return { type: errorConstants.ABOUTPLACE_DECREMENT_COUNTER }
}
function itinerarydecrement() {
    return { type: errorConstants.ITINERARY_DECREMENT_COUNTER }
}

function inclusionreset() {
    return { type: errorConstants.INCLUSION_RESET_COUNTER }
}
function exclusionreset() {
    return { type: errorConstants.EXCLUSION_RESET_COUNTER }
}
function policyreset() {
    return { type: errorConstants.POLICY_RESET_COUNTER }
}
function aboutplacereset() {
    return { type: errorConstants.ABOUTPLACE_RESET_COUNTER }
}
function itineraryreset() {
    return { type: errorConstants.ITINERARY_RESET_COUNTER }
}

function overviewdurationset() {
    return { type: errorConstants.OVERVIEW_DURATION_COUNTER }
}
function overviewdurationreset() {
    return { type: errorConstants.OVERVIEW_DURATION_RESET_COUNTER }
}
function overviewdurationfromset() {
    return { type: errorConstants.OVERVIEW_DURATIONFROM_COUNTER }
}
function overviewdurationfromreset() {
    return { type: errorConstants.OVERVIEW_DURATIONFROM_RESET_COUNTER }
}
function overvievdurationtoset() {
    return { type: errorConstants.OVERVIEW_DURATIONTO_COUNTER }
}
function overviewdurationtoreset() {
    return { type: errorConstants.OVERVIEW_DURATIONTO_RESET_COUNTER }
}
function overviewvisitfromset() {
    return { type: errorConstants.OVERVIEW_VISITFROM_COUNTER }
}
function overviewvisitfromreset() {
    return { type: errorConstants.OVERVIEW_VISITFROM_RESET_COUNTER }
}
function overviewvisittoset() {
    return { type: errorConstants.OVERVIEW_VISITTO_COUNTER }
}
function overviewvisittoreset() {
    return { type: errorConstants.OVERVIEW_VISITTO_RESET_COUNTER }
}
function overviewthemeset() {
    return { type: errorConstants.OVERVIEW_THEME_COUNTER }
}
function overviewthemereset() {
    return { type: errorConstants.OVERVIEW_THEME_RESET_COUNTER }
}

function overviewcitydetailset() {
    return { type: errorConstants.OVERVIEW_CITY_DETAIL_COUNTER }
}
function overviewcitydetailreset() {
    return { type: errorConstants.OVERVIEW_CITY_DETAIL_RESET_COUNTER }
}
function overviewnightpendingset() {
    return { type: errorConstants.OVERVIEW_NIGHT_PENDING_COUNTER }
}
function overviewnightpendingreset() {
    return { type: errorConstants.OVERVIEW_NIGHT_PENDING_RESET_COUNTER }
}


function pricingtart() {
    return { type: errorConstants.PRICING_STATUS_TRUE };
}
function pricingend() {
    return { type: errorConstants.PRICING_STATUS_FALSE };
}
function pricingsupplier() {
    return { type: errorConstants.PRICING_SUPPLIER }
}
function pricingsupplierreset() {
    return { type: errorConstants.PRICING_SUPPLIER_RESET }
}
function pricingtransfer() {
    return { type: errorConstants.PRICING_TRANSFER }
}
function pricingtransferreset() {
    return { type: errorConstants.PRICING_TRANSFER_RESET }
}
function pricingjoiningcost() {
    return { type: errorConstants.PRICING_JOINING_COST }
}
function pricingjoiningcostreset() {
    return { type: errorConstants.PRICING_JOINING_COST_RESET }
}
function pricingoperationcost() {
    return { type: errorConstants.PRICING_OPERATION_COST }
}
function pricingoperationcostreset() {
    return { type: errorConstants.PRICING_OPERATION_COST_RESET }
}
function pricingtour() {
    return { type: errorConstants.PRICING_TOUR }
}
function pricingtourreset() {
    return { type: errorConstants.PRICING_TOUR_RESET }
}
function pricingchildren() {
    return { type: errorConstants.PRICING_CHILDREN }
}
function pricingchildrenreset() {
    return { type: errorConstants.PRICING_CHILDREN_RESET }
}

function resetallcounter() {
    return { type: errorConstants.RESET_ALL_COUNTERS }
}
