import React, { useRef, useMemo } from "react";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import {
  getAllData,
  postUsersData,
  verifytoken,
} from "../../../../services/ApiCallingUserServices";
import { onlyNumeric } from "../../../commonClass/CommonValidation";
import {
  ClearLocalStorage,
  GetLocalStorage,
} from "../../../commonClass/Session";
import { loaderActions } from "../../../../_actions/loader.actions";
import { useDispatch } from "react-redux";
import CustomSelection from "../../../../customControl/CustomSelection";

export default function Pricing({
  disabled,
  attraction,
  editdisabled,
  clearDisable,
  viewtype,
  attractionId,
  attractionsList,
  userRights,
}) {
  const Clone =
    viewtype !== "Add" && attraction
      ? {
        AttractionBasicId: attraction?.AttractionBasicId || 1,
        SupplierId: attraction?.SupplierId || "",
        PassengerTypeId: attraction?.PassengerTypeId || 1,
        Adult: attraction?.Adult || "",
        ChildUpto6Year: attraction?.ChildUpto6Year || "",
        Child6to12Year: attraction?.Child6to12Year || "",
        FacilityCollection: attraction?.FacilityCollection || [],
        CancellationPolicyId: attraction?.CancellationPolicyId || 1,
        CancellationTypeId: attraction?.type || "",
        NoShow: attraction?.NoShow || "",
        Upto24hrs: attraction?.Upto24hrs || "",
        Upto48hrs: attraction?.Upto48hrs || "",
        Morethan48hrs: attraction?.Morethan48hrs || "",
        IsActive: true,
        UserId: 25,
      }
      : {
        AttractionBasicId: 1,
        SupplierId: "",
        PassengerTypeId: 1,
        Adult: "",
        ChildUpto6Year: "",
        Child6to12Year: "",
        FacilityCollection: [],
        CancellationPolicyId: 1,
        CancellationTypeId: "",
        NoShow: "",
        Upto24hrs: "",
        Upto48hrs: "",
        Morethan48hrs: "",
        IsActive: true,
        UserId: 25,
      };
  const [savedisabled, setSaveDisabled] = useState(true);
  const [clearbuttondisable, setClearButtonDisable] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const supplierId = useRef(null);
  const nameId = useRef(null);
  const priceId = useRef(null);
  const [globleError, setGlobalError] = useState("");
  const [supplierActiveDisable, setsupplierActiveDisable] = useState({
    isActive: true,
  });
  const [buttonaddeditText, setbuttonaddeditText] = useState('Add');
  const [isEditDisable, setIsEditDisable] = useState(editdisabled);
  const [formValue, setFormValue] = useState(Clone);

  const [PersonDetails, setPersonDetails] = useState({
    facilityName: "",
    price: "",
    seqNo: "",
  });

  //-- from CreateTripSupplieer
  const [suppliers, setSuppliers] = useState([]);
  const [passengerType, setpassengerType] = useState([]);
  const [Cancelll, setCancelll] = useState([]);
  const [cancellationType, setcancellationTypeList] = useState([]);
  const getAddonSupplier = async () => {

    let statusoftoken = await verifytoken();
    if (statusoftoken) {

      const Supp = await getAllData("/master/v1/getothermaster");

      setpassengerType(Supp.data[0].passengerTypeList);
      setSuppliers(Supp.data[0].supplierMasterList);
      setCancelll(Supp.data[0].cancellationPolicyList);
      setcancellationTypeList(Supp.data[0].cancellationTypeList);

      if (buttonaddeditText !== "Add" && attraction) {
        const current = Supp.data[0].supplierMasterList?.find(
          (e) => e.supplierId === attraction.supplierId
        );
        setsupplierActiveDisable({
          label: current?.supplierName,
          value: current?.supplierId,
          code: current?.supplierCode,
          isActive: buttonaddeditText === "Add" ? false : current?.isActive,
        });
      }

      dispatch(loaderActions.end());
    }
    else {
      ClearLocalStorage();
      dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
    }
  };

  useEffect(() => {
    dispatch(loaderActions.start());
    getAddonSupplier();
    // let token = GetLocalStorage();
    // if (token && token.userName && token.token) {
    // } else {
    //   ClearLocalStorage();
    //   dispatch({
    //     type: "SESSIONOUT",
    //     message: "Your Session Expired. Please Login Again!",
    //   });
    // }
  }, [attractionId, attraction]);

  const [formError, setFormError] = useState({
    codeerror: "",
    Oname: "",
    Oprice: "",
    exist: "",
  });

  let errors = {
    codeerror: "",
    Oname: "",
    Oprice: "",
    exist: "",
  };

  const onChangePersonDetails = (e) => {
    setIsEditDisable(false);
    setFormError({ codeerror: "", Oname: "", Oprice: "", exist: "" });
    errors.codeerror = "";
    errors.Oname = "";
    errors.Oprice = "";
    var result;

    if (e.target.name === "facilityName") {
      formError.Oname = "";
      errors.Oname = "";
      setPersonDetails((prev) => ({ ...prev, facilityName: e.target.value }));
    }
    if (e.target.name === "price") {
      formError.Oprice = "";
      errors.Oprice = "";
      result = onlyNumeric(e.target.value);

      if (result !== 0) {
        setPersonDetails((prev) => ({ ...prev, price: Number(result) }));
      }
      if (result === "" || result === 0 || result === null) {
        setPersonDetails((prev) => ({ ...prev, price: "" }));
      }
    }
  };
  const [OtherFacility2, setOtherFacility2] = useState([]);

  const onClickOtherFacilityAdd = () => {
    setFormError({ codeerror: "", Oname: "", Oprice: "", exist: "" });
    let error = [];
    if (PersonDetails.facilityName !== "" && (PersonDetails.price === "" || PersonDetails.price === null)) {
      errors.Oprice = "Kindly enter the Price";
      error.push("price");
      setFormError(errors);
      priceId.current.focus();
      document.getElementById(error[0]).focus();
      return;
    }
    if (PersonDetails.price !== "" && (PersonDetails.facilityName === "" || PersonDetails.facilityName === null)) {
      errors.Oname = "Kindly enter the Name";
      error.push("name");
      setFormError(errors);
      nameId.current.focus();
      document.getElementById(error[0]).focus();
      return;
    }
    if ((PersonDetails.price === "" || PersonDetails.price === null) && (PersonDetails.facilityName === "" || PersonDetails.facilityName === null)) {
      errors.Oprice = "Kindly enter the Price";
      error.push("price");
      errors.Oname = "Kindly enter the Name";
      error.push("name");
      nameId.current.focus();
      setFormError(errors);
      return;
    }
    if (formValue.FacilityCollection.length > 0) {
      if (formValue.FacilityCollection?.filter(IsDup => IsDup.facilityName === PersonDetails.facilityName)?.length !== 0) {
        errors.exist = 'Other facility name already exist'
        setFormError(errors);
        return;
      }
    }

    let x = formValue.FacilityCollection;
    let actualdata = { ...PersonDetails, seqNo: x.length + 1 };

    setFormValue({
      ...formValue,
      FacilityCollection: [...x, actualdata],
    });
    setPersonDetails({ facilityName: "", price: "", seqNo: "" });
  };

  useEffect(() => {
    // Set OtherFacility2 after formValue is initialized

    setOtherFacility2(formValue.FacilityCollection);
  }, [formValue, attraction, buttonaddeditText]);

  const onChangePersonAgeDetails = (e) => {
    setIsEditDisable(false);
    setFormError({ codeerror: "", Oname: "", Oprice: "", exist: "" });
    errors.codeerror = "";
    errors.Oname = "";
    errors.Oprice = "";
    var result;

    result = onlyNumeric(e.target.value) === '' ? '' : Number(onlyNumeric(e.target.value));

    if (result !== 0) {
      setFormValue({ ...formValue, [e.target.name]: result });
    }
    if (result === 0 && formValue[e.target.name] !== "") {
      setFormValue({ ...formValue, [e.target.name]: "" });
    }
    if (result === 0 && formValue[e.target.name] === "") {
      setFormValue({ ...formValue, [e.target.name]: 0 });
    }
  };

  const onClickAddOtherFacilityRemove = (seqNo) => {
    setFormError({ codeerror: "", Oname: "", Oprice: "", exist: "" });
    setIsEditDisable(false);
    let x = formValue.FacilityCollection;
    const planningNights = x?.reduce((acc, night) => {
      if (night.seqNo !== seqNo) {
        acc = [...acc, { ...night, seqNo: acc.length + 1 }];
      }
      return acc;
    }, []);
    setFormValue((prev) => ({
      ...prev,
      FacilityCollection: planningNights,
    }));

    // setFormValue({
    //   ...formValue,
    //   FacilityCollection: formValue.FacilityCollection.filter(
    //     (not, index) => index !== id
    //   ),
    // });
  };
  useEffect(() => { }, [formValue.Adult]);
  //#region validations
  const validateData = (formValue) => {
    let error = [];
    let y = false;
    if (formValue.SupplierId === 0) {
      errors.codeerror = "Kindly select the Supplier";
      error.push("supplierId");
      y === false && supplierId.current.focus();
      y = true;
    }
    if (formValue.SupplierId?.length === 0) {
      errors.codeerror = "Kindly select the Supplier";
      error.push("supplierId");
      y === false && supplierId.current.focus();
      y = true;
    }
    if (
      PersonDetails.facilityName !== "" &&
      (PersonDetails.price === "" || PersonDetails.price === null)
    ) {
      errors.Oprice = "Kindly enter the Price";
      error.push("price");
      y === false && priceId.current.focus();
      y = true;
    }
    if (
      PersonDetails.price !== "" &&
      (PersonDetails.facilityName === "" || PersonDetails.facilityName === null)
    ) {
      errors.Oname = "Kindly enter the Name";
      error.push("name");
      y === false && nameId.current.focus();
      y = true;
    }
    if (y === true) {
      setFormError(errors);
      document.getElementById(error[0]).focus();
    }

    return y;
  };
  const onlyPercentage = (value) => {
    // Numeric constion +  in range 0-100
    const numericValue = Math.min(Math.max(parseFloat(value), 0), 100);
    return isNaN(numericValue) ? "" : numericValue.toString();
  };
  //#endregion
  const handleChangeHrs = (e) => {
    setIsEditDisable(false);
    setFormError({ codeerror: "", Oname: "", Oprice: "", exist: "" });
    errors.codeerror = "";
    errors.Oname = "";
    errors.Oprice = "";
    var result;

    if (formValue.CancellationTypeId === 1) {

      result = onlyNumeric(e.target.value) === '' ? '' : Number(onlyNumeric(e.target.value));
      // result = Number(onlyNumeric(e.target.value));
      if (result !== 0) {
        setFormValue({ ...formValue, [e.target.name]: result });
      }
      if (result === 0 && formValue[e.target.name] !== "") {
        setFormValue({ ...formValue, [e.target.name]: "" });
      }
      if (result === 0 && formValue[e.target.name] === "") {
        setFormValue({ ...formValue, [e.target.name]: 0 });
      }
    }
    if (formValue.CancellationTypeId === 2) {

      // const result = Number(onlyNumeric(e.target.value));
      result = onlyNumeric(e.target.value) === '' ? '' : Number(onlyNumeric(e.target.value));
      if (!isNaN(result) && result >= 0 && result <= 100) {
        if (result !== 0) {
          setFormValue({ ...formValue, [e.target.name]: result });
        }
        if (result === 0 && formValue[e.target.name] !== "") {
          setFormValue({ ...formValue, [e.target.name]: "" });
        }
        if (result === 0 && formValue[e.target.name] === "") {
          setFormValue({ ...formValue, [e.target.name]: 0 });
        }
      }

      // result = Number(onlyPercentage(e.target.value));
      // setFormValue({ ...formValue, [e.target.name]: result });
    }
  };

  const supplierOptions = useMemo(
    () =>
      suppliers
        ?.filter((suppliers) => suppliers.isActive)
        .map((suppliers) => ({
          label: suppliers.supplierName,
          value: suppliers.supplierId,
          isActive: suppliers.isActive,
          code: suppliers.supplierCode,
        })),
    [suppliers, buttonaddeditText]
  );
  const passengerOptions = useMemo(
    () =>
      passengerType?.map((e) => ({
        passengerTypeId: e.passengerTypeId,
        passengerType: e.passengerType,
      })),
    [passengerType, buttonaddeditText]
  );
  const cancelpolicyoption = useMemo(
    () =>
      Cancelll?.map((e) => ({
        cancellationPolicyId: e.cancellationPolicyId,
        cancellationPolicy: e.cancellationPolicy,
      })),
    [Cancelll, buttonaddeditText]
  );

  const options = useMemo(
    () =>
      cancellationType?.map((e) => ({
        label: e.cancellationType,
        value: e.cancellationTypeId,
      })),
    [cancellationType, buttonaddeditText]
  );

  const getSuppOption = (opt) => {
    if (buttonaddeditText === "Add") {
      return opt;
    } else {
      let currentCity = supplierActiveDisable;
      return currentCity?.isActive === true
        ? opt
        : [...opt, supplierActiveDisable];
    }
  };
  const [supplierCode, setsupplierCode] = useState("");
  const handleSupplierChanges = (name, e) => {
    setIsEditDisable(false);
    setFormError({ codeerror: "", Oname: "", Oprice: "", exist: "" });
    errors.codeerror = "";
    errors.Oname = "";
    errors.Oprice = "";
    supplierId.current.blur();
    setFormValue({ ...formValue, SupplierId: e.value });
    setsupplierCode(e.code);
  };

  const clearAll = () => {
    setFormError({ codeerror: "", Oname: "", Oprice: "", exist: "" });
    errors.codeerror = "";
    errors.Oname = "";
    errors.Oprice = "";
    setFormValue(Clone);
    setsupplierCode("");
    setPersonDetails({
      facilityName: "",
      price: "",
      seqNo: "",
    });

    setGlobalError("");
  };

  const saveAll = async (e) => {
    let cloneBasicInfo = { ...formValue };
    for (const key in cloneBasicInfo) {
      if (cloneBasicInfo[key] === "") {
        cloneBasicInfo[key] = 0;
      }
    }
    e.preventDefault();
    let statusoftoken = await verifytoken();
    if (validateData(cloneBasicInfo) !== true) {
      if (statusoftoken) {
        if (buttonaddeditText === "Add") {
          const basiceInfo = await postUsersData(
            "/master/v1/insertattractionprice",
            cloneBasicInfo
          );

          if (basiceInfo.status) {
            dispatch({ type: "ALERTINGMESSAGE", message: basiceInfo.message });
            clearAll();
          } else {
            setGlobalError(basiceInfo.message);
          }
        } else {
          const basiceInfoUpdate = await postUsersData(
            "/master/v1/insertattractionprice",
            cloneBasicInfo
          );
          if (basiceInfoUpdate.status) {
            dispatch({
              type: "ALERTINGMESSAGE",
              message: basiceInfoUpdate.message,
            });
            setIsEditDisable(true);
            clearAll();
          } else {
            setGlobalError(basiceInfoUpdate.message);
          }
        }
      } else {
        ClearLocalStorage();
        dispatch({
          type: "SESSIONOUT",
          message: "Your Session Expired. Please Login Again!",
        });
      }
    } else if (validateData(cloneBasicInfo)) {
      return;
    }
  };

  const handleBack = () => {
    clearAll();
    setIsEditDisable(true);
    navigate("/AttractionsList");
  };

  useEffect(() => {
    if (attraction?.AttractionBasicId > 0) {
      setSaveDisabled(disabled)
      handleRetrive();
    }
  }, []);

  const handleRetrive = async () => {
    await getAllData('/master/v1/getattractionpricebyid?AttractionBasicId=' + attraction?.AttractionBasicId).then(res => {
      res?.data?.map((obj) => {
        formValue.SupplierId = obj.SupplierId
        setsupplierCode(obj.SupplierCode)
        formValue.PassengerTypeId = obj.PassengerTypeId
        formValue.Adult = obj.Adult
        formValue.ChildUpto6Year = obj.ChildUpto6Year
        formValue.Child6to12Year = obj.Child6to12Year
        formValue.FacilityCollection = obj.DataCollection === null ? [] : obj.DataCollection
        setOtherFacility2(obj.DataCollection)
        formValue.CancellationPolicyId = obj.CancellationPolicyId
        formValue.CancellationTypeId = obj.CancellationTypeId
        formValue.NoShow = obj.NoShow
        formValue.Upto24hrs = obj.Upto24hrs
        formValue.Upto48hrs = obj.Upto48hrs
        formValue.Morethan48hrs = obj.Morethan48hrs
        setbuttonaddeditText(obj.AttractionPriceId > 0 ? 'Update' : 'Add');
        setClearButtonDisable(obj.AttractionPriceId > 0 ? true : false)
      })

    })
  }

  return (
    <div className="container form_border py-4 my-4">
      <div className="container my-lg-3 py-2">
        {globleError && (
          <p className="text-danger text-center">{globleError}</p>
        )}
        <div className="col-lg-12 col-md-12 col-sm-12 ">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="row">
                <div className="col-lg-4 col-md-12 mb-1">
                  <label className="col-form-label required">
                    Select Supplier
                  </label>
                </div>
                <div className="col-lg-8 mb-1 mx-lg-0 col-md-12 align-self-center">
                  {/* <Select
                    id="supplierId"
                    name="SupplierId"
                    className="dropdown select_dropdown"
                    options={getSuppOption(supplierOptions)}
                    value={supplierOptions?.filter(
                      (supplier) => supplier.value === formValue.supplier
                    )}
                    onChange={(e) => {
                      handleSupplierChanges(e);
                    }}
                    isDisabled={disabled}
                  /> */}
                  <CustomSelection
                    selId="supplierId"
                    datalist={getSuppOption(supplierOptions)}
                    selRef={supplierId}
                    fieldid={formValue?.SupplierId}
                    SelectionChange={(e) => {
                      handleSupplierChanges("supplierId", e);
                    }}
                    isdisable={disabled}
                    // isactivedisable={!supplierActiveDisable.isActive}
                    issearchable={true}
                  />
                  {formError.codeerror && (
                    <div className="text-start text-danger">
                      {formError.codeerror}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="row">
                <div className="col-lg-4 col-md-12 mb-1">
                  <label className="col-form-label ">Supplier Code</label>
                </div>
                <div className="col-lg-8 mb-1 col-md-12 align-self-center">
                  <input
                    placeholder="supplier code"
                    type="text"
                    id="supplierCode"
                    className="form-control"
                    name="supplierCode"
                    onChange={(e) => { }}
                    value={supplierCode}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container form_border px-4 py-4 my-4">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
          <div className="row">
            <div className="col-xl-2 col-lg-3 mx-0 mx-lg-0 mx-md-0 mx-sm-0 col-md-4">
              <label className="col-form-label">Passenger Type</label>
            </div>
            <div className="col mx-lg-1 mx-md-1 mx-0 mt-2">
              <div className="row">
                <div className="row">
                  {passengerOptions?.map((element, index) => (
                    <div className="col-lg-4 col-md-5" key={index}>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="PassengerType"
                          id={element.passengerType}
                          value={element.passengerType}
                          checked={
                            formValue.PassengerTypeId ===
                              element.passengerTypeId
                              ? true
                              : false
                          }
                          disabled={
                            viewtype === "view"
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            setIsEditDisable(false);
                            setFormError({
                              codeerror: "",
                              Oname: "",
                              Oprice: "",
                              exist: ""
                            });
                            errors.codeerror = "";
                            errors.Oname = "";
                            errors.Oprice = "";
                            errors.exist = "";

                            setFormValue({
                              ...formValue,
                              PassengerTypeId: element.passengerTypeId,
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={element.passengerType}
                        >
                          {element.passengerType}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
          <div className="row">
            <div className="col-lg-3 col-md-3">
              <label htmlFor="inputPassword6" className="col-form-label ">
                Adult
              </label>
              <input
                name="Adult"
                type="text"
                className="form-control"
                id="Adult"
                onChange={onChangePersonAgeDetails}
                value={formValue.Adult}
                maxLength={4}
                disabled={disabled}
              />
            </div>
            <div className="col-lg-3 col-md-3">
              <label htmlFor="inputPassword6" className="col-form-label ">
                Child up to 6 Year
              </label>

              <input
                name="ChildUpto6Year"
                type="text"
                className="form-control"
                id="ChildUpto6Year"
                onChange={onChangePersonAgeDetails}
                value={formValue.ChildUpto6Year}
                maxLength={4}
                disabled={disabled}
              />
            </div>

            <div className="col-lg-3 col-md-3">
              <label htmlFor="inputPassword6" className="col-form-label ">
                Child 6 to 12 years
              </label>
              <input
                type="text"
                id="Child6to12Year"
                className="form-control"
                name="Child6to12Year"
                onChange={onChangePersonAgeDetails}
                value={formValue.Child6to12Year}
                maxLength={4}
                disabled={disabled}
              />
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-12 col-md-12 col-sm-12">
            {formError.exist && (
              <div className="text-center text-danger">
                {formError.exist}
              </div>
            )}
            <div className="row ">
              <div className="col-lg-2 col-md-12 col-sm-12 col-12 mt-2">
                <label htmlFor="inputPassword6" className="col-form-label ">
                  Other Facility
                </label>
              </div>

              <div className="col-lg-4 col-md-5 col-sm-12 col-12 mt-2">
                <input
                  ref={nameId}
                  type="text"
                  id="facilityName"
                  className="form-control"
                  name="facilityName"
                  placeholder="name"
                  onChange={onChangePersonDetails}
                  value={PersonDetails.facilityName}
                  maxLength={150}
                  disabled={disabled}
                />
                {formError.Oname && (
                  <div className="text-start text-danger">
                    {formError.Oname}
                  </div>
                )}
              </div>

              <div className="col-lg-4 col-md-5 col-sm-12 col-12 pe-xl-4 mt-2">
                <input
                  ref={priceId}
                  type="text"
                  id="price"
                  className="form-control"
                  name="price"
                  placeholder="price"
                  onChange={onChangePersonDetails}
                  value={PersonDetails.price}
                  maxLength={4}
                  disabled={disabled}
                />
                {formError.Oprice && (
                  <div className="text-start text-danger">
                    {formError.Oprice}
                  </div>
                )}
              </div>

              <div className="col-lg-1 col-md-2 col-sm-12 col-12 mt-2">
                <button
                  type="button"
                  className="btn btn-primary"
                  id="btnAdd3 "
                  // disabled={Add2btnDisable}
                  onClick={onClickOtherFacilityAdd}
                  disabled={
                    disabled
                      ? true
                      : formValue.FacilityCollection?.length < 10
                        ? false
                        : true
                  }
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>

        {OtherFacility2?.map((element, index) => (
          <div className="row mt-3" key={index}>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row">
                <div className="col-lg-2 col-md-12 col-sm-12 col-12 mt-2 d-lg-block d-none">
                  <label htmlFor="inputPassword6" className="col-form-label ">
                  </label>
                </div>
                <div className="col-lg-4 col-md-5 col-sm-12 col-12 mt-lg-0 mt-1">
                  <input
                    disabled={true}
                    type="text"
                    key={index}
                    value={element.facilityName}
                    id="namedisplay"
                    className="form-control"
                    name="namedisplay"
                  />
                </div>

                <div className="col-lg-4 col-md-5 col-sm-12 col-12 pe-xl-4 mt-lg-0 mt-1">
                  <input
                    disabled={true}
                    type="text"
                    key={index}
                    value={element.price}
                    id="pricedisplay"
                    className="form-control"
                    name="pricedisplay"
                  />
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12 col-12 mt-lg-0 mt-1">
                  <button
                    type="button"
                    className="btn btn-primary "
                    id="remove2"
                    onClick={(e) => {
                      onClickAddOtherFacilityRemove(element.seqNo);
                    }}
                  >
                    ✖
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="container form_border py-4 my-4">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="row ">
              <div className="col-lg-3 col-md-4">
                <label className="col-form-label">Cancellation Policy</label>
              </div>
              <div className="col mt-2">
                <div className="row">
                  {cancelpolicyoption?.map((element, index) => (
                    <div className="col-lg-4 col-md-5" key={index}>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Policy"
                          id={element.cancellationPolicy}
                          value={element.cancellationPolicy}
                          checked={
                            formValue.CancellationPolicyId ===
                              element.cancellationPolicyId
                              ? true
                              : false
                          }
                          disabled={
                            viewtype === "view"
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            setIsEditDisable(false);
                            setFormError({
                              codeerror: "",
                              Oname: "",
                              Oprice: "",
                              exist: ""
                            });
                            errors.codeerror = "";
                            errors.Oname = "";
                            errors.Oprice = "";
                            errors.exist = "";

                            setFormValue({
                              ...formValue,
                              CancellationPolicyId:
                                element.cancellationPolicyId,
                              CancellationTypeId: "",
                              NoShow: "",
                              Upto24hrs: "",
                              Upto48hrs: "",
                              Morethan48hrs: "",
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={element.cancellationPolicy}
                        >
                          {element.cancellationPolicy}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
            <div className="row">
              <div className="col-lg-2 col-sm-4 col-md-3 col-6">
                <label className="col-form-label">Select Type</label>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                <Select
                  className="dropdown select_dropdown"
                  name="type"
                  isSearchable
                  placeholder="Select Type"
                  id="type"
                  options={options}
                  isDisabled={
                    !disabled
                      ? formValue.CancellationPolicyId === 1
                        ? false
                        : true
                      : true
                  }
                  onChange={(e) => {
                    setIsEditDisable(false);
                    setFormError({ codeerror: "", Oname: "", Oprice: "", exist: "" });
                    errors.codeerror = "";
                    errors.Oname = "";
                    errors.Oprice = "";
                    errors.exist = "";
                    // setType(e.value);
                    setFormValue({
                      ...formValue,
                      CancellationTypeId: e.value,
                      NoShow: "",
                      Upto24hrs: "",
                      Upto48hrs: "",
                      Morethan48hrs: "",
                    });
                  }}
                  value={options?.filter(function (options) {
                    return options.value === formValue.CancellationTypeId;
                  })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="col-lg-11">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <label htmlFor="inputPassword6" className="col-form-label ">
                  No Show
                </label>
                <input
                  type="text"
                  id="NoShow"
                  className="form-control"
                  name="NoShow"
                  onChange={(e) => {
                    setFormError({ codeerror: "", Oname: "", Oprice: "", exist: "" });
                    errors.codeerror = "";
                    errors.Oname = "";
                    errors.Oprice = "";
                    errors.exist = "";
                    handleChangeHrs(e);
                  }}
                  value={formValue.NoShow}
                  maxLength={
                    formValue.CancellationTypeId === 1 ||
                      formValue.CancellationTypeId === null || formValue.CancellationTypeId === ''
                      ? 4
                      : 3
                  }
                  disabled={
                    !disabled
                      ? formValue.CancellationPolicyId === 1
                        ? formValue.CancellationTypeId === null || formValue.CancellationTypeId === '' ? true :
                          false
                        : true
                      : true
                  }
                />
              </div>

              <div className="col-lg-3 col-md-6">
                <label htmlFor="inputPassword6" className="col-form-label ">
                  Up to 24 hrs
                </label>
                <input
                  type="text"
                  id="Upto24hrs"
                  className="form-control"
                  name="Upto24hrs"
                  onChange={(e) => {
                    setFormError({ codeerror: "", Oname: "", Oprice: "", exist: "" });
                    errors.codeerror = "";
                    errors.Oname = "";
                    errors.Oprice = "";
                    errors.exist = "";

                    handleChangeHrs(e);
                  }}
                  value={formValue.Upto24hrs}
                  maxLength={
                    formValue.CancellationTypeId === 1 ||
                      formValue.CancellationTypeId === null || formValue.CancellationTypeId === ''
                      ? 4
                      : 3
                  }
                  disabled={
                    !disabled
                      ? formValue.CancellationPolicyId === 1
                        ? formValue.CancellationTypeId === null || formValue.CancellationTypeId === '' ? true :
                          false
                        : true
                      : true
                  }
                />
              </div>
              <div className="col-lg-3 col-md-6">
                <label htmlFor="inputPassword6" className="col-form-label ">
                  Up to 48 hrs
                </label>
                <input
                  type="text"
                  id="Upto48hrs"
                  className="form-control"
                  name="Upto48hrs"
                  onChange={(e) => {
                    setFormError({ codeerror: "", Oname: "", Oprice: "", exist: "" });
                    errors.codeerror = "";
                    errors.Oname = "";
                    errors.Oprice = "";
                    errors.exist = "";

                    handleChangeHrs(e);
                  }}
                  value={formValue.Upto48hrs}
                  maxLength={
                    formValue.CancellationTypeId === 1 ||
                      formValue.CancellationTypeId === null || formValue.CancellationTypeId === ''
                      ? 4
                      : 3
                  }
                  disabled={
                    !disabled
                      ? formValue.CancellationPolicyId === 1
                        ? formValue.CancellationTypeId === null || formValue.CancellationTypeId === '' ? true :
                          false
                        : true
                      : true
                  }
                />
              </div>
              <div className="col-lg-3 col-md-6">
                <label htmlFor="inputPassword6" className="col-form-label ">
                  More than 48 hrs
                </label>
                <input
                  type="text"
                  id="Morethan48hrs"
                  className="form-control"
                  name="Morethan48hrs"
                  onChange={(e) => {
                    setFormError({ codeerror: "", Oname: "", Oprice: "", exist: "" });
                    errors.codeerror = "";
                    errors.Oname = "";
                    errors.Oprice = "";
                    errors.exist = "";

                    handleChangeHrs(e);
                  }}
                  value={formValue.Morethan48hrs}
                  maxLength={
                    formValue.CancellationTypeId === 1 ||
                      formValue.CancellationTypeId === null || formValue.CancellationTypeId === ''
                      ? 4
                      : 3
                  }
                  disabled={
                    !disabled
                      ? formValue.CancellationPolicyId === 1
                        ? formValue.CancellationTypeId === null || formValue.CancellationTypeId === '' ? true :
                          false
                        : true
                      : true
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center mb-4 mt-5">
        {buttonaddeditText === "Add" ? (
          <button
            type="button"
            class="btn btn-primary mx-1"
            id="btnAdd"
            onClick={(e) => saveAll(e)}
            // disabled={!userRights?.URightAdd || disabled}
            disabled={savedisabled ? true : false}
          >
            Add
          </button>
        ) : (
          <button
            type="button"
            class="btn btn-primary mx-1"
            id="btnAdd"
            onClick={(e) => saveAll(e)}
            // disabled={
            //   isEditDisable
            //     ? true
            //     : false || disabled || !userRights?.URightEdit
            // }
            disabled={savedisabled || isEditDisable ? true : false}
          >
            Update
          </button>
        )}
        <button
          type="button"
          className="btn btn-primary mx-1"
          id="btnClear"
          onClick={clearAll}
          // disabled={clearDisable ? true : false}
          disabled={clearbuttondisable ? true : false}
        >
          Clear
        </button>
        <button
          type="button"
          className="btn btn-primary mx-1 d-none"
          id="btnClear"
          onClick={handleBack}
        >
          Back
        </button>
      </div>
    </div>
  );
}
{
  /* <div className="row">
                  <div className="col-lg-4 col-md-5">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="Cancel"
                        id="Refundable"
                        value="Refundable"
                        checked={formValue.CancellationPolicy === "Refundable"}
                        disabled={
                          viewtype === "view" || viewtype === "update"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setIsEditDisable(false);
                          setFormError({
                            codeerror: "",
                            Oname: "",
                            Oprice: "",
                          });
                          errors.codeerror = "";
                          errors.Oname = "";
                          errors.Oprice = "";

                          setFormValue({
                            ...formValue,
                            CancellationPolicy: e.target.value,
                            type: "",
                            NoShow: "",
                            Upto24hrs: "",
                            Upto48hrs: "",
                            Morethan48hrs: "",
                          });
                        }}
                      />
                      <label className="form-check-label" htmlFor="Refundable">
                        Refundable
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-5   col-md-5">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="Cancel"
                        id="Non-Refundable"
                        value="Non-Refundable"
                        checked={
                          formValue.CancellationPolicy === "Non-Refundable"
                        }
                        disabled={
                          viewtype === "view" || viewtype === "update"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setIsEditDisable(false);
                          setFormError({
                            codeerror: "",
                            Oname: "",
                            Oprice: "",
                          });
                          errors.codeerror = "";
                          errors.Oname = "";
                          errors.Oprice = "";

                          setFormValue({
                            ...formValue,
                            CancellationPolicy: e.target.value,
                            type: "",
                            NoShow: "",
                            Upto24hrs: "",
                            Upto48hrs: "",
                            Morethan48hrs: "",
                          });
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Non-Refundable"
                      >
                        Non-Refundable
                      </label>
                    </div>
                  </div>
                </div> */
}
{
  /* <div className="col-lg-4 col-md-5">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="PassengerType"
                      id="Indian"
                      value="Indian"
                      checked={formValue.pricetype === "Indian" ? true : false}
                      disabled={
                        viewtype === "view" || viewtype === "update"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setIsEditDisable(false);
                        setFormError({ codeerror: "", Oname: "", Oprice: "" });
                        errors.codeerror = "";
                        errors.Oname = "";
                        errors.Oprice = "";
                        setFormValue({
                          ...formValue,
                          pricetype: e.target.value,
                        });
                        //setPricingType(e.target.value);
                      }}
                    />
                    <label className="form-check-label" htmlFor="Indian">
                      Indian
                    </label>
                  </div>
                </div>

                <div className="col-lg-4 col-md-5">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="PassengerType"
                      id="Foreigner"
                      value="Foreigner"
                      checked={
                        formValue.pricetype === "Foreigner" ? true : false
                      }
                      disabled={
                        viewtype === "view" || viewtype === "update"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setIsEditDisable(false);
                        setFormError({ codeerror: "", Oname: "", Oprice: "" });
                        errors.codeerror = "";
                        errors.Oname = "";
                        errors.Oprice = "";
                        setFormValue({
                          ...formValue,
                          pricetype: e.target.value,
                        });
                        // setPricingType(e.target.value);
                      }}
                    />
                    <label className="form-check-label" htmlFor="Foreigner">
                      Foreigner
                    </label>
                  </div>
                </div> */
}
