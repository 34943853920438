import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getAllData } from "../../../services/ApiCallingUserServices";
import { loaderActions } from "../../../_actions/loader.actions";
import { ClearLocalStorage, GetLocalStorage } from "../../commonClass/Session";
import { generatePDF } from "../../commonClass/CommonValidation";
import { ExportExcel } from "../../commonClass/ExportExcel";
import Select from 'react-select';
import CustomTable from "../../../customControl/CustomTable";

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('/');
}

function JobVacancy() {
    const user = useSelector(store => store.userstatus.tba);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [userRights, setUserRights] = useState({});
    const [allJobs, setAllJobs] = useState([])
    const [jobList, setJobList] = useState([]);

    const [attractionsList, setAttractionsList] = useState([]);
    const [searchby, setSearchby] = useState('10');
    const [searchValue, setSearchValue] = useState('');
    const [clrshow, setsclshow] = useState(false);
    const [serchdisable, setSerchdisable] = useState(false)
    const [filterData, setFilterData] = useState([])

    useEffect(() => {
        let pagelink = user?.find(x => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);

        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        };
        setUserRights(mid);
    }, [user]);



    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();

        if (token && token.userName && token.token) {
            getJobVacanyyList();
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
    }, []);

    const getJobVacanyyList = async () => {
        dispatch(loaderActions.start());
        const getJobList = await getAllData('/master/v1/getjobvacancylist');
        if (getJobList.status) {
            setJobList(getJobList.data);
            setAllJobs(getJobList.data)
        }
        dispatch(loaderActions.end());
    };

    const createNewJob = () => {
        navigate('/NewJobVacancy', {
            state: {
                disabled: false,
                clearDisable: false,
                editdisabled: false,
                attraction: [],
                JobVacancyId: null,
                viewtype: 'Add',
                attractionsList: attractionsList,
                userRights: userRights
            }
        });
    }

    const columns = [
        {
            name: 'Job Code',
            sortable: true,
            selector: (data) => data?.JodeCode
        },
        {
            name: 'State',
            sortable: true,
            selector: (data) => data?.StateName
        },
        {
            name: 'City',
            sortable: true,
            selector: (data) => data?.City
        },
        {
            name: 'Department',
            sortable: true,
            selector: (data) => data?.DepartmentName
        },
        {
            name: 'Job Title',
            sortable: true,
            selector: (data) => data?.JobTitle
        },
        {
            name: 'Experience',
            sortable: true,
            selector: (data) => data?.ExpName
        },
        {
            name: 'Qualification',
            sortable: true,
            selector: (data) => data?.QuaName
        },
        {
            name: 'Apply Limitations',
            sortable: true,
            selector: (data) => data?.ApplyLimit * data.NoOfVacancy
        },
        {
            name: 'Active',
            sortable: true,
            selector: (data) => data?.IsActive ? 'Active' : 'In Active'
        },
        {
            name: 'Created Date',
            sortable: true,
            selector: (data) => formatDate(data?.CreatedOn)
        },
        {
            name: 'Edit',
            selector: (data) => userRights?.URightEdit ? <button
                type='button'
                className="border-0 p-0 bg-body set_disableclr"
                disabled={!userRights?.URightEdit}
            >
                <Link
                    className="border-0 p-0 bg-body set_disableclr remove_hover_effect"
                    to="/NewJobVacancy"
                    state={{
                        disabled: false,
                        clearDisable: true,
                        editdisabled: true,
                        JobVacancyId: data.JobVacancyId,
                        viewtype: 'edit',
                        userRights: userRights
                    }}>
                    <i className="fa fa-edit"
                    >
                    </i>
                </Link>
            </button> : <button type='button' className='border-0 p-0 bg-body set_disableclr'
                disabled={!userRights?.URightEdit}><i className="fa fa-edit"></i></button>
        },
        {
            name: 'View',
            selector: (data) => userRights?.URightView ? <button
                type='button'
                className='border-0 p-0 bg-body set_disableclr '
                disabled={!userRights?.URightView}
            >
                <Link to="/NewJobVacancy"
                    className='remove_hover_effect'
                    state={{
                        disabled: true,
                        clearDisable: true,
                        editdisabled: true,
                        attraction: data,
                        viewtype: 'view',
                        JobVacancyId: data.JobVacancyId,
                        attractionId: data?.AttractionBasicId,
                        attractionsList: attractionsList,
                        userRights: userRights
                    }}>
                    <i className="fa fa-eye "
                    ></i>
                </Link>
            </button> : <button
                type='button'
                className='border-0 p-0 bg-body set_disableclr '
                disabled={!userRights?.URightView}
            >
                <Link to="/NewJobVacancy"
                    className='remove_hover_effect'
                    state={{
                        disabled: true,
                        clearDisable: true,
                        editdisabled: true,
                        attraction: data,
                        viewtype: 'view',
                        JobVacancyId: data.JobVacancyId,
                        attractionId: data?.AttractionBasicId,
                        attractionsList: attractionsList,
                        userRights: userRights
                    }}>
                    <i className="fa fa-eye "
                    ></i>
                </Link>
            </button>

        }
    ];

    const serachoption = [
        {
            label: 'Job Code',
            value: '1'
        },
        {
            label: 'State',
            value: '2'
        },
        {
            label: 'City',
            value: '3'
        },
        {
            label: 'Department',
            value: '4'
        },
        {
            label: 'Job Title',
            value: '5'
        },
        {
            label: 'Experience',
            value: '6'
        },
        {
            label: 'Qualification',
            value: '7'
        },
        {
            label: 'Apply Limitations',
            value: '8'
        },
        {
            label: 'Active',
            value: '9'
        },
        {
            label: 'All',
            value: '10'
        },
    ];

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleSearch(e);
        };
    };

    const handleSearchBy = (val) => {
        setSearchby(val.value);
    };
    const handleChangeSearchValue = (e) => {
        setsclshow(true);
        setSearchValue(e.target.value);
        if (e.target.value === '') {
            getJobVacanyyList();
        }
    };
    const handleSearch = (e) => {
        e.preventDefault();

        if (searchValue.trim().length > 0 && searchby) {
            var filteritem = filterData;
            if (filteritem?.length < allJobs?.length) {
                filteritem = allJobs
            }
            var filterdata;

            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec?.JodeCode?.toLowerCase().includes(searchValue.toLowerCase()));
            }
            else if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec?.StateName?.toLowerCase().includes(searchValue.toLowerCase()));
            }
            else if (searchby === '3') {
                filterdata = filteritem?.filter(issec => issec?.City?.toLowerCase().includes(searchValue.toLowerCase()));
            }
            else if (searchby === '4') {
                filterdata = filteritem?.filter(issec => issec?.DepartmentName?.toLowerCase().includes(searchValue.toLowerCase()));
            }
            else if (searchby === '5') {
                filterdata = filteritem?.filter(issec => issec?.JobTitle?.toLowerCase()?.includes(searchValue.toLowerCase()));
            }
            else if (searchby === '6') {
                filterdata = filteritem?.filter(issec => issec?.ExpName?.toLowerCase()?.includes(searchValue.toLowerCase()));
            }
            else if (searchby === '7') {
                filterdata = filteritem?.filter(issec => issec?.QuaName?.toLowerCase()?.includes(searchValue.toLowerCase()));
            }
            else if (searchby === '8') {
                filterdata = filteritem?.filter(issec => issec?.ApplyLimit?.toString()?.includes(searchValue));
            }
            else if (searchby === '9') {
                if (searchValue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === true);
                }
                else if (searchValue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === false);
                }
                else if ('active'.toLowerCase().includes(searchValue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === true || issec.IsActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchValue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => formatDate(issec.CreatedOn).toLowerCase().includes("%#$&%^"));
                }
            }
            else if (searchby === '10') {
                if (searchValue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec =>
                        issec.JodeCode?.toLowerCase().includes(searchValue.toLowerCase())
                        || issec?.StateName?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.City?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.DepartmentName?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec.JobTitle?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.ExpName?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.QuaName?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.ApplyLimit?.toString().includes(searchValue.trim())
                        || formatDate(issec?.CreatedOn)?.toLowerCase().includes(searchValue?.toLowerCase())
                        || issec.IsActive === true);
                }
                else if (searchValue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec =>
                        issec?.JodeCode?.toLowerCase().includes(searchValue.toLowerCase())
                        || issec?.StateName?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.City?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.DepartmentName?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.JobTitle?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.ExpName?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.QuaName?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.ApplyLimit?.toString().includes(searchValue.trim())
                        || formatDate(issec?.CreatedOn)?.toLowerCase().includes(searchValue?.toLowerCase())
                        || issec.IsActive === false);
                }
                else if ('active'.toLowerCase().includes(searchValue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec =>
                        issec.JodeCode.toLowerCase().includes(searchValue.toLowerCase())
                        || issec.StateName.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec.City.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec.DepartmentName.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec.JobTitle.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec.ExpName.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.QuaName?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.ApplyLimit?.toString().includes(searchValue.trim())
                        || formatDate(issec.CreatedOn)?.toLowerCase().includes(searchValue?.toLowerCase())
                        || issec.IsActive === true
                        || issec.IsActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchValue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec =>
                        issec.JodeCode.toLowerCase().includes(searchValue.toLowerCase())
                        || issec.StateName.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec.City.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec.DepartmentName.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec.JobTitle.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec.ExpName.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.QuaName?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.ApplyLimit?.toString().includes(searchValue.trim())
                        || formatDate(issec.CreatedOn)?.toLowerCase().includes(searchValue?.toLowerCase())
                        || issec.IsActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec =>
                        issec?.JodeCode?.toLowerCase().includes(searchValue.toLowerCase())
                        || issec?.StateName?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.City?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.DepartmentName?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.JobTitle.toLowerCase()?.includes(searchValue.trim().toLowerCase())
                        || issec?.ExpName.toLowerCase()?.includes(searchValue.trim().toLowerCase())
                        || issec?.QuaName?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.ApplyLimit?.toString().includes(searchValue.trim())
                        || formatDate(issec.CreatedOn)?.toLowerCase().includes(searchValue?.toLowerCase())
                    );
                }
            }
            setJobList(filterdata)
        }
    }

    const clearsearch = (e) => {
        e.preventDefault();
        setJobList(allJobs)
        setSearchValue('')
    };

    function PdfExport() {
        const columns = [
            "Job Code",
            "State",
            "City",
            "Department",
            "Job Title",
            "Experience",
            "Qualification",
            "Apply Limitations",
            "Active",
            "Created Date",
        ];
        var rows = [];
        for (let i = 0; i < jobList?.length; i++) {
            var temp = [
                jobList[i].JodeCode,
                jobList[i].StateName,
                jobList[i].City,
                jobList[i].DepartmentName,
                jobList[i].JobTitle,
                jobList[i].ExpName,
                jobList[i].QuaName,
                jobList[i].ApplyLimit * jobList[i].NoOfVacancy,
                `${jobList[i].IsActive ? 'Active' : 'InActive'}`,
                formatDate(jobList[i]?.CreatedOn),
            ];
            rows = [...rows, temp]
        }
        generatePDF(columns, rows, "EtmCareerList", "ETM Career List")
    };

    const ExportToExcel = () => {
        const jobListData = jobList?.map((job) => ({ ...job, ApplyLimit: job.ApplyLimit * job.NoOfVacancy }))
        const displayname = [
            "Job Code",
            "State",
            "City",
            "Department",
            "Job Title",
            "Experience",
            "Qualification",
            "Apply Limitations",
            "Active",
            "Created Date"
        ];
        const fields = ['JodeCode', 'StateName', 'City', 'DepartmentName', 'JobTitle', 'ExpName', 'QuaName', 'ApplyLimit', 'IsActive', 'CreatedOn'];
        ExportExcel(displayname, fields, jobListData, 'ETM Career List', 'EtmCareerList.xls')
    };

    return (
        <>
            <div class="container mt-4 heading_color">
                <h3>ETM Career List</h3>
            </div>
            <div class="container form_border py-5 my-3">
                <form action="" >
                    <div class="d-flex justify-content-start ms-lg-3">
                        <button type="button"
                            disabled={!userRights?.URightAdd}
                            class="btn btn-primary mx-1" to="Suppliermaster" id="btnAdd"
                            onClick={createNewJob}
                        >
                            Create New Vacancy
                        </button>
                    </div>
                    <br />
                    <div class="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}

                                />
                            </div>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input
                                        onKeyDown={onKeyDownHandler}
                                        type="text" id="btnSearchText" class="form-control"
                                        placeholder='Enter keyword' name="SearchValue"
                                        onChange={handleChangeSearchValue}
                                        value={searchValue}
                                        disabled={serchdisable ? true : false}
                                    />
                                    <button
                                        className={`${clrshow === true ? "d-block" : "d-none"}`}
                                        disabled={serchdisable ? true : false}
                                        onClick={clearsearch}
                                        type="reset"
                                    >&times;</button>
                                </div>
                            </div>
                            <div class="p-2 text-center">
                                <button type="button" class="btn btn-primary mx-1" id="btnSearch"
                                    disabled={serchdisable ? true : false}
                                    onClick={handleSearch}
                                >Search</button>
                            </div>
                        </div>

                    </div>

                    <CustomTable
                        columns={columns}
                        data={jobList}
                        PdfExport={PdfExport}
                        ExportToExcel={ExportToExcel}
                        disabled={!userRights?.URightExport}
                    />
                </form>
            </div>
        </>
    )
}

export default JobVacancy;