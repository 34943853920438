import React from 'react';
import { useEffect, useState } from "react";
import Select from 'react-select';
import "../../assets/css/master.css"
import { getAllData } from '../../services/ApiCallingUserServices';
import { generatePDF } from '../commonClass/CommonValidation'
import { useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../commonClass/Session';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../_actions/loader.actions";
import { ExportExcel } from '../commonClass/ExportExcel';
import CustomTable from '../../customControl/CustomTable';

const CruiseInventoryList = () => {
    const dispatch = useDispatch();
    /////////////////////declartion of variable ///////////////////////////////////////
    //#region declartion of variable
    const navigate = useNavigate();
    const [cruiselistdata, setCruiseListData] = useState([]);
    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('20');
    const [serchdisable, setSerachDisable] = useState(false);
    const [filteritems, setFilterItems] = useState([]);
    const [clrshow, setsclshow] = useState(false);
    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);

    const serachoption = [
        {
            label: 'Travel Scope',
            value: '1'
        },
        {
            label: 'Trip Type',
            value: '2'
        },
        {
            label: 'Supplier Name',
            value: '3'
        },
        {
            label: 'Supplier Code',
            value: '4'
        },
        {
            label: 'Package Name',
            value: '5'
        },
        {
            label: 'Cruise Name',
            value: '6'
        },
        {
            label: 'Cruise Number',
            value: '7'
        },
        {
            label: 'Active',
            value: '19'
        },
        // {
        //     label: 'Created Date',
        //     value: '24'
        // },
        {
            label: 'All',
            value: '20'
        },
    ];
    //#endregion
    /////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////set form date for add, edit and view////////////////////


    //////////////////////////////use effect section //////////////////////////////////
    //#region  use effect section
    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            getCruiseDataList().then(() => { dispatch(loaderActions.end()) });
            setitems();
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, [])

    useEffect(() => {
        let pagelink = user?.find(x => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);

        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);

        });

    }, [user])
    //#endregion
    //////////////////////////////////////////////////////////////////////////////////////////////


    ///////////////////////fill dropdown and handle input value//////////////////////////
    //#region fill dropdown and handle input value


    const getCruiseDataList = async () => {
        const cruiselistdata = await getAllData("/master/v1/getallinventorycruise");
        setCruiseListData(cruiselistdata.data);
    }

    //#endregion
    /////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////////////serch and export sction////////////////////////////////////////////
    //#region handling search 

    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }

    const setitems = async () => {
        const cruiselistdata = await getAllData("/master/v1/getallinventorycruise");
        setFilterItems(cruiselistdata.data);
    }
    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            getCruiseDataList();
            setsclshow(false)
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleClickSearchValue();
        }
    };

    const handleClickSearchValue = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            setitems();
            var filteritem = filteritems;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec.TravelScope?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec.TravelType?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '3') {
                filterdata = filteritem?.filter(issec => issec.SupplierName?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '4') {
                filterdata = filteritem?.filter(issec => issec.SupplierCode?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '5') {
                filterdata = filteritem?.filter(issec => issec.City?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '6') {
                filterdata = filteritem?.filter(issec => issec.CruiseName?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '7') {
                filterdata = filteritem?.filter(issec => issec.CruiseNo?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '24') {
                filterdata = filteritem?.filter(issec => formatDate(issec.CreatedOn)?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '19') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === true || issec.IsActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => formatDate(issec.CreatedOn)?.toLowerCase().includes("%#$&%^"));
                }
            }
            else if (searchby === '20') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.TravelScope?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.TravelType?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.SupplierName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.SupplierCode?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.City?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.CruiseName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.CruiseNo?.toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDate(issec.CreatedOn)?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.IsActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.TravelScope?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.TravelType?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.SupplierName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.SupplierCode?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.City?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.CruiseName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.CruiseNo?.toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDate(issec.CreatedOn).toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.IsActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.TravelScope?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.TravelType?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.SupplierName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.SupplierCode?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.City?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.CruiseName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.CruiseNo?.toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDate(issec.CreatedOn).toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.IsActive === true || issec.IsActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.TravelScope?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.TravelType?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.SupplierName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.SupplierCode?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.City?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.CruiseName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.CruiseNo?.toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDate(issec.CreatedOn).toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.IsActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => issec.TravelScope?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.TravelType?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.SupplierName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.SupplierCode?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.City?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.CruiseName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.CruiseNo?.toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDate(issec.CreatedOn).toLowerCase().includes(searchvalue.toLowerCase()));
                }

            }
            setCruiseListData(filterdata)
        }
    }

    function PdfExport() {
        //print
        const columns = [
            "Travel Scope",
            "Trip Type",
            "Supplier Name",
            "Supplier Code",
            "Package Name",
            "Cruise Name",
            "Cruise Number",
            "Active",
            "Created Date"
        ];
        var rows = [];

        for (let i = 0; i < cruiselistdata?.length; i++) {
            var temp = [
                cruiselistdata[i].TravelScope,
                cruiselistdata[i].TravelType,
                cruiselistdata[i].SupplierName,
                cruiselistdata[i].SupplierCode,
                cruiselistdata[i].City,
                cruiselistdata[i].CruiseName,
                cruiselistdata[i].CruiseNo,
                `${cruiselistdata[i].IsActive ? 'Active' : 'InActive'}`,
                formatDate(cruiselistdata[i].CreatedOn)
            ];
            rows = [...rows, temp]
        }
        generatePDF(columns, rows, "CruiseMaster", "Cruise Master", 'l')
    }


    const ExportToExcel = () => {

        const dispalyname = ['Travel Scope', 'Trip Type', 'Supplier Name', 'Supplier Code', 'Package Name', 'Cruise Name', 'Cruise Number', 'Active', 'Created Date'];
        const fields = ['TravelScope', 'TravelType', 'SupplierName', 'SupplierCode', 'City', 'CruiseName', 'CruiseNo', 'IsActive', 'CreatedOn'];
        ExportExcel(dispalyname, fields, cruiselistdata, "CruiseMaster", "CruiseMaster.xls");
    }
    //#endregion
    //////////////////////////////////////////////////////////////////////////////////////

    ///////////////////////////handle table Pagination///////////////////////////////////
    //#region table Pagination
    const columns = [
        {
            name: 'Travel Scope',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (trip) => trip.TravelScope
        },
        {
            name: 'Trip Type',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (trip) => trip.TravelType
        },
        {
            name: 'Supplier Name',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (trip) => trip.SupplierName
        },
        {
            name: 'Supplier Code',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (trip) => trip.SupplierCode
        },
        {
            name: 'Package Name',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (trip) => trip.City
        },
        {
            name: 'Cruise Name',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (trip) => trip.CruiseName
        },
        {
            name: 'Cruise Number',
            sortable: true,
            float: "left",
            reorder: true,
            selector: (trip) => trip.CruiseNo
        },
        {
            name: 'Active',
            sortable: true,
            float: "left",
            selector: (trip) => trip.IsActive ? 'Active' : 'InActive'
        },
        {
            name: 'Created Date',
            sortable: true,
            float: "left",
            selector: (trip) => formatDate(trip.CreatedOn)
        },
        {
            name: 'Edit',
            // float:"right",
            selector: (trip) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEdit(trip.InventoryCruiseId)}></i></button>
        },
        {
            name: 'View',
            // float:"right",
            selector: (trip) => <i className="fa fa-eye" onClick={() => handleView(trip.InventoryCruiseId)}></i>
        }
    ];



    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getCruiseDataList();
    }
    //#endregion
    ////////////////////////////////////////////////////////////////////////////////////////

    const handleOpen = () => {
        navigate('/CruiseInventoryMaster', { state: { disabled: false, editdisabled: false, viewtype: 'Add', InventoryCruiseId: 0 } });
    }

    const handleEdit = (id) => {
        navigate('/CruiseInventoryMaster', { state: { disabled: false, editdisabled: true, viewtype: 'Update', InventoryCruiseId: id } });
    }

    const handleView = (id) => {;
        navigate('/CruiseInventoryMaster', { state: { disabled: true, editdisabled: true, viewtype: 'View', InventoryCruiseId: id } });
    }
    function formatDate(date) {
        if (date !== null) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [day, month, year].join('/');
        }
        return null
    }

    return (
        <>
            <div class="container mt-4 heading_color">
                <h3>Cruise Inventory List</h3>
            </div>
            <div class="container form_border py-5 my-3">
                <form action="" >
                    <div class="d-flex justify-content-start ms-lg-3">
                        <button type="button" disabled={!useradd} class="btn btn-primary mx-1" id="btnAdd" onClick={handleOpen} >Create Cruise Inventory</button>
                    </div>
                    <br />
                    <div class="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}

                                />
                            </div>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input onKeyDown={onKeyDownHandler} type="text" id="btnSearchText" class="form-control" placeholder='Enter keyword' name="SearchValue" onChange={handleChangeSearchValue} value={searchvalue} disabled={serchdisable ? true : false} />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div class="p-2 text-center">
                                <button type="button" class="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleClickSearchValue} >Search</button>
                            </div>
                        </div>

                    </div>

                    
                    <CustomTable
                        columns={columns}
                        data={cruiselistdata}
                        PdfExport={PdfExport}
                        ExportToExcel={ExportToExcel}
                        disabled={!userexport}
                    />

                </form>
            </div>
        </>
    )
}

export default CruiseInventoryList