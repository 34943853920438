import React, { useEffect, useMemo, useRef, useState } from 'react'
import Select from 'react-select';
import Switch from 'react-switch';
import AccessPoints from './AccessPoints';
import NearByFacilities from './NearByFacilities';
import CodeOfConduct from './CodeOfConduct';
import { TimePicker } from 'antd';
import CustomTimePicker from '../../../../customControl/CustomTimePicker';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ConfirmationModal } from '../../../../customControl/ConfirmationModal';
import { getAllData, postUsersData, verifytoken } from '../../../../services/ApiCallingUserServices';
import { ClearLocalStorage, GetLocalStorage } from '../../../commonClass/Session';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { loaderActions } from '../../../../_actions/loader.actions';

function AdditionInformation({
   disable,
   attraction,
   editdisable,
   clearDisable,
   viewtype,
   attractionId,
   attractionsList,
   userRights,
}) {

   const dispatch = useDispatch()
   const [formValue, setFormValue] = useState(
      {
         AttractionBasicId: attractionId,
         WeekDayCollection: [],

         AttractionTimeTypeId: 1,
         AttractionTimeSlotCollection: [],
         AttractionClosingPeriodCollection: [],
         RecommendedDuration: "",
         RecommendedDescription: "",
         AttractionSuggetedTimeCollection: [],

         IsSpecialEvent: true,
         AttractionSplEventCollection: [],

         DressCode: "",
         ThingsToCarry: "",
         ThingsNotToCarry: "",
         EntranceRegulations: "",
         SpecialComments: "",

         RailwayStationCollection: [],
         BusStandCollection: [],
         AirportCollection: [],
         PortCollection: [],

         AttractionFacilityCollection: [],
         HospitalCollection: [],
         PoliceStationCollection: [],
         ShoppingCollection: [],
         GalleryCollection: [],

         IsActive: true,
         UserId: 0,
      }
   )

   const [savedisabled, setSaveDisabled] = useState(true);
   const [buttonaddeditText, setbuttonaddeditText] = useState('Add');
   const [clearbuttondisable, setClearButtonDisable] = useState(false);
   const [disabled, setDisabled] = useState(disable);
   const [isEditDisable, setIsEditDisable] = useState(editdisable);


   const [weekDaysCollection, setWeekDaysCollection] = useState([])

   const [timeType, setTimeType] = useState(1)
   const [startTime, setStartTime] = useState(null)
   const [endTime, setEndTime] = useState(null)

   const [closingPeriodFrom, setClosingPeriodFrom] = useState(null);
   const [closingPeriodTo, setClosingPeriodTo] = useState(null);
   const [fromDate, setFromDate] = useState(null);
   const [toDate, setToDate] = useState(null);

   const [recommendedDuration, setRecommendedDuration] = useState(null)
   const [suggetedTime, setSuggestedTime] = useState(null)
   const [recomDuration, setRecomDuration] = useState('')
   const [suggTime, setSuggTime] = useState({
      suggetedTime: ''
   })
   const [recomDurationInsert, setRecomDurationInsert] = useState('')
   const [suggTimeInsert, setSuggTimeInsert] = useState({
      suggetedTime: ''
   })

   const [specialEvent, setSpecialEvent] = useState(true)
   const [eventName, setEventName] = useState('')

   const [formError, setFormError] = useState('')

   const Ref = useRef({
      startTime: null,
      endtime: null,
   });


   const [timeSlot, setTimeSlot] = useState({
      startTime: '',
      endtime: '',
   });

   const [timeSlotInsert, setTimeSlotInsert] = useState({
      startTime: '',
      endtime: '',
   });

   const [closingPeriod, setClosingPeriod] = useState({
      closingPeriodFrom: '',
      closingPeriodTo: ''
   })
   const [closingPeriodInsert, setClosingPeriodInsert] = useState({
      closingPeriodFrom: '',
      closingPeriodTo: ''
   })

   const [eventDate, setEventDate] = useState({
      eventName: '',
      fromDate: '',
      toDate: ''
   })
   const [eventDateInsert, setEventDateInsert] = useState({
      eventName: '',
      fromDate: '',
      toDate: ''
   })

   const [TimeSloteInsertCollection, setTimeSlotInsertCollection] = useState([])
   const [ClosingPeriodInsertCollection, setClosingPeriodInsertCollection] = useState([])
   const [SuggestedTimeToVisitInsertCollection, setSuggestedTimeToVisitInsertCollection] = useState([])
   const [SpecialEventInsertCollection, setSpecialEventInsertCollection] = useState([])

   const [CodeOfConductCollection, setCodeOfConductCollection] = useState([])


   const [additionInfo, setAdditionInfo] = useState({
      OperatedDay: [
         { weekDayId: 1, weekDayName: 'All', isCheck: false },
         { weekDayId: 2, weekDayName: 'Monday', isCheck: false },
         { weekDayId: 3, weekDayName: 'Tuesday', isCheck: false },
         { weekDayId: 4, weekDayName: 'Wednesday', isCheck: false },
         { weekDayId: 5, weekDayName: 'Thursday', isCheck: false },
         { weekDayId: 6, weekDayName: 'Friday', isCheck: false },
         { weekDayId: 7, weekDayName: 'Saturday', isCheck: false },
         { weekDayId: 8, weekDayName: 'Sunday', isCheck: false }
      ],

      TimeSlote: [],
      ClosingPeriod: [],
      SuggestedTimeToVisit: [],
      Description: '',
      Type: '',
      EventDate: [],

      GalleryRemove: [],

      AttractionFacilityCollection: [],

      AccessPoints: {
         trainStation: [],
         busStation: [],
         airPort: [],
         port: []
         ,
      },

      AccessPointsLabels: {
         trainStation: [],
         busStation: [],
         airPort: [],
         port: []
         ,
      },

      NearByFacilities: {
         Hospital: [],
         PoliceStation: [],
         Shopping: [],
         Gallery: []
      },

   });

   const [attractionTimeTypeList, setAttractionTimeTypeList] = useState([])
   const [accessPointList, setAccessPointList] = useState([])
   const [accessPointRetieveData, setAccessPointRetieveData] = useState([])
   const [facilityData, setFacilityData] = useState([])

   const getrelativemaster = async () => {
      let statusoftoken = await verifytoken();
      if (statusoftoken) {
         let userData = GetLocalStorage()
         formValue.UserId = userData?.userMasterID

         const relativemaster = await getAllData('/master/v1/getrelativemaster')
         relativemaster?.data?.map(obj => {
            setAttractionTimeTypeList(obj.attractionTimeTypeList?.filter(obj => obj.isActive === true))
            let accessPointOptionsList = {}
            accessPointOptionsList.railwayStationList = obj.railwayStationList?.filter(obj => obj.isActive === true)
            accessPointOptionsList.busStandList = obj.busStandList?.filter(obj => obj.isActive === true)
            accessPointOptionsList.airportList = obj.airportList?.filter(obj => obj.isActive === true)
            accessPointOptionsList.portList = obj.portList?.filter(obj => obj.isActive === true)
            setAccessPointList(accessPointOptionsList)
            setFacilityData(obj?.attractionFacilityList)
         })
      }
      else {
         ClearLocalStorage();
         dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
      }
      dispatch(loaderActions.end())
   }


   //#region Operated Day

   const handleFormData = (name, value) => {
      setAdditionInfo({
         ...additionInfo,
         [name]: value
      })

   }

   const checkIsAllSelected = (updateOption) => {
      let allSelected = true;
      updateOption.forEach((option) => {
         if (option.weekDayId != '1') {
            if (option.isCheck === false) {
               allSelected = false;
            }
         }
      });

      return allSelected;
   }

   const handleOperatedDay = (name, value) => {
      setIsEditDisable(false)
      let updateOption = [...additionInfo.OperatedDay];

      if (name === '1') {
         updateOption = additionInfo.OperatedDay.map((option) => {
            option = { ...option, isCheck: value }
            return option
         });

      } else {
         updateOption = additionInfo.OperatedDay.map((option) => {
            if (option.weekDayId == name) {
               option = { ...option, isCheck: value };
            }

            return option
         });
      }

      updateOption = updateOption.map(
         (option) => option.weekDayId == '1' ?
            { ...option, isCheck: checkIsAllSelected(updateOption) }
            : option
      );

      handleFormData('OperatedDay', updateOption)
   }
   //#endregion

   //#region Time Radio
   const handleTimeTypeRadio = (e, id) => {
      setFormError('')
      setStartTime(null)
      setEndTime(null)
      setTimeSlot({ ...timeSlot, startTime: '', endtime: '' })
      setTimeSlotInsert({ ...timeSlotInsert, startTime: '', endtime: '' })
      setAdditionInfo({ ...additionInfo, TimeSlote: [] })
      setTimeSlotInsertCollection([])
      setIsEditDisable(false)

      setTimeType(id)
   }
   //#endregion

   //#region Start and End Time
   const handleTime = (time, name) => {

      setFormError('')
      setIsEditDisable(false)
      if (name === 'startTime') {
         setStartTime(time)
         setTimeSlot({ ...timeSlot, startTime: formatTime(time) })
         setTimeSlotInsert({ ...timeSlotInsert, startTime: formatDateTime(time) })
      }
      else if (name === 'endtime') {
         setEndTime(time)
         setTimeSlot({ ...timeSlot, endtime: formatTime(time) })
         setTimeSlotInsert({ ...timeSlotInsert, endtime: formatDateTime(time) })
      }
      else if (name === 'RecommendedDuration') {
         setRecommendedDuration(time)
         setRecomDuration(formatTime(time))
         setRecomDurationInsert(formatDateTime(time))
      }
      else if (name === 'SuggestedTime') {
         setSuggestedTime(time)
         setSuggTime({ ...suggTime, suggetedTime: formatTime(time) })
         setSuggTimeInsert({ ...suggTimeInsert, suggetedTime: formatDateTime(time) })
      }
   }

   const validationTimeAdd = () => {
      let status = false
      let error = {}
      if (timeSlot.startTime === '' || timeSlot.endtime === '') {
         status = true;
      }
      if (timeSlot.startTime === '' && timeSlot.endtime) {
         error.startTime = 'Kindly specify the start time';
         status = true;
      }
      if (timeSlot.startTime && timeSlot.endtime === '') {
         error.endtime = 'Kindly specify the end time';
         status = true;
      }
      else if (timeSlot.startTime && timeSlot.endtime && timeSlot.startTime === timeSlot.endtime) {
         error.endtime = 'End time should be greater than start time';
         status = true;
      }
      else if (timeSlot.startTime && timeSlot.endtime && new Date(startTime).getTime() > new Date(endTime).getTime()) {
         error.endtime = 'End time should be greater than start time';
         status = true;
      }
      else if (timeType === 1 && additionInfo?.TimeSlote?.length >= 1) {
         error.Continuouse = 'Maximum allowed entry is 1';
         status = true;
      }
      else if (timeType === 2 && additionInfo?.TimeSlote?.length >= 10) {
         error.Multiple = 'Maximum allowed entry is 10';
         status = true;
      }

      // else if (additionInfo?.TimeSlote?.length > 0) {
      //    if (additionInfo?.TimeSlote?.filter(dup => dup?.startTime === timeSlot.startTime || dup?.endtime === timeSlot.endtime)?.length !== 0) {
      //       error.TimeDuplicate = 'Data already exist';
      //       status = true;
      //    }
      // }

      else if (additionInfo?.TimeSlote?.length > 0) {
         if (additionInfo?.TimeSlote?.filter(dup =>
            (new Date(formatTimeToDateTime(timeSlot.startTime)).getTime() >= new Date(formatTimeToDateTime(dup?.startTime)).getTime()
               && new Date(formatTimeToDateTime(timeSlot.startTime)).getTime() <= new Date(formatTimeToDateTime(dup?.endtime)).getTime())
            ||
            (new Date(formatTimeToDateTime(timeSlot.endtime)).getTime() >= new Date(formatTimeToDateTime(dup?.startTime)).getTime()
               && new Date(formatTimeToDateTime(timeSlot.endtime)).getTime() <= new Date(formatTimeToDateTime(dup?.endtime)).getTime()))?.length !== 0) {
            error.TimeDuplicate = 'Data already exist';
            status = true;
         }
      }

      setFormError(error)

      if (Object.keys(error).length > 0) {
         let id = Object.keys(error)[0];
         Ref.current[id]?.focus();
      }
      return status;
   }

   const handleAddTime = (e) => {
      if (!validationTimeAdd()) {
         e.preventDefault();
         setAdditionInfo({ ...additionInfo, TimeSlote: [...additionInfo.TimeSlote, { ...timeSlot, seqNo: additionInfo.TimeSlote.length + 1, }] })
         // setTimeSlotInsertCollection([...TimeSloteInsertCollection, { ...timeSlotInsert, seqNo: TimeSloteInsertCollection.length + 1, }])

         setStartTime(null)
         setEndTime(null)
         setTimeSlot({ ...timeSlot, startTime: '', endtime: '' })
         setTimeSlotInsert({ ...timeSlotInsert, startTime: '', endtime: '' })
      }
   }

   const removeTimeSlot = (e, seqNo) => {
      setIsEditDisable(false)
      setFormError('')
      e.preventDefault();

      setAdditionInfo({
         ...additionInfo,
         TimeSlote: additionInfo.TimeSlote.filter((slot) => slot.seqNo !== seqNo)
      });
      setTimeSlotInsertCollection(TimeSloteInsertCollection?.filter(slot => slot.seqNo !== seqNo))

   };
   //#endregion

   //#region ClosingPeriodDate function
   const openDatePicker = (e) => {
      //open date picker on click date icon 
      document.querySelector("#" + e).click();
   }

   const handleDateChange = (name, date) => {
      setIsEditDisable(false)
      setFormError('')
      if (name === 'closingPeriodFrom') {
         setClosingPeriodFrom(date)
         setClosingPeriod({ ...closingPeriod, closingPeriodFrom: formatDate(date) })
         setClosingPeriodInsert({ ...closingPeriodInsert, closingPeriodFrom: formatDateTime(date) })

      }
      else if (name === 'closingPeriodTo') {
         setClosingPeriodTo(date)
         setClosingPeriod({ ...closingPeriod, closingPeriodTo: formatDate(date) })
         setClosingPeriodInsert({ ...closingPeriodInsert, closingPeriodTo: formatDateTime(date) })
      }
      else if (name === 'fromDate') {
         setFromDate(date)
         setEventDate({ ...eventDate, fromDate: formatDate(date) })
         setEventDateInsert({ ...eventDateInsert, fromDate: formatDateTime(date) })
      }
      else {
         setToDate(date)
         setEventDate({ ...eventDate, toDate: formatDate(date) })
         setEventDateInsert({ ...eventDateInsert, toDate: formatDateTime(date) })
      }
   }

   const validationClosingPeriodDateAdd = () => {
      let status = false
      let error = {}
      if (closingPeriod.closingPeriodFrom === '' || closingPeriod.closingPeriodTo === '') {
         status = true;
      }
      if (closingPeriod.closingPeriodFrom === '' && closingPeriod.closingPeriodTo) {
         error.closingPeriodFrom = 'Kindly select the closing period from date';
         status = true;
      }
      if (closingPeriod.closingPeriodFrom && closingPeriod.closingPeriodTo === '') {
         error.closingPeriodTo = 'Kindly select the closing period to date';
         status = true;
      }
      else if (closingPeriod.closingPeriodFrom && closingPeriod.closingPeriodTo && new Date(closingPeriodFrom) > new Date(closingPeriodTo)) {
         error.closingPeriodTo = 'Closing period To Date should be greater than from date';
         status = true;
      }
      else if (additionInfo?.ClosingPeriod?.length >= 10) {
         error.ClosingPeriodMexEntry = 'Maximum allowed entry is 10';
         status = true;
      }
      // else if (additionInfo?.ClosingPeriod?.length > 0) {
      //    if (additionInfo?.ClosingPeriod?.filter(dup => dup.closingPeriodFrom === closingPeriod.closingPeriodFrom || dup.closingPeriodTo === closingPeriod.closingPeriodTo)?.length !== 0) {
      //       error.ClosingPeriodDuplicate = 'Data already exist';
      //       status = true;
      //    }
      // }
      else if (additionInfo?.ClosingPeriod?.length > 0) {
         if (additionInfo?.ClosingPeriod?.filter(dup =>
            (new Date(formatDateToDateTime(closingPeriod.closingPeriodFrom)) >= new Date(formatDateToDateTime(dup?.closingPeriodFrom))
               && new Date(formatDateToDateTime(closingPeriod.closingPeriodFrom)) <= new Date(formatDateToDateTime(dup?.closingPeriodTo)))
            ||
            (new Date(formatDateToDateTime(closingPeriod.closingPeriodTo)) >= new Date(formatDateToDateTime(dup?.closingPeriodFrom))
               && new Date(formatDateToDateTime(closingPeriod.closingPeriodTo)) <= new Date(formatDateToDateTime(dup?.closingPeriodTo))))?.length !== 0) {
            error.ClosingPeriodDuplicate = 'Data already exist';
            status = true;
         }
      }

      setFormError(error)
      if (Object.keys(error).length > 0) {
         let id = Object.keys(error)[0];
         let doc = document.getElementById(id);
         if (doc !== null)
            doc.focus();
      }
      return status;
   }


   const handleAddClosingPeriodDate = (e) => {
      if (!validationClosingPeriodDateAdd()) {
         e.preventDefault();
         setAdditionInfo({ ...additionInfo, ClosingPeriod: [...additionInfo.ClosingPeriod, { ...closingPeriod, seqNo: additionInfo.ClosingPeriod?.length + 1 }] })
         // setClosingPeriodInsertCollection([...ClosingPeriodInsertCollection, { ...closingPeriodInsert, seqNo: ClosingPeriodInsertCollection?.length + 1 }])

         setClosingPeriodFrom(null)
         setClosingPeriodTo(null)
         setClosingPeriod({ ...closingPeriod, closingPeriodFrom: '', closingPeriodTo: '' })
         setClosingPeriodInsert({ ...closingPeriodInsert, closingPeriodFrom: '', closingPeriodTo: '' })
      }
   }

   const removeClosingPeriod = (e, seqNo) => {
      setIsEditDisable(false)
      setFormError('')
      e.preventDefault();

      setAdditionInfo({
         ...additionInfo,
         ClosingPeriod: additionInfo.ClosingPeriod.filter((closingPeriod) => closingPeriod.seqNo !== seqNo)
      });
      setClosingPeriodInsertCollection(ClosingPeriodInsertCollection?.filter((closingPeriod) => closingPeriod.seqNo !== seqNo))

   };
   //#endregion

   //#region Suggested Time To Visit
   const validationSuggestedTimeAdd = () => {
      let status = false
      let error = {}
      if (suggTime.suggetedTime === '') {
         status = true;
      }
      else if (additionInfo?.SuggestedTimeToVisit?.length >= 10) {
         error.SuggestedTimeMexEntry = 'Maximum allowed entry is 10';
         status = true;
      }
      else if (additionInfo?.SuggestedTimeToVisit?.length > 0) {
         if (additionInfo?.SuggestedTimeToVisit?.filter(dup => dup.suggetedTime === suggTime.suggetedTime)?.length !== 0) {
            error.SuggestedTimeDuplicate = 'Data already exist';
            status = true;
         }
      }

      setFormError(error)
      return status;
   }

   const handleAddSuggestedTime = (e) => {
      if (!validationSuggestedTimeAdd()) {
         e.preventDefault();
         setAdditionInfo({ ...additionInfo, SuggestedTimeToVisit: [...additionInfo.SuggestedTimeToVisit, { ...suggTime, seqNo: additionInfo.SuggestedTimeToVisit.length + 1, }] })
         // setSuggestedTimeToVisitInsertCollection([...SuggestedTimeToVisitInsertCollection, { ...suggTimeInsert, seqNo: SuggestedTimeToVisitInsertCollection.length + 1, }])

         setSuggestedTime(null)
         setSuggTime({ ...suggTime, suggetedTime: '' })
         setSuggTimeInsert({ ...suggTimeInsert, suggetedTime: '' })
      }
   }

   const removeSuggestedTime = (e, seqNo) => {
      setIsEditDisable(false);
      setFormError('');
      e.preventDefault();

      setAdditionInfo({
         ...additionInfo,
         SuggestedTimeToVisit: additionInfo.SuggestedTimeToVisit.filter((slot) => slot.seqNo !== seqNo)
      });
      setSuggestedTimeToVisitInsertCollection(SuggestedTimeToVisitInsertCollection?.filter(slot => slot.seqNo !== seqNo));
   };
   //#endregion

   //#region Description
   const handleDescription = (e) => {
      setIsEditDisable(false)
      // setAdditionInfo({ ...additionInfo, Description: e.target.value })
      setFormValue({ ...formValue, RecommendedDescription: e.target.value })
   }
   //#endregion

   //#region Special Event

   const handleSpecialEventRadio = (e, name) => {
      setFormError('')
      setEventName('')
      setFromDate(null)
      setToDate(null)
      setEventDate({ ...eventDate, eventName: '', fromDate: '', toDate: '' })
      setEventDateInsert({ ...eventDateInsert, eventName: '', fromDate: '', toDate: '' })
      setAdditionInfo({ ...additionInfo, EventDate: [] })
      setSpecialEventInsertCollection([])
      setIsEditDisable(false)
      if (name === 'eventyes') {
         setSpecialEvent(true)
      }
      else {
         setSpecialEvent(false)
      }
   }

   const handleEventNameChange = (e) => {
      setIsEditDisable(false)
      setFormError('')
      setEventName(e.target.value)
      setEventDate({ ...eventDate, eventName: e.target.value })
      setEventDateInsert({ ...eventDateInsert, eventName: e.target.value })
   }

   const validationEventDateAdd = () => {
      let status = false
      let error = {}

      if (eventName === '' || eventDate.fromDate === '' || eventDate.fromDate === '') {
         status = true;
      }
      if (eventName === '' && (eventDate.fromDate || eventDate.toDate)) {
         error.eventName = 'Kindly enter the event name';
         status = true;
      }
      if (eventDate.fromDate === '' && (eventName || eventDate.toDate)) {
         error.fromDate = 'Kindly select the from date';
         status = true;
      }
      if (eventDate.toDate === '' && (eventName || eventDate.fromDate)) {
         error.toDate = 'Kindly select To date';
         status = true;
      }
      else if (new Date(fromDate) > new Date(toDate)) {
         error.toDate = 'To Date should be greater than from date';
         status = true;
      }
      else if (additionInfo?.EventDate?.length >= 10) {
         error.EventDateMexEntry = 'Maximum allowed entry is 10';
         status = true;
      }
      // else if (additionInfo?.EventDate?.length > 0) {
      //    if (additionInfo?.EventDate?.filter(dup => dup.fromDate === eventDate.fromDate || dup.toDate === eventDate.toDate)?.length !== 0) {
      //       error.EventDateMexEntryDuplicate = 'Data already exist';
      //       status = true;
      //    }
      // }

      else if (additionInfo?.EventDate?.length > 0) {
         if (additionInfo?.EventDate?.filter(dup =>
            (new Date(formatDateToDateTime(eventDate.fromDate)) >= new Date(formatDateToDateTime(dup?.fromDate))
               && new Date(formatDateToDateTime(eventDate.fromDate)) <= new Date(formatDateToDateTime(dup?.toDate)))
            ||
            (new Date(formatDateToDateTime(eventDate.toDate)) >= new Date(formatDateToDateTime(dup?.fromDate))
               && new Date(formatDateToDateTime(eventDate.toDate)) <= new Date(formatDateToDateTime(dup?.toDate))))?.length !== 0) {
            error.EventDateMexEntryDuplicate = 'Data already exist';
            status = true;
         }
      }

      setFormError(error)
      if (Object.keys(error).length > 0) {
         let id = Object.keys(error)[0];
         let doc = document.getElementById(id);
         if (doc !== null)
            doc.focus();
      }
      return status;
   }


   const handleAddEventDate = (e) => {
      if (!validationEventDateAdd()) {
         e.preventDefault();
         setAdditionInfo({ ...additionInfo, EventDate: [...additionInfo.EventDate, { ...eventDate, seqNo: additionInfo.EventDate.length + 1 }] })
         // setSpecialEventInsertCollection([...SpecialEventInsertCollection, { ...eventDateInsert, seqNo: SpecialEventInsertCollection?.length + 1 }])

         setEventName('')
         setFromDate(null)
         setToDate(null)
         setEventDate({ ...eventDate, eventName: '', fromDate: '', toDate: '' })
         setEventDateInsert({ ...eventDateInsert, eventName: '', fromDate: '', toDate: '' })
      }
   }

   const removeEventDate = (e, seqNo) => {
      setIsEditDisable(false)
      setFormError('')
      e.preventDefault();

      setAdditionInfo({
         ...additionInfo,
         EventDate: additionInfo.EventDate.filter((EventDate) => EventDate.seqNo !== seqNo)
      });
      setSpecialEventInsertCollection(SpecialEventInsertCollection?.filter((EventDate) => EventDate.seqNo !== seqNo))


   };
   //#endregion

   //#region Add/Update Data

   const validationData = () => {
      let status = false
      let error = {}

      if (additionInfo?.TimeSlote?.length === 0) {
         if (timeSlot.startTime === '') {
            error.startTime = 'Kindly specify the start time';
            status = true;
         }
         if (timeSlot.endtime === '') {
            error.endtime = 'Kindly specify the end time';
            status = true;
         }
         else if (additionInfo?.TimeSlote?.length === 0) {
            error.TimeDuplicate = 'Please select at least one start time and end time';
            status = true;
         }
      }
      setFormError(error)
      if (Object.keys(error).length > 0) {
         let id = Object.keys(error)[0];
         Ref.current[id]?.focus();
      }
      return status;
   }

   const handleAddUpdateBtn = async () => {

      let TimeSlotColl = JSON.parse(JSON.stringify(additionInfo.TimeSlote))?.map(obj => {
         obj.startTime = formatTimeToDateTime(obj.startTime)
         obj.endtime = formatTimeToDateTime(obj.endtime)
         return obj;
      })

      let ClosingPeriodColl = JSON.parse(JSON.stringify(additionInfo.ClosingPeriod))?.map(obj => {
         obj.closingPeriodFrom = formatDateToDateTime(obj.closingPeriodFrom)
         obj.closingPeriodTo = formatDateToDateTime(obj.closingPeriodTo)
         return obj;
      })

      let EventDateColl = JSON.parse(JSON.stringify(additionInfo.EventDate))?.map(obj => {
         obj.fromDate = formatDateToDateTime(obj.fromDate)
         obj.toDate = formatDateToDateTime(obj.toDate)
         return obj;
      })


      let SuggestionColl = JSON.parse(JSON.stringify(additionInfo.SuggestedTimeToVisit))?.map(obj => {
         obj.suggetedTime = formatTimeToDateTime(obj.suggetedTime)
         return obj;
      })



      formValue.AttractionTimeTypeId = timeType
      // formValue.AttractionTimeSlotCollection = TimeSloteInsertCollection || []
      formValue.AttractionTimeSlotCollection = TimeSlotColl || []
      // formValue.AttractionClosingPeriodCollection = ClosingPeriodInsertCollection || []
      formValue.AttractionClosingPeriodCollection = ClosingPeriodColl || []
      formValue.RecommendedDuration = recomDurationInsert || null
      // formValue.AttractionSuggetedTimeCollection = SuggestedTimeToVisitInsertCollection || []
      formValue.AttractionSuggetedTimeCollection = SuggestionColl || []
      formValue.IsSpecialEvent = specialEvent
      // formValue.AttractionSplEventCollection = SpecialEventInsertCollection || []
      formValue.AttractionSplEventCollection = EventDateColl || []
      formValue.DressCode = CodeOfConductCollection.DressCode
      formValue.ThingsToCarry = CodeOfConductCollection.ThingsToCarry
      formValue.ThingsNotToCarry = CodeOfConductCollection.ThingsNotToCarry
      formValue.EntranceRegulations = CodeOfConductCollection.EntranceRegulations
      formValue.SpecialComments = CodeOfConductCollection.SpecialComments

      formValue.RailwayStationCollection = additionInfo.AccessPoints.trainStation
      formValue.BusStandCollection = additionInfo.AccessPoints.busStation
      formValue.AirportCollection = additionInfo.AccessPoints.airPort
      formValue.PortCollection = additionInfo.AccessPoints.port

      formValue.AttractionFacilityCollection = additionInfo.AttractionFacilityCollection
      formValue.HospitalCollection = additionInfo.NearByFacilities.Hospital
      formValue.PoliceStationCollection = additionInfo.NearByFacilities.PoliceStation
      formValue.ShoppingCollection = additionInfo.NearByFacilities.Shopping
      formValue.GalleryCollection = additionInfo.NearByFacilities.Gallery

      formValue.WeekDayCollection = weekDaysCollection

      submitData()
   }
   const submitData = async () => {
      let statusoftoken = await verifytoken();
      if (statusoftoken) {
         const insertattractioninformation = await postUsersData('/master/v1/insertattractioninformation', formValue)
         if (insertattractioninformation.status) {
            if (insertattractioninformation.data > 0) {
               dispatch({ type: 'ALERTINGMESSAGE', message: insertattractioninformation.message })
               handleClear();
            }
            else {
               dispatch({ type: 'ALERTINGMESSAGE', message: insertattractioninformation.message })
               return;
            }
         }
         else {
            dispatch({ type: 'ALERTINGMESSAGE', message: insertattractioninformation.message })
            return;
         }
      }
      else {
         ClearLocalStorage();
         dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
      }
   }

   //#endregion
   useEffect(() => {
      dispatch(loaderActions.start())
      getrelativemaster()
   }, []);


   //#region Retrieve Data
   useEffect(() => {
      if (attractionId > 0) {
         setSaveDisabled(disabled)
         handleRetrive();
      }
   }, [attractionId]);

   const handleRetrive = async () => {
      await getAllData('/master/v1/getattractioninforbyid?AttractionBasicId=' + attractionId).then(res => {
         res?.data?.map((obj) => {

            setTimeType(obj?.AttractionTimeTypeId)

            let timecollectiongrid = (obj?.TimeCollection && JSON.parse(JSON.stringify(obj?.TimeCollection))?.map(time => {
               time.startTime = formatTime(time.startTime)
               time.endtime = formatTime(time.endtime)
               return time;
            })) || []

            let closingperiodcollectiongrid = (obj?.ClosingPeriodCollection && JSON.parse(JSON.stringify(obj?.ClosingPeriodCollection))?.map(date => {
               date.closingPeriodFrom = formatDate(date.closingPeriodFrom)
               date.closingPeriodTo = formatDate(date.closingPeriodTo)
               return date;
            })) || []

            let suggetedTimecollection = (obj?.SuggetedTime && JSON.parse(JSON.stringify(obj?.SuggetedTime))?.map(sugg => {
               sugg.suggetedTime = formatTime(sugg.suggetedTime)
               return sugg;
            })) || []

            let specialEventcollection = (obj?.SpeciallEvent && JSON.parse(JSON.stringify(obj?.SpeciallEvent))?.map(event => {
               event.fromDate = formatDate(event.fromDate)
               event.toDate = formatDate(event.toDate)
               return event;
            })) || []

            setTimeSlotInsertCollection(obj?.TimeCollection)
            setClosingPeriodInsertCollection(obj?.ClosingPeriodCollection)

            setAdditionInfo({
               ...additionInfo,
               OperatedDay: obj?.WeekdayElement,
               TimeSlote: timecollectiongrid,
               ClosingPeriod: closingperiodcollectiongrid,
               SuggestedTimeToVisit: suggetedTimecollection,
               EventDate: specialEventcollection,
               AccessPoints: {
                  trainStation: obj?.RailwayStation || [],
                  busStation: obj?.BusStandElement || [],
                  airPort: obj?.AirportElement || [],
                  port: obj?.PortElement || [],
               },
               AttractionFacilityCollection: obj?.AttractionFacilityElement || [],
               NearByFacilities: {
                  Hospital: obj?.HospitalElement || [],
                  PoliceStation: obj?.PolicyStationElement || [],
                  Shopping: obj?.ShopingElement || [],
                  Gallery: obj?.GalleryElement || []
               },

            })

            setbuttonaddeditText(obj?.AttractionInformationId > 0 ? 'Update' : 'Add');
            setClearButtonDisable(obj?.AttractionInformationId > 0 ? true : false)

            setFormValue({ ...formValue, RecommendedDescription: obj?.RecommendedDescription })

            let duration = formatHourMinut(obj?.RecommendedDuration?.hours, obj?.RecommendedDuration?.minutes)
            setRecommendedDuration(duration ? dayjs(duration) : null)
            setRecomDurationInsert(duration)
            setSuggestedTimeToVisitInsertCollection(obj?.SuggetedTime)

            setSpecialEvent(obj?.IsSpecialEvent)
            setSpecialEventInsertCollection(obj?.SpeciallEvent)

            let conductCollection = {}
            conductCollection.DressCode = obj?.DressCode
            conductCollection.ThingsToCarry = obj?.ThingsToCarry
            conductCollection.ThingsNotToCarry = obj?.ThingsNotToCarry
            conductCollection.EntranceRegulations = obj?.EntranceRegulations
            conductCollection.SpecialComments = obj?.SpecialComments

            setCodeOfConductCollection(conductCollection);

            let accesspointdata = {}
            accesspointdata.trainStation = obj?.RailwayStation
            accesspointdata.busStation = obj?.BusStandElement
            accesspointdata.airPort = obj?.AirportElement
            accesspointdata.port = obj?.PortElement

            setAccessPointRetieveData(accesspointdata);

         })
      })
   }
   //#endregion

   //#region AccessPoint Collection Retrieve
   useMemo(() => {

      //Railway
      let railwaycollection = (accessPointRetieveData?.trainStation && JSON.parse(JSON.stringify(accessPointRetieveData?.trainStation))?.map(rail => {
         rail.accessPointLabel = accessPointList?.railwayStationList?.map(val => {
            if (rail.accessPointId === val.accessPointRailwayId) {
               return val.railwayStationName
            }
         }).find(x => x !== undefined)

         return rail;
      })) || []

      railwaycollection?.map(obj => {
         delete obj.accessPointId
      })

      //Bus
      let buscollection = (accessPointRetieveData?.busStation && JSON.parse(JSON.stringify(accessPointRetieveData?.busStation))?.map(rail => {
         rail.accessPointLabel = accessPointList?.busStandList?.map(val => {
            if (rail.accessPointId === val.accessPointBusId) {
               return val.busStandName
            }
         }).find(x => x !== undefined)

         return rail;
      })) || []

      buscollection?.map(obj => {
         delete obj.accessPointId
      })

      //Airport
      let airportcollection = (accessPointRetieveData?.airPort && JSON.parse(JSON.stringify(accessPointRetieveData?.airPort))?.map(rail => {
         rail.accessPointLabel = accessPointList?.airportList?.map(val => {
            if (rail.accessPointId === val.accessPointAirportId) {
               return val.airportName
            }
         }).find(x => x !== undefined)

         return rail;
      })) || []

      airportcollection?.map(obj => {
         delete obj.accessPointId
      })

      //Port
      let portcollection = (accessPointRetieveData?.port && JSON.parse(JSON.stringify(accessPointRetieveData?.port))?.map(rail => {
         rail.accessPointLabel = accessPointList?.portList?.map(val => {
            if (rail.accessPointId === val.accessPointPortId) {
               return val.portName
            }
         }).find(x => x !== undefined)

         return rail;
      })) || []

      portcollection?.map(obj => {
         delete obj.accessPointId
      })

      // setAdditionInfo({
      //    ...additionInfo,
      //    AccessPointsLabels: {
      //       trainStation: railwaycollection
      //    },
      // })

      setAdditionInfo({
         ...additionInfo,
         AccessPointsLabels: {
            ...additionInfo.AccessPointsLabels,
            trainStation: railwaycollection,
            busStation: buscollection,
            airPort: airportcollection,
            port: portcollection,
         }
      });


   }, [accessPointList, accessPointRetieveData])

   //#endregion

   //#region Clear Function
   const handleClear = () => {
      setFormError('')

      setAdditionInfo({
         ...additionInfo,
         OperatedDay: [
            { weekDayId: 1, weekDayName: 'All', isCheck: false },
            { weekDayId: 2, weekDayName: 'Monday', isCheck: false },
            { weekDayId: 3, weekDayName: 'Tuesday', isCheck: false },
            { weekDayId: 4, weekDayName: 'Wednesday', isCheck: false },
            { weekDayId: 5, weekDayName: 'Thursday', isCheck: false },
            { weekDayId: 6, weekDayName: 'Friday', isCheck: false },
            { weekDayId: 7, weekDayName: 'Saturday', isCheck: false },
            { weekDayId: 8, weekDayName: 'Sunday', isCheck: false }
         ],

         TimeSlote: [],
         ClosingPeriod: [],
         SuggestedTimeToVisit: [],
         Description: '',
         Type: '',
         EventDate: [],

         AttractionFacilityCollection: [],

         AccessPoints: {
            trainStation: [],
            busStation: [],
            airPort: [],
            port: []
            ,
         },

         AccessPointsLabels: {
            trainStation: [],
            busStation: [],
            airPort: [],
            port: []
            ,
         },

         NearByFacilities: {
            Hospital: [],
            PoliceStation: [],
            Shopping: [],
            Gallery: []
         },
      })

      setTimeType(1)
      setStartTime(null)
      setEndTime(null)
      setTimeSlot({ ...timeSlot, startTime: '', endtime: '' })
      setTimeSlotInsert({ ...timeSlotInsert, startTime: '', endtime: '' })
      setTimeSlotInsertCollection([])

      setClosingPeriodFrom(null)
      setClosingPeriodTo(null)
      setClosingPeriod({ ...closingPeriod, closingPeriodFrom: '', closingPeriodTo: '' })
      setClosingPeriodInsert({ ...closingPeriodInsert, closingPeriodFrom: '', closingPeriodTo: '' })
      setClosingPeriodInsertCollection([])

      setRecommendedDuration(null)
      setRecomDuration('')
      setRecomDurationInsert('')
      setSuggestedTime(null)
      setSuggTime({ ...suggTime, suggetedTime: '' })
      setSuggTimeInsert({ ...suggTimeInsert, suggetedTime: '' })
      setSuggestedTimeToVisitInsertCollection([])

      setSpecialEvent(true)
      setEventName('')
      setFromDate(null)
      setToDate(null)
      setEventDate({ ...eventDate, eventName: '', fromDate: '', toDate: '' })
      setEventDateInsert({ ...eventDateInsert, eventName: '', fromDate: '', toDate: '' })
      setSpecialEventInsertCollection([])
   }
   //#endregion

   //#region Operated days Collection
   useMemo(() => {
      let weekDays = JSON.parse(JSON.stringify(additionInfo.OperatedDay))?.filter(obj => obj.isCheck)
      weekDays?.map(val => {
         delete val.weekDayName
         delete val.isCheck
         return val
      })
      setWeekDaysCollection(weekDays)
   }, [additionInfo.OperatedDay])
   //#endregion

   //#region Format Date and Time
   function formatDate(date) {
      var d = new Date(date),
         month = '' + (d.getMonth() + 1),
         day = '' + d.getDate(),
         year = d.getFullYear();

      if (month.length < 2)
         month = '0' + month;
      if (day.length < 2)
         day = '0' + day;

      return date === null ? '' : [day, month, year].join('/');
   }

   function formatTime(time) {
      let x = new Date(time)
      let hours = x.getHours();
      let minut = x.getMinutes();
      if (hours < 10) {
         hours = '0' + hours
      }
      if (minut < 10) {
         minut = '0' + minut
      }
      return time === null ? '' : [hours, minut].join(':');
   }

   function formatDateTime(time) {
      let x = new Date(time)

      let month = '' + (x.getMonth() + 1),
         day = '' + x.getDate(),
         year = x.getFullYear();

      if (month.length < 2)
         month = '0' + month;
      if (day.length < 2)
         day = '0' + day;

      let date = [year, month, day].join('-')

      let hours = x.getHours();
      let minut = x.getMinutes();
      if (hours < 10) {
         hours = '0' + hours
      }
      if (minut < 10) {
         minut = '0' + minut
      }
      return time === null ? null : date + 'T' + hours + ':' + minut + ':00';
   }

   function formatHourMinut(hours, minut) {

      if (hours < 10) {
         hours = '0' + hours
      }
      if (minut < 10) {
         minut = '0' + minut
      }
      // let time = new Date();
      let x = new Date()

      let month = '' + (x.getMonth() + 1),
         day = '' + x.getDate(),
         year = x.getFullYear();

      if (month.length < 2)
         month = '0' + month;
      if (day.length < 2)
         day = '0' + day;

      let date = [year, month, day].join('-')

      return ((hours === null || minut === null) || (hours === undefined || minut === undefined)) ? null : date + 'T' + hours + ':' + minut + ':00';
   }

   function formatTimeToDateTime(time) {

      let x = new Date()

      let month = '' + (x.getMonth() + 1),
         day = '' + x.getDate(),
         year = x.getFullYear();

      if (month.length < 2)
         month = '0' + month;
      if (day.length < 2)
         day = '0' + day;

      let date = [year, month, day].join('-')

      return (time === null || time === '' || time === undefined) ? null : date + 'T' + time + ':00';
   }

   function formatDateToDateTime(d) {
      let date = d.split('/')
      let dt = [date[2], date[1], date[0]].join('-')

      return d === null ? null : dt + 'T00:00:00';
   }
   //#endregion


   return (
      <div className='container form_border py-3'>

         {/* Operated Day */}
         <div className='container form_border py-5'>
            <form action=''>
               <div className='col-lg-12 col-md-12 col-sm-12 px-md-4 px-lg-4'>

                  <div className=' row'>
                     <div className='col-lg-2 col-md-3 col-sm-12'>
                        <label className="col-form-label">Operated Day</label>
                     </div>


                     <div className='col-lg-10 col-md-9 col-sm-12 mt-2 mr-4'>
                        {additionInfo.OperatedDay && additionInfo.OperatedDay?.map((option) => {
                           return (
                              <div className=" form-lg-check form-check-inline me-2" key={option.value}>
                                 <input
                                    className="form-check-input h-5 me-2"
                                    name={option.weekDayId}
                                    type="checkbox"
                                    id="checkboxNoLabelm"
                                    checked={option.isCheck}
                                    //aria-label="..."
                                    //  value={ele.PackageMenuId} key={ele.PackageMenuId} onChange={() => showDivHandlerD(ele.PackageMenuId)} disabled={ele.PackageMenuId === 3 ? true : false}/>
                                    aria-label="..."
                                    value={option.weekDayId}
                                    key={option.weekDayId}
                                    onChange={(e) => handleOperatedDay(e.target.name, e.target.checked)}
                                    disabled={disabled ? true : false}
                                 />
                                 <label className="mx-1" disabled={disabled ? true : false}>{option.weekDayName}</label>
                              </div>
                           );
                        })
                        }
                     </div>
                  </div>

                  <div className='row mt-lg-3 mt-md-3 '>
                     <div className='col-lg-2 col-md-3 col-sm-12'>
                        <label className="col-form-label">Type</label>
                     </div>
                     <div className='col-lg-10 col-md-5 col-sm-6 d-lg-flex align-self-center '>

                        {attractionTimeTypeList && attractionTimeTypeList?.map((obj, index) => {
                           return (
                              <div className="form-check-inline col-lg-6 col-md-6 col-12 me-0">
                                 <input
                                    className="form-check-input"
                                    type="radio"
                                    name="TimeType"
                                    id={"TimeType" + obj?.attractionTimeTypeId}
                                    value={obj?.attractionTimeTypeId}
                                    key={obj?.attractionTimeTypeId}
                                    checked={obj?.attractionTimeTypeId === timeType ? true : false}
                                    // onChange={(e) => handleServiceTypeChange(obj?.attractionTimeTypeId, e)}
                                    onChange={(e) => {
                                       handleTimeTypeRadio(e, obj?.attractionTimeTypeId);
                                    }}
                                    disabled={disabled ? true : false}
                                 />
                                 <label className="form-check-label ms-1" htmlFor={"TimeType" + obj?.attractionTimeTypeId} >{obj?.informationType}</label>
                              </div>
                           );
                        })}

                     </div>
                  </div>

                  <div className='row mt-lg-3'>
                     {formError.Continuouse && <div className="text-danger text-center mb-2">{formError.Continuouse} </div>}
                     {formError.Multiple && <div className="text-danger text-center mb-2">{formError.Multiple} </div>}
                     {formError.TimeDuplicate && <div className="text-danger text-center mb-2">{formError.TimeDuplicate} </div>}
                     <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='row'>
                           <div className='col-lg-4 col-md-12 col-sm-12'>
                              <label className="col-form-label">Start Time</label>
                           </div>
                           <div className='col-lg-8 col-md-12 col-sm-12'>

                              <TimePicker
                                 ref={(el) => (Ref.current.startTime = el)}
                                 id='startTime'
                                 className='form-control defo_border'
                                 disabled={disabled ? true : false}
                                 placeholder='--:--'
                                 format='HH:mm'
                                 value={startTime}
                                 onChange={(e) => handleTime(e, 'startTime')}
                              />
                              {formError.startTime && <div className="text-danger"> {formError.startTime} </div>}
                           </div>
                        </div>
                     </div>
                     <div className='col-lg-6 col-md-6 col-sm-12 '>
                        <div className='row'>
                           <div className='col-lg-4 col-md-12 col-sm-12'>
                              <label className="col-form-label">End Time</label>
                           </div>
                           <div className='col-lg-8 col-md-12 col-sm-12'>
                              <TimePicker
                                 ref={(el) => (Ref.current.endtime = el)}
                                 id='endtime'
                                 className='form-control defo_border'
                                 disabled={disabled ? true : false}
                                 placeholder='--:--'
                                 format='HH:mm'
                                 value={endTime}
                                 onChange={(e) => handleTime(e, 'endtime')}
                              />
                              {formError.endtime && <div className="text-danger"> {formError.endtime} </div>}
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="col-lg-12 col-md-12 col-sm-12 justify-content-center d-flex mt-4">
                     <button type="button"
                        onClick={(e) => handleAddTime(e)}
                        className="btn btn-primary mx-3" id="btncontactAdd"
                        disabled={disabled ? true : false}
                     >Add</button>
                  </div>



                  <div className="row mt-4">
                     <div className="col-lg-12 mb-1 table-scrollable">
                        <table id="data" className="table table-hover" >
                           <thead>
                              <tr>
                                 <th className='text-start'>Start Time
                                 </th>
                                 <th className='text-center'>End Time
                                 </th>
                                 <th className='text-end'>Remove
                                 </th>
                              </tr>
                           </thead>
                           <tbody>
                              {additionInfo.TimeSlote && additionInfo.TimeSlote?.map((element, index) => {
                                 return (
                                    <tr className="ReactTable" key={element.seqNo}>
                                       <td className='text-start'>{element.startTime}</td>
                                       <td className='text-center'>{element.endtime}</td>
                                       <td className='text-end'><button type="button"
                                          onClick={(e) => { removeTimeSlot(e, element.seqNo) }}
                                          className="btn btn-primary mx-1"
                                          id="btnAdd"
                                          disabled={disabled}
                                       >Remove</button></td>
                                    </tr>
                                 );
                              })}
                           </tbody>
                        </table>
                     </div>
                  </div>


                  <div className='row mt-3'>
                     {formError.ClosingPeriodDuplicate && <div className="text-danger text-center mb-2">{formError.ClosingPeriodDuplicate} </div>}
                     {formError.ClosingPeriodMexEntry && <div className="text-danger text-center mb-2">{formError.ClosingPeriodMexEntry} </div>}
                     <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='row'>
                           <div className='col-lg-4 col-md-12 col-sm-12'>
                              <label className="col-form-label">Closing Period From</label>
                           </div>
                           <div className='col-lg-8 col-md-12 col-sm-12'>
                              <div className="text-container">
                                 <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className='form-control'
                                    id='closingPeriodFrom'
                                    selected={closingPeriodFrom}
                                    onChange={(e) => handleDateChange('closingPeriodFrom', e)}
                                    disabled={disabled ? true : false}
                                    placeholderText="dd/mm/yyyy"
                                    autoComplete='off'
                                    minDate={new Date()}
                                 />
                                 <span className="example-custom-input calendarBtn" onClick={(e) => openDatePicker("closingPeriodFrom")} id="calendarBtn1" disabled={disabled ? true : false} ><i className="fa fa-calendar"></i></span>
                              </div>
                              {formError.closingPeriodFrom && <div className="text-danger">{formError.closingPeriodFrom} </div>}
                           </div>
                        </div>
                     </div>
                     <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='row'>
                           <div className='col-lg-4 col-md-12 col-sm-12'>
                              <label className="col-form-label">Closing Period To</label>
                           </div>
                           <div className='col-lg-8 col-md-12 col-sm-12'>
                              <div className="text-container">
                                 <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className='form-control'
                                    id='closingPeriodTo'
                                    selected={closingPeriodTo}
                                    onChange={(e) => handleDateChange('closingPeriodTo', e)}
                                    disabled={disabled ? true : false}
                                    placeholderText="dd/mm/yyyy"
                                    autoComplete='off'
                                    minDate={new Date()}
                                 />
                                 <span className="example-custom-input calendarBtn" onClick={(e) => openDatePicker("closingPeriodTo")} id="calendarBtn1" disabled={disabled ? true : false} ><i className="fa fa-calendar"></i></span>
                              </div>
                              {formError.closingPeriodTo && <div className="text-danger">{formError.closingPeriodTo} </div>}
                           </div>
                        </div>
                     </div>
                  </div>



                  <div className="col-lg-12 col-md-12 col-sm-12 justify-content-center d-flex mt-4">
                     <button type="button"
                        onClick={(e) => handleAddClosingPeriodDate(e)}
                        className="btn btn-primary mx-1" id="btncontactAdd"
                        disabled={disabled ? true : false}
                     >Add</button>
                  </div>


                  {/* table */}
                  <div className="row mt-4">
                     <div className="col-lg-12 mb-1 table-scrollable">
                        <table id="data" className="table table-hover" >
                           <thead>
                              <tr>
                                 <th className='text-start'>Closing Period From
                                 </th>
                                 <th className='text-center'>Closing Period To
                                 </th>
                                 <th className='text-end'>Remove
                                 </th>
                              </tr>
                           </thead>
                           <tbody id="location-data">
                              {additionInfo.ClosingPeriod && additionInfo.ClosingPeriod?.map((element, index) => {
                                 return (
                                    <tr className="ReactTable" key={element.seqNo}>
                                       <td className='text-start'>{element.closingPeriodFrom}</td>
                                       <td className='text-center'>{element.closingPeriodTo}</td>
                                       <td className='text-end'><button type="button"
                                          onClick={(e) => { removeClosingPeriod(e, element.seqNo) }}
                                          className="btn btn-primary mx-1"
                                          id="btnAdd"
                                          disabled={disabled}>Remove</button></td>
                                    </tr>
                                 );
                              })}
                           </tbody>
                        </table>
                     </div>
                  </div>


                  <div className='row mt-3'>
                     <div className='col-lg-2 col-md-12 col-sm-12 '>
                        <label className="col-form-label">Recommended Duration</label>
                     </div>
                     <div className='col-lg-3 col-md-8 col-sm-8 col-8'>

                        <TimePicker id='DepTime'
                           className='form-control defo_border'
                           disabled={disabled ? true : false}
                           placeholder='--:--'
                           format='HH:mm'
                           value={recommendedDuration}
                           onChange={(e) => handleTime(e, 'RecommendedDuration')}
                        />
                     </div>

                  </div>

                  <div className='row mt-lg-3 mt-md-3 mt-sm-1'>
                     <div className='col-lg-2 col-md-12 col-sm-12 '>
                        <label className="col-form-label">Suggested time to visit</label>
                     </div>
                     <div className='col-lg-3 col-md-8 col-sm-8 col-8'>

                        <TimePicker id='DepTime'
                           className='form-control defo_border'
                           disabled={disabled ? true : false}
                           placeholder='--:--'
                           format='HH:mm'
                           value={suggetedTime}
                           onChange={(e) => handleTime(e, 'SuggestedTime')}
                        />
                        {formError.SuggestedTimeMexEntry && <div className="text-danger ">{formError.SuggestedTimeMexEntry} </div>}
                        {formError.SuggestedTimeDuplicate && <div className="text-danger ">{formError.SuggestedTimeDuplicate} </div>}
                     </div>

                     <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                        <button type="button"
                           onClick={(e) => handleAddSuggestedTime(e)}
                           className=" btn btn-primary mx-1" id="btncontactAdd"
                           disabled={disabled ? true : false}
                        >Add</button>
                     </div>
                  </div>

                  {
                     additionInfo.SuggestedTimeToVisit && additionInfo.SuggestedTimeToVisit.map((time, index) => (
                        <div className='row mt-3' key={index}>
                           <div className='col-lg-2 d-lg-block d-none'>
                              <label className="col-form-label "></label>
                           </div>
                           <div className='col-lg-3 col-md-8 col-sm-8 col-8'>
                              <div className=" form-lg-check form-lg-check-inline w-100">
                                 <input className="form-control"
                                    type="text"
                                    value={time.suggetedTime}
                                    disabled={true}
                                 />
                              </div>
                           </div>
                           <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                              <button type="button"
                                 onClick={(e) => removeSuggestedTime(e, time.seqNo)}
                                 className="btn btn-primary mx-1" id="btncontactAdd"
                                 disabled={disabled ? true : false}
                              >X</button>
                           </div>
                        </div>
                     ))
                  }

                  <div className='row mt-3'>
                     <div className='col-lg-2'>
                        <label className="col-form-label">Description</label>
                     </div>
                     <div className='col-lg-10'>
                        <div className=" form-lg-check form-lg-check-inline w-100">
                           <textarea className="form-control"
                              id="Description"
                              rows="4"
                              name="Description"
                              disabled={disabled ? true : false}
                              value={formValue.RecommendedDescription}
                              onChange={(e) => handleDescription(e)}
                              placeholder='Description'
                           >
                           </textarea>
                        </div>
                     </div>
                  </div>



               </div>
            </form>
         </div>

         {/* Special Event */}
         <div className='container form_border py-2 my-4'>
            <form action=''>
               <div className='col-lg-12 col-md-12 col-sm-12 px-lg-4 px-md-4'>

                  <div className='row mt-lg-3 mt-md-3 '>
                     <div className='col-lg-2 col-md-3 col-sm-12'>
                        <label className="col-form-label">Special Event</label>
                     </div>
                     <div className='col-lg-4 col-md-5 col-sm-6 d-lg-flex align-self-center '>

                        <div className=" form-check form-check-inline">
                           <input
                              className="form-check-input"
                              type="radio"
                              name="SpecialEventRadio"
                              id="eventyes"
                              value="yes"
                              checked={specialEvent === true ? true : false}
                              onChange={(e) => {
                                 handleSpecialEventRadio(e, 'eventyes');
                              }}
                              disabled={disabled ? true : false}
                           />
                           <label className="form-check-label" htmlFor="eventyes">Yes</label>
                        </div>
                     </div>

                     <div className='col-lg-4 col-md-4 col-sm-6 d-lg-flex align-self-center '>
                        <div className=" form-check form-check-inline">
                           <input
                              className="form-check-input"
                              type="radio"
                              name="SpecialEventRadio"
                              id="eventno"
                              value="no"
                              checked={specialEvent === false ? true : false}
                              onChange={(e) => {
                                 handleSpecialEventRadio(e, 'eventno');
                              }}
                              disabled={disabled ? true : false}
                           />
                           <label className="form-check-label" htmlFor="eventno">No</label>
                        </div>
                     </div>

                  </div>

                  <div className='row mt-3'>
                     {formError.EventDateMexEntry && <div className="text-center text-danger mb-2">{formError.EventDateMexEntry} </div>}
                     {formError.EventDateMexEntryDuplicate && <div className="text-center text-danger mb-2">{formError.EventDateMexEntryDuplicate} </div>}
                     <div className='col-lg-6'>
                        <div className='row'>
                           <div className='col-lg-4 col-md-12 col-sm-12'>
                              <label className="col-form-label">Event Name</label>
                           </div>
                           <div className='col-lg-7 col-md-12 col-sm-12'>
                              <div className=" form-lg-check form-lg-check-inline w-100">
                                 <input className="form-control"
                                    type="text"
                                    id='EventName'
                                    name="EventName"
                                    maxLength={150}
                                    value={eventName}
                                    onChange={(e) => handleEventNameChange(e)}
                                    disabled={disabled || !specialEvent ? true : false}
                                    placeholder='Event Name'
                                 />
                              </div>
                              {formError.eventName && <div className="text-danger">{formError.eventName} </div>}
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className='row mt-lg-3'>
                     <div className='col-lg-6'>
                        <div className='row'>
                           <div className='col-lg-4 col-md-12 col-sm-12'>
                              <label className="col-form-label">From Date</label>
                           </div>
                           <div className='col-lg-7 col-md-12 col-sm-12'>
                              <div className="text-container">
                                 <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className='form-control'
                                    id='fromDate'
                                    selected={fromDate}
                                    onChange={(e) => handleDateChange('fromDate', e)}
                                    disabled={disabled || !specialEvent ? true : false}
                                    placeholderText="dd/mm/yyyy"
                                    autoComplete='off'
                                    minDate={new Date()}
                                 />
                                 <span className="example-custom-input calendarBtn" onClick={(e) => openDatePicker("fromDate")} id="calendarBtn1" disabled={disabled ? true : false} ><i className="fa fa-calendar"></i></span>
                              </div>
                              {formError.fromDate && <div className="text-danger">{formError.fromDate} </div>}
                           </div>
                        </div>
                     </div>
                     <div className='col-lg-6'>
                        <div className='row'>
                           <div className='col-lg-4 col-md-12 col-sm-12'>
                              <label className="col-form-label">To Date</label>
                           </div>
                           <div className='col-lg-7 col-md-12 col-sm-12'>
                              <div className="text-container">
                                 <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className='form-control'
                                    id='toDate'
                                    selected={toDate}
                                    onChange={(e) => handleDateChange('toDate', e)}
                                    disabled={disabled || !specialEvent ? true : false}
                                    placeholderText="dd/mm/yyyy"
                                    autoComplete='off'
                                    minDate={new Date()}
                                 />
                                 <span className="example-custom-input calendarBtn" onClick={(e) => openDatePicker("toDate")} id="calendarBtn1" disabled={disabled ? true : false} ><i className="fa fa-calendar"></i></span>
                              </div>
                              {formError.toDate && <div className="text-danger">{formError.toDate} </div>}
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="col-lg-12 col-md-12 col-sm-12 justify-content-center d-flex mt-4">
                     <button type="button"
                        onClick={handleAddEventDate}
                        className="btn btn-primary mx-1" id="btncontactAdd"
                        disabled={disabled || !specialEvent ? true : false}
                     >Add</button>
                  </div>


                  <div className="row mt-4">
                     <div className="col-lg-12 mb-1 table-scrollable">
                        <table id="data" className="table table-hover" >
                           <thead>
                              <tr>
                                 <th className='text-start'>Event Name
                                 </th>
                                 <th className='text-center pe-4'>From Date
                                 </th>
                                 <th className='text-center pe-4'>To Date
                                 </th>
                                 <th className='text-end pe-4'>Remove
                                 </th>
                              </tr>
                           </thead>
                           <tbody id="location-data">
                              {additionInfo.EventDate && additionInfo.EventDate?.map((element, index) => {
                                 return (
                                    <tr className="ReactTable" key={element.seqNo}>
                                       <td className='text-start'>{element.eventName}</td>
                                       <td className='text-center'>{element.fromDate}</td>
                                       <td className='text-center'>{element.toDate}</td>
                                       <td className='text-end'><button type="button"
                                          onClick={(e) => { removeEventDate(e, element.seqNo) }}
                                          className="btn btn-primary mx-1"
                                          id="btnAdd"
                                          disabled={disabled}>Remove</button></td>
                                    </tr>
                                 );
                              })}

                           </tbody>
                        </table>
                     </div>
                  </div>

               </div>
            </form>
         </div>


         {/* Code Of Conduct */}
         <CodeOfConduct
            CodeOfConductCollection={CodeOfConductCollection}
            setCodeOfConductCollection={setCodeOfConductCollection}
            isEditDisable={isEditDisable}
            setIsEditDisable={setIsEditDisable}
            disabled={disabled}
         />

         {/* Access Points */}
         <AccessPoints
            additionInfo={additionInfo}
            setAdditionInfo={setAdditionInfo}
            accessPointList={accessPointList}
            setAccessPointList={setAccessPointList}
            isEditDisable={isEditDisable}
            setIsEditDisable={setIsEditDisable}
            disabled={disabled}
         />



         {/* Near by Facilities */}
         <NearByFacilities
            additionInfo={additionInfo}
            setAdditionInfo={setAdditionInfo}
            facilityData={facilityData}
            isEditDisable={isEditDisable}
            setIsEditDisable={setIsEditDisable}
            disabled={disabled}
         />

         <div className="mt-5 d-flex justify-content-center">
            <button type="button" className="btn btn-primary mx-1" id="btnAdd"
               onClick={(e) => handleAddUpdateBtn(e)}
               disabled={savedisabled || (buttonaddeditText === 'Update' ? isEditDisable ? true : false : '')}
            >
               {buttonaddeditText}
            </button>

            <button
               type="button"
               className="btn btn-primary mx-1"
               id="btnClear"
               onClick={handleClear}
               disabled={disabled || clearbuttondisable ? true : false}
            >Clear</button>

         </div>
      </div>

   )
}

export default AdditionInformation