import React from 'react';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from 'react-select';
import "../../assets/css/master.css";
import { useEffect, useState } from "react";
import { getAllData } from '../../services/ApiCallingUserServices';
import { useDispatch, useSelector } from 'react-redux';
import { errorActions } from '../../_actions/error.action';


const VendorPolicies = (props) => {
    const errorStatus = useSelector(state => state.errorStatus.isError);
    const policystatus = useSelector(state => state.errorStatus.isPolicy);
    const dispatch = useDispatch();
    const {
        handlePolicyAdd,
        handlePolicyDisable,
        retriveflag,
        policydatalist,
        editableflag,
        ischecked
    } = props

    // const dispatch = useDispatch();
    const [policylist, setPolicyList] = useState([]);
    const [policyvalue, setPolicy] = useState([]);
    const [policyId, setPolicyId] = useState(0);
    const [policyseqno, setPolicySeqNo] = useState(0);
    const [datapolicydetails, setPolicyDetails] = useState([]);
    const [policyError, setPolicyError] = useState('');


    const getPolicylist = async () => {
        await getAllData('/master/vendor/v1/vengetpackagepolicy').then((response) => {
            setPolicyList(response.data?.map((value) => ({
                label: value.PolicyName,
                value: value.PolicyId
            })));
        })
    }

    const handlepolicyonchange = (e) => {
        setPolicyError('');
        const Policytype = [
            { value: e.value, label: e.label }
        ]
        setPolicyId(e.value)
        setPolicy(Policytype);
        handlePolicyDisable(false);
        dispatch(errorActions.resetallstatusflag());
    }
    const PolicyClick = () => {
        let index = datapolicydetails?.find((obj) => obj.PolicyId === policyId) || []
        if (index.PolicyId > 0) {
            dispatch(errorActions.start())
            setPolicyError('You can not add duplicate value')
            return;
        }
        else if (policyvalue.length > 0) {
            setPolicySeqNo(policyseqno + 1)
            handlePolicyAdd([...datapolicydetails, { SeqNo: policyseqno + 1, Policy: policyvalue[0].label, PolicyId: policyvalue[0].value }])
            setPolicyDetails([...datapolicydetails, { SeqNo: policyseqno + 1, Policy: policyvalue[0].label, PolicyId: policyvalue[0].value }]);
            setPolicyId(-1);
            dispatch(errorActions.ploicyincrement());
            dispatch(errorActions.resetallstatusflag());
        }
        setPolicy([]);
        handlePolicyDisable(false);
    };
    useEffect(() => {
        getPolicylist()
    }, [])
    const policyremovev = (id, e) => {
        e.preventDefault();
        handlePolicyAdd((oldState) => oldState?.filter((item) => item.SeqNo !== id))
        setPolicyDetails((oldState) => oldState?.filter((item) => item.SeqNo !== id));
        setPolicyError('');
        setPolicyId(-1);
        handlePolicyDisable(false);
        dispatch(errorActions.policydecrement());
        dispatch(errorActions.resetallstatusflag());
        // reseterrormessage();
        // setEditDisabled(false);
    }
    const validation = () => {
        // const policycount = datapolicydetails?.filter((obj) => obj.SeqNo > 0);

        // if (policycount.length === 0) {
            setPolicyError('Please enter at least one policy');
            let incllopased = document?.getElementById("PolicyError")
            if (incllopased.ariaExpanded === "false")
                document.querySelector("#PolicyError").click();
            // dispatch(errorActions.submitend());
            dispatch(errorActions.start())
        // }
        // else {
        //     dispatch(errorActions.policystart())
        // }

    }
    useEffect(() => {
        if (retriveflag) {
            setPolicyDetails(policydatalist)
            setPolicySeqNo(policydatalist.length)

        }

    }, [policydatalist, retriveflag])
    useEffect(() => {
        if (!errorStatus) {
            setPolicyError('')
        }
    }, [errorStatus])
    useEffect(() => {
        if (policystatus && ischecked) {
            validation()
        }
    }, [policystatus])

    return (
        <>
            {<div class="accordion-item mb-3">
                <h2 class="accordion-header" id="panelsStayOpen-headingFive">
                    <button class="accordion-button collapsed" id="PolicyError" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                        Policy
                    </button>
                </h2>
                <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
                    <div class="accordion-body">
                        <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                            <div class="row">
                                {/* <label for="inputPassword6" class="col-form-label">Policy</label> */}
                                <div class="row">
                                    {/* {formerror.PolicyError && <p className='text-center text-danger'>{formerror.PolicyError}</p>} */}
                                    {policyError && <p className='text-center text-danger'>{policyError}</p>}
                                    <div className='col-lg-12 col-sm-12 '>
                                        <div className='row'>
                                            <div class="col-lg-2 col-md-3 mb-1">
                                                <label class="col-form-label required">Add Policy</label>
                                            </div>
                                            <div class="col-lg-4 mb-1 col-md-4">
                                                <Select
                                                    className="dropdown select_dropdown"
                                                    isDisabled={editableflag}
                                                    name='PolicyD'
                                                    value={policylist?.filter(({ value }) => value === policyId)}
                                                    options={policylist}
                                                    onChange={handlepolicyonchange}
                                                    isSearchable
                                                />
                                                {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                            </div>
                                            <div class="col-lg-4 mb-1 col-md-4">
                                                <button type="button" onClick={PolicyClick} class="btn btn-primary mx-1"
                                                    disabled={editableflag}
                                                    id="btnAdd">Add</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mb-1 col-lg-12 col-md-12 mt-3'>
                                        <table id="data" className="table table-hover" >
                                            <thead>
                                                <tr>
                                                    <th>Policy
                                                    </th>
                                                    {/* <th className='d-none'>Inclusion Type Id
                                                                    </th> */}
                                                    <th className='text-end pe-4'>Remove
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody id="location-data">
                                                {datapolicydetails?.map((element, index) => {
                                                    // if (datapolicycounter > 0) {
                                                    return (
                                                        <tr class="ReactTable" key={index}>
                                                            <td >{element.Policy}</td>
                                                            {/* <td className='d-none'>{element.InclusionTypeId}</td> */}
                                                            <td className='text-end'><button type="button"
                                                                onClick={(e) => { policyremovev(element.SeqNo, e) }}
                                                                disabled={editableflag}
                                                                class="btn btn-primary mx-1" id="btnAdd" >Remove</button></td>
                                                        </tr>
                                                    );
                                                    // }
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default VendorPolicies